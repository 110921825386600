import React, { forwardRef, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Typography,
    CircularProgress,
    TextField,
    Checkbox,
    FormControlLabel,
    Switch
} from "@material-ui/core";
import Axios from "axios"
import { makeStyles } from "@material-ui/core/styles";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} mountOnEnter unmountOnExit />;
});

const UpdateTransitTimes = (props) => {
    const {
        open,
        handleClose,
        setSnackBarProps
    } = props;

    const [loading, setLoading] = useState(false)
    const [north, setNorth] = useState(null);
    const [south, setSouth] = useState(null);
    const [autoRaiseTickets, setAutoRaiseTickets] = useState(false);

    useEffect(() => {
        Axios.get(`HoursInTransit/GetOverdueTimesWithAutoRaiseTicketStatus`)
            .then(res => {
                setNorth(res.data.north);
                setSouth(res.data.south);
                setAutoRaiseTickets(res.data.autoRaiseTicket)
            })
            .catch(e => {
                // console.log(e);
            })
    }, [])

    useEffect(() => {
        console.log("autoRaiseTickets")
        console.log(autoRaiseTickets)
    }, [autoRaiseTickets])

    const saveChanges = () => {
        setLoading(true)
        Axios.patch(`HoursInTransit/UpdateTicketRaisingAdmin`, {
            north: north,
            south: south,
            autoRaiseTicket: autoRaiseTickets
        })
            .then(res => {
                setSnackBarProps("success", "Saved", true);
            })
            .catch(e => {
                props.setSnackBarProps("warning", "Failed to save", true);
            })
            .finally(() => {
                handleClose();
                setLoading(false);
            })
    }

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => handleClose()}
                disableBackdropClick
            >
                <DialogTitle>Update overdue transit hours</DialogTitle>
                <DialogContent>
                    <TextField
                        variant="outlined"
                        label="North Island"
                        fullWidth
                        type="number"
                        value={north}
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        onChange={e => {
                            setNorth(e.target.value);
                        }}
                    />
                    <TextField
                        variant="outlined"
                        label="South Island"
                        fullWidth
                        type="number"
                        value={south}
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        onChange={e => {
                            setSouth(e.target.value);
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={autoRaiseTickets}
                                onChange={(e) => setAutoRaiseTickets(e.target.checked)}
                                name="auto-raise-ticket-switch"
                                color="primary"
                            />
                            // <Checkbox
                            //     color="primary"
                            //     checked={autoRaiseTickets}
                            //     onClick={() => {
                            //         if(autoRaiseTickets){
                            //             setAutoRaiseTickets(false)
                            //         } else {
                            //             setAutoRaiseTickets(true)
                            //         }
                            //     }}
                            // />
                        }
                        label="Auto-raise NZPost Tickets"
                        labelPlacement="left"
                    />
                    <Typography>When unchecked it will send emails to customers but will NOT raise tickets. Tickets will need to be raised manually.</Typography>
                </DialogContent>
                <DialogActions>
                    {loading ?
                        <CircularProgress />
                        : (
                            <React.Fragment>
                                <Button variant="contained" color="default" onClick={() => handleClose()}>
                                    Go Back
                                </Button>
                                <Button variant="contained" color="primary" onClick={() => saveChanges()}>
                                    Update
                                </Button>
                            </React.Fragment>
                        )
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default UpdateTransitTimes;