import React from "react";
import { connect } from "react-redux";
import OrdersTable from "../Shared/OrdersTable";

const OnHoldOrders = (props) => {
  const { orders, hpMode } = props;

  if (orders) {
    debugger;
    return (
      <OrdersTable
        parent="Orders On Hold"
        orders={orders.filter(
          (o) =>
            o.status === 11 || (hpMode && (o.status === 9 || o.status === 6))
        )}
      />
    );
  } else {
    return <div>Loading...</div>;
  }
};

const mapStateToProps = (state) => ({
  orders: state.order.orders,
  hpMode: state.system.hpMode,
});

export default connect(mapStateToProps, {})(OnHoldOrders);
