import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import CurrencyFormatter from "../../Shared/CurrencyFormatter";
import { convertIdFromGraphql } from "../../Utils/shopifyConverter";
import "./refundModal.scss"
const RefundModal = ({ open, handleClose, order, handleCallback, forceValue, title }) => {
  const [refundValue, setRefundValue] = useState("0");
  const [refund, setRefund] = useState({});
  const initialValue = forceValue ? forceValue : order?.total;

  useEffect(() => {
    if (order) {
      setRefund({
        paymentIntentId: order?.paymentIntentId,
        amount: Math.floor(initialValue * 100),
      });
      setRefundValue(Math.floor(initialValue * 100) / 100)
    }
  }, [order]);


  const handleSubmit = async () => {
    if (Number(refundValue) > 0) {
      refund.amount = Math.floor(Number(refundValue) * 100)
    } else {
      alert("Error with refund value")
      return
    }

    console.log(refund);
    axios.post(`Stripe/CreateRefund/${convertIdFromGraphql(order.shopifyOrderId)}`, refund)
      .then(({ data }) => {
        console.log(data)
        handleClose();
        handleCallback();
      }).catch(err => { 
        console.log(err) 
        alert("unable to refund") 
      })
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"xs"}>
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <span>Total:</span>{" "}
          <CurrencyFormatter useDollar amount={order.total} />
        </DialogContentText>
        <DialogContentText>
          <span>Stripe Payment: </span>
          <a
            href={`https://dashboard.stripe.com/test/payments/${refund.paymentIntentId}`}
            target="_blank"
          >
            {refund.paymentIntentId}
          </a>
        </DialogContentText>
        <div className="form-fields">
          <TextField
            id="amount"
            size="small"
            label="Amount to refund"
            type="number"
            variant="outlined"
            disabled={forceValue !== null}
            value={refundValue}
            onChange={(e) => setRefundValue(e.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Refund
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RefundModal;
