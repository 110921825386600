import { createReducer } from "@reduxjs/toolkit";

const initialState = {};

const notes = createReducer(initialState, {
  GET_ALL_MANAGED_NOTES: (state, action) => {
    return {
      ...state,
      notes: action.payload,
    };
  },
  UPDATE_NOTE: (state, action) => {
    return {
      ...state,
      notes: state.notes.map(n => n.id === action.payload.id ? action.payload : n),
    };
  },
  ADD_MANAGED_NOTE: (state, action) => {
    let existingNote = state.notes.find(n => n.id === action.payload.id);
    if(existingNote){
      return {
        ...state,
        notes: state.notes.map(n => n.id === action.payload.id ? action.payload : n),
      };
    }else{
      return {
        ...state,
        notes: [action.payload, ...state.notes],
      };
    }
  },
});

export default notes;
