import React, { forwardRef, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Typography,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import ShippingDetails from "./ShippingDetails";
import axios from "axios";
import SkuItemTable from "../BusinessDirect/SkuItemTable";
import _ from "lodash";
import { connect } from "react-redux";
import OrderNote from "./OrderNote";
import { formatColumnValueForExport } from "../Utils/domainUtils";
import { PacmanLoader } from "react-spinners";
import {
  addUpdatingOrder,
  removeUpdatingOrder,
} from "../../reduxActions/order";
import { makeStyles } from "@material-ui/core/styles";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide direction="up" ref={ref} {...props} mountOnEnter unmountOnExit />
  );
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }
}));

const QuickFixPopup = ({
  open,
  handleClose,
  order,
  setSnackBarProps,
  messages,
  allProducts,
  addUpdatingOrder,
  removeUpdatingOrder,
}) => {
  const classes = useStyles();
  const [orderToBeUpdated, setOrderToBeUpdated] = useState(null);
  const [skuPriceItems, setSkuPriceItems] = useState([]);
  const [itemQuantity, setItemQuantity] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [total, setTotal] = useState(0);
  const [returnLabel, setReturnLabel] = useState(0);
  const [loading, setloading] = useState(false);
  const [preferredCourier, setPreferredCourier] = useState(null);

  useEffect(() => {
    if (order) {
      setOrderToBeUpdated(order);
    }
    console.log(order);
  }, [order]);

  useEffect(() => {
    axios
      .get(`BusinessDirect/GetSkuPrice`)
      .then(({ data }) => {
        setSkuPriceItems(data);
      })
      .catch((e) => { });
  }, []);

  useEffect(() => {
    if (itemQuantity === null || _.isEmpty(itemQuantity)) {
      var items = {};
      skuPriceItems.map((s) => {
        items = {
          ...items,
          [s.flavour]: "0",
        };
      });
      setItemQuantity(items);
    }
  }, [skuPriceItems]);

  useEffect(() => {
    if (itemQuantity !== null) {
      var newTotal = 0;
      var keys = Object.keys(itemQuantity);
      for (var i = 0; i < keys.length; i++) {
        var val = itemQuantity[keys[i]];
        if (typeof val == "string" && val.trim() != "") {
          newTotal = parseInt(val) + newTotal;
        }
      }
      setTotal(newTotal);
    }
  }, [itemQuantity]);

  const goToShopify = (order) => {
    window.open(
      "https://simply-coffee-capsules.myshopify.com/admin/orders/" + order.id
    );
  };

  const viewCourierTracking = async (order) => {
    const courierOrder = order.courierOrder;
    if (courierOrder) {
      const tracking = formatColumnValueForExport(
        "trackingNumbers",
        courierOrder.trackingNumbers
      );
      var url =
        courierOrder.courier === 1
          ? "https://www.nzpost.co.nz/tools/tracking/item/" + tracking
          : "https://www.aramex.co.nz/tools/track/?l=" + tracking;
      window.open(url);
    }
  };

  const getCoffeeStringArray = (itemQuantities) => {
    console.log("itemQuantities");
    console.log(itemQuantities);
    var coffeeStringArray = [];
    for (let property in itemQuantities) {
      var p = allProducts.find(
        (p) => p.code.toLocaleLowerCase() === property.toLowerCase()
      );
      if (p.isMiscellaneous === 0 || property.toLowerCase() === "rt" || property.toLowerCase() === "glm") {
        var quantity = itemQuantities[property];
        for (var i = 0; i < quantity; i++) {
          coffeeStringArray.push(property);
        }
      }
    }
    return coffeeStringArray;
  };

  const handleSave = (option) => {
    setloading(true);
    addUpdatingOrder(orderToBeUpdated.id);
    debugger;
    let editedOrder = {
      makeDefault: false,
      changeShipping: false,
      firstName: orderToBeUpdated.shippingAddress.firstName,
      lastName: orderToBeUpdated.shippingAddress.lastName,
      street: orderToBeUpdated.shippingAddress.address1,
      suburb: orderToBeUpdated.shippingAddress.address2,
      city: orderToBeUpdated.shippingAddress.city,
      postCode: orderToBeUpdated.shippingAddress.zip,
      isPostal: orderToBeUpdated.shippingAddress.isPostal,
      country: orderToBeUpdated.shippingAddress.country,
      notes: "",
      deliveryInstructions: "",
      coffeeTypesQuantityArr: getCoffeeStringArray(itemQuantity),
      miscQuantityArr: [],
      validAddress: 1,
      handwrittenNote: {
        ...selectedMessage,
        message: selectedMessage.message1,
        greeting:
          selectedMessage.greeting +
          " " +
          orderToBeUpdated.shippingAddress.firstName,
        handwrittenNoteObj: JSON.stringify(selectedMessage.messageObj).replace(
          "{{name}}",
          orderToBeUpdated.shippingAddress.firstName
        ),
      },
      company: orderToBeUpdated.shippingAddress.company,
      priority: 1,
      id: orderToBeUpdated.id,
      preferredCourier: preferredCourier,
    };
    handleClose();
    axios
      .put(`Orders/QuickEditOrder/${orderToBeUpdated.id}/${option}/SC${orderToBeUpdated.orderNumber}`, {
        editedOrder,
        returnLabel,
      })
      .then(({ data }) => {
        setSnackBarProps("success", `Order Updated`, true);
      })
      .catch((e) => {
        console.log(e);
        setSnackBarProps("warning", "Failed to Update Order", true);
      })
      .finally(() => {
        setloading(false);
        removeUpdatingOrder(orderToBeUpdated.id)
      });
  };

  const loadOriginalOrderItems = () => {
    if (order && order.courierOrder) {
      var items = {};
      skuPriceItems.map((s) => {
        items = {
          ...items,
          [s.flavour]: "0",
        };
      });
      const types = order.courierOrder.coffeeTypesQuantity.split(",");
      if (types) {
        let _itemQuantity = { ...items };
        types.forEach((item) => {
          let quantity = parseInt(_itemQuantity[item]);
          if (quantity > 0) {
            _itemQuantity[item] = (quantity + 1).toString();
          } else {
            _itemQuantity[item] = "1";
          }
        });
        setItemQuantity(_itemQuantity);
      }
      debugger;
      if(order.preferredCourier){
        setPreferredCourier(order.preferredCourier);
      }
      else {
        setPreferredCourier(null);
      }
    } else {
      alert("something went wrong");
    }
  };

  const isOrderValid = () => {
    let isInvalid = false;
    if (
      !orderToBeUpdated ||
      !orderToBeUpdated.customerFirstName ||
      !orderToBeUpdated.customerLastName ||
      total < 1 ||
      !orderToBeUpdated.shippingAddress.address1 ||
      !orderToBeUpdated.shippingAddress.zip ||
      !selectedMessage
    ) {
      isInvalid = true;
    }
    return isInvalid;
  };
  return (
    <div>
      <Dialog
        open={open && orderToBeUpdated}
        TransitionComponent={Transition}
        onClose={handleClose}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle className="quickfix-dialog-title">{`Editing Order SC${order.orderNumber}`}</DialogTitle>
        {loading ? (
          <DialogContent style={{ marginTop: 10 }}>
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          </DialogContent>
        ) : (
          <DialogContent style={{ marginTop: 10 }}>
            <Grid container justify="space-evenly" spacing={4}>
              <Grid item>
                <Typography
                  className="hyperlink"
                  variant="caption"
                  component="div"
                  onClick={() => goToShopify(orderToBeUpdated)}
                >
                  Go to Shopify
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className="hyperlink"
                  variant="caption"
                  component="div"
                  onClick={() => viewCourierTracking(orderToBeUpdated)}
                >
                  Track Package
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <ShippingDetails
                  order={orderToBeUpdated}
                  setOrderToBeUpdated={setOrderToBeUpdated}
                  returnLabel={returnLabel}
                  setReturnLabel={setReturnLabel}
                />
              </Grid>
              <Grid item xs={12}>
                <OrderNote
                  order={orderToBeUpdated}
                  messages={messages.filter((m) => m.isApologyNote)}
                  setSelectedMessage={setSelectedMessage}
                  selectedMessage={selectedMessage}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink>
                    Preferred Courier
                  </InputLabel>
                  <Select
                    displayEmpty
                    value={preferredCourier}
                    onChange={(e) => {
                      //debugger;
                      setPreferredCourier(e.target.value)
                      //setCurrentCourierPreferenceChanged(true);
                    }}
                  >
                    <MenuItem value={null}>
                      <em>No Preference</em>
                    </MenuItem>
                    <MenuItem value={"NZ POST"}>NZPost</MenuItem>
                    <MenuItem value={"ARAMEX"}>Aramex</MenuItem>
                    {/* <MenuItem value={"AS FAST AS POSSIBLE"}>As fast as possible</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} className="color-black">
                <div style={{ textAlign: "end" }}>
                  <Button variant="outlined" onClick={loadOriginalOrderItems}>
                    Load from Original Order
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} className="color-black">
                <SkuItemTable
                  className="color-black"
                  skuPriceItems={skuPriceItems}
                  itemQuantity={itemQuantity}
                  setItemQuantity={setItemQuantity}
                  total={total}
                />
              </Grid>
            </Grid>
          </DialogContent>
        )}
        <DialogActions>
          <Button variant="contained" color="default" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isOrderValid()}
            onClick={() => handleSave("SEND_TO_STORE")}
          >
            Send to Store
          </Button>
          {/* <Button
            variant="contained"
            color="primary"
            disabled={isOrderValid()}
            onClick={() => handleSave("SAVE_AND_RELEASE")}
          >
            Save and Release
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  messages: state.message.messages,
  allProducts: state.products.allProducts,
});

export default connect(mapStateToProps, {
  addUpdatingOrder,
  removeUpdatingOrder,
})(QuickFixPopup);
