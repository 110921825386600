// import 'bootstrap/dist/css/bootstrap.css';
import React from "react";
import ReactDOM from "react-dom";
import store from "./store/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import App from "./App";
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from "./registerServiceWorker";
import "./Font/astSpahn.ttf";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CustomerContextProvider } from "./context/customer/CustomerContext";
import { StoreProvider } from "./context/store/StoreContext";
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

ReactDOM.render(
  <Provider store={store}>
    <Elements stripe={stripePromise}>
      <GoogleOAuthProvider clientId="179069991436-jvv7cail7193b23a2llburkihsgj74de.apps.googleusercontent.com">
        <CustomerContextProvider>
          <StoreProvider>
            <BrowserRouter basename={baseUrl}>
              <App />
            </BrowserRouter>
          </StoreProvider>
        </CustomerContextProvider>
      </GoogleOAuthProvider>
    </Elements>
  </Provider>,
  rootElement
);

// registerServiceWorker();
unregister();
