import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import React, { useState } from "react";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ExportColumnsModal = (props) => {
  const [columns, setColumns] = useState([
    {
      name: "Order Id",
      field: "id",
      isChecked: false,
      entity: "Order",
    },
    {
      name: "Order Number",
      field: "orderNumber",
      isChecked: true,
      entity: "Order",
    },
    {
      name: "Status",
      field: "status",
      isChecked: true,
      entity: "Order",
    },
    {
      name: "Time Received",
      field: "timeReceived",
      isChecked: true,
      entity: "Order",
    },
    {
      name: "Number Of Boxes",
      field: "numberOfBoxes",
      isChecked: false,
      entity: "Order",
    },
    {
      name: "Box Size",
      field: "boxSize",
      isChecked: false,
      entity: "Order",
    },
    {
      name: "Total Price",
      field: "totalPrice",
      isChecked: false,
      entity: "Order",
    },
    {
      name: "Order Items",
      field: "quantitiesDescription",
      isChecked: false,
      entity: "Order",
      parent: "courierOrder", 
      isArray: true,
    },
    {
      name: "Courier",
      field: "courier",
      isChecked: false,
      entity: "Courier",
      parent: "courierOrder", 
      isArray: true,
    },
    {
      name: "Tracking Number",
      field: "trackingNumbers",
      isChecked: false,
      entity: "Courier",
      parent: "courierOrder", 
      isArray: true,
    },
    {
      name: "Address 1",
      field: "address1",
      isChecked: false,
      entity: "Shipping Address",
      parent: "shippingAddress", 
      isArray: false,
    },
    {
      name: "Address 2",
      field: "address2",
      isChecked: false,
      entity: "Shipping Address",
      parent: "shippingAddress", 
      isArray: false,
    },
    {
      name: "City",
      field: "city",
      isChecked: false,
      entity: "Shipping Address",
      parent: "shippingAddress", 
      isArray: false,
    },
    {
      name: "Postcode",
      field: "zip",
      isChecked: false,
      entity: "Shipping Address",
      parent: "shippingAddress", 
      isArray: false,
    },
    {
      name: "First Name",
      field: "customerFirstName",
      isChecked: true,
      entity: "Customer",
    },
    {
      name: "Last Name",
      field: "customerLastName",
      isChecked: true,
      entity: "Customer",
    },
    {
      name: "Email Address",
      field: "emailAddress",
      isChecked: true,
      entity: "Customer",
    },
  ]);

  const handleChangeColumn = (newValues) => {
    var columnsClone = [...columns];
    setColumns(
      columnsClone.map((c) => {
        var selectedOption = newValues?.find(col => col.field === c.field)
        if (selectedOption) {
          c.isChecked = true;
        } else {
          c.isChecked = false;
        }
        return c;
      })
    );
  };

  return (
    <div>
      <Dialog
        open={props.open}
        keepMounted
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Please select the columns"}
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            multiple
            options={columns}
            disableCloseOnSelect
            groupBy={(option) => option.entity}
            getOptionLabel={(option) => option.name}
            value={columns.filter((c) => c.isChecked)}
            onChange={(event, newValue) => {
              handleChangeColumn(newValue)
            }}
            renderOption={(column, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={column.isChecked}
                />
                {column.name}
              </React.Fragment>
            )}
            style={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Select Columns"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) =>
              props.bulkExport(columns.filter((c) => c.isChecked))
            }
            color="primary"
          >
            Export
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ExportColumnsModal;
