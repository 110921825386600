import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ConfirmationDialogWithInput from "../../Shared/ConfirmationDialogWithInput";
import axios from "axios";
const options = ["Mark as processed", "Test"];

const WebsiteActionButtons = ({ websiteError,removeWebsiteErrorFromList }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [openConfirmMarkProcessed, setOpenConfirmMarkProcessed] =
    useState(false);

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
    if (selectedIndex === 0) {
      setOpenConfirmMarkProcessed(true);
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleMarkAsProcessed = (orderNumber) => {
    setIsProcessing(true)
    axios.post(`errors/MarkWebsiteOrderAsProcessed/${websiteError.id}`, null, {
        params: {
            orderNumber: orderNumber ? orderNumber.match(/\d+/g)?.join('') : null
        }
    })
    .then(({data}) => {
        setOpenConfirmMarkProcessed(false)
        removeWebsiteErrorFromList(websiteError.id)
    })
    .catch((err) => {
        alert("something went wrong")
    })
    .finally(() => setIsProcessing(false))
  };

  return (
    <>
      {openConfirmMarkProcessed && (
        <ConfirmationDialogWithInput
          loading={isProcessing}
          open={openConfirmMarkProcessed}
          handleClose={() => {
            setOpenConfirmMarkProcessed(false);
          }}
          handleSubmit={handleMarkAsProcessed}
          submitLabel={"Save"}
          description={"Mark this error as processed."}
          textFieldLabel={"Order Number"}
          title={"Please enter the Order Number."}
        />
      )}
      <ButtonGroup
        variant="contained"
        color="secondary"
        ref={anchorRef}
        size="small"
      >
        <Button onClick={handleClick}>{options[selectedIndex]}</Button>
        <Button
          color="primary"
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          style={{ backgroundColor: "#C1AF94" }}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: "9999" }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default WebsiteActionButtons;
