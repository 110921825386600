import React, { forwardRef, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    FormControlLabel,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Typography,
    CircularProgress
} from "@material-ui/core";
import Axios from "axios"
import { makeStyles } from "@material-ui/core/styles";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} mountOnEnter unmountOnExit />;
});

const PrintCourierCompost = (props) => {
    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        }
    }));
    const classes = useStyles();

    const {
        open,
        handleClose,
        setSnackBarProps
    } = props;

    const [printAmount, setPrintAmount] = useState(1);
    const [loading, setLoading] = useState(false)

    const print = () => {
        setLoading(true)
        Axios.post(`SystemActions/GenerateCourierCompostLabel/${printAmount}`)
            .then(res => {
                setSnackBarProps("success", "Sent to the printer", true);
            })
            .catch(e => {
                props.setSnackBarProps("warning", "Failed to print", true);
            })
            .finally(() => {
                handleClose()
                setLoading(false)
            })
    }

    const getQuantityRange = () => {
        const menuItems = []

        for (var i = 1; i <= 50; i++) {
            menuItems.push(
                <MenuItem value={i}>{i}</MenuItem>
            )
        }

        return menuItems;
    }

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => handleClose()}
            >
                <DialogTitle>Courier and Compost print options</DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1">Select the amount of labels to print</Typography>
                    <FormControl className={classes.formControl}>
                        <InputLabel>Quanity</InputLabel>
                        <Select
                            value={printAmount}
                            onChange={(e) => setPrintAmount(e.target.value)}
                            disabled={loading}
                        >
                            {getQuantityRange().map(item => {
                                return item
                            })}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    {loading ?
                        <CircularProgress />
                        : (
                            <React.Fragment>
                                <Button variant="contained" color="default" onClick={() => handleClose()}>
                                    Go Back
                                </Button>
                                <Button variant="contained" color="primary" onClick={() => print()}>
                                    Print
                                </Button>
                            </React.Fragment>
                        )
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default PrintCourierCompost;