import React from 'react'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

const options = ['Send to Store', "Release", "Go to Shopify", "View Tracking #", "Ignore", "Fulfill Quietly"];

const ActionButton = ({order, viewOrder, handleRelease, handleSendToStore, goToShopify, viewCourierTracking, markAsResolved, fulfillOrder}) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
  
    const handleMenuItemClick = (event, index) => {
        if (index === 0) {
            handleSendToStore(order)
        } else if (index === 1) {
            handleRelease(order)
        } else if (index === 2) {
            goToShopify(order)
        } else if (index === 3) {
            viewCourierTracking(order)
        } else if (index === 4) {
            markAsResolved(order)
        } else if (index === 5) {
          fulfillOrder(order, false)
      }
        setOpen(false);
    };
  
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };
  
    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
  
      setOpen(false);
    };
  
    return (
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button"
                  size="small">
            <Button onClick={(e) => viewOrder(order)}>{`View Order`}</Button>
            <Button
              color="primary"
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex: 999}}>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'top' ? 'center top' : 'center top'
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {options.map((option, index) => (
                        <MenuItem
                          key={option}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    );
}

export default ActionButton