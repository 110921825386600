import React, { useCallback } from "react";
import CurrencyFormatter from "../../Shared/CurrencyFormatter";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  ButtonGroup,
  TextField,
} from "@material-ui/core";
import { useContext } from "react";
import { StoreContext } from "../../../context/store/StoreContext";
import { useState } from "react";
import QuantityInput from "../QuantityInput/QuantityInput";
import debounce from "lodash.debounce";
import { useEffect } from "react";
import { checkSkuForCapsulesTotal } from "../../Utils/adminPanelUtils";
import { Skeleton } from "@material-ui/lab";

const ProductTableItem = ({
  variant,
  isSubscription,
  handleSelectNewProduct,
  index,
}) => {
  const {
    checkout,
    updateLineItem,
    addVariantToCart,
    loading,
    registerCalculatingPrices,
  } = useContext(StoreContext);
  const [quantity, setQuantity] = useState(0);
  const [lineItem, setLineItem] = useState(null);
  const [processingLineItem, setProcessingLineItem] = useState(false);

  useEffect(() => {
    const lineItem = checkout?.lineItems?.find(
      (item) => item?.variant?.id === variant?.variantId
    );
    if (lineItem) {
      setQuantity(lineItem.quantity);
    }
  }, []);

  useEffect(() => {
    const lineItem = checkout?.lineItems?.find(
      (item) => item?.variant?.id === variant?.variantId
    );
    setLineItem(lineItem);
  }, [checkout?.lineItems]);

  const addToCart = async (variant, quantity) => {
    addVariantToCart(variant.variantId, quantity, {
      numberOfCapsules: await checkSkuForCapsulesTotal(variant.sku, parseInt(1, 10)),
      })
      .then(() => {
        setProcessingLineItem(false)
        registerCalculatingPrices(index + "Adding", true)
      });
  };

  const ali = debounce((value) => addToCart(variant, value), 400)
  // eslint-disable-next-line
  const debouncedAli = useCallback((value,) => {
    registerCalculatingPrices(index + "Adding", false) 
    ali(value)}, []);

  const uli = debounce(
    (value,checkoutId, lineItemId) => {
      updateLineItem( checkoutId, lineItemId, value, variant)
        .then(() => registerCalculatingPrices(lineItemId, true));
    },
    2000
  );
  // eslint-disable-next-line
  const debouncedUli = useCallback((value, checkoutId, lineItemId) => {
    registerCalculatingPrices(lineItemId, false)
    uli(value, checkoutId, lineItemId)}, []
  );


  const handleUpdateCheckout = (value) => {
    setQuantity(value < 0 ? 0 : value);
    if (Number(value) >= 1) {
      if (lineItem) {
        debouncedUli(value, checkout.id, lineItem.id);
      } else {
        debouncedAli(value);
      }
    } else {
      if (lineItem?.id) {
        debouncedUli(0, checkout.id, lineItem.id);
      }
    }
  };
  const handleQuantityChange = (value) => {
    // Stop quantity from going undefined
    if (value === undefined) {
      setQuantity(0);
      value = "0";
    }
    if (lineItem) {
      handleUpdateCheckout(value);
    } else {
      //setProcessingLineItem(true);
      handleUpdateCheckout(value);
    }
  };

  function doIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1);
  }

  function doDecrement() {
    if (quantity >= 0) {
      handleQuantityChange(Number(quantity || 0) - 1);
    }
  }

  return (
    <TableRow>
      <TableCell className="product-table-row-title-cell">
        <div className="product-description-container">
          <div className="product-image-container">
            <img
              src={`${variant.productImage}`}
              alt={`product-${variant.sku}`}
              className="product-image"
            />
          </div>
          {`${variant.title}`}
        </div>
      </TableCell>
      {isSubscription ? (
        <TableCell>
          <Button
            onClick={() => handleSelectNewProduct(variant)}
            variant="outlined"
            size="small"
          >
            Select Variant
          </Button>
        </TableCell>
      ) : (
        <TableCell>
          {processingLineItem ? (
            <Skeleton variant="rect" width={120} height={40} />
          ) : (
            <QuantityInput
              value={quantity}
              onIncrement={doIncrement}
              onDecrement={doDecrement}
              onChange={(e) => handleQuantityChange(e.currentTarget.value)}
              disabled={loading}
            />
          )}
        </TableCell>
      )}

      <TableCell align="right">
        <CurrencyFormatter useDollar amount={variant.price} />
      </TableCell>
    </TableRow>
  );
};

const ProductTable = ({
  capsuleType,
  products,
  isSubscription,
  handleSelectNewProduct,
}) => {
  return (
    <Table className="product-table">
      <TableHead className="product-table-head">
        <TableRow>
          <TableCell>{`${capsuleType} Capsules`}</TableCell>
          <TableCell align="center">Quantity</TableCell>
          <TableCell align="right">Price</TableCell>
        </TableRow>
      </TableHead>
      <TableBody className="product-table-body">
        {products &&
          products.map((p, index) => {
            return (
              <ProductTableItem
                variant={p}
                key={index}
                index={index}
                isSubscription={isSubscription}
                handleSelectNewProduct={handleSelectNewProduct}
              />
            );
          })}
      </TableBody>
    </Table>
  );
};

export default ProductTable;
