import React, { useState, useEffect, Fragment, useCallback } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  Modal,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Grid,
  TextField,
  Button,
  Tab,
  Tabs,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";
import { Close, KeyboardArrowDown, KeyboardArrowUp, Label } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import TabPanel from "../Shared/TabPanel";
import { makeStyles } from "@material-ui/core/styles";
import Axios from "axios";
import { getValueOrEmptyString, hasValue } from "../Utils/helperFunctions";
import "./editOrder.scss";
import card from "../../img/note.PNG";
import Dialogy from "../Shared/Dialogy";
import StatusModal from "./ChangeStatusModal";
import LabelTabPanel from "./LabelTabPanel";
import { getAllNotes, addManagedNote } from "../../reduxActions/notes";
import ManageNoteButtonGroup from "../Control/ManageNotesComponents/ManageNoteButtonGroup";
import { addUpdatingOrder, saveEditedOrder, saveAndRelease } from "../../reduxActions/order";
import AddressHistoryTabPanel from "./AddressHistoryTabPanel";
import SaveToShopifyDialog from "./SaveToShopifyDialog";
import ErrorLogTabPanel from './ErrorLogTabPanel';

const EditStoreOrderPopup = (props) => {

  const useStyles = makeStyles((theme) => ({
    paper: {
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 3),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));

  const {
    addUpdatingOrder,
    saveEditedOrder,
    saveAndRelease
  } = props;


  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [addresses, setAddressses] = useState([]);
  const [addressObj, setAddressObj] = useState({
    street: undefined,
    suburb: undefined,
    city: undefined,
    postCode: undefined,
    country: undefined,
    error: true,
    isPostal: null
  });
  const [currentAddress, setCurrentAddress] = useState(undefined);
  const [originalAddress, setOriginalAddress] = useState(undefined);
  const [currentAddressChanged, setCurrentAddressChanged] = useState(false);
  const [currentCourierPreferenceChanged, setCurrentCourierPreferenceChanged] = useState(false);
  const [nameObj, setNameObj] = useState({
    firstName: undefined,
    lastName: undefined,
    firstNameChanged: false,
    lastNameChanged: false,
  });
  const [customerNotes, setCustomerNotes] = useState({
    note: undefined,
    noteChanged: false,
  });
  const [deliveryInstructions, setDeliveryInstructions] = useState(undefined);
  const [messageObj, setMessageObj] = useState({});
  const [itemQuantities, setItemQuantities] = useState({});
  const [showProfaneWarning, setShowProfaneWarning] = useState({
    firstName: null,
    lastName: null,
    note: null,
  });
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [disabledInfo, setDisabledInfo] = useState({
    orderNumber: null,
    status: null,
    shopifyId: null,
    trackingNumbers: null,
    driveId: null,
    courierType: null
  });

  const [priority, setPriority] = useState(0);

  const [originalOrder, setOriginalOrder] = useState({
    nameObj: {
      firstName: undefined,
      lastName: undefined,
    },
    address: {
      street: undefined,
      suburb: undefined,
      city: undefined,
      postCode: undefined,
      country: undefined,
      error: true,
      isPostal: null
    },
    deliveryInstructions: undefined,
    itemQuantities: {},
    messageObj: {
      id: undefined,
      greeting: "",
      message: "",
      signedBy: "",
    },
  });
  const [orderChanged, setOrderChanged] = useState(false);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [isExistingManagedNote, setIsExistingManagedNote] = useState(false);

  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [previousAddresses, setPreviousAddresses] = useState([]);
  const [checkAddress, setCheckAddress] = useState(false);

  const { notes, getAllNotes, addManagedNote, allProducts, products } = props;

  //-------------Functions----------
  const getNZPostAddresses = (searchTerm) => {
    setAutocompleteLoading(true)
    if (searchTerm && searchTerm !== "") {
      Axios.get(`Orders/GetNZPostAddresses/${encodeURIComponent(searchTerm)}`)
        .then((res) => {
          // console.log(res);
          setAddressses(res.data);
        }).catch((e) => {
          // console.log(e);
          setAddressses([]);
        }).finally(() => setAutocompleteLoading(false));
    } else {
      setAutocompleteLoading(false)
    }
  };

  const handleAddressSelection = (fullAddress, isPostalAddress, isUndo) => {
    let addressArr = fullAddress.split(",");
    let street,
      suburb,
      city,
      postCode,
      country = null;
    street = addressArr[0].trim();
    if (addressArr.length === 3) {
      suburb = addressArr[1].trim();
      let postcodeCountryArr = addressArr[2].split(" ");
      postCode = postcodeCountryArr[postcodeCountryArr.length - 1].trim();

      for (let i = 0; i < postcodeCountryArr.length - 1; i++) {
        if (postcodeCountryArr[i] !== "") {
          if (city == null) {
            city = ` ${postcodeCountryArr[i].trim()}`;
          } else {
            city += ` ${postcodeCountryArr[i].trim()}`;
          }
        }
      }
    } else if (addressArr.length === 2) {
      let postcodeCityArr = addressArr[1].split(" ");
      postCode = postcodeCityArr[postcodeCityArr.length - 1].trim();

      //starts at 1 so it does not include "" and ends at -1 so postcode is not included
      for (let i = 1; i < postcodeCityArr.length - 1; i++) {
        if (city == null) {
          if (city === undefined || city === null) {
            city = postcodeCityArr[i].trim();
          } else {
            city += ` ${postcodeCityArr[i].trim()}`;
          }
        } else {
          city += ` ${postcodeCityArr[i].trim()}`;
        }
      }
    } else if (addressArr.length === 4 && addressArr[0].includes("Level")) {
      let postcodeCityArr = addressArr[3].split(" ");
      street = `${addressArr[0]}, ${addressArr[1].trim()}`;
      suburb = addressArr[2].trim();
      postCode = postcodeCityArr[postcodeCityArr.length - 1].trim();

      //starts at 1 so it does not include "" and ends at -1 so postcode is not included
      for (let i = 1; i < postcodeCityArr.length - 1; i++) {
        if (city == null) {
          if (city === undefined || city === null) {
            city = postcodeCityArr[i].trim();
          } else {
            city += ` ${postcodeCityArr[i].trim()}`;
          }
        } else {
          city += ` ${postcodeCityArr[i].trim()}`;
        }
      }
    }
    country = "New Zealand";
    setAddressObj({
      street: street,
      suburb: suburb,
      city: city,
      postCode: postCode,
      country: country,
      error: false,
      isPostal: isPostalAddress ? 1 : 0
    });
    var suburbString = (suburb === null || suburb === undefined || suburb === "" ? "" : `${suburb}, `);
    setCurrentAddress(`${street}, ${suburbString}${city} ${postCode}, ${country}`);
    setRerenderAddressAutocomplete(true);
    debugger;
    if (isUndo) {
      setCurrentAddressChanged(false);
    } else {
      setCurrentAddressChanged(true);
    }
  };

  const getCourierName = (courierOrder) => {
    if(courierOrder === 1){
      return "NZ Post";
    } else if(courierOrder === 2){
      return "Aramex"
    } else {
      return "";
    }
  }

  const [dialogyDetails, setdialogyDetails] = useState({
    title: null,
    text: null,
    type: null,
  });

  const [saveToShopifyDialog, setSaveShopifyDialog] = useState(false);
  const [makeDefaultShopify, setMakeDefaultShopify] = useState(false);
  const [changeShippingShopify, setChangeShippingShopify] = useState(false);
  const [updatePreferredCourierShopify, setUpdatePreferredCourierShopify] = useState(false);
  const [saveAndReleaseClicked, setSaveAndReleaseClicked] = useState(false);
  const [closeIconClicked, setCloseIconClicked] = useState(false);


  const [proceed, setproceed] = useState(false);
  const [getOrder, setGetOrder] = useState(true);
  const [company, setCompany] = useState("");

  const [openConfirmLabel, setOpenConfirmLabel] = React.useState(false);

  const [rerenderAddressAutocomplete, setRerenderAddressAutocomplete] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [preferredCourier, setPreferredCourier] = useState(null);

  const handleKeyDownResetAddress = useCallback((event) => {
    if (tabValue === 0 && event.key === 'z' && event.ctrlKey) {
      handleAddressSelection(originalAddress.replace(", New Zealand", ""), originalOrder.address.isPostal, true)
    }
  }, [tabValue, originalAddress, originalOrder]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDownResetAddress);

    // cleanup this component
    return () => {
      window.removeEventListener('keydown', handleKeyDownResetAddress);
    };
  }, [tabValue, originalAddress, originalOrder, handleKeyDownResetAddress]);

  useEffect(() => {
    if (rerenderAddressAutocomplete) {
      setRerenderAddressAutocomplete(false);
    }
  }, [rerenderAddressAutocomplete])

  useEffect(() => {
    if ({ searchTerm }) {
      const delayDebounceFn = setTimeout(() => {
        getNZPostAddresses(searchTerm);
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchTerm]);

  const getCoffeeStringArray = (itemQuantities) => {
    var coffeeStringArray = [];
    for (let property in itemQuantities) {
      var p = allProducts.find(p => p.code.toLocaleLowerCase() === property.toLowerCase())
      if (p.isMiscellaneous === 0 || property.toLowerCase() === "rt" || property.toLowerCase() === "glm") {
        var quantity = itemQuantities[property];
        for (var i = 0; i < quantity; i++) {
          coffeeStringArray.push(property);
        }
      }
    }
    return coffeeStringArray;
  }

  const getMiscStringArray = (itemQuantities) => {
    var miscStringArray = [];
    for (let property in itemQuantities) {
      var p = allProducts.find(p => p.code.toLocaleLowerCase() === property.toLowerCase())
      if (p.isMiscellaneous === 1 && (property.toLowerCase() !== "rt" || property.toLowerCase() !== "glm")) {
        var quantity = itemQuantities[property];
        for (var i = 0; i < quantity; i++) {
          miscStringArray.push(property);
        }
      }
    }
    return miscStringArray;
  }

  const handleSave = () => {
    if (
      !proceed &&
      ((showProfaneWarning.firstName !== null &&
        showProfaneWarning.firstName !== "" &&
        !nameObj.firstNameChanged) ||
        (showProfaneWarning.lastName !== null &&
          showProfaneWarning.lastName !== "" &&
          !nameObj.lastNameChanged) ||
        (showProfaneWarning.note !== null &&
          showProfaneWarning.note !== "" &&
          !customerNotes.noteChanged))
    ) {
      setOpenConfirmLabel(false);
      if (addressObj.error && !currentAddressChanged) {
        // alert("Please update address");
        setdialogyDetails({
          title: "Invalid Address",
          text: "Please update address",
          type: "address",
        });
        setdialogyOpen(true);
      } else {
        // alert("Ignore Checks");
        setdialogyDetails({
          title: "Failed Checks",
          text: "Would you like to proceed?",
          type: "checks",
        });
        setdialogyOpen(true);
      }
    } else {
      debugger;
      addUpdatingOrder(props.order.id)
      setdialogyOpen(false);
      // setLoading(true);
      setproceed(false);

      // console.log("addressObj");
      // console.log(addressObj);

      saveEditedOrder({
        id: props.order.id,
        firstName: nameObj.firstName,
        lastName: nameObj.lastName,
        street: addressObj.street,
        suburb: addressObj.suburb,
        city: addressObj.city,
        postCode: addressObj.postCode,
        isPostal: addressObj.isPostal ? 1 : 0,
        country: addressObj.country,
        notes: customerNotes.note,
        deliveryInstructions: deliveryInstructions,
        coffeeTypesQuantityArr: getCoffeeStringArray(itemQuantities),
        miscQuantityArr: getMiscStringArray(itemQuantities),
        validAddress: addressObj.error ? 0 : 1,
        handwrittenNote: {
          ...messageObj,
          handwrittenNoteObj: JSON.stringify(messageObj.handwrittenNoteObj),
        },
        company: company,
        priority: priority === 0 ? 7 : priority,
        makeDefault: makeDefaultShopify,
        changeShipping: changeShippingShopify,
        checkAddress: checkAddress,
        firstNameForbiddenProfaneWords: showProfaneWarning.firstName,
        lastNameForbiddenProfaneWords: showProfaneWarning.lastName,
        preferredCourier: preferredCourier,
        updatePreferredCourierShopify: updatePreferredCourierShopify
      })

      props.handleCloseModal();
    }
  };

  const handleSaveAndRelease = () => {
    addUpdatingOrder(props.order.id)
    saveAndRelease({
      id: props.order.id,
      firstName: nameObj.firstName,
      lastName: nameObj.lastName,
      street: addressObj.street,
      suburb: addressObj.suburb,
      city: addressObj.city,
      postCode: addressObj.postCode,
      isPostal: addressObj.isPostal ? 1 : 0,
      country: addressObj.country,
      notes: customerNotes.note,
      deliveryInstructions: deliveryInstructions,
      coffeeTypesQuantityArr: getCoffeeStringArray(itemQuantities),
      miscQuantityArr: getMiscStringArray(itemQuantities),
      validAddress: addressObj.error ? 0 : 1,
      handwrittenNote: {
        ...messageObj,
        handwrittenNoteObj: JSON.stringify(messageObj.handwrittenNoteObj),
      },
      company: company,
      priority: priority === 0 ? 7 : priority,
      makeDefault: makeDefaultShopify,
      changeShipping: changeShippingShopify,
      checkAddress: checkAddress,
      firstNameForbiddenProfaneWords: showProfaneWarning.firstName,
      lastNameForbiddenProfaneWords: showProfaneWarning.lastName,
      preferredCourier: preferredCourier,
      updatePreferredCourierShopify: updatePreferredCourierShopify
    })
    props.handleCloseModal();
  };

  const handleResend = () => {
    setLoading(true);
    //messageObj.handwrittenNoteObj = JSON.stringify(messageObj.handwrittenNoteObj);
    Axios.post(`Orders/ResendOrder/${props.order.id}`, {
      firstName: nameObj.firstName,
      lastName: nameObj.lastName,
      street: addressObj.street,
      suburb: addressObj.suburb,
      city: addressObj.city,
      postCode: addressObj.postCode,
      country: addressObj.country,
      notes: customerNotes.note,
      deliveryInstructions: deliveryInstructions,
      coffeeTypesQuantityArr: getCoffeeStringArray(itemQuantities),
      miscQuantityArr: getMiscStringArray(itemQuantities),
      validAddress: addressObj.error ? 0 : 1,
      handwrittenNote: {
        ...messageObj,
        handwrittenNoteObj: JSON.stringify(messageObj.handwrittenNoteObj),
      },
      company: company,
      priority: priority === 0 ? 7 : priority,
      preferredCourier: preferredCourier
    })
      .then((res) => {
        // console.log(res);
      })
      .catch((e) => {
        // console.log(e);
      })
      .finally(() => {
        setLoading(false);
        props.handleCloseModal();
      });
  };

  const handleRelease = () => {
    setLoading(true);
    Axios.patch(`Orders/PatchOrderRelease/${props.order.id}`, {
      id: props.order.id,
      status: 1,
    })
      .then((res) => {
        // console.log(res);
      })
      .catch((e) => {
        // console.log(e);
      })
      .finally(() => {
        setLoading(false);
        props.handleCloseModal();
      });
  };

  const handleAddManagedNote = async (isActive) => {
    if (isActive === 1) {
      handleAddDeliveryInstructions(customerNotes.note);
    } else {
      setDeliveryInstructions("");
    }
    await addManagedNote(customerNotes.note, isActive);
  }

  const handleAddDeliveryInstructions = async (customerNote) => {
    let newInstructions = `${getValueOrEmptyString(customerNote)} ${getValueOrEmptyString(deliveryInstructions)}`;
    setDeliveryInstructions(newInstructions);
  }

  //------ Components ---------
  const ConfirmSaveDialog = () => {
    const handleClose = () => {
      setOpenConfirmLabel(false);
    };

    return (
      <div>
        <Dialog
          open={openConfirmLabel}
          keepMounted
          onClose={handleClose}
        >
          <DialogTitle>{"Generate Label?"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`Would you like to generate the label? (This will use the selected address)`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => props.handleCloseModal()}>No</Button>
            <Button onClick={() => {
              /* handleSave() */
              debugger;
              setOpenConfirmLabel(false);
              if (currentAddressChanged || (originalOrder.company !== company) || (originalOrder.preferredCourier !== preferredCourier)) {
                setSaveShopifyDialog(true);
                setMakeDefaultShopify(currentAddressChanged);
                setChangeShippingShopify(currentAddressChanged);
                setUpdatePreferredCourierShopify(currentCourierPreferenceChanged)
              } else {
                handleSave();
              }
            }}>Yes</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  const nzPostAddressDropdown = () => {
    return (
      <div>
        {!rerenderAddressAutocomplete && addresses && (
          <Autocomplete
            disableClearable
            loading={autocompleteLoading}
            fullWidth
            id="nzpost-address-dropdown"
            // options={addresses}
            options={addresses.map((option) => `${option.fullAddress} (${option.isPostal ? "Postal" : "Not Postal"})`)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Search for an address"}
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  // if (e.target.value !== null && e.target.value !== "") {
                  //   return getNZPostAddresses(e.target.value)
                  // } else {
                  //   return ""
                  // }
                  if (e.target.value?.trim()) {
                    //getNZPostAddresses(e.target.value.trim())
                    setSearchTerm(e.target.value.trim())
                  }
                }}
                // onBlur={(e) => {
                //   if (e.target.value !== null && e.target.value !== "") {
                //     return getNZPostAddresses(e.target.value)
                //   } else {
                //     return ""
                //   }
                // }}
                InputLabelProps={{
                  shrink: true,
                }}
                error={addressObj.error}
              />
            )}
            onChange={(event, value) => {
              var newValue = value;
              var isPostalAddress = false;
              if (value.includes(" (Not Postal)")) {
                newValue = value.replace(" (Not Postal)", "");
                isPostalAddress = false;
              } else if (value.includes("(Postal)")) {
                newValue = newValue.replace(" (Postal)", "");
                isPostalAddress = true;
              }
              handleAddressSelection(newValue, isPostalAddress, false);
            }}
          />
        )}

      </div>
    );
  };

  const handleIncrease = (itemCode) => {
    setItemQuantities({
      ...itemQuantities,
      [itemCode]: itemQuantities[itemCode] + 1,
    });
    if (itemCode.toLowerCase() !== "rt" && itemCode.toLowerCase() !== "glm") {
      setTotalItems(totalItems + 1);
    }
  };

  const handleDecrease = (itemCode) => {
    if (itemQuantities[itemCode] > 0) {
      setItemQuantities({
        ...itemQuantities,
        [itemCode]:
          itemQuantities[itemCode] > 0
            ? itemQuantities[itemCode] - 1
            : 0,
      });
      if (props.itemType !== "rt" && itemCode.toLowerCase() !== "glm") {
        setTotalItems(totalItems > 0 ? totalItems - 1 : 0);
      }
    }
  };

  const LoadingBackdrop = (props) => {
    const classes = useStyles();

    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  };

  useEffect(() => {
    if (getOrder) {
      setGetOrder(false);
      Axios.get(`Orders/GetEditOrder/${props.order.id}`)
        .then((res) => {
          let order = res.data;
          console.log("order")
          console.log(order)
          setPreferredCourier(order.preferredCourier)
          setPreviousAddresses(order.previousShippingAddresses)
          setCheckAddress(order.checkAddress)
          setCompany(order.company)
          setDisabledInfo({
            orderNumber: order.orderNumber,
            status: order.status,
            shopifyId: order.shopifyId,
            trackingNumbers: order.trackingIds,
            driveId: order.driveIdsString,
            courierType: order.courierType
          });
          var suburbString = (order.suburb === null || order.suburb === undefined || order.suburb === "" ? "" : `${order.suburb}, `);
          setCurrentAddress(`${order.street}, ${suburbString}${order.city} ${order.postCode}, ${order.country}`);
          setAddressObj({
            street: order.street,
            suburb: order.suburb,
            city: order.city,
            postCode: order.postCode,
            country: order.country,
            error: order.validAddress === 0 ? true : false,
            isPostal: order.isPostal ? 1 : 0
          });
          setNameObj({
            ...nameObj,
            firstName: order.firstName,
            lastName: order.lastName,
          });
          setCustomerNotes({
            ...customerNotes,
            note: order.notes,
          });
          setDeliveryInstructions(order.deliveryInstructions);
          setPriority(order.priority);

          var defaultItemQuantitiesObj = {};
          for (var i = 0; i < allProducts.length; i++) {
            defaultItemQuantitiesObj[allProducts[i].code] = 0;
          }

          for (var j = 0; j < order.coffeeTypesQuantity.length; j++) {
            if (order.coffeeTypesQuantity[j].key !== "") {
              defaultItemQuantitiesObj[order.coffeeTypesQuantity[j].key] = order.coffeeTypesQuantity[j].val;
            }
          }
          for (var k = 0; k < order.miscQuantity.length; k++) {
            if (order.miscQuantity[k].key !== "") {
              defaultItemQuantitiesObj[order.miscQuantity[k].key] = order.miscQuantity[k].val;
            }
          }

          setItemQuantities(defaultItemQuantitiesObj)

          let newTotal = 0;
          for (let property in defaultItemQuantitiesObj) {
            if (property !== "rt" && property !== "glm") {
              newTotal += defaultItemQuantitiesObj[property];
            }
          }
          setTotalItems(newTotal);
          setShowProfaneWarning({
            firstName: order.firstNameForbiddenProfaneWords,
            lastName: order.lastNameForbiddenProfaneWords,
            note: order.noteProfanewords,
          });
          setMessageObj({
            ...order.handwrittenNote,
            handwrittenNoteObj: JSON.parse(
              order.handwrittenNote.handwrittenNoteObj
            ),
          });
          setOriginalAddress(`${order.street}, ${suburbString}${order.city} ${order.postCode}, ${order.country}`)
          debugger;
          setOriginalOrder({
            address: {
              street: order.street,
              suburb: order.suburb,
              city: order.city,
              postCode: order.postCode,
              country: order.country,
              error: order.validAddress === 0 ? true : false,
              isPostal: order.isPostal
            },
            deliveryInstructions: order.deliveryInstructions,
            itemQuantities: defaultItemQuantitiesObj,//tempItemQuantities,
            nameObj: {
              firstName: order.firstName,
              lastName: order.lastName,
            },
            messageObj: {
              ...order.handwrittenNote,
              handwrittenNoteObj: JSON.parse(
                order.handwrittenNote.handwrittenNoteObj
              ),
            },
            company: order.company,
            preferredCourier: order.preferredCourier
          });
          if (props.activeTab) {
            setTabValue(props.activeTab);
          }
        })
        .catch((e) => { });
    }
  }, [getOrder]);

  const [displayCard, setdisplayCard] = useState(false);

  const handleChange = (e, field) => {
    switch (field) {
      case "firstName":
        if (nameObj.firstNameChanged) {
          setNameObj({
            ...nameObj,
            firstName: e.target.value,
          });
        } else {
          setNameObj({
            ...nameObj,
            firstName: e.target.value,
            firstNameChanged: true,
          });
        }
        break;
      case "lastName":
        if (nameObj.lastNameChanged) {
          setNameObj({
            ...nameObj,
            lastName: e.target.value,
          });
        } else {
          setNameObj({
            ...nameObj,
            lastName: e.target.value,
            lastNameChanged: true,
          });
        }
        break;
      case "note":
        if (customerNotes.noteChanged) {
          setCustomerNotes({
            ...customerNotes,
            note: e.target.value,
          });
        } else {
          setCustomerNotes({
            note: e.target.value,
            noteChanged: true,
          });
        }
        break;
      default:
        break;
    }
  };

  const [dialogyOpen, setdialogyOpen] = useState(false);

  useEffect(() => {
    if (proceed) {
      handleSave();
    }
  }, [proceed]);

  useEffect(() => {
    if (!notes) {
      getAllNotes();
    }
  }, [notes]);

  useEffect(() => {
    console.log("priority")
    console.log(priority)
  }, [priority]);

  useEffect(() => {
    var currentOrder = {
      address: {
        street: addressObj.street,
        suburb: addressObj.suburb,
        city: addressObj.city,
        postCode: addressObj.postCode,
        country: addressObj.country,
        error: addressObj.validAddress === 0 ? true : false,
      },
      deliveryInstructions: deliveryInstructions,
      itemQuantities: itemQuantities,
      nameObj: {
        firstName: nameObj.firstName,
        lastName: nameObj.lastName,
      },
      messageObj: messageObj,
    };
    if (originalOrder.nameObj.firstName !== undefined) {
      if (_.isEqual(originalOrder, currentOrder)) {
        if (orderChanged) {
          setOrderChanged(false);
        }
      } else {
        if (!orderChanged) {
          setOrderChanged(true);
        }
      }
    }
    // console.log(addressObj);
  }, [nameObj, addressObj, deliveryInstructions, itemQuantities, messageObj]);

  useEffect(() => {
    // console.log(messageObj);
  }, [messageObj]);

  const createLines = (value) => {
    const width = document.getElementsByClassName("message-body-id-edit");

    let inches;
    let inchesArr = [];
    let linePosition = 1.75;
    let lines = [];

    for (let i = 0; i < width.length; i++) {
      // inches = width.map((i) => i.getBoundingClientRect().width);
      inchesArr.push(
        (width[i].getBoundingClientRect().width / 96 / 2 + 2).toFixed(2) - 1
      );
    }
    lines = value.split("\n").map((m, index) => {
      if (index > 0) {
        linePosition += 0.25;
      }
      return {
        lx: inchesArr[index],
        ly: linePosition,
        text: m.replace("’", "'"),
      };
    });

    setMessageObj({
      ...messageObj,
      message: value,
      handwrittenNoteObj: {
        ...messageObj.handwrittenNoteObj,
        body: {
          lines: lines,
          fontSize: 30,
        },
        signature: {
          ...messageObj.handwrittenNoteObj.signature,
          lx: 1.8,
          ly: lines[lines.length - 1].ly + 0.5,
          fontSize: 30,
        }
      },
    });
  };

  return (
    <Modal
      open={props.modalState}
      disableBackdropClick
      onClose={() => {
        if (disabledInfo.driveId !== null) {
          handleSave();
        }
      }}
      id="edit-order-modal"
    >
      <div className="edit-order-modal">
        {showChangeStatusModal && (
          <StatusModal
            modalState={showChangeStatusModal}
            closeModal={() => setShowChangeStatusModal(false)}
            status={disabledInfo.status}
            id={props.order.id}
            refreshOrder={() => setGetOrder(true)}
          />
        )}
        <Dialogy
          open={dialogyOpen}
          handleClose={() => setdialogyOpen(false)}
          details={dialogyDetails}
          proceed={() => {
            setproceed(true);
          }}
        />
        {saveToShopifyDialog && (
          <SaveToShopifyDialog
            open={saveToShopifyDialog}
            handleClose={() => {
              setMakeDefaultShopify(false);
              setChangeShippingShopify(false);
              setUpdatePreferredCourierShopify(false);
              setSaveShopifyDialog(false);
              setSaveAndReleaseClicked(false);
              setCloseIconClicked(false);
            }}
            handleContinue={() => {
              if (saveAndReleaseClicked) {
                handleSaveAndRelease();
              } else if (closeIconClicked) {
                handleSave();
              }
              setSaveShopifyDialog(false);
            }}
            makeDefaultShopify={makeDefaultShopify}
            setMakeDefaultShopify={setMakeDefaultShopify}
            changeShippingShopify={changeShippingShopify}
            setChangeShippingShopify={setChangeShippingShopify}
            updatePreferredCourierShopify={updatePreferredCourierShopify}
            setUpdatePreferredCourierShopify={setUpdatePreferredCourierShopify}
          />
        )}
        {openConfirmLabel && (
          <ConfirmSaveDialog />
        )}
        {loading && <LoadingBackdrop />}
        <AppBar position="static" style={{ background: "#27293d" }}>
          <Toolbar variant="dense" style={{ paddingRight: 0 }}>
            <span style={{ display: "flex" }}>
              <Tabs
                value={tabValue}
                onChange={(event, newValue) => {
                  setTabValue(newValue);
                }}
                variant="scrollable"
                scrollButtons="auto"
                id="edit-order-tabs-bar"
              >
                <Tab wrapped label="Delivery Details" />
                <Tab wrapped label="Written Note" />
                <Tab wrapped label="Order Items" />
                <Tab wrapped label="Label" />
                <Tab wrapped label="Address History" />
                <Tab wrapped label="Error Log"/>
              </Tabs>
            </span>
            <Typography
              style={{
                fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                width: "100%",
                textAlign: "center",
              }}
              variant={"subtitle1"}
              component={"span"}
            >
              {`SC${disabledInfo.orderNumber}`}
            </Typography>
            <IconButton
              onClick={() => {
                setCloseIconClicked(true);
                if (disabledInfo.driveId !== null) {
                  // handleSave();
                  if (currentAddressChanged || (originalOrder.company !== company) || (originalOrder.preferredCourier !== preferredCourier)) {
                    debugger;
                    setMakeDefaultShopify(currentAddressChanged);
                    setChangeShippingShopify(currentAddressChanged);
                    setUpdatePreferredCourierShopify(currentCourierPreferenceChanged)
                    setSaveShopifyDialog(true);
                  } else {
                    handleSave();
                  }
                }
                else {
                  setOpenConfirmLabel(true);
                }
              }}
              style={{
                color: "white",
                display: "block",
                margin: "auto 0px auto auto",
              }}
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        {!getOrder && (
          <div className={`${classes.paper} edit-order-paper`}>
            <TabPanel value={tabValue} index={0}>
              <Grid container spacing={2}>
                <Grid item xs={2} md={2} lg={2}>
                  <Typography variant={"caption"}>Recipient</Typography>
                </Grid>
                <Grid item xs={4} md={4} lg={4}></Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Typography
                      className="hyperlink"
                      variant="caption"
                      component="div"
                      onClick={() =>
                        window.open(
                          `https://simply-coffee-capsules.myshopify.com/admin/orders/${disabledInfo.shopifyId}`
                        )
                      }
                    >
                      Go to Shopify
                    </Typography>
                    {disabledInfo.trackingNumbers !== null &&
                      disabledInfo.trackingNumbers !== undefined &&
                      disabledInfo.trackingNumbers.length > 0 &&
                      disabledInfo.trackingNumbers.map((num, index) => {
                        return (
                          <Typography
                            className="hyperlink"
                            variant="caption"
                            component="div"
                            onClick={() => {
                              var url = disabledInfo.courierType === 1 ?
                                `https://www.nzpost.co.nz/tools/tracking/item/${num}`
                                : `https://www.aramex.co.nz/tools/track/?l=${num}`;
                              window.open(url)
                            }
                            }
                          >
                            {`Track Package ${index + 1}`}
                          </Typography>
                        );
                      })}
                  </div>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <TextField
                    error={
                      showProfaneWarning.firstName && !nameObj.firstNameChanged
                    }
                    helperText={
                      showProfaneWarning.firstName &&
                      !nameObj.firstNameChanged &&
                      `Profanity/Forbidden detected - ${showProfaneWarning.firstName}`
                    }
                    className={
                      (!showProfaneWarning.firstName ||
                        nameObj.firstNameChanged) &&
                      "green-textfield"
                    }
                    fullWidth
                    label="First Name"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={nameObj.firstName}
                    onChange={(e) => {
                      handleChange(e, "firstName");
                    }}
                    spellCheck
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <TextField
                    error={
                      showProfaneWarning.lastName && !nameObj.lastNameChanged
                    }
                    helperText={
                      showProfaneWarning.lastName &&
                      !nameObj.lastNameChanged &&
                      `Profanity/Forbidden detected - ${showProfaneWarning.lastName}`
                    }
                    className={
                      (!showProfaneWarning.lastName ||
                        nameObj.lastNameChanged) &&
                      "green-textfield"
                    }
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={nameObj.lastName}
                    onChange={(e) => {
                      handleChange(e, "lastName");
                    }}
                    spellCheck
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <TextField
                    fullWidth
                    label="Company"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    spellCheck
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  {checkAddress === 1 && (
                    <React.Fragment>
                      <Typography variant={"caption"}>Warning Previous Addresses:</Typography>&emsp;<br />
                    </React.Fragment>
                  )}
                  {checkAddress === 1 && previousAddresses.map((sa, index) => {
                    return (
                      <Typography variant={"caption"} onClick={(e) => {
                        setAddressObj({
                          street: sa.address1,
                          suburb: sa.address2,
                          city: sa.city,
                          postCode: sa.zip,
                          country: "New Zealand",
                          error: false,
                          isPostal: sa.isPostal
                        });
                        var suburbString = (sa.address2 === null || sa.address2 === undefined || sa.address2 === "" ? "" : `${sa.address2}, `);
                        setCurrentAddress(`${sa.address1}, ${suburbString}${sa.city} ${sa.zip}, New Zealand`);
                        setCurrentAddressChanged(true);
                      }}>
                        <b>{`(${index + 1})`}</b>{` ${sa.address1}, ${sa.address2}, ${sa.city} ${sa.zip}`}&emsp;
                        {previousAddresses.length === index + 1 && <span><br /><br /></span>}
                      </Typography>)
                  })}
                  {checkAddress === 1 && (
                    <React.Fragment>
                      <Button className="ignore-address-button" color="primary" variant="contained" onClick={() => {
                        setCheckAddress(0);
                        setAddressObj({
                          ...addressObj,
                          error: false
                        })
                        //setCurrentAddressChanged(true);
                      }}>
                        Ignore Address Warning
                      </Button><br />
                    </React.Fragment>
                  )}
                  <Typography variant={"caption"}>Delivery Address</Typography>
                </Grid>
                {/* <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      fullWidth
                      label={`Current Address ${originalOrder.address.isPostal === null ? "" : (originalOrder.address.isPostal === 1 ? "(Postal)" : "(Not Postal)")}`}
                      variant="outlined"
                      value={originalAddress}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={() => { }}
                    />
                </Grid> */}
                {/* <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    fullWidth
                    label={`Selected Address ${addressObj.isPostal === null ? "" : (addressObj.isPostal === 1 ? "(Postal)" : "(Not Postal)")} - Address will be used for label generation`}
                    variant="outlined"
                    value={currentAddress}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={addressObj.error}
                    className={!addressObj.error && "green-textfield"}
                  />
                </Grid> */}
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    fullWidth
                    label={`Selected Address (Address will be used for label generation)`}
                    variant="outlined"
                    value={currentAddress}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={addressObj.error}
                    className={!addressObj.error && "green-textfield"}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  {nzPostAddressDropdown()}
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    error={
                      (showProfaneWarning.note && !customerNotes.noteChanged) ||
                      ((customerNotes.note !== null && customerNotes.note !== undefined && customerNotes.note.trim() !== "") && (deliveryInstructions === null || deliveryInstructions === ""))
                    }
                    helperText={
                      showProfaneWarning.note &&
                      !customerNotes.noteChanged &&
                      `Profanity detected - ${showProfaneWarning.note}`
                    }
                    className={
                      (!showProfaneWarning.note || nameObj.noteChanged) &&
                      (customerNotes.note === null || (deliveryInstructions !== null && deliveryInstructions !== "")) &&
                      "green-textfield"
                    }
                    label="Customer notes"
                    fullWidth
                    variant="outlined"
                    rowsMax={1}
                    rows={1}
                    multiline
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      handleChange(e, "note");
                    }}
                    value={customerNotes.note}
                    spellCheck
                  />
                  <div
                    style={{
                      display: "flex",
                      margin: "5px 0px 5px auto",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      style={{ display: "block" }}
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        handleAddDeliveryInstructions(customerNotes.note)
                      }
                      size="small"
                    >
                      Add to delivery instructions
                    </Button>
                    <ManageNoteButtonGroup addManagedNote={handleAddManagedNote} />
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  label="Delivery Instructions"
                  fullWidth
                  variant="outlined"
                  rowsMax={1}
                  rows={1}
                  multiline
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setDeliveryInstructions(e.target.value);
                  }}
                  value={deliveryInstructions}
                  spellCheck
                />
              </Grid>
              {(disabledInfo.status === 0 || disabledInfo.status === 9) &&
                !addressObj.error &&
                orderChanged && (
                  <Grid item xs={12} md={12} lg={12}>
                    <Button
                      size="small"
                      style={{
                        display: "block",
                        margin: "5px 0px 5px auto",
                        backgroundColor: "green",
                        color: "white",
                      }}
                      variant="contained"
                      onClick={() => {
                        //handleSaveAndRelease();
                        setSaveAndReleaseClicked(true);
                        setMakeDefaultShopify(currentAddressChanged);
                        setChangeShippingShopify(currentAddressChanged);
                        setUpdatePreferredCourierShopify(currentCourierPreferenceChanged)
                        setSaveShopifyDialog(true);
                      }}
                    >
                      {"Save & Release"}
                    </Button>
                  </Grid>
                )}
              {!orderChanged &&
                disabledInfo.status !== 7 &&
                disabledInfo.status !== 4 &&
                (props.order.courierOrder.length > 0
                  ? props.order.courierOrder.driveId !== null
                  : false) && (
                  <Grid item xs={12} md={12} lg={12}>
                    <Button
                      size="small"
                      style={{
                        display: "block",
                        margin: "5px 0px 5px auto",
                        backgroundColor: "green",
                        color: "white",
                      }}
                      color="primary"
                      onClick={() => handleRelease()}
                      variant="contained"
                    >
                      Release
                    </Button>
                  </Grid>
                )}
              {disabledInfo.status === 7 && (
                <Grid item xs={12} md={12} lg={12}>
                  <Button
                    size="small"
                    style={{
                      display: "block",
                      margin: "5px 0px 5px auto",
                      backgroundColor: "purple",
                      color: "white",
                    }}
                    color="primary"
                    onClick={() => handleResend()}
                    variant="contained"
                  >
                    Unfulfill
                  </Button>
                </Grid>
              )}
              {/* <Grid item xs={6} md={6} lg={6}> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 10
                }}
              >
                <TextField
                  // fullWidth
                  label="Priority"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={priority.toString()}
                  onChange={(e) => {
                    setPriority(parseInt(e.target.value))
                  }}
                  type="number"
                />
                <FormControl className={classes.formControl}>
                  <InputLabel shrink>
                    Preferred Courier
                  </InputLabel>
                  <Select
                    displayEmpty
                    value={preferredCourier}
                    onChange={(e) => {
                      debugger;
                      setPreferredCourier(e.target.value)
                      setCurrentCourierPreferenceChanged(true);
                    }}
                  >
                    <MenuItem value={null}>
                      <em>No Preference</em>
                    </MenuItem>
                    <MenuItem value={"NZ POST"}>NZPost</MenuItem>
                    <MenuItem value={"ARAMEX"}>Aramex</MenuItem>
                    {/* <MenuItem value={"AS FAST AS POSSIBLE"}>As fast as possible</MenuItem> */}
                  </Select>
                </FormControl>
                <Button
                  style={{
                    margin: "5px 0px 5px auto",
                    backgroundColor: "purple",
                    color: "white",
                  }}
                  color="primary"
                  variant="contained"
                  onClick={() => setShowChangeStatusModal(true)}
                  size="small"
                >
                  Manually set status
                </Button>
              </div>
              {disabledInfo.driveId ? "" :
                <div>
                  <span style={{ color: "red" }}>{`Error generating ${getCourierName(disabledInfo.courierType)} label`}</span>
                </div>
              }
              {/* </Grid> */}
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    label="Greeting"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setMessageObj({
                        ...messageObj,
                        greeting: e.target.value,
                        handwrittenNoteObj: {
                          ...messageObj.handwrittenNoteObj,
                          greeting: {
                            lx: 1.55,
                            ly: 1.25,
                            text: e.target.value,
                            fontSize: 30,
                          },
                        },
                      });
                    }}
                    value={messageObj.greeting}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    label="Message"
                    fullWidth
                    variant="outlined"
                    multiline={true}
                    rowsMax={5}
                    rows={5}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      createLines(e.target.value);
                    }}
                    value={messageObj.message}
                    spellCheck
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    label="Signed by"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setMessageObj({
                        ...messageObj,
                        signedBy: e.target.value,
                        handwrittenNoteObj: {
                          ...messageObj.handwrittenNoteObj,
                          signature: {
                            ...messageObj.handwrittenNoteObj.signature,
                            text: e.target.value,
                          },
                        },
                      });
                    }}
                    value={messageObj.signedBy}
                  />
                </Grid>
                <Grid className="card-grid">
                  <div class="handwritten-card-container">
                    <img
                      src={card}
                      alt="Snow"
                      width="auto"
                      height="auto"
                      onLoad={() => setdisplayCard(true)}
                    />
                    {!displayCard && <CircularProgress />}
                    {displayCard && (
                      <div class="card-greeting-edit">
                        <Typography className="robot-font-edit">
                          {messageObj.handwrittenNoteObj.greeting.text}
                        </Typography>
                        <Typography className="card-message robot-font-edit">
                          {messageObj.handwrittenNoteObj.body.lines.map((m) => (
                            <div className="message-body-id-edit">{m.text}</div>
                          ))}
                        </Typography>
                        <Typography className="card-signed robot-font-edit">
                          {messageObj.handwrittenNoteObj.signature.text}
                        </Typography>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              {/* allProducts */}
              <Grid container spacing={4}>
                {products.map(p => (
                  <Grid item xs={6} md={4} lg={3} key={`${p.code}-count`}>
                    <TextField
                      label={p.code}
                      value={itemQuantities[p.code]}
                      inputProps={{
                        className: `edit-order-item-text`,
                        style: {
                          color: itemQuantities[p.code] > 0 ? "green" : "",
                        }
                      }}
                      InputLabelProps={{
                        shrink: true,
                        className: `edit-flavour-label`
                      }}
                      type="number"
                      onChange={(e) => {
                        var s = e.target.value.replace(/^0+/, '');
                        var newItemQuantities = {};
                        newItemQuantities = Object.assign(itemQuantities, newItemQuantities)
                        newItemQuantities = {
                          ...newItemQuantities,
                          [p.code]: parseInt(s !== "" ? s : "0")
                        }
                        setItemQuantities(newItemQuantities);
                      }}
                      variant="outlined"
                    />
                  </Grid>
                ))}
                <Grid item xs={12} md={12} lg={12}>
                  <Typography className="edit-order-item-total-header">
                    Total
                  </Typography>
                  <div className="edit-order-item-total-text">{totalItems}</div>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              {disabledInfo.driveId ?
                <LabelTabPanel
                  driveId={disabledInfo.driveId}
                  orderId={disabledInfo.shopifyId}
                  handwrittenNoteFk={messageObj.id}
                /> : <div>There is no label associated with this order</div>}
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
              <AddressHistoryTabPanel
                shippingAddressHistory={props.order?.shippingAddressHistory}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={5}>
              <ErrorLogTabPanel orderId = {props.order.id}/>
            </TabPanel>
          </div>
        )}
      </div >
    </Modal >
  );
};

const mapStateToProps = (state) => ({
  orders: state.order.orders,
  notes: state.note.notes,
  allProducts: state.products.allProducts,
  products: state.products.products
});

export default connect(mapStateToProps, {
  getAllNotes,
  addManagedNote,
  addUpdatingOrder,
  saveEditedOrder,
  saveAndRelease
})(EditStoreOrderPopup);
