import React, { useEffect, useState } from "react"
import { withStyles } from "@material-ui/core/styles";
import {
    Button,
    Grid,
    TextField,
    Typography,
    IconButton
} from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import {
    AddCircle as AddCircleIcon,
    Delete as DeleteIcon
} from '@material-ui/icons';

const WhiteTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "white",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "white",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "white",
            },
            "&:hover fieldset": {
                borderColor: "white",
            },
            "&.Mui-focused fieldset": {
                borderColor: "white",
            },
        },
    },
})(TextField);

const EditEmployeeeSection = (props) => {
    let {
        selectedCompany,
        updateSelectedCompany,
        updateEmployeeArr
    } = props;

    const [employeeList, setEmployeeList] = useState([]);

    const updateEmployee = (index, property, newValue) => {
        var newEmployeeList = [...employeeList];

        newEmployeeList[index] = {
            ...newEmployeeList[index],
            [`${property}`]: newValue
        }
        updateEmployeeArr(newEmployeeList);
    }

    const addNewEmployee = () => {
        var newEmployeeList = [...employeeList];

        newEmployeeList.push({
            // id: null,
            firstName: "",
            lastName: "",
            email: "",
            companyFk: selectedCompany.id,
            phoneNumber: ""
        })
        updateEmployeeArr(newEmployeeList);
    }

    useEffect(() => {
        if (typeof selectedCompany.employee !== "undefined") {
            setEmployeeList(selectedCompany.employee)
        }
    }, [selectedCompany])

    return (
        <React.Fragment>
            <Grid item xs={12} md={12} lg={12}>
                <Typography variant={"h6"} align="center">
                    Employees
                </Typography>
            </Grid>
            {employeeList.map((e, i) => {
                return (
                    <React.Fragment>
                        <Grid item xs={3} md={3} lg={3}>
                            <WhiteTextField
                                key={`first-name-textbox-${i}`}
                                label="First Name"
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {
                                    updateEmployee(i, "firstName", e.target.value)
                                }}
                                value={e.firstName}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={3} md={3} lg={3}>
                            <WhiteTextField
                                key={`last-name-textbox-${i}`}
                                label="Last Name"
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {
                                    updateEmployee(i, "lastName", e.target.value)
                                }}
                                value={e.lastName}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={3} md={3} lg={3}>
                            <WhiteTextField
                                key={`email-textbox-${i}`}
                                label="Email"
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {
                                    updateEmployee(i, "email", e.target.value)
                                }}
                                value={e.email}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={3} md={3} lg={3}>
                            <WhiteTextField
                                key={`phone-textbox-${i}`}
                                label="Phone Number"
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {
                                    updateEmployee(i, "phoneNumber", e.target.value)
                                }}
                                value={e.phoneNumber}
                                size="small"
                            />
                        </Grid>
                    </React.Fragment>
                )
            })}
            <Grid item xs={12} md={12} lg={12}>
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => addNewEmployee()}
                >
                    Add Employee
                </Button>
            </Grid>
        </React.Fragment>
    )
}

export default EditEmployeeeSection;

/*
    const [person, setPerson] = useState({
        id: null,
        firstName: "",
        lastName: "",
        phone: ""
    });

    const handleEmployeeChange = (event, newValue, property) => {
        if (newValue && newValue.inputValue) {
            setPerson({
                ...person,
                [`${property}`]: newValue.inputValue,
            });
        } else {
            setPerson({
                firstName: newValue.firstName,
                lastName: newValue.lastName,
                email: newValue.email,
                phoneNumber: newValue.phoneNumber,
            });
        }
    };

    const filterAutoComplete = createFilterOptions();

    const autoCompleteFilterOptions = (options, params) => {
        const filtered = filterAutoComplete(options, params);
        // Suggest the creation of a new value
        if (params.inputValue !== "" || filtered.length == 0) {
            filtered.push({
                inputValue: params.inputValue,
                title: `Add "${params.inputValue}"`,
            });
        }
        return filtered;
    };

    const getAutoCompleteOptionlabel = (option, type) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
            return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue === null) {
            return "";
        }
        if (option.inputValue) {
            return option.title;
        }
        return `${option.firstName} ${option.lastName}`;
    };
*/