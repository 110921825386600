import { Grid, Typography } from "@material-ui/core";
import React from "react";

const ThankYou = (props) => {
    // console.log("Thanks")
    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={12}>
                <Typography variant="h1">Thank You!</Typography>
            </Grid>
        </Grid>
    )
}

export default ThankYou;