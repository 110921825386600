import React, { useEffect, useRef, useState } from "react";
import _, { over } from "lodash";
import { connect } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormGroup,
  InputLabel,
  DialogActions,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Input,
  Fab,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
  Button,
} from "@material-ui/core";
import { Delete, Add, DragIndicator } from "@material-ui/icons";
import {
  getInfinityNotes,
  onEditField,
  onUpdateField,
  removeInfinityNote,
  swapAndUpdate,
} from "../../reduxActions/infinityNotes";
import "./control.scss";
import card from "../../img/note.PNG";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import { scrollToBottom } from "../Utils/helperFunctions";
import axios from "axios";
import { getNowDateString } from "../Utils/dateTime";
import Snacky from "../Shared/Snacky";
import { setSnackBarProps } from "../../reduxActions/snackbar";

const InfinityNotes = (props) => {
  const {
    infinityNotes,
    getInfinityNotes,
    onEditField,
    onUpdateField,
    removeInfinityNote,
    swapAndUpdate,
    infinityNotesLoading,
    snackbar,
    setSnackBarProps
  } = props;

  const [loading, setLoading] = useState(false);

  const [displayCard, setdisplayCard] = useState(false);
  const [infinityNoteField, setInfinityNoteField] = useState({
    message: "",
    index: -1,
  });
  const [creatingNewInfinityNote, setCreatingNewInfinityNote] = useState(false);

  const [infinityNotesDialogOpen, setInfinityNotesDialogOpen] = useState(false);
  const [infinityNotesAmount, setInfinityNotesAmount] = useState(0);
  const [infinityNotesLocation, setInfinityNoteLocation] = useState("ROBOT");
  const [loadingSendInfinityNotes, setLoadingSendInfinityNotes] =
    useState(false);

  const sendInfinityNotes = async () => {
    if (infinityNotesAmount > 0) {
      setLoadingSendInfinityNotes(true);
      await axios.post(
        `Orders/InfinityNotes/${infinityNotesAmount}/${infinityNotesLocation}`,
        {
          messageObj: JSON.stringify(
            infinityNotes[infinityNoteIndex].messageObj
          ),
        }
      );
      setSnackBarProps("success", "Sent to be written", true);
      setLoadingSendInfinityNotes(false);
    }
    setInfinityNotesDialogOpen(false);
  };

  const cancelInfinityNotes = async () => {
    setLoadingSendInfinityNotes(true);
    const res = await axios.delete(`Orders/InfinityNotes`);
    if (res.status === 200) {
      setSnackBarProps("success", "All infinity notes cleard", true);
    }
    else {
      setSnackBarProps("error", "Failed to clear infinity notes", true);
    
    }
    setLoadingSendInfinityNotes(false);
  };

  const prevInfinityNotes = usePrevious(infinityNotes);

  // Hook
  function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();

    // Store current value in ref
    useEffect(() => {
      ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
  }

  useEffect(() => {
    if (!infinityNotes) {
      getInfinityNotes(prevInfinityNotes);
    }
  }, []);

  useEffect(() => {
    if (infinityNotes) {
      setInfinityNoteField({
        index: 0,
        message: infinityNotes[0].message,
      });
    }
  }, []);

  const createNewInfinityNote = (m, infinityNotesLength, id) => {
    setCreatingNewInfinityNote(true);
    axios
      .post(`InfinityNotes/PostInfinityNote`, {
        id: id,
        greeting: "Hi",
        message: "Enjoy your coffee!",
        signedBy: "Patrick",
        messageObj: JSON.stringify({
          body: {
            lines: [
              {
                lx: 2.05,
                ly: 1.75,
                text: "Enjoy your coffee!",
              },
            ],
            fontSize: 30,
          },
          message: {
            rx: 7.1,
            ry: 2.95,
          },
          greeting: {
            lx: 1.55,
            ly: 1.25,
            text: "Hi {{name}}",
            fontSize: 30,
          },
          signature: {
            lx: 1.8,
            ly: 2.25,
            text: "Patrick",
            fontSize: 30,
          },
        }),
        end: getNowDateString(),
        orderOnScreen: infinityNotesLength,
      })
      .then(() => {
        getInfinityNotes();
        setInfinityNoteField({
          index: infinityNotesLength,
          message: infinityNotes[infinityNotesLength].message,
        });
      })
      .catch((e) => {})
      .finally(() => {
        setCreatingNewInfinityNote(false);
      });
  };

  const handleDragStart = () => {
    let rowSize = 0;
    let rows = document.getElementsByClassName("table-rows-els");

    if (rows.length > 0) {
      for (let i = 0; i < rows.length; i++) {
        rowSize += rows[i].offsetHeight;
      }
    }
    let table = document.getElementsByClassName("messaging-table-container")[0];
    if (table) {
      table.style.height = `${rowSize + 40}px`;
    }
  };

  const handleDragEnd = (m) => {
    swapAndUpdate(m.destination.index, m.source.index, infinityNotes);
    let table = document.getElementsByClassName("messaging-table-container")[0];
    if (table) {
      table.style.height = "auto";
    }
  };

  useEffect(() => {
    setCreatingNewInfinityNote(false);
  }, [infinityNotes]);

  const [infinityNoteIndex, setinfinityNoteIndex] = useState();

  const handleUpdate = (infinityNote, field) => {
    let m = { ...infinityNote };
    m.messageObj = JSON.stringify(m.messageObj);
    onUpdateField(m, field);
    setInfinityNoteFieldUpdated(true);
  };

  const [updateInfinityNoteStore, setUpdateInfinityNoteStore] = useState();

  const handleEditInfinityNote = (value, index) => {
    setInfinityNoteField({ message: value, index: index });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setUpdateInfinityNoteStore(true);
      setInfinityNoteFieldUpdated(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [infinityNoteField]);

  useEffect(() => {
    if (updateInfinityNoteStore) {
      onEditField({
        index: infinityNoteField.index,
        value: infinityNoteField.message,
        field: "message",
        width: document.getElementsByClassName("message-body-id"),
      });
      setUpdateInfinityNoteStore(false);
    }
  }, [updateInfinityNoteStore]);

  const [infinityNoteFieldUpdated, setInfinityNoteFieldUpdated] =
    useState(false);

  useEffect(() => {
    if (
      infinityNoteField.message &&
      document.getElementsByClassName("message-body-id").length &&
      !infinityNoteFieldUpdated
    ) {
      onEditField({
        index: infinityNoteField.index,
        value: infinityNoteField.message,
        field: "message",
        width: document.getElementsByClassName("message-body-id"),
      });
      setInfinityNoteFieldUpdated(true);
    }
  });

  if (infinityNotes) {
    return (
      <div>
        <Snacky snackprops={snackbar} setSnackBarProps={setSnackBarProps} />
        <div className="messaging-div">
          <div
            style={{ width: "100%", display: "flex", justifyContent: "end" }}
          >
            <Button
              className="infinity-note-button"
              color="secondary"
              variant="contained"
              onClick={() => cancelInfinityNotes()}
            >
              Remove Infinity Notes In Queue
            </Button>
          </div>

          <Paper className="message-table-paper">
            <TableContainer
              className="message-table-container"
              id="message-table-body"
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow style={{ height: "40px" }}>
                    <TableCell
                      align={"center"}
                      style={{ width: "3%" }}
                    ></TableCell>
                    <TableCell align={"center"} style={{ width: "5%" }}>
                      Order
                    </TableCell>
                    <TableCell align={"center"} style={{ width: "7%" }}>
                      Greeting
                    </TableCell>
                    <TableCell align={"center"} style={{ width: "62%" }}>
                      Message
                    </TableCell>
                    <TableCell align={"center"} style={{ width: "10%" }}>
                      Signed By
                    </TableCell>
                    <TableCell style={{ width: "3%" }}></TableCell>
                  </TableRow>
                </TableHead>
                <DragDropContext
                  onDragEnd={handleDragEnd}
                  onDragStart={handleDragStart}
                >
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <TableBody ref={provided.innerRef}>
                        {infinityNotes.map((m, index) => {
                          return (
                            <Draggable
                              key={`${m.id}-draggable`}
                              draggableId={`${m.id}-draggable-id`}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <TableRow
                                  className="table-rows-els"
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={`${m.id}-table-row`}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <TableCell
                                    className="messaging-body-cell"
                                    style={{ width: "3%" }}
                                  >
                                    <DragIndicator
                                      fontSize="large"
                                      style={{
                                        padding: 0,
                                        color: "#b3b3b3",
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    className="messaging-body-cell"
                                    align="center"
                                    style={{ width: "5%" }}
                                  >
                                    {m.orderOnScreen}
                                  </TableCell>
                                  <TableCell
                                    className="messaging-body-cell"
                                    style={{ width: "7%" }}
                                    onClick={() => setinfinityNoteIndex(index)}
                                  >
                                    <Input
                                      value={m.greeting}
                                      onBlur={(e) =>
                                        handleUpdate(m, "greeting")
                                      }
                                      onChange={(e) => {
                                        onEditField({
                                          index: index,
                                          value: e.target.value,
                                          field: "greeting",
                                          width: document
                                            .getElementById(
                                              "message-greeting-id"
                                            )
                                            .getBoundingClientRect().width,
                                        });
                                      }}
                                      fullWidth={true}
                                      className="messaging-input"
                                    />
                                  </TableCell>
                                  <TableCell
                                    className="messaging-body-cell"
                                    style={{ width: "57%" }}
                                    onClick={() => {
                                      setinfinityNoteIndex(index);
                                    }}
                                  >
                                    <Input
                                      value={
                                        m.message &&
                                        infinityNoteField.index !== index
                                          ? m.message
                                          : infinityNoteField.message
                                      }
                                      onBlur={(e) => handleUpdate(m, "message")}
                                      onChange={(e) => {
                                        handleEditInfinityNote(
                                          e.target.value,
                                          index
                                        );
                                      }}
                                      fullWidth={true}
                                      className="messaging-input"
                                      multiline={true}
                                    />
                                  </TableCell>
                                  <TableCell
                                    className="messaging-body-cell"
                                    style={{ width: "10%" }}
                                    onClick={() => setinfinityNoteIndex(index)}
                                  >
                                    <Input
                                      value={m.signedBy}
                                      onBlur={(e) =>
                                        handleUpdate(m, "signedBy")
                                      }
                                      onChange={(e) => {
                                        onEditField({
                                          index: index,
                                          value: e.target.value,
                                          field: "signedBy",
                                          width: document
                                            .getElementById(
                                              "message-signature-id"
                                            )
                                            .getBoundingClientRect().width,
                                        });
                                      }}
                                      fullWidth={true}
                                      className="messaging-input"
                                    />
                                  </TableCell>
                                  <TableCell
                                    className="messaging-body-cell-delete"
                                    style={{ width: "3%" }}
                                  >
                                    {m.start && (
                                      <Delete
                                        className="messaging-delete-icon"
                                        onClick={() => {
                                          setinfinityNoteIndex();
                                          removeInfinityNote(m.id, index);
                                        }}
                                      />
                                    )}
                                  </TableCell>
                                </TableRow>
                              )}
                            </Draggable>
                          );
                        })}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableContainer>
          </Paper>
          <Fab
            disabled={creatingNewInfinityNote && !infinityNotesLoading}
            size="small"
            color="primary"
            aria-label="add"
            className="messaging-fab"
            onClick={() => {
              if (!creatingNewInfinityNote && !infinityNotesLoading) {
                setCreatingNewInfinityNote(true);
                var highestId = infinityNotes.length - 1;
                for (var i = 0; i < infinityNotes.length; i++) {
                  highestId =
                    infinityNotes[i].id > highestId
                      ? infinityNotes[i].id
                      : highestId;
                }
                highestId++; //new Id will be 1 higher;
                setinfinityNoteIndex(infinityNotes.length - 1);
                createNewInfinityNote(
                  infinityNotes[infinityNotes.length - 1],
                  infinityNotes.length,
                  highestId
                );
                scrollToBottom("message-table-body");
              }
            }}
          >
            <Add />
          </Fab>
        </div>
        <div className="handwritten-card-container message-table-car-container">
          <img
            src={card}
            alt="Snow"
            width="auto"
            height="auto"
            onLoad={() => setdisplayCard(true)}
          />
          {!displayCard && <CircularProgress />}
          {displayCard && infinityNoteIndex != null && (
            <div className="message-table-card-greeting">
              <Typography id="message-greeting-id" className="robot-font">
                {infinityNotes[
                  infinityNoteIndex
                ].messageObj.greeting.text.slice(0, -9)}
              </Typography>
              <Typography className="message-table-card-message robot-font">
                {infinityNotes[infinityNoteIndex].messageObj.body.lines.map(
                  (m) => (
                    <div className="message-body-id">{m.text}</div>
                  )
                )}
              </Typography>
              <Typography
                id="message-signature-id"
                className="message-table-card-signed robot-font"
              >
                {infinityNotes[infinityNoteIndex].messageObj.signature.text}
              </Typography>
            </div>
          )}
          {infinityNoteIndex != null && (
            <Button
              className="infinity-note-button"
              color="primary"
              variant="contained"
              onClick={() => setInfinityNotesDialogOpen(true)}
            >
              Use As Infinity Notes
            </Button>
          )}
          <Dialog
            open={infinityNotesDialogOpen}
            onClose={(e, reason) => {
              setInfinityNotesDialogOpen(false);
            }}
          >
            <DialogTitle>Infinity Notes</DialogTitle>
            <DialogContent>
              {infinityNotesLocation === "ROBOT" && (
                <Typography>
                  Please restart the robot once queueing the infinity notes
                </Typography>
              )}
              <FormGroup>
                <InputLabel>How many infinity notes?</InputLabel>
                <Input
                  type="number"
                  value={infinityNotesAmount}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (value !== "" && Number.parseInt(value) < 0) {
                      value = 0;
                    }
                    setInfinityNotesAmount(value);
                  }}
                  disabled={loadingSendInfinityNotes}
                />
                <InputLabel>Where to print</InputLabel>
                <Select
                  value={infinityNotesLocation}
                  onChange={(e) => setInfinityNoteLocation(e.target.value)}
                  input={<Input id="select-multiple-flavours-chip" />}
                >
                  <MenuItem value={"ROBOT"}>ROBOT</MenuItem>
                  <MenuItem value={"MAIL_ROOM"}>MAIL ROOM</MenuItem>
                </Select>
              </FormGroup>
            </DialogContent>
            <DialogActions>
              {loadingSendInfinityNotes ? (
                <CircularProgress />
              ) : (
                <React.Fragment>
                  <Button
                    variant="contained"
                    color="default"
                    onClick={() => setInfinityNotesDialogOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => sendInfinityNotes()}
                  >
                    Write
                  </Button>
                </React.Fragment>
              )}
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};

const mapStateToProps = (state) => ({
  infinityNotes: state.infinityNote.infinityNotes,
  infinityNotesLoading: state.infinityNote.infinityNotesLoading,
  snackbar: state.snackbar,
});

export default connect(mapStateToProps, {
  getInfinityNotes,
  onEditField,
  onUpdateField,
  removeInfinityNote,
  swapAndUpdate,
  setSnackBarProps,
})(InfinityNotes);
