import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import ClaimsTable from "./ClaimsTable";
import "./claims.scss";

const Claims = (props) => {
    const [claims, setClaims] = useState([])
    const [loading, setLoading] = useState(true)
    const [fetchData, setFetchData] = useState(true)

    useEffect(() => {
        if(fetchData){
            setFetchData(false)
            setLoading(true)
            axios.get("ControlCenter/GetClaimItems")
            .then(res => setClaims(res.data))
            .finally(() => setLoading(false))
        }
    }, [fetchData]);

  if (!loading && claims.length > 0) {
    return <ClaimsTable claims={claims} refreshClaims={() => setFetchData(true)}/>;
  } else {
    return <div>Loading...</div>
  }
};

const mapStateToProps = (state) => ({
  
});

export default connect(mapStateToProps, {})(Claims);
