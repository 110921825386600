import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ProductDetailsRow from "./ProductDetailsRow";
import ProductDetailsStockRow from "./ProductDetailsStockRow";
import ProductDetailsBusinessDirectRow from "./ProductDetailsBusinessDirectRow";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
const ProductsTable = ({
  handleUpdateProductChangeList,
  products,
  productSuppliers,
  handleUpdateAllProductsChangeList,
  handleChangeProductStatus
}) => {
  const [localProducts, setLocalProducts] = useState(products);

  useEffect(() => {
    setLocalProducts(products);
  }, [products]);

  const handleUpdateLocalProduct = (field, value, productId) => {
    let _product = localProducts.find((p) => p.id === productId);
    if (_product) {
      _product = { ..._product, [field]: value };
      setLocalProducts(
        localProducts.map((p) => (p.id === productId ? _product : p))
      );
      handleUpdateProductChangeList(_product);
    }
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#eceae1" : "inherit",
    flex: "1"
  });

  const onDragEnd = (result) => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    const fromIndex = source.index;
    const toIndex = destination.index;

    // same category
    if (fromIndex !== toIndex) {
      reorderProducts(fromIndex, toIndex);
    }
  };

  const reorderProducts = (fromIndex, toIndex) => {
    // Step 1: Adjust the list to move the item from `fromIndex` to `toIndex`
    const updatedLocalProducts = [...localProducts].sort(
      (a, b) => a.displayOrder - b.displayOrder
    );
    const [movedItem] = updatedLocalProducts.splice(fromIndex, 1);
    updatedLocalProducts.splice(toIndex, 0, movedItem);

    const reorderedProducts = updatedLocalProducts.map((_product, index) => ({
      ..._product,
      displayOrder: index + 1,
    }));

    setLocalProducts(reorderedProducts);
    handleUpdateAllProductsChangeList(reorderedProducts);
  };

  return (
    <div className="products-table-root">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              className="products-table-container-wrapper"
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              <Typography className="products-table-heading">
                Base Product Info
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="products-table-header-cell"
                      style={{ width: 30 }}
                    ></TableCell>
                    <TableCell
                      className="products-table-header-cell"
                      style={{ width: 200 }}
                    >
                      <Typography
                        variant="caption"
                        className="products-table-header"
                      >
                        Name
                      </Typography>
                    </TableCell>
                    <TableCell
                      className="products-table-header-cell"
                      style={{ width: 200 }}
                    >
                      <Typography
                        variant="caption"
                        className="products-table-header"
                      >
                        Supplier
                      </Typography>
                    </TableCell>
                    <TableCell
                      className="products-table-header-cell"
                      style={{ width: 80 }}
                    >
                      <Typography
                        variant="caption"
                        className="products-table-header"
                      >
                        Currency
                      </Typography>
                    </TableCell>
                    <TableCell
                      className="products-table-header-cell"
                      style={{ width: 100 }}
                    >
                      <Typography
                        variant="caption"
                        className="products-table-header"
                      >
                        Supplier Code
                      </Typography>
                    </TableCell>
                    <TableCell
                      className="products-table-header-cell"
                      style={{ width: 80 }}
                    >
                      <Typography
                        variant="caption"
                        className="products-table-header"
                      >
                        Short Code
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {localProducts
                    .map((product, index) => (
                      <ProductDetailsRow
                        key={index}
                        index={index}
                        product={product}
                        productSuppliers={productSuppliers}
                        handleChangeProductStatus={handleChangeProductStatus}
                        handleUpdateLocalProduct={handleUpdateLocalProduct}
                      />
                    ))}
                </TableBody>
              </Table>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="products-table-container-wrapper">
        <Typography className="products-table-heading">Stock</Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                className="products-table-header-cell"
                style={{ width: 85 }}
              >
                <Typography variant="caption" className="products-table-header">
                  QTY in a pack
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {localProducts
              .map((product, index) => (
                <ProductDetailsStockRow
                  key={index}
                  index={index}
                  product={product}
                  handleUpdateLocalProduct={handleUpdateLocalProduct}
                />
              ))}
          </TableBody>
        </Table>
      </div>
      <div className="products-table-container-wrapper">
        <Typography className="products-table-heading">
          Business Direct
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className="products-table-header-cell">
                <Typography variant="caption" className="products-table-header">
                  Wholesale
                </Typography>
              </TableCell>
              <TableCell className="products-table-header-cell">
                <Typography variant="caption" className="products-table-header">
                  Retail
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {localProducts
              .map((product, index) => (
                <ProductDetailsBusinessDirectRow
                  key={index}
                  index={index}
                  product={product}
                  handleUpdateLocalProduct={handleUpdateLocalProduct}
                />
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default ProductsTable;
