import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setSnackBarProps } from "../../reduxActions/snackbar";
import _ from "lodash";
import {
  Modal,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Grid,
  TextField,
  Button,
  Tab,
  Tabs,
  Backdrop,
  CircularProgress,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

const UpdateStockPopup = (props) => {
  const {
    modalState,
    handleCloseModal,
    height,
    width,
    headerColor,
    column,
    setSnackBarProps,
    allProducts
  } = props;
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
    },
    paper: {
      backgroundColor: "#fefefe",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 3),
      height: height,
      maxHeight: "85vH",
      width: width,
      overflowY: "auto",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  const [buttonClicked, setButtonClicked] = useState(false);
  const [stock, setStock] = useState(undefined);
  const [stockName, setStockName] = useState(undefined);
  const [coffeeOptions, setCoffeeOptions] = useState([]);
  const [refreshOptions, setRefreshOptions] = useState(false);
  const [is6Pack, setIs6Pack] = useState(false);
  const [is20CapsulePack, setIs20CapsulePack] = useState(false);

  const update = (type) => {
    setButtonClicked(true);
    axios
      .patch("Stock/PatchStockValues", {
        closed: type === "close" ? 1 : 0,
        label: column.label,
        current: column.current !== stock ? stock : column.current,
        stock: column.stock !== stockName ? stockName : column.stock,
        doubleWidth: (column.doubleWidth !== is20CapsulePack ? is20CapsulePack : column.doubleWidth) ? 1 : 0,
      })
      .then((res) => {
        handleCloseModal();
        setButtonClicked(false);
        setSnackBarProps("success", "Sheet updated", true);
      })
      .catch((e) => {
        // console.log(e);
        setButtonClicked(false);
        setSnackBarProps("warning", "Failed to update sheet", true);
      });
  };

  const updateMax = () => {
    setButtonClicked(true);
    axios
      .patch("Stock/PatchStockValues", {
        closed: 0,
        label: column.label,
        current: 100,
        stock: column.stock !== stockName ? stockName : column.stock,
        doubleWidth: (column.doubleWidth !== is20CapsulePack ? is20CapsulePack : column.doubleWidth) ? 1 : 0,
      })
      .then((res) => {
        handleCloseModal();
        setButtonClicked(false);
        setSnackBarProps("success", "Sheet updated", true);
      })
      .catch((e) => {
        // console.log(e);
        setButtonClicked(false);
        setSnackBarProps("warning", "Failed to update sheet", true);
      });
  };

  const handleSaveAndExit = () => {
    if (
      (column.current !== stock || column.stock !== stockName || column.doubleWidth !== is20CapsulePack) &&
      stock !== undefined && stockName !== undefined) {
      setButtonClicked(true);
      axios.patch("Stock/PatchStockValues", {
        closed: column.closed ? 1 : 0,
        label: column.label,
        current: column.current !== stock ? stock : column.current,
        stock: column.stock !== stockName ? stockName : column.stock,
        doubleWidth: (column.doubleWidth !== is20CapsulePack ? is20CapsulePack : column.doubleWidth) ? 1 : 0,
      })
        .then((res) => {
          handleCloseModal();
          setButtonClicked(false);
          setSnackBarProps("success", "Sheet updated", true);
        })
        .catch((e) => {
          // console.log(e);
          setButtonClicked(false);
          setSnackBarProps("warning", "Failed to update sheet", true);
        });
    } else {
      handleCloseModal();
    }
  };

  const getMenuItemArr = () => {
    const menuItems = [];

    for (var i = 0; i <= 100; i++) {
      menuItems.push(<MenuItem key={`stock-count-dropdown-${i}`} value={i}>{i}</MenuItem>);
    }

    return menuItems;
  };

  const getFlavourItemArr = () => {
    const flavourItems = [];
    coffeeOptions.map(p => {
      flavourItems.push(<MenuItem key={`${p.code}-stock-name-dropdown-item`} value={p.code}>{p.code}</MenuItem>);
    })

    if (stockName !== undefined && (coffeeOptions.findIndex(p => p.code === stockName) === -1)) {
      flavourItems.push(<MenuItem key={`${stockName}-stock-name-dropdown-item`} value={stockName}>{stockName}</MenuItem>);
    }

    return flavourItems;
  }

  useEffect(() => {
    setStockName(column.stock);
    setStock(column.current);
    if (column.stock !== undefined && coffeeOptions.findIndex(p => p.code === column.stock) === -1) {
      if (column.stock.split("")[0] === "6") {
        setIs6Pack(true)
      }
    }
    setIs20CapsulePack(column.doubleWidth)
  }, []);

  useEffect(() => {
    if (refreshOptions) {
      setRefreshOptions(false);
    }
  }, [refreshOptions]);

  useEffect(() => {
    var newCoffeeOptions = [];
    allProducts.map(p => {
      if (p.isMiscellaneous === 0) {
        newCoffeeOptions.push(p);
      }
    })

    setCoffeeOptions(newCoffeeOptions)
  }, [allProducts]);

  return (
    <Modal
      open={modalState}
      onClose={() => handleSaveAndExit()}
      className={classes.modal}
      disableBackdropClick
    >
      <div>
        <AppBar position="static" style={{ background: headerColor }}>
          <Toolbar variant="dense" style={{ paddingRight: 0 }}>
            <Typography
              variant="subtitle1"
              align="center"
              style={{ flexGrow: 1 }}
            >
              Stock
            </Typography>
            {!buttonClicked && (
              <span style={{ display: "flex" }}>
                <IconButton
                  onClick={() => handleSaveAndExit()}
                  color="inherit"
                  style={{ color: "white" }}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            )}
          </Toolbar>
        </AppBar>
        <div className={classes.paper}>
          {!buttonClicked ? (
            <Grid container spacing={2}>
              <Grid item xs={6} md={6} lg={6}>
                {stock !== undefined && (
                  <FormControl className={classes.formControl}>
                    <InputLabel>Current Stock</InputLabel>
                    <Select
                      value={stock}
                      onChange={(e) => setStock(e.target.value)}
                    >
                      {getMenuItemArr().map((item) => {
                        return item;
                      })}
                    </Select>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
                {stockName !== undefined && (
                  <React.Fragment>
                    <FormControl className={classes.formControl}>
                      <InputLabel>Current Item</InputLabel>
                      <Select
                        value={stockName}
                        onChange={(e) => {
                          setIs6Pack(false)
                          setStockName(e.target.value)
                        }}
                      >
                        {!refreshOptions && getFlavourItemArr()}
                      </Select>
                    </FormControl>

                    <FormControlLabel
                      style={{
                        marginTop: 20,
                      }}
                      value=""
                      control={<Checkbox
                        color="primary"
                        checked={is6Pack}
                        onChange={e => {
                          if (e.target.checked) {
                            setRefreshOptions(true)
                            setStockName(`6${stockName}`)
                            setIs6Pack(true)
                          }
                          else {
                            var newStockNameArr = stockName.split("");
                            var newStockName = "";
                            for (var i = 1; i < newStockNameArr.length; i++) {
                              newStockName += newStockNameArr[i];
                            }
                            setRefreshOptions(true)
                            setStockName(newStockName)
                            setIs6Pack(false)
                          }
                        }}
                      />}
                      label="Is 6 Pack"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      style={{
                        marginTop: 20,
                      }}
                      value=""
                      control={<Checkbox
                        color="primary"
                        checked={is20CapsulePack}
                        onChange={e => {
                          setRefreshOptions(true)
                          setIs20CapsulePack(e.target.checked)
                        }}
                      />}
                      label="Is 20 Capsules"
                      labelPlacement="end"
                    />
                  </React.Fragment>
                )}
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                <Button
                  style={{
                    backgroundColor: "maroon",
                    color: "white",
                    left: "0px",
                  }}
                  variant="contained"
                  size="small"
                  onClick={() => update("close")}
                >
                  Close row
                </Button>
              </Grid>
              <Grid item xs={4} md={4} lg={4} alignContent="center">
                <Button
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    right: "0px",
                  }}
                  variant="contained"
                  size="small"
                  onClick={() => update("open")}
                >
                  Open row
                </Button>
              </Grid>
              <Grid item xs={4} md={4} lg={4} alignContent="center">
                <Button
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    right: "0px",
                  }}
                  variant="contained"
                  size="small"
                  onClick={() => updateMax()}
                >
                  Open max
                </Button>
              </Grid>
            </Grid>
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  allProducts: state.products.allProducts
});

export default connect(mapStateToProps, {
  setSnackBarProps,
})(UpdateStockPopup);
