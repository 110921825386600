import React, { useEffect, useState } from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Select,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem
} from "@material-ui/core";
import axios from "axios";

const StatusModal = (props) => {
    const [currentStatus, setCurrentStatus] = useState("");

    useEffect(() => {
        // console.log(props.status);
        setCurrentStatus(props.status)
    }, [props.status])

    return (
        <Dialog
            open={props.modalState}
            keepMounted
            onClose={() => props.closeModal()}
        >
            <DialogTitle>Change Status</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Select a status
                </DialogContentText>
                <FormControl
                    style={{
                        margin: 1,
                        minWidth: 120
                    }}
                >
                    <InputLabel>Status</InputLabel>
                    <Select
                        value={currentStatus}
                        onChange={e => setCurrentStatus(e.target.value)}
                    >
                        <MenuItem value="" disabled>
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={0}>Store Order</MenuItem>
                        <MenuItem value={1}>Line Item</MenuItem>
                        <MenuItem value={2}>Processed</MenuItem>
                        <MenuItem value={3}>Packing</MenuItem>
                        <MenuItem value={4}>Completed</MenuItem>
                        <MenuItem value={7}>Fulfilled</MenuItem>
                        <MenuItem value={8}>{`P&L`}</MenuItem>
                        <MenuItem value={6}>Handpack</MenuItem>
                        <MenuItem value={11}>On Hold</MenuItem>
                        <MenuItem value={9}>Error</MenuItem>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={() => props.closeModal()}>
                    Go Back
                </Button>
                <Button 
                variant="contained" 
                color="primary" 
                onClick={() => {
                    if(currentStatus !== ""){
                        axios.patch(`Orders/PatchStatusManually/${props.id}/${currentStatus}/qlbvgherlkjubvg897645HRSTHNXF454lkjhbv`)
                        .then(r => {
                            // console.log(r);
                            props.closeModal();
                            props.refreshOrder();
                        })
                        .catch(e => {
                            // console.log(e);
                        })
                    }
                    else{
                        //error
                    }
                }}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default StatusModal;