import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { IconButton, CircularProgress, Button } from "@material-ui/core";
import {
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  Print as PrintIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
// import PDFViewer from 'pdf-viewer-reactjs'
import Axios from "axios";
import PrintLabelPopup from "../Shared/PrintLabelPopup";
import { setSnackBarProps } from "../../reduxActions/snackbar";
import PdfViewer from "../Shared/PdfViewer";

const LabelTabPanel = (props) => {
  const { snackbar, setSnackBarProps } = props;

  const [base64, setBase64] = useState(null);
  const [downloadedLabel, setDownloadedLabel] = useState(false);

  const [labelsBase64, setLabelsBase64] = useState([]);
  const [driveIds, setDriveIds] = useState([]);
  const [page, setPage] = useState(0);
  const [scale, setScale] = useState(2);
  const [reloadPdfViewer, setReloadPdfViewer] = useState(false);
  const [showPrintLabelPopup, setShowPrintLabelPopup] = useState(false);

  useEffect(() => {
    if (!downloadedLabel) {
      setDownloadedLabel(true);
      setDriveIds(props.driveId.split(","));
      Axios.get(`Orders/GetLabel/${props.driveId}`)
        .then((res) => {
          setLabelsBase64(res.data);
          setBase64(res.data[0]);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [downloadedLabel]);

  useEffect(() => {
    if (reloadPdfViewer) {
      setReloadPdfViewer(false);
    }
  }, [reloadPdfViewer]);
 

  return (
    <div>
      {base64 && !reloadPdfViewer ? (
        <React.Fragment>
          {/* <PrintLabelPopup
                        open={showPrintLabelPopup}
                        handleClose={() => {
                            setShowPrintLabelPopup(false)
                        }}
                        order={{
                            id: props.orderId,
                            handwrittenNoyeFk: props.handwrittenNoteFk,
                            courierOrder: [{
                                driveId: props.driveId
                            }]
                        }}
                        setSnackBarProps={setSnackBarProps}
                    /> */}
          
            <PdfViewer file={`data:application/pdf;base64,${base64}`} scale={1.8}/>
          {/* <PDFViewer
                        scale={scale}
                        hideNavbar
                        hideZoom
                        hideRotation
                        canvasCss={"canvasCss"}
                        document={{
                            url: `data:application/pdf;base64,${base64}`,
                        }}
                        navigation={{
                            css: {
                                navbarWrapper: "navbarWrapper",  // CSS Class for the Navbar Wrapper
                                zoomOutBtn: "navButton",  // CSS Class for the ZoomOut Button
                                resetZoomBtn: "navButton",  // CSS Class for the Reset Zoom Button
                                zoomInBtn: "navButton",  // CSS Class for the ZoomIn Button
                                previousPageBtn: "navButton",  // CSS Class for the PreviousPage button
                                pageIndicator: "navButton navPageIndicator",  // CSS Class for the Page Indicator
                                nextPageBtn: "navButton",  // CSS Class for the NextPage button
                                // rotateLeftBtn: "",  // CSS Class for the RotateLeft button
                                // resetRotationBtn: "",  // CSS Class for the Reset Rotation button
                                // rotateRightBtn: ""  // CSS Class for the RotateRight button
                            },
                        }}
                    /> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <IconButton
              size="small"
              onClick={() => {
                // console.log(scale)
                if (scale - 1 >= 1) {
                  setScale(scale - 1);
                  setReloadPdfViewer(true);
                }
              }}
              color="inherit"
              className="label-button"
            >
              <ZoomOutIcon />
            </IconButton>
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                if (page - 1 >= 0) {
                  setPage(page - 1);
                  setBase64(labelsBase64[page - 1]);
                }
              }}
              className="label-button"
            >
              Prev
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                if (page + 1 <= labelsBase64.length - 1) {
                  setPage(page + 1);
                  setBase64(labelsBase64[page + 1]);
                }
              }}
              className="label-button"
            >
              Next
            </Button>
            <IconButton
              size="small"
              onClick={() => {
                // console.log(scale)
                if (scale + 1 <= 5) {
                  setScale(scale + 1);
                  setReloadPdfViewer(true);
                }
              }}
              color="inherit"
              className="label-button"
            >
              <ZoomInIcon />
            </IconButton>
          </div>
        </React.Fragment>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  snackbar: state.snackbar,
});

export default connect(mapStateToProps, {
  setSnackBarProps,
})(LabelTabPanel);
