import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useCustomer } from "../../../context/customer/CustomerContext";
import { isEmpty } from "../../Utils/helperFunctions";
import AddNewPayment from "../AddNewPayment/AddNewPayment";

const errorState = {
  cardholderName: "",
};

const AddNewCardModal = ({ open, handleClose }) => {
  const { customer, firestoreCustomer, refreshPaymentMethods } = useCustomer();
  const elements = useElements();
  const stripe = useStripe();
  const [isValidPaymentMethod, setIsValidPaymentMethod] = useState(false);
  const [cardholderName, setCardholderName] = useState("");
  const [errorForm, setErrorForm] = useState(errorState);
  const [setupIntentSecret, setSetupIntentSecret] = useState(null);

  useEffect(() => {
    setupIntent();
  }, []);

  const setupIntent = async () => {
    if (firestoreCustomer && firestoreCustomer.stripeId) {
      const { data } = await axios.post(
        `Stripe/CreateNewSetupIntent/${firestoreCustomer.stripeId}`
      );
      console.log(data);
      if (data && data.client_secret) {
         setSetupIntentSecret(data.client_secret);
      } else {
        alert("Unable to create Stripe Setup Intent");
      }
    }
  };

  function isValid() {
    let validForm = true;
    const tempError = { ...errorState };

    if (isEmpty(cardholderName)) {
      tempError.cardholderName = "Required field";
      validForm = false;
    }

    if (!validForm) {
      setErrorForm(tempError);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }

    return validForm;
  }

  const handleSavePayment = async () => {
    if (!isValid || !firestoreCustomer?.email) {
      return;
    }
    let payload = {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: cardholderName,
          email: firestoreCustomer.email,
        },
      },
    };
    const setupResult = await stripe.confirmCardSetup(
      setupIntentSecret,
      payload
    );
    if (setupResult.error) {
      alert(
        `Unable to save credit card. ${setupResult.error.message}`
      );
    } else {
      refreshPaymentMethods(firestoreCustomer.stripeId);
      alert(`Credit card saved`);
      handleClose()
    }
  };

  const handleCardChange = async (event) => {
    if (event.complete) {
      setIsValidPaymentMethod(true);
    } else {
      setIsValidPaymentMethod(false);
    }
  };
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"xs"}>
      <DialogTitle id="form-dialog-title">{`Add new card to ${customer.firstName}`}</DialogTitle>
      <DialogContent>
        <AddNewPayment
          handleCardChange={handleCardChange}
          setCardholderName={setCardholderName}
          cardholderName={cardholderName}
          errorState={errorForm}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSavePayment} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddNewCardModal;
