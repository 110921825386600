import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import moment from "moment/moment";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";


import "./referralRow.scss";

const ReferralRow = ({
  referral,
  handleDenyReferralUse,
  handleApproveReferralUse,
  isProcessing,
}) => {
  const [open, setOpen] = useState(false);

  const toggleRow = () => {
    setOpen((open) => !open);
  };


  return (
    <React.Fragment>
      <TableRow>
        <TableCell
          component="th"
          scope="row"
          onClick={toggleRow}
          className="referral-row-outer-cell"
        >
          <Typography variant="body1">
            {referral.code}
          </Typography>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          onClick={toggleRow}
          className="referral-row-outer-cell"
        >
          <Link to={`/AdminPanel/Customers/?firestoreId=${referral.referrerCustomerId}`}>
            {`${referral.referrerCustomer?.firstName} ${referral.referrerCustomer?.lastName}`}
          </Link>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          onClick={toggleRow}
          className="referral-row-outer-cell"
        >
          <div className="referral-row-date-cell">
            <Typography variant="body1">
              {referral.referralUsage && moment(referral.referralUsage?.usedAt).format("MMMM Do Y")}
            </Typography>
          </div>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          onClick={toggleRow}
          className="referral-row-outer-cell"
        >
          {referral.referralUsage != null
            && <Link to={`/AdminPanel/Customers/?firestoreId=${referral?.usedCustomerId}`}>
              {`${referral.usedCustomer?.firstName} ${referral.usedCustomer?.lastName}`}
            </Link>}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          onClick={toggleRow}
          className="referral-row-outer-cell"
        >
          <Typography variant="body1">{referral.creditStatus}</Typography>
        </TableCell>
        <TableCell className="referral-row-outer-cell">

          {referral?.creditStatus == "PENDING" && <div className="referral-row-statusBtns-container">
            <Button
              size={"small"}
              variant="outlined"
              color="primary"
              onClick={() => handleApproveReferralUse(referral)}
            >
              <div className={"referral-row-statusBtn"}>
                <CheckIcon />
                <span>Approve</span>
              </div>

            </Button>
            <Button
              size={"small"}
              variant="outlined"
              color="primary"
              onClick={() => handleDenyReferralUse(referral)}
            >
              < div className={"referral-row-statusBtn"}>
                <ClearIcon />
                <span>Deny</span>
              </div>
            </Button>
          </div>}
        </TableCell>
      </TableRow>
      {/* <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className={"referral-row-addressDetailContainer"}>
                <div className={"referral-row-addressContainer"}>
                  <span>
                    {`Deliver to 
                ${referral?.shippingAddress?.firstName
                        ? referral?.shippingAddress?.firstName + ", "
                        : ""
                      }
                ${referral?.shippingAddress?.companyName !== null &&
                        !isEmpty(referral?.shippingAddress?.companyName)
                        ? referral?.shippingAddress?.companyName + ", "
                        : ""
                      }
                ${referral?.shippingAddress?.addressLine1
                        ? referral?.shippingAddress?.addressLine1 + ", "
                        : ""
                      }
                ${referral?.shippingAddress?.addressLine2
                        ? referral?.shippingAddress?.addressLine2 + ", "
                        : ""
                      }
                ${referral?.shippingAddress?.city
                        ? referral?.shippingAddress?.city + ", "
                        : ""
                      }
                ${referral?.shippingAddress?.postCode
                        ? referral?.shippingAddress?.postCode + "."
                        : ""
                      }
              `}
                  </span>
                  <div className={"referral-row-editLinkContainer"}>
                    <span
                      role={"presentation"}
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenAddressChangeModal(true);
                      }}
                    >
                      Edit
                    </span>
                  </div>
                </div>
              </div>

              <div className={"referral-row-paymentDetailContainer"}>
                <div className={"referral-row-ccBrand"}>
                  <PaymentIcon />
                </div>
                {selectedPayment && (
                  <div className="referral-row-ccDetails">
                    <div>{`**** ${selectedPayment?.card?.last4}`}</div>
                    <div>{`EXP ${selectedPayment?.card?.exp_month < 10
                      ? "0" + selectedPayment?.card?.exp_month
                      : selectedPayment?.card?.exp_month
                      }/${selectedPayment?.card?.exp_year}`}</div>
                  </div>
                )}
                <div className={"referral-row-editLinkContainer"}>
                  <span
                    role={"presentation"}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenPaymentMethods();
                    }}
                  >
                    Edit
                  </span>
                </div>
              </div>
              {referral?.hasPaymentFailed && (
                <>
                  <div className={"referral-row-paymentDetailContainer"}>
                    <div className={"referral-row-ccBrand"}>
                      <WarningIcon />
                    </div>

                    <div className="referral-row-ccDetails">
                      {`The Payment has failed.`}
                    </div>
                  </div>
                  <div className={"referral-row-paymentDetailContainer"}>
                    <div className="referral-row-ccDetails">
                      {`Reason: ${referral.errorMessage}`}
                    </div>
                  </div>
                  <div className={"referral-row-paymentDetailContainer"}>
                    <div className="referral-row-ccDetails">
                      {`Number of retries: ${referral.paymentRetries}`}
                    </div>
                  </div>
                  <div className={"referral-row-paymentDetailContainer"}>
                    <div className="referral-row-ccDetails">
                      {`Has Customer been notified? ${referral.errorNotificationSent ? "Yes" : "No"
                        }`}
                    </div>
                  </div>
                </>
              )}

              <div className={"referral-row-newProductContainer"}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => handleOpenNewProductModal()}
                  startIcon={<AddIcon />}
                >
                  Add new product
                </Button>
              </div>

              <div className={"referral-row-itemList"}>
                {referral?.lineItems?.map((item, index) => {
                  return (
                    <div className={"referral-row-itemWrapper"} key={index}>
                      <div className={"referral-row-closeContainer"}>
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={(e) => handleRemoveItem(referral, item)}
                          size="small"
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                      <div
                        className={"referral-row-itemContainer"}
                        key={index}
                      >
                        <div
                          role={"presentation"}
                          className={"referral-row-imageContainer"}
                        >
                          <img
                            alt={item.productTitle}
                            src={item.imageSrc}
                          ></img>
                        </div>
                        <div>
                          <span className={"referral-row-itemName"}>
                            {item.productTitle}
                          </span>
                          <div className={"referral-row-orderItemMeta"}>
                            <span className={"referral-row-meta"}>
                              {item.variantTitle} {" x "} {item.quantity}{" "}
                              <CurrencyFormatter
                                useDollar
                                amount={item.price}
                              />{" "}
                              {" each"}
                            </span>
                            <QuantityInput
                              value={item.quantity}
                              onIncrement={() => doIncrement(item)}
                              onDecrement={() => doDecrement(item)}
                              onChange={(e) =>
                                handleQuantityChange(
                                  referral,
                                  item,
                                  e.currentTarget.value
                                )
                              }
                              disabled={isUpdating}
                            />
                            <div
                              className={
                                "referral-row-mobileActionContainer"
                              }
                            >
                              <div
                                className={"referral-row-itemTotalMobile"}
                              >
                                <CurrencyFormatter
                                  useDollar
                                  amount={item.quantity * item.price}
                                />
                              </div>
                              <div
                                className={
                                  "referral-row-actionContainerMobile"
                                }
                              >
                                <Button
                                  variant="outlined"
                                  onClick={() =>
                                    handleOpenSwapProductModal(item)
                                  }
                                  fullWidth
                                  className={"referral-row-addItemToCart"}
                                  size={"small"}
                                  level={"secondary"}
                                >
                                  Swap Product
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={"referral-row-actionContainer"}>
                          <Button
                            variant="outlined"
                            onClick={() => handleOpenSwapProductModal(item)}
                            fullWidth
                            className={"referral-row-addItemToCart"}
                            size={"small"}
                            level={"secondary"}
                          >
                            Swap Product
                          </Button>
                        </div>
                        <div className={"referral-row-itemTotal"}>
                          <CurrencyFormatter
                            useDollar
                            amount={item.quantity * item.price}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div className={"referral-row-transactionDetails"}>
                  <div className={"referral-row-transactionalGrid"}>
                    <>
                      <span
                        className={"referral-row-meta"}
                      >{`Subtotal`}</span>
                      <span className={"referral-row-meta"}>
                        <CurrencyFormatter
                          useDollar
                          amount={orderSummary?.subtotal}
                        />
                      </span>
                    </>
                    {orderSummary?.loyaltyDiscount !== 0 && (
                      <>
                        <span
                          className={"referral-row-meta"}
                        >{`Loyalty Discount `}</span>
                        <span className={"referral-row-meta"}>
                          <CurrencyFormatter
                            appendZero
                            useDollar
                            isDiscountCode
                            amount={orderSummary?.loyaltyDiscount}
                          />
                        </span>
                      </>
                    )}
                    {orderSummary?.autoshipDiscount !== 0 && (
                      <>
                        <span
                          className={"referral-row-meta"}
                        >{`Autoship every ${referral.referralFrequency} weeks`}</span>
                        <span className={"referral-row-meta"}>
                          <CurrencyFormatter
                            useDollar
                            amount={orderSummary?.autoshipDiscount}
                            isDiscountCode
                          />
                        </span>
                      </>
                    )}
                    <>
                      <span
                        className={"referral-row-meta"}
                      >{`Shipping and Delivery`}</span>
                      <span className={"referral-row-meta"}>
                        <CurrencyFormatter
                          useDollar
                          amount={orderSummary?.shippingCost}
                        />
                      </span>
                    </>
                    <span className={"referral-row-grandTotal"}>
                      Order Total{" "}
                    </span>
                    <span
                      className={`referral-row-grandTotal referral-row-bold`}
                    >
                      <CurrencyFormatter
                        useDollar
                        amount={orderSummary?.total}
                      />
                    </span>
                  </div>
                </div>
                <div className={"referral-row-actions"}>
                  <div className={"referral-row-deletenow"}>
                    {isProcessing ? (
                      <Typography>Processing ... </Typography>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() =>
                          deleteReferralOrder(referral)
                        }
                        startIcon={<ClearIcon />}
                      >
                        Delete Autoship For Good
                      </Button>
                    )}
                  </div>
                  {referral.status === "ACTIVE" && (
                    <div className={"referral-row-processnow"}>
                      {isProcessing ? (
                        <Typography>Processing ... </Typography>
                      ) : (
                        justProcessed ? <Typography>Just Processed</Typography>
                          : <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() =>
                              handleProcessReferralOrder(referral)
                            }
                            startIcon={<CheckIcon />}
                          >
                            Process Autoship Now
                          </Button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell> 
        <TableCell />
      </TableRow> */}
    </React.Fragment >
  );
};

export default ReferralRow;
