import React, { forwardRef, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    FormControlLabel,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Typography,
    CircularProgress,
    FormGroup,
    Checkbox,
    TextField,
    Grid
} from "@material-ui/core";
import Axios from "axios"

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} mountOnEnter unmountOnExit />;
});

const GenerateManualTicketPopUp = (props) => {
    const {
        open,
        handleClose,
        setSnackBarProps
    } = props;

    const [loading, setLoading] = useState(false);
    const [boxSize, setBoxSize] = useState("6");
    const [print, setPrint] = useState(true);
    const [download, setDownload] = useState(false);
    const [shippingAddress, setShippingAddress] = useState({
        firstName: "",
        lastName: "",
        address1: "",
        address2: "",
        city: "",
        zip: "",
        company: ""
    })

    const generateLabel = () => {
        setLoading(true)
        Axios.post(`SystemActions/GenerateManualLabel/${boxSize}/${print}/${download}`, shippingAddress,
            {
                params: {
                    cacheBustTimestamp: Date.now(), // prevents IE cache problems on re-download
                },
                responseType: "blob",
                headers: {
                    Accept: "application/octet-stream",
                },
            })
            .then(res => {
                setSnackBarProps("success", "Success", true);
                // var downloadStream = res.data.downloadStream;
                if (res.data !== "" && download) {
                    const blob = new Blob([res.data], { type: "application/pdf" });

                    //Used to open pdf in a another tab
                    // const fileUrl = URL.createObjectURL(blob);
                    // const w = window.open(fileUrl, '_blank');
                    // w && w.focus();

                    // Code Below to force download
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `Manual-${+new Date()}.pdf`;
                    link.click();
                }
            })
            .catch(e => {
                props.setSnackBarProps("warning", "Failed", true);
            })
            .finally(() => {
                handleClose()
                setLoading(false)
            })
    }

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={(e, reason) => {
                    if (reason !== 'backdropClick') {
                        handleClose()
                    }
                }}
            >
                <DialogTitle>Generate Manual Label</DialogTitle>
                <DialogContent>
                    <FormGroup>
                        <Grid container spacing={1}>
                            <Grid item xs={6} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="First Name"
                                    value={shippingAddress.firstName}
                                    onChange={(e) => {
                                        var newShippingAddress = {
                                            ...shippingAddress,
                                            firstName: e.target.value
                                        }
                                        setShippingAddress(newShippingAddress)
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Last Name"
                                    value={shippingAddress.lastName}
                                    onChange={(e) => {
                                        var newShippingAddress = {
                                            ...shippingAddress,
                                            lastName: e.target.value
                                        }
                                        setShippingAddress(newShippingAddress)
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Company"
                                    value={shippingAddress.company}
                                    onChange={(e) => {
                                        var newShippingAddress = {
                                            ...shippingAddress,
                                            company: e.target.value
                                        }
                                        setShippingAddress(newShippingAddress)
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Street"
                                    value={shippingAddress.address1}
                                    onChange={(e) => {
                                        var newShippingAddress = {
                                            ...shippingAddress,
                                            address1: e.target.value
                                        }
                                        setShippingAddress(newShippingAddress)
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Suburb"
                                    value={shippingAddress.address2}
                                    onChange={(e) => {
                                        var newShippingAddress = {
                                            ...shippingAddress,
                                            address2: e.target.value
                                        }
                                        setShippingAddress(newShippingAddress)
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="City"
                                    value={shippingAddress.city}
                                    onChange={(e) => {
                                        var newShippingAddress = {
                                            ...shippingAddress,
                                            city: e.target.value
                                        }
                                        setShippingAddress(newShippingAddress)
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Postcode"
                                    value={shippingAddress.zip}
                                    onChange={(e) => {
                                        var newShippingAddress = {
                                            ...shippingAddress,
                                            zip: e.target.value
                                        }
                                        setShippingAddress(newShippingAddress)
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                <InputLabel id={"box-size-select-label"}>Box Size</InputLabel>
                                <Select
                                    id="box-size-select"
                                    value={boxSize}
                                    onChange={(e) => setBoxSize(e.target.value)}
                                    disabled={loading}
                                    fullWidth
                                >
                                    <MenuItem value={"6"}>6</MenuItem>
                                    <MenuItem value={"12"}>12</MenuItem>
                                    <MenuItem value={"18"}>18</MenuItem>
                                    <MenuItem value={"24"}>24</MenuItem>
                                    <MenuItem value={"36"}>36</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                <FormControlLabel
                                    control={<Checkbox color="primary" disabled={loading} checked={print} onChange={e => setPrint(e.target.checked)} name="print-return-label" />}
                                    label="Print"
                                />
                                <FormControlLabel
                                    control={<Checkbox color="primary" disabled={loading} checked={download} onChange={e => setDownload(e.target.checked)} name="download-return-label" />}
                                    label="Download"
                                />
                            </Grid>
                        </Grid>
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    {loading ?
                        <CircularProgress style={{ margin: 10 }} />
                        : (
                            <React.Fragment>
                                <Button variant="contained" color="default" onClick={() => handleClose()}>
                                    Go Back
                                </Button>
                                <Button variant="contained" color="primary" onClick={() => generateLabel()}>
                                    Continue
                                </Button>
                            </React.Fragment>
                        )
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default GenerateManualTicketPopUp;