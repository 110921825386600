export const calculateTotalCheckout = (checkout, shippingCost) => {
  const total = shippingCost ? Number(checkout?.totalPriceV2?.amount) +  Number(shippingCost.rate) : checkout?.totalPriceV2?.amount 
  return total;
};

export const calculateDiscountTotal = (discountApplications, total) => {
  if (discountApplications && discountApplications.length > 0) {
    if (discountApplications[0]?.value.amount) {
      return {
        title: discountApplications[0]?.code,
        value: Number(discountApplications[0]?.value.amount),
        valueType: "fixed_amount",
        amount: Number(discountApplications[0]?.value.amount),
      };
    } else if (discountApplications[0]?.value.percentage) {
      return {
        title: discountApplications[0]?.code,
        value: discountApplications[0]?.value.percentage,
        valueType: "percentage",
        amount: total * (discountApplications[0]?.value.percentage / 100),
      };
    }
  }
  return null;
};

export const convertCheckoutLineItems = (checkout) => {
  let lineItems = [];
  if (checkout && checkout.lineItems?.length > 0) {
    checkout.lineItems.forEach((item) => {
      const lineItem = {
        sku: item.variant?.sku,
        title: item.title,
        quantity: item.quantity,
        price: item.variant?.price?.amount,
        productId: item.variant?.product?.id,
        variantId: item.variant?.id,
        variantTitle: item.variant?.title,
        // variant_id: convertIdFromGraphql(
        //   item.variableValues?.lineItems[0]?.variantId
        // ),
      };
      lineItems.push(lineItem);
    });
  }

  return lineItems;
};