import "./editOrder.scss";

import {
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import React, { useEffect, useState } from "react";
import Axios from "axios";

const DUMMY_ERRORS = [
  {
    timestamp: "2023-03-27 05:01:14",
    title: "Out Of Stock PHC",
    body: "Order set to failed in OutOfStockWithFail",
    tags: "OutOfStock",
  },
  {
    timestamp: "2023-03-27 21:01:14",
    title: "Only Gleam or RT in Order",
    body: "The robot cannot pack orders of only RT and/or GLM",
    tags: "RT,GLM",
  },
];

const ErrorLogTabPanel = (props) => {
  const [errorsLoaded, setErrorsLoaded] = useState(false);
  const [errors, setErrors] = useState([]);

  const { orderId } = props;

  useEffect(() => {
    Axios.get(`Orders/GetOrderErrorLog/${orderId}`).then((res) => {
      setErrors(res.data.reverse());

      setErrorsLoaded(true);
      console.log(res.data[0].timestamp);
    });
  }, []);

  return (
    <div>
      {errorsLoaded ? (
        <div>
          <h1>Error History</h1>
          {errors.length > 0 ? (
            <TableContainer style={{ padding: "20px 0" }}>
              <Table aria-labelledby="tableTitle" aria-label="sticky table">
                <TableHead className="color-black">
                  <TableRow>
                    <TableCell className="small-header">Time</TableCell>
                    <TableCell className="small-header">Error</TableCell>
                    <TableCell>Description</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody className="color-black">
                  {errors.map((o) => (
                    <TableRow key={o.id}>
                      <TableCell>
                        {new Date(`${o.timestamp}Z`).toLocaleDateString(
                          "en-NZ"
                        )}{" "}
                        {new Date(`${o.timestamp}Z`).toLocaleTimeString(
                          "en-NZ"
                        )}
                      </TableCell>
                      <TableCell>{o.title}</TableCell>
                      <TableCell>{o.body}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div>No Errors To Display</div>
          )}{" "}
        </div>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

export default ErrorLogTabPanel;
