import axios from "axios";

const statusOptions = [
    { label: "All", value: null },
    { label: "Pending", value: "PENDING" },
    { label: "Code Not Used", value: "CODE_NOT_USED" },
    { label: "Approved", value: "APPROVED" },
    { label: "Denied", value: "DENIED" },
  ];

const approveReferral = async (referralId) => {
    const response = await axios.patch(
        `ReferralCode/ApproveReferral/${referralId}`
    );
    if (response.status != 200) {
        return null;
    }
    return response.data;
}

const denyReferral = async (referralId) => {
    const response = await axios.patch(
        `ReferralCode/DenyReferral/${referralId}`
    );
    if (response.status != 200) {
        return null;
    }
    return response.data;
}

const searchReferrals = async (referralFilter) => {
    try {
        let data;
        console.log(referralFilter)
        if (referralFilter.value != null) {
            data = await getReferralsWithCreditStatus(referralFilter.value);
        } else {
            data = await getAllReferrals();
            if (referralFilter.value != null) {
                data = data.filter((referral) => referral.creditStatus == referralFilter.value);
            }
        }
        return data;
    } catch (err) {
        console.error(err);
    }
}

const findReferralsByIds = async (referralIds) => {
    const { data } = await axios.post(
        `ReferralCode/GetReferralsByIds/`, referralIds
    );
    if (!data) {
        data = [];
    }

    return data;
}

const getAllReferrals = async () => {
    const { data } = await axios.get(
        `ReferralCode/GetAllReferrals`
    );
    if (!data) {
        data = [];
    }

    return data;
}

const getReferralsWithCreditStatus = async (creditStatus) => {
    const { data } = await axios.get(
        `ReferralCode/GetReferralsWithCreditStatus/${creditStatus}`
    );
    if (!data) {
        data = [];
    }

    return data;
};

export { statusOptions, getAllReferrals, getReferralsWithCreditStatus, searchReferrals, approveReferral, denyReferral, findReferralsByIds };