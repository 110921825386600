import decode from "jwt-decode";
import jwtDecode from "jwt-decode";
import axios from "axios";

export const isAuthenticated = (token, oAuthSignedIn, oAuthEmail, userList) => {
  if (oAuthSignedIn && userList.indexOf(oAuthEmail) !== -1) {
    return true;
  } else if (!oAuthSignedIn && token !== "") {
    var tokenObj = decode(token);
    // console.log(tokenObj);
    if (tokenObj.id) {
      return true;
    }
  } else {
    return false;
  }
};

// export const isSignedIn = () => {
//   const token =  window.localStorage.getItem("access_token")
//
//   return token && typeof token === "string" && token.length > 1 ? token : ""
// };

export async function isValidToken() {
  try {
    const token = window.localStorage.getItem("access_token");

    if (token && typeof token === "string" && token.length > 1) {
      const userInfo = jwtDecode(token);
      const response = await axios.get(
        `Users/GetUserForAuth/${userInfo.email}`
      );
      return response.data !== "" ? token : "";
    } else {
      return "";
    }
  } catch (error) {
    //console.log(error);
    return "";
  }
}
