import React, { useState, useEffect } from "react";
import Axios from "axios";
import {
  Typography,
  Paper,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import "./BusinessDirect.scss";
import SkuItemTable from "./SkuItemTable";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { setSnackBarProps } from "../../reduxActions/snackbar";
import { connect } from "react-redux";
import BusinessDirectHistory from "./BusinessDirectHistory";
import Dialogy from "../Shared/Dialogy";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { ConvertNZPostAddress } from "../Utils/nzPostUtils"

const WhiteTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
})(TextField);

const BusinessDirect = () => {
  let history = useHistory();
  const [companies, setCompanies] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [companyAddresses, setCompanyAddresses] = useState([]);
  const [companyAddress, setCompanyAddress] = useState({
    address1: "",
    address2: "",
    city: "",
    zip: "",
    country: "",
  });
  const [shippingAddresses, setShippingAddresses] = useState([]); //For NZPost dropdown
  const [companyShipingAddresses, setCompanyShippingAddresses] = useState([]);
  const [shippingAddress, setShippingAddress] = useState({
    address1: "",
    address2: "",
    city: "",
    zip: "",
    country: "",
    name: "",
  });
  const [person, setPerson] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });
  const [companyName, setCompanyName] = useState("");
  const [accountsEmail, setAccountsEmail] = useState("");
  const [skuPriceItems, setSkuPriceItems] = useState([]);
  const [itemQuantity, setItemQuantity] = useState(null);
  const [rate, setRate] = useState("");
  const [customerRates, setCustomerRates] = useState([]);
  //const [total, setTotal] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalCapsules, setTotalCapsules] = useState(0);

  const [purchaseOrder, setPurchaseOrder] = useState(null);
  const [shippingType, setShippingType] = useState("none");

  const [orderToReorder, setOrderToReorder] = useState(null);
  const [lineItemsReorder, setLineItemsReorder] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [sameAsBilling, setSameAsBiilng] = useState(true);
  const [alternativeCompanyName, setAlternativeCompanyName] = useState("");
  const [dialogyOpen, setDialogyOpen] = useState(false);
  const [savePurchaseOrder, setSavePurchaseOrder] = useState(false);

  const [companyAddressOptionsLoading, setCompanyAddressOptionsLoading] = useState(false);
  const [shippingAddressOptionsLoading, setShippingAddressOptionsLoading] = useState(false);

  //-------------------------------------------------//
  const getNZPostAddresses = (searchTerm, isShipping) => {
    if (isShipping) {
      setShippingAddressOptionsLoading(true);
    } else {
      setCompanyAddressOptionsLoading(true);
    }
    Axios.get(`Orders/GetNZPostAddresses/${encodeURIComponent(searchTerm)}`)
      .then((res) => {
        var addressList = [];
        for (var i = 0; i < res.data.length; i++) {
          addressList.push(res.data[i].fullAddress)
        }
        if (isShipping) {
          setShippingAddresses(addressList);
        } else {
          setCompanyAddresses(addressList);
        }
      })
      .catch((e) => { console.log(e); })
      .finally(() => {
        if (isShipping) {
          setShippingAddressOptionsLoading(false);
        } else {
          setCompanyAddressOptionsLoading(false);
        }
      });
  };

  const handleAddressSelection = (fullAddress, isShipping) => {
    var addressObject = ConvertNZPostAddress(fullAddress);

    if (isShipping) {
      setShippingAddress({
        ...shippingAddress,
        ...addressObject
      });
    } else {
      setCompanyAddress({
        ...addressObject
      });
    }
  };

  const filterAutoComplete = createFilterOptions();

  const autoCompleteFilterOptions = (options, params) => {
    const filtered = filterAutoComplete(options, params);
    // Suggest the creation of a new value
    if (params.inputValue !== "" || filtered.length == 0) {
      filtered.push({
        inputValue: params.inputValue,
        title: `Add "${params.inputValue}"`,
      });
    }
    return filtered;
  };

  const getAutoCompleteOptionlabel = (option, type) => {
    // Value selected with enter, right from the input
    if (typeof option === "string") {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue === null) {
      return "";
    }
    if (option.inputValue) {
      return option.title;
    }
    if (type === "company") {
      return option.name !== null ? option.name : "";
    } else if (type === "shipping") {
      return option.name !== null ? option.name : "";
    } else {
      return `${option.firstName} ${option.lastName}`;
    }
  };

  const handleCompanyNameChange = (event, newValue) => {
    if (newValue && newValue.inputValue) {
      setCompanyName(newValue.inputValue);
      setAlternativeCompanyName(newValue.inputValue);
      setEmployees([]);
      setAccountsEmail("");
      setCompanyAddress({
        ...companyAddress,
        address1: "",
        address2: "",
        zip: "",
        city: "",
        country: "",
      });
      setShippingAddress({
        name: "",
        address1: "",
        address2: "",
        zip: "",
        city: "",
        country: "",
      });
      setPerson({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
      });
      setPurchaseOrder(null);
      setCompanyShippingAddresses([]);
      setSavePurchaseOrder(false);
      setSameAsBiilng(true);
    } else {
      setCompanyName(newValue);
      if (typeof newValue !== "string") {
        setAlternativeCompanyName(newValue.name);
        setEmployees(newValue.employee);
        setAccountsEmail(newValue.accountsEmail);
        setCompanyAddress({
          ...companyAddress,
          address1: newValue.address1,
          address2: newValue.address2,
          zip: newValue.zip,
          city: newValue.city,
          country: newValue.country,
        });
        var shippingAddress = newValue.companyAddress.find(
          (ca) => ca.addressType === "shipping"
        );
        if (shippingAddress !== undefined && shippingAddress !== null) {
          setShippingAddress({
            name: shippingAddress.name,
            address1: shippingAddress.address1,
            address2: shippingAddress.address2,
            zip: shippingAddress.zip,
            city: shippingAddress.city,
            country: shippingAddress.country,
          });
        } else {
          setShippingAddress({
            name: "",
            address1: "",
            address2: "",
            zip: "",
            city: "",
            country: "",
          });
        }
        setCompanyShippingAddresses(
          newValue.companyAddress.filter((ca) => ca.addressType === "shipping")
        );
        setPerson({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
        });
        setSavePurchaseOrder(newValue.savePurchaseOrder === 1);
        if (newValue.savePurchaseOrder === 1) {
          setPurchaseOrder(newValue.purchaseOrder);
        } else {
          setPurchaseOrder(null);
        }
        if (newValue.shippingSameAsBilling === 1) {
          setSameAsBiilng(true);
        } else {
          setSameAsBiilng(false);
        }
      }
    }
    setRefresh(true);
  };

  const handleFirstNameChange = (event, newValue) => {
    if (newValue && newValue.inputValue) {
      setPerson({
        ...person,
        firstName: newValue.inputValue,
      });
    } else {
      setPerson({
        firstName: newValue.firstName,
        lastName: newValue.lastName,
        email: newValue.email,
        phoneNumber: newValue.phoneNumber,
      });
    }
  };

  const handleShippingNameChange = (event, newValue) => {
    console.log("newValue")
    console.log(newValue)
    if (newValue && newValue.inputValue) {
      setShippingAddress({
        ...shippingAddress,
        name: newValue.inputValue,
      });
    } else {
      try {
        setShippingAddress({
          name: newValue.name,
          address1: newValue.address1,
          address2: newValue.address2,
          zip: newValue.zip,
          city: newValue.city,
          country: newValue.country,
        });
      } catch {

      }

    }
  };

  const handleLastNameChange = (event, newValue) => {
    if (newValue && newValue.inputValue) {
      setPerson({
        ...person,
        lastName: newValue.inputValue,
      });
    } else {
      setPerson({
        firstName: newValue.firstName,
        lastName: newValue.lastName,
        email: newValue.email,
        phoneNumber: newValue.phoneNumber,
      });
    }
  };

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const allFieldsComplete = () => {
    return (
      companyName !== "" &&
      accountsEmail !== "" &&
      validateEmail(accountsEmail) &&
      person.firstName !== "" &&
      person.lastName !== "" &&
      companyAddress.address1 !== "" &&
      companyAddress.address2 !== "" &&
      companyAddress.city !== "" &&
      companyAddress.country !== "" && rate !== ""
    );
  };

  const placeOrder = () => {
    var keys = Object.keys(itemQuantity);

    for (var i = 0; i < keys.length; i++) {
      var val = itemQuantity[keys[i]];
      if (typeof val == "string" && val.trim() == "") {
        itemQuantity[keys[i]] = 0;
      }
    }

    Axios.post(`BusinessDirect/PlaceOrder`, {
      companyName: companyName,
      firstName: person.firstName,
      lastName: person.lastName,
      email: person.email,
      accountsEmail: accountsEmail,
      companyAddress: companyAddress,
      itemQuantity: itemQuantity,
      purchaseOrder: purchaseOrder,
      phoneNumber: person.phoneNumber,
      rate: rate,
      shippingType: shippingType,
      shippingAddress: shippingAddress,
      sameAsBilling: sameAsBilling,
      alternativeCompanyName: alternativeCompanyName,
      savePurchaseOrder: savePurchaseOrder
    })
      .then((res) => {
        setDialogyOpen(true);
        setSnackBarProps("success", "Sent for processing", true);
      })
      .catch((e) => {
        setSnackBarProps("warning", "Failed. Please try again", true);
      });
  };

  const convertBdItemsToItemQuantity = (items) => {
    var newItemQuantity = {};
    skuPriceItems.map((s) => {
      newItemQuantity = {
        ...newItemQuantity,
        [s.flavour]: "0",
      };
    });
    for (var i = 0; i < items.length; i++) {
      var item = skuPriceItems.find(s => s.code === items[i].Sku);
      if (item !== null && item !== undefined) {
        newItemQuantity[`${item.flavour}`] = items[i].Quantity.toString();
      } else {
        // debugger;
      }
    }
    setItemQuantity(newItemQuantity);
  };

  //---------------------------------------------------//

  useEffect(() => {
    Axios.get(`BusinessDirect/GetSkuPrice`)
      .then((res) => {
        setSkuPriceItems(res.data);
      })
      .catch((e) => { });
  }, []);

  useEffect(() => {
    Axios.get(`BusinessDirect/GetCustomerRates`)
      .then((res) => {
        setCustomerRates(res.data);
      })
      .catch((e) => { });
  }, []);

  useEffect(() => {
    Axios.get(`BusinessDirect/GetCompaniesWithHistory`)
      .then((res) => {
        setCompanies(res.data);
        // console.log(res.data)
      })
      .catch((e) => { });
  }, []);

  useEffect(() => {
    if (itemQuantity === null || _.isEmpty(itemQuantity)) {
      var items = {};
      skuPriceItems.map((s) => {
        items = {
          ...items,
          [s.flavour]: "0",
        };
      });
      setItemQuantity(items);
    }
  }, [skuPriceItems]);

  useEffect(() => {
    if (itemQuantity !== null) {
      //var newTotal = 0;
      var newTotalItems = 0;
      var newTotalCapsules = 0;

      var keys = Object.keys(itemQuantity);
      for (var i = 0; i < keys.length; i++) {
        var val = itemQuantity[keys[i]];
        if (typeof val == "string" && val.trim() != "") {
          newTotalItems = parseInt(val) + newTotalItems;
          if(keys[i] === "PHC" || keys[i] === "ICECHC" || keys[i] === "MOCHA"){
            newTotalCapsules = parseInt(val)*20 + newTotalCapsules;
          } else {
            newTotalCapsules = parseInt(val)*10 + newTotalCapsules;
          }
        }
      }
      //setTotal(newTotal);
      setTotalItems(newTotalItems);
      setTotalCapsules(newTotalCapsules);
    }
  }, [itemQuantity]);

  useEffect(() => {
    if (orderToReorder !== null) {
      // console.log(orderToReorder);
      setCompanyName(orderToReorder.company);
      setAlternativeCompanyName(orderToReorder.company);
      setEmployees(
        companies.find((c) => c.id === orderToReorder.companyFk).employee
      );
      setPerson({
        firstName: orderToReorder.firstName,
        lastName: orderToReorder.lastName,
        email: orderToReorder.customerEmail,
        phoneNumber: orderToReorder.phoneNumber,
      });
      setAccountsEmail(orderToReorder.accountsEmail);
      setCompanyAddress({
        address1: orderToReorder.address1,
        address2: orderToReorder.address2,
        zip: orderToReorder.zip,
        city: orderToReorder.city,
        country: orderToReorder.country,
      });
      setShippingAddress({
        name:
          orderToReorder.name !== null
            ? orderToReorder.name
            : orderToReorder.name,
        address1:
          orderToReorder.shippingAddress1 !== null
            ? orderToReorder.shippingAddress1
            : orderToReorder.address1,
        address2:
          orderToReorder.shippingAddress2 !== null
            ? orderToReorder.shippingAddress2
            : orderToReorder.address2,
        zip:
          orderToReorder.shippingZip !== null
            ? orderToReorder.shippingZip
            : orderToReorder.zip,
        city:
          orderToReorder.shippingCity !== null
            ? orderToReorder.shippingCity
            : orderToReorder.city,
        country:
          orderToReorder.shippingCountry !== null
            ? orderToReorder.shippingCountry
            : orderToReorder.country,
      });
      convertBdItemsToItemQuantity(lineItemsReorder);
      setPurchaseOrder(orderToReorder.purchaseOrder)
    }
  }, [orderToReorder]);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    document.getElementsByTagName("body")[0].style.overflow = "unset";
    return () => {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    };
  }, []);

  return (
    <div className="container-div">
      <Dialogy
        open={dialogyOpen}
        handleClose={() => {
          history.push("/");
        }}
        details={{
          title: "Success",
          text: "Order has been created",
          type: "",
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Autocomplete
                id="companies"
                freeSolo
                selectOnFocus
                disableClearable
                handleHomeEndKeys
                value={companyName}
                onChange={handleCompanyNameChange}
                fullWidth
                autoHighlight
                autoSelect
                options={companies}
                filterOptions={(options, params) =>
                  autoCompleteFilterOptions(options, params)
                }
                getOptionLabel={(option) =>
                  getAutoCompleteOptionlabel(option, "company")
                }
                renderInput={(params) => (
                  <WhiteTextField
                    {...params}
                    label="Company Name"
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
            {companyName !== "" && (
              <React.Fragment>
                <Grid item xs={12} md={12} lg={12}>
                  <WhiteTextField
                    className="business-direct-textfield"
                    label="Accounts Email"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setAccountsEmail(e.target.value);
                    }}
                    value={accountsEmail}
                    spellCheck
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography variant={"h6"} align="center">
                    Employee Info
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <Autocomplete
                    id="firstName"
                    // freeSolo
                    selectOnFocus
                    disableClearable
                    handleHomeEndKeys
                    value={person.firstName}
                    onChange={handleFirstNameChange}
                    fullWidth
                    autoHighlight
                    autoSelect
                    options={employees}
                    filterOptions={(options, params) =>
                      autoCompleteFilterOptions(options, params)
                    }
                    getOptionLabel={(option) =>
                      getAutoCompleteOptionlabel(option, "firstName")
                    }
                    renderInput={(params) => (
                      <WhiteTextField
                        {...params}
                        id="first-name-employee"
                        label="First Name"
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <Autocomplete
                    id="lastName"
                    freeSolo
                    selectOnFocus
                    disableClearable
                    handleHomeEndKeys
                    value={person.lastName}
                    onChange={handleLastNameChange}
                    fullWidth
                    autoHighlight
                    autoSelect
                    options={employees}
                    filterOptions={(options, params) =>
                      autoCompleteFilterOptions(options, params)
                    }
                    getOptionLabel={(option) =>
                      getAutoCompleteOptionlabel(option, "lastName")
                    }
                    renderInput={(params) => (
                      <WhiteTextField
                        {...params}
                        id="last-name-employee"
                        label="Last Name"
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <WhiteTextField
                    id="email-employee"
                    className="business-direct-textfield"
                    label="Email"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setPerson({
                        ...person,
                        email: e.target.value,
                      });
                    }}
                    value={person.email}
                    spellCheck
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <WhiteTextField
                    className="business-direct-textfield"
                    label="PhoneNumber"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setPerson({
                        ...person,
                        phoneNumber: e.target.value,
                      });
                    }}
                    value={person.phoneNumber}
                    spellCheck
                  />
                </Grid>
                {/* Company Address */}
                <Grid item xs={12} md={12} lg={12}>
                  <Typography variant={"h6"} align="center">
                    Company Address
                  </Typography>
                </Grid>
                {sameAsBilling && (
                  <Grid item xs={12} md={12} lg={12}>
                    <WhiteTextField
                      id="email-employee"
                      className="business-direct-textfield"
                      label="Company Name (For Shipping)"
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={alternativeCompanyName}
                      onChange={(e) => {
                        setAlternativeCompanyName(e.target.value);
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={12} lg={12}>
                  <Autocomplete
                    fullWidth
                    id="nzpost-address-dropdown"
                    options={companyAddresses}
                    loading={companyAddressOptionsLoading}
                    renderInput={(params) => (
                      <WhiteTextField
                        {...params}
                        label={"Search for an address"}
                        variant="outlined"
                        fullWidth
                        onChange={(e) =>
                          e.target.value !== null && e.target.value !== ""
                            ? getNZPostAddresses(e.target.value, false)
                            : ""
                        }
                        // onBlur={(e) =>
                        //   e.target.value !== null && e.target.value !== ""
                        //     ? getNZPostAddresses(e.target.value, false)
                        //     : ""
                        // }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                    onChange={(event, value) => {
                      if (value !== null && value !== "") {
                        handleAddressSelection(value, false);
                      } else {
                        setShippingAddress({
                          name: "",
                          address1: "",
                          address2: "",
                          city: "",
                          zip: "",
                          country: "",
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <WhiteTextField
                    className="business-direct-textfield"
                    label="Address 1"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setCompanyAddress({
                        ...companyAddress,
                        address1: e.target.value,
                      });
                    }}
                    value={companyAddress.address1}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <WhiteTextField
                    className="business-direct-textfield"
                    label="Address 2"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setCompanyAddress({
                        ...companyAddress,
                        address2: e.target.value,
                      });
                    }}
                    value={companyAddress.address2}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <WhiteTextField
                    className="business-direct-textfield"
                    label="City"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setCompanyAddress({
                        ...companyAddress,
                        city: e.target.value,
                      });
                    }}
                    value={companyAddress.city}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <WhiteTextField
                    className="business-direct-textfield"
                    label="Zip"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setCompanyAddress({
                        ...companyAddress,
                        zip: e.target.value,
                      });
                    }}
                    value={companyAddress.zip}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <WhiteTextField
                    className="business-direct-textfield"
                    label="Country"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setCompanyAddress({
                        ...companyAddress,
                        country: e.target.value,
                      });
                    }}
                    value={companyAddress.country}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6} alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{
                          color: "white",
                        }}
                        checked={sameAsBilling}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setShippingAddress({
                              name: companyName,
                              address1: companyAddress.address1,
                              address2: companyAddress.address2,
                              city: companyAddress.city,
                              zip: companyAddress.zip,
                              country: companyAddress.country,
                            });
                          }
                          setAlternativeCompanyName(companyName);
                          setShippingType("none");
                          setSameAsBiilng(e.target.checked);
                        }}
                      />
                    }
                    label="Shipping same as billing address"
                  />
                </Grid>
                {/* Shipping Address */}
                {!sameAsBilling && (
                  <React.Fragment>
                    <Grid item xs={12} md={12} lg={12}>
                      <Typography variant={"h6"} align="center">
                        Shipping Address
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Autocomplete
                        id="shipping-name-autocomplete"
                        // freeSolo
                        selectOnFocus
                        handleHomeEndKeys
                        value={shippingAddress.name != null ? shippingAddress.name : ""}
                        onChange={handleShippingNameChange}
                        fullWidth
                        autoHighlight
                        autoSelect
                        options={companyShipingAddresses}
                        filterOptions={(options, params) =>
                          autoCompleteFilterOptions(options, params)
                        }
                        getOptionLabel={(option) =>
                          getAutoCompleteOptionlabel(option, "shipping")
                        }
                        renderInput={(params) => (
                          <WhiteTextField
                            {...params}
                            id="shipping-name-text-field"
                            label="Shipping Company Name"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Autocomplete
                        fullWidth
                        id="nzpost-address-dropdown"
                        options={shippingAddresses}
                        loading={shippingAddressOptionsLoading}
                        renderInput={(params) => (
                          <WhiteTextField
                            {...params}
                            label={"Search for an address"}
                            variant="outlined"
                            fullWidth
                            onChange={(e) =>
                              e.target.value !== null &&
                                e.target.value !== ""
                                ? getNZPostAddresses(e.target.value, true)
                                : ""
                            }
                            // onBlur={(e) =>
                            //   e.target.value !== null &&
                            //     e.target.value !== ""
                            //     ? getNZPostAddresses(e.target.value, true)
                            //     : ""
                            // }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                        onChange={(event, value) => {
                          if (value !== null && value !== "") {
                            handleAddressSelection(value, true);
                          } else {
                            setShippingAddress({
                              name: "",
                              address1: "",
                              address2: "",
                              city: "",
                              zip: "",
                              country: "",
                            });
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <WhiteTextField
                        className="business-direct-textfield"
                        label="Address 1"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => {
                          setShippingAddress({
                            ...shippingAddress,
                            address1: e.target.value,
                          });
                        }}
                        value={shippingAddress.address1}
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <WhiteTextField
                        className="business-direct-textfield"
                        label="Address 2"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => {
                          setShippingAddress({
                            ...shippingAddress,
                            address2: e.target.value,
                          });
                        }}
                        value={shippingAddress.address2}
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <WhiteTextField
                        className="business-direct-textfield"
                        label="City"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => {
                          setShippingAddress({
                            ...shippingAddress,
                            city: e.target.value,
                          });
                        }}
                        value={shippingAddress.city}
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <WhiteTextField
                        className="business-direct-textfield"
                        label="Zip"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => {
                          setShippingAddress({
                            ...shippingAddress,
                            zip: e.target.value,
                          });
                        }}
                        value={shippingAddress.zip}
                      />
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                      <WhiteTextField
                        className="business-direct-textfield"
                        label="Country"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => {
                          setShippingAddress({
                            ...shippingAddress,
                            country: e.target.value,
                          });
                        }}
                        value={shippingAddress.country}
                      />
                    </Grid>
                  </React.Fragment>
                )}
                {/* Order Details */}
                <Grid item xs={12} md={12} lg={12}>
                  <Typography variant={"h6"} align="center">
                    Order Details
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <FormControl style={{ display: "flex" }}>
                    <InputLabel>Rate</InputLabel>
                    <Select
                      value={rate}
                      onChange={(e) => setRate(e.target.value)}
                      fullWidth
                      variant="standard"
                    >
                      {customerRates.map(cr => {
                        return <MenuItem value={cr.name}>{`${cr.name}`}</MenuItem>
                      })}
                      {/* <MenuItem value={"rate1"}>Retail</MenuItem>
                      <MenuItem value={"rate2"}>Wholesale</MenuItem> */}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <FormControl style={{ display: "flex" }}>
                    <InputLabel>Shipping Method</InputLabel>
                    {(sameAsBilling &&
                      parseInt(companyAddress.zip) <= 6999) ||
                      (!sameAsBilling &&
                        parseInt(shippingAddress.zip) <= 6999) ? (
                      <Select
                        value={shippingType}
                        onChange={(e) => setShippingType(e.target.value)}
                        fullWidth
                        variant="standard"
                      >
                        <MenuItem value={"none"}>None</MenuItem>
                        <MenuItem
                          value={"aucklandMetro"}
                        >{`Auckand (Metro)`}</MenuItem>
                        <MenuItem
                          value={"aucklandRural"}
                        >{`Auckand (Rural)`}</MenuItem>
                        <MenuItem
                          value={"northMetro"}
                        >{`North Island (Metro)`}</MenuItem>
                        <MenuItem
                          value={"northRural"}
                        >{`North Island (Rural)`}</MenuItem>
                      </Select>
                    ) : (
                      ""
                    )}
                    {(sameAsBilling &&
                      parseInt(companyAddress.zip) > 6999) ||
                      (!sameAsBilling &&
                        parseInt(shippingAddress.zip) > 6999) ? (
                      <Select
                        value={shippingType}
                        onChange={(e) => setShippingType(e.target.value)}
                        fullWidth
                        variant="standard"
                      >
                        <MenuItem value={"none"}>None</MenuItem>
                        <MenuItem
                          value={"southMetro"}
                        >{`South Island (Metro)`}</MenuItem>
                        <MenuItem
                          value={"southRural"}
                        >{`South Island (Rural)`}</MenuItem>
                      </Select>
                    ) : (
                      ""
                    )}
                    {(sameAsBilling && companyAddress.zip === "") ||
                      (sameAsBilling &&
                        parseInt(companyAddress.zip) === undefined) ||
                      (!sameAsBilling && shippingAddress.zip === "") ||
                      (!sameAsBilling &&
                        parseInt(shippingAddress.zip) === undefined) ? (
                      <Select
                        value={shippingType}
                        onChange={(e) => setShippingType(e.target.value)}
                        fullWidth
                        variant="standard"
                      >
                        <MenuItem value={"none"}>None</MenuItem>
                      </Select>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <WhiteTextField
                    className="business-direct-textfield"
                    label="Purchase Order"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setPurchaseOrder(e.target.value);
                    }}
                    value={purchaseOrder}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{
                          color: "white",
                        }}
                        checked={savePurchaseOrder}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSavePurchaseOrder(true);
                          } else {
                            setSavePurchaseOrder(false);
                          }
                        }}
                      />
                    }
                    label="Save purchase order for next time"
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <SkuItemTable
                    skuPriceItems={skuPriceItems}
                    itemQuantity={itemQuantity}
                    setItemQuantity={setItemQuantity}
                    //total={total}
                    totalItems={totalItems}
                    totalCapsules={totalCapsules}//edit here
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  {allFieldsComplete() && (
                    <div
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => placeOrder()}
                        style={{ width: "300px" }}
                      >
                        Place Order
                      </Button>
                    </div>
                  )}
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <Grid container spacing={2} style={{ marginTop: 15 }}>
            {refresh && <CircularProgress />}
            {!refresh && (
              <BusinessDirectHistory
                companyName={companyName}
                companies={companies}
                setOrderToReorder={setOrderToReorder}
                setLineItemsReorder={setLineItemsReorder}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  snackbar: state.snackbar,
});

export default connect(mapStateToProps, {
  setSnackBarProps,
})(BusinessDirect);
