import React, { useState, useEffect } from "react";
import {
  Modal,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  CircularProgress,
  Button,
} from "@material-ui/core";
import {
  Close as CloseIcon,
  OpenInBrowser as OpenInBrowserIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
// import PDFViewer from "pdf-viewer-reactjs";
import Axios from "axios";
import PdfViewer from "./PdfViewer";

const DocumentViewer = (props) => {
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
    },
    paper: {
      backgroundColor: "#fefefe", //theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 3),
      height: props.height,
      maxHeight: "85vH",
      width: props.width,
      overflowY: "auto",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const classes = useStyles();
  const [base64, setBase64] = useState(null);
  const [downloadedLabel, setDownloadedLabel] = useState(false);

  const [labelsBase64, setLabelsBase64] = useState([]);
  const [driveIds, setDriveIds] = useState([]);
  const [page, setPage] = useState(0);
  const [scale, setScale] = useState(1);
  const [reloadPdfViewer, setReloadPdfViewer] = useState(false);

  useEffect(() => {
    if (!downloadedLabel) {
      setDownloadedLabel(true);
      setDriveIds(props.driveId.split(","));
      Axios.get(`Orders/GetLabel/${props.driveId}`)
        .then((res) => {
          setLabelsBase64(res.data);
          setBase64(res.data[0]);
        })
        .catch((e) => {
          // console.log(e)
        });
    }
  }, [downloadedLabel]);

  useEffect(() => {
    if (reloadPdfViewer) {
      setReloadPdfViewer(false);
    }
  }, [reloadPdfViewer]);

  return (
    <Modal
      open={props.modalState}
      onClose={() => props.handleCloseModal()}
      className={classes.modal}
    >
      <div>
        <AppBar position="static" style={{ background: props.headerColor }}>
          <Toolbar variant="dense" style={{ paddingRight: 0 }}>
            <Typography
              variant="subtitle1"
              align="center"
              style={{ flexGrow: 1 }}
            >
              {props.title}
            </Typography>
            <span style={{ display: "flex" }}>
              {base64 && (
                <IconButton
                  variant="contained"
                  onClick={() => {
                    var driveId = props.driveId.split(",")[page];
                    window.open(
                      `https://drive.google.com/uc?id=${driveId}&export=view`
                    );
                  }}
                  style={{ color: "white" }}
                >
                  <OpenInBrowserIcon />
                </IconButton>
              )}
              <IconButton
                onClick={() => props.handleCloseModal()}
                color="inherit"
                style={{ color: "white" }}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </Toolbar>
        </AppBar>
        <div className={classes.paper}>
          {base64 && !reloadPdfViewer ? (
            <React.Fragment>
              <PdfViewer file={`data:application/pdf;base64,${base64}`} scale={scale}/>
              {/* <PDFViewer
                scale={scale}
                hideNavbar
                hideZoom
                hideRotation
                canvasCss={"canvasCss"}
                document={{
                  url: `data:application/pdf;base64,${base64}`,
                }}
                navigation={{
                  css: {
                    navbarWrapper: "navbarWrapper", // CSS Class for the Navbar Wrapper
                    zoomOutBtn: "navButton", // CSS Class for the ZoomOut Button
                    resetZoomBtn: "navButton", // CSS Class for the Reset Zoom Button
                    zoomInBtn: "navButton", // CSS Class for the ZoomIn Button
                    previousPageBtn: "navButton", // CSS Class for the PreviousPage button
                    pageIndicator: "navButton navPageIndicator", // CSS Class for the Page Indicator
                    nextPageBtn: "navButton", // CSS Class for the NextPage button
                    // rotateLeftBtn: "",  // CSS Class for the RotateLeft button
                    // resetRotationBtn: "",  // CSS Class for the Reset Rotation button
                    // rotateRightBtn: ""  // CSS Class for the RotateRight button
                  },
                }}
              /> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <IconButton
                  size="small"
                  onClick={() => {
                    // console.log(scale)
                    if (scale - 1 >= 1) {
                      setScale(scale - 1);
                      setReloadPdfViewer(true);
                    }
                  }}
                  color="inherit"
                  className="label-button"
                >
                  <ZoomOutIcon />
                </IconButton>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    if (page - 1 >= 0) {
                      setPage(page - 1);
                      setBase64(labelsBase64[page - 1]);
                    }
                  }}
                  className="label-button"
                >
                  Prev
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    if (page + 1 <= labelsBase64.length - 1) {
                      setPage(page + 1);
                      setBase64(labelsBase64[page + 1]);
                    }
                  }}
                  className="label-button"
                >
                  Next
                </Button>
                <IconButton
                  size="small"
                  onClick={() => {
                    // console.log(scale)
                    if (scale + 1 <= 5) {
                      setScale(scale + 1);
                      setReloadPdfViewer(true);
                    }
                  }}
                  color="inherit"
                  className="label-button"
                >
                  <ZoomInIcon />
                </IconButton>
              </div>
            </React.Fragment>
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default DocumentViewer;
