import React, { useState, useEffect } from 'react';
import {
    Button,
    Modal,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    TextField
} from "@material-ui/core";
import {
    Close as CloseIcon
} from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    MergeTagOptions
} from "../../Utils/ToolbarCustomOptions"
import {
    EditorState,
    convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    paper: {
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

const NewEmailTemplatePopUp = (props) => {
    const {
        modalState,
        handleClose,
        setGetTemplates,
        type
    } = props;

    const classes = useStyles();

    const [subject, setSubject] = useState("");
    const [body, setBody] = useState("");

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    };

    const saveChanges = () => {
        var html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        axios.post(`EmailTemplates/AddEmailTemplate`, {
            body: html,
            subject: subject,
            type: type
        }).then((res) => {
            // console.log(res);
            setGetTemplates(true);
            handleClose();
        })
    }


    return (
        <Modal
            open={modalState}
            disableBackdropClick
            onClose={() => {
                handleClose();
            }}
            className={"delayed-order-modal"}
        >
            <div>
                <AppBar position="static" style={{ background: "#27293d" }}>
                    <Toolbar variant="dense" style={{ paddingRight: 0 }}>
                        <Typography
                            style={{
                                fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                                width: "100%",
                                textAlign: "center",
                            }}
                            variant={"subtitle1"}
                            component={"span"}
                        >
                            New Email Template
                        </Typography>
                        <IconButton
                            onClick={() => {
                                handleClose();
                            }}
                            style={{
                                color: "white",
                                display: "block",
                                margin: "auto 0px auto auto",
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className={`${classes.paper} email-template-popup-paper`}>
                    <Typography
                        style={{
                            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                            width: "100%",
                            textAlign: "center",
                        }}
                        variant={"subtitle1"}
                        component={"span"}
                    >
                        Subject
                    </Typography>
                    <TextField
                        value={subject}
                        variant="outlined"
                        size="medium"
                        fullWidth
                        onChange={e => setSubject(e.target.value)}
                    />
                    <Typography
                        style={{
                            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                            width: "100%",
                            textAlign: "center",
                            paddingTop: 10
                        }}
                        variant={"subtitle1"}
                        component={"span"}
                    >
                        Body
                    </Typography>
                    <div className="editor-container-popup">
                        <Editor
                            editorState={editorState}
                            wrapperClassName="text-editor-wrapper"
                            editorClassName="editor-style-popup"
                            onEditorStateChange={onEditorStateChange}
                            handlePastedText={() => false}
                            toolbar={{
                                options: [],
                            }}
                            toolbarCustomButtons={[
                                <MergeTagOptions onChange={onEditorStateChange} editorState={editorState} />
                            ]}
                        />
                    </div>
                    <Button
                        onClick={() => saveChanges()}
                        variant="contained"
                        color="primary"
                    >
                        Save
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default NewEmailTemplatePopUp;