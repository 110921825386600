import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Tabs,
  Tab,
} from "@material-ui/core";


function a11yProps(index) {
  return {
    id: `stock-tab-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#27293d",
  },
}));

const NoteTabs = (props) => {
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    props.handleTabChange(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" id="stock-tab-app-bar">
        <Tabs value={props.activeTab} onChange={handleChange}>
          <Tab label="APPROVED" {...a11yProps(0)} />
          <Tab label="NOT APPLICABLE" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
    </div>
  );
};

export default NoteTabs;
