import React, { useState, useEffect } from "react";
import {
    Grid,
    Button,
    ButtonGroup,
    Popper,
    Grow,
    MenuItem,
    ClickAwayListener,
    Paper,
    MenuList,
  
  } from "@material-ui/core";
  import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";

const LinksButton = (props) => {
    const {
        courier,
        orderId,
        trackingNumber,
        orderNumber
    } = props;
    const splitButtonOptions = ["Links", "Shopify", courier, "RobotUI"];
    const anchorRef = React.useRef(null);

    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleMenuItemClick = (event, index) => {
      if (index === 1) {
        window.open(
          `https://simply-coffee-capsules.myshopify.com/admin/orders/${orderId}`
        );
      } else if (index === 2) {
        window.open(
         courier === "Aramex" ? `https://www.aramex.co.nz/tools/track/?l=${trackingNumber}` : `https://www.nzpost.co.nz/tools/tracking/item/${trackingNumber}`
        );
      } else if (index === 3) {
        window.open(
          `https://cc2u-robot-ui.ts.r.appspot.com/AllOrders/${orderNumber}`
        );
      }

      setOpen(false);
    };

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    return (
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <ButtonGroup
            variant="contained"
            color="primary"
            ref={anchorRef}
            aria-label="split button"
          >
            <Button size="small">{splitButtonOptions[selectedIndex]}</Button>
            <Button
              color="primary"
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: "99999999999999" }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-delayed-order">
                      {splitButtonOptions.map((option, index) => (
                        <MenuItem
                          key={option}
                          disabled={index === 0}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    );
  };

  export default LinksButton;