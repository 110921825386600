import {
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { Fragment, useEffect, useState } from "react";
import card from "../../img/note.PNG";

const OrderNote = ({
  order,
  messages,
  setSelectedMessage,
  selectedMessage,
}) => {
  const [displayCard, setdisplayCard] = useState(false);

  useEffect(() => {
    if(!selectedMessage && messages.length > 0){
        setSelectedMessage(messages[0])
    }
  }, [])
  
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Autocomplete
          fullWidth
          options={messages}
          getOptionLabel={(option) => option.message1}
          onChange={(event, value) => {
            setSelectedMessage(value);
          }}
          value={selectedMessage}
          renderInput={(params) => (
            <TextField
              {...params}
              label={"Note"}
              variant="outlined"
              fullWidth
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      </Grid>
      {/* <Grid item>
        <div className="handwritten-card-container message-table-car-container">
          {selectedMessage && (
            <Fragment>
              <img
                src={card}
                alt="card"
                width="450px"
                height="auto"
                onLoad={() => setdisplayCard(true)}
              />
              <div className="message-table-card-greeting-quickfix">
                <Typography
                  id="message-greeting-id"
                  className="robot-font-quickfix"
                >
                  {displayCard && selectedMessage.messageObj.greeting.text.slice(0, -9)}
                </Typography>
                <Typography className="message-table-card-message robot-font-quickfix">
                  {displayCard && selectedMessage.messageObj.body.lines.map((m) => (
                    <div className="message-body-id">{m.text}</div>
                  ))}
                </Typography>
                <Typography
                  id="message-signature-id"
                  className="message-table-card-signed robot-font-quickfix"
                >
                  { displayCard && selectedMessage.messageObj.signature.text}
                </Typography>
              </div>
            </Fragment>
          )}
        </div>
      </Grid> */}
    </Grid>
  );
};

export default OrderNote;
