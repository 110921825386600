export const ruralPostcodes = [
	{
		"SuburbName" : "Maungatapere",
		"Postcode" : "0170"
	},
	{
		"SuburbName" : "Maunu",
		"Postcode" : "0170"
	},
	{
		"SuburbName" : "Otaika",
		"Postcode" : "0170"
	},
	{
		"SuburbName" : "Puwera",
		"Postcode" : "0170"
	},
	{
		"SuburbName" : "Whangarei",
		"Postcode" : "0170"
	},
	{
		"SuburbName" : "Mangapai",
		"Postcode" : "0171"
	},
	{
		"SuburbName" : "Marsden Point",
		"Postcode" : "0171"
	},
	{
		"SuburbName" : "Mata",
		"Postcode" : "0171"
	},
	{
		"SuburbName" : "Oakleigh",
		"Postcode" : "0171"
	},
	{
		"SuburbName" : "One Tree Point",
		"Postcode" : "0171"
	},
	{
		"SuburbName" : "Portland",
		"Postcode" : "0171"
	},
	{
		"SuburbName" : "Puwera",
		"Postcode" : "0171"
	},
	{
		"SuburbName" : "Ruakaka",
		"Postcode" : "0171"
	},
	{
		"SuburbName" : "Springfield",
		"Postcode" : "0171"
	},
	{
		"SuburbName" : "Takahiwai",
		"Postcode" : "0171"
	},
	{
		"SuburbName" : "Waipu",
		"Postcode" : "0171"
	},
	{
		"SuburbName" : "Whangarei",
		"Postcode" : "0171"
	},
	{
		"SuburbName" : "Nukutawhiti",
		"Postcode" : "0172"
	},
	{
		"SuburbName" : "Pakotai",
		"Postcode" : "0172"
	},
	{
		"SuburbName" : "Parakao",
		"Postcode" : "0172"
	},
	{
		"SuburbName" : "Pipiwai",
		"Postcode" : "0172"
	},
	{
		"SuburbName" : "Poroti",
		"Postcode" : "0172"
	},
	{
		"SuburbName" : "Purua",
		"Postcode" : "0172"
	},
	{
		"SuburbName" : "Tangiteroria",
		"Postcode" : "0172"
	},
	{
		"SuburbName" : "Titoki",
		"Postcode" : "0172"
	},
	{
		"SuburbName" : "Whangarei",
		"Postcode" : "0172"
	},
	{
		"SuburbName" : "Glenbervie",
		"Postcode" : "0173"
	},
	{
		"SuburbName" : "Kiripaka",
		"Postcode" : "0173"
	},
	{
		"SuburbName" : "Matapouri",
		"Postcode" : "0173"
	},
	{
		"SuburbName" : "MATAPOURI",
		"Postcode" : "0173"
	},
	{
		"SuburbName" : "Ngunguru",
		"Postcode" : "0173"
	},
	{
		"SuburbName" : "Tutukaka",
		"Postcode" : "0173"
	},
	{
		"SuburbName" : "Whangarei",
		"Postcode" : "0173"
	},
	{
		"SuburbName" : "Whareora",
		"Postcode" : "0173"
	},
	{
		"SuburbName" : "Onerahi",
		"Postcode" : "0174"
	},
	{
		"SuburbName" : "Parua Bay",
		"Postcode" : "0174"
	},
	{
		"SuburbName" : "Tamaterau",
		"Postcode" : "0174"
	},
	{
		"SuburbName" : "Whangarei",
		"Postcode" : "0174"
	},
	{
		"SuburbName" : "Whangarei Heads",
		"Postcode" : "0174"
	},
	{
		"SuburbName" : "Abbey Caves",
		"Postcode" : "0175"
	},
	{
		"SuburbName" : "Glenbervie",
		"Postcode" : "0175"
	},
	{
		"SuburbName" : "Parahaki",
		"Postcode" : "0175"
	},
	{
		"SuburbName" : "Parua Bay",
		"Postcode" : "0175"
	},
	{
		"SuburbName" : "Pataua",
		"Postcode" : "0175"
	},
	{
		"SuburbName" : "Pataua",
		"Postcode" : "0175"
	},
	{
		"SuburbName" : "Tikipunga",
		"Postcode" : "0175"
	},
	{
		"SuburbName" : "Whangarei",
		"Postcode" : "0175"
	},
	{
		"SuburbName" : "Whareora",
		"Postcode" : "0175"
	},
	{
		"SuburbName" : "Kamo",
		"Postcode" : "0176"
	},
	{
		"SuburbName" : "Matarau",
		"Postcode" : "0176"
	},
	{
		"SuburbName" : "Ngararatunua",
		"Postcode" : "0176"
	},
	{
		"SuburbName" : "Pipiwai",
		"Postcode" : "0176"
	},
	{
		"SuburbName" : "Purua",
		"Postcode" : "0176"
	},
	{
		"SuburbName" : "Ruatangata West",
		"Postcode" : "0176"
	},
	{
		"SuburbName" : "Whangarei",
		"Postcode" : "0176"
	},
	{
		"SuburbName" : "Mangapai",
		"Postcode" : "0178"
	},
	{
		"SuburbName" : "Maungakaramea",
		"Postcode" : "0178"
	},
	{
		"SuburbName" : "Oakleigh",
		"Postcode" : "0178"
	},
	{
		"SuburbName" : "Otaika",
		"Postcode" : "0178"
	},
	{
		"SuburbName" : "Paparoa",
		"Postcode" : "0178"
	},
	{
		"SuburbName" : "Portland",
		"Postcode" : "0178"
	},
	{
		"SuburbName" : "Puwera",
		"Postcode" : "0178"
	},
	{
		"SuburbName" : "Raumanga",
		"Postcode" : "0178"
	},
	{
		"SuburbName" : "Springfield",
		"Postcode" : "0178"
	},
	{
		"SuburbName" : "Taipuha",
		"Postcode" : "0178"
	},
	{
		"SuburbName" : "Waiotira",
		"Postcode" : "0178"
	},
	{
		"SuburbName" : "Waipu",
		"Postcode" : "0178"
	},
	{
		"SuburbName" : "Whangarei",
		"Postcode" : "0178"
	},
	{
		"SuburbName" : "Wheki Valley",
		"Postcode" : "0178"
	},
	{
		"SuburbName" : "Kamo",
		"Postcode" : "0179"
	},
	{
		"SuburbName" : "Kokopu",
		"Postcode" : "0179"
	},
	{
		"SuburbName" : "Maungatapere",
		"Postcode" : "0179"
	},
	{
		"SuburbName" : "Maunu",
		"Postcode" : "0179"
	},
	{
		"SuburbName" : "Otaika",
		"Postcode" : "0179"
	},
	{
		"SuburbName" : "Poroti",
		"Postcode" : "0179"
	},
	{
		"SuburbName" : "Ruatangata West",
		"Postcode" : "0179"
	},
	{
		"SuburbName" : "Titoki",
		"Postcode" : "0179"
	},
	{
		"SuburbName" : "Whangarei",
		"Postcode" : "0179"
	},
	{
		"SuburbName" : "Wheki Valley",
		"Postcode" : "0179"
	},
	{
		"SuburbName" : "Glenbervie Forest",
		"Postcode" : "0181"
	},
	{
		"SuburbName" : "Hikurangi",
		"Postcode" : "0181"
	},
	{
		"SuburbName" : "Opuawhanga",
		"Postcode" : "0181"
	},
	{
		"SuburbName" : "Whakapara",
		"Postcode" : "0181"
	},
	{
		"SuburbName" : "Whananaki",
		"Postcode" : "0181"
	},
	{
		"SuburbName" : "Hikurangi",
		"Postcode" : "0182"
	},
	{
		"SuburbName" : "Hukerenui",
		"Postcode" : "0182"
	},
	{
		"SuburbName" : "Kamo",
		"Postcode" : "0182"
	},
	{
		"SuburbName" : "Kawakawa",
		"Postcode" : "0182"
	},
	{
		"SuburbName" : "Riponui",
		"Postcode" : "0182"
	},
	{
		"SuburbName" : "Ruatangata West",
		"Postcode" : "0182"
	},
	{
		"SuburbName" : "Towai",
		"Postcode" : "0182"
	},
	{
		"SuburbName" : "Whakapara",
		"Postcode" : "0182"
	},
	{
		"SuburbName" : "Helena Bay",
		"Postcode" : "0184"
	},
	{
		"SuburbName" : "Hikurangi",
		"Postcode" : "0184"
	},
	{
		"SuburbName" : "Oakura Bay",
		"Postcode" : "0184"
	},
	{
		"SuburbName" : "Opuawhanga",
		"Postcode" : "0184"
	},
	{
		"SuburbName" : "Parekura Bay",
		"Postcode" : "0184"
	},
	{
		"SuburbName" : "Punaruku",
		"Postcode" : "0184"
	},
	{
		"SuburbName" : "Rawhiti",
		"Postcode" : "0184"
	},
	{
		"SuburbName" : "Russell",
		"Postcode" : "0184"
	},
	{
		"SuburbName" : "Whakapara",
		"Postcode" : "0184"
	},
	{
		"SuburbName" : "Whangaruru",
		"Postcode" : "0184"
	},
	{
		"SuburbName" : "Hikurangi",
		"Postcode" : "0185"
	},
	{
		"SuburbName" : "Kamo",
		"Postcode" : "0185"
	},
	{
		"SuburbName" : "Kauri",
		"Postcode" : "0185"
	},
	{
		"SuburbName" : "Matarau",
		"Postcode" : "0185"
	},
	{
		"SuburbName" : "Ngararatunua",
		"Postcode" : "0185"
	},
	{
		"SuburbName" : "Riponui",
		"Postcode" : "0185"
	},
	{
		"SuburbName" : "Ruatangata West",
		"Postcode" : "0185"
	},
	{
		"SuburbName" : "Whangarei",
		"Postcode" : "0185"
	},
	{
		"SuburbName" : "Onerahi",
		"Postcode" : "0192"
	},
	{
		"SuburbName" : "Parua Bay",
		"Postcode" : "0192"
	},
	{
		"SuburbName" : "Pataua",
		"Postcode" : "0192"
	},
	{
		"SuburbName" : "Pataua",
		"Postcode" : "0192"
	},
	{
		"SuburbName" : "Taiharuru",
		"Postcode" : "0192"
	},
	{
		"SuburbName" : "Tamaterau",
		"Postcode" : "0192"
	},
	{
		"SuburbName" : "Whangarei",
		"Postcode" : "0192"
	},
	{
		"SuburbName" : "Whareora",
		"Postcode" : "0192"
	},
	{
		"SuburbName" : "Omana",
		"Postcode" : "0193"
	},
	{
		"SuburbName" : "Paparoa",
		"Postcode" : "0193"
	},
	{
		"SuburbName" : "Tangiteroria",
		"Postcode" : "0193"
	},
	{
		"SuburbName" : "Waiotira",
		"Postcode" : "0193"
	},
	{
		"SuburbName" : "Kawakawa",
		"Postcode" : "0272"
	},
	{
		"SuburbName" : "Okiato",
		"Postcode" : "0272"
	},
	{
		"SuburbName" : "Opua",
		"Postcode" : "0272"
	},
	{
		"SuburbName" : "Parekura Bay",
		"Postcode" : "0272"
	},
	{
		"SuburbName" : "Russell",
		"Postcode" : "0272"
	},
	{
		"SuburbName" : "Hikurangi",
		"Postcode" : "0281"
	},
	{
		"SuburbName" : "Kaikohe",
		"Postcode" : "0281"
	},
	{
		"SuburbName" : "Kawakawa",
		"Postcode" : "0281"
	},
	{
		"SuburbName" : "Maromaku",
		"Postcode" : "0281"
	},
	{
		"SuburbName" : "Matawaia",
		"Postcode" : "0281"
	},
	{
		"SuburbName" : "Moerewa",
		"Postcode" : "0281"
	},
	{
		"SuburbName" : "Motatau",
		"Postcode" : "0281"
	},
	{
		"SuburbName" : "Ohaeawai",
		"Postcode" : "0281"
	},
	{
		"SuburbName" : "Pipiwai",
		"Postcode" : "0281"
	},
	{
		"SuburbName" : "Towai",
		"Postcode" : "0281"
	},
	{
		"SuburbName" : "Waiomio",
		"Postcode" : "0281"
	},
	{
		"SuburbName" : "Whangarei",
		"Postcode" : "0281"
	},
	{
		"SuburbName" : "Hikurangi",
		"Postcode" : "0282"
	},
	{
		"SuburbName" : "Hukerenui",
		"Postcode" : "0282"
	},
	{
		"SuburbName" : "Karetu",
		"Postcode" : "0282"
	},
	{
		"SuburbName" : "Kawakawa",
		"Postcode" : "0282"
	},
	{
		"SuburbName" : "Opua",
		"Postcode" : "0282"
	},
	{
		"SuburbName" : "Karetu",
		"Postcode" : "0283"
	},
	{
		"SuburbName" : "Kawakawa",
		"Postcode" : "0283"
	},
	{
		"SuburbName" : "Russell",
		"Postcode" : "0283"
	},
	{
		"SuburbName" : "Waikare",
		"Postcode" : "0283"
	},
	{
		"SuburbName" : "Haruru",
		"Postcode" : "0293"
	},
	{
		"SuburbName" : "Kerikeri",
		"Postcode" : "0293"
	},
	{
		"SuburbName" : "Oromahoe",
		"Postcode" : "0293"
	},
	{
		"SuburbName" : "Waimate North",
		"Postcode" : "0293"
	},
	{
		"SuburbName" : "Waitangi",
		"Postcode" : "0293"
	},
	{
		"SuburbName" : "Kerikeri",
		"Postcode" : "0294"
	},
	{
		"SuburbName" : "Te Tii",
		"Postcode" : "0294"
	},
	{
		"SuburbName" : "Kaeo",
		"Postcode" : "0295"
	},
	{
		"SuburbName" : "Kerikeri",
		"Postcode" : "0295"
	},
	{
		"SuburbName" : "Okaihau",
		"Postcode" : "0295"
	},
	{
		"SuburbName" : "Waipapa",
		"Postcode" : "0295"
	},
	{
		"SuburbName" : "Arapohue",
		"Postcode" : "0370"
	},
	{
		"SuburbName" : "Dargaville",
		"Postcode" : "0370"
	},
	{
		"SuburbName" : "Aratapu",
		"Postcode" : "0371"
	},
	{
		"SuburbName" : "Dargaville",
		"Postcode" : "0371"
	},
	{
		"SuburbName" : "Mahuta",
		"Postcode" : "0371"
	},
	{
		"SuburbName" : "Te Kopuru",
		"Postcode" : "0371"
	},
	{
		"SuburbName" : "Awakino Point",
		"Postcode" : "0372"
	},
	{
		"SuburbName" : "Dargaville",
		"Postcode" : "0372"
	},
	{
		"SuburbName" : "Mamaranui",
		"Postcode" : "0372"
	},
	{
		"SuburbName" : "Parore",
		"Postcode" : "0372"
	},
	{
		"SuburbName" : "Tangiteroria",
		"Postcode" : "0372"
	},
	{
		"SuburbName" : "Tangowahine",
		"Postcode" : "0372"
	},
	{
		"SuburbName" : "Dargaville",
		"Postcode" : "0373"
	},
	{
		"SuburbName" : "Kaihu",
		"Postcode" : "0373"
	},
	{
		"SuburbName" : "Mamaranui",
		"Postcode" : "0373"
	},
	{
		"SuburbName" : "Omamari",
		"Postcode" : "0373"
	},
	{
		"SuburbName" : "Parore",
		"Postcode" : "0373"
	},
	{
		"SuburbName" : "Arapohue",
		"Postcode" : "0374"
	},
	{
		"SuburbName" : "Dargaville",
		"Postcode" : "0374"
	},
	{
		"SuburbName" : "Hoanga",
		"Postcode" : "0374"
	},
	{
		"SuburbName" : "Turiwiri",
		"Postcode" : "0374"
	},
	{
		"SuburbName" : "Aranga",
		"Postcode" : "0376"
	},
	{
		"SuburbName" : "Dargaville",
		"Postcode" : "0376"
	},
	{
		"SuburbName" : "Donnellys Crossing",
		"Postcode" : "0376"
	},
	{
		"SuburbName" : "Kaihu",
		"Postcode" : "0376"
	},
	{
		"SuburbName" : "Kaikohe",
		"Postcode" : "0376"
	},
	{
		"SuburbName" : "Waima Forest",
		"Postcode" : "0376"
	},
	{
		"SuburbName" : "Waipoua",
		"Postcode" : "0376"
	},
	{
		"SuburbName" : "Waipoua Forest",
		"Postcode" : "0376"
	},
	{
		"SuburbName" : "Waipoua Kauri Forest",
		"Postcode" : "0376"
	},
	{
		"SuburbName" : "Baylys Beach",
		"Postcode" : "0377"
	},
	{
		"SuburbName" : "Dargaville",
		"Postcode" : "0377"
	},
	{
		"SuburbName" : "Parore",
		"Postcode" : "0377"
	},
	{
		"SuburbName" : "Dargaville",
		"Postcode" : "0379"
	},
	{
		"SuburbName" : "Donnellys Crossing",
		"Postcode" : "0379"
	},
	{
		"SuburbName" : "Kaihu",
		"Postcode" : "0379"
	},
	{
		"SuburbName" : "Arapohue",
		"Postcode" : "0381"
	},
	{
		"SuburbName" : "Dargaville",
		"Postcode" : "0381"
	},
	{
		"SuburbName" : "Omana",
		"Postcode" : "0381"
	},
	{
		"SuburbName" : "Pukehuia",
		"Postcode" : "0381"
	},
	{
		"SuburbName" : "Tangiteroria",
		"Postcode" : "0381"
	},
	{
		"SuburbName" : "Pouto",
		"Postcode" : "0391"
	},
	{
		"SuburbName" : "Te Kopuru",
		"Postcode" : "0391"
	},
	{
		"SuburbName" : "Haruru",
		"Postcode" : "0472"
	},
	{
		"SuburbName" : "Kaikohe",
		"Postcode" : "0472"
	},
	{
		"SuburbName" : "Kawakawa",
		"Postcode" : "0472"
	},
	{
		"SuburbName" : "Kerikeri",
		"Postcode" : "0472"
	},
	{
		"SuburbName" : "Moerewa",
		"Postcode" : "0472"
	},
	{
		"SuburbName" : "Ngawha Springs",
		"Postcode" : "0472"
	},
	{
		"SuburbName" : "Ohaeawai",
		"Postcode" : "0472"
	},
	{
		"SuburbName" : "Okaihau",
		"Postcode" : "0472"
	},
	{
		"SuburbName" : "Opua",
		"Postcode" : "0472"
	},
	{
		"SuburbName" : "Opua Forest",
		"Postcode" : "0472"
	},
	{
		"SuburbName" : "Oromahoe",
		"Postcode" : "0472"
	},
	{
		"SuburbName" : "Pakaraka",
		"Postcode" : "0472"
	},
	{
		"SuburbName" : "Waimate North",
		"Postcode" : "0472"
	},
	{
		"SuburbName" : "Horeke",
		"Postcode" : "0473"
	},
	{
		"SuburbName" : "Kaikohe",
		"Postcode" : "0473"
	},
	{
		"SuburbName" : "Okaihau",
		"Postcode" : "0473"
	},
	{
		"SuburbName" : "Omanaia",
		"Postcode" : "0473"
	},
	{
		"SuburbName" : "Omapere",
		"Postcode" : "0473"
	},
	{
		"SuburbName" : "Opononi",
		"Postcode" : "0473"
	},
	{
		"SuburbName" : "Otaua",
		"Postcode" : "0473"
	},
	{
		"SuburbName" : "Oue",
		"Postcode" : "0473"
	},
	{
		"SuburbName" : "Rawene",
		"Postcode" : "0473"
	},
	{
		"SuburbName" : "Taheke",
		"Postcode" : "0473"
	},
	{
		"SuburbName" : "Waima",
		"Postcode" : "0473"
	},
	{
		"SuburbName" : "Waimamaku",
		"Postcode" : "0473"
	},
	{
		"SuburbName" : "Whirinaki",
		"Postcode" : "0473"
	},
	{
		"SuburbName" : "Awarua",
		"Postcode" : "0474"
	},
	{
		"SuburbName" : "Donnellys Crossing",
		"Postcode" : "0474"
	},
	{
		"SuburbName" : "Kaikohe",
		"Postcode" : "0474"
	},
	{
		"SuburbName" : "Nukutawhiti",
		"Postcode" : "0474"
	},
	{
		"SuburbName" : "Otaua",
		"Postcode" : "0474"
	},
	{
		"SuburbName" : "Punakitere Valley",
		"Postcode" : "0474"
	},
	{
		"SuburbName" : "Tautoro",
		"Postcode" : "0474"
	},
	{
		"SuburbName" : "Whangarei",
		"Postcode" : "0474"
	},
	{
		"SuburbName" : "Horeke",
		"Postcode" : "0475"
	},
	{
		"SuburbName" : "Kaikohe",
		"Postcode" : "0475"
	},
	{
		"SuburbName" : "Kerikeri",
		"Postcode" : "0475"
	},
	{
		"SuburbName" : "Ohaeawai",
		"Postcode" : "0475"
	},
	{
		"SuburbName" : "Okaihau",
		"Postcode" : "0475"
	},
	{
		"SuburbName" : "Horeke",
		"Postcode" : "0476"
	},
	{
		"SuburbName" : "Mangamuka",
		"Postcode" : "0476"
	},
	{
		"SuburbName" : "Okaihau",
		"Postcode" : "0476"
	},
	{
		"SuburbName" : "Puketi Forest",
		"Postcode" : "0476"
	},
	{
		"SuburbName" : "Umawera",
		"Postcode" : "0476"
	},
	{
		"SuburbName" : "Kaeo",
		"Postcode" : "0478"
	},
	{
		"SuburbName" : "Matauri Bay",
		"Postcode" : "0478"
	},
	{
		"SuburbName" : "Okaihau",
		"Postcode" : "0478"
	},
	{
		"SuburbName" : "Whangaroa",
		"Postcode" : "0478"
	},
	{
		"SuburbName" : "Kaeo",
		"Postcode" : "0479"
	},
	{
		"SuburbName" : "Kaitaia",
		"Postcode" : "0479"
	},
	{
		"SuburbName" : "Mangamuka",
		"Postcode" : "0479"
	},
	{
		"SuburbName" : "Okaihau",
		"Postcode" : "0479"
	},
	{
		"SuburbName" : "Peria",
		"Postcode" : "0479"
	},
	{
		"SuburbName" : "Totara North",
		"Postcode" : "0479"
	},
	{
		"SuburbName" : "Ahipara",
		"Postcode" : "0481"
	},
	{
		"SuburbName" : "Broadwood",
		"Postcode" : "0481"
	},
	{
		"SuburbName" : "Herekino",
		"Postcode" : "0481"
	},
	{
		"SuburbName" : "Herekino Forest",
		"Postcode" : "0481"
	},
	{
		"SuburbName" : "Kaitaia",
		"Postcode" : "0481"
	},
	{
		"SuburbName" : "Kohukohu",
		"Postcode" : "0481"
	},
	{
		"SuburbName" : "Takahue",
		"Postcode" : "0481"
	},
	{
		"SuburbName" : "Awanui",
		"Postcode" : "0482"
	},
	{
		"SuburbName" : "Kaingaroa",
		"Postcode" : "0482"
	},
	{
		"SuburbName" : "Kaitaia",
		"Postcode" : "0482"
	},
	{
		"SuburbName" : "Karikari Peninsula",
		"Postcode" : "0482"
	},
	{
		"SuburbName" : "Lake Ohia",
		"Postcode" : "0482"
	},
	{
		"SuburbName" : "Peria",
		"Postcode" : "0482"
	},
	{
		"SuburbName" : "Ahipara",
		"Postcode" : "0483"
	},
	{
		"SuburbName" : "Awanui",
		"Postcode" : "0483"
	},
	{
		"SuburbName" : "Kaingaroa",
		"Postcode" : "0483"
	},
	{
		"SuburbName" : "Kaitaia",
		"Postcode" : "0483"
	},
	{
		"SuburbName" : "Karikari Peninsula",
		"Postcode" : "0483"
	},
	{
		"SuburbName" : "Lake Ohia",
		"Postcode" : "0483"
	},
	{
		"SuburbName" : "Mangonui",
		"Postcode" : "0483"
	},
	{
		"SuburbName" : "Peria",
		"Postcode" : "0483"
	},
	{
		"SuburbName" : "Taipa",
		"Postcode" : "0483"
	},
	{
		"SuburbName" : "Awanui",
		"Postcode" : "0484"
	},
	{
		"SuburbName" : "Cape Reinga",
		"Postcode" : "0484"
	},
	{
		"SuburbName" : "Houhora",
		"Postcode" : "0484"
	},
	{
		"SuburbName" : "Kaitaia",
		"Postcode" : "0484"
	},
	{
		"SuburbName" : "Ngataki",
		"Postcode" : "0484"
	},
	{
		"SuburbName" : "Pukenui",
		"Postcode" : "0484"
	},
	{
		"SuburbName" : "Pukenui",
		"Postcode" : "0484"
	},
	{
		"SuburbName" : "Te Hapua",
		"Postcode" : "0484"
	},
	{
		"SuburbName" : "Te Kao",
		"Postcode" : "0484"
	},
	{
		"SuburbName" : "Waiharara",
		"Postcode" : "0484"
	},
	{
		"SuburbName" : "Waipapakauri",
		"Postcode" : "0484"
	},
	{
		"SuburbName" : "Awanui",
		"Postcode" : "0486"
	},
	{
		"SuburbName" : "Kaimaumau",
		"Postcode" : "0486"
	},
	{
		"SuburbName" : "Paparore",
		"Postcode" : "0486"
	},
	{
		"SuburbName" : "Waiharara",
		"Postcode" : "0486"
	},
	{
		"SuburbName" : "Waipapakauri",
		"Postcode" : "0486"
	},
	{
		"SuburbName" : "Broadwood",
		"Postcode" : "0491"
	},
	{
		"SuburbName" : "Kohukohu",
		"Postcode" : "0491"
	},
	{
		"SuburbName" : "Mangamuka",
		"Postcode" : "0491"
	},
	{
		"SuburbName" : "Okaihau",
		"Postcode" : "0491"
	},
	{
		"SuburbName" : "Kohukohu",
		"Postcode" : "0492"
	},
	{
		"SuburbName" : "Mitimiti",
		"Postcode" : "0492"
	},
	{
		"SuburbName" : "Panguru",
		"Postcode" : "0492"
	},
	{
		"SuburbName" : "Hihi",
		"Postcode" : "0494"
	},
	{
		"SuburbName" : "Kaeo",
		"Postcode" : "0494"
	},
	{
		"SuburbName" : "Kaitaia",
		"Postcode" : "0494"
	},
	{
		"SuburbName" : "Kerikeri",
		"Postcode" : "0494"
	},
	{
		"SuburbName" : "Mangonui",
		"Postcode" : "0494"
	},
	{
		"SuburbName" : "Paewhenua Island",
		"Postcode" : "0494"
	},
	{
		"SuburbName" : "Peria",
		"Postcode" : "0494"
	},
	{
		"SuburbName" : "Taupo Bay",
		"Postcode" : "0494"
	},
	{
		"SuburbName" : "Totara North",
		"Postcode" : "0494"
	},
	{
		"SuburbName" : "Broadwood",
		"Postcode" : "0496"
	},
	{
		"SuburbName" : "Kohukohu",
		"Postcode" : "0496"
	},
	{
		"SuburbName" : "Panguru",
		"Postcode" : "0496"
	},
	{
		"SuburbName" : "Pawarenga",
		"Postcode" : "0496"
	},
	{
		"SuburbName" : "Mareretu",
		"Postcode" : "0571"
	},
	{
		"SuburbName" : "Maungaturoto",
		"Postcode" : "0571"
	},
	{
		"SuburbName" : "Northland",
		"Postcode" : "0571"
	},
	{
		"SuburbName" : "Pahi",
		"Postcode" : "0571"
	},
	{
		"SuburbName" : "Paparoa",
		"Postcode" : "0571"
	},
	{
		"SuburbName" : "Taipuha",
		"Postcode" : "0571"
	},
	{
		"SuburbName" : "Hakaru",
		"Postcode" : "0573"
	},
	{
		"SuburbName" : "Kaiwaka",
		"Postcode" : "0573"
	},
	{
		"SuburbName" : "Mangawhai",
		"Postcode" : "0573"
	},
	{
		"SuburbName" : "Mangawhai Heads",
		"Postcode" : "0573"
	},
	{
		"SuburbName" : "Langs Beach",
		"Postcode" : "0582"
	},
	{
		"SuburbName" : "Paparoa",
		"Postcode" : "0582"
	},
	{
		"SuburbName" : "Ruakaka",
		"Postcode" : "0582"
	},
	{
		"SuburbName" : "Springfield",
		"Postcode" : "0582"
	},
	{
		"SuburbName" : "Taipuha",
		"Postcode" : "0582"
	},
	{
		"SuburbName" : "Waipu",
		"Postcode" : "0582"
	},
	{
		"SuburbName" : "Whangarei",
		"Postcode" : "0582"
	},
	{
		"SuburbName" : "Maungaturoto",
		"Postcode" : "0583"
	},
	{
		"SuburbName" : "Paparoa",
		"Postcode" : "0583"
	},
	{
		"SuburbName" : "Whakapirau",
		"Postcode" : "0583"
	},
	{
		"SuburbName" : "Brynderwyn",
		"Postcode" : "0587"
	},
	{
		"SuburbName" : "Maungaturoto",
		"Postcode" : "0587"
	},
	{
		"SuburbName" : "Waipu",
		"Postcode" : "0587"
	},
	{
		"SuburbName" : "Maungaturoto",
		"Postcode" : "0588"
	},
	{
		"SuburbName" : "Mareretu",
		"Postcode" : "0589"
	},
	{
		"SuburbName" : "Maungaturoto",
		"Postcode" : "0589"
	},
	{
		"SuburbName" : "Paparoa",
		"Postcode" : "0589"
	},
	{
		"SuburbName" : "Taipuha",
		"Postcode" : "0589"
	},
	{
		"SuburbName" : "Matakohe",
		"Postcode" : "0591"
	},
	{
		"SuburbName" : "Ruawai",
		"Postcode" : "0591"
	},
	{
		"SuburbName" : "Arapohue",
		"Postcode" : "0592"
	},
	{
		"SuburbName" : "Dargaville",
		"Postcode" : "0592"
	},
	{
		"SuburbName" : "Ruawai",
		"Postcode" : "0592"
	},
	{
		"SuburbName" : "Matakohe",
		"Postcode" : "0593"
	},
	{
		"SuburbName" : "Tinopai",
		"Postcode" : "0593"
	},
	{
		"SuburbName" : "Ararua",
		"Postcode" : "0594"
	},
	{
		"SuburbName" : "Matakohe",
		"Postcode" : "0594"
	},
	{
		"SuburbName" : "Paparoa",
		"Postcode" : "0594"
	},
	{
		"SuburbName" : "Ruawai",
		"Postcode" : "0594"
	},
	{
		"SuburbName" : "Waitakere",
		"Postcode" : "0600"
	},
	{
		"SuburbName" : "Waitakere",
		"Postcode" : "0602"
	},
	{
		"SuburbName" : "Waitakere",
		"Postcode" : "0610"
	},
	{
		"SuburbName" : "Waitakere",
		"Postcode" : "0612"
	},
	{
		"SuburbName" : "Waitakere",
		"Postcode" : "0663"
	},
	{
		"SuburbName" : "Anawhata",
		"Postcode" : "0772"
	},
	{
		"SuburbName" : "Auckland",
		"Postcode" : "0772"
	},
	{
		"SuburbName" : "Karekare",
		"Postcode" : "0772"
	},
	{
		"SuburbName" : "New Lynn",
		"Postcode" : "0772"
	},
	{
		"SuburbName" : "Piha",
		"Postcode" : "0772"
	},
	{
		"SuburbName" : "Waitakere",
		"Postcode" : "0772"
	},
	{
		"SuburbName" : "Anawhata",
		"Postcode" : "0781"
	},
	{
		"SuburbName" : "Auckland",
		"Postcode" : "0781"
	},
	{
		"SuburbName" : "Bethells Beach",
		"Postcode" : "0781"
	},
	{
		"SuburbName" : "Henderson",
		"Postcode" : "0781"
	},
	{
		"SuburbName" : "Muriwai",
		"Postcode" : "0781"
	},
	{
		"SuburbName" : "Swanson",
		"Postcode" : "0781"
	},
	{
		"SuburbName" : "Waitakere",
		"Postcode" : "0781"
	},
	{
		"SuburbName" : "Auckland",
		"Postcode" : "0782"
	},
	{
		"SuburbName" : "Henderson",
		"Postcode" : "0782"
	},
	{
		"SuburbName" : "Kumeu",
		"Postcode" : "0782"
	},
	{
		"SuburbName" : "Massey",
		"Postcode" : "0782"
	},
	{
		"SuburbName" : "Taupaki",
		"Postcode" : "0782"
	},
	{
		"SuburbName" : "Waitakere",
		"Postcode" : "0782"
	},
	{
		"SuburbName" : "Albany",
		"Postcode" : "0792"
	},
	{
		"SuburbName" : "Albany Heights",
		"Postcode" : "0792"
	},
	{
		"SuburbName" : "Auckland",
		"Postcode" : "0792"
	},
	{
		"SuburbName" : "Coatesville",
		"Postcode" : "0792"
	},
	{
		"SuburbName" : "Dairy Flat",
		"Postcode" : "0792"
	},
	{
		"SuburbName" : "Long Bay",
		"Postcode" : "0792"
	},
	{
		"SuburbName" : "Lucas Heights",
		"Postcode" : "0792"
	},
	{
		"SuburbName" : "North Shore City",
		"Postcode" : "0792"
	},
	{
		"SuburbName" : "Okura",
		"Postcode" : "0792"
	},
	{
		"SuburbName" : "Redvale",
		"Postcode" : "0792"
	},
	{
		"SuburbName" : "Silverdale",
		"Postcode" : "0792"
	},
	{
		"SuburbName" : "Albany",
		"Postcode" : "0793"
	},
	{
		"SuburbName" : "Albany Heights",
		"Postcode" : "0793"
	},
	{
		"SuburbName" : "Auckland",
		"Postcode" : "0793"
	},
	{
		"SuburbName" : "Coatesville",
		"Postcode" : "0793"
	},
	{
		"SuburbName" : "Dairy Flat",
		"Postcode" : "0793"
	},
	{
		"SuburbName" : "Lucas Heights",
		"Postcode" : "0793"
	},
	{
		"SuburbName" : "North Shore City",
		"Postcode" : "0793"
	},
	{
		"SuburbName" : "Paremoremo",
		"Postcode" : "0793"
	},
	{
		"SuburbName" : "Riverhead",
		"Postcode" : "0793"
	},
	{
		"SuburbName" : "Albany",
		"Postcode" : "0794"
	},
	{
		"SuburbName" : "Auckland",
		"Postcode" : "0794"
	},
	{
		"SuburbName" : "Coatesville",
		"Postcode" : "0794"
	},
	{
		"SuburbName" : "Dairy Flat",
		"Postcode" : "0794"
	},
	{
		"SuburbName" : "Okura Bush",
		"Postcode" : "0794"
	},
	{
		"SuburbName" : "Redvale",
		"Postcode" : "0794"
	},
	{
		"SuburbName" : "Silverdale",
		"Postcode" : "0794"
	},
	{
		"SuburbName" : "Stillwater",
		"Postcode" : "0794"
	},
	{
		"SuburbName" : "Waitoki",
		"Postcode" : "0794"
	},
	{
		"SuburbName" : "Dairy Flat",
		"Postcode" : "0871"
	},
	{
		"SuburbName" : "Helensville",
		"Postcode" : "0871"
	},
	{
		"SuburbName" : "Kaukapakapa",
		"Postcode" : "0871"
	},
	{
		"SuburbName" : "Wainui",
		"Postcode" : "0871"
	},
	{
		"SuburbName" : "Waitoki",
		"Postcode" : "0871"
	},
	{
		"SuburbName" : "Kaukapakapa",
		"Postcode" : "0873"
	},
	{
		"SuburbName" : "Makarau",
		"Postcode" : "0873"
	},
	{
		"SuburbName" : "Orewa",
		"Postcode" : "0873"
	},
	{
		"SuburbName" : "Puhoi",
		"Postcode" : "0873"
	},
	{
		"SuburbName" : "Tahekeroa",
		"Postcode" : "0873"
	},
	{
		"SuburbName" : "Wainui",
		"Postcode" : "0873"
	},
	{
		"SuburbName" : "Waiwera",
		"Postcode" : "0873"
	},
	{
		"SuburbName" : "Warkworth",
		"Postcode" : "0873"
	},
	{
		"SuburbName" : "Helensville",
		"Postcode" : "0874"
	},
	{
		"SuburbName" : "Parakai",
		"Postcode" : "0874"
	},
	{
		"SuburbName" : "Shelly Beach",
		"Postcode" : "0874"
	},
	{
		"SuburbName" : "South Head",
		"Postcode" : "0874"
	},
	{
		"SuburbName" : "Woodhill Forest",
		"Postcode" : "0874"
	},
	{
		"SuburbName" : "Helensville",
		"Postcode" : "0875"
	},
	{
		"SuburbName" : "Kaukapakapa",
		"Postcode" : "0875"
	},
	{
		"SuburbName" : "Muriwai",
		"Postcode" : "0875"
	},
	{
		"SuburbName" : "Waimauku",
		"Postcode" : "0875"
	},
	{
		"SuburbName" : "Waitoki",
		"Postcode" : "0875"
	},
	{
		"SuburbName" : "Muriwai",
		"Postcode" : "0881"
	},
	{
		"SuburbName" : "MURIWAI BEACH",
		"Postcode" : "0881"
	},
	{
		"SuburbName" : "Waimauku",
		"Postcode" : "0881"
	},
	{
		"SuburbName" : "Waimauku",
		"Postcode" : "0882"
	},
	{
		"SuburbName" : "Helensville",
		"Postcode" : "0882"
	},
	{
		"SuburbName" : "Waimauku",
		"Postcode" : "0882"
	},
	{
		"SuburbName" : "Kumeu",
		"Postcode" : "0883"
	},
	{
		"SuburbName" : "Muriwai",
		"Postcode" : "0883"
	},
	{
		"SuburbName" : "Waimauku",
		"Postcode" : "0883"
	},
	{
		"SuburbName" : "Huapai",
		"Postcode" : "0891"
	},
	{
		"SuburbName" : "Kumeu",
		"Postcode" : "0891"
	},
	{
		"SuburbName" : "Waimauku",
		"Postcode" : "0891"
	},
	{
		"SuburbName" : "Huapai",
		"Postcode" : "0892"
	},
	{
		"SuburbName" : "Kumeu",
		"Postcode" : "0892"
	},
	{
		"SuburbName" : "Riverhead",
		"Postcode" : "0892"
	},
	{
		"SuburbName" : "Taupaki",
		"Postcode" : "0892"
	},
	{
		"SuburbName" : "Leigh",
		"Postcode" : "0972"
	},
	{
		"SuburbName" : "Pakiri",
		"Postcode" : "0972"
	},
	{
		"SuburbName" : "Tauhoa",
		"Postcode" : "0972"
	},
	{
		"SuburbName" : "Tomarata",
		"Postcode" : "0972"
	},
	{
		"SuburbName" : "Wayby Valley",
		"Postcode" : "0972"
	},
	{
		"SuburbName" : "Wellsford",
		"Postcode" : "0972"
	},
	{
		"SuburbName" : "Whangaripo",
		"Postcode" : "0972"
	},
	{
		"SuburbName" : "Port Albert",
		"Postcode" : "0973"
	},
	{
		"SuburbName" : "Tauhoa",
		"Postcode" : "0973"
	},
	{
		"SuburbName" : "Te Hana",
		"Postcode" : "0973"
	},
	{
		"SuburbName" : "Wellsford",
		"Postcode" : "0973"
	},
	{
		"SuburbName" : "Wharehine",
		"Postcode" : "0973"
	},
	{
		"SuburbName" : "Te Arai",
		"Postcode" : "0974"
	},
	{
		"SuburbName" : "Te Hana",
		"Postcode" : "0974"
	},
	{
		"SuburbName" : "Tomarata",
		"Postcode" : "0974"
	},
	{
		"SuburbName" : "Wayby Valley",
		"Postcode" : "0974"
	},
	{
		"SuburbName" : "Wellsford",
		"Postcode" : "0974"
	},
	{
		"SuburbName" : "Whangaripo",
		"Postcode" : "0974"
	},
	{
		"SuburbName" : "Hakaru",
		"Postcode" : "0975"
	},
	{
		"SuburbName" : "Mangawhai",
		"Postcode" : "0975"
	},
	{
		"SuburbName" : "Oruawharo",
		"Postcode" : "0975"
	},
	{
		"SuburbName" : "Te Arai",
		"Postcode" : "0975"
	},
	{
		"SuburbName" : "Te Hana",
		"Postcode" : "0975"
	},
	{
		"SuburbName" : "Topuni",
		"Postcode" : "0975"
	},
	{
		"SuburbName" : "Wellsford",
		"Postcode" : "0975"
	},
	{
		"SuburbName" : "Tapora",
		"Postcode" : "0977"
	},
	{
		"SuburbName" : "Wellsford",
		"Postcode" : "0977"
	},
	{
		"SuburbName" : "Wharehine",
		"Postcode" : "0977"
	},
	{
		"SuburbName" : "Dome Forest",
		"Postcode" : "0981"
	},
	{
		"SuburbName" : "Dome Valley",
		"Postcode" : "0981"
	},
	{
		"SuburbName" : "Glorit",
		"Postcode" : "0981"
	},
	{
		"SuburbName" : "Kaipara Flats",
		"Postcode" : "0981"
	},
	{
		"SuburbName" : "Makarau",
		"Postcode" : "0981"
	},
	{
		"SuburbName" : "Puhoi",
		"Postcode" : "0981"
	},
	{
		"SuburbName" : "Warkworth",
		"Postcode" : "0981"
	},
	{
		"SuburbName" : "Algies Bay",
		"Postcode" : "0982"
	},
	{
		"SuburbName" : "Mahurangi East",
		"Postcode" : "0982"
	},
	{
		"SuburbName" : "Sandspit",
		"Postcode" : "0982"
	},
	{
		"SuburbName" : "Snells Beach",
		"Postcode" : "0982"
	},
	{
		"SuburbName" : "Warkworth",
		"Postcode" : "0982"
	},
	{
		"SuburbName" : "Mahurangi West",
		"Postcode" : "0983"
	},
	{
		"SuburbName" : "Pohuehue",
		"Postcode" : "0983"
	},
	{
		"SuburbName" : "Puhoi",
		"Postcode" : "0983"
	},
	{
		"SuburbName" : "Warkworth",
		"Postcode" : "0983"
	},
	{
		"SuburbName" : "Dome Forest",
		"Postcode" : "0984"
	},
	{
		"SuburbName" : "Glorit",
		"Postcode" : "0984"
	},
	{
		"SuburbName" : "Kaipara Flats",
		"Postcode" : "0984"
	},
	{
		"SuburbName" : "Kaukapakapa",
		"Postcode" : "0984"
	},
	{
		"SuburbName" : "Makarau",
		"Postcode" : "0984"
	},
	{
		"SuburbName" : "Mangakura",
		"Postcode" : "0984"
	},
	{
		"SuburbName" : "Tauhoa",
		"Postcode" : "0984"
	},
	{
		"SuburbName" : "Warkworth",
		"Postcode" : "0984"
	},
	{
		"SuburbName" : "Wellsford",
		"Postcode" : "0984"
	},
	{
		"SuburbName" : "Big Omaha",
		"Postcode" : "0985"
	},
	{
		"SuburbName" : "Dome Valley",
		"Postcode" : "0985"
	},
	{
		"SuburbName" : "Leigh",
		"Postcode" : "0985"
	},
	{
		"SuburbName" : "Matakana",
		"Postcode" : "0985"
	},
	{
		"SuburbName" : "Pakiri",
		"Postcode" : "0985"
	},
	{
		"SuburbName" : "Tawharanui Peninsula",
		"Postcode" : "0985"
	},
	{
		"SuburbName" : "Ti Point",
		"Postcode" : "0985"
	},
	{
		"SuburbName" : "Warkworth",
		"Postcode" : "0985"
	},
	{
		"SuburbName" : "Wellsford",
		"Postcode" : "0985"
	},
	{
		"SuburbName" : "Whangaripo",
		"Postcode" : "0985"
	},
	{
		"SuburbName" : "Whangateau",
		"Postcode" : "0985"
	},
	{
		"SuburbName" : "Omaha",
		"Postcode" : "0986"
	},
	{
		"SuburbName" : "Point Wells",
		"Postcode" : "0986"
	},
	{
		"SuburbName" : "Warkworth",
		"Postcode" : "0986"
	},
	{
		"SuburbName" : "Tawharanui Peninsula",
		"Postcode" : "0986"
	},
	{
		"SuburbName" : "Warkworth",
		"Postcode" : "0986"
	},
	{
		"SuburbName" : "Great Barrier Island",
		"Postcode" : "0991"
	},
	{
		"SuburbName" : "Great Barrier Island (Aotea Is",
		"Postcode" : "0991"
	},
	{
		"SuburbName" : "Great Barrier Island (Aotea Island)",
		"Postcode" : "0991"
	},
	{
		"SuburbName" : "Great Barrier Island",
		"Postcode" : "0991"
	},
	{
		"SuburbName" : "Dairy Flat",
		"Postcode" : "0992"
	},
	{
		"SuburbName" : "Orewa",
		"Postcode" : "0992"
	},
	{
		"SuburbName" : "Silverdale",
		"Postcode" : "0992"
	},
	{
		"SuburbName" : "Wainui",
		"Postcode" : "0992"
	},
	{
		"SuburbName" : "Waitoki",
		"Postcode" : "0992"
	},
	{
		"SuburbName" : "Waiwera",
		"Postcode" : "0992"
	},
	{
		"SuburbName" : "Auckland",
		"Postcode" : "0993"
	},
	{
		"SuburbName" : "Redvale",
		"Postcode" : "0993"
	},
	{
		"SuburbName" : "Silverdale",
		"Postcode" : "0993"
	},
	{
		"SuburbName" : "Stillwater",
		"Postcode" : "0993"
	},
	{
		"SuburbName" : "Makarau",
		"Postcode" : "0994"
	},
	{
		"SuburbName" : "Orewa",
		"Postcode" : "0994"
	},
	{
		"SuburbName" : "Puhoi",
		"Postcode" : "0994"
	},
	{
		"SuburbName" : "Silverdale",
		"Postcode" : "0994"
	},
	{
		"SuburbName" : "Wainui",
		"Postcode" : "0994"
	},
	{
		"SuburbName" : "Waitoki",
		"Postcode" : "0994"
	},
	{
		"SuburbName" : "Waiwera",
		"Postcode" : "0994"
	},
	{
		"SuburbName" : "Warkworth",
		"Postcode" : "0994"
	},
	{
		"SuburbName" : "Waiheke Island",
		"Postcode" : "1971"
	},
	{
		"SuburbName" : "Oneroa",
		"Postcode" : "1971"
	},
	{
		"SuburbName" : "ONETANGI",
		"Postcode" : "1971"
	},
	{
		"SuburbName" : "Ostend",
		"Postcode" : "1971"
	},
	{
		"SuburbName" : "Waiheke Island",
		"Postcode" : "1971"
	},
	{
		"SuburbName" : "Mercer",
		"Postcode" : "2437"
	},
	{
		"SuburbName" : "Mercer",
		"Postcode" : "2441"
	},
	{
		"SuburbName" : "Meremere",
		"Postcode" : "2441"
	},
	{
		"SuburbName" : "Meremere",
		"Postcode" : "2441"
	},
	{
		"SuburbName" : "Kopuku",
		"Postcode" : "2471"
	},
	{
		"SuburbName" : "Mangatangi",
		"Postcode" : "2471"
	},
	{
		"SuburbName" : "Mangatawhiri",
		"Postcode" : "2471"
	},
	{
		"SuburbName" : "Maramarua",
		"Postcode" : "2471"
	},
	{
		"SuburbName" : "Maramarua",
		"Postcode" : "2471"
	},
	{
		"SuburbName" : "Mercer",
		"Postcode" : "2471"
	},
	{
		"SuburbName" : "Miranda",
		"Postcode" : "2471"
	},
	{
		"SuburbName" : "Ngatea",
		"Postcode" : "2471"
	},
	{
		"SuburbName" : "Pokeno",
		"Postcode" : "2471"
	},
	{
		"SuburbName" : "Te Kauwhata",
		"Postcode" : "2471"
	},
	{
		"SuburbName" : "Waitakaruru",
		"Postcode" : "2471"
	},
	{
		"SuburbName" : "Bombay",
		"Postcode" : "2472"
	},
	{
		"SuburbName" : "Mangatawhiri",
		"Postcode" : "2472"
	},
	{
		"SuburbName" : "Pokeno",
		"Postcode" : "2472"
	},
	{
		"SuburbName" : "Kaiaua",
		"Postcode" : "2473"
	},
	{
		"SuburbName" : "Mangatangi",
		"Postcode" : "2473"
	},
	{
		"SuburbName" : "Mangatawhiri",
		"Postcode" : "2473"
	},
	{
		"SuburbName" : "Maramarua",
		"Postcode" : "2473"
	},
	{
		"SuburbName" : "Miranda",
		"Postcode" : "2473"
	},
	{
		"SuburbName" : "Pokeno",
		"Postcode" : "2473"
	},
	{
		"SuburbName" : "Whakatiwai",
		"Postcode" : "2473"
	},
	{
		"SuburbName" : "Mangatawhiri",
		"Postcode" : "2474"
	},
	{
		"SuburbName" : "Mercer",
		"Postcode" : "2474"
	},
	{
		"SuburbName" : "Meremere",
		"Postcode" : "2474"
	},
	{
		"SuburbName" : "Pokeno",
		"Postcode" : "2474"
	},
	{
		"SuburbName" : "Auckland",
		"Postcode" : "2571"
	},
	{
		"SuburbName" : "Beachlands",
		"Postcode" : "2571"
	},
	{
		"SuburbName" : "East Tamaki Heights",
		"Postcode" : "2571"
	},
	{
		"SuburbName" : "Howick",
		"Postcode" : "2571"
	},
	{
		"SuburbName" : "Manukau",
		"Postcode" : "2571"
	},
	{
		"SuburbName" : "Maraetai",
		"Postcode" : "2571"
	},
	{
		"SuburbName" : "Shamrock Park",
		"Postcode" : "2571"
	},
	{
		"SuburbName" : "Whitford",
		"Postcode" : "2571"
	},
	{
		"SuburbName" : "Alfriston",
		"Postcode" : "2576"
	},
	{
		"SuburbName" : "Ardmore",
		"Postcode" : "2576"
	},
	{
		"SuburbName" : "Manurewa",
		"Postcode" : "2576"
	},
	{
		"SuburbName" : "Brookby",
		"Postcode" : "2576"
	},
	{
		"SuburbName" : "Clevedon",
		"Postcode" : "2576"
	},
	{
		"SuburbName" : "Flat Bush",
		"Postcode" : "2576"
	},
	{
		"SuburbName" : "Manukau",
		"Postcode" : "2576"
	},
	{
		"SuburbName" : "Manurewa",
		"Postcode" : "2576"
	},
	{
		"SuburbName" : "Papakura",
		"Postcode" : "2576"
	},
	{
		"SuburbName" : "Takanini",
		"Postcode" : "2576"
	},
	{
		"SuburbName" : "The Gardens",
		"Postcode" : "2576"
	},
	{
		"SuburbName" : "Totara Park",
		"Postcode" : "2576"
	},
	{
		"SuburbName" : "Whitford",
		"Postcode" : "2576"
	},
	{
		"SuburbName" : "Drury",
		"Postcode" : "2577"
	},
	{
		"SuburbName" : "Opaheke",
		"Postcode" : "2577"
	},
	{
		"SuburbName" : "Papakura",
		"Postcode" : "2577"
	},
	{
		"SuburbName" : "Drury",
		"Postcode" : "2578"
	},
	{
		"SuburbName" : "Karaka",
		"Postcode" : "2578"
	},
	{
		"SuburbName" : "Paerata",
		"Postcode" : "2578"
	},
	{
		"SuburbName" : "Papakura",
		"Postcode" : "2578"
	},
	{
		"SuburbName" : "Ramarama",
		"Postcode" : "2578"
	},
	{
		"SuburbName" : "Runciman",
		"Postcode" : "2578"
	},
	{
		"SuburbName" : "Ararimu",
		"Postcode" : "2579"
	},
	{
		"SuburbName" : "Bombay",
		"Postcode" : "2579"
	},
	{
		"SuburbName" : "Drury",
		"Postcode" : "2579"
	},
	{
		"SuburbName" : "Ramarama",
		"Postcode" : "2579"
	},
	{
		"SuburbName" : "Runciman",
		"Postcode" : "2579"
	},
	{
		"SuburbName" : "Drury",
		"Postcode" : "2580"
	},
	{
		"SuburbName" : "Karaka",
		"Postcode" : "2580"
	},
	{
		"SuburbName" : "Kingseat",
		"Postcode" : "2580"
	},
	{
		"SuburbName" : "Paerata",
		"Postcode" : "2580"
	},
	{
		"SuburbName" : "Papakura",
		"Postcode" : "2580"
	},
	{
		"SuburbName" : "Pukekohe",
		"Postcode" : "2580"
	},
	{
		"SuburbName" : "Ardmore",
		"Postcode" : "2582"
	},
	{
		"SuburbName" : "Auckland",
		"Postcode" : "2582"
	},
	{
		"SuburbName" : "Brookby",
		"Postcode" : "2582"
	},
	{
		"SuburbName" : "Clevedon",
		"Postcode" : "2582"
	},
	{
		"SuburbName" : "Manukau",
		"Postcode" : "2582"
	},
	{
		"SuburbName" : "Maraetai",
		"Postcode" : "2582"
	},
	{
		"SuburbName" : "Papakura",
		"Postcode" : "2582"
	},
	{
		"SuburbName" : "Takanini",
		"Postcode" : "2582"
	},
	{
		"SuburbName" : "Ararimu",
		"Postcode" : "2583"
	},
	{
		"SuburbName" : "Clevedon",
		"Postcode" : "2583"
	},
	{
		"SuburbName" : "Drury",
		"Postcode" : "2583"
	},
	{
		"SuburbName" : "Hunua",
		"Postcode" : "2583"
	},
	{
		"SuburbName" : "Mangatawhiri",
		"Postcode" : "2583"
	},
	{
		"SuburbName" : "Papakura",
		"Postcode" : "2583"
	},
	{
		"SuburbName" : "Drury",
		"Postcode" : "2584"
	},
	{
		"SuburbName" : "Hunua",
		"Postcode" : "2584"
	},
	{
		"SuburbName" : "Opaheke",
		"Postcode" : "2584"
	},
	{
		"SuburbName" : "Papakura",
		"Postcode" : "2584"
	},
	{
		"SuburbName" : "Clevedon",
		"Postcode" : "2585"
	},
	{
		"SuburbName" : "Kawakawa Bay",
		"Postcode" : "2585"
	},
	{
		"SuburbName" : "Miranda",
		"Postcode" : "2585"
	},
	{
		"SuburbName" : "Ness Valley",
		"Postcode" : "2585"
	},
	{
		"SuburbName" : "Orere Point",
		"Postcode" : "2585"
	},
	{
		"SuburbName" : "Papakura",
		"Postcode" : "2585"
	},
	{
		"SuburbName" : "Whakatiwai",
		"Postcode" : "2585"
	},
	{
		"SuburbName" : "Ararimu",
		"Postcode" : "2675"
	},
	{
		"SuburbName" : "Bombay",
		"Postcode" : "2675"
	},
	{
		"SuburbName" : "Drury",
		"Postcode" : "2675"
	},
	{
		"SuburbName" : "Mangatawhiri",
		"Postcode" : "2675"
	},
	{
		"SuburbName" : "Drury",
		"Postcode" : "2676"
	},
	{
		"SuburbName" : "Karaka",
		"Postcode" : "2676"
	},
	{
		"SuburbName" : "Paerata",
		"Postcode" : "2676"
	},
	{
		"SuburbName" : "Papakura",
		"Postcode" : "2676"
	},
	{
		"SuburbName" : "Pukekohe",
		"Postcode" : "2676"
	},
	{
		"SuburbName" : "Bombay",
		"Postcode" : "2677"
	},
	{
		"SuburbName" : "Buckland",
		"Postcode" : "2677"
	},
	{
		"SuburbName" : "Drury",
		"Postcode" : "2677"
	},
	{
		"SuburbName" : "Paerata",
		"Postcode" : "2677"
	},
	{
		"SuburbName" : "Pukekohe",
		"Postcode" : "2677"
	},
	{
		"SuburbName" : "Pukekohe East",
		"Postcode" : "2677"
	},
	{
		"SuburbName" : "Ramarama",
		"Postcode" : "2677"
	},
	{
		"SuburbName" : "Tuakau",
		"Postcode" : "2677"
	},
	{
		"SuburbName" : "Aka Aka",
		"Postcode" : "2678"
	},
	{
		"SuburbName" : "Mauku",
		"Postcode" : "2678"
	},
	{
		"SuburbName" : "Patumahoe",
		"Postcode" : "2678"
	},
	{
		"SuburbName" : "Pukekohe",
		"Postcode" : "2678"
	},
	{
		"SuburbName" : "Puni",
		"Postcode" : "2678"
	},
	{
		"SuburbName" : "Waiuku",
		"Postcode" : "2678"
	},
	{
		"SuburbName" : "Clarks Beach",
		"Postcode" : "2679"
	},
	{
		"SuburbName" : "Glenbrook",
		"Postcode" : "2679"
	},
	{
		"SuburbName" : "Karaka",
		"Postcode" : "2679"
	},
	{
		"SuburbName" : "Kingseat",
		"Postcode" : "2679"
	},
	{
		"SuburbName" : "Papakura",
		"Postcode" : "2679"
	},
	{
		"SuburbName" : "Patumahoe",
		"Postcode" : "2679"
	},
	{
		"SuburbName" : "Pukekohe",
		"Postcode" : "2679"
	},
	{
		"SuburbName" : "Waiau Pa",
		"Postcode" : "2679"
	},
	{
		"SuburbName" : "Glenbrook",
		"Postcode" : "2681"
	},
	{
		"SuburbName" : "Mauku",
		"Postcode" : "2681"
	},
	{
		"SuburbName" : "Pukekohe",
		"Postcode" : "2681"
	},
	{
		"SuburbName" : "Waiuku",
		"Postcode" : "2681"
	},
	{
		"SuburbName" : "Aka Aka",
		"Postcode" : "2682"
	},
	{
		"SuburbName" : "Otaua",
		"Postcode" : "2682"
	},
	{
		"SuburbName" : "Pukekohe",
		"Postcode" : "2682"
	},
	{
		"SuburbName" : "Waiuku",
		"Postcode" : "2682"
	},
	{
		"SuburbName" : "Karioitahi",
		"Postcode" : "2683"
	},
	{
		"SuburbName" : "Lake Puketi",
		"Postcode" : "2683"
	},
	{
		"SuburbName" : "Otaua",
		"Postcode" : "2683"
	},
	{
		"SuburbName" : "Pollok",
		"Postcode" : "2683"
	},
	{
		"SuburbName" : "Waiuku",
		"Postcode" : "2683"
	},
	{
		"SuburbName" : "Awhitu",
		"Postcode" : "2684"
	},
	{
		"SuburbName" : "Manukau Heads",
		"Postcode" : "2684"
	},
	{
		"SuburbName" : "Pollok",
		"Postcode" : "2684"
	},
	{
		"SuburbName" : "Waiuku",
		"Postcode" : "2684"
	},
	{
		"SuburbName" : "Onewhero",
		"Postcode" : "2693"
	},
	{
		"SuburbName" : "Port Waikato",
		"Postcode" : "2693"
	},
	{
		"SuburbName" : "Te Kohanga",
		"Postcode" : "2693"
	},
	{
		"SuburbName" : "Tuakau",
		"Postcode" : "2693"
	},
	{
		"SuburbName" : "Buckland",
		"Postcode" : "2694"
	},
	{
		"SuburbName" : "Pokeno",
		"Postcode" : "2694"
	},
	{
		"SuburbName" : "Pukekohe",
		"Postcode" : "2694"
	},
	{
		"SuburbName" : "Tuakau",
		"Postcode" : "2694"
	},
	{
		"SuburbName" : "Churchill",
		"Postcode" : "2695"
	},
	{
		"SuburbName" : "Glen Murray",
		"Postcode" : "2695"
	},
	{
		"SuburbName" : "Huntly",
		"Postcode" : "2695"
	},
	{
		"SuburbName" : "Naike",
		"Postcode" : "2695"
	},
	{
		"SuburbName" : "Onewhero",
		"Postcode" : "2695"
	},
	{
		"SuburbName" : "Port Waikato",
		"Postcode" : "2695"
	},
	{
		"SuburbName" : "Pukekawa",
		"Postcode" : "2695"
	},
	{
		"SuburbName" : "Tuakau",
		"Postcode" : "2695"
	},
	{
		"SuburbName" : "Waikaretu",
		"Postcode" : "2695"
	},
	{
		"SuburbName" : "Mercer",
		"Postcode" : "2696"
	},
	{
		"SuburbName" : "Onewhero",
		"Postcode" : "2696"
	},
	{
		"SuburbName" : "Pukekawa",
		"Postcode" : "2696"
	},
	{
		"SuburbName" : "Tuakau",
		"Postcode" : "2696"
	},
	{
		"SuburbName" : "Glen Murray",
		"Postcode" : "2697"
	},
	{
		"SuburbName" : "Onewhero",
		"Postcode" : "2697"
	},
	{
		"SuburbName" : "Port Waikato",
		"Postcode" : "2697"
	},
	{
		"SuburbName" : "Te Kohanga",
		"Postcode" : "2697"
	},
	{
		"SuburbName" : "Tuakau",
		"Postcode" : "2697"
	},
	{
		"SuburbName" : "Hamurana",
		"Postcode" : "3072"
	},
	{
		"SuburbName" : "Mamaku",
		"Postcode" : "3072"
	},
	{
		"SuburbName" : "Ngatira",
		"Postcode" : "3072"
	},
	{
		"SuburbName" : "Ngongotaha",
		"Postcode" : "3072"
	},
	{
		"SuburbName" : "Ngongotaha Valley",
		"Postcode" : "3072"
	},
	{
		"SuburbName" : "Okere Falls",
		"Postcode" : "3072"
	},
	{
		"SuburbName" : "Pukehangi",
		"Postcode" : "3072"
	},
	{
		"SuburbName" : "Rotorua",
		"Postcode" : "3072"
	},
	{
		"SuburbName" : "Kaingaroa Forest",
		"Postcode" : "3073"
	},
	{
		"SuburbName" : "Kapenga",
		"Postcode" : "3073"
	},
	{
		"SuburbName" : "Kawerau",
		"Postcode" : "3073"
	},
	{
		"SuburbName" : "Mount Tarawera",
		"Postcode" : "3073"
	},
	{
		"SuburbName" : "MOUNT TARAWERA",
		"Postcode" : "3073"
	},
	{
		"SuburbName" : "Murupara",
		"Postcode" : "3073"
	},
	{
		"SuburbName" : "Ngakuru",
		"Postcode" : "3073"
	},
	{
		"SuburbName" : "Reporoa",
		"Postcode" : "3073"
	},
	{
		"SuburbName" : "Rerewhakaaitu",
		"Postcode" : "3073"
	},
	{
		"SuburbName" : "Rotorua",
		"Postcode" : "3073"
	},
	{
		"SuburbName" : "Tarawera Forest",
		"Postcode" : "3073"
	},
	{
		"SuburbName" : "Tumunui",
		"Postcode" : "3073"
	},
	{
		"SuburbName" : "Waimangu",
		"Postcode" : "3073"
	},
	{
		"SuburbName" : "Waiotapu",
		"Postcode" : "3073"
	},
	{
		"SuburbName" : "Whakarewarewa",
		"Postcode" : "3073"
	},
	{
		"SuburbName" : "Kapenga",
		"Postcode" : "3074"
	},
	{
		"SuburbName" : "Lake Okataina",
		"Postcode" : "3074"
	},
	{
		"SuburbName" : "Lake Rotoma",
		"Postcode" : "3074"
	},
	{
		"SuburbName" : "Mourea",
		"Postcode" : "3074"
	},
	{
		"SuburbName" : "Okere Falls",
		"Postcode" : "3074"
	},
	{
		"SuburbName" : "Owhata",
		"Postcode" : "3074"
	},
	{
		"SuburbName" : "Pongakawa",
		"Postcode" : "3074"
	},
	{
		"SuburbName" : "Rotoiti Forest",
		"Postcode" : "3074"
	},
	{
		"SuburbName" : "Rotoma",
		"Postcode" : "3074"
	},
	{
		"SuburbName" : "Rotorua",
		"Postcode" : "3074"
	},
	{
		"SuburbName" : "Tikitere",
		"Postcode" : "3074"
	},
	{
		"SuburbName" : "Whakarewarewa",
		"Postcode" : "3074"
	},
	{
		"SuburbName" : "Lake Okareka",
		"Postcode" : "3076"
	},
	{
		"SuburbName" : "Lake Tarawera",
		"Postcode" : "3076"
	},
	{
		"SuburbName" : "Lynmore",
		"Postcode" : "3076"
	},
	{
		"SuburbName" : "Rotorua",
		"Postcode" : "3076"
	},
	{
		"SuburbName" : "Atiamuri",
		"Postcode" : "3077"
	},
	{
		"SuburbName" : "Horohoro",
		"Postcode" : "3077"
	},
	{
		"SuburbName" : "Kapenga",
		"Postcode" : "3077"
	},
	{
		"SuburbName" : "Mihi",
		"Postcode" : "3077"
	},
	{
		"SuburbName" : "Ngakuru",
		"Postcode" : "3077"
	},
	{
		"SuburbName" : "Ngongotaha Valley",
		"Postcode" : "3077"
	},
	{
		"SuburbName" : "Ohakuri",
		"Postcode" : "3077"
	},
	{
		"SuburbName" : "Reporoa",
		"Postcode" : "3077"
	},
	{
		"SuburbName" : "Rotorua",
		"Postcode" : "3077"
	},
	{
		"SuburbName" : "Tumunui",
		"Postcode" : "3077"
	},
	{
		"SuburbName" : "Waikite Valley",
		"Postcode" : "3077"
	},
	{
		"SuburbName" : "Whakarewarewa",
		"Postcode" : "3077"
	},
	{
		"SuburbName" : "Atiamuri",
		"Postcode" : "3078"
	},
	{
		"SuburbName" : "Kinleith",
		"Postcode" : "3078"
	},
	{
		"SuburbName" : "Kinleith Forest",
		"Postcode" : "3078"
	},
	{
		"SuburbName" : "Mokai",
		"Postcode" : "3078"
	},
	{
		"SuburbName" : "Ohakuri",
		"Postcode" : "3078"
	},
	{
		"SuburbName" : "Reporoa",
		"Postcode" : "3078"
	},
	{
		"SuburbName" : "Tirohanga",
		"Postcode" : "3078"
	},
	{
		"SuburbName" : "Tokoroa",
		"Postcode" : "3078"
	},
	{
		"SuburbName" : "Galatea",
		"Postcode" : "3079"
	},
	{
		"SuburbName" : "Kaingaroa Forest",
		"Postcode" : "3079"
	},
	{
		"SuburbName" : "Minginui",
		"Postcode" : "3079"
	},
	{
		"SuburbName" : "Murupara",
		"Postcode" : "3079"
	},
	{
		"SuburbName" : "Ruatahuna",
		"Postcode" : "3079"
	},
	{
		"SuburbName" : "Ruatoki",
		"Postcode" : "3079"
	},
	{
		"SuburbName" : "Taneatua",
		"Postcode" : "3079"
	},
	{
		"SuburbName" : "Urewera National Park",
		"Postcode" : "3079"
	},
	{
		"SuburbName" : "Waiohau",
		"Postcode" : "3079"
	},
	{
		"SuburbName" : "Whakatane",
		"Postcode" : "3079"
	},
	{
		"SuburbName" : "Whirinaki Forest Park",
		"Postcode" : "3079"
	},
	{
		"SuburbName" : "Broadlands",
		"Postcode" : "3081"
	},
	{
		"SuburbName" : "Broadlands Forest",
		"Postcode" : "3081"
	},
	{
		"SuburbName" : "KAINGAROA FOREST",
		"Postcode" : "3081"
	},
	{
		"SuburbName" : "Mihi",
		"Postcode" : "3081"
	},
	{
		"SuburbName" : "Reporoa",
		"Postcode" : "3081"
	},
	{
		"SuburbName" : "Tauhara",
		"Postcode" : "3081"
	},
	{
		"SuburbName" : "Taupo",
		"Postcode" : "3081"
	},
	{
		"SuburbName" : "Mihi",
		"Postcode" : "3083"
	},
	{
		"SuburbName" : "Ohaaki",
		"Postcode" : "3083"
	},
	{
		"SuburbName" : "Ohakuri",
		"Postcode" : "3083"
	},
	{
		"SuburbName" : "Reporoa",
		"Postcode" : "3083"
	},
	{
		"SuburbName" : "Tahorakuri Forest",
		"Postcode" : "3083"
	},
	{
		"SuburbName" : "Waiotapu",
		"Postcode" : "3083"
	},
	{
		"SuburbName" : "Hamurana",
		"Postcode" : "3096"
	},
	{
		"SuburbName" : "Ngongotaha",
		"Postcode" : "3096"
	},
	{
		"SuburbName" : "Okere Falls",
		"Postcode" : "3096"
	},
	{
		"SuburbName" : "Rotorua",
		"Postcode" : "3096"
	},
	{
		"SuburbName" : "Hamurana",
		"Postcode" : "3097"
	},
	{
		"SuburbName" : "Ngongotaha",
		"Postcode" : "3097"
	},
	{
		"SuburbName" : "Rotorua",
		"Postcode" : "3097"
	},
	{
		"SuburbName" : "Katikati",
		"Postcode" : "3170"
	},
	{
		"SuburbName" : "Tahawai",
		"Postcode" : "3170"
	},
	{
		"SuburbName" : "Tanners Point",
		"Postcode" : "3170"
	},
	{
		"SuburbName" : "Greerton",
		"Postcode" : "3171"
	},
	{
		"SuburbName" : "Hamurana",
		"Postcode" : "3171"
	},
	{
		"SuburbName" : "Lower Kaimai",
		"Postcode" : "3171"
	},
	{
		"SuburbName" : "Ngawaro",
		"Postcode" : "3171"
	},
	{
		"SuburbName" : "Omanawa",
		"Postcode" : "3171"
	},
	{
		"SuburbName" : "Pyes Pa",
		"Postcode" : "3171"
	},
	{
		"SuburbName" : "Tauranga",
		"Postcode" : "3171"
	},
	{
		"SuburbName" : "Tauriko",
		"Postcode" : "3171"
	},
	{
		"SuburbName" : "Te Puna",
		"Postcode" : "3171"
	},
	{
		"SuburbName" : "Wairoa",
		"Postcode" : "3171"
	},
	{
		"SuburbName" : "Bethlehem",
		"Postcode" : "3172"
	},
	{
		"SuburbName" : "Matakana Island",
		"Postcode" : "3172"
	},
	{
		"SuburbName" : "Omokoroa",
		"Postcode" : "3172"
	},
	{
		"SuburbName" : "Tauranga",
		"Postcode" : "3172"
	},
	{
		"SuburbName" : "Te Puna",
		"Postcode" : "3172"
	},
	{
		"SuburbName" : "Whakamarama",
		"Postcode" : "3172"
	},
	{
		"SuburbName" : "Greerton",
		"Postcode" : "3173"
	},
	{
		"SuburbName" : "Hairini",
		"Postcode" : "3173"
	},
	{
		"SuburbName" : "Ohauiti",
		"Postcode" : "3173"
	},
	{
		"SuburbName" : "Omanawa",
		"Postcode" : "3173"
	},
	{
		"SuburbName" : "Oropi",
		"Postcode" : "3173"
	},
	{
		"SuburbName" : "Pyes Pa",
		"Postcode" : "3173"
	},
	{
		"SuburbName" : "Tauranga",
		"Postcode" : "3173"
	},
	{
		"SuburbName" : "Tauriko",
		"Postcode" : "3173"
	},
	{
		"SuburbName" : "Tauranga",
		"Postcode" : "3174"
	},
	{
		"SuburbName" : "Te Puna",
		"Postcode" : "3174"
	},
	{
		"SuburbName" : "Whakamarama",
		"Postcode" : "3174"
	},
	{
		"SuburbName" : "Kairua",
		"Postcode" : "3175"
	},
	{
		"SuburbName" : "Matapihi",
		"Postcode" : "3175"
	},
	{
		"SuburbName" : "Mount Maunganui",
		"Postcode" : "3175"
	},
	{
		"SuburbName" : "Mt Maunganui",
		"Postcode" : "3175"
	},
	{
		"SuburbName" : "Ohauiti",
		"Postcode" : "3175"
	},
	{
		"SuburbName" : "Papamoa",
		"Postcode" : "3175"
	},
	{
		"SuburbName" : "Papamoa",
		"Postcode" : "3175"
	},
	{
		"SuburbName" : "Tauranga",
		"Postcode" : "3175"
	},
	{
		"SuburbName" : "Waitao",
		"Postcode" : "3175"
	},
	{
		"SuburbName" : "Welcome Bay",
		"Postcode" : "3175"
	},
	{
		"SuburbName" : "Bethlehem",
		"Postcode" : "3176"
	},
	{
		"SuburbName" : "Tauranga",
		"Postcode" : "3176"
	},
	{
		"SuburbName" : "Te Puna",
		"Postcode" : "3176"
	},
	{
		"SuburbName" : "Wairoa",
		"Postcode" : "3176"
	},
	{
		"SuburbName" : "Whakamarama",
		"Postcode" : "3176"
	},
	{
		"SuburbName" : "Athenree",
		"Postcode" : "3177"
	},
	{
		"SuburbName" : "Athenree Gorge",
		"Postcode" : "3177"
	},
	{
		"SuburbName" : "Bowentown",
		"Postcode" : "3177"
	},
	{
		"SuburbName" : "Katikati",
		"Postcode" : "3177"
	},
	{
		"SuburbName" : "Tahawai",
		"Postcode" : "3177"
	},
	{
		"SuburbName" : "Tanners Point",
		"Postcode" : "3177"
	},
	{
		"SuburbName" : "Waihi",
		"Postcode" : "3177"
	},
	{
		"SuburbName" : "Waihi Beach",
		"Postcode" : "3177"
	},
	{
		"SuburbName" : "Aongatete",
		"Postcode" : "3178"
	},
	{
		"SuburbName" : "Katikati",
		"Postcode" : "3178"
	},
	{
		"SuburbName" : "Tahawai",
		"Postcode" : "3178"
	},
	{
		"SuburbName" : "Whakamarama",
		"Postcode" : "3178"
	},
	{
		"SuburbName" : "Tauranga",
		"Postcode" : "3179"
	},
	{
		"SuburbName" : "Te Puna",
		"Postcode" : "3179"
	},
	{
		"SuburbName" : "Whakamarama",
		"Postcode" : "3179"
	},
	{
		"SuburbName" : "Tauranga",
		"Postcode" : "3180"
	},
	{
		"SuburbName" : "Tuki Tuki",
		"Postcode" : "3180"
	},
	{
		"SuburbName" : "Whakamarama",
		"Postcode" : "3180"
	},
	{
		"SuburbName" : "Aongatete",
		"Postcode" : "3181"
	},
	{
		"SuburbName" : "Katikati",
		"Postcode" : "3181"
	},
	{
		"SuburbName" : "Whakamarama",
		"Postcode" : "3181"
	},
	{
		"SuburbName" : "Te Puke",
		"Postcode" : "3182"
	},
	{
		"SuburbName" : "Te Ranga",
		"Postcode" : "3182"
	},
	{
		"SuburbName" : "Papamoa",
		"Postcode" : "3183"
	},
	{
		"SuburbName" : "Tauranga",
		"Postcode" : "3183"
	},
	{
		"SuburbName" : "Te Puke",
		"Postcode" : "3183"
	},
	{
		"SuburbName" : "Waitao",
		"Postcode" : "3183"
	},
	{
		"SuburbName" : "Matata",
		"Postcode" : "3186"
	},
	{
		"SuburbName" : "Okere Falls",
		"Postcode" : "3186"
	},
	{
		"SuburbName" : "Paengaroa",
		"Postcode" : "3186"
	},
	{
		"SuburbName" : "Pikowai",
		"Postcode" : "3186"
	},
	{
		"SuburbName" : "Pongakawa",
		"Postcode" : "3186"
	},
	{
		"SuburbName" : "Pukehina",
		"Postcode" : "3186"
	},
	{
		"SuburbName" : "Rotorua",
		"Postcode" : "3186"
	},
	{
		"SuburbName" : "Te Puke",
		"Postcode" : "3186"
	},
	{
		"SuburbName" : "Kairua",
		"Postcode" : "3187"
	},
	{
		"SuburbName" : "Papamoa",
		"Postcode" : "3187"
	},
	{
		"SuburbName" : "Papamoa",
		"Postcode" : "3187"
	},
	{
		"SuburbName" : "Te Puke",
		"Postcode" : "3187"
	},
	{
		"SuburbName" : "Hamurana",
		"Postcode" : "3188"
	},
	{
		"SuburbName" : "Ngawaro",
		"Postcode" : "3188"
	},
	{
		"SuburbName" : "Paengaroa",
		"Postcode" : "3188"
	},
	{
		"SuburbName" : "Rangiuru",
		"Postcode" : "3188"
	},
	{
		"SuburbName" : "Te Puke",
		"Postcode" : "3188"
	},
	{
		"SuburbName" : "Te Ranga",
		"Postcode" : "3188"
	},
	{
		"SuburbName" : "Maketu",
		"Postcode" : "3189"
	},
	{
		"SuburbName" : "Paengaroa",
		"Postcode" : "3189"
	},
	{
		"SuburbName" : "Papamoa",
		"Postcode" : "3189"
	},
	{
		"SuburbName" : "Papamoa",
		"Postcode" : "3189"
	},
	{
		"SuburbName" : "Pongakawa",
		"Postcode" : "3189"
	},
	{
		"SuburbName" : "Pukehina",
		"Postcode" : "3189"
	},
	{
		"SuburbName" : "Te Puke",
		"Postcode" : "3189"
	},
	{
		"SuburbName" : "Awakeri",
		"Postcode" : "3191"
	},
	{
		"SuburbName" : "Coastlands",
		"Postcode" : "3191"
	},
	{
		"SuburbName" : "Maraetotara",
		"Postcode" : "3191"
	},
	{
		"SuburbName" : "Ohope",
		"Postcode" : "3191"
	},
	{
		"SuburbName" : "Poroporo",
		"Postcode" : "3191"
	},
	{
		"SuburbName" : "Ruatoki",
		"Postcode" : "3191"
	},
	{
		"SuburbName" : "Taneatua",
		"Postcode" : "3191"
	},
	{
		"SuburbName" : "Tawera",
		"Postcode" : "3191"
	},
	{
		"SuburbName" : "Thornton",
		"Postcode" : "3191"
	},
	{
		"SuburbName" : "Waimana",
		"Postcode" : "3191"
	},
	{
		"SuburbName" : "Whakatane",
		"Postcode" : "3191"
	},
	{
		"SuburbName" : "White Pine Bush",
		"Postcode" : "3191"
	},
	{
		"SuburbName" : "Awakeri",
		"Postcode" : "3192"
	},
	{
		"SuburbName" : "Coastlands",
		"Postcode" : "3192"
	},
	{
		"SuburbName" : "Edgecumbe",
		"Postcode" : "3192"
	},
	{
		"SuburbName" : "Kawerau",
		"Postcode" : "3192"
	},
	{
		"SuburbName" : "Lake Matahina",
		"Postcode" : "3192"
	},
	{
		"SuburbName" : "Manawahe",
		"Postcode" : "3192"
	},
	{
		"SuburbName" : "Matahina Forest",
		"Postcode" : "3192"
	},
	{
		"SuburbName" : "Omataroa",
		"Postcode" : "3192"
	},
	{
		"SuburbName" : "Otakiri",
		"Postcode" : "3192"
	},
	{
		"SuburbName" : "Poroporo",
		"Postcode" : "3192"
	},
	{
		"SuburbName" : "Putauaki",
		"Postcode" : "3192"
	},
	{
		"SuburbName" : "Rotoma",
		"Postcode" : "3192"
	},
	{
		"SuburbName" : "Te Teko",
		"Postcode" : "3192"
	},
	{
		"SuburbName" : "Waiohau",
		"Postcode" : "3192"
	},
	{
		"SuburbName" : "Whakatane",
		"Postcode" : "3192"
	},
	{
		"SuburbName" : "White Pine Bush",
		"Postcode" : "3192"
	},
	{
		"SuburbName" : "Awakeri",
		"Postcode" : "3193"
	},
	{
		"SuburbName" : "Edgecumbe",
		"Postcode" : "3193"
	},
	{
		"SuburbName" : "Manawahe",
		"Postcode" : "3193"
	},
	{
		"SuburbName" : "Matata",
		"Postcode" : "3193"
	},
	{
		"SuburbName" : "Otakiri",
		"Postcode" : "3193"
	},
	{
		"SuburbName" : "Poroporo",
		"Postcode" : "3193"
	},
	{
		"SuburbName" : "Rotoma",
		"Postcode" : "3193"
	},
	{
		"SuburbName" : "Te Teko",
		"Postcode" : "3193"
	},
	{
		"SuburbName" : "Thornton",
		"Postcode" : "3193"
	},
	{
		"SuburbName" : "Whakatane",
		"Postcode" : "3193"
	},
	{
		"SuburbName" : "Awakeri",
		"Postcode" : "3194"
	},
	{
		"SuburbName" : "Coastlands",
		"Postcode" : "3194"
	},
	{
		"SuburbName" : "Edgecumbe",
		"Postcode" : "3194"
	},
	{
		"SuburbName" : "Lake Rotoma",
		"Postcode" : "3194"
	},
	{
		"SuburbName" : "Manawahe",
		"Postcode" : "3194"
	},
	{
		"SuburbName" : "Matata",
		"Postcode" : "3194"
	},
	{
		"SuburbName" : "Pikowai",
		"Postcode" : "3194"
	},
	{
		"SuburbName" : "Pongakawa",
		"Postcode" : "3194"
	},
	{
		"SuburbName" : "Rotoma",
		"Postcode" : "3194"
	},
	{
		"SuburbName" : "Thornton",
		"Postcode" : "3194"
	},
	{
		"SuburbName" : "Whakatane",
		"Postcode" : "3194"
	},
	{
		"SuburbName" : "Matahi",
		"Postcode" : "3196"
	},
	{
		"SuburbName" : "Nukuhou",
		"Postcode" : "3196"
	},
	{
		"SuburbName" : "Urewera National Park",
		"Postcode" : "3196"
	},
	{
		"SuburbName" : "Waimana",
		"Postcode" : "3196"
	},
	{
		"SuburbName" : "Wainui",
		"Postcode" : "3196"
	},
	{
		"SuburbName" : "Whakatane",
		"Postcode" : "3196"
	},
	{
		"SuburbName" : "Hawai",
		"Postcode" : "3197"
	},
	{
		"SuburbName" : "Opotiki",
		"Postcode" : "3197"
	},
	{
		"SuburbName" : "Otara",
		"Postcode" : "3197"
	},
	{
		"SuburbName" : "Tirohanga",
		"Postcode" : "3197"
	},
	{
		"SuburbName" : "Toatoa",
		"Postcode" : "3197"
	},
	{
		"SuburbName" : "Torere",
		"Postcode" : "3197"
	},
	{
		"SuburbName" : "Waioeka",
		"Postcode" : "3197"
	},
	{
		"SuburbName" : "Kutarere",
		"Postcode" : "3198"
	},
	{
		"SuburbName" : "Nukuhou",
		"Postcode" : "3198"
	},
	{
		"SuburbName" : "Ohakana Island",
		"Postcode" : "3198"
	},
	{
		"SuburbName" : "Opotiki",
		"Postcode" : "3198"
	},
	{
		"SuburbName" : "Otara",
		"Postcode" : "3198"
	},
	{
		"SuburbName" : "Waimana",
		"Postcode" : "3198"
	},
	{
		"SuburbName" : "Wainui",
		"Postcode" : "3198"
	},
	{
		"SuburbName" : "Waioeka",
		"Postcode" : "3198"
	},
	{
		"SuburbName" : "Waiotahi",
		"Postcode" : "3198"
	},
	{
		"SuburbName" : "Waiotahi Valley",
		"Postcode" : "3198"
	},
	{
		"SuburbName" : "Waiotahi",
		"Postcode" : "3198"
	},
	{
		"SuburbName" : "Waiotahi Valley",
		"Postcode" : "3198"
	},
	{
		"SuburbName" : "Waiou",
		"Postcode" : "3198"
	},
	{
		"SuburbName" : "Whakatane",
		"Postcode" : "3198"
	},
	{
		"SuburbName" : "Cape Runaway",
		"Postcode" : "3199"
	},
	{
		"SuburbName" : "Hawai",
		"Postcode" : "3199"
	},
	{
		"SuburbName" : "Hicks Bay",
		"Postcode" : "3199"
	},
	{
		"SuburbName" : "Maraenui",
		"Postcode" : "3199"
	},
	{
		"SuburbName" : "Omaio",
		"Postcode" : "3199"
	},
	{
		"SuburbName" : "Opotiki",
		"Postcode" : "3199"
	},
	{
		"SuburbName" : "Potaka",
		"Postcode" : "3199"
	},
	{
		"SuburbName" : "Te Araroa",
		"Postcode" : "3199"
	},
	{
		"SuburbName" : "Te Kaha",
		"Postcode" : "3199"
	},
	{
		"SuburbName" : "Tikitiki",
		"Postcode" : "3199"
	},
	{
		"SuburbName" : "Waihau Bay",
		"Postcode" : "3199"
	},
	{
		"SuburbName" : "Waikura",
		"Postcode" : "3199"
	},
	{
		"SuburbName" : "Waikura Valley",
		"Postcode" : "3199"
	},
	{
		"SuburbName" : "Chartwell",
		"Postcode" : "3281"
	},
	{
		"SuburbName" : "Fairview Downs",
		"Postcode" : "3281"
	},
	{
		"SuburbName" : "Flagstaff",
		"Postcode" : "3281"
	},
	{
		"SuburbName" : "Gordonton",
		"Postcode" : "3281"
	},
	{
		"SuburbName" : "Hamilton",
		"Postcode" : "3281"
	},
	{
		"SuburbName" : "Horsham Downs",
		"Postcode" : "3281"
	},
	{
		"SuburbName" : "Huntington",
		"Postcode" : "3281"
	},
	{
		"SuburbName" : "Morrinsville",
		"Postcode" : "3281"
	},
	{
		"SuburbName" : "Ngaruawahia",
		"Postcode" : "3281"
	},
	{
		"SuburbName" : "Puketaha",
		"Postcode" : "3281"
	},
	{
		"SuburbName" : "Rototuna North",
		"Postcode" : "3281"
	},
	{
		"SuburbName" : "Taupiri",
		"Postcode" : "3281"
	},
	{
		"SuburbName" : "Whitikahu",
		"Postcode" : "3281"
	},
	{
		"SuburbName" : "Fitzroy",
		"Postcode" : "3282"
	},
	{
		"SuburbName" : "Glenview",
		"Postcode" : "3282"
	},
	{
		"SuburbName" : "Hamilton",
		"Postcode" : "3282"
	},
	{
		"SuburbName" : "Ohaupo",
		"Postcode" : "3282"
	},
	{
		"SuburbName" : "Hamilton",
		"Postcode" : "3282"
	},
	{
		"SuburbName" : "Rukuhia",
		"Postcode" : "3282"
	},
	{
		"SuburbName" : "Tamahere",
		"Postcode" : "3282"
	},
	{
		"SuburbName" : "Templeview",
		"Postcode" : "3282"
	},
	{
		"SuburbName" : "Cambridge",
		"Postcode" : "3283"
	},
	{
		"SuburbName" : "Hamilton",
		"Postcode" : "3283"
	},
	{
		"SuburbName" : "Tamahere",
		"Postcode" : "3283"
	},
	{
		"SuburbName" : "Eureka",
		"Postcode" : "3284"
	},
	{
		"SuburbName" : "Gordonton",
		"Postcode" : "3284"
	},
	{
		"SuburbName" : "Hamilton",
		"Postcode" : "3284"
	},
	{
		"SuburbName" : "Hillcrest",
		"Postcode" : "3284"
	},
	{
		"SuburbName" : "Matangi",
		"Postcode" : "3284"
	},
	{
		"SuburbName" : "Morrinsville",
		"Postcode" : "3284"
	},
	{
		"SuburbName" : "Newstead",
		"Postcode" : "3284"
	},
	{
		"SuburbName" : "Pukemoremore",
		"Postcode" : "3284"
	},
	{
		"SuburbName" : "Puketaha",
		"Postcode" : "3284"
	},
	{
		"SuburbName" : "Tamahere",
		"Postcode" : "3284"
	},
	{
		"SuburbName" : "Tauwhare",
		"Postcode" : "3284"
	},
	{
		"SuburbName" : "Dinsdale",
		"Postcode" : "3285"
	},
	{
		"SuburbName" : "Hamilton",
		"Postcode" : "3285"
	},
	{
		"SuburbName" : "Karamu",
		"Postcode" : "3285"
	},
	{
		"SuburbName" : "Pirongia",
		"Postcode" : "3285"
	},
	{
		"SuburbName" : "Raglan",
		"Postcode" : "3285"
	},
	{
		"SuburbName" : "Te Pahu",
		"Postcode" : "3285"
	},
	{
		"SuburbName" : "Templeview",
		"Postcode" : "3285"
	},
	{
		"SuburbName" : "Waitetuna",
		"Postcode" : "3285"
	},
	{
		"SuburbName" : "Whatawhata",
		"Postcode" : "3285"
	},
	{
		"SuburbName" : "Eureka",
		"Postcode" : "3286"
	},
	{
		"SuburbName" : "Hamilton",
		"Postcode" : "3286"
	},
	{
		"SuburbName" : "Hillcrest",
		"Postcode" : "3286"
	},
	{
		"SuburbName" : "Newstead",
		"Postcode" : "3286"
	},
	{
		"SuburbName" : "Puketaha",
		"Postcode" : "3286"
	},
	{
		"SuburbName" : "Ruakura",
		"Postcode" : "3286"
	},
	{
		"SuburbName" : "Eureka",
		"Postcode" : "3287"
	},
	{
		"SuburbName" : "Gordonton",
		"Postcode" : "3287"
	},
	{
		"SuburbName" : "Hamilton",
		"Postcode" : "3287"
	},
	{
		"SuburbName" : "Matangi",
		"Postcode" : "3287"
	},
	{
		"SuburbName" : "Newstead",
		"Postcode" : "3287"
	},
	{
		"SuburbName" : "Pukemoremore",
		"Postcode" : "3287"
	},
	{
		"SuburbName" : "Tamahere",
		"Postcode" : "3287"
	},
	{
		"SuburbName" : "Tauwhare",
		"Postcode" : "3287"
	},
	{
		"SuburbName" : "Avalon",
		"Postcode" : "3288"
	},
	{
		"SuburbName" : "Burbush",
		"Postcode" : "3288"
	},
	{
		"SuburbName" : "Hamilton",
		"Postcode" : "3288"
	},
	{
		"SuburbName" : "Horotiu",
		"Postcode" : "3288"
	},
	{
		"SuburbName" : "Ngaruawahia",
		"Postcode" : "3288"
	},
	{
		"SuburbName" : "Northgate",
		"Postcode" : "3288"
	},
	{
		"SuburbName" : "Pukete",
		"Postcode" : "3288"
	},
	{
		"SuburbName" : "Rotokauri",
		"Postcode" : "3288"
	},
	{
		"SuburbName" : "Te Kowhai",
		"Postcode" : "3288"
	},
	{
		"SuburbName" : "Te Rapa Park",
		"Postcode" : "3288"
	},
	{
		"SuburbName" : "Whatawhata",
		"Postcode" : "3288"
	},
	{
		"SuburbName" : "Baverstock",
		"Postcode" : "3289"
	},
	{
		"SuburbName" : "Burbush",
		"Postcode" : "3289"
	},
	{
		"SuburbName" : "Hamilton",
		"Postcode" : "3289"
	},
	{
		"SuburbName" : "Rotokauri",
		"Postcode" : "3289"
	},
	{
		"SuburbName" : "Temple View",
		"Postcode" : "3289"
	},
	{
		"SuburbName" : "Whatawhata",
		"Postcode" : "3289"
	},
	{
		"SuburbName" : "Hamilton",
		"Postcode" : "3290"
	},
	{
		"SuburbName" : "Ngahinapouri",
		"Postcode" : "3290"
	},
	{
		"SuburbName" : "Ohaupo",
		"Postcode" : "3290"
	},
	{
		"SuburbName" : "Temple View",
		"Postcode" : "3290"
	},
	{
		"SuburbName" : "Whatawhata",
		"Postcode" : "3290"
	},
	{
		"SuburbName" : "Hamilton",
		"Postcode" : "3293"
	},
	{
		"SuburbName" : "Raglan",
		"Postcode" : "3293"
	},
	{
		"SuburbName" : "Waitetuna",
		"Postcode" : "3293"
	},
	{
		"SuburbName" : "Whatawhata",
		"Postcode" : "3293"
	},
	{
		"SuburbName" : "Ngaruawahia",
		"Postcode" : "3295"
	},
	{
		"SuburbName" : "Raglan",
		"Postcode" : "3295"
	},
	{
		"SuburbName" : "Waingaro",
		"Postcode" : "3295"
	},
	{
		"SuburbName" : "Waitetuna",
		"Postcode" : "3295"
	},
	{
		"SuburbName" : "Raglan",
		"Postcode" : "3296"
	},
	{
		"SuburbName" : "Raglan",
		"Postcode" : "3297"
	},
	{
		"SuburbName" : "Cambridge",
		"Postcode" : "3371"
	},
	{
		"SuburbName" : "Eureka",
		"Postcode" : "3371"
	},
	{
		"SuburbName" : "Hamilton",
		"Postcode" : "3371"
	},
	{
		"SuburbName" : "Morrinsville",
		"Postcode" : "3371"
	},
	{
		"SuburbName" : "Ngarua",
		"Postcode" : "3371"
	},
	{
		"SuburbName" : "Richmond Downs",
		"Postcode" : "3371"
	},
	{
		"SuburbName" : "Tauwhare",
		"Postcode" : "3371"
	},
	{
		"SuburbName" : "Te Miro",
		"Postcode" : "3371"
	},
	{
		"SuburbName" : "Waitoa",
		"Postcode" : "3371"
	},
	{
		"SuburbName" : "Eureka",
		"Postcode" : "3372"
	},
	{
		"SuburbName" : "Gordonton",
		"Postcode" : "3372"
	},
	{
		"SuburbName" : "Hamilton",
		"Postcode" : "3372"
	},
	{
		"SuburbName" : "Morrinsville",
		"Postcode" : "3372"
	},
	{
		"SuburbName" : "Motumaoho",
		"Postcode" : "3372"
	},
	{
		"SuburbName" : "Hoe-O-Tainui",
		"Postcode" : "3373"
	},
	{
		"SuburbName" : "Kaihere",
		"Postcode" : "3373"
	},
	{
		"SuburbName" : "Mangateparu",
		"Postcode" : "3373"
	},
	{
		"SuburbName" : "Morrinsville",
		"Postcode" : "3373"
	},
	{
		"SuburbName" : "Ngatea",
		"Postcode" : "3373"
	},
	{
		"SuburbName" : "Patetonga",
		"Postcode" : "3373"
	},
	{
		"SuburbName" : "Springdale",
		"Postcode" : "3373"
	},
	{
		"SuburbName" : "Tahuna",
		"Postcode" : "3373"
	},
	{
		"SuburbName" : "Tauhei",
		"Postcode" : "3373"
	},
	{
		"SuburbName" : "Morrinsville",
		"Postcode" : "3374"
	},
	{
		"SuburbName" : "Ngarua",
		"Postcode" : "3374"
	},
	{
		"SuburbName" : "Springdale",
		"Postcode" : "3374"
	},
	{
		"SuburbName" : "Tatuanui",
		"Postcode" : "3374"
	},
	{
		"SuburbName" : "Te Puninga",
		"Postcode" : "3374"
	},
	{
		"SuburbName" : "Waitoa",
		"Postcode" : "3374"
	},
	{
		"SuburbName" : "Mangateparu",
		"Postcode" : "3375"
	},
	{
		"SuburbName" : "Morrinsville",
		"Postcode" : "3375"
	},
	{
		"SuburbName" : "Orini",
		"Postcode" : "3375"
	},
	{
		"SuburbName" : "Tauhei",
		"Postcode" : "3375"
	},
	{
		"SuburbName" : "Taupiri",
		"Postcode" : "3375"
	},
	{
		"SuburbName" : "Whitikahu",
		"Postcode" : "3375"
	},
	{
		"SuburbName" : "Acacia Bay",
		"Postcode" : "3377"
	},
	{
		"SuburbName" : "Atiamuri",
		"Postcode" : "3377"
	},
	{
		"SuburbName" : "Kinleith Forest",
		"Postcode" : "3377"
	},
	{
		"SuburbName" : "Kinloch",
		"Postcode" : "3377"
	},
	{
		"SuburbName" : "Marotiri",
		"Postcode" : "3377"
	},
	{
		"SuburbName" : "Nukuhau",
		"Postcode" : "3377"
	},
	{
		"SuburbName" : "Ohakuri",
		"Postcode" : "3377"
	},
	{
		"SuburbName" : "Oruanui",
		"Postcode" : "3377"
	},
	{
		"SuburbName" : "Rangitira Park",
		"Postcode" : "3377"
	},
	{
		"SuburbName" : "Rangitira Park",
		"Postcode" : "3377"
	},
	{
		"SuburbName" : "Reporoa",
		"Postcode" : "3377"
	},
	{
		"SuburbName" : "Taupo",
		"Postcode" : "3377"
	},
	{
		"SuburbName" : "Wairakei",
		"Postcode" : "3377"
	},
	{
		"SuburbName" : "Hatepe",
		"Postcode" : "3378"
	},
	{
		"SuburbName" : "Rotokawa",
		"Postcode" : "3378"
	},
	{
		"SuburbName" : "Tauhara",
		"Postcode" : "3378"
	},
	{
		"SuburbName" : "Tauhara",
		"Postcode" : "3378"
	},
	{
		"SuburbName" : "Taupo",
		"Postcode" : "3378"
	},
	{
		"SuburbName" : "Turangi",
		"Postcode" : "3378"
	},
	{
		"SuburbName" : "Wairakei",
		"Postcode" : "3378"
	},
	{
		"SuburbName" : "Waitahanui",
		"Postcode" : "3378"
	},
	{
		"SuburbName" : "Wharewaka",
		"Postcode" : "3378"
	},
	{
		"SuburbName" : "Hilltop",
		"Postcode" : "3379"
	},
	{
		"SuburbName" : "Iwitahi",
		"Postcode" : "3379"
	},
	{
		"SuburbName" : "Maunganamu",
		"Postcode" : "3379"
	},
	{
		"SuburbName" : "Napier",
		"Postcode" : "3379"
	},
	{
		"SuburbName" : "Rangitaiki",
		"Postcode" : "3379"
	},
	{
		"SuburbName" : "Reporoa",
		"Postcode" : "3379"
	},
	{
		"SuburbName" : "Taharua",
		"Postcode" : "3379"
	},
	{
		"SuburbName" : "Tarawera",
		"Postcode" : "3379"
	},
	{
		"SuburbName" : "Tauhara",
		"Postcode" : "3379"
	},
	{
		"SuburbName" : "Tauhara",
		"Postcode" : "3379"
	},
	{
		"SuburbName" : "Taupo",
		"Postcode" : "3379"
	},
	{
		"SuburbName" : "Waipunga",
		"Postcode" : "3379"
	},
	{
		"SuburbName" : "Morrinsville",
		"Postcode" : "3380"
	},
	{
		"SuburbName" : "Ngarua",
		"Postcode" : "3380"
	},
	{
		"SuburbName" : "Springdale",
		"Postcode" : "3380"
	},
	{
		"SuburbName" : "Tatuanui",
		"Postcode" : "3380"
	},
	{
		"SuburbName" : "Te Puninga",
		"Postcode" : "3380"
	},
	{
		"SuburbName" : "Waitoa",
		"Postcode" : "3380"
	},
	{
		"SuburbName" : "Kuratau",
		"Postcode" : "3381"
	},
	{
		"SuburbName" : "Omori",
		"Postcode" : "3381"
	},
	{
		"SuburbName" : "Pukawa",
		"Postcode" : "3381"
	},
	{
		"SuburbName" : "Pureora Forest Park",
		"Postcode" : "3381"
	},
	{
		"SuburbName" : "Taupo",
		"Postcode" : "3381"
	},
	{
		"SuburbName" : "Tihoi",
		"Postcode" : "3381"
	},
	{
		"SuburbName" : "Tokaanu",
		"Postcode" : "3381"
	},
	{
		"SuburbName" : "Turangi",
		"Postcode" : "3381"
	},
	{
		"SuburbName" : "Waihaha",
		"Postcode" : "3381"
	},
	{
		"SuburbName" : "Bulli Point",
		"Postcode" : "3382"
	},
	{
		"SuburbName" : "Desert Road",
		"Postcode" : "3382"
	},
	{
		"SuburbName" : "Hatepe",
		"Postcode" : "3382"
	},
	{
		"SuburbName" : "Kaimanawa Forest Park",
		"Postcode" : "3382"
	},
	{
		"SuburbName" : "Motuoapa",
		"Postcode" : "3382"
	},
	{
		"SuburbName" : "Motutere",
		"Postcode" : "3382"
	},
	{
		"SuburbName" : "Otukou",
		"Postcode" : "3382"
	},
	{
		"SuburbName" : "Rangipo",
		"Postcode" : "3382"
	},
	{
		"SuburbName" : "Tauranga Taupo",
		"Postcode" : "3382"
	},
	{
		"SuburbName" : "Tongariro National Park",
		"Postcode" : "3382"
	},
	{
		"SuburbName" : "Turangi",
		"Postcode" : "3382"
	},
	{
		"SuburbName" : "Atiamuri",
		"Postcode" : "3384"
	},
	{
		"SuburbName" : "Kinleith Forest",
		"Postcode" : "3384"
	},
	{
		"SuburbName" : "Ohakuri",
		"Postcode" : "3384"
	},
	{
		"SuburbName" : "Oruanui",
		"Postcode" : "3384"
	},
	{
		"SuburbName" : "Rangitira Park",
		"Postcode" : "3384"
	},
	{
		"SuburbName" : "Reporoa",
		"Postcode" : "3384"
	},
	{
		"SuburbName" : "Tahorakuri Forest",
		"Postcode" : "3384"
	},
	{
		"SuburbName" : "Taupo",
		"Postcode" : "3384"
	},
	{
		"SuburbName" : "Wairakei",
		"Postcode" : "3384"
	},
	{
		"SuburbName" : "Acacia Bay",
		"Postcode" : "3385"
	},
	{
		"SuburbName" : "Kinloch",
		"Postcode" : "3385"
	},
	{
		"SuburbName" : "Nukuhau",
		"Postcode" : "3385"
	},
	{
		"SuburbName" : "Taupo",
		"Postcode" : "3385"
	},
	{
		"SuburbName" : "Wairakei",
		"Postcode" : "3385"
	},
	{
		"SuburbName" : "Gordon",
		"Postcode" : "3391"
	},
	{
		"SuburbName" : "Manawaru",
		"Postcode" : "3391"
	},
	{
		"SuburbName" : "Te Aroha",
		"Postcode" : "3391"
	},
	{
		"SuburbName" : "Te Aroha West",
		"Postcode" : "3391"
	},
	{
		"SuburbName" : "Waharoa",
		"Postcode" : "3391"
	},
	{
		"SuburbName" : "Wardville",
		"Postcode" : "3391"
	},
	{
		"SuburbName" : "Otway",
		"Postcode" : "3392"
	},
	{
		"SuburbName" : "Te Aroha",
		"Postcode" : "3392"
	},
	{
		"SuburbName" : "Otway",
		"Postcode" : "3393"
	},
	{
		"SuburbName" : "Springdale",
		"Postcode" : "3393"
	},
	{
		"SuburbName" : "Te Aroha",
		"Postcode" : "3393"
	},
	{
		"SuburbName" : "Te Aroha West",
		"Postcode" : "3393"
	},
	{
		"SuburbName" : "Waihou",
		"Postcode" : "3393"
	},
	{
		"SuburbName" : "Waitoa",
		"Postcode" : "3393"
	},
	{
		"SuburbName" : "Hautapu",
		"Postcode" : "3451"
	},
	{
		"SuburbName" : "Hautapu",
		"Postcode" : "3451"
	},
	{
		"SuburbName" : "Gordon",
		"Postcode" : "3471"
	},
	{
		"SuburbName" : "Manawaru",
		"Postcode" : "3471"
	},
	{
		"SuburbName" : "Matamata",
		"Postcode" : "3471"
	},
	{
		"SuburbName" : "Okauia",
		"Postcode" : "3471"
	},
	{
		"SuburbName" : "Turangaomoana",
		"Postcode" : "3471"
	},
	{
		"SuburbName" : "Waharoa",
		"Postcode" : "3471"
	},
	{
		"SuburbName" : "Wardville",
		"Postcode" : "3471"
	},
	{
		"SuburbName" : "Cambridge",
		"Postcode" : "3472"
	},
	{
		"SuburbName" : "Karapiro",
		"Postcode" : "3472"
	},
	{
		"SuburbName" : "Matamata",
		"Postcode" : "3472"
	},
	{
		"SuburbName" : "Lower Kaimai",
		"Postcode" : "3473"
	},
	{
		"SuburbName" : "Matamata",
		"Postcode" : "3473"
	},
	{
		"SuburbName" : "Okoroire",
		"Postcode" : "3473"
	},
	{
		"SuburbName" : "Tauranga",
		"Postcode" : "3473"
	},
	{
		"SuburbName" : "Te Poi",
		"Postcode" : "3473"
	},
	{
		"SuburbName" : "Tirau",
		"Postcode" : "3473"
	},
	{
		"SuburbName" : "Manawaru",
		"Postcode" : "3474"
	},
	{
		"SuburbName" : "Matamata",
		"Postcode" : "3474"
	},
	{
		"SuburbName" : "Ngarua",
		"Postcode" : "3474"
	},
	{
		"SuburbName" : "Waharoa",
		"Postcode" : "3474"
	},
	{
		"SuburbName" : "Waitoa",
		"Postcode" : "3474"
	},
	{
		"SuburbName" : "Wardville",
		"Postcode" : "3474"
	},
	{
		"SuburbName" : "Richmond Downs",
		"Postcode" : "3475"
	},
	{
		"SuburbName" : "Waharoa",
		"Postcode" : "3475"
	},
	{
		"SuburbName" : "Walton",
		"Postcode" : "3475"
	},
	{
		"SuburbName" : "Arapuni",
		"Postcode" : "3481"
	},
	{
		"SuburbName" : "Kinleith",
		"Postcode" : "3481"
	},
	{
		"SuburbName" : "Lichfield",
		"Postcode" : "3481"
	},
	{
		"SuburbName" : "Putaruru",
		"Postcode" : "3481"
	},
	{
		"SuburbName" : "Tokoroa",
		"Postcode" : "3481"
	},
	{
		"SuburbName" : "Waotu",
		"Postcode" : "3481"
	},
	{
		"SuburbName" : "Kinleith",
		"Postcode" : "3482"
	},
	{
		"SuburbName" : "Lichfield",
		"Postcode" : "3482"
	},
	{
		"SuburbName" : "Mamaku",
		"Postcode" : "3482"
	},
	{
		"SuburbName" : "Ngatira",
		"Postcode" : "3482"
	},
	{
		"SuburbName" : "Putaruru",
		"Postcode" : "3482"
	},
	{
		"SuburbName" : "Tokoroa",
		"Postcode" : "3482"
	},
	{
		"SuburbName" : "Waotu",
		"Postcode" : "3482"
	},
	{
		"SuburbName" : "Wiltsdown",
		"Postcode" : "3482"
	},
	{
		"SuburbName" : "Mamaku",
		"Postcode" : "3483"
	},
	{
		"SuburbName" : "Ngatira",
		"Postcode" : "3483"
	},
	{
		"SuburbName" : "Putaruru",
		"Postcode" : "3483"
	},
	{
		"SuburbName" : "Tapapa",
		"Postcode" : "3483"
	},
	{
		"SuburbName" : "Tirau",
		"Postcode" : "3483"
	},
	{
		"SuburbName" : "Arapuni",
		"Postcode" : "3484"
	},
	{
		"SuburbName" : "Cambridge",
		"Postcode" : "3484"
	},
	{
		"SuburbName" : "Karapiro",
		"Postcode" : "3484"
	},
	{
		"SuburbName" : "Okoroire",
		"Postcode" : "3484"
	},
	{
		"SuburbName" : "Piarere",
		"Postcode" : "3484"
	},
	{
		"SuburbName" : "Putaruru",
		"Postcode" : "3484"
	},
	{
		"SuburbName" : "Tirau",
		"Postcode" : "3484"
	},
	{
		"SuburbName" : "Mamaku",
		"Postcode" : "3485"
	},
	{
		"SuburbName" : "Matamata",
		"Postcode" : "3485"
	},
	{
		"SuburbName" : "Ngatira",
		"Postcode" : "3485"
	},
	{
		"SuburbName" : "Okoroire",
		"Postcode" : "3485"
	},
	{
		"SuburbName" : "Putaruru",
		"Postcode" : "3485"
	},
	{
		"SuburbName" : "Tapapa",
		"Postcode" : "3485"
	},
	{
		"SuburbName" : "Te Poi",
		"Postcode" : "3485"
	},
	{
		"SuburbName" : "Tirau",
		"Postcode" : "3485"
	},
	{
		"SuburbName" : "Kinleith",
		"Postcode" : "3491"
	},
	{
		"SuburbName" : "Lichfield",
		"Postcode" : "3491"
	},
	{
		"SuburbName" : "Putaruru",
		"Postcode" : "3491"
	},
	{
		"SuburbName" : "Tokoroa",
		"Postcode" : "3491"
	},
	{
		"SuburbName" : "Wiltsdown",
		"Postcode" : "3491"
	},
	{
		"SuburbName" : "Kinleith",
		"Postcode" : "3492"
	},
	{
		"SuburbName" : "Mangakino",
		"Postcode" : "3492"
	},
	{
		"SuburbName" : "Marotiri",
		"Postcode" : "3492"
	},
	{
		"SuburbName" : "Taupo",
		"Postcode" : "3492"
	},
	{
		"SuburbName" : "Tihoi",
		"Postcode" : "3492"
	},
	{
		"SuburbName" : "Tirohanga",
		"Postcode" : "3492"
	},
	{
		"SuburbName" : "Tokoroa",
		"Postcode" : "3492"
	},
	{
		"SuburbName" : "Whakamaru",
		"Postcode" : "3492"
	},
	{
		"SuburbName" : "Cambridge",
		"Postcode" : "3493"
	},
	{
		"SuburbName" : "Hamilton",
		"Postcode" : "3493"
	},
	{
		"SuburbName" : "Matangi",
		"Postcode" : "3493"
	},
	{
		"SuburbName" : "Morrinsville",
		"Postcode" : "3493"
	},
	{
		"SuburbName" : "Pukemoremore",
		"Postcode" : "3493"
	},
	{
		"SuburbName" : "Tamahere",
		"Postcode" : "3493"
	},
	{
		"SuburbName" : "Tauwhare",
		"Postcode" : "3493"
	},
	{
		"SuburbName" : "Cambridge",
		"Postcode" : "3494"
	},
	{
		"SuburbName" : "Karapiro",
		"Postcode" : "3494"
	},
	{
		"SuburbName" : "Maungatautari",
		"Postcode" : "3494"
	},
	{
		"SuburbName" : "Pukeatua",
		"Postcode" : "3494"
	},
	{
		"SuburbName" : "Cambridge",
		"Postcode" : "3495"
	},
	{
		"SuburbName" : "Leamington",
		"Postcode" : "3495"
	},
	{
		"SuburbName" : "Maungatautari",
		"Postcode" : "3495"
	},
	{
		"SuburbName" : "Ohaupo",
		"Postcode" : "3495"
	},
	{
		"SuburbName" : "Rotoorangi",
		"Postcode" : "3495"
	},
	{
		"SuburbName" : "Cambridge",
		"Postcode" : "3496"
	},
	{
		"SuburbName" : "Hamilton",
		"Postcode" : "3496"
	},
	{
		"SuburbName" : "Karapiro",
		"Postcode" : "3496"
	},
	{
		"SuburbName" : "Pukemoremore",
		"Postcode" : "3496"
	},
	{
		"SuburbName" : "Te Miro",
		"Postcode" : "3496"
	},
	{
		"SuburbName" : "Preece Point",
		"Postcode" : "3506"
	},
	{
		"SuburbName" : "Ngatea",
		"Postcode" : "3574"
	},
	{
		"SuburbName" : "Orongo",
		"Postcode" : "3574"
	},
	{
		"SuburbName" : "Pipiroa",
		"Postcode" : "3574"
	},
	{
		"SuburbName" : "Thames",
		"Postcode" : "3574"
	},
	{
		"SuburbName" : "Turua",
		"Postcode" : "3574"
	},
	{
		"SuburbName" : "Coromandel Forest Park",
		"Postcode" : "3575"
	},
	{
		"SuburbName" : "Kereta",
		"Postcode" : "3575"
	},
	{
		"SuburbName" : "Ruamahunga",
		"Postcode" : "3575"
	},
	{
		"SuburbName" : "Tapu",
		"Postcode" : "3575"
	},
	{
		"SuburbName" : "Te Mata",
		"Postcode" : "3575"
	},
	{
		"SuburbName" : "Te Puru",
		"Postcode" : "3575"
	},
	{
		"SuburbName" : "Thames",
		"Postcode" : "3575"
	},
	{
		"SuburbName" : "Thornton Bay",
		"Postcode" : "3575"
	},
	{
		"SuburbName" : "Waiomu",
		"Postcode" : "3575"
	},
	{
		"SuburbName" : "Whakatete Bay",
		"Postcode" : "3575"
	},
	{
		"SuburbName" : "Mangatarata",
		"Postcode" : "3576"
	},
	{
		"SuburbName" : "Miranda",
		"Postcode" : "3576"
	},
	{
		"SuburbName" : "Ngatea",
		"Postcode" : "3576"
	},
	{
		"SuburbName" : "Pipiroa",
		"Postcode" : "3576"
	},
	{
		"SuburbName" : "Thames",
		"Postcode" : "3576"
	},
	{
		"SuburbName" : "Waitakaruru",
		"Postcode" : "3576"
	},
	{
		"SuburbName" : "Coromandel Forest Park",
		"Postcode" : "3577"
	},
	{
		"SuburbName" : "Thames",
		"Postcode" : "3577"
	},
	{
		"SuburbName" : "Kopu",
		"Postcode" : "3578"
	},
	{
		"SuburbName" : "Matatoki",
		"Postcode" : "3578"
	},
	{
		"SuburbName" : "Puriri",
		"Postcode" : "3578"
	},
	{
		"SuburbName" : "Thames",
		"Postcode" : "3578"
	},
	{
		"SuburbName" : "Duck Creek",
		"Postcode" : "3579"
	},
	{
		"SuburbName" : "Hikuai",
		"Postcode" : "3579"
	},
	{
		"SuburbName" : "Pauanui",
		"Postcode" : "3579"
	},
	{
		"SuburbName" : "Pukepoto",
		"Postcode" : "3579"
	},
	{
		"SuburbName" : "Slipper Island (Whakahau)",
		"Postcode" : "3579"
	},
	{
		"SuburbName" : "Slipper Island",
		"Postcode" : "3579"
	},
	{
		"SuburbName" : "Tairua",
		"Postcode" : "3579"
	},
	{
		"SuburbName" : "Coromandel",
		"Postcode" : "3581"
	},
	{
		"SuburbName" : "Kereta",
		"Postcode" : "3581"
	},
	{
		"SuburbName" : "Manaia",
		"Postcode" : "3581"
	},
	{
		"SuburbName" : "Preece Point",
		"Postcode" : "3581"
	},
	{
		"SuburbName" : "Te Kouma",
		"Postcode" : "3581"
	},
	{
		"SuburbName" : "Thames",
		"Postcode" : "3581"
	},
	{
		"SuburbName" : "Waiau",
		"Postcode" : "3581"
	},
	{
		"SuburbName" : "Wyuna Bay",
		"Postcode" : "3581"
	},
	{
		"SuburbName" : "Coromandel",
		"Postcode" : "3582"
	},
	{
		"SuburbName" : "Otapaurau",
		"Postcode" : "3582"
	},
	{
		"SuburbName" : "Te Rerenga",
		"Postcode" : "3582"
	},
	{
		"SuburbName" : "Whangapoua",
		"Postcode" : "3582"
	},
	{
		"SuburbName" : "Coromandel",
		"Postcode" : "3583"
	},
	{
		"SuburbName" : "Kennedy Bay",
		"Postcode" : "3583"
	},
	{
		"SuburbName" : "Tuateawa",
		"Postcode" : "3583"
	},
	{
		"SuburbName" : "Amodeo Bay",
		"Postcode" : "3584"
	},
	{
		"SuburbName" : "Colville",
		"Postcode" : "3584"
	},
	{
		"SuburbName" : "Coromandel",
		"Postcode" : "3584"
	},
	{
		"SuburbName" : "Coromandel Forest Park",
		"Postcode" : "3584"
	},
	{
		"SuburbName" : "Fletcher Bay",
		"Postcode" : "3584"
	},
	{
		"SuburbName" : "Port Charles",
		"Postcode" : "3584"
	},
	{
		"SuburbName" : "Port Jackson",
		"Postcode" : "3584"
	},
	{
		"SuburbName" : "Waiaro",
		"Postcode" : "3584"
	},
	{
		"SuburbName" : "Waikawau",
		"Postcode" : "3584"
	},
	{
		"SuburbName" : "Cooks Beach",
		"Postcode" : "3591"
	},
	{
		"SuburbName" : "Coroglen",
		"Postcode" : "3591"
	},
	{
		"SuburbName" : "Coromandel Forest Park",
		"Postcode" : "3591"
	},
	{
		"SuburbName" : "Ferry Landing",
		"Postcode" : "3591"
	},
	{
		"SuburbName" : "Hahei",
		"Postcode" : "3591"
	},
	{
		"SuburbName" : "Hot Water Beach",
		"Postcode" : "3591"
	},
	{
		"SuburbName" : "Kaimarama",
		"Postcode" : "3591"
	},
	{
		"SuburbName" : "Pumpkin Hill",
		"Postcode" : "3591"
	},
	{
		"SuburbName" : "Purangi",
		"Postcode" : "3591"
	},
	{
		"SuburbName" : "Whenuakite",
		"Postcode" : "3591"
	},
	{
		"SuburbName" : "Whitianga",
		"Postcode" : "3591"
	},
	{
		"SuburbName" : "Coromandel",
		"Postcode" : "3592"
	},
	{
		"SuburbName" : "Coromandel Forest Park",
		"Postcode" : "3592"
	},
	{
		"SuburbName" : "Kuaotunu",
		"Postcode" : "3592"
	},
	{
		"SuburbName" : "Kuaotunu West",
		"Postcode" : "3592"
	},
	{
		"SuburbName" : "Matarangi",
		"Postcode" : "3592"
	},
	{
		"SuburbName" : "Opito Bay",
		"Postcode" : "3592"
	},
	{
		"SuburbName" : "Otapaurau",
		"Postcode" : "3592"
	},
	{
		"SuburbName" : "Rings Beach",
		"Postcode" : "3592"
	},
	{
		"SuburbName" : "Simpsons Beach",
		"Postcode" : "3592"
	},
	{
		"SuburbName" : "Waitaia Bay",
		"Postcode" : "3592"
	},
	{
		"SuburbName" : "Whitianga",
		"Postcode" : "3592"
	},
	{
		"SuburbName" : "Kaihere",
		"Postcode" : "3597"
	},
	{
		"SuburbName" : "Kerepehi",
		"Postcode" : "3597"
	},
	{
		"SuburbName" : "Mangatarata",
		"Postcode" : "3597"
	},
	{
		"SuburbName" : "Ngatea",
		"Postcode" : "3597"
	},
	{
		"SuburbName" : "Paeroa",
		"Postcode" : "3597"
	},
	{
		"SuburbName" : "Pipiroa",
		"Postcode" : "3597"
	},
	{
		"SuburbName" : "Thames",
		"Postcode" : "3597"
	},
	{
		"SuburbName" : "Turua",
		"Postcode" : "3597"
	},
	{
		"SuburbName" : "Awaiti",
		"Postcode" : "3671"
	},
	{
		"SuburbName" : "Kerepehi",
		"Postcode" : "3671"
	},
	{
		"SuburbName" : "Netherton",
		"Postcode" : "3671"
	},
	{
		"SuburbName" : "Paeroa",
		"Postcode" : "3671"
	},
	{
		"SuburbName" : "Turua",
		"Postcode" : "3671"
	},
	{
		"SuburbName" : "Awaiti",
		"Postcode" : "3672"
	},
	{
		"SuburbName" : "Netherton",
		"Postcode" : "3672"
	},
	{
		"SuburbName" : "Paeroa",
		"Postcode" : "3672"
	},
	{
		"SuburbName" : "Awaiti",
		"Postcode" : "3673"
	},
	{
		"SuburbName" : "Otway",
		"Postcode" : "3673"
	},
	{
		"SuburbName" : "Paeroa",
		"Postcode" : "3673"
	},
	{
		"SuburbName" : "Te Aroha",
		"Postcode" : "3673"
	},
	{
		"SuburbName" : "Tirohia",
		"Postcode" : "3673"
	},
	{
		"SuburbName" : "Coromandel Forest Park",
		"Postcode" : "3674"
	},
	{
		"SuburbName" : "Hikutaia",
		"Postcode" : "3674"
	},
	{
		"SuburbName" : "Kaimai-Mamaku Forest Park",
		"Postcode" : "3674"
	},
	{
		"SuburbName" : "Karangahake",
		"Postcode" : "3674"
	},
	{
		"SuburbName" : "Komata",
		"Postcode" : "3674"
	},
	{
		"SuburbName" : "Paeroa",
		"Postcode" : "3674"
	},
	{
		"SuburbName" : "Puriri",
		"Postcode" : "3674"
	},
	{
		"SuburbName" : "Thames",
		"Postcode" : "3674"
	},
	{
		"SuburbName" : "Athenree Gorge",
		"Postcode" : "3681"
	},
	{
		"SuburbName" : "Waihi",
		"Postcode" : "3681"
	},
	{
		"SuburbName" : "Waihi Beach",
		"Postcode" : "3681"
	},
	{
		"SuburbName" : "Whangamata",
		"Postcode" : "3681"
	},
	{
		"SuburbName" : "Whiritoa",
		"Postcode" : "3681"
	},
	{
		"SuburbName" : "Athenree Gorge",
		"Postcode" : "3682"
	},
	{
		"SuburbName" : "Waihi",
		"Postcode" : "3682"
	},
	{
		"SuburbName" : "Waikino",
		"Postcode" : "3682"
	},
	{
		"SuburbName" : "Duck Creek",
		"Postcode" : "3691"
	},
	{
		"SuburbName" : "Hikuai",
		"Postcode" : "3691"
	},
	{
		"SuburbName" : "Onemana",
		"Postcode" : "3691"
	},
	{
		"SuburbName" : "Opoutere",
		"Postcode" : "3691"
	},
	{
		"SuburbName" : "Whangamata",
		"Postcode" : "3691"
	},
	{
		"SuburbName" : "Whiritoa",
		"Postcode" : "3691"
	},
	{
		"SuburbName" : "Glen Afton",
		"Postcode" : "3771"
	},
	{
		"SuburbName" : "Huntly",
		"Postcode" : "3771"
	},
	{
		"SuburbName" : "Ngaruawahia",
		"Postcode" : "3771"
	},
	{
		"SuburbName" : "Ohinewai",
		"Postcode" : "3771"
	},
	{
		"SuburbName" : "Pukemiro",
		"Postcode" : "3771"
	},
	{
		"SuburbName" : "Pukemiro",
		"Postcode" : "3771"
	},
	{
		"SuburbName" : "Rangiriri",
		"Postcode" : "3771"
	},
	{
		"SuburbName" : "Rangiriri West",
		"Postcode" : "3771"
	},
	{
		"SuburbName" : "Rotongaro",
		"Postcode" : "3771"
	},
	{
		"SuburbName" : "Rotowaro",
		"Postcode" : "3771"
	},
	{
		"SuburbName" : "Te Kauwhata",
		"Postcode" : "3771"
	},
	{
		"SuburbName" : "Te Ohaki",
		"Postcode" : "3771"
	},
	{
		"SuburbName" : "Waikokowai",
		"Postcode" : "3771"
	},
	{
		"SuburbName" : "Churchill",
		"Postcode" : "3772"
	},
	{
		"SuburbName" : "Glen Murray",
		"Postcode" : "3772"
	},
	{
		"SuburbName" : "Huntly",
		"Postcode" : "3772"
	},
	{
		"SuburbName" : "Naike",
		"Postcode" : "3772"
	},
	{
		"SuburbName" : "Onewhero",
		"Postcode" : "3772"
	},
	{
		"SuburbName" : "Pepepe",
		"Postcode" : "3772"
	},
	{
		"SuburbName" : "Rangiriri West",
		"Postcode" : "3772"
	},
	{
		"SuburbName" : "Rotongaro",
		"Postcode" : "3772"
	},
	{
		"SuburbName" : "Ruawaro",
		"Postcode" : "3772"
	},
	{
		"SuburbName" : "Te Ohaki",
		"Postcode" : "3772"
	},
	{
		"SuburbName" : "Maramarua",
		"Postcode" : "3781"
	},
	{
		"SuburbName" : "Te Kauwhata",
		"Postcode" : "3781"
	},
	{
		"SuburbName" : "Waerenga",
		"Postcode" : "3781"
	},
	{
		"SuburbName" : "Waiterimu",
		"Postcode" : "3781"
	},
	{
		"SuburbName" : "Hampton Downs",
		"Postcode" : "3782"
	},
	{
		"SuburbName" : "Huntly",
		"Postcode" : "3782"
	},
	{
		"SuburbName" : "Island Block",
		"Postcode" : "3782"
	},
	{
		"SuburbName" : "Maramarua",
		"Postcode" : "3782"
	},
	{
		"SuburbName" : "Mercer",
		"Postcode" : "3782"
	},
	{
		"SuburbName" : "Meremere",
		"Postcode" : "3782"
	},
	{
		"SuburbName" : "Rangiriri",
		"Postcode" : "3782"
	},
	{
		"SuburbName" : "Rangiriri West",
		"Postcode" : "3782"
	},
	{
		"SuburbName" : "Te Kauwhata",
		"Postcode" : "3782"
	},
	{
		"SuburbName" : "Waerenga",
		"Postcode" : "3782"
	},
	{
		"SuburbName" : "Whangamarino",
		"Postcode" : "3782"
	},
	{
		"SuburbName" : "Hoe-O-Tainui",
		"Postcode" : "3784"
	},
	{
		"SuburbName" : "Huntly",
		"Postcode" : "3784"
	},
	{
		"SuburbName" : "Ohinewai",
		"Postcode" : "3784"
	},
	{
		"SuburbName" : "Tahuna",
		"Postcode" : "3784"
	},
	{
		"SuburbName" : "Te Hoe",
		"Postcode" : "3784"
	},
	{
		"SuburbName" : "Te Kauwhata",
		"Postcode" : "3784"
	},
	{
		"SuburbName" : "Waerenga",
		"Postcode" : "3784"
	},
	{
		"SuburbName" : "Waiterimu",
		"Postcode" : "3784"
	},
	{
		"SuburbName" : "Gordonton",
		"Postcode" : "3791"
	},
	{
		"SuburbName" : "Hamilton",
		"Postcode" : "3791"
	},
	{
		"SuburbName" : "Horsham Downs",
		"Postcode" : "3791"
	},
	{
		"SuburbName" : "Ngaruawahia",
		"Postcode" : "3791"
	},
	{
		"SuburbName" : "Taupiri",
		"Postcode" : "3791"
	},
	{
		"SuburbName" : "Ngaruawahia",
		"Postcode" : "3792"
	},
	{
		"SuburbName" : "Orini",
		"Postcode" : "3792"
	},
	{
		"SuburbName" : "Tahuna",
		"Postcode" : "3792"
	},
	{
		"SuburbName" : "Taupiri",
		"Postcode" : "3792"
	},
	{
		"SuburbName" : "Te Hoe",
		"Postcode" : "3792"
	},
	{
		"SuburbName" : "Whitikahu",
		"Postcode" : "3792"
	},
	{
		"SuburbName" : "Glen Massey",
		"Postcode" : "3793"
	},
	{
		"SuburbName" : "Ngaruawahia",
		"Postcode" : "3793"
	},
	{
		"SuburbName" : "Te Akau",
		"Postcode" : "3793"
	},
	{
		"SuburbName" : "Waingaro",
		"Postcode" : "3793"
	},
	{
		"SuburbName" : "Glen Massey",
		"Postcode" : "3794"
	},
	{
		"SuburbName" : "Glen Murray",
		"Postcode" : "3794"
	},
	{
		"SuburbName" : "Huntly",
		"Postcode" : "3794"
	},
	{
		"SuburbName" : "Matira",
		"Postcode" : "3794"
	},
	{
		"SuburbName" : "Naike",
		"Postcode" : "3794"
	},
	{
		"SuburbName" : "Ngaruawahia",
		"Postcode" : "3794"
	},
	{
		"SuburbName" : "Onewhero",
		"Postcode" : "3794"
	},
	{
		"SuburbName" : "Pepepe",
		"Postcode" : "3794"
	},
	{
		"SuburbName" : "Port Waikato",
		"Postcode" : "3794"
	},
	{
		"SuburbName" : "Pukemiro",
		"Postcode" : "3794"
	},
	{
		"SuburbName" : "Te Akau",
		"Postcode" : "3794"
	},
	{
		"SuburbName" : "Waikaretu",
		"Postcode" : "3794"
	},
	{
		"SuburbName" : "Waingaro",
		"Postcode" : "3794"
	},
	{
		"SuburbName" : "Kihikihi",
		"Postcode" : "3872"
	},
	{
		"SuburbName" : "Parawera",
		"Postcode" : "3872"
	},
	{
		"SuburbName" : "Pukeatua",
		"Postcode" : "3872"
	},
	{
		"SuburbName" : "Te Awamutu",
		"Postcode" : "3872"
	},
	{
		"SuburbName" : "Maihiihi",
		"Postcode" : "3873"
	},
	{
		"SuburbName" : "Ngahape",
		"Postcode" : "3873"
	},
	{
		"SuburbName" : "Otorohanga",
		"Postcode" : "3873"
	},
	{
		"SuburbName" : "Parawera",
		"Postcode" : "3873"
	},
	{
		"SuburbName" : "Pokuru",
		"Postcode" : "3873"
	},
	{
		"SuburbName" : "Te Awamutu",
		"Postcode" : "3873"
	},
	{
		"SuburbName" : "Te Kawa",
		"Postcode" : "3873"
	},
	{
		"SuburbName" : "Tokanui",
		"Postcode" : "3873"
	},
	{
		"SuburbName" : "Waikeria",
		"Postcode" : "3873"
	},
	{
		"SuburbName" : "Wharepuhunga",
		"Postcode" : "3873"
	},
	{
		"SuburbName" : "Kihikihi",
		"Postcode" : "3874"
	},
	{
		"SuburbName" : "Parawera",
		"Postcode" : "3874"
	},
	{
		"SuburbName" : "Pureora Forest Park",
		"Postcode" : "3874"
	},
	{
		"SuburbName" : "Te Awamutu",
		"Postcode" : "3874"
	},
	{
		"SuburbName" : "Tokanui",
		"Postcode" : "3874"
	},
	{
		"SuburbName" : "Waikeria",
		"Postcode" : "3874"
	},
	{
		"SuburbName" : "Wharepuhunga",
		"Postcode" : "3874"
	},
	{
		"SuburbName" : "Kihikihi",
		"Postcode" : "3875"
	},
	{
		"SuburbName" : "Pokuru",
		"Postcode" : "3875"
	},
	{
		"SuburbName" : "Te Awamutu",
		"Postcode" : "3875"
	},
	{
		"SuburbName" : "Te Kawa",
		"Postcode" : "3875"
	},
	{
		"SuburbName" : "Tokanui",
		"Postcode" : "3875"
	},
	{
		"SuburbName" : "Honikiwi",
		"Postcode" : "3876"
	},
	{
		"SuburbName" : "Ngahinapouri",
		"Postcode" : "3876"
	},
	{
		"SuburbName" : "Ohaupo",
		"Postcode" : "3876"
	},
	{
		"SuburbName" : "Otorohanga",
		"Postcode" : "3876"
	},
	{
		"SuburbName" : "Pirongia",
		"Postcode" : "3876"
	},
	{
		"SuburbName" : "Puketotara",
		"Postcode" : "3876"
	},
	{
		"SuburbName" : "Te Awamutu",
		"Postcode" : "3876"
	},
	{
		"SuburbName" : "Parawera",
		"Postcode" : "3877"
	},
	{
		"SuburbName" : "Pureora Forest Park",
		"Postcode" : "3877"
	},
	{
		"SuburbName" : "Te Awamutu",
		"Postcode" : "3877"
	},
	{
		"SuburbName" : "Wharepapa South",
		"Postcode" : "3877"
	},
	{
		"SuburbName" : "Wharepuhunga",
		"Postcode" : "3877"
	},
	{
		"SuburbName" : "Hauturu",
		"Postcode" : "3878"
	},
	{
		"SuburbName" : "Honikiwi",
		"Postcode" : "3878"
	},
	{
		"SuburbName" : "Kawhia",
		"Postcode" : "3878"
	},
	{
		"SuburbName" : "Oparau",
		"Postcode" : "3878"
	},
	{
		"SuburbName" : "Otorohanga",
		"Postcode" : "3878"
	},
	{
		"SuburbName" : "Te Awamutu",
		"Postcode" : "3878"
	},
	{
		"SuburbName" : "Te Rauamoa",
		"Postcode" : "3878"
	},
	{
		"SuburbName" : "Cambridge",
		"Postcode" : "3879"
	},
	{
		"SuburbName" : "Parawera",
		"Postcode" : "3879"
	},
	{
		"SuburbName" : "Rotoorangi",
		"Postcode" : "3879"
	},
	{
		"SuburbName" : "Te Awamutu",
		"Postcode" : "3879"
	},
	{
		"SuburbName" : "Cambridge",
		"Postcode" : "3880"
	},
	{
		"SuburbName" : "Parawera",
		"Postcode" : "3880"
	},
	{
		"SuburbName" : "Pukeatua",
		"Postcode" : "3880"
	},
	{
		"SuburbName" : "Rotoorangi",
		"Postcode" : "3880"
	},
	{
		"SuburbName" : "Te Awamutu",
		"Postcode" : "3880"
	},
	{
		"SuburbName" : "Wharepapa South",
		"Postcode" : "3880"
	},
	{
		"SuburbName" : "Ohaupo",
		"Postcode" : "3881"
	},
	{
		"SuburbName" : "Hamilton",
		"Postcode" : "3882"
	},
	{
		"SuburbName" : "Ngahinapouri",
		"Postcode" : "3882"
	},
	{
		"SuburbName" : "Ohaupo",
		"Postcode" : "3882"
	},
	{
		"SuburbName" : "Rukuhia",
		"Postcode" : "3882"
	},
	{
		"SuburbName" : "Tamahere",
		"Postcode" : "3882"
	},
	{
		"SuburbName" : "Ngahinapouri",
		"Postcode" : "3883"
	},
	{
		"SuburbName" : "Ohaupo",
		"Postcode" : "3883"
	},
	{
		"SuburbName" : "Te Awamutu",
		"Postcode" : "3883"
	},
	{
		"SuburbName" : "Oparau",
		"Postcode" : "3885"
	},
	{
		"SuburbName" : "Hauturu",
		"Postcode" : "3886"
	},
	{
		"SuburbName" : "Honikiwi",
		"Postcode" : "3886"
	},
	{
		"SuburbName" : "Oparau",
		"Postcode" : "3886"
	},
	{
		"SuburbName" : "Otorohanga",
		"Postcode" : "3886"
	},
	{
		"SuburbName" : "Owhiro",
		"Postcode" : "3886"
	},
	{
		"SuburbName" : "Te Anga",
		"Postcode" : "3886"
	},
	{
		"SuburbName" : "Te Awamutu",
		"Postcode" : "3886"
	},
	{
		"SuburbName" : "Te Kuiti",
		"Postcode" : "3886"
	},
	{
		"SuburbName" : "Kawhia",
		"Postcode" : "3889"
	},
	{
		"SuburbName" : "Oparau",
		"Postcode" : "3889"
	},
	{
		"SuburbName" : "Makomako",
		"Postcode" : "3894"
	},
	{
		"SuburbName" : "Raglan",
		"Postcode" : "3894"
	},
	{
		"SuburbName" : "Te Mata",
		"Postcode" : "3894"
	},
	{
		"SuburbName" : "Kawhia",
		"Postcode" : "3895"
	},
	{
		"SuburbName" : "Makomako",
		"Postcode" : "3895"
	},
	{
		"SuburbName" : "Raglan",
		"Postcode" : "3895"
	},
	{
		"SuburbName" : "Te Mata",
		"Postcode" : "3895"
	},
	{
		"SuburbName" : "Aria",
		"Postcode" : "3970"
	},
	{
		"SuburbName" : "Mahoenui",
		"Postcode" : "3970"
	},
	{
		"SuburbName" : "Piopio",
		"Postcode" : "3970"
	},
	{
		"SuburbName" : "Te Kuiti",
		"Postcode" : "3970"
	},
	{
		"SuburbName" : "Te Mapara",
		"Postcode" : "3970"
	},
	{
		"SuburbName" : "Mangaotaki",
		"Postcode" : "3971"
	},
	{
		"SuburbName" : "Piopio",
		"Postcode" : "3971"
	},
	{
		"SuburbName" : "Pomarangai",
		"Postcode" : "3971"
	},
	{
		"SuburbName" : "Maihiihi",
		"Postcode" : "3972"
	},
	{
		"SuburbName" : "Ngahape",
		"Postcode" : "3972"
	},
	{
		"SuburbName" : "Otewa",
		"Postcode" : "3972"
	},
	{
		"SuburbName" : "Otorohanga",
		"Postcode" : "3972"
	},
	{
		"SuburbName" : "Te Awamutu",
		"Postcode" : "3972"
	},
	{
		"SuburbName" : "Wharepuhunga",
		"Postcode" : "3972"
	},
	{
		"SuburbName" : "Honikiwi",
		"Postcode" : "3973"
	},
	{
		"SuburbName" : "Otorohanga",
		"Postcode" : "3973"
	},
	{
		"SuburbName" : "Pirongia",
		"Postcode" : "3973"
	},
	{
		"SuburbName" : "Pirongia Forest Park",
		"Postcode" : "3973"
	},
	{
		"SuburbName" : "Puketotara",
		"Postcode" : "3973"
	},
	{
		"SuburbName" : "Te Awamutu",
		"Postcode" : "3973"
	},
	{
		"SuburbName" : "Te Kawa",
		"Postcode" : "3973"
	},
	{
		"SuburbName" : "Tihiroa",
		"Postcode" : "3973"
	},
	{
		"SuburbName" : "Otorohanga",
		"Postcode" : "3974"
	},
	{
		"SuburbName" : "Te Awamutu",
		"Postcode" : "3974"
	},
	{
		"SuburbName" : "Te Kawa",
		"Postcode" : "3974"
	},
	{
		"SuburbName" : "Maihiihi",
		"Postcode" : "3975"
	},
	{
		"SuburbName" : "Otewa",
		"Postcode" : "3975"
	},
	{
		"SuburbName" : "Otorohanga",
		"Postcode" : "3975"
	},
	{
		"SuburbName" : "Rangitoto",
		"Postcode" : "3975"
	},
	{
		"SuburbName" : "Te Kuiti",
		"Postcode" : "3975"
	},
	{
		"SuburbName" : "Waimahora",
		"Postcode" : "3975"
	},
	{
		"SuburbName" : "Hangatiki",
		"Postcode" : "3976"
	},
	{
		"SuburbName" : "Otorohanga",
		"Postcode" : "3976"
	},
	{
		"SuburbName" : "Te Kuiti",
		"Postcode" : "3976"
	},
	{
		"SuburbName" : "Hangatiki",
		"Postcode" : "3977"
	},
	{
		"SuburbName" : "Otorohanga",
		"Postcode" : "3977"
	},
	{
		"SuburbName" : "Te Kuiti",
		"Postcode" : "3977"
	},
	{
		"SuburbName" : "Waitomo",
		"Postcode" : "3977"
	},
	{
		"SuburbName" : "Awakino",
		"Postcode" : "3978"
	},
	{
		"SuburbName" : "Mahoenui",
		"Postcode" : "3978"
	},
	{
		"SuburbName" : "Mokau",
		"Postcode" : "3978"
	},
	{
		"SuburbName" : "Aria",
		"Postcode" : "3979"
	},
	{
		"SuburbName" : "ARIA",
		"Postcode" : "3979"
	},
	{
		"SuburbName" : "Mahoenui",
		"Postcode" : "3979"
	},
	{
		"SuburbName" : "Matiere",
		"Postcode" : "3979"
	},
	{
		"SuburbName" : "Otangiwai",
		"Postcode" : "3979"
	},
	{
		"SuburbName" : "Ahititi",
		"Postcode" : "3980"
	},
	{
		"SuburbName" : "Ohura",
		"Postcode" : "3980"
	},
	{
		"SuburbName" : "Stratford",
		"Postcode" : "3980"
	},
	{
		"SuburbName" : "Tangarakau",
		"Postcode" : "3980"
	},
	{
		"SuburbName" : "Tokirima",
		"Postcode" : "3980"
	},
	{
		"SuburbName" : "Urenui",
		"Postcode" : "3980"
	},
	{
		"SuburbName" : "Waitaanga",
		"Postcode" : "3980"
	},
	{
		"SuburbName" : "Aria",
		"Postcode" : "3981"
	},
	{
		"SuburbName" : "Piopio",
		"Postcode" : "3981"
	},
	{
		"SuburbName" : "Puketutu",
		"Postcode" : "3981"
	},
	{
		"SuburbName" : "Te Kuiti",
		"Postcode" : "3981"
	},
	{
		"SuburbName" : "Te Mapara",
		"Postcode" : "3981"
	},
	{
		"SuburbName" : "Waitomo",
		"Postcode" : "3981"
	},
	{
		"SuburbName" : "Rangitoto",
		"Postcode" : "3982"
	},
	{
		"SuburbName" : "Te Kuiti",
		"Postcode" : "3982"
	},
	{
		"SuburbName" : "Aria",
		"Postcode" : "3983"
	},
	{
		"SuburbName" : "Kopaki",
		"Postcode" : "3983"
	},
	{
		"SuburbName" : "Mangaokewa",
		"Postcode" : "3983"
	},
	{
		"SuburbName" : "Puketutu",
		"Postcode" : "3983"
	},
	{
		"SuburbName" : "Te Kuiti",
		"Postcode" : "3983"
	},
	{
		"SuburbName" : "Te Mapara",
		"Postcode" : "3983"
	},
	{
		"SuburbName" : "Hangatiki",
		"Postcode" : "3985"
	},
	{
		"SuburbName" : "Mangaotaki",
		"Postcode" : "3985"
	},
	{
		"SuburbName" : "Marokopa",
		"Postcode" : "3985"
	},
	{
		"SuburbName" : "Piopio",
		"Postcode" : "3985"
	},
	{
		"SuburbName" : "Pomarangai",
		"Postcode" : "3985"
	},
	{
		"SuburbName" : "Te Anga",
		"Postcode" : "3985"
	},
	{
		"SuburbName" : "Te Kuiti",
		"Postcode" : "3985"
	},
	{
		"SuburbName" : "WAITOMO DISTRICT",
		"Postcode" : "3985"
	},
	{
		"SuburbName" : "Waitomo",
		"Postcode" : "3985"
	},
	{
		"SuburbName" : "Hangatiki",
		"Postcode" : "3986"
	},
	{
		"SuburbName" : "Te Kuiti",
		"Postcode" : "3986"
	},
	{
		"SuburbName" : "Benneydale",
		"Postcode" : "3987"
	},
	{
		"SuburbName" : "Kopaki",
		"Postcode" : "3987"
	},
	{
		"SuburbName" : "Mangakino",
		"Postcode" : "3987"
	},
	{
		"SuburbName" : "Mangaokewa",
		"Postcode" : "3987"
	},
	{
		"SuburbName" : "Pureora Forest Park",
		"Postcode" : "3987"
	},
	{
		"SuburbName" : "Te Kuiti",
		"Postcode" : "3987"
	},
	{
		"SuburbName" : "Waimiha",
		"Postcode" : "3987"
	},
	{
		"SuburbName" : "Waipa Valley",
		"Postcode" : "3987"
	},
	{
		"SuburbName" : "Marokopa",
		"Postcode" : "3988"
	},
	{
		"SuburbName" : "Piopio",
		"Postcode" : "3988"
	},
	{
		"SuburbName" : "Taharoa",
		"Postcode" : "3988"
	},
	{
		"SuburbName" : "Te Anga",
		"Postcode" : "3988"
	},
	{
		"SuburbName" : "Te Kuiti",
		"Postcode" : "3988"
	},
	{
		"SuburbName" : "Waitomo",
		"Postcode" : "3988"
	},
	{
		"SuburbName" : "Hikumutu",
		"Postcode" : "3989"
	},
	{
		"SuburbName" : "Kakahi",
		"Postcode" : "3989"
	},
	{
		"SuburbName" : "Manunui",
		"Postcode" : "3989"
	},
	{
		"SuburbName" : "National Park",
		"Postcode" : "3989"
	},
	{
		"SuburbName" : "Ohakune",
		"Postcode" : "3989"
	},
	{
		"SuburbName" : "Owhango",
		"Postcode" : "3989"
	},
	{
		"SuburbName" : "Raurimu",
		"Postcode" : "3989"
	},
	{
		"SuburbName" : "Taumarunui",
		"Postcode" : "3989"
	},
	{
		"SuburbName" : "Tongariro",
		"Postcode" : "3989"
	},
	{
		"SuburbName" : "Tongariro Forest Park",
		"Postcode" : "3989"
	},
	{
		"SuburbName" : "Tongariro National Park",
		"Postcode" : "3989"
	},
	{
		"SuburbName" : "Turangi",
		"Postcode" : "3989"
	},
	{
		"SuburbName" : "Erua",
		"Postcode" : "3990"
	},
	{
		"SuburbName" : "Kaitieke",
		"Postcode" : "3990"
	},
	{
		"SuburbName" : "Owhango",
		"Postcode" : "3990"
	},
	{
		"SuburbName" : "OWHANGO",
		"Postcode" : "3990"
	},
	{
		"SuburbName" : "Raurimu",
		"Postcode" : "3990"
	},
	{
		"SuburbName" : "Retaruke",
		"Postcode" : "3990"
	},
	{
		"SuburbName" : "Wanganui National Park",
		"Postcode" : "3990"
	},
	{
		"SuburbName" : "Whanganui National Park",
		"Postcode" : "3990"
	},
	{
		"SuburbName" : "Aukopae",
		"Postcode" : "3991"
	},
	{
		"SuburbName" : "Ohura",
		"Postcode" : "3991"
	},
	{
		"SuburbName" : "Taumarunui",
		"Postcode" : "3991"
	},
	{
		"SuburbName" : "Tokirima",
		"Postcode" : "3991"
	},
	{
		"SuburbName" : "Hikumutu",
		"Postcode" : "3992"
	},
	{
		"SuburbName" : "Kirikau",
		"Postcode" : "3992"
	},
	{
		"SuburbName" : "Manunui",
		"Postcode" : "3992"
	},
	{
		"SuburbName" : "Taumarunui",
		"Postcode" : "3992"
	},
	{
		"SuburbName" : "Kirikau",
		"Postcode" : "3993"
	},
	{
		"SuburbName" : "Taumarunui",
		"Postcode" : "3993"
	},
	{
		"SuburbName" : "Wanganui National Park",
		"Postcode" : "3993"
	},
	{
		"SuburbName" : "Whanganui National Park",
		"Postcode" : "3993"
	},
	{
		"SuburbName" : "Kakahi",
		"Postcode" : "3994"
	},
	{
		"SuburbName" : "Manunui",
		"Postcode" : "3994"
	},
	{
		"SuburbName" : "Ngapuke",
		"Postcode" : "3994"
	},
	{
		"SuburbName" : "Ongarue",
		"Postcode" : "3994"
	},
	{
		"SuburbName" : "Taringamotu",
		"Postcode" : "3994"
	},
	{
		"SuburbName" : "Taumarunui",
		"Postcode" : "3994"
	},
	{
		"SuburbName" : "Matiere",
		"Postcode" : "3995"
	},
	{
		"SuburbName" : "Ohura",
		"Postcode" : "3995"
	},
	{
		"SuburbName" : "Otangiwai",
		"Postcode" : "3995"
	},
	{
		"SuburbName" : "Matiere",
		"Postcode" : "3996"
	},
	{
		"SuburbName" : "Ongarue",
		"Postcode" : "3996"
	},
	{
		"SuburbName" : "Taumarunui",
		"Postcode" : "3996"
	},
	{
		"SuburbName" : "Matiere",
		"Postcode" : "3997"
	},
	{
		"SuburbName" : "Ongarue",
		"Postcode" : "3997"
	},
	{
		"SuburbName" : "Otangiwai",
		"Postcode" : "3997"
	},
	{
		"SuburbName" : "Pureora Forest Park",
		"Postcode" : "3997"
	},
	{
		"SuburbName" : "Waimiha",
		"Postcode" : "3997"
	},
	{
		"SuburbName" : "Waimiha",
		"Postcode" : "3998"
	},
	{
		"SuburbName" : "Awapuni",
		"Postcode" : "4071"
	},
	{
		"SuburbName" : "Gisborne",
		"Postcode" : "4071"
	},
	{
		"SuburbName" : "Gisborne",
		"Postcode" : "4071"
	},
	{
		"SuburbName" : "Hexton",
		"Postcode" : "4071"
	},
	{
		"SuburbName" : "Lytton West",
		"Postcode" : "4071"
	},
	{
		"SuburbName" : "Makaraka",
		"Postcode" : "4071"
	},
	{
		"SuburbName" : "Makauri",
		"Postcode" : "4071"
	},
	{
		"SuburbName" : "Manutuke",
		"Postcode" : "4071"
	},
	{
		"SuburbName" : "Matawhero",
		"Postcode" : "4071"
	},
	{
		"SuburbName" : "Matokitoki",
		"Postcode" : "4071"
	},
	{
		"SuburbName" : "Ormond",
		"Postcode" : "4071"
	},
	{
		"SuburbName" : "Outer Kaiti",
		"Postcode" : "4071"
	},
	{
		"SuburbName" : "Patutahi",
		"Postcode" : "4071"
	},
	{
		"SuburbName" : "Pouawa",
		"Postcode" : "4071"
	},
	{
		"SuburbName" : "Riverdale",
		"Postcode" : "4071"
	},
	{
		"SuburbName" : "Taruheru",
		"Postcode" : "4071"
	},
	{
		"SuburbName" : "Te Karaka",
		"Postcode" : "4071"
	},
	{
		"SuburbName" : "Waerengaahika",
		"Postcode" : "4071"
	},
	{
		"SuburbName" : "Waihirere",
		"Postcode" : "4071"
	},
	{
		"SuburbName" : "Waimata",
		"Postcode" : "4071"
	},
	{
		"SuburbName" : "Waipaoa",
		"Postcode" : "4071"
	},
	{
		"SuburbName" : "Waituhi",
		"Postcode" : "4071"
	},
	{
		"SuburbName" : "Whataupoko",
		"Postcode" : "4071"
	},
	{
		"SuburbName" : "Bartletts",
		"Postcode" : "4072"
	},
	{
		"SuburbName" : "Gisborne",
		"Postcode" : "4072"
	},
	{
		"SuburbName" : "Manutuke",
		"Postcode" : "4072"
	},
	{
		"SuburbName" : "Muriwai",
		"Postcode" : "4072"
	},
	{
		"SuburbName" : "Ngatapa",
		"Postcode" : "4072"
	},
	{
		"SuburbName" : "Nuhaka",
		"Postcode" : "4072"
	},
	{
		"SuburbName" : "Ormond",
		"Postcode" : "4072"
	},
	{
		"SuburbName" : "Otoko",
		"Postcode" : "4072"
	},
	{
		"SuburbName" : "Patutahi",
		"Postcode" : "4072"
	},
	{
		"SuburbName" : "Pehiri",
		"Postcode" : "4072"
	},
	{
		"SuburbName" : "Rere",
		"Postcode" : "4072"
	},
	{
		"SuburbName" : "Tahunga",
		"Postcode" : "4072"
	},
	{
		"SuburbName" : "Te Karaka",
		"Postcode" : "4072"
	},
	{
		"SuburbName" : "Tiniroto",
		"Postcode" : "4072"
	},
	{
		"SuburbName" : "Waerengaokuri",
		"Postcode" : "4072"
	},
	{
		"SuburbName" : "Waituhi",
		"Postcode" : "4072"
	},
	{
		"SuburbName" : "Wharekopae",
		"Postcode" : "4072"
	},
	{
		"SuburbName" : "Gisborne",
		"Postcode" : "4073"
	},
	{
		"SuburbName" : "Makauri",
		"Postcode" : "4073"
	},
	{
		"SuburbName" : "Makorori",
		"Postcode" : "4073"
	},
	{
		"SuburbName" : "Matokitoki",
		"Postcode" : "4073"
	},
	{
		"SuburbName" : "Okitu",
		"Postcode" : "4073"
	},
	{
		"SuburbName" : "Outer Kaiti",
		"Postcode" : "4073"
	},
	{
		"SuburbName" : "Pouawa",
		"Postcode" : "4073"
	},
	{
		"SuburbName" : "Tolaga",
		"Postcode" : "4073"
	},
	{
		"SuburbName" : "Tolaga Bay",
		"Postcode" : "4073"
	},
	{
		"SuburbName" : "Waimata",
		"Postcode" : "4073"
	},
	{
		"SuburbName" : "Wainui",
		"Postcode" : "4073"
	},
	{
		"SuburbName" : "Whangara",
		"Postcode" : "4073"
	},
	{
		"SuburbName" : "Matawai",
		"Postcode" : "4075"
	},
	{
		"SuburbName" : "Rakauroa",
		"Postcode" : "4075"
	},
	{
		"SuburbName" : "Wharekopae",
		"Postcode" : "4075"
	},
	{
		"SuburbName" : "Anaura Bay",
		"Postcode" : "4077"
	},
	{
		"SuburbName" : "Pouawa",
		"Postcode" : "4077"
	},
	{
		"SuburbName" : "Tauwhareparae",
		"Postcode" : "4077"
	},
	{
		"SuburbName" : "Te Karaka",
		"Postcode" : "4077"
	},
	{
		"SuburbName" : "Tokomaru Bay",
		"Postcode" : "4077"
	},
	{
		"SuburbName" : "Tolaga",
		"Postcode" : "4077"
	},
	{
		"SuburbName" : "Tolaga Bay",
		"Postcode" : "4077"
	},
	{
		"SuburbName" : "Whatatutu",
		"Postcode" : "4077"
	},
	{
		"SuburbName" : "Bartletts",
		"Postcode" : "4078"
	},
	{
		"SuburbName" : "Hangaroa",
		"Postcode" : "4078"
	},
	{
		"SuburbName" : "Manutuke",
		"Postcode" : "4078"
	},
	{
		"SuburbName" : "Morere",
		"Postcode" : "4078"
	},
	{
		"SuburbName" : "Muriwai",
		"Postcode" : "4078"
	},
	{
		"SuburbName" : "Nuhaka",
		"Postcode" : "4078"
	},
	{
		"SuburbName" : "Pehiri",
		"Postcode" : "4078"
	},
	{
		"SuburbName" : "Tiniroto",
		"Postcode" : "4078"
	},
	{
		"SuburbName" : "Waingake",
		"Postcode" : "4078"
	},
	{
		"SuburbName" : "Wharerata",
		"Postcode" : "4078"
	},
	{
		"SuburbName" : "Anaura Bay",
		"Postcode" : "4079"
	},
	{
		"SuburbName" : "Raukumara",
		"Postcode" : "4079"
	},
	{
		"SuburbName" : "Ruatoria",
		"Postcode" : "4079"
	},
	{
		"SuburbName" : "Tauwhareparae",
		"Postcode" : "4079"
	},
	{
		"SuburbName" : "Te Puia Springs",
		"Postcode" : "4079"
	},
	{
		"SuburbName" : "Tokomaru",
		"Postcode" : "4079"
	},
	{
		"SuburbName" : "Tokomaru Bay",
		"Postcode" : "4079"
	},
	{
		"SuburbName" : "Tolaga Bay",
		"Postcode" : "4079"
	},
	{
		"SuburbName" : "Waipiro Bay",
		"Postcode" : "4079"
	},
	{
		"SuburbName" : "Raukumara",
		"Postcode" : "4081"
	},
	{
		"SuburbName" : "Ruatoria",
		"Postcode" : "4081"
	},
	{
		"SuburbName" : "Te Puia Springs",
		"Postcode" : "4081"
	},
	{
		"SuburbName" : "Tokomaru Bay",
		"Postcode" : "4081"
	},
	{
		"SuburbName" : "Waipiro Bay",
		"Postcode" : "4081"
	},
	{
		"SuburbName" : "Ruatoria",
		"Postcode" : "4082"
	},
	{
		"SuburbName" : "Tikitiki",
		"Postcode" : "4082"
	},
	{
		"SuburbName" : "Raukumara",
		"Postcode" : "4083"
	},
	{
		"SuburbName" : "Ruatoria",
		"Postcode" : "4083"
	},
	{
		"SuburbName" : "Tikitiki",
		"Postcode" : "4083"
	},
	{
		"SuburbName" : "Ruatoria",
		"Postcode" : "4086"
	},
	{
		"SuburbName" : "Tikitiki",
		"Postcode" : "4086"
	},
	{
		"SuburbName" : "East Cape",
		"Postcode" : "4087"
	},
	{
		"SuburbName" : "Hicks Bay",
		"Postcode" : "4087"
	},
	{
		"SuburbName" : "Te Araroa",
		"Postcode" : "4087"
	},
	{
		"SuburbName" : "Tikitiki",
		"Postcode" : "4087"
	},
	{
		"SuburbName" : "Te Karaka",
		"Postcode" : "4091"
	},
	{
		"SuburbName" : "Whatatutu",
		"Postcode" : "4091"
	},
	{
		"SuburbName" : "Matawai",
		"Postcode" : "4092"
	},
	{
		"SuburbName" : "Motu",
		"Postcode" : "4092"
	},
	{
		"SuburbName" : "Otoko",
		"Postcode" : "4092"
	},
	{
		"SuburbName" : "Rakauroa",
		"Postcode" : "4092"
	},
	{
		"SuburbName" : "Te Karaka",
		"Postcode" : "4092"
	},
	{
		"SuburbName" : "Te Karaka",
		"Postcode" : "4093"
	},
	{
		"SuburbName" : "Raukumara",
		"Postcode" : "4094"
	},
	{
		"SuburbName" : "Ruatoria",
		"Postcode" : "4094"
	},
	{
		"SuburbName" : "Te Karaka",
		"Postcode" : "4094"
	},
	{
		"SuburbName" : "Whatatutu",
		"Postcode" : "4094"
	},
	{
		"SuburbName" : "Bridge Pa",
		"Postcode" : "4171"
	},
	{
		"SuburbName" : "Hastings",
		"Postcode" : "4171"
	},
	{
		"SuburbName" : "Kereru",
		"Postcode" : "4171"
	},
	{
		"SuburbName" : "Maraekakaho",
		"Postcode" : "4171"
	},
	{
		"SuburbName" : "Raukawa",
		"Postcode" : "4171"
	},
	{
		"SuburbName" : "Roys Hill",
		"Postcode" : "4171"
	},
	{
		"SuburbName" : "Ruahine Forest Park",
		"Postcode" : "4171"
	},
	{
		"SuburbName" : "Tikokino",
		"Postcode" : "4171"
	},
	{
		"SuburbName" : "Akina",
		"Postcode" : "4172"
	},
	{
		"SuburbName" : "Clifton",
		"Postcode" : "4172"
	},
	{
		"SuburbName" : "Clive",
		"Postcode" : "4172"
	},
	{
		"SuburbName" : "Hastings",
		"Postcode" : "4172"
	},
	{
		"SuburbName" : "Haumoana",
		"Postcode" : "4172"
	},
	{
		"SuburbName" : "Havelock North",
		"Postcode" : "4172"
	},
	{
		"SuburbName" : "Karamu",
		"Postcode" : "4172"
	},
	{
		"SuburbName" : "Longlands",
		"Postcode" : "4172"
	},
	{
		"SuburbName" : "Mahora",
		"Postcode" : "4172"
	},
	{
		"SuburbName" : "Mangateretere",
		"Postcode" : "4172"
	},
	{
		"SuburbName" : "Pakipaki",
		"Postcode" : "4172"
	},
	{
		"SuburbName" : "Parkvale",
		"Postcode" : "4172"
	},
	{
		"SuburbName" : "Poukawa",
		"Postcode" : "4172"
	},
	{
		"SuburbName" : "Pukahu",
		"Postcode" : "4172"
	},
	{
		"SuburbName" : "Te Awanga",
		"Postcode" : "4172"
	},
	{
		"SuburbName" : "Tomoana",
		"Postcode" : "4172"
	},
	{
		"SuburbName" : "Tuki Tuki",
		"Postcode" : "4172"
	},
	{
		"SuburbName" : "Waipatu",
		"Postcode" : "4172"
	},
	{
		"SuburbName" : "Whakatu",
		"Postcode" : "4172"
	},
	{
		"SuburbName" : "Bridge Pa",
		"Postcode" : "4174"
	},
	{
		"SuburbName" : "Hastings",
		"Postcode" : "4174"
	},
	{
		"SuburbName" : "Longlands",
		"Postcode" : "4174"
	},
	{
		"SuburbName" : "Maraekakaho",
		"Postcode" : "4174"
	},
	{
		"SuburbName" : "Otane",
		"Postcode" : "4174"
	},
	{
		"SuburbName" : "Raukawa",
		"Postcode" : "4174"
	},
	{
		"SuburbName" : "Te Hauke",
		"Postcode" : "4174"
	},
	{
		"SuburbName" : "Te Onepu",
		"Postcode" : "4174"
	},
	{
		"SuburbName" : "Bridge Pa",
		"Postcode" : "4175"
	},
	{
		"SuburbName" : "Fernhill",
		"Postcode" : "4175"
	},
	{
		"SuburbName" : "Flaxmere",
		"Postcode" : "4175"
	},
	{
		"SuburbName" : "Hastings",
		"Postcode" : "4175"
	},
	{
		"SuburbName" : "Longlands",
		"Postcode" : "4175"
	},
	{
		"SuburbName" : "Napier",
		"Postcode" : "4175"
	},
	{
		"SuburbName" : "Omahu",
		"Postcode" : "4175"
	},
	{
		"SuburbName" : "Roys Hill",
		"Postcode" : "4175"
	},
	{
		"SuburbName" : "Tomoana",
		"Postcode" : "4175"
	},
	{
		"SuburbName" : "Twyford",
		"Postcode" : "4175"
	},
	{
		"SuburbName" : "Woolwich",
		"Postcode" : "4175"
	},
	{
		"SuburbName" : "Hastings",
		"Postcode" : "4178"
	},
	{
		"SuburbName" : "Longlands",
		"Postcode" : "4178"
	},
	{
		"SuburbName" : "Otane",
		"Postcode" : "4178"
	},
	{
		"SuburbName" : "Pakipaki",
		"Postcode" : "4178"
	},
	{
		"SuburbName" : "Poukawa",
		"Postcode" : "4178"
	},
	{
		"SuburbName" : "Raukawa",
		"Postcode" : "4178"
	},
	{
		"SuburbName" : "Te Hauke",
		"Postcode" : "4178"
	},
	{
		"SuburbName" : "Crownthorpe",
		"Postcode" : "4179"
	},
	{
		"SuburbName" : "Hastings",
		"Postcode" : "4179"
	},
	{
		"SuburbName" : "Kaweka Forest Park",
		"Postcode" : "4179"
	},
	{
		"SuburbName" : "Ngamahanga",
		"Postcode" : "4179"
	},
	{
		"SuburbName" : "Omahu",
		"Postcode" : "4179"
	},
	{
		"SuburbName" : "Otamauri",
		"Postcode" : "4179"
	},
	{
		"SuburbName" : "Pukehamoamoa",
		"Postcode" : "4179"
	},
	{
		"SuburbName" : "Sherenden",
		"Postcode" : "4179"
	},
	{
		"SuburbName" : "Taihape",
		"Postcode" : "4179"
	},
	{
		"SuburbName" : "Waiwhare",
		"Postcode" : "4179"
	},
	{
		"SuburbName" : "Whanawhana",
		"Postcode" : "4179"
	},
	{
		"SuburbName" : "Clifton",
		"Postcode" : "4180"
	},
	{
		"SuburbName" : "Clive",
		"Postcode" : "4180"
	},
	{
		"SuburbName" : "Hastings",
		"Postcode" : "4180"
	},
	{
		"SuburbName" : "Haumoana",
		"Postcode" : "4180"
	},
	{
		"SuburbName" : "Havelock North",
		"Postcode" : "4180"
	},
	{
		"SuburbName" : "Mangateretere",
		"Postcode" : "4180"
	},
	{
		"SuburbName" : "Te Awanga",
		"Postcode" : "4180"
	},
	{
		"SuburbName" : "Tomoana",
		"Postcode" : "4180"
	},
	{
		"SuburbName" : "Tuki Tuki",
		"Postcode" : "4180"
	},
	{
		"SuburbName" : "Waipatu",
		"Postcode" : "4180"
	},
	{
		"SuburbName" : "Whakatu",
		"Postcode" : "4180"
	},
	{
		"SuburbName" : "Eskdale",
		"Postcode" : "4181"
	},
	{
		"SuburbName" : "Kotemaori",
		"Postcode" : "4181"
	},
	{
		"SuburbName" : "Napier",
		"Postcode" : "4181"
	},
	{
		"SuburbName" : "Putorino",
		"Postcode" : "4181"
	},
	{
		"SuburbName" : "Tangoio",
		"Postcode" : "4181"
	},
	{
		"SuburbName" : "Tutira",
		"Postcode" : "4181"
	},
	{
		"SuburbName" : "Bay View",
		"Postcode" : "4182"
	},
	{
		"SuburbName" : "Eskdale",
		"Postcode" : "4182"
	},
	{
		"SuburbName" : "Glengarry",
		"Postcode" : "4182"
	},
	{
		"SuburbName" : "Napier",
		"Postcode" : "4182"
	},
	{
		"SuburbName" : "Orotu Park",
		"Postcode" : "4182"
	},
	{
		"SuburbName" : "Poraiti",
		"Postcode" : "4182"
	},
	{
		"SuburbName" : "Puketapu",
		"Postcode" : "4182"
	},
	{
		"SuburbName" : "Tangoio",
		"Postcode" : "4182"
	},
	{
		"SuburbName" : "Tarawera",
		"Postcode" : "4182"
	},
	{
		"SuburbName" : "Te Haroto",
		"Postcode" : "4182"
	},
	{
		"SuburbName" : "Te Pohue",
		"Postcode" : "4182"
	},
	{
		"SuburbName" : "Awatoto",
		"Postcode" : "4183"
	},
	{
		"SuburbName" : "Fernhill",
		"Postcode" : "4183"
	},
	{
		"SuburbName" : "Meeanee",
		"Postcode" : "4183"
	},
	{
		"SuburbName" : "Napier",
		"Postcode" : "4183"
	},
	{
		"SuburbName" : "Pakowhai",
		"Postcode" : "4183"
	},
	{
		"SuburbName" : "Puketapu",
		"Postcode" : "4183"
	},
	{
		"SuburbName" : "Taradale",
		"Postcode" : "4183"
	},
	{
		"SuburbName" : "Te Awa",
		"Postcode" : "4183"
	},
	{
		"SuburbName" : "Waiohiki",
		"Postcode" : "4183"
	},
	{
		"SuburbName" : "Kaweka Forest Park",
		"Postcode" : "4184"
	},
	{
		"SuburbName" : "Napier",
		"Postcode" : "4184"
	},
	{
		"SuburbName" : "Patoka",
		"Postcode" : "4184"
	},
	{
		"SuburbName" : "Puketapu",
		"Postcode" : "4184"
	},
	{
		"SuburbName" : "Puketitiri",
		"Postcode" : "4184"
	},
	{
		"SuburbName" : "Rissington",
		"Postcode" : "4184"
	},
	{
		"SuburbName" : "Dartmoor",
		"Postcode" : "4186"
	},
	{
		"SuburbName" : "Hastings",
		"Postcode" : "4186"
	},
	{
		"SuburbName" : "Napier",
		"Postcode" : "4186"
	},
	{
		"SuburbName" : "Patoka",
		"Postcode" : "4186"
	},
	{
		"SuburbName" : "Puketapu",
		"Postcode" : "4186"
	},
	{
		"SuburbName" : "Waiwhare",
		"Postcode" : "4186"
	},
	{
		"SuburbName" : "Kotemaori",
		"Postcode" : "4188"
	},
	{
		"SuburbName" : "Mohaka",
		"Postcode" : "4188"
	},
	{
		"SuburbName" : "Putere",
		"Postcode" : "4188"
	},
	{
		"SuburbName" : "Putorino",
		"Postcode" : "4188"
	},
	{
		"SuburbName" : "Raupunga",
		"Postcode" : "4188"
	},
	{
		"SuburbName" : "Raupunga",
		"Postcode" : "4188"
	},
	{
		"SuburbName" : "Wairoa",
		"Postcode" : "4188"
	},
	{
		"SuburbName" : "Kotemaori",
		"Postcode" : "4189"
	},
	{
		"SuburbName" : "Mohaka",
		"Postcode" : "4189"
	},
	{
		"SuburbName" : "Putere",
		"Postcode" : "4189"
	},
	{
		"SuburbName" : "Raupunga",
		"Postcode" : "4189"
	},
	{
		"SuburbName" : "Tuai",
		"Postcode" : "4189"
	},
	{
		"SuburbName" : "Wairoa",
		"Postcode" : "4189"
	},
	{
		"SuburbName" : "Frasertown",
		"Postcode" : "4191"
	},
	{
		"SuburbName" : "Putere",
		"Postcode" : "4191"
	},
	{
		"SuburbName" : "Raupunga",
		"Postcode" : "4191"
	},
	{
		"SuburbName" : "Wairoa",
		"Postcode" : "4191"
	},
	{
		"SuburbName" : "Frasertown",
		"Postcode" : "4193"
	},
	{
		"SuburbName" : "Wairoa",
		"Postcode" : "4193"
	},
	{
		"SuburbName" : "Frasertown",
		"Postcode" : "4195"
	},
	{
		"SuburbName" : "Ruatahuna",
		"Postcode" : "4195"
	},
	{
		"SuburbName" : "Murupara",
		"Postcode" : "4195"
	},
	{
		"SuburbName" : "Ohuka",
		"Postcode" : "4195"
	},
	{
		"SuburbName" : "Ruakituri",
		"Postcode" : "4195"
	},
	{
		"SuburbName" : "Ruatahuna",
		"Postcode" : "4195"
	},
	{
		"SuburbName" : "Tuai",
		"Postcode" : "4195"
	},
	{
		"SuburbName" : "Urewera National Park",
		"Postcode" : "4195"
	},
	{
		"SuburbName" : "Wairoa",
		"Postcode" : "4195"
	},
	{
		"SuburbName" : "Frasertown",
		"Postcode" : "4196"
	},
	{
		"SuburbName" : "Ruakituri",
		"Postcode" : "4196"
	},
	{
		"SuburbName" : "Wairoa",
		"Postcode" : "4196"
	},
	{
		"SuburbName" : "Whakaki",
		"Postcode" : "4196"
	},
	{
		"SuburbName" : "Mohaka",
		"Postcode" : "4197"
	},
	{
		"SuburbName" : "Raupunga",
		"Postcode" : "4197"
	},
	{
		"SuburbName" : "Wairoa",
		"Postcode" : "4197"
	},
	{
		"SuburbName" : "Mahia",
		"Postcode" : "4198"
	},
	{
		"SuburbName" : "Nuhaka",
		"Postcode" : "4198"
	},
	{
		"SuburbName" : "Whakaki",
		"Postcode" : "4198"
	},
	{
		"SuburbName" : "Aramoana",
		"Postcode" : "4271"
	},
	{
		"SuburbName" : "Omakere",
		"Postcode" : "4271"
	},
	{
		"SuburbName" : "Otane",
		"Postcode" : "4271"
	},
	{
		"SuburbName" : "Pourerere",
		"Postcode" : "4271"
	},
	{
		"SuburbName" : "Waipawa",
		"Postcode" : "4271"
	},
	{
		"SuburbName" : "Ongaonga",
		"Postcode" : "4272"
	},
	{
		"SuburbName" : "Waipawa",
		"Postcode" : "4272"
	},
	{
		"SuburbName" : "Argyll",
		"Postcode" : "4273"
	},
	{
		"SuburbName" : "Otane",
		"Postcode" : "4273"
	},
	{
		"SuburbName" : "Tikokino",
		"Postcode" : "4273"
	},
	{
		"SuburbName" : "Waipawa",
		"Postcode" : "4273"
	},
	{
		"SuburbName" : "Tikokino",
		"Postcode" : "4274"
	},
	{
		"SuburbName" : "Waipawa",
		"Postcode" : "4274"
	},
	{
		"SuburbName" : "Waipawa",
		"Postcode" : "4275"
	},
	{
		"SuburbName" : "Waipukurau",
		"Postcode" : "4275"
	},
	{
		"SuburbName" : "Argyll",
		"Postcode" : "4276"
	},
	{
		"SuburbName" : "Otane",
		"Postcode" : "4276"
	},
	{
		"SuburbName" : "Te Hauke",
		"Postcode" : "4276"
	},
	{
		"SuburbName" : "Waipawa",
		"Postcode" : "4276"
	},
	{
		"SuburbName" : "Elsthorpe",
		"Postcode" : "4277"
	},
	{
		"SuburbName" : "Otane",
		"Postcode" : "4277"
	},
	{
		"SuburbName" : "Waipawa",
		"Postcode" : "4277"
	},
	{
		"SuburbName" : "Ongaonga",
		"Postcode" : "4278"
	},
	{
		"SuburbName" : "Ongaonga",
		"Postcode" : "4279"
	},
	{
		"SuburbName" : "Tikokino",
		"Postcode" : "4279"
	},
	{
		"SuburbName" : "Hatuma",
		"Postcode" : "4281"
	},
	{
		"SuburbName" : "Takapau",
		"Postcode" : "4281"
	},
	{
		"SuburbName" : "Waipukurau",
		"Postcode" : "4281"
	},
	{
		"SuburbName" : "Flemington",
		"Postcode" : "4282"
	},
	{
		"SuburbName" : "Hatuma",
		"Postcode" : "4282"
	},
	{
		"SuburbName" : "Waipukurau",
		"Postcode" : "4282"
	},
	{
		"SuburbName" : "Wallingford",
		"Postcode" : "4282"
	},
	{
		"SuburbName" : "Wanstead",
		"Postcode" : "4282"
	},
	{
		"SuburbName" : "Ashley Clinton",
		"Postcode" : "4283"
	},
	{
		"SuburbName" : "Ongaonga",
		"Postcode" : "4283"
	},
	{
		"SuburbName" : "Takapau",
		"Postcode" : "4283"
	},
	{
		"SuburbName" : "Waipawa",
		"Postcode" : "4283"
	},
	{
		"SuburbName" : "Waipukurau",
		"Postcode" : "4283"
	},
	{
		"SuburbName" : "Blackhead",
		"Postcode" : "4284"
	},
	{
		"SuburbName" : "Omakere",
		"Postcode" : "4284"
	},
	{
		"SuburbName" : "Oueroa",
		"Postcode" : "4284"
	},
	{
		"SuburbName" : "Porangahau",
		"Postcode" : "4284"
	},
	{
		"SuburbName" : "Waipukurau",
		"Postcode" : "4284"
	},
	{
		"SuburbName" : "Wallingford",
		"Postcode" : "4284"
	},
	{
		"SuburbName" : "Wanstead",
		"Postcode" : "4284"
	},
	{
		"SuburbName" : "Waipukurau",
		"Postcode" : "4285"
	},
	{
		"SuburbName" : "Ashley Clinton",
		"Postcode" : "4286"
	},
	{
		"SuburbName" : "Ongaonga",
		"Postcode" : "4286"
	},
	{
		"SuburbName" : "Takapau",
		"Postcode" : "4286"
	},
	{
		"SuburbName" : "Ashley Clinton",
		"Postcode" : "4287"
	},
	{
		"SuburbName" : "Ormondville",
		"Postcode" : "4287"
	},
	{
		"SuburbName" : "Takapau",
		"Postcode" : "4287"
	},
	{
		"SuburbName" : "Ashley Clinton",
		"Postcode" : "4288"
	},
	{
		"SuburbName" : "Takapau",
		"Postcode" : "4288"
	},
	{
		"SuburbName" : "Mangaorapa",
		"Postcode" : "4291"
	},
	{
		"SuburbName" : "Porangahau",
		"Postcode" : "4291"
	},
	{
		"SuburbName" : "Porangahau",
		"Postcode" : "4292"
	},
	{
		"SuburbName" : "Porangahau",
		"Postcode" : "4293"
	},
	{
		"SuburbName" : "Havelock North",
		"Postcode" : "4294"
	},
	{
		"SuburbName" : "Kahuranaki",
		"Postcode" : "4294"
	},
	{
		"SuburbName" : "Maraetotara",
		"Postcode" : "4294"
	},
	{
		"SuburbName" : "Ocean Beach",
		"Postcode" : "4294"
	},
	{
		"SuburbName" : "Tuki Tuki",
		"Postcode" : "4294"
	},
	{
		"SuburbName" : "Waimarama",
		"Postcode" : "4294"
	},
	{
		"SuburbName" : "Elsthorpe",
		"Postcode" : "4295"
	},
	{
		"SuburbName" : "Havelock North",
		"Postcode" : "4295"
	},
	{
		"SuburbName" : "Kahuranaki",
		"Postcode" : "4295"
	},
	{
		"SuburbName" : "Kairakau",
		"Postcode" : "4295"
	},
	{
		"SuburbName" : "Burgess Park",
		"Postcode" : "4371"
	},
	{
		"SuburbName" : "Egmont Village",
		"Postcode" : "4371"
	},
	{
		"SuburbName" : "Ferndale",
		"Postcode" : "4371"
	},
	{
		"SuburbName" : "Frankleigh Park",
		"Postcode" : "4371"
	},
	{
		"SuburbName" : "Highlands Park",
		"Postcode" : "4371"
	},
	{
		"SuburbName" : "Hurdon",
		"Postcode" : "4371"
	},
	{
		"SuburbName" : "Hurworth",
		"Postcode" : "4371"
	},
	{
		"SuburbName" : "Kaimiro",
		"Postcode" : "4371"
	},
	{
		"SuburbName" : "Korito",
		"Postcode" : "4371"
	},
	{
		"SuburbName" : "Koru",
		"Postcode" : "4371"
	},
	{
		"SuburbName" : "Mangorei",
		"Postcode" : "4371"
	},
	{
		"SuburbName" : "New Plymouth",
		"Postcode" : "4371"
	},
	{
		"SuburbName" : "Ridgewood",
		"Postcode" : "4371"
	},
	{
		"SuburbName" : "Upper Vogeltown",
		"Postcode" : "4371"
	},
	{
		"SuburbName" : "Welbourn",
		"Postcode" : "4371"
	},
	{
		"SuburbName" : "Bell Block",
		"Postcode" : "4372"
	},
	{
		"SuburbName" : "Egmont Village",
		"Postcode" : "4372"
	},
	{
		"SuburbName" : "Glen Avon",
		"Postcode" : "4372"
	},
	{
		"SuburbName" : "Hillsborough",
		"Postcode" : "4372"
	},
	{
		"SuburbName" : "Inglewood",
		"Postcode" : "4372"
	},
	{
		"SuburbName" : "Lepperton",
		"Postcode" : "4372"
	},
	{
		"SuburbName" : "New Plymouth",
		"Postcode" : "4372"
	},
	{
		"SuburbName" : "Paraite",
		"Postcode" : "4372"
	},
	{
		"SuburbName" : "Tarurutangi",
		"Postcode" : "4372"
	},
	{
		"SuburbName" : "Waiwhakaiho",
		"Postcode" : "4372"
	},
	{
		"SuburbName" : "Bell Block",
		"Postcode" : "4373"
	},
	{
		"SuburbName" : "Brixton",
		"Postcode" : "4373"
	},
	{
		"SuburbName" : "Everett Park",
		"Postcode" : "4373"
	},
	{
		"SuburbName" : "Huirangi",
		"Postcode" : "4373"
	},
	{
		"SuburbName" : "Inglewood",
		"Postcode" : "4373"
	},
	{
		"SuburbName" : "Lepperton",
		"Postcode" : "4373"
	},
	{
		"SuburbName" : "New Plymouth",
		"Postcode" : "4373"
	},
	{
		"SuburbName" : "New Plymouth Airport",
		"Postcode" : "4373"
	},
	{
		"SuburbName" : "Paraite",
		"Postcode" : "4373"
	},
	{
		"SuburbName" : "Sentry Hill",
		"Postcode" : "4373"
	},
	{
		"SuburbName" : "Waiongana",
		"Postcode" : "4373"
	},
	{
		"SuburbName" : "Waitara",
		"Postcode" : "4373"
	},
	{
		"SuburbName" : "Hurdon",
		"Postcode" : "4374"
	},
	{
		"SuburbName" : "Hurford",
		"Postcode" : "4374"
	},
	{
		"SuburbName" : "Kaitake",
		"Postcode" : "4374"
	},
	{
		"SuburbName" : "Koru",
		"Postcode" : "4374"
	},
	{
		"SuburbName" : "New Plymouth",
		"Postcode" : "4374"
	},
	{
		"SuburbName" : "Oakura",
		"Postcode" : "4374"
	},
	{
		"SuburbName" : "Okato",
		"Postcode" : "4374"
	},
	{
		"SuburbName" : "Omata",
		"Postcode" : "4374"
	},
	{
		"SuburbName" : "Pitone",
		"Postcode" : "4374"
	},
	{
		"SuburbName" : "Pukeiti",
		"Postcode" : "4374"
	},
	{
		"SuburbName" : "Spotswood",
		"Postcode" : "4374"
	},
	{
		"SuburbName" : "Tataraimaka",
		"Postcode" : "4374"
	},
	{
		"SuburbName" : "Whalers Gate",
		"Postcode" : "4374"
	},
	{
		"SuburbName" : "Okoki",
		"Postcode" : "4375"
	},
	{
		"SuburbName" : "Onaero",
		"Postcode" : "4375"
	},
	{
		"SuburbName" : "Urenui",
		"Postcode" : "4375"
	},
	{
		"SuburbName" : "Awakino",
		"Postcode" : "4376"
	},
	{
		"SuburbName" : "Mohakatino",
		"Postcode" : "4376"
	},
	{
		"SuburbName" : "Mokau",
		"Postcode" : "4376"
	},
	{
		"SuburbName" : "Tongaporutu",
		"Postcode" : "4376"
	},
	{
		"SuburbName" : "Urenui",
		"Postcode" : "4376"
	},
	{
		"SuburbName" : "Mimi",
		"Postcode" : "4377"
	},
	{
		"SuburbName" : "Urenui",
		"Postcode" : "4377"
	},
	{
		"SuburbName" : "Waiiti",
		"Postcode" : "4377"
	},
	{
		"SuburbName" : "Ahititi",
		"Postcode" : "4378"
	},
	{
		"SuburbName" : "Mount Messenger",
		"Postcode" : "4378"
	},
	{
		"SuburbName" : "Mount Messenger",
		"Postcode" : "4378"
	},
	{
		"SuburbName" : "Okoki",
		"Postcode" : "4378"
	},
	{
		"SuburbName" : "Tongaporutu",
		"Postcode" : "4378"
	},
	{
		"SuburbName" : "Urenui",
		"Postcode" : "4378"
	},
	{
		"SuburbName" : "Uruti",
		"Postcode" : "4378"
	},
	{
		"SuburbName" : "Mimi",
		"Postcode" : "4379"
	},
	{
		"SuburbName" : "Mount Messenger",
		"Postcode" : "4379"
	},
	{
		"SuburbName" : "Mount Messenger",
		"Postcode" : "4379"
	},
	{
		"SuburbName" : "Urenui",
		"Postcode" : "4379"
	},
	{
		"SuburbName" : "Uruti",
		"Postcode" : "4379"
	},
	{
		"SuburbName" : "New Plymouth",
		"Postcode" : "4381"
	},
	{
		"SuburbName" : "Newall",
		"Postcode" : "4381"
	},
	{
		"SuburbName" : "Okato",
		"Postcode" : "4381"
	},
	{
		"SuburbName" : "Pungarehu",
		"Postcode" : "4381"
	},
	{
		"SuburbName" : "Warea",
		"Postcode" : "4381"
	},
	{
		"SuburbName" : "Brixton",
		"Postcode" : "4382"
	},
	{
		"SuburbName" : "Huirangi",
		"Postcode" : "4382"
	},
	{
		"SuburbName" : "New Plymouth",
		"Postcode" : "4382"
	},
	{
		"SuburbName" : "Sentry Hill",
		"Postcode" : "4382"
	},
	{
		"SuburbName" : "Waitara",
		"Postcode" : "4382"
	},
	{
		"SuburbName" : "Huirangi",
		"Postcode" : "4383"
	},
	{
		"SuburbName" : "Motunui",
		"Postcode" : "4383"
	},
	{
		"SuburbName" : "New Plymouth",
		"Postcode" : "4383"
	},
	{
		"SuburbName" : "Onaero",
		"Postcode" : "4383"
	},
	{
		"SuburbName" : "Tarata",
		"Postcode" : "4383"
	},
	{
		"SuburbName" : "Tikorangi",
		"Postcode" : "4383"
	},
	{
		"SuburbName" : "Urenui",
		"Postcode" : "4383"
	},
	{
		"SuburbName" : "Waitara",
		"Postcode" : "4383"
	},
	{
		"SuburbName" : "Egmont National Park",
		"Postcode" : "4386"
	},
	{
		"SuburbName" : "Egmont Village",
		"Postcode" : "4386"
	},
	{
		"SuburbName" : "Inglewood",
		"Postcode" : "4386"
	},
	{
		"SuburbName" : "Kaimiro",
		"Postcode" : "4386"
	},
	{
		"SuburbName" : "Norfolk",
		"Postcode" : "4386"
	},
	{
		"SuburbName" : "Inglewood",
		"Postcode" : "4387"
	},
	{
		"SuburbName" : "Kaimata",
		"Postcode" : "4387"
	},
	{
		"SuburbName" : "Kaimiro",
		"Postcode" : "4387"
	},
	{
		"SuburbName" : "Matau",
		"Postcode" : "4387"
	},
	{
		"SuburbName" : "Norfolk",
		"Postcode" : "4387"
	},
	{
		"SuburbName" : "Purangi",
		"Postcode" : "4387"
	},
	{
		"SuburbName" : "Stratford",
		"Postcode" : "4387"
	},
	{
		"SuburbName" : "Tarata",
		"Postcode" : "4387"
	},
	{
		"SuburbName" : "Everett Park",
		"Postcode" : "4388"
	},
	{
		"SuburbName" : "Inglewood",
		"Postcode" : "4388"
	},
	{
		"SuburbName" : "Kaimata",
		"Postcode" : "4388"
	},
	{
		"SuburbName" : "Norfolk",
		"Postcode" : "4388"
	},
	{
		"SuburbName" : "Stratford",
		"Postcode" : "4388"
	},
	{
		"SuburbName" : "Tariki",
		"Postcode" : "4388"
	},
	{
		"SuburbName" : "Waitui",
		"Postcode" : "4388"
	},
	{
		"SuburbName" : "Everett Park",
		"Postcode" : "4389"
	},
	{
		"SuburbName" : "Huirangi",
		"Postcode" : "4389"
	},
	{
		"SuburbName" : "Inglewood",
		"Postcode" : "4389"
	},
	{
		"SuburbName" : "Lepperton",
		"Postcode" : "4389"
	},
	{
		"SuburbName" : "New Plymouth",
		"Postcode" : "4389"
	},
	{
		"SuburbName" : "Tarurutangi",
		"Postcode" : "4389"
	},
	{
		"SuburbName" : "Waiongana",
		"Postcode" : "4389"
	},
	{
		"SuburbName" : "Inglewood",
		"Postcode" : "4390"
	},
	{
		"SuburbName" : "Kaimata",
		"Postcode" : "4390"
	},
	{
		"SuburbName" : "Ratapiko",
		"Postcode" : "4390"
	},
	{
		"SuburbName" : "Stratford",
		"Postcode" : "4390"
	},
	{
		"SuburbName" : "Tariki",
		"Postcode" : "4390"
	},
	{
		"SuburbName" : "Te Popo",
		"Postcode" : "4390"
	},
	{
		"SuburbName" : "Cardiff",
		"Postcode" : "4391"
	},
	{
		"SuburbName" : "Egmont National Park",
		"Postcode" : "4391"
	},
	{
		"SuburbName" : "Eltham",
		"Postcode" : "4391"
	},
	{
		"SuburbName" : "Hawera",
		"Postcode" : "4391"
	},
	{
		"SuburbName" : "Kaponga",
		"Postcode" : "4391"
	},
	{
		"SuburbName" : "Mahoe",
		"Postcode" : "4391"
	},
	{
		"SuburbName" : "Mangatoki",
		"Postcode" : "4391"
	},
	{
		"SuburbName" : "Ngaere",
		"Postcode" : "4391"
	},
	{
		"SuburbName" : "Pembroke",
		"Postcode" : "4391"
	},
	{
		"SuburbName" : "Stratford",
		"Postcode" : "4391"
	},
	{
		"SuburbName" : "Aotuhia",
		"Postcode" : "4392"
	},
	{
		"SuburbName" : "Douglas",
		"Postcode" : "4392"
	},
	{
		"SuburbName" : "Huinga",
		"Postcode" : "4392"
	},
	{
		"SuburbName" : "Huiroa",
		"Postcode" : "4392"
	},
	{
		"SuburbName" : "Makahu",
		"Postcode" : "4392"
	},
	{
		"SuburbName" : "Puniwhakau",
		"Postcode" : "4392"
	},
	{
		"SuburbName" : "Stratford",
		"Postcode" : "4392"
	},
	{
		"SuburbName" : "Strathmore",
		"Postcode" : "4392"
	},
	{
		"SuburbName" : "Te Popo",
		"Postcode" : "4392"
	},
	{
		"SuburbName" : "Te Wera",
		"Postcode" : "4392"
	},
	{
		"SuburbName" : "Toko",
		"Postcode" : "4392"
	},
	{
		"SuburbName" : "Tututawa",
		"Postcode" : "4392"
	},
	{
		"SuburbName" : "Bird Road",
		"Postcode" : "4393"
	},
	{
		"SuburbName" : "Cardiff",
		"Postcode" : "4393"
	},
	{
		"SuburbName" : "Eltham",
		"Postcode" : "4393"
	},
	{
		"SuburbName" : "Mangamingi",
		"Postcode" : "4393"
	},
	{
		"SuburbName" : "Ngaere",
		"Postcode" : "4393"
	},
	{
		"SuburbName" : "Pukengahu",
		"Postcode" : "4393"
	},
	{
		"SuburbName" : "Stratford",
		"Postcode" : "4393"
	},
	{
		"SuburbName" : "Midhirst",
		"Postcode" : "4394"
	},
	{
		"SuburbName" : "Pembroke",
		"Postcode" : "4394"
	},
	{
		"SuburbName" : "Stratford",
		"Postcode" : "4394"
	},
	{
		"SuburbName" : "Tariki",
		"Postcode" : "4394"
	},
	{
		"SuburbName" : "Te Popo",
		"Postcode" : "4394"
	},
	{
		"SuburbName" : "Toko",
		"Postcode" : "4394"
	},
	{
		"SuburbName" : "Huiroa",
		"Postcode" : "4395"
	},
	{
		"SuburbName" : "Kiore",
		"Postcode" : "4395"
	},
	{
		"SuburbName" : "Matau",
		"Postcode" : "4395"
	},
	{
		"SuburbName" : "Pohokura",
		"Postcode" : "4395"
	},
	{
		"SuburbName" : "Stratford",
		"Postcode" : "4395"
	},
	{
		"SuburbName" : "Te Wera",
		"Postcode" : "4395"
	},
	{
		"SuburbName" : "Kohuratahi",
		"Postcode" : "4396"
	},
	{
		"SuburbName" : "Pohokura",
		"Postcode" : "4396"
	},
	{
		"SuburbName" : "Stratford",
		"Postcode" : "4396"
	},
	{
		"SuburbName" : "Tahora",
		"Postcode" : "4396"
	},
	{
		"SuburbName" : "Tangarakau",
		"Postcode" : "4396"
	},
	{
		"SuburbName" : "Whangamomona",
		"Postcode" : "4396"
	},
	{
		"SuburbName" : "Ahititi",
		"Postcode" : "4397"
	},
	{
		"SuburbName" : "Stratford",
		"Postcode" : "4397"
	},
	{
		"SuburbName" : "Tahora",
		"Postcode" : "4397"
	},
	{
		"SuburbName" : "Tangarakau",
		"Postcode" : "4397"
	},
	{
		"SuburbName" : "Urenui",
		"Postcode" : "4397"
	},
	{
		"SuburbName" : "Eltham",
		"Postcode" : "4398"
	},
	{
		"SuburbName" : "Hawera",
		"Postcode" : "4398"
	},
	{
		"SuburbName" : "Maata",
		"Postcode" : "4398"
	},
	{
		"SuburbName" : "Normanby",
		"Postcode" : "4398"
	},
	{
		"SuburbName" : "Rawhitiroa",
		"Postcode" : "4398"
	},
	{
		"SuburbName" : "Ararata",
		"Postcode" : "4399"
	},
	{
		"SuburbName" : "Eltham",
		"Postcode" : "4399"
	},
	{
		"SuburbName" : "Hawera",
		"Postcode" : "4399"
	},
	{
		"SuburbName" : "Mangamingi",
		"Postcode" : "4399"
	},
	{
		"SuburbName" : "Matemateaonga",
		"Postcode" : "4399"
	},
	{
		"SuburbName" : "Rawhitiroa",
		"Postcode" : "4399"
	},
	{
		"SuburbName" : "Ashhurst",
		"Postcode" : "4470"
	},
	{
		"SuburbName" : "Bunnythorpe",
		"Postcode" : "4470"
	},
	{
		"SuburbName" : "Kelvin Grove",
		"Postcode" : "4470"
	},
	{
		"SuburbName" : "Milson",
		"Postcode" : "4470"
	},
	{
		"SuburbName" : "Palmerston North",
		"Postcode" : "4470"
	},
	{
		"SuburbName" : "Whakarongo",
		"Postcode" : "4470"
	},
	{
		"SuburbName" : "Aokautere",
		"Postcode" : "4471"
	},
	{
		"SuburbName" : "Fitzherbert",
		"Postcode" : "4471"
	},
	{
		"SuburbName" : "Palmerston North",
		"Postcode" : "4471"
	},
	{
		"SuburbName" : "Turitea",
		"Postcode" : "4471"
	},
	{
		"SuburbName" : "Fitzherbert",
		"Postcode" : "4472"
	},
	{
		"SuburbName" : "Linton",
		"Postcode" : "4472"
	},
	{
		"SuburbName" : "Linton",
		"Postcode" : "4472"
	},
	{
		"SuburbName" : "Palmerston North",
		"Postcode" : "4472"
	},
	{
		"SuburbName" : "Turitea",
		"Postcode" : "4472"
	},
	{
		"SuburbName" : "Glen Oroua",
		"Postcode" : "4473"
	},
	{
		"SuburbName" : "Glen Oroua",
		"Postcode" : "4473"
	},
	{
		"SuburbName" : "Rongotea",
		"Postcode" : "4473"
	},
	{
		"SuburbName" : "Tangimoana",
		"Postcode" : "4473"
	},
	{
		"SuburbName" : "Fitzherbert",
		"Postcode" : "4474"
	},
	{
		"SuburbName" : "Linton",
		"Postcode" : "4474"
	},
	{
		"SuburbName" : "Opiki",
		"Postcode" : "4474"
	},
	{
		"SuburbName" : "Palmerston North",
		"Postcode" : "4474"
	},
	{
		"SuburbName" : "Shannon",
		"Postcode" : "4474"
	},
	{
		"SuburbName" : "Tokomaru",
		"Postcode" : "4474"
	},
	{
		"SuburbName" : "Turitea",
		"Postcode" : "4474"
	},
	{
		"SuburbName" : "Cloverlea",
		"Postcode" : "4475"
	},
	{
		"SuburbName" : "Kairanga",
		"Postcode" : "4475"
	},
	{
		"SuburbName" : "Longburn",
		"Postcode" : "4475"
	},
	{
		"SuburbName" : "Milson",
		"Postcode" : "4475"
	},
	{
		"SuburbName" : "Newbury",
		"Postcode" : "4475"
	},
	{
		"SuburbName" : "Palmerston North",
		"Postcode" : "4475"
	},
	{
		"SuburbName" : "Tiakitahuna",
		"Postcode" : "4475"
	},
	{
		"SuburbName" : "Westbrook",
		"Postcode" : "4475"
	},
	{
		"SuburbName" : "Awahuri",
		"Postcode" : "4476"
	},
	{
		"SuburbName" : "Kairanga",
		"Postcode" : "4476"
	},
	{
		"SuburbName" : "Newbury",
		"Postcode" : "4476"
	},
	{
		"SuburbName" : "Palmerston North",
		"Postcode" : "4476"
	},
	{
		"SuburbName" : "Rongotea",
		"Postcode" : "4476"
	},
	{
		"SuburbName" : "Tangimoana",
		"Postcode" : "4476"
	},
	{
		"SuburbName" : "Awapuni",
		"Postcode" : "4477"
	},
	{
		"SuburbName" : "Bainesse",
		"Postcode" : "4477"
	},
	{
		"SuburbName" : "Foxton",
		"Postcode" : "4477"
	},
	{
		"SuburbName" : "Glen Oroua",
		"Postcode" : "4477"
	},
	{
		"SuburbName" : "Himatangi",
		"Postcode" : "4477"
	},
	{
		"SuburbName" : "Kairanga",
		"Postcode" : "4477"
	},
	{
		"SuburbName" : "Longburn",
		"Postcode" : "4477"
	},
	{
		"SuburbName" : "Palmerston North",
		"Postcode" : "4477"
	},
	{
		"SuburbName" : "Rangiotu",
		"Postcode" : "4477"
	},
	{
		"SuburbName" : "Tiakitahuna",
		"Postcode" : "4477"
	},
	{
		"SuburbName" : "Bunnythorpe",
		"Postcode" : "4478"
	},
	{
		"SuburbName" : "Milson",
		"Postcode" : "4478"
	},
	{
		"SuburbName" : "Newbury",
		"Postcode" : "4478"
	},
	{
		"SuburbName" : "Palmerston North",
		"Postcode" : "4478"
	},
	{
		"SuburbName" : "Awahuri",
		"Postcode" : "4479"
	},
	{
		"SuburbName" : "Bulls",
		"Postcode" : "4479"
	},
	{
		"SuburbName" : "Feilding",
		"Postcode" : "4479"
	},
	{
		"SuburbName" : "Halcombe",
		"Postcode" : "4479"
	},
	{
		"SuburbName" : "Ohakea",
		"Postcode" : "4479"
	},
	{
		"SuburbName" : "Palmerston North",
		"Postcode" : "4479"
	},
	{
		"SuburbName" : "Rongotea",
		"Postcode" : "4479"
	},
	{
		"SuburbName" : "Sanson",
		"Postcode" : "4479"
	},
	{
		"SuburbName" : "Tangimoana",
		"Postcode" : "4479"
	},
	{
		"SuburbName" : "Bunnythorpe",
		"Postcode" : "4481"
	},
	{
		"SuburbName" : "Palmerston North",
		"Postcode" : "4481"
	},
	{
		"SuburbName" : "Aramoho",
		"Postcode" : "4571"
	},
	{
		"SuburbName" : "Brunswick",
		"Postcode" : "4571"
	},
	{
		"SuburbName" : "Kai Iwi",
		"Postcode" : "4571"
	},
	{
		"SuburbName" : "Otamatea",
		"Postcode" : "4571"
	},
	{
		"SuburbName" : "Papaiti",
		"Postcode" : "4571"
	},
	{
		"SuburbName" : "Wanganui",
		"Postcode" : "4571"
	},
	{
		"SuburbName" : "Westmere",
		"Postcode" : "4571"
	},
	{
		"SuburbName" : "Whanganui",
		"Postcode" : "4571"
	},
	{
		"SuburbName" : "Durie Hill",
		"Postcode" : "4572"
	},
	{
		"SuburbName" : "Fordell",
		"Postcode" : "4572"
	},
	{
		"SuburbName" : "Kaitoke",
		"Postcode" : "4572"
	},
	{
		"SuburbName" : "Marybank",
		"Postcode" : "4572"
	},
	{
		"SuburbName" : "Okoia",
		"Postcode" : "4572"
	},
	{
		"SuburbName" : "Wanganui",
		"Postcode" : "4572"
	},
	{
		"SuburbName" : "Whangaehu",
		"Postcode" : "4572"
	},
	{
		"SuburbName" : "Whanganui",
		"Postcode" : "4572"
	},
	{
		"SuburbName" : "Kakatahi",
		"Postcode" : "4573"
	},
	{
		"SuburbName" : "Mangamahu",
		"Postcode" : "4573"
	},
	{
		"SuburbName" : "Matahiwi",
		"Postcode" : "4573"
	},
	{
		"SuburbName" : "Ohakune",
		"Postcode" : "4573"
	},
	{
		"SuburbName" : "Okoia",
		"Postcode" : "4573"
	},
	{
		"SuburbName" : "Parikino",
		"Postcode" : "4573"
	},
	{
		"SuburbName" : "Manawatu",
		"Postcode" : "4573"
	},
	{
		"SuburbName" : "Upokongaro",
		"Postcode" : "4573"
	},
	{
		"SuburbName" : "Waimarino",
		"Postcode" : "4573"
	},
	{
		"SuburbName" : "Wanganui",
		"Postcode" : "4573"
	},
	{
		"SuburbName" : "Wanganui",
		"Postcode" : "4573"
	},
	{
		"SuburbName" : "Whanganui East",
		"Postcode" : "4573"
	},
	{
		"SuburbName" : "Whanganui East",
		"Postcode" : "4573"
	},
	{
		"SuburbName" : "Brunswick",
		"Postcode" : "4574"
	},
	{
		"SuburbName" : "Kai Iwi",
		"Postcode" : "4574"
	},
	{
		"SuburbName" : "Maxwell",
		"Postcode" : "4574"
	},
	{
		"SuburbName" : "Wanganui",
		"Postcode" : "4574"
	},
	{
		"SuburbName" : "Westmere",
		"Postcode" : "4574"
	},
	{
		"SuburbName" : "Whanganui",
		"Postcode" : "4574"
	},
	{
		"SuburbName" : "Kaiwhaiki",
		"Postcode" : "4575"
	},
	{
		"SuburbName" : "Parikino",
		"Postcode" : "4575"
	},
	{
		"SuburbName" : "Upokongaro",
		"Postcode" : "4575"
	},
	{
		"SuburbName" : "Wanganui",
		"Postcode" : "4575"
	},
	{
		"SuburbName" : "Whanganui",
		"Postcode" : "4575"
	},
	{
		"SuburbName" : "Brunswick",
		"Postcode" : "4576"
	},
	{
		"SuburbName" : "Matahiwi",
		"Postcode" : "4576"
	},
	{
		"SuburbName" : "Parikino",
		"Postcode" : "4576"
	},
	{
		"SuburbName" : "Pipiriki",
		"Postcode" : "4576"
	},
	{
		"SuburbName" : "Ranana",
		"Postcode" : "4576"
	},
	{
		"SuburbName" : "Taunoka",
		"Postcode" : "4576"
	},
	{
		"SuburbName" : "Wanganui",
		"Postcode" : "4576"
	},
	{
		"SuburbName" : "Wanganui National Park",
		"Postcode" : "4576"
	},
	{
		"SuburbName" : "Whanganui",
		"Postcode" : "4576"
	},
	{
		"SuburbName" : "Whanganui National Park",
		"Postcode" : "4576"
	},
	{
		"SuburbName" : "Fordell",
		"Postcode" : "4577"
	},
	{
		"SuburbName" : "Mangamahu",
		"Postcode" : "4577"
	},
	{
		"SuburbName" : "Wanganui",
		"Postcode" : "4577"
	},
	{
		"SuburbName" : "Whanganui",
		"Postcode" : "4577"
	},
	{
		"SuburbName" : "Kai Iwi",
		"Postcode" : "4578"
	},
	{
		"SuburbName" : "Paparangi",
		"Postcode" : "4578"
	},
	{
		"SuburbName" : "Taunoka",
		"Postcode" : "4578"
	},
	{
		"SuburbName" : "Wanganui",
		"Postcode" : "4578"
	},
	{
		"SuburbName" : "Whanganui",
		"Postcode" : "4578"
	},
	{
		"SuburbName" : "Bulls",
		"Postcode" : "4581"
	},
	{
		"SuburbName" : "Fordell",
		"Postcode" : "4581"
	},
	{
		"SuburbName" : "Koitiata",
		"Postcode" : "4581"
	},
	{
		"SuburbName" : "Marton",
		"Postcode" : "4581"
	},
	{
		"SuburbName" : "Ratana",
		"Postcode" : "4581"
	},
	{
		"SuburbName" : "Santoft",
		"Postcode" : "4581"
	},
	{
		"SuburbName" : "Turakina",
		"Postcode" : "4581"
	},
	{
		"SuburbName" : "Wanganui",
		"Postcode" : "4581"
	},
	{
		"SuburbName" : "Whangaehu",
		"Postcode" : "4581"
	},
	{
		"SuburbName" : "Whanganui",
		"Postcode" : "4581"
	},
	{
		"SuburbName" : "Fordell",
		"Postcode" : "4582"
	},
	{
		"SuburbName" : "Okoia",
		"Postcode" : "4582"
	},
	{
		"SuburbName" : "Wanganui",
		"Postcode" : "4582"
	},
	{
		"SuburbName" : "Wanganui",
		"Postcode" : "4582"
	},
	{
		"SuburbName" : "Whanganui East",
		"Postcode" : "4582"
	},
	{
		"SuburbName" : "Whanganui East",
		"Postcode" : "4582"
	},
	{
		"SuburbName" : "Aramoho",
		"Postcode" : "4584"
	},
	{
		"SuburbName" : "Brunswick",
		"Postcode" : "4584"
	},
	{
		"SuburbName" : "Okoia",
		"Postcode" : "4584"
	},
	{
		"SuburbName" : "Papaiti",
		"Postcode" : "4584"
	},
	{
		"SuburbName" : "Paparangi",
		"Postcode" : "4584"
	},
	{
		"SuburbName" : "Wanganui",
		"Postcode" : "4584"
	},
	{
		"SuburbName" : "Whanganui",
		"Postcode" : "4584"
	},
	{
		"SuburbName" : "Kakatahi",
		"Postcode" : "4585"
	},
	{
		"SuburbName" : "Rangiwaea",
		"Postcode" : "4585"
	},
	{
		"SuburbName" : "Taihape",
		"Postcode" : "4585"
	},
	{
		"SuburbName" : "Wanganui",
		"Postcode" : "4585"
	},
	{
		"SuburbName" : "Whanganui",
		"Postcode" : "4585"
	},
	{
		"SuburbName" : "Hunterville",
		"Postcode" : "4586"
	},
	{
		"SuburbName" : "Kakatahi",
		"Postcode" : "4586"
	},
	{
		"SuburbName" : "Mangamahu",
		"Postcode" : "4586"
	},
	{
		"SuburbName" : "Papanui Junction",
		"Postcode" : "4586"
	},
	{
		"SuburbName" : "Wanganui",
		"Postcode" : "4586"
	},
	{
		"SuburbName" : "Whanganui",
		"Postcode" : "4586"
	},
	{
		"SuburbName" : "Maxwell",
		"Postcode" : "4587"
	},
	{
		"SuburbName" : "Waitotara",
		"Postcode" : "4587"
	},
	{
		"SuburbName" : "Wanganui",
		"Postcode" : "4587"
	},
	{
		"SuburbName" : "Whanganui",
		"Postcode" : "4587"
	},
	{
		"SuburbName" : "Ngamatapouri",
		"Postcode" : "4588"
	},
	{
		"SuburbName" : "Waiinu Beach",
		"Postcode" : "4588"
	},
	{
		"SuburbName" : "Waitotara",
		"Postcode" : "4588"
	},
	{
		"SuburbName" : "Wanganui",
		"Postcode" : "4588"
	},
	{
		"SuburbName" : "Whanganui",
		"Postcode" : "4588"
	},
	{
		"SuburbName" : "Patea",
		"Postcode" : "4591"
	},
	{
		"SuburbName" : "Waverley",
		"Postcode" : "4591"
	},
	{
		"SuburbName" : "Mangawhio",
		"Postcode" : "4592"
	},
	{
		"SuburbName" : "Waitotara",
		"Postcode" : "4592"
	},
	{
		"SuburbName" : "Wanganui",
		"Postcode" : "4592"
	},
	{
		"SuburbName" : "Waverley",
		"Postcode" : "4592"
	},
	{
		"SuburbName" : "Whanganui",
		"Postcode" : "4592"
	},
	{
		"SuburbName" : "Patea",
		"Postcode" : "4597"
	},
	{
		"SuburbName" : "Alton",
		"Postcode" : "4598"
	},
	{
		"SuburbName" : "Hurleyville",
		"Postcode" : "4598"
	},
	{
		"SuburbName" : "Kakaramea",
		"Postcode" : "4598"
	},
	{
		"SuburbName" : "Manutahi",
		"Postcode" : "4598"
	},
	{
		"SuburbName" : "Patea",
		"Postcode" : "4598"
	},
	{
		"SuburbName" : "Opunake",
		"Postcode" : "4649"
	},
	{
		"SuburbName" : "Rahotu",
		"Postcode" : "4649"
	},
	{
		"SuburbName" : "Hawera",
		"Postcode" : "4671"
	},
	{
		"SuburbName" : "Manaia",
		"Postcode" : "4671"
	},
	{
		"SuburbName" : "Normanby",
		"Postcode" : "4671"
	},
	{
		"SuburbName" : "Ohawe",
		"Postcode" : "4671"
	},
	{
		"SuburbName" : "Okaiawa",
		"Postcode" : "4671"
	},
	{
		"SuburbName" : "Tokaora",
		"Postcode" : "4671"
	},
	{
		"SuburbName" : "Hawera",
		"Postcode" : "4672"
	},
	{
		"SuburbName" : "Manutahi",
		"Postcode" : "4672"
	},
	{
		"SuburbName" : "Mokoia",
		"Postcode" : "4672"
	},
	{
		"SuburbName" : "Ohangai",
		"Postcode" : "4672"
	},
	{
		"SuburbName" : "Patea",
		"Postcode" : "4672"
	},
	{
		"SuburbName" : "Eltham",
		"Postcode" : "4673"
	},
	{
		"SuburbName" : "Matapu",
		"Postcode" : "4673"
	},
	{
		"SuburbName" : "Normanby",
		"Postcode" : "4673"
	},
	{
		"SuburbName" : "Te Roti",
		"Postcode" : "4673"
	},
	{
		"SuburbName" : "Ararata",
		"Postcode" : "4674"
	},
	{
		"SuburbName" : "Hawera",
		"Postcode" : "4674"
	},
	{
		"SuburbName" : "Normanby",
		"Postcode" : "4674"
	},
	{
		"SuburbName" : "Ohangai",
		"Postcode" : "4674"
	},
	{
		"SuburbName" : "Tokaora",
		"Postcode" : "4674"
	},
	{
		"SuburbName" : "Hawera",
		"Postcode" : "4675"
	},
	{
		"SuburbName" : "Kaponga",
		"Postcode" : "4675"
	},
	{
		"SuburbName" : "Kapuni",
		"Postcode" : "4675"
	},
	{
		"SuburbName" : "Mangatoki",
		"Postcode" : "4675"
	},
	{
		"SuburbName" : "Matapu",
		"Postcode" : "4675"
	},
	{
		"SuburbName" : "Normanby",
		"Postcode" : "4675"
	},
	{
		"SuburbName" : "Okaiawa",
		"Postcode" : "4675"
	},
	{
		"SuburbName" : "Stratford",
		"Postcode" : "4675"
	},
	{
		"SuburbName" : "Auroa",
		"Postcode" : "4678"
	},
	{
		"SuburbName" : "Awatuna",
		"Postcode" : "4678"
	},
	{
		"SuburbName" : "Hawera",
		"Postcode" : "4678"
	},
	{
		"SuburbName" : "Kapuni",
		"Postcode" : "4678"
	},
	{
		"SuburbName" : "Kaupokonui",
		"Postcode" : "4678"
	},
	{
		"SuburbName" : "Manaia",
		"Postcode" : "4678"
	},
	{
		"SuburbName" : "Okaiawa",
		"Postcode" : "4678"
	},
	{
		"SuburbName" : "Otakeho",
		"Postcode" : "4678"
	},
	{
		"SuburbName" : "Auroa",
		"Postcode" : "4679"
	},
	{
		"SuburbName" : "Awatuna",
		"Postcode" : "4679"
	},
	{
		"SuburbName" : "Hawera",
		"Postcode" : "4679"
	},
	{
		"SuburbName" : "Kaponga",
		"Postcode" : "4679"
	},
	{
		"SuburbName" : "Kapuni",
		"Postcode" : "4679"
	},
	{
		"SuburbName" : "Mahoe",
		"Postcode" : "4679"
	},
	{
		"SuburbName" : "Opunake",
		"Postcode" : "4679"
	},
	{
		"SuburbName" : "Riverlea",
		"Postcode" : "4679"
	},
	{
		"SuburbName" : "Stratford",
		"Postcode" : "4679"
	},
	{
		"SuburbName" : "Te Kiri",
		"Postcode" : "4679"
	},
	{
		"SuburbName" : "Oaonui",
		"Postcode" : "4681"
	},
	{
		"SuburbName" : "Opunake",
		"Postcode" : "4681"
	},
	{
		"SuburbName" : "Rahotu",
		"Postcode" : "4681"
	},
	{
		"SuburbName" : "Hawera",
		"Postcode" : "4682"
	},
	{
		"SuburbName" : "Opunake",
		"Postcode" : "4682"
	},
	{
		"SuburbName" : "Otakeho",
		"Postcode" : "4682"
	},
	{
		"SuburbName" : "Pihama",
		"Postcode" : "4682"
	},
	{
		"SuburbName" : "Te Kiri",
		"Postcode" : "4682"
	},
	{
		"SuburbName" : "Opunake",
		"Postcode" : "4684"
	},
	{
		"SuburbName" : "Rahotu",
		"Postcode" : "4684"
	},
	{
		"SuburbName" : "Opunake",
		"Postcode" : "4685"
	},
	{
		"SuburbName" : "Pungarehu",
		"Postcode" : "4685"
	},
	{
		"SuburbName" : "Rahotu",
		"Postcode" : "4685"
	},
	{
		"SuburbName" : "Ohakune",
		"Postcode" : "4691"
	},
	{
		"SuburbName" : "Manawatu",
		"Postcode" : "4691"
	},
	{
		"SuburbName" : "Rangataua",
		"Postcode" : "4691"
	},
	{
		"SuburbName" : "Tangiwai",
		"Postcode" : "4691"
	},
	{
		"SuburbName" : "Tohunga Junction",
		"Postcode" : "4691"
	},
	{
		"SuburbName" : "Tongariro National Park",
		"Postcode" : "4691"
	},
	{
		"SuburbName" : "Waimarino",
		"Postcode" : "4691"
	},
	{
		"SuburbName" : "Manawatu",
		"Postcode" : "4694"
	},
	{
		"SuburbName" : "Erua",
		"Postcode" : "4696"
	},
	{
		"SuburbName" : "Horopito",
		"Postcode" : "4696"
	},
	{
		"SuburbName" : "Makakahi",
		"Postcode" : "4696"
	},
	{
		"SuburbName" : "Orautoha",
		"Postcode" : "4696"
	},
	{
		"SuburbName" : "Owhango",
		"Postcode" : "4696"
	},
	{
		"SuburbName" : "Parinui",
		"Postcode" : "4696"
	},
	{
		"SuburbName" : "Manawatu",
		"Postcode" : "4696"
	},
	{
		"SuburbName" : "Ruatiti",
		"Postcode" : "4696"
	},
	{
		"SuburbName" : "Tohunga Junction",
		"Postcode" : "4696"
	},
	{
		"SuburbName" : "Apiti",
		"Postcode" : "4771"
	},
	{
		"SuburbName" : "Apiti",
		"Postcode" : "4774"
	},
	{
		"SuburbName" : "Kimbolton",
		"Postcode" : "4774"
	},
	{
		"SuburbName" : "Mangaweka",
		"Postcode" : "4774"
	},
	{
		"SuburbName" : "Ohingaiti",
		"Postcode" : "4774"
	},
	{
		"SuburbName" : "Rangiwahia",
		"Postcode" : "4774"
	},
	{
		"SuburbName" : "Aorangi",
		"Postcode" : "4775"
	},
	{
		"SuburbName" : "Ashhurst",
		"Postcode" : "4775"
	},
	{
		"SuburbName" : "Bunnythorpe",
		"Postcode" : "4775"
	},
	{
		"SuburbName" : "Colyton",
		"Postcode" : "4775"
	},
	{
		"SuburbName" : "Feilding",
		"Postcode" : "4775"
	},
	{
		"SuburbName" : "Halcombe",
		"Postcode" : "4775"
	},
	{
		"SuburbName" : "Newbury",
		"Postcode" : "4775"
	},
	{
		"SuburbName" : "Palmerston North",
		"Postcode" : "4775"
	},
	{
		"SuburbName" : "Pohangina",
		"Postcode" : "4775"
	},
	{
		"SuburbName" : "Apiti",
		"Postcode" : "4777"
	},
	{
		"SuburbName" : "Ashhurst",
		"Postcode" : "4777"
	},
	{
		"SuburbName" : "Beaconsfield",
		"Postcode" : "4777"
	},
	{
		"SuburbName" : "Cheltenham",
		"Postcode" : "4777"
	},
	{
		"SuburbName" : "Feilding",
		"Postcode" : "4777"
	},
	{
		"SuburbName" : "Halcombe",
		"Postcode" : "4777"
	},
	{
		"SuburbName" : "Kimbolton",
		"Postcode" : "4777"
	},
	{
		"SuburbName" : "Kiwitea",
		"Postcode" : "4777"
	},
	{
		"SuburbName" : "Pohangina",
		"Postcode" : "4777"
	},
	{
		"SuburbName" : "Beaconsfield",
		"Postcode" : "4779"
	},
	{
		"SuburbName" : "Feilding",
		"Postcode" : "4779"
	},
	{
		"SuburbName" : "Halcombe",
		"Postcode" : "4779"
	},
	{
		"SuburbName" : "Kimbolton",
		"Postcode" : "4779"
	},
	{
		"SuburbName" : "Marton",
		"Postcode" : "4779"
	},
	{
		"SuburbName" : "Sanson",
		"Postcode" : "4779"
	},
	{
		"SuburbName" : "Waituna West",
		"Postcode" : "4779"
	},
	{
		"SuburbName" : "Feilding",
		"Postcode" : "4780"
	},
	{
		"SuburbName" : "Rewa",
		"Postcode" : "4780"
	},
	{
		"SuburbName" : "Waituna West",
		"Postcode" : "4780"
	},
	{
		"SuburbName" : "Hunterville",
		"Postcode" : "4781"
	},
	{
		"SuburbName" : "Fordell",
		"Postcode" : "4782"
	},
	{
		"SuburbName" : "Hunterville",
		"Postcode" : "4782"
	},
	{
		"SuburbName" : "Papanui Junction",
		"Postcode" : "4782"
	},
	{
		"SuburbName" : "Taihape",
		"Postcode" : "4782"
	},
	{
		"SuburbName" : "Feilding",
		"Postcode" : "4783"
	},
	{
		"SuburbName" : "Hunterville",
		"Postcode" : "4783"
	},
	{
		"SuburbName" : "Kimbolton",
		"Postcode" : "4783"
	},
	{
		"SuburbName" : "Ohingaiti",
		"Postcode" : "4783"
	},
	{
		"SuburbName" : "Waituna West",
		"Postcode" : "4783"
	},
	{
		"SuburbName" : "Hunterville",
		"Postcode" : "4784"
	},
	{
		"SuburbName" : "Hunterville",
		"Postcode" : "4785"
	},
	{
		"SuburbName" : "Kimbolton",
		"Postcode" : "4785"
	},
	{
		"SuburbName" : "Ohingaiti",
		"Postcode" : "4785"
	},
	{
		"SuburbName" : "Taihape",
		"Postcode" : "4785"
	},
	{
		"SuburbName" : "Hunterville",
		"Postcode" : "4786"
	},
	{
		"SuburbName" : "Bulls",
		"Postcode" : "4787"
	},
	{
		"SuburbName" : "Crofton",
		"Postcode" : "4787"
	},
	{
		"SuburbName" : "Feilding",
		"Postcode" : "4787"
	},
	{
		"SuburbName" : "Hunterville",
		"Postcode" : "4787"
	},
	{
		"SuburbName" : "Marton",
		"Postcode" : "4787"
	},
	{
		"SuburbName" : "Hunterville",
		"Postcode" : "4788"
	},
	{
		"SuburbName" : "Marton",
		"Postcode" : "4788"
	},
	{
		"SuburbName" : "Turakina",
		"Postcode" : "4788"
	},
	{
		"SuburbName" : "Wanganui",
		"Postcode" : "4788"
	},
	{
		"SuburbName" : "Whanganui",
		"Postcode" : "4788"
	},
	{
		"SuburbName" : "Bulls",
		"Postcode" : "4789"
	},
	{
		"SuburbName" : "Lake Alice",
		"Postcode" : "4789"
	},
	{
		"SuburbName" : "Marton",
		"Postcode" : "4789"
	},
	{
		"SuburbName" : "Turakina",
		"Postcode" : "4789"
	},
	{
		"SuburbName" : "Hunterville",
		"Postcode" : "4791"
	},
	{
		"SuburbName" : "Ohakune",
		"Postcode" : "4791"
	},
	{
		"SuburbName" : "Papanui Junction",
		"Postcode" : "4791"
	},
	{
		"SuburbName" : "Rangiwaea",
		"Postcode" : "4791"
	},
	{
		"SuburbName" : "Taihape",
		"Postcode" : "4791"
	},
	{
		"SuburbName" : "Tangiwai",
		"Postcode" : "4791"
	},
	{
		"SuburbName" : "Moawhango",
		"Postcode" : "4792"
	},
	{
		"SuburbName" : "Ngamahanga",
		"Postcode" : "4792"
	},
	{
		"SuburbName" : "Pukeokahu",
		"Postcode" : "4792"
	},
	{
		"SuburbName" : "Taihape",
		"Postcode" : "4792"
	},
	{
		"SuburbName" : "Pukeokahu",
		"Postcode" : "4793"
	},
	{
		"SuburbName" : "Taihape",
		"Postcode" : "4793"
	},
	{
		"SuburbName" : "Taoroa Junction",
		"Postcode" : "4793"
	},
	{
		"SuburbName" : "Mangaweka",
		"Postcode" : "4794"
	},
	{
		"SuburbName" : "Taihape",
		"Postcode" : "4794"
	},
	{
		"SuburbName" : "Taoroa Junction",
		"Postcode" : "4794"
	},
	{
		"SuburbName" : "Utiku",
		"Postcode" : "4794"
	},
	{
		"SuburbName" : "Taihape",
		"Postcode" : "4795"
	},
	{
		"SuburbName" : "Waiouru",
		"Postcode" : "4795"
	},
	{
		"SuburbName" : "Waiouru",
		"Postcode" : "4795"
	},
	{
		"SuburbName" : "Mangaweka",
		"Postcode" : "4796"
	},
	{
		"SuburbName" : "Taihape",
		"Postcode" : "4796"
	},
	{
		"SuburbName" : "Mangaweka",
		"Postcode" : "4797"
	},
	{
		"SuburbName" : "Taihape",
		"Postcode" : "4797"
	},
	{
		"SuburbName" : "Taoroa Junction",
		"Postcode" : "4797"
	},
	{
		"SuburbName" : "Apiti",
		"Postcode" : "4884"
	},
	{
		"SuburbName" : "Ashhurst",
		"Postcode" : "4884"
	},
	{
		"SuburbName" : "Pohangina",
		"Postcode" : "4884"
	},
	{
		"SuburbName" : "Ruahine Forest Park",
		"Postcode" : "4884"
	},
	{
		"SuburbName" : "Foxton",
		"Postcode" : "4891"
	},
	{
		"SuburbName" : "Foxton Beach",
		"Postcode" : "4891"
	},
	{
		"SuburbName" : "Glen Oroua",
		"Postcode" : "4891"
	},
	{
		"SuburbName" : "Himatangi",
		"Postcode" : "4891"
	},
	{
		"SuburbName" : "Himatangi Beach",
		"Postcode" : "4891"
	},
	{
		"SuburbName" : "Rongotea",
		"Postcode" : "4891"
	},
	{
		"SuburbName" : "Tangimoana",
		"Postcode" : "4891"
	},
	{
		"SuburbName" : "Foxton",
		"Postcode" : "4893"
	},
	{
		"SuburbName" : "Moutoa",
		"Postcode" : "4893"
	},
	{
		"SuburbName" : "Shannon",
		"Postcode" : "4893"
	},
	{
		"SuburbName" : "Bulls",
		"Postcode" : "4894"
	},
	{
		"SuburbName" : "Lake Alice",
		"Postcode" : "4894"
	},
	{
		"SuburbName" : "Marton",
		"Postcode" : "4894"
	},
	{
		"SuburbName" : "Parewanui",
		"Postcode" : "4894"
	},
	{
		"SuburbName" : "Santoft",
		"Postcode" : "4894"
	},
	{
		"SuburbName" : "Tangimoana",
		"Postcode" : "4894"
	},
	{
		"SuburbName" : "Akitio",
		"Postcode" : "4970"
	},
	{
		"SuburbName" : "Dannevirke",
		"Postcode" : "4970"
	},
	{
		"SuburbName" : "Herbertville",
		"Postcode" : "4970"
	},
	{
		"SuburbName" : "Pongaroa",
		"Postcode" : "4970"
	},
	{
		"SuburbName" : "Te Uri",
		"Postcode" : "4970"
	},
	{
		"SuburbName" : "Waitahora",
		"Postcode" : "4970"
	},
	{
		"SuburbName" : "Weber",
		"Postcode" : "4970"
	},
	{
		"SuburbName" : "Dannevirke",
		"Postcode" : "4971"
	},
	{
		"SuburbName" : "Pahiatua",
		"Postcode" : "4971"
	},
	{
		"SuburbName" : "Pongaroa",
		"Postcode" : "4971"
	},
	{
		"SuburbName" : "Waione",
		"Postcode" : "4971"
	},
	{
		"SuburbName" : "Waitahora",
		"Postcode" : "4971"
	},
	{
		"SuburbName" : "Weber",
		"Postcode" : "4971"
	},
	{
		"SuburbName" : "Dannevirke",
		"Postcode" : "4972"
	},
	{
		"SuburbName" : "Kumeroa",
		"Postcode" : "4972"
	},
	{
		"SuburbName" : "Maharahara",
		"Postcode" : "4972"
	},
	{
		"SuburbName" : "Papatawa",
		"Postcode" : "4972"
	},
	{
		"SuburbName" : "Woodville",
		"Postcode" : "4972"
	},
	{
		"SuburbName" : "Dannevirke",
		"Postcode" : "4973"
	},
	{
		"SuburbName" : "Te Uri",
		"Postcode" : "4973"
	},
	{
		"SuburbName" : "Waitahora",
		"Postcode" : "4973"
	},
	{
		"SuburbName" : "Weber",
		"Postcode" : "4973"
	},
	{
		"SuburbName" : "Dannevirke",
		"Postcode" : "4974"
	},
	{
		"SuburbName" : "Matamau",
		"Postcode" : "4974"
	},
	{
		"SuburbName" : "Norsewood",
		"Postcode" : "4974"
	},
	{
		"SuburbName" : "Ormondville",
		"Postcode" : "4974"
	},
	{
		"SuburbName" : "Takapau",
		"Postcode" : "4974"
	},
	{
		"SuburbName" : "Dannevirke",
		"Postcode" : "4975"
	},
	{
		"SuburbName" : "Dannevirke",
		"Postcode" : "4976"
	},
	{
		"SuburbName" : "Ormondville",
		"Postcode" : "4976"
	},
	{
		"SuburbName" : "Dannevirke",
		"Postcode" : "4977"
	},
	{
		"SuburbName" : "Flemington",
		"Postcode" : "4977"
	},
	{
		"SuburbName" : "Matamau",
		"Postcode" : "4977"
	},
	{
		"SuburbName" : "Norsewood",
		"Postcode" : "4977"
	},
	{
		"SuburbName" : "Ormondville",
		"Postcode" : "4977"
	},
	{
		"SuburbName" : "Te Uri",
		"Postcode" : "4977"
	},
	{
		"SuburbName" : "Dannevirke",
		"Postcode" : "4978"
	},
	{
		"SuburbName" : "Dannevirke",
		"Postcode" : "4979"
	},
	{
		"SuburbName" : "Waione",
		"Postcode" : "4979"
	},
	{
		"SuburbName" : "Weber",
		"Postcode" : "4979"
	},
	{
		"SuburbName" : "Alfredton",
		"Postcode" : "4981"
	},
	{
		"SuburbName" : "Eketahuna",
		"Postcode" : "4981"
	},
	{
		"SuburbName" : "Kaitawa",
		"Postcode" : "4981"
	},
	{
		"SuburbName" : "Pahiatua",
		"Postcode" : "4981"
	},
	{
		"SuburbName" : "Kumeroa",
		"Postcode" : "4982"
	},
	{
		"SuburbName" : "Mangatainoka",
		"Postcode" : "4982"
	},
	{
		"SuburbName" : "Pahiatua",
		"Postcode" : "4982"
	},
	{
		"SuburbName" : "Ballance",
		"Postcode" : "4983"
	},
	{
		"SuburbName" : "Mangamutu",
		"Postcode" : "4983"
	},
	{
		"SuburbName" : "Pahiatua",
		"Postcode" : "4983"
	},
	{
		"SuburbName" : "Eketahuna",
		"Postcode" : "4984"
	},
	{
		"SuburbName" : "Pahiatua",
		"Postcode" : "4984"
	},
	{
		"SuburbName" : "Makuri",
		"Postcode" : "4985"
	},
	{
		"SuburbName" : "Pahiatua",
		"Postcode" : "4985"
	},
	{
		"SuburbName" : "Alfredton",
		"Postcode" : "4986"
	},
	{
		"SuburbName" : "Eketahuna",
		"Postcode" : "4986"
	},
	{
		"SuburbName" : "Pahiatua",
		"Postcode" : "4986"
	},
	{
		"SuburbName" : "Pahiatua",
		"Postcode" : "4987"
	},
	{
		"SuburbName" : "Eketahuna",
		"Postcode" : "4988"
	},
	{
		"SuburbName" : "Pahiatua",
		"Postcode" : "4988"
	},
	{
		"SuburbName" : "Makuri",
		"Postcode" : "4989"
	},
	{
		"SuburbName" : "Pahiatua",
		"Postcode" : "4989"
	},
	{
		"SuburbName" : "Pongaroa",
		"Postcode" : "4989"
	},
	{
		"SuburbName" : "Pongaroa",
		"Postcode" : "4990"
	},
	{
		"SuburbName" : "Akitio",
		"Postcode" : "4991"
	},
	{
		"SuburbName" : "Pongaroa",
		"Postcode" : "4991"
	},
	{
		"SuburbName" : "Waione",
		"Postcode" : "4991"
	},
	{
		"SuburbName" : "Weber",
		"Postcode" : "4991"
	},
	{
		"SuburbName" : "Pongaroa",
		"Postcode" : "4992"
	},
	{
		"SuburbName" : "Waione",
		"Postcode" : "4992"
	},
	{
		"SuburbName" : "Weber",
		"Postcode" : "4992"
	},
	{
		"SuburbName" : "Alfredton",
		"Postcode" : "4993"
	},
	{
		"SuburbName" : "Eketahuna",
		"Postcode" : "4993"
	},
	{
		"SuburbName" : "Kaitawa",
		"Postcode" : "4993"
	},
	{
		"SuburbName" : "Pahiatua",
		"Postcode" : "4993"
	},
	{
		"SuburbName" : "Eketahuna",
		"Postcode" : "4994"
	},
	{
		"SuburbName" : "Mauriceville",
		"Postcode" : "4994"
	},
	{
		"SuburbName" : "Eketahuna",
		"Postcode" : "4995"
	},
	{
		"SuburbName" : "Alfredton",
		"Postcode" : "4996"
	},
	{
		"SuburbName" : "Eketahuna",
		"Postcode" : "4996"
	},
	{
		"SuburbName" : "Ihuraua",
		"Postcode" : "4996"
	},
	{
		"SuburbName" : "Masterton",
		"Postcode" : "4996"
	},
	{
		"SuburbName" : "Pongaroa",
		"Postcode" : "4996"
	},
	{
		"SuburbName" : "Tinui",
		"Postcode" : "4996"
	},
	{
		"SuburbName" : "Tiraumea",
		"Postcode" : "4996"
	},
	{
		"SuburbName" : "Kumeroa",
		"Postcode" : "4997"
	},
	{
		"SuburbName" : "Pahiatua",
		"Postcode" : "4997"
	},
	{
		"SuburbName" : "Woodville",
		"Postcode" : "4997"
	},
	{
		"SuburbName" : "Papatawa",
		"Postcode" : "4998"
	},
	{
		"SuburbName" : "Woodville",
		"Postcode" : "4998"
	},
	{
		"SuburbName" : "Ashhurst",
		"Postcode" : "4999"
	},
	{
		"SuburbName" : "Mangatainoka",
		"Postcode" : "4999"
	},
	{
		"SuburbName" : "Pahiatua",
		"Postcode" : "4999"
	},
	{
		"SuburbName" : "Woodville",
		"Postcode" : "4999"
	},
	{
		"SuburbName" : "Blue Mountains",
		"Postcode" : "5371"
	},
	{
		"SuburbName" : "Maidstone",
		"Postcode" : "5371"
	},
	{
		"SuburbName" : "Mangaroa",
		"Postcode" : "5371"
	},
	{
		"SuburbName" : "Maymorn",
		"Postcode" : "5371"
	},
	{
		"SuburbName" : "Moonshine Valley",
		"Postcode" : "5371"
	},
	{
		"SuburbName" : "Pinehaven",
		"Postcode" : "5371"
	},
	{
		"SuburbName" : "Riverstone Terraces",
		"Postcode" : "5371"
	},
	{
		"SuburbName" : "Upper Hutt",
		"Postcode" : "5371"
	},
	{
		"SuburbName" : "Wallaceville",
		"Postcode" : "5371"
	},
	{
		"SuburbName" : "Whitemans Valley",
		"Postcode" : "5371"
	},
	{
		"SuburbName" : "Akatarawa",
		"Postcode" : "5372"
	},
	{
		"SuburbName" : "Akatarawa Valley",
		"Postcode" : "5372"
	},
	{
		"SuburbName" : "Craigs Flat",
		"Postcode" : "5372"
	},
	{
		"SuburbName" : "Pakuratahi",
		"Postcode" : "5372"
	},
	{
		"SuburbName" : "Rimutaka Hill",
		"Postcode" : "5372"
	},
	{
		"SuburbName" : "Upper Hutt",
		"Postcode" : "5372"
	},
	{
		"SuburbName" : "Lower Hutt",
		"Postcode" : "5373"
	},
	{
		"SuburbName" : "Rimutaka Forest Park",
		"Postcode" : "5373"
	},
	{
		"SuburbName" : "Wainuiomata",
		"Postcode" : "5373"
	},
	{
		"SuburbName" : "Wainuiomata Coast",
		"Postcode" : "5373"
	},
	{
		"SuburbName" : "Belmont",
		"Postcode" : "5381"
	},
	{
		"SuburbName" : "Camborne",
		"Postcode" : "5381"
	},
	{
		"SuburbName" : "Judgeford",
		"Postcode" : "5381"
	},
	{
		"SuburbName" : "Lower Hutt",
		"Postcode" : "5381"
	},
	{
		"SuburbName" : "Moonshine Valley",
		"Postcode" : "5381"
	},
	{
		"SuburbName" : "Paekakariki Hill",
		"Postcode" : "5381"
	},
	{
		"SuburbName" : "Pauatahanui",
		"Postcode" : "5381"
	},
	{
		"SuburbName" : "Porirua",
		"Postcode" : "5381"
	},
	{
		"SuburbName" : "Pukerua Bay",
		"Postcode" : "5381"
	},
	{
		"SuburbName" : "Upper Hutt",
		"Postcode" : "5381"
	},
	{
		"SuburbName" : "Whitby",
		"Postcode" : "5381"
	},
	{
		"SuburbName" : "Otaihanga",
		"Postcode" : "5391"
	},
	{
		"SuburbName" : "Paraparaumu",
		"Postcode" : "5391"
	},
	{
		"SuburbName" : "Peka Peka",
		"Postcode" : "5391"
	},
	{
		"SuburbName" : "Reikorangi",
		"Postcode" : "5391"
	},
	{
		"SuburbName" : "Waikanae",
		"Postcode" : "5391"
	},
	{
		"SuburbName" : "Kuku",
		"Postcode" : "5570"
	},
	{
		"SuburbName" : "Levin",
		"Postcode" : "5570"
	},
	{
		"SuburbName" : "MANAKAU",
		"Postcode" : "5570"
	},
	{
		"SuburbName" : "Muhunoa East",
		"Postcode" : "5570"
	},
	{
		"SuburbName" : "Ohau",
		"Postcode" : "5570"
	},
	{
		"SuburbName" : "Hokio Beach",
		"Postcode" : "5571"
	},
	{
		"SuburbName" : "Koputaroa",
		"Postcode" : "5571"
	},
	{
		"SuburbName" : "Levin",
		"Postcode" : "5571"
	},
	{
		"SuburbName" : "Ohau",
		"Postcode" : "5571"
	},
	{
		"SuburbName" : "Shannon",
		"Postcode" : "5571"
	},
	{
		"SuburbName" : "Waitarere Beach",
		"Postcode" : "5571"
	},
	{
		"SuburbName" : "Foxton",
		"Postcode" : "5572"
	},
	{
		"SuburbName" : "Levin",
		"Postcode" : "5572"
	},
	{
		"SuburbName" : "Moutoa",
		"Postcode" : "5572"
	},
	{
		"SuburbName" : "Poroutawhao",
		"Postcode" : "5572"
	},
	{
		"SuburbName" : "WAITARERE BEACH",
		"Postcode" : "5572"
	},
	{
		"SuburbName" : "Levin",
		"Postcode" : "5573"
	},
	{
		"SuburbName" : "MANAKAU",
		"Postcode" : "5573"
	},
	{
		"SuburbName" : "Waikawa Beach",
		"Postcode" : "5573"
	},
	{
		"SuburbName" : "Levin",
		"Postcode" : "5574"
	},
	{
		"SuburbName" : "WAITARERE BEACH",
		"Postcode" : "5574"
	},
	{
		"SuburbName" : "Koputaroa",
		"Postcode" : "5575"
	},
	{
		"SuburbName" : "Levin",
		"Postcode" : "5575"
	},
	{
		"SuburbName" : "Poroutawhao",
		"Postcode" : "5575"
	},
	{
		"SuburbName" : "Shannon",
		"Postcode" : "5575"
	},
	{
		"SuburbName" : "Otaki",
		"Postcode" : "5581"
	},
	{
		"SuburbName" : "Peka Peka",
		"Postcode" : "5581"
	},
	{
		"SuburbName" : "Te Horo",
		"Postcode" : "5581"
	},
	{
		"SuburbName" : "Te Horo Beach",
		"Postcode" : "5581"
	},
	{
		"SuburbName" : "Waikanae",
		"Postcode" : "5581"
	},
	{
		"SuburbName" : "Hautere",
		"Postcode" : "5582"
	},
	{
		"SuburbName" : "Otaki",
		"Postcode" : "5582"
	},
	{
		"SuburbName" : "Te Horo",
		"Postcode" : "5582"
	},
	{
		"SuburbName" : "Hautere",
		"Postcode" : "5583"
	},
	{
		"SuburbName" : "MANAKAU",
		"Postcode" : "5583"
	},
	{
		"SuburbName" : "Otaki",
		"Postcode" : "5583"
	},
	{
		"SuburbName" : "Bidwells Cutting",
		"Postcode" : "5771"
	},
	{
		"SuburbName" : "Featherston",
		"Postcode" : "5771"
	},
	{
		"SuburbName" : "Greytown",
		"Postcode" : "5771"
	},
	{
		"SuburbName" : "Kahutara",
		"Postcode" : "5771"
	},
	{
		"SuburbName" : "Lake Reserve",
		"Postcode" : "5771"
	},
	{
		"SuburbName" : "Martinborough",
		"Postcode" : "5771"
	},
	{
		"SuburbName" : "Morrisons Bush",
		"Postcode" : "5771"
	},
	{
		"SuburbName" : "Featherston",
		"Postcode" : "5771"
	},
	{
		"SuburbName" : "Tauherenikau",
		"Postcode" : "5771"
	},
	{
		"SuburbName" : "Aorangi Forest Park",
		"Postcode" : "5772"
	},
	{
		"SuburbName" : "Cape Palliser",
		"Postcode" : "5772"
	},
	{
		"SuburbName" : "Featherston",
		"Postcode" : "5772"
	},
	{
		"SuburbName" : "Kahutara",
		"Postcode" : "5772"
	},
	{
		"SuburbName" : "Lake Ferry",
		"Postcode" : "5772"
	},
	{
		"SuburbName" : "Pirinoa",
		"Postcode" : "5772"
	},
	{
		"SuburbName" : "Western Lake",
		"Postcode" : "5772"
	},
	{
		"SuburbName" : "Whangaimoana",
		"Postcode" : "5772"
	},
	{
		"SuburbName" : "Featherston",
		"Postcode" : "5773"
	},
	{
		"SuburbName" : "wellington",
		"Postcode" : "5773"
	},
	{
		"SuburbName" : "Rimutaka Forest Park",
		"Postcode" : "5773"
	},
	{
		"SuburbName" : "Featherston",
		"Postcode" : "5773"
	},
	{
		"SuburbName" : "Tauherenikau",
		"Postcode" : "5773"
	},
	{
		"SuburbName" : "Western Lake",
		"Postcode" : "5773"
	},
	{
		"SuburbName" : "Aorangi Forest Park",
		"Postcode" : "5781"
	},
	{
		"SuburbName" : "Dyerville",
		"Postcode" : "5781"
	},
	{
		"SuburbName" : "Hillside",
		"Postcode" : "5781"
	},
	{
		"SuburbName" : "Martinborough",
		"Postcode" : "5781"
	},
	{
		"SuburbName" : "Pirinoa",
		"Postcode" : "5781"
	},
	{
		"SuburbName" : "Ruakokoputuna",
		"Postcode" : "5781"
	},
	{
		"SuburbName" : "Te Muna",
		"Postcode" : "5781"
	},
	{
		"SuburbName" : "Martinborough",
		"Postcode" : "5782"
	},
	{
		"SuburbName" : "Ruakokoputuna",
		"Postcode" : "5782"
	},
	{
		"SuburbName" : "Te Awaiti",
		"Postcode" : "5782"
	},
	{
		"SuburbName" : "Tora",
		"Postcode" : "5782"
	},
	{
		"SuburbName" : "Tuturumuri",
		"Postcode" : "5782"
	},
	{
		"SuburbName" : "White Rock",
		"Postcode" : "5782"
	},
	{
		"SuburbName" : "Bidwells Cutting",
		"Postcode" : "5783"
	},
	{
		"SuburbName" : "Martinborough",
		"Postcode" : "5783"
	},
	{
		"SuburbName" : "Carterton",
		"Postcode" : "5784"
	},
	{
		"SuburbName" : "Hillside",
		"Postcode" : "5784"
	},
	{
		"SuburbName" : "Hinakura",
		"Postcode" : "5784"
	},
	{
		"SuburbName" : "Longbush",
		"Postcode" : "5784"
	},
	{
		"SuburbName" : "Martinborough",
		"Postcode" : "5784"
	},
	{
		"SuburbName" : "Ponatahi",
		"Postcode" : "5784"
	},
	{
		"SuburbName" : "Te Muna",
		"Postcode" : "5784"
	},
	{
		"SuburbName" : "Carrington",
		"Postcode" : "5791"
	},
	{
		"SuburbName" : "Carterton",
		"Postcode" : "5791"
	},
	{
		"SuburbName" : "Clareville",
		"Postcode" : "5791"
	},
	{
		"SuburbName" : "Dalefield",
		"Postcode" : "5791"
	},
	{
		"SuburbName" : "Masterton",
		"Postcode" : "5791"
	},
	{
		"SuburbName" : "Mount Holdsworth",
		"Postcode" : "5791"
	},
	{
		"SuburbName" : "Mt Holdsworth",
		"Postcode" : "5791"
	},
	{
		"SuburbName" : "Tararua Forest Park",
		"Postcode" : "5791"
	},
	{
		"SuburbName" : "Upper Plain",
		"Postcode" : "5791"
	},
	{
		"SuburbName" : "Waingawa",
		"Postcode" : "5791"
	},
	{
		"SuburbName" : "West Taratahi",
		"Postcode" : "5791"
	},
	{
		"SuburbName" : "Ahiaruhe",
		"Postcode" : "5792"
	},
	{
		"SuburbName" : "Carterton",
		"Postcode" : "5792"
	},
	{
		"SuburbName" : "Clareville",
		"Postcode" : "5792"
	},
	{
		"SuburbName" : "East Taratahi",
		"Postcode" : "5792"
	},
	{
		"SuburbName" : "Gladstone",
		"Postcode" : "5792"
	},
	{
		"SuburbName" : "Kokotau",
		"Postcode" : "5792"
	},
	{
		"SuburbName" : "Longbush",
		"Postcode" : "5792"
	},
	{
		"SuburbName" : "Martinborough",
		"Postcode" : "5792"
	},
	{
		"SuburbName" : "Masterton",
		"Postcode" : "5792"
	},
	{
		"SuburbName" : "Parkvale",
		"Postcode" : "5792"
	},
	{
		"SuburbName" : "Ponatahi",
		"Postcode" : "5792"
	},
	{
		"SuburbName" : "Taumata Island",
		"Postcode" : "5792"
	},
	{
		"SuburbName" : "Waihakeke",
		"Postcode" : "5792"
	},
	{
		"SuburbName" : "Ahikouka",
		"Postcode" : "5794"
	},
	{
		"SuburbName" : "Bidwells Cutting",
		"Postcode" : "5794"
	},
	{
		"SuburbName" : "Greytown",
		"Postcode" : "5794"
	},
	{
		"SuburbName" : "Martinborough",
		"Postcode" : "5794"
	},
	{
		"SuburbName" : "Morison Bush",
		"Postcode" : "5794"
	},
	{
		"SuburbName" : "Papawai",
		"Postcode" : "5794"
	},
	{
		"SuburbName" : "Tauherenikau",
		"Postcode" : "5794"
	},
	{
		"SuburbName" : "Woodside",
		"Postcode" : "5794"
	},
	{
		"SuburbName" : "Bideford",
		"Postcode" : "5871"
	},
	{
		"SuburbName" : "Lansdowne",
		"Postcode" : "5871"
	},
	{
		"SuburbName" : "Masterton",
		"Postcode" : "5871"
	},
	{
		"SuburbName" : "Opaki",
		"Postcode" : "5871"
	},
	{
		"SuburbName" : "Rangitumau",
		"Postcode" : "5871"
	},
	{
		"SuburbName" : "Te Ore Ore",
		"Postcode" : "5871"
	},
	{
		"SuburbName" : "Tinui",
		"Postcode" : "5871"
	},
	{
		"SuburbName" : "Blairlogie",
		"Postcode" : "5872"
	},
	{
		"SuburbName" : "Homewood",
		"Postcode" : "5872"
	},
	{
		"SuburbName" : "Kaiwhata",
		"Postcode" : "5872"
	},
	{
		"SuburbName" : "Mangapakeha",
		"Postcode" : "5872"
	},
	{
		"SuburbName" : "Masterton",
		"Postcode" : "5872"
	},
	{
		"SuburbName" : "Otahome",
		"Postcode" : "5872"
	},
	{
		"SuburbName" : "Riversdale Beach",
		"Postcode" : "5872"
	},
	{
		"SuburbName" : "Riversdale Beach",
		"Postcode" : "5872"
	},
	{
		"SuburbName" : "Te Wharau",
		"Postcode" : "5872"
	},
	{
		"SuburbName" : "Whareama",
		"Postcode" : "5872"
	},
	{
		"SuburbName" : "Eketahuna",
		"Postcode" : "5881"
	},
	{
		"SuburbName" : "Kiriwhakapapa",
		"Postcode" : "5881"
	},
	{
		"SuburbName" : "Masterton",
		"Postcode" : "5881"
	},
	{
		"SuburbName" : "Mikimiki",
		"Postcode" : "5881"
	},
	{
		"SuburbName" : "Mount Bruce",
		"Postcode" : "5881"
	},
	{
		"SuburbName" : "MOUNT BRUCE",
		"Postcode" : "5881"
	},
	{
		"SuburbName" : "Opaki",
		"Postcode" : "5881"
	},
	{
		"SuburbName" : "Alfredton",
		"Postcode" : "5882"
	},
	{
		"SuburbName" : "Bideford",
		"Postcode" : "5882"
	},
	{
		"SuburbName" : "Eketahuna",
		"Postcode" : "5882"
	},
	{
		"SuburbName" : "Ihuraua",
		"Postcode" : "5882"
	},
	{
		"SuburbName" : "Kopuaranga",
		"Postcode" : "5882"
	},
	{
		"SuburbName" : "Masterton",
		"Postcode" : "5882"
	},
	{
		"SuburbName" : "Mauriceville",
		"Postcode" : "5882"
	},
	{
		"SuburbName" : "Opaki",
		"Postcode" : "5882"
	},
	{
		"SuburbName" : "Rangitumau",
		"Postcode" : "5882"
	},
	{
		"SuburbName" : "Whanaehu Valley",
		"Postcode" : "5882"
	},
	{
		"SuburbName" : "Whangaehu",
		"Postcode" : "5882"
	},
	{
		"SuburbName" : "Admiral Hill",
		"Postcode" : "5883"
	},
	{
		"SuburbName" : "Flat Point",
		"Postcode" : "5883"
	},
	{
		"SuburbName" : "Gladstone",
		"Postcode" : "5883"
	},
	{
		"SuburbName" : "Glenburn",
		"Postcode" : "5883"
	},
	{
		"SuburbName" : "Hinakura",
		"Postcode" : "5883"
	},
	{
		"SuburbName" : "Kourarau Hill",
		"Postcode" : "5883"
	},
	{
		"SuburbName" : "Martinborough",
		"Postcode" : "5883"
	},
	{
		"SuburbName" : "Masterton",
		"Postcode" : "5883"
	},
	{
		"SuburbName" : "Te Wharau",
		"Postcode" : "5883"
	},
	{
		"SuburbName" : "Gladstone",
		"Postcode" : "5884"
	},
	{
		"SuburbName" : "Hinakura",
		"Postcode" : "5884"
	},
	{
		"SuburbName" : "Homebush",
		"Postcode" : "5884"
	},
	{
		"SuburbName" : "Longbush",
		"Postcode" : "5884"
	},
	{
		"SuburbName" : "Masterton",
		"Postcode" : "5884"
	},
	{
		"SuburbName" : "Te Ore Ore",
		"Postcode" : "5884"
	},
	{
		"SuburbName" : "Te Whiti",
		"Postcode" : "5884"
	},
	{
		"SuburbName" : "Homebush",
		"Postcode" : "5885"
	},
	{
		"SuburbName" : "Masterton",
		"Postcode" : "5885"
	},
	{
		"SuburbName" : "Solway",
		"Postcode" : "5885"
	},
	{
		"SuburbName" : "Masterton",
		"Postcode" : "5886"
	},
	{
		"SuburbName" : "Rangitumau",
		"Postcode" : "5886"
	},
	{
		"SuburbName" : "Te Ore Ore",
		"Postcode" : "5886"
	},
	{
		"SuburbName" : "Whanaehu Valley",
		"Postcode" : "5886"
	},
	{
		"SuburbName" : "Whangaehu",
		"Postcode" : "5886"
	},
	{
		"SuburbName" : "Carterton",
		"Postcode" : "5887"
	},
	{
		"SuburbName" : "Clareville",
		"Postcode" : "5887"
	},
	{
		"SuburbName" : "East Taratahi",
		"Postcode" : "5887"
	},
	{
		"SuburbName" : "Masterton",
		"Postcode" : "5887"
	},
	{
		"SuburbName" : "West Taratahi",
		"Postcode" : "5887"
	},
	{
		"SuburbName" : "Kaituna",
		"Postcode" : "5888"
	},
	{
		"SuburbName" : "Masterton",
		"Postcode" : "5888"
	},
	{
		"SuburbName" : "Matahiwi",
		"Postcode" : "5888"
	},
	{
		"SuburbName" : "Upper Plain",
		"Postcode" : "5888"
	},
	{
		"SuburbName" : "Bideford",
		"Postcode" : "5889"
	},
	{
		"SuburbName" : "Blairlogie",
		"Postcode" : "5889"
	},
	{
		"SuburbName" : "Castlepoint",
		"Postcode" : "5889"
	},
	{
		"SuburbName" : "Mangapakeha",
		"Postcode" : "5889"
	},
	{
		"SuburbName" : "Masterton",
		"Postcode" : "5889"
	},
	{
		"SuburbName" : "Mataikona",
		"Postcode" : "5889"
	},
	{
		"SuburbName" : "Pongaroa",
		"Postcode" : "5889"
	},
	{
		"SuburbName" : "Tauweru",
		"Postcode" : "5889"
	},
	{
		"SuburbName" : "Te Ore Ore",
		"Postcode" : "5889"
	},
	{
		"SuburbName" : "Tinui",
		"Postcode" : "5889"
	},
	{
		"SuburbName" : "Whakataki",
		"Postcode" : "5889"
	},
	{
		"SuburbName" : "Gladstone",
		"Postcode" : "5890"
	},
	{
		"SuburbName" : "Kourarau Hill",
		"Postcode" : "5890"
	},
	{
		"SuburbName" : "Masterton",
		"Postcode" : "5890"
	},
	{
		"SuburbName" : "Ngahape",
		"Postcode" : "5890"
	},
	{
		"SuburbName" : "Ngaumu",
		"Postcode" : "5890"
	},
	{
		"SuburbName" : "Stronvar",
		"Postcode" : "5890"
	},
	{
		"SuburbName" : "Tauweru",
		"Postcode" : "5890"
	},
	{
		"SuburbName" : "Te Ore Ore",
		"Postcode" : "5890"
	},
	{
		"SuburbName" : "Wainuioru",
		"Postcode" : "5890"
	},
	{
		"SuburbName" : "Weraiti",
		"Postcode" : "5890"
	},
	{
		"SuburbName" : "Bideford",
		"Postcode" : "5894"
	},
	{
		"SuburbName" : "Tinui",
		"Postcode" : "5894"
	},
	{
		"SuburbName" : "Tinui Valley",
		"Postcode" : "5894"
	},
	{
		"SuburbName" : "Karori",
		"Postcode" : "6972"
	},
	{
		"SuburbName" : "Makara",
		"Postcode" : "6972"
	},
	{
		"SuburbName" : "Makara Beach",
		"Postcode" : "6972"
	},
	{
		"SuburbName" : "Wellington",
		"Postcode" : "6972"
	},
	{
		"SuburbName" : "Cable Bay Nelson District",
		"Postcode" : "7071"
	},
	{
		"SuburbName" : "Delaware Bay",
		"Postcode" : "7071"
	},
	{
		"SuburbName" : "Glenduan",
		"Postcode" : "7071"
	},
	{
		"SuburbName" : "Hira",
		"Postcode" : "7071"
	},
	{
		"SuburbName" : "Hira Forest",
		"Postcode" : "7071"
	},
	{
		"SuburbName" : "Mount Richmond Forest Park",
		"Postcode" : "7071"
	},
	{
		"SuburbName" : "MOUNT RICHMOND FOREST PARK",
		"Postcode" : "7071"
	},
	{
		"SuburbName" : "Nelson",
		"Postcode" : "7071"
	},
	{
		"SuburbName" : "Pepin Island",
		"Postcode" : "7071"
	},
	{
		"SuburbName" : "Rai Valley",
		"Postcode" : "7071"
	},
	{
		"SuburbName" : "Todds Valley",
		"Postcode" : "7071"
	},
	{
		"SuburbName" : "Wakapuaka",
		"Postcode" : "7071"
	},
	{
		"SuburbName" : "Whangamoa",
		"Postcode" : "7071"
	},
	{
		"SuburbName" : "Atapo",
		"Postcode" : "7072"
	},
	{
		"SuburbName" : "Glenhope",
		"Postcode" : "7072"
	},
	{
		"SuburbName" : "Howard",
		"Postcode" : "7072"
	},
	{
		"SuburbName" : "Kikiwa",
		"Postcode" : "7072"
	},
	{
		"SuburbName" : "Motupiko",
		"Postcode" : "7072"
	},
	{
		"SuburbName" : "Nelson",
		"Postcode" : "7072"
	},
	{
		"SuburbName" : "Nelson Lakes National Park",
		"Postcode" : "7072"
	},
	{
		"SuburbName" : "St Arnaud",
		"Postcode" : "7072"
	},
	{
		"SuburbName" : "Tapawera",
		"Postcode" : "7072"
	},
	{
		"SuburbName" : "Wakefield",
		"Postcode" : "7072"
	},
	{
		"SuburbName" : "Bainham",
		"Postcode" : "7073"
	},
	{
		"SuburbName" : "Collingwood",
		"Postcode" : "7073"
	},
	{
		"SuburbName" : "Kahurangi National Park",
		"Postcode" : "7073"
	},
	{
		"SuburbName" : "Kaihoka",
		"Postcode" : "7073"
	},
	{
		"SuburbName" : "Mangarakau",
		"Postcode" : "7073"
	},
	{
		"SuburbName" : "Murchison",
		"Postcode" : "7073"
	},
	{
		"SuburbName" : "Owen River",
		"Postcode" : "7073"
	},
	{
		"SuburbName" : "Pakawau",
		"Postcode" : "7073"
	},
	{
		"SuburbName" : "Parapara",
		"Postcode" : "7073"
	},
	{
		"SuburbName" : "Puponga",
		"Postcode" : "7073"
	},
	{
		"SuburbName" : "Rakopi",
		"Postcode" : "7073"
	},
	{
		"SuburbName" : "Rockville",
		"Postcode" : "7073"
	},
	{
		"SuburbName" : "Braeburn Range",
		"Postcode" : "7077"
	},
	{
		"SuburbName" : "Collingwood",
		"Postcode" : "7077"
	},
	{
		"SuburbName" : "Gowanbridge",
		"Postcode" : "7077"
	},
	{
		"SuburbName" : "Kahurangi National Park",
		"Postcode" : "7077"
	},
	{
		"SuburbName" : "Longford",
		"Postcode" : "7077"
	},
	{
		"SuburbName" : "Mangles Valley",
		"Postcode" : "7077"
	},
	{
		"SuburbName" : "Maruia",
		"Postcode" : "7077"
	},
	{
		"SuburbName" : "Matakitaki",
		"Postcode" : "7077"
	},
	{
		"SuburbName" : "Matiri",
		"Postcode" : "7077"
	},
	{
		"SuburbName" : "Murchison",
		"Postcode" : "7077"
	},
	{
		"SuburbName" : "Owen River",
		"Postcode" : "7077"
	},
	{
		"SuburbName" : "Owen River CMB",
		"Postcode" : "7077"
	},
	{
		"SuburbName" : "Reefton",
		"Postcode" : "7077"
	},
	{
		"SuburbName" : "Rotoroa",
		"Postcode" : "7077"
	},
	{
		"SuburbName" : "Shenandoah",
		"Postcode" : "7077"
	},
	{
		"SuburbName" : "Six Mile",
		"Postcode" : "7077"
	},
	{
		"SuburbName" : "St Arnaud",
		"Postcode" : "7077"
	},
	{
		"SuburbName" : "Tutaki",
		"Postcode" : "7077"
	},
	{
		"SuburbName" : "Aniseed Valley",
		"Postcode" : "7081"
	},
	{
		"SuburbName" : "Appleby",
		"Postcode" : "7081"
	},
	{
		"SuburbName" : "Bell Island",
		"Postcode" : "7081"
	},
	{
		"SuburbName" : "Best Island",
		"Postcode" : "7081"
	},
	{
		"SuburbName" : "Brightwater",
		"Postcode" : "7081"
	},
	{
		"SuburbName" : "Hope",
		"Postcode" : "7081"
	},
	{
		"SuburbName" : "Nelson",
		"Postcode" : "7081"
	},
	{
		"SuburbName" : "Rabbit Island",
		"Postcode" : "7081"
	},
	{
		"SuburbName" : "Redwood Valley",
		"Postcode" : "7081"
	},
	{
		"SuburbName" : "Richmond",
		"Postcode" : "7081"
	},
	{
		"SuburbName" : "Appleby",
		"Postcode" : "7091"
	},
	{
		"SuburbName" : "Brightwater",
		"Postcode" : "7091"
	},
	{
		"SuburbName" : "Hope",
		"Postcode" : "7091"
	},
	{
		"SuburbName" : "Lee Valley",
		"Postcode" : "7091"
	},
	{
		"SuburbName" : "Nelson",
		"Postcode" : "7091"
	},
	{
		"SuburbName" : "Richmond",
		"Postcode" : "7091"
	},
	{
		"SuburbName" : "Wairoa Valley",
		"Postcode" : "7091"
	},
	{
		"SuburbName" : "Wakefield",
		"Postcode" : "7091"
	},
	{
		"SuburbName" : "Belgrove",
		"Postcode" : "7095"
	},
	{
		"SuburbName" : "Brightwater",
		"Postcode" : "7095"
	},
	{
		"SuburbName" : "Eighty Eight Valley",
		"Postcode" : "7095"
	},
	{
		"SuburbName" : "Foxhill",
		"Postcode" : "7095"
	},
	{
		"SuburbName" : "Golden Downs",
		"Postcode" : "7095"
	},
	{
		"SuburbName" : "Wai-iti",
		"Postcode" : "7095"
	},
	{
		"SuburbName" : "Wakefield",
		"Postcode" : "7095"
	},
	{
		"SuburbName" : "Belgrove",
		"Postcode" : "7096"
	},
	{
		"SuburbName" : "Dovedale",
		"Postcode" : "7096"
	},
	{
		"SuburbName" : "Glenhope",
		"Postcode" : "7096"
	},
	{
		"SuburbName" : "Golden Downs",
		"Postcode" : "7096"
	},
	{
		"SuburbName" : "Kohatu",
		"Postcode" : "7096"
	},
	{
		"SuburbName" : "Motueka",
		"Postcode" : "7096"
	},
	{
		"SuburbName" : "Nelson",
		"Postcode" : "7096"
	},
	{
		"SuburbName" : "Pigeon Valley",
		"Postcode" : "7096"
	},
	{
		"SuburbName" : "Spooners Range",
		"Postcode" : "7096"
	},
	{
		"SuburbName" : "St Arnaud",
		"Postcode" : "7096"
	},
	{
		"SuburbName" : "Stanley Brook",
		"Postcode" : "7096"
	},
	{
		"SuburbName" : "Tadmor",
		"Postcode" : "7096"
	},
	{
		"SuburbName" : "Tapawera",
		"Postcode" : "7096"
	},
	{
		"SuburbName" : "Thorpe",
		"Postcode" : "7096"
	},
	{
		"SuburbName" : "Wakefield",
		"Postcode" : "7096"
	},
	{
		"SuburbName" : "Woodstock",
		"Postcode" : "7096"
	},
	{
		"SuburbName" : "Bronte",
		"Postcode" : "7173"
	},
	{
		"SuburbName" : "Jackett Island",
		"Postcode" : "7173"
	},
	{
		"SuburbName" : "Mahana",
		"Postcode" : "7173"
	},
	{
		"SuburbName" : "Mapua",
		"Postcode" : "7173"
	},
	{
		"SuburbName" : "Motueka",
		"Postcode" : "7173"
	},
	{
		"SuburbName" : "Ruby Bay",
		"Postcode" : "7173"
	},
	{
		"SuburbName" : "Tasman",
		"Postcode" : "7173"
	},
	{
		"SuburbName" : "Upper Moutere",
		"Postcode" : "7173"
	},
	{
		"SuburbName" : "Dovedale",
		"Postcode" : "7175"
	},
	{
		"SuburbName" : "Lower Moutere",
		"Postcode" : "7175"
	},
	{
		"SuburbName" : "Motueka",
		"Postcode" : "7175"
	},
	{
		"SuburbName" : "Ngatimoti",
		"Postcode" : "7175"
	},
	{
		"SuburbName" : "Tasman",
		"Postcode" : "7175"
	},
	{
		"SuburbName" : "Upper Moutere",
		"Postcode" : "7175"
	},
	{
		"SuburbName" : "Wakefield",
		"Postcode" : "7175"
	},
	{
		"SuburbName" : "Canvastown",
		"Postcode" : "7178"
	},
	{
		"SuburbName" : "Havelock",
		"Postcode" : "7178"
	},
	{
		"SuburbName" : "Kaituna",
		"Postcode" : "7178"
	},
	{
		"SuburbName" : "Kaiuma Bay",
		"Postcode" : "7178"
	},
	{
		"SuburbName" : "Okaramio",
		"Postcode" : "7178"
	},
	{
		"SuburbName" : "Renwick",
		"Postcode" : "7178"
	},
	{
		"SuburbName" : "Wakamarina",
		"Postcode" : "7178"
	},
	{
		"SuburbName" : "Collingwood",
		"Postcode" : "7182"
	},
	{
		"SuburbName" : "Onekaka",
		"Postcode" : "7182"
	},
	{
		"SuburbName" : "Parapara",
		"Postcode" : "7182"
	},
	{
		"SuburbName" : "Patons Rock",
		"Postcode" : "7182"
	},
	{
		"SuburbName" : "Puramahoi",
		"Postcode" : "7182"
	},
	{
		"SuburbName" : "Rangihaeata",
		"Postcode" : "7182"
	},
	{
		"SuburbName" : "Takaka",
		"Postcode" : "7182"
	},
	{
		"SuburbName" : "Abel Tasman National Park",
		"Postcode" : "7183"
	},
	{
		"SuburbName" : "Awaroa",
		"Postcode" : "7183"
	},
	{
		"SuburbName" : "Awaroa Bay",
		"Postcode" : "7183"
	},
	{
		"SuburbName" : "CLIFTON",
		"Postcode" : "7183"
	},
	{
		"SuburbName" : "East Takaka",
		"Postcode" : "7183"
	},
	{
		"SuburbName" : "Ligar Bay",
		"Postcode" : "7183"
	},
	{
		"SuburbName" : "Motupipi",
		"Postcode" : "7183"
	},
	{
		"SuburbName" : "Pohara",
		"Postcode" : "7183"
	},
	{
		"SuburbName" : "Takaka",
		"Postcode" : "7183"
	},
	{
		"SuburbName" : "Tata Beach",
		"Postcode" : "7183"
	},
	{
		"SuburbName" : "Torrent Bay",
		"Postcode" : "7183"
	},
	{
		"SuburbName" : "Upper Takaka",
		"Postcode" : "7183"
	},
	{
		"SuburbName" : "Uruwhenua",
		"Postcode" : "7183"
	},
	{
		"SuburbName" : "Wainui Bay",
		"Postcode" : "7183"
	},
	{
		"SuburbName" : "Canvastown",
		"Postcode" : "7192"
	},
	{
		"SuburbName" : "Havelock",
		"Postcode" : "7192"
	},
	{
		"SuburbName" : "Pelorus Bridge",
		"Postcode" : "7192"
	},
	{
		"SuburbName" : "Rai Valley",
		"Postcode" : "7192"
	},
	{
		"SuburbName" : "Admiralty Bay",
		"Postcode" : "7193"
	},
	{
		"SuburbName" : "Bulwer",
		"Postcode" : "7193"
	},
	{
		"SuburbName" : "Cissy Bay",
		"Postcode" : "7193"
	},
	{
		"SuburbName" : "Elaine Bay",
		"Postcode" : "7193"
	},
	{
		"SuburbName" : "Fitzroy Bay",
		"Postcode" : "7193"
	},
	{
		"SuburbName" : "French Pass",
		"Postcode" : "7193"
	},
	{
		"SuburbName" : "Marlborough Sounds",
		"Postcode" : "7193"
	},
	{
		"SuburbName" : "Okiwi Bay",
		"Postcode" : "7193"
	},
	{
		"SuburbName" : "Port Ligar",
		"Postcode" : "7193"
	},
	{
		"SuburbName" : "Rangitoto Ki Te Tonga",
		"Postcode" : "7193"
	},
	{
		"SuburbName" : "Squally Cove",
		"Postcode" : "7193"
	},
	{
		"SuburbName" : "Taipare Bay",
		"Postcode" : "7193"
	},
	{
		"SuburbName" : "Te Towaka",
		"Postcode" : "7193"
	},
	{
		"SuburbName" : "Te Towaka Bay",
		"Postcode" : "7193"
	},
	{
		"SuburbName" : "Waitata",
		"Postcode" : "7193"
	},
	{
		"SuburbName" : "Rai Valley",
		"Postcode" : "7194"
	},
	{
		"SuburbName" : "Whangamoa",
		"Postcode" : "7194"
	},
	{
		"SuburbName" : "Duncan Bay",
		"Postcode" : "7195"
	},
	{
		"SuburbName" : "Marlborough Sounds",
		"Postcode" : "7195"
	},
	{
		"SuburbName" : "Rai Valley",
		"Postcode" : "7195"
	},
	{
		"SuburbName" : "Tennyson Inlet",
		"Postcode" : "7195"
	},
	{
		"SuburbName" : "Brooklyn",
		"Postcode" : "7196"
	},
	{
		"SuburbName" : "Lower Moutere",
		"Postcode" : "7196"
	},
	{
		"SuburbName" : "Motueka",
		"Postcode" : "7196"
	},
	{
		"SuburbName" : "Motueka Valley",
		"Postcode" : "7196"
	},
	{
		"SuburbName" : "Ngatimoti",
		"Postcode" : "7196"
	},
	{
		"SuburbName" : "Tapawera",
		"Postcode" : "7196"
	},
	{
		"SuburbName" : "Thorpe",
		"Postcode" : "7196"
	},
	{
		"SuburbName" : "Upper Moutere",
		"Postcode" : "7196"
	},
	{
		"SuburbName" : "Wakefield",
		"Postcode" : "7196"
	},
	{
		"SuburbName" : "Woodstock",
		"Postcode" : "7196"
	},
	{
		"SuburbName" : "Kaiteriteri",
		"Postcode" : "7197"
	},
	{
		"SuburbName" : "Kaiteriteri",
		"Postcode" : "7197"
	},
	{
		"SuburbName" : "Marahau",
		"Postcode" : "7197"
	},
	{
		"SuburbName" : "Motueka",
		"Postcode" : "7197"
	},
	{
		"SuburbName" : "Riwaka",
		"Postcode" : "7197"
	},
	{
		"SuburbName" : "Brooklyn",
		"Postcode" : "7198"
	},
	{
		"SuburbName" : "Motueka",
		"Postcode" : "7198"
	},
	{
		"SuburbName" : "Riwaka",
		"Postcode" : "7198"
	},
	{
		"SuburbName" : "Takaka Hill",
		"Postcode" : "7198"
	},
	{
		"SuburbName" : "Blenheim",
		"Postcode" : "7271"
	},
	{
		"SuburbName" : "Renwick",
		"Postcode" : "7271"
	},
	{
		"SuburbName" : "Springlands",
		"Postcode" : "7271"
	},
	{
		"SuburbName" : "Waihopai Valley",
		"Postcode" : "7271"
	},
	{
		"SuburbName" : "Wairau Valley",
		"Postcode" : "7271"
	},
	{
		"SuburbName" : "Woodbourne",
		"Postcode" : "7271"
	},
	{
		"SuburbName" : "Yelverton",
		"Postcode" : "7271"
	},
	{
		"SuburbName" : "Blenheim",
		"Postcode" : "7272"
	},
	{
		"SuburbName" : "Burleigh",
		"Postcode" : "7272"
	},
	{
		"SuburbName" : "Fairhall",
		"Postcode" : "7272"
	},
	{
		"SuburbName" : "Hawkesbury",
		"Postcode" : "7272"
	},
	{
		"SuburbName" : "Omaka",
		"Postcode" : "7272"
	},
	{
		"SuburbName" : "Rapaura",
		"Postcode" : "7272"
	},
	{
		"SuburbName" : "Renwick",
		"Postcode" : "7272"
	},
	{
		"SuburbName" : "Springlands",
		"Postcode" : "7272"
	},
	{
		"SuburbName" : "Taylor Pass",
		"Postcode" : "7272"
	},
	{
		"SuburbName" : "Thomsons Ford",
		"Postcode" : "7272"
	},
	{
		"SuburbName" : "Wither Hills",
		"Postcode" : "7272"
	},
	{
		"SuburbName" : "Blenheim",
		"Postcode" : "7273"
	},
	{
		"SuburbName" : "Dillons Point",
		"Postcode" : "7273"
	},
	{
		"SuburbName" : "Grovetown",
		"Postcode" : "7273"
	},
	{
		"SuburbName" : "Islington",
		"Postcode" : "7273"
	},
	{
		"SuburbName" : "Kaituna",
		"Postcode" : "7273"
	},
	{
		"SuburbName" : "Koromiko",
		"Postcode" : "7273"
	},
	{
		"SuburbName" : "Mayfield",
		"Postcode" : "7273"
	},
	{
		"SuburbName" : "Mount Pleasant",
		"Postcode" : "7273"
	},
	{
		"SuburbName" : "Mt Pleasant",
		"Postcode" : "7273"
	},
	{
		"SuburbName" : "Picton",
		"Postcode" : "7273"
	},
	{
		"SuburbName" : "Rapaura",
		"Postcode" : "7273"
	},
	{
		"SuburbName" : "Rarangi",
		"Postcode" : "7273"
	},
	{
		"SuburbName" : "Renwick",
		"Postcode" : "7273"
	},
	{
		"SuburbName" : "Riverlands",
		"Postcode" : "7273"
	},
	{
		"SuburbName" : "Robin Hood Bay",
		"Postcode" : "7273"
	},
	{
		"SuburbName" : "Spring Creek",
		"Postcode" : "7273"
	},
	{
		"SuburbName" : "Thomsons Ford",
		"Postcode" : "7273"
	},
	{
		"SuburbName" : "Tuamarina",
		"Postcode" : "7273"
	},
	{
		"SuburbName" : "Awatere Valley",
		"Postcode" : "7274"
	},
	{
		"SuburbName" : "Blenheim",
		"Postcode" : "7274"
	},
	{
		"SuburbName" : "Clarence",
		"Postcode" : "7274"
	},
	{
		"SuburbName" : "Lower Dashwood",
		"Postcode" : "7274"
	},
	{
		"SuburbName" : "Omaka",
		"Postcode" : "7274"
	},
	{
		"SuburbName" : "Redwood Pass",
		"Postcode" : "7274"
	},
	{
		"SuburbName" : "Redwoodtown",
		"Postcode" : "7274"
	},
	{
		"SuburbName" : "Riverlands",
		"Postcode" : "7274"
	},
	{
		"SuburbName" : "Seddon",
		"Postcode" : "7274"
	},
	{
		"SuburbName" : "Taylor Pass",
		"Postcode" : "7274"
	},
	{
		"SuburbName" : "Wither Hills",
		"Postcode" : "7274"
	},
	{
		"SuburbName" : "Blenheim",
		"Postcode" : "7275"
	},
	{
		"SuburbName" : "Kaituna",
		"Postcode" : "7275"
	},
	{
		"SuburbName" : "Mount Richmond Forest Park",
		"Postcode" : "7275"
	},
	{
		"SuburbName" : "MOUNT RICHMOND FOREST PARK",
		"Postcode" : "7275"
	},
	{
		"SuburbName" : "North Bank",
		"Postcode" : "7275"
	},
	{
		"SuburbName" : "Onamalutu",
		"Postcode" : "7275"
	},
	{
		"SuburbName" : "Renwick",
		"Postcode" : "7275"
	},
	{
		"SuburbName" : "Wairau Valley",
		"Postcode" : "7275"
	},
	{
		"SuburbName" : "Blenheim",
		"Postcode" : "7276"
	},
	{
		"SuburbName" : "Hawkesbury",
		"Postcode" : "7276"
	},
	{
		"SuburbName" : "Renwick",
		"Postcode" : "7276"
	},
	{
		"SuburbName" : "Waihopai Valley",
		"Postcode" : "7276"
	},
	{
		"SuburbName" : "Anakiwa",
		"Postcode" : "7281"
	},
	{
		"SuburbName" : "Picton",
		"Postcode" : "7281"
	},
	{
		"SuburbName" : "Linkwater",
		"Postcode" : "7281"
	},
	{
		"SuburbName" : "Momorangi Bay",
		"Postcode" : "7281"
	},
	{
		"SuburbName" : "Ngakuta Bay",
		"Postcode" : "7281"
	},
	{
		"SuburbName" : "Picton",
		"Postcode" : "7281"
	},
	{
		"SuburbName" : "Port Underwood",
		"Postcode" : "7281"
	},
	{
		"SuburbName" : "Shakespeare Bay",
		"Postcode" : "7281"
	},
	{
		"SuburbName" : "Waikawa",
		"Postcode" : "7281"
	},
	{
		"SuburbName" : "Whatamango Bay",
		"Postcode" : "7281"
	},
	{
		"SuburbName" : "Whenuanui Bay",
		"Postcode" : "7281"
	},
	{
		"SuburbName" : "Black Rock",
		"Postcode" : "7282"
	},
	{
		"SuburbName" : "Broughton Bay",
		"Postcode" : "7282"
	},
	{
		"SuburbName" : "Clova Bay",
		"Postcode" : "7282"
	},
	{
		"SuburbName" : "Crail Bay",
		"Postcode" : "7282"
	},
	{
		"SuburbName" : "Goulter Bay",
		"Postcode" : "7282"
	},
	{
		"SuburbName" : "Kenepuru Head",
		"Postcode" : "7282"
	},
	{
		"SuburbName" : "Linkwater",
		"Postcode" : "7282"
	},
	{
		"SuburbName" : "Mahau Sound",
		"Postcode" : "7282"
	},
	{
		"SuburbName" : "Manaroa",
		"Postcode" : "7282"
	},
	{
		"SuburbName" : "Marlborough Sounds",
		"Postcode" : "7282"
	},
	{
		"SuburbName" : "Mills Bay",
		"Postcode" : "7282"
	},
	{
		"SuburbName" : "Moetapu Bay",
		"Postcode" : "7282"
	},
	{
		"SuburbName" : "Nopera",
		"Postcode" : "7282"
	},
	{
		"SuburbName" : "Ohauparuparu Bay",
		"Postcode" : "7282"
	},
	{
		"SuburbName" : "Onahau Bay",
		"Postcode" : "7282"
	},
	{
		"SuburbName" : "Picton",
		"Postcode" : "7282"
	},
	{
		"SuburbName" : "Portage",
		"Postcode" : "7282"
	},
	{
		"SuburbName" : "Punga Cove",
		"Postcode" : "7282"
	},
	{
		"SuburbName" : "MARLBOROUGH",
		"Postcode" : "7282"
	},
	{
		"SuburbName" : "Saint Omer",
		"Postcode" : "7282"
	},
	{
		"SuburbName" : "St Omer",
		"Postcode" : "7282"
	},
	{
		"SuburbName" : "Te Mahia",
		"Postcode" : "7282"
	},
	{
		"SuburbName" : "Totaranui",
		"Postcode" : "7282"
	},
	{
		"SuburbName" : "Waitaria Bay",
		"Postcode" : "7282"
	},
	{
		"SuburbName" : "Anakoha",
		"Postcode" : "7284"
	},
	{
		"SuburbName" : "Beatrix Bay",
		"Postcode" : "7284"
	},
	{
		"SuburbName" : "Marlborough Sounds",
		"Postcode" : "7284"
	},
	{
		"SuburbName" : "Picton",
		"Postcode" : "7284"
	},
	{
		"SuburbName" : "Titirangi",
		"Postcode" : "7284"
	},
	{
		"SuburbName" : "Blind River",
		"Postcode" : "7285"
	},
	{
		"SuburbName" : "Lake Grassmere",
		"Postcode" : "7285"
	},
	{
		"SuburbName" : "Seddon",
		"Postcode" : "7285"
	},
	{
		"SuburbName" : "Ward",
		"Postcode" : "7285"
	},
	{
		"SuburbName" : "Kaikoura Peninsula",
		"Postcode" : "7300"
	},
	{
		"SuburbName" : "Seaward Valley",
		"Postcode" : "7371"
	},
	{
		"SuburbName" : "Clarence",
		"Postcode" : "7371"
	},
	{
		"SuburbName" : "Half Moon Bay",
		"Postcode" : "7371"
	},
	{
		"SuburbName" : "Hapuku",
		"Postcode" : "7371"
	},
	{
		"SuburbName" : "Kaikoura",
		"Postcode" : "7371"
	},
	{
		"SuburbName" : "Kaikoura Flat",
		"Postcode" : "7371"
	},
	{
		"SuburbName" : "Mangamaunu",
		"Postcode" : "7371"
	},
	{
		"SuburbName" : "Puhi Puhi",
		"Postcode" : "7371"
	},
	{
		"SuburbName" : "Seaward Valley",
		"Postcode" : "7371"
	},
	{
		"SuburbName" : "Waipapa Bay",
		"Postcode" : "7371"
	},
	{
		"SuburbName" : "Inland Road",
		"Postcode" : "7373"
	},
	{
		"SuburbName" : "Kaikoura",
		"Postcode" : "7373"
	},
	{
		"SuburbName" : "Lyford",
		"Postcode" : "7373"
	},
	{
		"SuburbName" : "Peketa",
		"Postcode" : "7373"
	},
	{
		"SuburbName" : "Stag And Spey",
		"Postcode" : "7373"
	},
	{
		"SuburbName" : "Waiau",
		"Postcode" : "7373"
	},
	{
		"SuburbName" : "Goose Bay",
		"Postcode" : "7374"
	},
	{
		"SuburbName" : "Kaikoura",
		"Postcode" : "7374"
	},
	{
		"SuburbName" : "Oaro",
		"Postcode" : "7374"
	},
	{
		"SuburbName" : "Peketa",
		"Postcode" : "7374"
	},
	{
		"SuburbName" : "Culverden",
		"Postcode" : "7379"
	},
	{
		"SuburbName" : "Culverden",
		"Postcode" : "7379"
	},
	{
		"SuburbName" : "Rotherham",
		"Postcode" : "7379"
	},
	{
		"SuburbName" : "Cheviot",
		"Postcode" : "7381"
	},
	{
		"SuburbName" : "Lowry Hills Range",
		"Postcode" : "7381"
	},
	{
		"SuburbName" : "Spotswood",
		"Postcode" : "7381"
	},
	{
		"SuburbName" : "Cheviot",
		"Postcode" : "7382"
	},
	{
		"SuburbName" : "Domett",
		"Postcode" : "7382"
	},
	{
		"SuburbName" : "Gore Bay",
		"Postcode" : "7382"
	},
	{
		"SuburbName" : "Greta Valley",
		"Postcode" : "7382"
	},
	{
		"SuburbName" : "Lowry Hills Range",
		"Postcode" : "7382"
	},
	{
		"SuburbName" : "Cheviot",
		"Postcode" : "7383"
	},
	{
		"SuburbName" : "Motunau",
		"Postcode" : "7383"
	},
	{
		"SuburbName" : "Domett",
		"Postcode" : "7383"
	},
	{
		"SuburbName" : "Gore Bay",
		"Postcode" : "7383"
	},
	{
		"SuburbName" : "Greta Valley",
		"Postcode" : "7383"
	},
	{
		"SuburbName" : "Motunau",
		"Postcode" : "7383"
	},
	{
		"SuburbName" : "Cheviot",
		"Postcode" : "7384"
	},
	{
		"SuburbName" : "Conway Flat",
		"Postcode" : "7384"
	},
	{
		"SuburbName" : "Ferniehurst",
		"Postcode" : "7384"
	},
	{
		"SuburbName" : "Hawkswood",
		"Postcode" : "7384"
	},
	{
		"SuburbName" : "Hundalee",
		"Postcode" : "7384"
	},
	{
		"SuburbName" : "Kaikoura",
		"Postcode" : "7384"
	},
	{
		"SuburbName" : "Oaro",
		"Postcode" : "7384"
	},
	{
		"SuburbName" : "Spotswood",
		"Postcode" : "7384"
	},
	{
		"SuburbName" : "Waiau",
		"Postcode" : "7384"
	},
	{
		"SuburbName" : "Broomfield",
		"Postcode" : "7385"
	},
	{
		"SuburbName" : "Hawarden",
		"Postcode" : "7385"
	},
	{
		"SuburbName" : "Hurunui",
		"Postcode" : "7385"
	},
	{
		"SuburbName" : "Lake Sumner National Park",
		"Postcode" : "7385"
	},
	{
		"SuburbName" : "MacDonald Downs",
		"Postcode" : "7385"
	},
	{
		"SuburbName" : "Masons Flat",
		"Postcode" : "7385"
	},
	{
		"SuburbName" : "Medbury",
		"Postcode" : "7385"
	},
	{
		"SuburbName" : "Pyramid Valley",
		"Postcode" : "7385"
	},
	{
		"SuburbName" : "The Peaks",
		"Postcode" : "7385"
	},
	{
		"SuburbName" : "Virginia",
		"Postcode" : "7385"
	},
	{
		"SuburbName" : "Waikari",
		"Postcode" : "7385"
	},
	{
		"SuburbName" : "Greta Valley",
		"Postcode" : "7387"
	},
	{
		"SuburbName" : "GRETA VALLEY",
		"Postcode" : "7387"
	},
	{
		"SuburbName" : "Motunau",
		"Postcode" : "7387"
	},
	{
		"SuburbName" : "Omihi",
		"Postcode" : "7387"
	},
	{
		"SuburbName" : "Teviotdale",
		"Postcode" : "7387"
	},
	{
		"SuburbName" : "Waipara",
		"Postcode" : "7387"
	},
	{
		"SuburbName" : "Amuri Plain",
		"Postcode" : "7391"
	},
	{
		"SuburbName" : "Culverden",
		"Postcode" : "7391"
	},
	{
		"SuburbName" : "Mount Culverden",
		"Postcode" : "7391"
	},
	{
		"SuburbName" : "MOUNT CULVERDEN",
		"Postcode" : "7391"
	},
	{
		"SuburbName" : "Rotherham",
		"Postcode" : "7391"
	},
	{
		"SuburbName" : "Balmoral",
		"Postcode" : "7392"
	},
	{
		"SuburbName" : "Culverden",
		"Postcode" : "7392"
	},
	{
		"SuburbName" : "Hanmer Forest Park",
		"Postcode" : "7392"
	},
	{
		"SuburbName" : "Hanmer Plain",
		"Postcode" : "7392"
	},
	{
		"SuburbName" : "Hanmer Springs",
		"Postcode" : "7392"
	},
	{
		"SuburbName" : "Island Hills",
		"Postcode" : "7392"
	},
	{
		"SuburbName" : "Lewis Pass",
		"Postcode" : "7392"
	},
	{
		"SuburbName" : "Mount Culverden",
		"Postcode" : "7392"
	},
	{
		"SuburbName" : "MOUNT CULVERDEN",
		"Postcode" : "7392"
	},
	{
		"SuburbName" : "Poplars Range",
		"Postcode" : "7392"
	},
	{
		"SuburbName" : "Lewis Pass",
		"Postcode" : "7392"
	},
	{
		"SuburbName" : "Tekoa Range",
		"Postcode" : "7392"
	},
	{
		"SuburbName" : "Emu Plain",
		"Postcode" : "7395"
	},
	{
		"SuburbName" : "Hanmer Forest",
		"Postcode" : "7395"
	},
	{
		"SuburbName" : "Hanmer Springs",
		"Postcode" : "7395"
	},
	{
		"SuburbName" : "Kaikoura",
		"Postcode" : "7395"
	},
	{
		"SuburbName" : "Leslie Hills",
		"Postcode" : "7395"
	},
	{
		"SuburbName" : "Lyford",
		"Postcode" : "7395"
	},
	{
		"SuburbName" : "Saint James Range",
		"Postcode" : "7395"
	},
	{
		"SuburbName" : "St James Range",
		"Postcode" : "7395"
	},
	{
		"SuburbName" : "Stag And Spey",
		"Postcode" : "7395"
	},
	{
		"SuburbName" : "Waiau",
		"Postcode" : "7395"
	},
	{
		"SuburbName" : "Cust",
		"Postcode" : "7471"
	},
	{
		"SuburbName" : "Fernside",
		"Postcode" : "7471"
	},
	{
		"SuburbName" : "Okuku",
		"Postcode" : "7471"
	},
	{
		"SuburbName" : "Oxford",
		"Postcode" : "7471"
	},
	{
		"SuburbName" : "Rangiora",
		"Postcode" : "7471"
	},
	{
		"SuburbName" : "Starvation Hill",
		"Postcode" : "7471"
	},
	{
		"SuburbName" : "Swannanoa",
		"Postcode" : "7471"
	},
	{
		"SuburbName" : "West Eyreton",
		"Postcode" : "7471"
	},
	{
		"SuburbName" : "Amberley",
		"Postcode" : "7472"
	},
	{
		"SuburbName" : "Ashley",
		"Postcode" : "7472"
	},
	{
		"SuburbName" : "Ashley Forest",
		"Postcode" : "7472"
	},
	{
		"SuburbName" : "Balcairn",
		"Postcode" : "7472"
	},
	{
		"SuburbName" : "Leithfield",
		"Postcode" : "7472"
	},
	{
		"SuburbName" : "Loburn",
		"Postcode" : "7472"
	},
	{
		"SuburbName" : "Okuku Range",
		"Postcode" : "7472"
	},
	{
		"SuburbName" : "Rangiora",
		"Postcode" : "7472"
	},
	{
		"SuburbName" : "Sefton",
		"Postcode" : "7472"
	},
	{
		"SuburbName" : "Ashley",
		"Postcode" : "7473"
	},
	{
		"SuburbName" : "Glentui",
		"Postcode" : "7473"
	},
	{
		"SuburbName" : "Loburn",
		"Postcode" : "7473"
	},
	{
		"SuburbName" : "Okuku",
		"Postcode" : "7473"
	},
	{
		"SuburbName" : "Oxford",
		"Postcode" : "7473"
	},
	{
		"SuburbName" : "Rangiora",
		"Postcode" : "7473"
	},
	{
		"SuburbName" : "Waikuku",
		"Postcode" : "7473"
	},
	{
		"SuburbName" : "Waikuku Beach",
		"Postcode" : "7473"
	},
	{
		"SuburbName" : "Waikuku",
		"Postcode" : "7473"
	},
	{
		"SuburbName" : "Cust",
		"Postcode" : "7475"
	},
	{
		"SuburbName" : "Eyrewell Forest",
		"Postcode" : "7475"
	},
	{
		"SuburbName" : "Fernside",
		"Postcode" : "7475"
	},
	{
		"SuburbName" : "Ohoka",
		"Postcode" : "7475"
	},
	{
		"SuburbName" : "Rangiora",
		"Postcode" : "7475"
	},
	{
		"SuburbName" : "Swannanoa",
		"Postcode" : "7475"
	},
	{
		"SuburbName" : "West Eyreton",
		"Postcode" : "7475"
	},
	{
		"SuburbName" : "Eyrewell",
		"Postcode" : "7476"
	},
	{
		"SuburbName" : "Eyrewell Forest",
		"Postcode" : "7476"
	},
	{
		"SuburbName" : "Oxford",
		"Postcode" : "7476"
	},
	{
		"SuburbName" : "Rangiora",
		"Postcode" : "7476"
	},
	{
		"SuburbName" : "Starvation Hill",
		"Postcode" : "7476"
	},
	{
		"SuburbName" : "Swannanoa",
		"Postcode" : "7476"
	},
	{
		"SuburbName" : "West Eyreton",
		"Postcode" : "7476"
	},
	{
		"SuburbName" : "Amberley",
		"Postcode" : "7477"
	},
	{
		"SuburbName" : "Ashley",
		"Postcode" : "7477"
	},
	{
		"SuburbName" : "Ashley Forest",
		"Postcode" : "7477"
	},
	{
		"SuburbName" : "Balcairn",
		"Postcode" : "7477"
	},
	{
		"SuburbName" : "Leithfield",
		"Postcode" : "7477"
	},
	{
		"SuburbName" : "Loburn",
		"Postcode" : "7477"
	},
	{
		"SuburbName" : "Rangiora",
		"Postcode" : "7477"
	},
	{
		"SuburbName" : "Sefton",
		"Postcode" : "7477"
	},
	{
		"SuburbName" : "Amberley",
		"Postcode" : "7481"
	},
	{
		"SuburbName" : "Balcairn",
		"Postcode" : "7481"
	},
	{
		"SuburbName" : "Broomfield",
		"Postcode" : "7481"
	},
	{
		"SuburbName" : "Leithfield",
		"Postcode" : "7481"
	},
	{
		"SuburbName" : "Sefton",
		"Postcode" : "7481"
	},
	{
		"SuburbName" : "Teviotdale",
		"Postcode" : "7481"
	},
	{
		"SuburbName" : "Waipara",
		"Postcode" : "7481"
	},
	{
		"SuburbName" : "Amberley",
		"Postcode" : "7482"
	},
	{
		"SuburbName" : "Balcairn",
		"Postcode" : "7482"
	},
	{
		"SuburbName" : "Broomfield",
		"Postcode" : "7482"
	},
	{
		"SuburbName" : "Glasnevin",
		"Postcode" : "7482"
	},
	{
		"SuburbName" : "Amberley",
		"Postcode" : "7483"
	},
	{
		"SuburbName" : "Glasnevin",
		"Postcode" : "7483"
	},
	{
		"SuburbName" : "Greta Valley",
		"Postcode" : "7483"
	},
	{
		"SuburbName" : "Motunau",
		"Postcode" : "7483"
	},
	{
		"SuburbName" : "Omihi",
		"Postcode" : "7483"
	},
	{
		"SuburbName" : "Scargill",
		"Postcode" : "7483"
	},
	{
		"SuburbName" : "Teviotdale",
		"Postcode" : "7483"
	},
	{
		"SuburbName" : "Waikari",
		"Postcode" : "7483"
	},
	{
		"SuburbName" : "Waipara",
		"Postcode" : "7483"
	},
	{
		"SuburbName" : "Weka Pass",
		"Postcode" : "7483"
	},
	{
		"SuburbName" : "Hawarden",
		"Postcode" : "7491"
	},
	{
		"SuburbName" : "Pyramid Valley",
		"Postcode" : "7491"
	},
	{
		"SuburbName" : "Waikari",
		"Postcode" : "7491"
	},
	{
		"SuburbName" : "Waipara",
		"Postcode" : "7491"
	},
	{
		"SuburbName" : "Weka Pass",
		"Postcode" : "7491"
	},
	{
		"SuburbName" : "Burnt Hill",
		"Postcode" : "7495"
	},
	{
		"SuburbName" : "Coopers Creek",
		"Postcode" : "7495"
	},
	{
		"SuburbName" : "Cust",
		"Postcode" : "7495"
	},
	{
		"SuburbName" : "Eyrewell",
		"Postcode" : "7495"
	},
	{
		"SuburbName" : "Eyrewell Forest",
		"Postcode" : "7495"
	},
	{
		"SuburbName" : "Glentui",
		"Postcode" : "7495"
	},
	{
		"SuburbName" : "Lees Valley",
		"Postcode" : "7495"
	},
	{
		"SuburbName" : "Okuku",
		"Postcode" : "7495"
	},
	{
		"SuburbName" : "Oxford",
		"Postcode" : "7495"
	},
	{
		"SuburbName" : "Rangiora",
		"Postcode" : "7495"
	},
	{
		"SuburbName" : "Starvation Hill",
		"Postcode" : "7495"
	},
	{
		"SuburbName" : "View Hill",
		"Postcode" : "7495"
	},
	{
		"SuburbName" : "West Eyreton",
		"Postcode" : "7495"
	},
	{
		"SuburbName" : "Charing Cross",
		"Postcode" : "7571"
	},
	{
		"SuburbName" : "Coalgate",
		"Postcode" : "7571"
	},
	{
		"SuburbName" : "Darfield",
		"Postcode" : "7571"
	},
	{
		"SuburbName" : "Glentunnel",
		"Postcode" : "7571"
	},
	{
		"SuburbName" : "Kirwee",
		"Postcode" : "7571"
	},
	{
		"SuburbName" : "Sheffield",
		"Postcode" : "7571"
	},
	{
		"SuburbName" : "Coalgate",
		"Postcode" : "7572"
	},
	{
		"SuburbName" : "Darfield",
		"Postcode" : "7572"
	},
	{
		"SuburbName" : "Glentunnel",
		"Postcode" : "7572"
	},
	{
		"SuburbName" : "Hororata",
		"Postcode" : "7572"
	},
	{
		"SuburbName" : "Lake Coleridge",
		"Postcode" : "7572"
	},
	{
		"SuburbName" : "Lake Coleridge",
		"Postcode" : "7572"
	},
	{
		"SuburbName" : "Snowdon",
		"Postcode" : "7572"
	},
	{
		"SuburbName" : "Windwhistle",
		"Postcode" : "7572"
	},
	{
		"SuburbName" : "Arthur's Pass",
		"Postcode" : "7580"
	},
	{
		"SuburbName" : "Arthurs Pass",
		"Postcode" : "7580"
	},
	{
		"SuburbName" : "Castle Hill",
		"Postcode" : "7580"
	},
	{
		"SuburbName" : "Glentunnel",
		"Postcode" : "7580"
	},
	{
		"SuburbName" : "Lake Pearson",
		"Postcode" : "7580"
	},
	{
		"SuburbName" : "Malvern Hills",
		"Postcode" : "7580"
	},
	{
		"SuburbName" : "Sheffield",
		"Postcode" : "7580"
	},
	{
		"SuburbName" : "Springfield",
		"Postcode" : "7580"
	},
	{
		"SuburbName" : "Akaroa",
		"Postcode" : "7581"
	},
	{
		"SuburbName" : "Duvauchelle",
		"Postcode" : "7581"
	},
	{
		"SuburbName" : "Robinsons Bay",
		"Postcode" : "7581"
	},
	{
		"SuburbName" : "Takamatua",
		"Postcode" : "7581"
	},
	{
		"SuburbName" : "Akaroa",
		"Postcode" : "7582"
	},
	{
		"SuburbName" : "Duvauchelle",
		"Postcode" : "7582"
	},
	{
		"SuburbName" : "French Farm",
		"Postcode" : "7582"
	},
	{
		"SuburbName" : "Little River",
		"Postcode" : "7582"
	},
	{
		"SuburbName" : "Peraki",
		"Postcode" : "7582"
	},
	{
		"SuburbName" : "Wainui",
		"Postcode" : "7582"
	},
	{
		"SuburbName" : "Akaroa",
		"Postcode" : "7583"
	},
	{
		"SuburbName" : "Duvauchelle",
		"Postcode" : "7583"
	},
	{
		"SuburbName" : "Goughs Bay",
		"Postcode" : "7583"
	},
	{
		"SuburbName" : "Hickory Bay",
		"Postcode" : "7583"
	},
	{
		"SuburbName" : "Le Bons Bay",
		"Postcode" : "7583"
	},
	{
		"SuburbName" : "Little Akaloa",
		"Postcode" : "7583"
	},
	{
		"SuburbName" : "Long Bay",
		"Postcode" : "7583"
	},
	{
		"SuburbName" : "Okains Bay",
		"Postcode" : "7583"
	},
	{
		"SuburbName" : "OKAINS BAY",
		"Postcode" : "7583"
	},
	{
		"SuburbName" : "Pigeon Bay",
		"Postcode" : "7583"
	},
	{
		"SuburbName" : "Pigeon Bay",
		"Postcode" : "7583"
	},
	{
		"SuburbName" : "Takamatua",
		"Postcode" : "7583"
	},
	{
		"SuburbName" : "Ataahua",
		"Postcode" : "7591"
	},
	{
		"SuburbName" : "Birdlings Flat",
		"Postcode" : "7591"
	},
	{
		"SuburbName" : "Duvauchelle",
		"Postcode" : "7591"
	},
	{
		"SuburbName" : "Little River",
		"Postcode" : "7591"
	},
	{
		"SuburbName" : "Okuti Valley",
		"Postcode" : "7591"
	},
	{
		"SuburbName" : "Peraki",
		"Postcode" : "7591"
	},
	{
		"SuburbName" : "Prices Valley",
		"Postcode" : "7591"
	},
	{
		"SuburbName" : "Tai Tapu",
		"Postcode" : "7591"
	},
	{
		"SuburbName" : "Te Oka",
		"Postcode" : "7591"
	},
	{
		"SuburbName" : "Belfast",
		"Postcode" : "7670"
	},
	{
		"SuburbName" : "Christchurch",
		"Postcode" : "7670"
	},
	{
		"SuburbName" : "Coutts Island",
		"Postcode" : "7670"
	},
	{
		"SuburbName" : "Burnham",
		"Postcode" : "7671"
	},
	{
		"SuburbName" : "Charing Cross",
		"Postcode" : "7671"
	},
	{
		"SuburbName" : "Christchurch",
		"Postcode" : "7671"
	},
	{
		"SuburbName" : "Darfield",
		"Postcode" : "7671"
	},
	{
		"SuburbName" : "Greendale",
		"Postcode" : "7671"
	},
	{
		"SuburbName" : "Hororata",
		"Postcode" : "7671"
	},
	{
		"SuburbName" : "Kirwee",
		"Postcode" : "7671"
	},
	{
		"SuburbName" : "Rolleston",
		"Postcode" : "7671"
	},
	{
		"SuburbName" : "Templeton",
		"Postcode" : "7671"
	},
	{
		"SuburbName" : "West Melton",
		"Postcode" : "7671"
	},
	{
		"SuburbName" : "Ataahua",
		"Postcode" : "7672"
	},
	{
		"SuburbName" : "Christchurch",
		"Postcode" : "7672"
	},
	{
		"SuburbName" : "Gebbies Valley",
		"Postcode" : "7672"
	},
	{
		"SuburbName" : "Governors Bay",
		"Postcode" : "7672"
	},
	{
		"SuburbName" : "Halswell",
		"Postcode" : "7672"
	},
	{
		"SuburbName" : "Kennedys Bush",
		"Postcode" : "7672"
	},
	{
		"SuburbName" : "Lansdowne",
		"Postcode" : "7672"
	},
	{
		"SuburbName" : "Lincoln",
		"Postcode" : "7672"
	},
	{
		"SuburbName" : "Motukarara",
		"Postcode" : "7672"
	},
	{
		"SuburbName" : "Tai Tapu",
		"Postcode" : "7672"
	},
	{
		"SuburbName" : "Taitapu",
		"Postcode" : "7672"
	},
	{
		"SuburbName" : "Teddington",
		"Postcode" : "7672"
	},
	{
		"SuburbName" : "Coalgate",
		"Postcode" : "7673"
	},
	{
		"SuburbName" : "Darfield",
		"Postcode" : "7673"
	},
	{
		"SuburbName" : "Glentunnel",
		"Postcode" : "7673"
	},
	{
		"SuburbName" : "Hororata",
		"Postcode" : "7673"
	},
	{
		"SuburbName" : "Malvern Hills",
		"Postcode" : "7673"
	},
	{
		"SuburbName" : "Windwhistle",
		"Postcode" : "7673"
	},
	{
		"SuburbName" : "Christchurch",
		"Postcode" : "7674"
	},
	{
		"SuburbName" : "Halswell",
		"Postcode" : "7674"
	},
	{
		"SuburbName" : "Lincoln",
		"Postcode" : "7674"
	},
	{
		"SuburbName" : "Lincoln University",
		"Postcode" : "7674"
	},
	{
		"SuburbName" : "Motukarara",
		"Postcode" : "7674"
	},
	{
		"SuburbName" : "Prebbleton",
		"Postcode" : "7674"
	},
	{
		"SuburbName" : "Rolleston",
		"Postcode" : "7674"
	},
	{
		"SuburbName" : "Springston",
		"Postcode" : "7674"
	},
	{
		"SuburbName" : "Tai Tapu",
		"Postcode" : "7674"
	},
	{
		"SuburbName" : "Taitapu",
		"Postcode" : "7674"
	},
	{
		"SuburbName" : "Christchurch",
		"Postcode" : "7675"
	},
	{
		"SuburbName" : "Darfield",
		"Postcode" : "7675"
	},
	{
		"SuburbName" : "Kirwee",
		"Postcode" : "7675"
	},
	{
		"SuburbName" : "Rolleston",
		"Postcode" : "7675"
	},
	{
		"SuburbName" : "West Melton",
		"Postcode" : "7675"
	},
	{
		"SuburbName" : "Broomfield",
		"Postcode" : "7676"
	},
	{
		"SuburbName" : "Christchurch",
		"Postcode" : "7676"
	},
	{
		"SuburbName" : "Christchurch Airport",
		"Postcode" : "7676"
	},
	{
		"SuburbName" : "Halswell",
		"Postcode" : "7676"
	},
	{
		"SuburbName" : "Hornby South",
		"Postcode" : "7676"
	},
	{
		"SuburbName" : "Islington",
		"Postcode" : "7676"
	},
	{
		"SuburbName" : "Lincoln",
		"Postcode" : "7676"
	},
	{
		"SuburbName" : "McLeans Island",
		"Postcode" : "7676"
	},
	{
		"SuburbName" : "Prebbleton",
		"Postcode" : "7676"
	},
	{
		"SuburbName" : "Rolleston",
		"Postcode" : "7676"
	},
	{
		"SuburbName" : "Templeton",
		"Postcode" : "7676"
	},
	{
		"SuburbName" : "West Melton",
		"Postcode" : "7676"
	},
	{
		"SuburbName" : "Yaldhurst",
		"Postcode" : "7676"
	},
	{
		"SuburbName" : "Burnham",
		"Postcode" : "7677"
	},
	{
		"SuburbName" : "Charing Cross",
		"Postcode" : "7677"
	},
	{
		"SuburbName" : "Christchurch",
		"Postcode" : "7677"
	},
	{
		"SuburbName" : "Darfield",
		"Postcode" : "7677"
	},
	{
		"SuburbName" : "Greendale",
		"Postcode" : "7677"
	},
	{
		"SuburbName" : "Rolleston",
		"Postcode" : "7677"
	},
	{
		"SuburbName" : "Springston",
		"Postcode" : "7677"
	},
	{
		"SuburbName" : "Templeton",
		"Postcode" : "7677"
	},
	{
		"SuburbName" : "Burnham",
		"Postcode" : "7678"
	},
	{
		"SuburbName" : "Christchurch",
		"Postcode" : "7678"
	},
	{
		"SuburbName" : "Lincoln",
		"Postcode" : "7678"
	},
	{
		"SuburbName" : "Rolleston",
		"Postcode" : "7678"
	},
	{
		"SuburbName" : "Springston",
		"Postcode" : "7678"
	},
	{
		"SuburbName" : "Templeton",
		"Postcode" : "7678"
	},
	{
		"SuburbName" : "Yaldhurst",
		"Postcode" : "7678"
	},
	{
		"SuburbName" : "Sheffield",
		"Postcode" : "7681"
	},
	{
		"SuburbName" : "Springfield",
		"Postcode" : "7681"
	},
	{
		"SuburbName" : "Bankside",
		"Postcode" : "7682"
	},
	{
		"SuburbName" : "Doyleston",
		"Postcode" : "7682"
	},
	{
		"SuburbName" : "Dunsandel",
		"Postcode" : "7682"
	},
	{
		"SuburbName" : "Hororata",
		"Postcode" : "7682"
	},
	{
		"SuburbName" : "Leeston",
		"Postcode" : "7682"
	},
	{
		"SuburbName" : "Southbridge",
		"Postcode" : "7682"
	},
	{
		"SuburbName" : "Doyleston",
		"Postcode" : "7683"
	},
	{
		"SuburbName" : "Leeston",
		"Postcode" : "7683"
	},
	{
		"SuburbName" : "Southbridge",
		"Postcode" : "7683"
	},
	{
		"SuburbName" : "Kainga",
		"Postcode" : "7691"
	},
	{
		"SuburbName" : "Clarkville",
		"Postcode" : "7691"
	},
	{
		"SuburbName" : "Fernside",
		"Postcode" : "7691"
	},
	{
		"SuburbName" : "Flaxton",
		"Postcode" : "7691"
	},
	{
		"SuburbName" : "Kaiapoi",
		"Postcode" : "7691"
	},
	{
		"SuburbName" : "Kainga",
		"Postcode" : "7691"
	},
	{
		"SuburbName" : "Ohoka",
		"Postcode" : "7691"
	},
	{
		"SuburbName" : "Pegasus",
		"Postcode" : "7691"
	},
	{
		"SuburbName" : "Rangiora",
		"Postcode" : "7691"
	},
	{
		"SuburbName" : "Tuahiwi",
		"Postcode" : "7691"
	},
	{
		"SuburbName" : "Waikuku",
		"Postcode" : "7691"
	},
	{
		"SuburbName" : "Christchurch",
		"Postcode" : "7691"
	},
	{
		"SuburbName" : "Christchurch",
		"Postcode" : "7691"
	},
	{
		"SuburbName" : "Clarkville",
		"Postcode" : "7692"
	},
	{
		"SuburbName" : "Flaxton",
		"Postcode" : "7692"
	},
	{
		"SuburbName" : "Kaiapoi",
		"Postcode" : "7692"
	},
	{
		"SuburbName" : "Ohoka",
		"Postcode" : "7692"
	},
	{
		"SuburbName" : "Rangiora",
		"Postcode" : "7692"
	},
	{
		"SuburbName" : "Swannanoa",
		"Postcode" : "7692"
	},
	{
		"SuburbName" : "Alford Forest",
		"Postcode" : "7771"
	},
	{
		"SuburbName" : "Ashburton",
		"Postcode" : "7771"
	},
	{
		"SuburbName" : "Ashburton Forks",
		"Postcode" : "7771"
	},
	{
		"SuburbName" : "Ashburton Lakes",
		"Postcode" : "7771"
	},
	{
		"SuburbName" : "Cavendish",
		"Postcode" : "7771"
	},
	{
		"SuburbName" : "Greenstreet",
		"Postcode" : "7771"
	},
	{
		"SuburbName" : "Lagmhor",
		"Postcode" : "7771"
	},
	{
		"SuburbName" : "Mayfield",
		"Postcode" : "7771"
	},
	{
		"SuburbName" : "Mount Somers",
		"Postcode" : "7771"
	},
	{
		"SuburbName" : "Mt Somers",
		"Postcode" : "7771"
	},
	{
		"SuburbName" : "Staveley",
		"Postcode" : "7771"
	},
	{
		"SuburbName" : "Tinwald",
		"Postcode" : "7771"
	},
	{
		"SuburbName" : "Valetta",
		"Postcode" : "7771"
	},
	{
		"SuburbName" : "Westerfield",
		"Postcode" : "7771"
	},
	{
		"SuburbName" : "Allenton",
		"Postcode" : "7772"
	},
	{
		"SuburbName" : "Ashburton",
		"Postcode" : "7772"
	},
	{
		"SuburbName" : "Barrhill",
		"Postcode" : "7772"
	},
	{
		"SuburbName" : "Chertsey",
		"Postcode" : "7772"
	},
	{
		"SuburbName" : "Dromore",
		"Postcode" : "7772"
	},
	{
		"SuburbName" : "Elgin",
		"Postcode" : "7772"
	},
	{
		"SuburbName" : "Fairton",
		"Postcode" : "7772"
	},
	{
		"SuburbName" : "Hatfield",
		"Postcode" : "7772"
	},
	{
		"SuburbName" : "Lauriston",
		"Postcode" : "7772"
	},
	{
		"SuburbName" : "Mitcham",
		"Postcode" : "7772"
	},
	{
		"SuburbName" : "Netherby",
		"Postcode" : "7772"
	},
	{
		"SuburbName" : "Newland",
		"Postcode" : "7772"
	},
	{
		"SuburbName" : "Pendarves",
		"Postcode" : "7772"
	},
	{
		"SuburbName" : "Rakaia",
		"Postcode" : "7772"
	},
	{
		"SuburbName" : "Winchmore",
		"Postcode" : "7772"
	},
	{
		"SuburbName" : "Ashburton",
		"Postcode" : "7773"
	},
	{
		"SuburbName" : "Coldstream",
		"Postcode" : "7773"
	},
	{
		"SuburbName" : "Ealing",
		"Postcode" : "7773"
	},
	{
		"SuburbName" : "Eiffelton",
		"Postcode" : "7773"
	},
	{
		"SuburbName" : "Hinds",
		"Postcode" : "7773"
	},
	{
		"SuburbName" : "Lowcliffe",
		"Postcode" : "7773"
	},
	{
		"SuburbName" : "Tinwald",
		"Postcode" : "7773"
	},
	{
		"SuburbName" : "Willowby",
		"Postcode" : "7773"
	},
	{
		"SuburbName" : "Ashburton",
		"Postcode" : "7774"
	},
	{
		"SuburbName" : "Ashton",
		"Postcode" : "7774"
	},
	{
		"SuburbName" : "Eiffelton",
		"Postcode" : "7774"
	},
	{
		"SuburbName" : "Hinds",
		"Postcode" : "7774"
	},
	{
		"SuburbName" : "Huntingdon",
		"Postcode" : "7774"
	},
	{
		"SuburbName" : "Tinwald",
		"Postcode" : "7774"
	},
	{
		"SuburbName" : "Willowby",
		"Postcode" : "7774"
	},
	{
		"SuburbName" : "Ashburton",
		"Postcode" : "7775"
	},
	{
		"SuburbName" : "Carew",
		"Postcode" : "7775"
	},
	{
		"SuburbName" : "Ealing",
		"Postcode" : "7775"
	},
	{
		"SuburbName" : "Hinds",
		"Postcode" : "7775"
	},
	{
		"SuburbName" : "Lismore",
		"Postcode" : "7775"
	},
	{
		"SuburbName" : "Maronan",
		"Postcode" : "7775"
	},
	{
		"SuburbName" : "Mayfield",
		"Postcode" : "7775"
	},
	{
		"SuburbName" : "Ruapuna",
		"Postcode" : "7775"
	},
	{
		"SuburbName" : "Tinwald",
		"Postcode" : "7775"
	},
	{
		"SuburbName" : "Willowby",
		"Postcode" : "7775"
	},
	{
		"SuburbName" : "Winslow",
		"Postcode" : "7775"
	},
	{
		"SuburbName" : "Allenton",
		"Postcode" : "7776"
	},
	{
		"SuburbName" : "Ashburton",
		"Postcode" : "7776"
	},
	{
		"SuburbName" : "Ashburton Forks",
		"Postcode" : "7776"
	},
	{
		"SuburbName" : "Greenstreet",
		"Postcode" : "7776"
	},
	{
		"SuburbName" : "Lauriston",
		"Postcode" : "7776"
	},
	{
		"SuburbName" : "Methven",
		"Postcode" : "7776"
	},
	{
		"SuburbName" : "Mitcham",
		"Postcode" : "7776"
	},
	{
		"SuburbName" : "Winchmore",
		"Postcode" : "7776"
	},
	{
		"SuburbName" : "Ashburton",
		"Postcode" : "7777"
	},
	{
		"SuburbName" : "Dorie",
		"Postcode" : "7777"
	},
	{
		"SuburbName" : "Elgin",
		"Postcode" : "7777"
	},
	{
		"SuburbName" : "Newland",
		"Postcode" : "7777"
	},
	{
		"SuburbName" : "Pendarves",
		"Postcode" : "7777"
	},
	{
		"SuburbName" : "Rakaia",
		"Postcode" : "7777"
	},
	{
		"SuburbName" : "Wakanui",
		"Postcode" : "7777"
	},
	{
		"SuburbName" : "Ashburton",
		"Postcode" : "7778"
	},
	{
		"SuburbName" : "Cavendish",
		"Postcode" : "7778"
	},
	{
		"SuburbName" : "Lagmhor",
		"Postcode" : "7778"
	},
	{
		"SuburbName" : "Maronan",
		"Postcode" : "7778"
	},
	{
		"SuburbName" : "Mayfield",
		"Postcode" : "7778"
	},
	{
		"SuburbName" : "Montalto",
		"Postcode" : "7778"
	},
	{
		"SuburbName" : "Mount Somers",
		"Postcode" : "7778"
	},
	{
		"SuburbName" : "Mt Somers",
		"Postcode" : "7778"
	},
	{
		"SuburbName" : "Ruapuna",
		"Postcode" : "7778"
	},
	{
		"SuburbName" : "Surrey Hills",
		"Postcode" : "7778"
	},
	{
		"SuburbName" : "Tinwald",
		"Postcode" : "7778"
	},
	{
		"SuburbName" : "Valetta",
		"Postcode" : "7778"
	},
	{
		"SuburbName" : "Westerfield",
		"Postcode" : "7778"
	},
	{
		"SuburbName" : "Winslow",
		"Postcode" : "7778"
	},
	{
		"SuburbName" : "Dorie",
		"Postcode" : "7781"
	},
	{
		"SuburbName" : "Hatfield",
		"Postcode" : "7781"
	},
	{
		"SuburbName" : "Rakaia",
		"Postcode" : "7781"
	},
	{
		"SuburbName" : "Ashburton",
		"Postcode" : "7782"
	},
	{
		"SuburbName" : "Barrhill",
		"Postcode" : "7782"
	},
	{
		"SuburbName" : "Hatfield",
		"Postcode" : "7782"
	},
	{
		"SuburbName" : "Highbank",
		"Postcode" : "7782"
	},
	{
		"SuburbName" : "Hororata",
		"Postcode" : "7782"
	},
	{
		"SuburbName" : "Lauriston",
		"Postcode" : "7782"
	},
	{
		"SuburbName" : "Methven",
		"Postcode" : "7782"
	},
	{
		"SuburbName" : "Mitcham",
		"Postcode" : "7782"
	},
	{
		"SuburbName" : "Mount Hutt",
		"Postcode" : "7782"
	},
	{
		"SuburbName" : "Mt Hutt",
		"Postcode" : "7782"
	},
	{
		"SuburbName" : "Pudding Hill",
		"Postcode" : "7782"
	},
	{
		"SuburbName" : "Rakaia",
		"Postcode" : "7782"
	},
	{
		"SuburbName" : "Windwhistle",
		"Postcode" : "7782"
	},
	{
		"SuburbName" : "Bankside",
		"Postcode" : "7783"
	},
	{
		"SuburbName" : "Dunsandel",
		"Postcode" : "7783"
	},
	{
		"SuburbName" : "Hororata",
		"Postcode" : "7783"
	},
	{
		"SuburbName" : "Rakaia",
		"Postcode" : "7783"
	},
	{
		"SuburbName" : "Southbridge",
		"Postcode" : "7783"
	},
	{
		"SuburbName" : "Ashburton",
		"Postcode" : "7784"
	},
	{
		"SuburbName" : "Barrhill",
		"Postcode" : "7784"
	},
	{
		"SuburbName" : "Chertsey",
		"Postcode" : "7784"
	},
	{
		"SuburbName" : "Hatfield",
		"Postcode" : "7784"
	},
	{
		"SuburbName" : "Lauriston",
		"Postcode" : "7784"
	},
	{
		"SuburbName" : "Mitcham",
		"Postcode" : "7784"
	},
	{
		"SuburbName" : "Rakaia",
		"Postcode" : "7784"
	},
	{
		"SuburbName" : "Double Hill",
		"Postcode" : "7791"
	},
	{
		"SuburbName" : "Methven",
		"Postcode" : "7791"
	},
	{
		"SuburbName" : "Ahaura",
		"Postcode" : "7871"
	},
	{
		"SuburbName" : "Atarau",
		"Postcode" : "7871"
	},
	{
		"SuburbName" : "Blackball",
		"Postcode" : "7871"
	},
	{
		"SuburbName" : "Fox River",
		"Postcode" : "7871"
	},
	{
		"SuburbName" : "Ikamatua",
		"Postcode" : "7871"
	},
	{
		"SuburbName" : "Mawheraiti",
		"Postcode" : "7871"
	},
	{
		"SuburbName" : "Paparoa National Park",
		"Postcode" : "7871"
	},
	{
		"SuburbName" : "Punakaiki",
		"Postcode" : "7871"
	},
	{
		"SuburbName" : "Punakaiki",
		"Postcode" : "7871"
	},
	{
		"SuburbName" : "Reefton",
		"Postcode" : "7871"
	},
	{
		"SuburbName" : "Te Miko",
		"Postcode" : "7871"
	},
	{
		"SuburbName" : "Totara Flat",
		"Postcode" : "7871"
	},
	{
		"SuburbName" : "Ahaura",
		"Postcode" : "7872"
	},
	{
		"SuburbName" : "Arnold Valley",
		"Postcode" : "7872"
	},
	{
		"SuburbName" : "Dobson",
		"Postcode" : "7872"
	},
	{
		"SuburbName" : "Dunganville",
		"Postcode" : "7872"
	},
	{
		"SuburbName" : "Greymouth",
		"Postcode" : "7872"
	},
	{
		"SuburbName" : "Haupiri",
		"Postcode" : "7872"
	},
	{
		"SuburbName" : "Hochstetter",
		"Postcode" : "7872"
	},
	{
		"SuburbName" : "Marsden",
		"Postcode" : "7872"
	},
	{
		"SuburbName" : "Moana",
		"Postcode" : "7872"
	},
	{
		"SuburbName" : "Mount Ajax",
		"Postcode" : "7872"
	},
	{
		"SuburbName" : "Mt Ajax",
		"Postcode" : "7872"
	},
	{
		"SuburbName" : "Ngahere",
		"Postcode" : "7872"
	},
	{
		"SuburbName" : "Ngahere",
		"Postcode" : "7872"
	},
	{
		"SuburbName" : "Stillwater",
		"Postcode" : "7872"
	},
	{
		"SuburbName" : "Totara Flat",
		"Postcode" : "7872"
	},
	{
		"SuburbName" : "Barrytown",
		"Postcode" : "7873"
	},
	{
		"SuburbName" : "Greymouth",
		"Postcode" : "7873"
	},
	{
		"SuburbName" : "Nine Mile",
		"Postcode" : "7873"
	},
	{
		"SuburbName" : "Paparoa National Park",
		"Postcode" : "7873"
	},
	{
		"SuburbName" : "Punakaiki",
		"Postcode" : "7873"
	},
	{
		"SuburbName" : "Punakaiki",
		"Postcode" : "7873"
	},
	{
		"SuburbName" : "Punakaiki",
		"Postcode" : "7873"
	},
	{
		"SuburbName" : "Runanga",
		"Postcode" : "7873"
	},
	{
		"SuburbName" : "Arnold Valley",
		"Postcode" : "7875"
	},
	{
		"SuburbName" : "Arthurs Pass",
		"Postcode" : "7875"
	},
	{
		"SuburbName" : "Arthur's Pass National Park",
		"Postcode" : "7875"
	},
	{
		"SuburbName" : "Arthurs Pass",
		"Postcode" : "7875"
	},
	{
		"SuburbName" : "Arthurs Pass National Park",
		"Postcode" : "7875"
	},
	{
		"SuburbName" : "Dillmanstown",
		"Postcode" : "7875"
	},
	{
		"SuburbName" : "Dunganville",
		"Postcode" : "7875"
	},
	{
		"SuburbName" : "Greymouth",
		"Postcode" : "7875"
	},
	{
		"SuburbName" : "Hohonu",
		"Postcode" : "7875"
	},
	{
		"SuburbName" : "Inchbonnie",
		"Postcode" : "7875"
	},
	{
		"SuburbName" : "Jacksons",
		"Postcode" : "7875"
	},
	{
		"SuburbName" : "Kumara",
		"Postcode" : "7875"
	},
	{
		"SuburbName" : "Kumara Junction",
		"Postcode" : "7875"
	},
	{
		"SuburbName" : "Marsden",
		"Postcode" : "7875"
	},
	{
		"SuburbName" : "Moana",
		"Postcode" : "7875"
	},
	{
		"SuburbName" : "Otira",
		"Postcode" : "7875"
	},
	{
		"SuburbName" : "Turiwhate",
		"Postcode" : "7875"
	},
	{
		"SuburbName" : "Hokitika",
		"Postcode" : "7881"
	},
	{
		"SuburbName" : "Kaniere",
		"Postcode" : "7881"
	},
	{
		"SuburbName" : "Kokatahi",
		"Postcode" : "7881"
	},
	{
		"SuburbName" : "Seaview",
		"Postcode" : "7881"
	},
	{
		"SuburbName" : "Arahura Valley",
		"Postcode" : "7882"
	},
	{
		"SuburbName" : "Awatuna",
		"Postcode" : "7882"
	},
	{
		"SuburbName" : "Blue Spur",
		"Postcode" : "7882"
	},
	{
		"SuburbName" : "Greymouth",
		"Postcode" : "7882"
	},
	{
		"SuburbName" : "Hokitika",
		"Postcode" : "7882"
	},
	{
		"SuburbName" : "Kaniere",
		"Postcode" : "7882"
	},
	{
		"SuburbName" : "Kumara Junction",
		"Postcode" : "7882"
	},
	{
		"SuburbName" : "Seaview",
		"Postcode" : "7882"
	},
	{
		"SuburbName" : "Hokitika",
		"Postcode" : "7883"
	},
	{
		"SuburbName" : "Ross",
		"Postcode" : "7883"
	},
	{
		"SuburbName" : "Ruatapu",
		"Postcode" : "7883"
	},
	{
		"SuburbName" : "Totara River",
		"Postcode" : "7883"
	},
	{
		"SuburbName" : "Harihari",
		"Postcode" : "7884"
	},
	{
		"SuburbName" : "Kakapotahi",
		"Postcode" : "7885"
	},
	{
		"SuburbName" : "Pukekura",
		"Postcode" : "7885"
	},
	{
		"SuburbName" : "Ross",
		"Postcode" : "7885"
	},
	{
		"SuburbName" : "Bruce Bay",
		"Postcode" : "7886"
	},
	{
		"SuburbName" : "Fox Glacier",
		"Postcode" : "7886"
	},
	{
		"SuburbName" : "Franz Josef",
		"Postcode" : "7886"
	},
	{
		"SuburbName" : "Franz Josef Glacier",
		"Postcode" : "7886"
	},
	{
		"SuburbName" : "Haast",
		"Postcode" : "7886"
	},
	{
		"SuburbName" : "Jackson Bay",
		"Postcode" : "7886"
	},
	{
		"SuburbName" : "Karangarua",
		"Postcode" : "7886"
	},
	{
		"SuburbName" : "Okarito",
		"Postcode" : "7886"
	},
	{
		"SuburbName" : "Paringa",
		"Postcode" : "7886"
	},
	{
		"SuburbName" : "Waiho",
		"Postcode" : "7886"
	},
	{
		"SuburbName" : "Westland National Park",
		"Postcode" : "7886"
	},
	{
		"SuburbName" : "Whataroa",
		"Postcode" : "7886"
	},
	{
		"SuburbName" : "Birchfield",
		"Postcode" : "7891"
	},
	{
		"SuburbName" : "Corbyvale",
		"Postcode" : "7891"
	},
	{
		"SuburbName" : "Denniston",
		"Postcode" : "7891"
	},
	{
		"SuburbName" : "Fairdown",
		"Postcode" : "7891"
	},
	{
		"SuburbName" : "Granity",
		"Postcode" : "7891"
	},
	{
		"SuburbName" : "Hector",
		"Postcode" : "7891"
	},
	{
		"SuburbName" : "Karamea",
		"Postcode" : "7891"
	},
	{
		"SuburbName" : "Little Wanganui",
		"Postcode" : "7891"
	},
	{
		"SuburbName" : "Millerton",
		"Postcode" : "7891"
	},
	{
		"SuburbName" : "Mokihinui",
		"Postcode" : "7891"
	},
	{
		"SuburbName" : "Seddonville",
		"Postcode" : "7891"
	},
	{
		"SuburbName" : "Stockton",
		"Postcode" : "7891"
	},
	{
		"SuburbName" : "Waimangaroa",
		"Postcode" : "7891"
	},
	{
		"SuburbName" : "Westport",
		"Postcode" : "7891"
	},
	{
		"SuburbName" : "Cape Foulwind",
		"Postcode" : "7892"
	},
	{
		"SuburbName" : "Carters Beach",
		"Postcode" : "7892"
	},
	{
		"SuburbName" : "Charleston",
		"Postcode" : "7892"
	},
	{
		"SuburbName" : "Fairdown",
		"Postcode" : "7892"
	},
	{
		"SuburbName" : "Lower Buller Gorge",
		"Postcode" : "7892"
	},
	{
		"SuburbName" : "Virgin Flat",
		"Postcode" : "7892"
	},
	{
		"SuburbName" : "Westport",
		"Postcode" : "7892"
	},
	{
		"SuburbName" : "Kahurangi National Park.",
		"Postcode" : "7893"
	},
	{
		"SuburbName" : "Karamea",
		"Postcode" : "7893"
	},
	{
		"SuburbName" : "Little Wanganui",
		"Postcode" : "7893"
	},
	{
		"SuburbName" : "Cronadun",
		"Postcode" : "7895"
	},
	{
		"SuburbName" : "Ikamatua",
		"Postcode" : "7895"
	},
	{
		"SuburbName" : "Inangahua",
		"Postcode" : "7895"
	},
	{
		"SuburbName" : "Larrys Creek",
		"Postcode" : "7895"
	},
	{
		"SuburbName" : "Lewis Pass",
		"Postcode" : "7895"
	},
	{
		"SuburbName" : "Lower Buller Gorge",
		"Postcode" : "7895"
	},
	{
		"SuburbName" : "Maimai",
		"Postcode" : "7895"
	},
	{
		"SuburbName" : "Maruia",
		"Postcode" : "7895"
	},
	{
		"SuburbName" : "Mawheraiti",
		"Postcode" : "7895"
	},
	{
		"SuburbName" : "Murchison",
		"Postcode" : "7895"
	},
	{
		"SuburbName" : "Paparoa National Park",
		"Postcode" : "7895"
	},
	{
		"SuburbName" : "Rahu Saddle",
		"Postcode" : "7895"
	},
	{
		"SuburbName" : "Reefton",
		"Postcode" : "7895"
	},
	{
		"SuburbName" : "Shenandoah",
		"Postcode" : "7895"
	},
	{
		"SuburbName" : "Springs Junction",
		"Postcode" : "7895"
	},
	{
		"SuburbName" : "Totara Flat",
		"Postcode" : "7895"
	},
	{
		"SuburbName" : "Upper Buller Gorge",
		"Postcode" : "7895"
	},
	{
		"SuburbName" : "Victoria Forest Park",
		"Postcode" : "7895"
	},
	{
		"SuburbName" : "Westport",
		"Postcode" : "7895"
	},
	{
		"SuburbName" : "Adair",
		"Postcode" : "7971"
	},
	{
		"SuburbName" : "Hunter",
		"Postcode" : "7971"
	},
	{
		"SuburbName" : "Makikihi",
		"Postcode" : "7971"
	},
	{
		"SuburbName" : "Normanby",
		"Postcode" : "7971"
	},
	{
		"SuburbName" : "Otaio",
		"Postcode" : "7971"
	},
	{
		"SuburbName" : "Pareora",
		"Postcode" : "7971"
	},
	{
		"SuburbName" : "Pareora West",
		"Postcode" : "7971"
	},
	{
		"SuburbName" : "Saint Andrews",
		"Postcode" : "7971"
	},
	{
		"SuburbName" : "Salisbury",
		"Postcode" : "7971"
	},
	{
		"SuburbName" : "Scarborough",
		"Postcode" : "7971"
	},
	{
		"SuburbName" : "St Andrews",
		"Postcode" : "7971"
	},
	{
		"SuburbName" : "Timaru",
		"Postcode" : "7971"
	},
	{
		"SuburbName" : "Waimate",
		"Postcode" : "7971"
	},
	{
		"SuburbName" : "Adair",
		"Postcode" : "7972"
	},
	{
		"SuburbName" : "Cave",
		"Postcode" : "7972"
	},
	{
		"SuburbName" : "Claremont",
		"Postcode" : "7972"
	},
	{
		"SuburbName" : "Fairview",
		"Postcode" : "7972"
	},
	{
		"SuburbName" : "Hunters Hills",
		"Postcode" : "7972"
	},
	{
		"SuburbName" : "Kurow",
		"Postcode" : "7972"
	},
	{
		"SuburbName" : "Maungati",
		"Postcode" : "7972"
	},
	{
		"SuburbName" : "Pareora",
		"Postcode" : "7972"
	},
	{
		"SuburbName" : "Pareora West",
		"Postcode" : "7972"
	},
	{
		"SuburbName" : "Saint Andrews",
		"Postcode" : "7972"
	},
	{
		"SuburbName" : "Salisbury",
		"Postcode" : "7972"
	},
	{
		"SuburbName" : "Southburn",
		"Postcode" : "7972"
	},
	{
		"SuburbName" : "St Andrews",
		"Postcode" : "7972"
	},
	{
		"SuburbName" : "Taiko",
		"Postcode" : "7972"
	},
	{
		"SuburbName" : "Timaru",
		"Postcode" : "7972"
	},
	{
		"SuburbName" : "Kerrytown",
		"Postcode" : "7973"
	},
	{
		"SuburbName" : "Levels",
		"Postcode" : "7973"
	},
	{
		"SuburbName" : "Seadown",
		"Postcode" : "7973"
	},
	{
		"SuburbName" : "Timaru",
		"Postcode" : "7973"
	},
	{
		"SuburbName" : "Washdyke",
		"Postcode" : "7973"
	},
	{
		"SuburbName" : "Cave",
		"Postcode" : "7974"
	},
	{
		"SuburbName" : "Claremont",
		"Postcode" : "7974"
	},
	{
		"SuburbName" : "Fairview",
		"Postcode" : "7974"
	},
	{
		"SuburbName" : "Gleniti",
		"Postcode" : "7974"
	},
	{
		"SuburbName" : "Hadlow",
		"Postcode" : "7974"
	},
	{
		"SuburbName" : "Kerrytown",
		"Postcode" : "7974"
	},
	{
		"SuburbName" : "Levels",
		"Postcode" : "7974"
	},
	{
		"SuburbName" : "Levels Valley",
		"Postcode" : "7974"
	},
	{
		"SuburbName" : "Otipua Creek",
		"Postcode" : "7974"
	},
	{
		"SuburbName" : "Pleasant Point",
		"Postcode" : "7974"
	},
	{
		"SuburbName" : "ROSEWILL VALLEY",
		"Postcode" : "7974"
	},
	{
		"SuburbName" : "Sutherlands",
		"Postcode" : "7974"
	},
	{
		"SuburbName" : "Taiko",
		"Postcode" : "7974"
	},
	{
		"SuburbName" : "Timaru",
		"Postcode" : "7974"
	},
	{
		"SuburbName" : "Washdyke",
		"Postcode" : "7974"
	},
	{
		"SuburbName" : "Washdyke Flat",
		"Postcode" : "7974"
	},
	{
		"SuburbName" : "Claremont",
		"Postcode" : "7975"
	},
	{
		"SuburbName" : "Hadlow",
		"Postcode" : "7975"
	},
	{
		"SuburbName" : "Kerrytown",
		"Postcode" : "7975"
	},
	{
		"SuburbName" : "Levels",
		"Postcode" : "7975"
	},
	{
		"SuburbName" : "Levels Valley",
		"Postcode" : "7975"
	},
	{
		"SuburbName" : "ROSEWILL VALLEY",
		"Postcode" : "7975"
	},
	{
		"SuburbName" : "Timaru",
		"Postcode" : "7975"
	},
	{
		"SuburbName" : "Washdyke",
		"Postcode" : "7975"
	},
	{
		"SuburbName" : "Washdyke Flat",
		"Postcode" : "7975"
	},
	{
		"SuburbName" : "Elephant Hill",
		"Postcode" : "7977"
	},
	{
		"SuburbName" : "Ikawai",
		"Postcode" : "7977"
	},
	{
		"SuburbName" : "Waihao Downs",
		"Postcode" : "7977"
	},
	{
		"SuburbName" : "Waihaorunga",
		"Postcode" : "7977"
	},
	{
		"SuburbName" : "Waimate",
		"Postcode" : "7977"
	},
	{
		"SuburbName" : "Hunter",
		"Postcode" : "7978"
	},
	{
		"SuburbName" : "Makikihi",
		"Postcode" : "7978"
	},
	{
		"SuburbName" : "Timaru",
		"Postcode" : "7978"
	},
	{
		"SuburbName" : "Waimate",
		"Postcode" : "7978"
	},
	{
		"SuburbName" : "Glenavy",
		"Postcode" : "7979"
	},
	{
		"SuburbName" : "Ikawai",
		"Postcode" : "7979"
	},
	{
		"SuburbName" : "Morven",
		"Postcode" : "7979"
	},
	{
		"SuburbName" : "Waimate",
		"Postcode" : "7979"
	},
	{
		"SuburbName" : "Glenavy",
		"Postcode" : "7980"
	},
	{
		"SuburbName" : "Makikihi",
		"Postcode" : "7980"
	},
	{
		"SuburbName" : "Morven",
		"Postcode" : "7980"
	},
	{
		"SuburbName" : "Waimate",
		"Postcode" : "7980"
	},
	{
		"SuburbName" : "Waitaki Bridge",
		"Postcode" : "7980"
	},
	{
		"SuburbName" : "Fairlie",
		"Postcode" : "7982"
	},
	{
		"SuburbName" : "Geraldine",
		"Postcode" : "7982"
	},
	{
		"SuburbName" : "Hazelburn",
		"Postcode" : "7982"
	},
	{
		"SuburbName" : "Kakahu",
		"Postcode" : "7982"
	},
	{
		"SuburbName" : "Middle Valley",
		"Postcode" : "7982"
	},
	{
		"SuburbName" : "Pleasant Point",
		"Postcode" : "7982"
	},
	{
		"SuburbName" : "Sutherlands",
		"Postcode" : "7982"
	},
	{
		"SuburbName" : "Totara Valley",
		"Postcode" : "7982"
	},
	{
		"SuburbName" : "Cave",
		"Postcode" : "7983"
	},
	{
		"SuburbName" : "Hazelburn",
		"Postcode" : "7983"
	},
	{
		"SuburbName" : "Levels Valley",
		"Postcode" : "7983"
	},
	{
		"SuburbName" : "Pleasant Point",
		"Postcode" : "7983"
	},
	{
		"SuburbName" : "Sutherlands",
		"Postcode" : "7983"
	},
	{
		"SuburbName" : "Timaru",
		"Postcode" : "7983"
	},
	{
		"SuburbName" : "Albury",
		"Postcode" : "7984"
	},
	{
		"SuburbName" : "Cave",
		"Postcode" : "7984"
	},
	{
		"SuburbName" : "Hazelburn",
		"Postcode" : "7984"
	},
	{
		"SuburbName" : "Hunters Hills",
		"Postcode" : "7984"
	},
	{
		"SuburbName" : "Maungati",
		"Postcode" : "7984"
	},
	{
		"SuburbName" : "Pleasant Point",
		"Postcode" : "7984"
	},
	{
		"SuburbName" : "Southburn",
		"Postcode" : "7984"
	},
	{
		"SuburbName" : "Taiko",
		"Postcode" : "7984"
	},
	{
		"SuburbName" : "Geraldine",
		"Postcode" : "7985"
	},
	{
		"SuburbName" : "Geraldine Flat",
		"Postcode" : "7985"
	},
	{
		"SuburbName" : "Hilton",
		"Postcode" : "7985"
	},
	{
		"SuburbName" : "Kakahu",
		"Postcode" : "7985"
	},
	{
		"SuburbName" : "Temuka",
		"Postcode" : "7985"
	},
	{
		"SuburbName" : "Waitohi",
		"Postcode" : "7985"
	},
	{
		"SuburbName" : "Winchester",
		"Postcode" : "7985"
	},
	{
		"SuburbName" : "Clandeboye",
		"Postcode" : "7986"
	},
	{
		"SuburbName" : "Milford",
		"Postcode" : "7986"
	},
	{
		"SuburbName" : "Orari",
		"Postcode" : "7986"
	},
	{
		"SuburbName" : "Orton",
		"Postcode" : "7986"
	},
	{
		"SuburbName" : "Rangitata Island",
		"Postcode" : "7986"
	},
	{
		"SuburbName" : "Temuka",
		"Postcode" : "7986"
	},
	{
		"SuburbName" : "Winchester",
		"Postcode" : "7986"
	},
	{
		"SuburbName" : "Albury",
		"Postcode" : "7987"
	},
	{
		"SuburbName" : "Ashwick Flat",
		"Postcode" : "7987"
	},
	{
		"SuburbName" : "Burkes Pass",
		"Postcode" : "7987"
	},
	{
		"SuburbName" : "Coal Stream",
		"Postcode" : "7987"
	},
	{
		"SuburbName" : "Fairlie",
		"Postcode" : "7987"
	},
	{
		"SuburbName" : "Hazelburn",
		"Postcode" : "7987"
	},
	{
		"SuburbName" : "Kimbell",
		"Postcode" : "7987"
	},
	{
		"SuburbName" : "Middle Valley",
		"Postcode" : "7987"
	},
	{
		"SuburbName" : "Mount Dobson",
		"Postcode" : "7987"
	},
	{
		"SuburbName" : "Mount Dobson",
		"Postcode" : "7987"
	},
	{
		"SuburbName" : "Pleasant Point",
		"Postcode" : "7987"
	},
	{
		"SuburbName" : "Otaio",
		"Postcode" : "7988"
	},
	{
		"SuburbName" : "Saint Andrews",
		"Postcode" : "7988"
	},
	{
		"SuburbName" : "Southburn",
		"Postcode" : "7988"
	},
	{
		"SuburbName" : "St Andrews",
		"Postcode" : "7988"
	},
	{
		"SuburbName" : "Timaru",
		"Postcode" : "7988"
	},
	{
		"SuburbName" : "Mesopotamia",
		"Postcode" : "7990"
	},
	{
		"SuburbName" : "Mount Peel",
		"Postcode" : "7990"
	},
	{
		"SuburbName" : "Mt Peel",
		"Postcode" : "7990"
	},
	{
		"SuburbName" : "Peel Forest",
		"Postcode" : "7990"
	},
	{
		"SuburbName" : "Geraldine",
		"Postcode" : "7991"
	},
	{
		"SuburbName" : "Geraldine Downs",
		"Postcode" : "7991"
	},
	{
		"SuburbName" : "Geraldine Flat",
		"Postcode" : "7991"
	},
	{
		"SuburbName" : "Hilton",
		"Postcode" : "7991"
	},
	{
		"SuburbName" : "Kakahu",
		"Postcode" : "7991"
	},
	{
		"SuburbName" : "Orari",
		"Postcode" : "7991"
	},
	{
		"SuburbName" : "Orari Bridge",
		"Postcode" : "7991"
	},
	{
		"SuburbName" : "Orari Gorge",
		"Postcode" : "7991"
	},
	{
		"SuburbName" : "Pleasant Valley",
		"Postcode" : "7991"
	},
	{
		"SuburbName" : "Temuka",
		"Postcode" : "7991"
	},
	{
		"SuburbName" : "Woodbury",
		"Postcode" : "7991"
	},
	{
		"SuburbName" : "Arundel",
		"Postcode" : "7992"
	},
	{
		"SuburbName" : "Belfield",
		"Postcode" : "7992"
	},
	{
		"SuburbName" : "Geraldine",
		"Postcode" : "7992"
	},
	{
		"SuburbName" : "Mount Peel",
		"Postcode" : "7992"
	},
	{
		"SuburbName" : "Mt Peel",
		"Postcode" : "7992"
	},
	{
		"SuburbName" : "Orari",
		"Postcode" : "7992"
	},
	{
		"SuburbName" : "Orari Bridge",
		"Postcode" : "7992"
	},
	{
		"SuburbName" : "Orton",
		"Postcode" : "7992"
	},
	{
		"SuburbName" : "Peel Forest",
		"Postcode" : "7992"
	},
	{
		"SuburbName" : "Rangitata",
		"Postcode" : "7992"
	},
	{
		"SuburbName" : "Temuka",
		"Postcode" : "7992"
	},
	{
		"SuburbName" : "AORAKI MT COOK",
		"Postcode" : "7999"
	},
	{
		"SuburbName" : "AORAKI MT COOK",
		"Postcode" : "7999"
	},
	{
		"SuburbName" : "Aoraki Mt Cook",
		"Postcode" : "7999"
	},
	{
		"SuburbName" : "Ben Ohau",
		"Postcode" : "7999"
	},
	{
		"SuburbName" : "Glenbrook",
		"Postcode" : "7999"
	},
	{
		"SuburbName" : "Glentanner",
		"Postcode" : "7999"
	},
	{
		"SuburbName" : "Hopkins Valley",
		"Postcode" : "7999"
	},
	{
		"SuburbName" : "Lake Pukaki",
		"Postcode" : "7999"
	},
	{
		"SuburbName" : "Lake Tekapo",
		"Postcode" : "7999"
	},
	{
		"SuburbName" : "Mount Cook",
		"Postcode" : "7999"
	},
	{
		"SuburbName" : "Mount Cook",
		"Postcode" : "7999"
	},
	{
		"SuburbName" : "Mount Cook",
		"Postcode" : "7999"
	},
	{
		"SuburbName" : "Mount Cook",
		"Postcode" : "7999"
	},
	{
		"SuburbName" : "Pukaki",
		"Postcode" : "7999"
	},
	{
		"SuburbName" : "Sibbald",
		"Postcode" : "7999"
	},
	{
		"SuburbName" : "Tekapo",
		"Postcode" : "7999"
	},
	{
		"SuburbName" : "Tekapo",
		"Postcode" : "7999"
	},
	{
		"SuburbName" : "Totara Peak",
		"Postcode" : "7999"
	},
	{
		"SuburbName" : "Twizel",
		"Postcode" : "7999"
	},
	{
		"SuburbName" : "Allandale",
		"Postcode" : "8971"
	},
	{
		"SuburbName" : "Cass Bay",
		"Postcode" : "8971"
	},
	{
		"SuburbName" : "Charteris Bay",
		"Postcode" : "8971"
	},
	{
		"SuburbName" : "Christchurch",
		"Postcode" : "8971"
	},
	{
		"SuburbName" : "Diamond Harbour",
		"Postcode" : "8971"
	},
	{
		"SuburbName" : "Governors Bay",
		"Postcode" : "8971"
	},
	{
		"SuburbName" : "Kennedys Bush",
		"Postcode" : "8971"
	},
	{
		"SuburbName" : "Lyttelton",
		"Postcode" : "8971"
	},
	{
		"SuburbName" : "Rapaki",
		"Postcode" : "8971"
	},
	{
		"SuburbName" : "Teddington",
		"Postcode" : "8971"
	},
	{
		"SuburbName" : "Diamond Harbour",
		"Postcode" : "8972"
	},
	{
		"SuburbName" : "Port Levy",
		"Postcode" : "8972"
	},
	{
		"SuburbName" : "Purau",
		"Postcode" : "8972"
	},
	{
		"SuburbName" : "Allanton",
		"Postcode" : "9073"
	},
	{
		"SuburbName" : "Berwick",
		"Postcode" : "9073"
	},
	{
		"SuburbName" : "Berwick Forest",
		"Postcode" : "9073"
	},
	{
		"SuburbName" : "Circle Hill",
		"Postcode" : "9073"
	},
	{
		"SuburbName" : "Clarendon",
		"Postcode" : "9073"
	},
	{
		"SuburbName" : "Henley",
		"Postcode" : "9073"
	},
	{
		"SuburbName" : "Milton",
		"Postcode" : "9073"
	},
	{
		"SuburbName" : "Momona",
		"Postcode" : "9073"
	},
	{
		"SuburbName" : "Momona",
		"Postcode" : "9073"
	},
	{
		"SuburbName" : "Mosgiel",
		"Postcode" : "9073"
	},
	{
		"SuburbName" : "North Taieri",
		"Postcode" : "9073"
	},
	{
		"SuburbName" : "Outram",
		"Postcode" : "9073"
	},
	{
		"SuburbName" : "Riverside",
		"Postcode" : "9073"
	},
	{
		"SuburbName" : "Taieri Beach",
		"Postcode" : "9073"
	},
	{
		"SuburbName" : "Taieri Mouth",
		"Postcode" : "9073"
	},
	{
		"SuburbName" : "Titri",
		"Postcode" : "9073"
	},
	{
		"SuburbName" : "Waihola",
		"Postcode" : "9073"
	},
	{
		"SuburbName" : "Waipori Falls",
		"Postcode" : "9073"
	},
	{
		"SuburbName" : "Woodside",
		"Postcode" : "9073"
	},
	{
		"SuburbName" : "Clarks Junction",
		"Postcode" : "9074"
	},
	{
		"SuburbName" : "Hindon",
		"Postcode" : "9074"
	},
	{
		"SuburbName" : "Lee Flat",
		"Postcode" : "9074"
	},
	{
		"SuburbName" : "Lee Stream",
		"Postcode" : "9074"
	},
	{
		"SuburbName" : "Middlemarch",
		"Postcode" : "9074"
	},
	{
		"SuburbName" : "Mount Allan",
		"Postcode" : "9074"
	},
	{
		"SuburbName" : "Mt Allan",
		"Postcode" : "9074"
	},
	{
		"SuburbName" : "Outram",
		"Postcode" : "9074"
	},
	{
		"SuburbName" : "Pukerangi",
		"Postcode" : "9074"
	},
	{
		"SuburbName" : "Shannon",
		"Postcode" : "9074"
	},
	{
		"SuburbName" : "Waipori",
		"Postcode" : "9074"
	},
	{
		"SuburbName" : "Waipori Falls",
		"Postcode" : "9074"
	},
	{
		"SuburbName" : "Woodside",
		"Postcode" : "9074"
	},
	{
		"SuburbName" : "Abbotsford",
		"Postcode" : "9076"
	},
	{
		"SuburbName" : "Blackhead",
		"Postcode" : "9076"
	},
	{
		"SuburbName" : "Brighton",
		"Postcode" : "9076"
	},
	{
		"SuburbName" : "Burnside",
		"Postcode" : "9076"
	},
	{
		"SuburbName" : "Chain Hills",
		"Postcode" : "9076"
	},
	{
		"SuburbName" : "Concord",
		"Postcode" : "9076"
	},
	{
		"SuburbName" : "Corstorphine",
		"Postcode" : "9076"
	},
	{
		"SuburbName" : "Dunedin",
		"Postcode" : "9076"
	},
	{
		"SuburbName" : "Fairfield",
		"Postcode" : "9076"
	},
	{
		"SuburbName" : "Green Island",
		"Postcode" : "9076"
	},
	{
		"SuburbName" : "Halfway Bush",
		"Postcode" : "9076"
	},
	{
		"SuburbName" : "Kenmure",
		"Postcode" : "9076"
	},
	{
		"SuburbName" : "Kinmont Park",
		"Postcode" : "9076"
	},
	{
		"SuburbName" : "Mosgiel",
		"Postcode" : "9076"
	},
	{
		"SuburbName" : "Mount Grand",
		"Postcode" : "9076"
	},
	{
		"SuburbName" : "Mt Grand",
		"Postcode" : "9076"
	},
	{
		"SuburbName" : "North Taieri",
		"Postcode" : "9076"
	},
	{
		"SuburbName" : "Saddle Hill",
		"Postcode" : "9076"
	},
	{
		"SuburbName" : "Scroggs Hill",
		"Postcode" : "9076"
	},
	{
		"SuburbName" : "Waldronville",
		"Postcode" : "9076"
	},
	{
		"SuburbName" : "Westwood",
		"Postcode" : "9076"
	},
	{
		"SuburbName" : "Cape Saunders",
		"Postcode" : "9077"
	},
	{
		"SuburbName" : "Company Bay",
		"Postcode" : "9077"
	},
	{
		"SuburbName" : "Dunedin",
		"Postcode" : "9077"
	},
	{
		"SuburbName" : "Harington Point",
		"Postcode" : "9077"
	},
	{
		"SuburbName" : "Harwood",
		"Postcode" : "9077"
	},
	{
		"SuburbName" : "Highcliff",
		"Postcode" : "9077"
	},
	{
		"SuburbName" : "Larnachs Castle",
		"Postcode" : "9077"
	},
	{
		"SuburbName" : "Lower Portobello",
		"Postcode" : "9077"
	},
	{
		"SuburbName" : "Macandrew Bay",
		"Postcode" : "9077"
	},
	{
		"SuburbName" : "Ocean Grove",
		"Postcode" : "9077"
	},
	{
		"SuburbName" : "Okia Flat",
		"Postcode" : "9077"
	},
	{
		"SuburbName" : "Otakou",
		"Postcode" : "9077"
	},
	{
		"SuburbName" : "Portobello",
		"Postcode" : "9077"
	},
	{
		"SuburbName" : "Sandymount",
		"Postcode" : "9077"
	},
	{
		"SuburbName" : "The Cove",
		"Postcode" : "9077"
	},
	{
		"SuburbName" : "Vauxhall",
		"Postcode" : "9077"
	},
	{
		"SuburbName" : "Aramoana",
		"Postcode" : "9081"
	},
	{
		"SuburbName" : "Careys Bay",
		"Postcode" : "9081"
	},
	{
		"SuburbName" : "Dunedin",
		"Postcode" : "9081"
	},
	{
		"SuburbName" : "Heyward Point",
		"Postcode" : "9081"
	},
	{
		"SuburbName" : "Long Beach",
		"Postcode" : "9081"
	},
	{
		"SuburbName" : "Mihiwaka",
		"Postcode" : "9081"
	},
	{
		"SuburbName" : "Mount Cargill",
		"Postcode" : "9081"
	},
	{
		"SuburbName" : "Mt Cargill",
		"Postcode" : "9081"
	},
	{
		"SuburbName" : "Osborne",
		"Postcode" : "9081"
	},
	{
		"SuburbName" : "Port Chalmers",
		"Postcode" : "9081"
	},
	{
		"SuburbName" : "Purakaunui",
		"Postcode" : "9081"
	},
	{
		"SuburbName" : "Waitati",
		"Postcode" : "9081"
	},
	{
		"SuburbName" : "Aramoana",
		"Postcode" : "9082"
	},
	{
		"SuburbName" : "Careys Bay",
		"Postcode" : "9082"
	},
	{
		"SuburbName" : "Deborah Bay",
		"Postcode" : "9082"
	},
	{
		"SuburbName" : "Dunedin",
		"Postcode" : "9082"
	},
	{
		"SuburbName" : "Heyward Point",
		"Postcode" : "9082"
	},
	{
		"SuburbName" : "Mihiwaka",
		"Postcode" : "9082"
	},
	{
		"SuburbName" : "Port Chalmers",
		"Postcode" : "9082"
	},
	{
		"SuburbName" : "Dunedin",
		"Postcode" : "9085"
	},
	{
		"SuburbName" : "Evansdale",
		"Postcode" : "9085"
	},
	{
		"SuburbName" : "Mihiwaka",
		"Postcode" : "9085"
	},
	{
		"SuburbName" : "Mount Cargill",
		"Postcode" : "9085"
	},
	{
		"SuburbName" : "Mt Cargill",
		"Postcode" : "9085"
	},
	{
		"SuburbName" : "Normanby",
		"Postcode" : "9085"
	},
	{
		"SuburbName" : "Port Chalmers",
		"Postcode" : "9085"
	},
	{
		"SuburbName" : "Saint Leonards",
		"Postcode" : "9085"
	},
	{
		"SuburbName" : "Sawyers Bay",
		"Postcode" : "9085"
	},
	{
		"SuburbName" : "Upper Junction",
		"Postcode" : "9085"
	},
	{
		"SuburbName" : "Upper Waitati",
		"Postcode" : "9085"
	},
	{
		"SuburbName" : "Waikouaiti",
		"Postcode" : "9085"
	},
	{
		"SuburbName" : "Waitati",
		"Postcode" : "9085"
	},
	{
		"SuburbName" : "Akatore",
		"Postcode" : "9091"
	},
	{
		"SuburbName" : "Brighton",
		"Postcode" : "9091"
	},
	{
		"SuburbName" : "Dunedin",
		"Postcode" : "9091"
	},
	{
		"SuburbName" : "Kuri Bush",
		"Postcode" : "9091"
	},
	{
		"SuburbName" : "Milton",
		"Postcode" : "9091"
	},
	{
		"SuburbName" : "Taieri Beach",
		"Postcode" : "9091"
	},
	{
		"SuburbName" : "Taieri Mouth",
		"Postcode" : "9091"
	},
	{
		"SuburbName" : "Allanton",
		"Postcode" : "9092"
	},
	{
		"SuburbName" : "Brighton",
		"Postcode" : "9092"
	},
	{
		"SuburbName" : "Chain Hills",
		"Postcode" : "9092"
	},
	{
		"SuburbName" : "Dunedin",
		"Postcode" : "9092"
	},
	{
		"SuburbName" : "East Taieri",
		"Postcode" : "9092"
	},
	{
		"SuburbName" : "Henley",
		"Postcode" : "9092"
	},
	{
		"SuburbName" : "Mosgiel",
		"Postcode" : "9092"
	},
	{
		"SuburbName" : "North Taieri",
		"Postcode" : "9092"
	},
	{
		"SuburbName" : "Owhiro",
		"Postcode" : "9092"
	},
	{
		"SuburbName" : "Riverside",
		"Postcode" : "9092"
	},
	{
		"SuburbName" : "Saddle Hill",
		"Postcode" : "9092"
	},
	{
		"SuburbName" : "Scroggs Hill",
		"Postcode" : "9092"
	},
	{
		"SuburbName" : "Ahuriri Flat",
		"Postcode" : "9271"
	},
	{
		"SuburbName" : "Balclutha",
		"Postcode" : "9271"
	},
	{
		"SuburbName" : "Finegand",
		"Postcode" : "9271"
	},
	{
		"SuburbName" : "Glenomaru Valley",
		"Postcode" : "9271"
	},
	{
		"SuburbName" : "Kaka Point",
		"Postcode" : "9271"
	},
	{
		"SuburbName" : "Kakapuaka",
		"Postcode" : "9271"
	},
	{
		"SuburbName" : "Katea",
		"Postcode" : "9271"
	},
	{
		"SuburbName" : "Otanomomo",
		"Postcode" : "9271"
	},
	{
		"SuburbName" : "Owaka",
		"Postcode" : "9271"
	},
	{
		"SuburbName" : "Paretai",
		"Postcode" : "9271"
	},
	{
		"SuburbName" : "Romahapa",
		"Postcode" : "9271"
	},
	{
		"SuburbName" : "Waitepeka",
		"Postcode" : "9271"
	},
	{
		"SuburbName" : "Awamangu",
		"Postcode" : "9272"
	},
	{
		"SuburbName" : "Balclutha",
		"Postcode" : "9272"
	},
	{
		"SuburbName" : "Benhar",
		"Postcode" : "9272"
	},
	{
		"SuburbName" : "Hillend",
		"Postcode" : "9272"
	},
	{
		"SuburbName" : "Lawrence",
		"Postcode" : "9272"
	},
	{
		"SuburbName" : "Manuka Creek",
		"Postcode" : "9272"
	},
	{
		"SuburbName" : "Pukeawa",
		"Postcode" : "9272"
	},
	{
		"SuburbName" : "Stirling",
		"Postcode" : "9272"
	},
	{
		"SuburbName" : "Stony Creek",
		"Postcode" : "9272"
	},
	{
		"SuburbName" : "Balclutha",
		"Postcode" : "9273"
	},
	{
		"SuburbName" : "CLIFTON",
		"Postcode" : "9273"
	},
	{
		"SuburbName" : "Clinton",
		"Postcode" : "9273"
	},
	{
		"SuburbName" : "Glenomaru Valley",
		"Postcode" : "9273"
	},
	{
		"SuburbName" : "Kaihiku",
		"Postcode" : "9273"
	},
	{
		"SuburbName" : "Kaka Point",
		"Postcode" : "9273"
	},
	{
		"SuburbName" : "Kakapuaka",
		"Postcode" : "9273"
	},
	{
		"SuburbName" : "Lochindorb",
		"Postcode" : "9273"
	},
	{
		"SuburbName" : "Otanomomo",
		"Postcode" : "9273"
	},
	{
		"SuburbName" : "Romahapa",
		"Postcode" : "9273"
	},
	{
		"SuburbName" : "Te Houka",
		"Postcode" : "9273"
	},
	{
		"SuburbName" : "Waitepeka",
		"Postcode" : "9273"
	},
	{
		"SuburbName" : "Waiwera South",
		"Postcode" : "9273"
	},
	{
		"SuburbName" : "Warepa",
		"Postcode" : "9273"
	},
	{
		"SuburbName" : "Balclutha",
		"Postcode" : "9274"
	},
	{
		"SuburbName" : "Clydevale",
		"Postcode" : "9274"
	},
	{
		"SuburbName" : "Greenfield",
		"Postcode" : "9274"
	},
	{
		"SuburbName" : "Hillend",
		"Postcode" : "9274"
	},
	{
		"SuburbName" : "Lawrence",
		"Postcode" : "9274"
	},
	{
		"SuburbName" : "Pukeawa",
		"Postcode" : "9274"
	},
	{
		"SuburbName" : "Puketi",
		"Postcode" : "9274"
	},
	{
		"SuburbName" : "Rongahere",
		"Postcode" : "9274"
	},
	{
		"SuburbName" : "Tuapeka Mouth",
		"Postcode" : "9274"
	},
	{
		"SuburbName" : "Waitahuna West",
		"Postcode" : "9274"
	},
	{
		"SuburbName" : "Wharetoa",
		"Postcode" : "9274"
	},
	{
		"SuburbName" : "Inch Clutha",
		"Postcode" : "9281"
	},
	{
		"SuburbName" : "Kaitangata",
		"Postcode" : "9281"
	},
	{
		"SuburbName" : "Lovells Flat",
		"Postcode" : "9281"
	},
	{
		"SuburbName" : "Milton",
		"Postcode" : "9281"
	},
	{
		"SuburbName" : "Stirling",
		"Postcode" : "9281"
	},
	{
		"SuburbName" : "Kaitangata",
		"Postcode" : "9282"
	},
	{
		"SuburbName" : "Milton",
		"Postcode" : "9282"
	},
	{
		"SuburbName" : "Summer Hill",
		"Postcode" : "9282"
	},
	{
		"SuburbName" : "Toko Mouth",
		"Postcode" : "9282"
	},
	{
		"SuburbName" : "Wangaloa",
		"Postcode" : "9282"
	},
	{
		"SuburbName" : "Circle Hill",
		"Postcode" : "9291"
	},
	{
		"SuburbName" : "Clarendon",
		"Postcode" : "9291"
	},
	{
		"SuburbName" : "Milburn",
		"Postcode" : "9291"
	},
	{
		"SuburbName" : "Milton",
		"Postcode" : "9291"
	},
	{
		"SuburbName" : "Table Hill",
		"Postcode" : "9291"
	},
	{
		"SuburbName" : "Table Mound",
		"Postcode" : "9291"
	},
	{
		"SuburbName" : "Akatore",
		"Postcode" : "9292"
	},
	{
		"SuburbName" : "Balclutha",
		"Postcode" : "9292"
	},
	{
		"SuburbName" : "Crichton",
		"Postcode" : "9292"
	},
	{
		"SuburbName" : "Glenledi",
		"Postcode" : "9292"
	},
	{
		"SuburbName" : "Glenore",
		"Postcode" : "9292"
	},
	{
		"SuburbName" : "Hillend",
		"Postcode" : "9292"
	},
	{
		"SuburbName" : "Lovells Flat",
		"Postcode" : "9292"
	},
	{
		"SuburbName" : "Milton",
		"Postcode" : "9292"
	},
	{
		"SuburbName" : "Stony Creek",
		"Postcode" : "9292"
	},
	{
		"SuburbName" : "Table Hill",
		"Postcode" : "9292"
	},
	{
		"SuburbName" : "Table Mound",
		"Postcode" : "9292"
	},
	{
		"SuburbName" : "Toko Mouth",
		"Postcode" : "9292"
	},
	{
		"SuburbName" : "Arrow Junction",
		"Postcode" : "9371"
	},
	{
		"SuburbName" : "Arrowtown",
		"Postcode" : "9371"
	},
	{
		"SuburbName" : "Arthurs Point",
		"Postcode" : "9371"
	},
	{
		"SuburbName" : "Ben Lomond",
		"Postcode" : "9371"
	},
	{
		"SuburbName" : "Closeburn",
		"Postcode" : "9371"
	},
	{
		"SuburbName" : "Coronet Peak",
		"Postcode" : "9371"
	},
	{
		"SuburbName" : "Cromwell",
		"Postcode" : "9371"
	},
	{
		"SuburbName" : "Dalefield",
		"Postcode" : "9371"
	},
	{
		"SuburbName" : "Drift Bay",
		"Postcode" : "9371"
	},
	{
		"SuburbName" : "Frankton",
		"Postcode" : "9371"
	},
	{
		"SuburbName" : "Gibbston",
		"Postcode" : "9371"
	},
	{
		"SuburbName" : "Jacks Point",
		"Postcode" : "9371"
	},
	{
		"SuburbName" : "Kawarau Falls",
		"Postcode" : "9371"
	},
	{
		"SuburbName" : "Kawarau Gorge",
		"Postcode" : "9371"
	},
	{
		"SuburbName" : "Lake Hayes",
		"Postcode" : "9371"
	},
	{
		"SuburbName" : "Lake Hayes",
		"Postcode" : "9371"
	},
	{
		"SuburbName" : "Lower Shotover",
		"Postcode" : "9371"
	},
	{
		"SuburbName" : "Mount Creighton",
		"Postcode" : "9371"
	},
	{
		"SuburbName" : "MOUNT CREIGHTON",
		"Postcode" : "9371"
	},
	{
		"SuburbName" : "Queenstown",
		"Postcode" : "9371"
	},
	{
		"SuburbName" : "Queenstown Hill",
		"Postcode" : "9371"
	},
	{
		"SuburbName" : "Skippers",
		"Postcode" : "9371"
	},
	{
		"SuburbName" : "Speargrass Flat",
		"Postcode" : "9371"
	},
	{
		"SuburbName" : "Wye Creek",
		"Postcode" : "9371"
	},
	{
		"SuburbName" : "Glenorchy",
		"Postcode" : "9372"
	},
	{
		"SuburbName" : "Greenstone",
		"Postcode" : "9372"
	},
	{
		"SuburbName" : "Kinloch",
		"Postcode" : "9372"
	},
	{
		"SuburbName" : "Mount Aspiring National Park",
		"Postcode" : "9372"
	},
	{
		"SuburbName" : "Mt Aspiring National Park",
		"Postcode" : "9372"
	},
	{
		"SuburbName" : "Alexandra",
		"Postcode" : "9376"
	},
	{
		"SuburbName" : "Chatto Creek",
		"Postcode" : "9376"
	},
	{
		"SuburbName" : "Drybread",
		"Postcode" : "9376"
	},
	{
		"SuburbName" : "Matakanui",
		"Postcode" : "9376"
	},
	{
		"SuburbName" : "Omakau",
		"Postcode" : "9376"
	},
	{
		"SuburbName" : "Becks",
		"Postcode" : "9377"
	},
	{
		"SuburbName" : "Cambrians",
		"Postcode" : "9377"
	},
	{
		"SuburbName" : "Drybread",
		"Postcode" : "9377"
	},
	{
		"SuburbName" : "Lauder",
		"Postcode" : "9377"
	},
	{
		"SuburbName" : "Omakau",
		"Postcode" : "9377"
	},
	{
		"SuburbName" : "Ophir",
		"Postcode" : "9377"
	},
	{
		"SuburbName" : "Saint Bathans",
		"Postcode" : "9377"
	},
	{
		"SuburbName" : "St Bathans",
		"Postcode" : "9377"
	},
	{
		"SuburbName" : "Cardrona",
		"Postcode" : "9381"
	},
	{
		"SuburbName" : "Mount Barker",
		"Postcode" : "9381"
	},
	{
		"SuburbName" : "MOUNT BARKER",
		"Postcode" : "9381"
	},
	{
		"SuburbName" : "Pukerua Bay",
		"Postcode" : "9381"
	},
	{
		"SuburbName" : "Treble Cone",
		"Postcode" : "9381"
	},
	{
		"SuburbName" : "Wanaka",
		"Postcode" : "9381"
	},
	{
		"SuburbName" : "Albert Town",
		"Postcode" : "9382"
	},
	{
		"SuburbName" : "Cardrona",
		"Postcode" : "9382"
	},
	{
		"SuburbName" : "Cattle Flat",
		"Postcode" : "9382"
	},
	{
		"SuburbName" : "Dingle Burn",
		"Postcode" : "9382"
	},
	{
		"SuburbName" : "Gladstone",
		"Postcode" : "9382"
	},
	{
		"SuburbName" : "Glendhu Bay",
		"Postcode" : "9382"
	},
	{
		"SuburbName" : "Haast",
		"Postcode" : "9382"
	},
	{
		"SuburbName" : "Hawea",
		"Postcode" : "9382"
	},
	{
		"SuburbName" : "Hawea Flat",
		"Postcode" : "9382"
	},
	{
		"SuburbName" : "Hunter Valley",
		"Postcode" : "9382"
	},
	{
		"SuburbName" : "Jamestown",
		"Postcode" : "9382"
	},
	{
		"SuburbName" : "Lake Hawea",
		"Postcode" : "9382"
	},
	{
		"SuburbName" : "Lindis Pass",
		"Postcode" : "9382"
	},
	{
		"SuburbName" : "Luggate",
		"Postcode" : "9382"
	},
	{
		"SuburbName" : "Makarora",
		"Postcode" : "9382"
	},
	{
		"SuburbName" : "Mount Aspiring",
		"Postcode" : "9382"
	},
	{
		"SuburbName" : "Mount Aspiring National Park",
		"Postcode" : "9382"
	},
	{
		"SuburbName" : "Mount Barker",
		"Postcode" : "9382"
	},
	{
		"SuburbName" : "MOUNT ASPIRING",
		"Postcode" : "9382"
	},
	{
		"SuburbName" : "Mt Aspiring National Park",
		"Postcode" : "9382"
	},
	{
		"SuburbName" : "MOUNT BARKER",
		"Postcode" : "9382"
	},
	{
		"SuburbName" : "The Neck",
		"Postcode" : "9382"
	},
	{
		"SuburbName" : "Treble Cone",
		"Postcode" : "9382"
	},
	{
		"SuburbName" : "Wanaka",
		"Postcode" : "9382"
	},
	{
		"SuburbName" : "Bendigo",
		"Postcode" : "9383"
	},
	{
		"SuburbName" : "Cromwell",
		"Postcode" : "9383"
	},
	{
		"SuburbName" : "Cromwell Gorge",
		"Postcode" : "9383"
	},
	{
		"SuburbName" : "Lagoon Valley",
		"Postcode" : "9383"
	},
	{
		"SuburbName" : "Lindis Pass",
		"Postcode" : "9383"
	},
	{
		"SuburbName" : "Lindis Valley",
		"Postcode" : "9383"
	},
	{
		"SuburbName" : "Lowburn",
		"Postcode" : "9383"
	},
	{
		"SuburbName" : "Luggate",
		"Postcode" : "9383"
	},
	{
		"SuburbName" : "Mount Pisa",
		"Postcode" : "9383"
	},
	{
		"SuburbName" : "Mt Pisa",
		"Postcode" : "9383"
	},
	{
		"SuburbName" : "Northburn",
		"Postcode" : "9383"
	},
	{
		"SuburbName" : "Queensberry",
		"Postcode" : "9383"
	},
	{
		"SuburbName" : "Tarras",
		"Postcode" : "9383"
	},
	{
		"SuburbName" : "Wanaka",
		"Postcode" : "9383"
	},
	{
		"SuburbName" : "Athol",
		"Postcode" : "9384"
	},
	{
		"SuburbName" : "Bannockburn",
		"Postcode" : "9384"
	},
	{
		"SuburbName" : "Cornish Point",
		"Postcode" : "9384"
	},
	{
		"SuburbName" : "Cromwell",
		"Postcode" : "9384"
	},
	{
		"SuburbName" : "Gibbston",
		"Postcode" : "9384"
	},
	{
		"SuburbName" : "Kawarau Gorge",
		"Postcode" : "9384"
	},
	{
		"SuburbName" : "Kingston",
		"Postcode" : "9384"
	},
	{
		"SuburbName" : "Lowburn",
		"Postcode" : "9384"
	},
	{
		"SuburbName" : "Lower Nevis",
		"Postcode" : "9384"
	},
	{
		"SuburbName" : "Mount Pisa",
		"Postcode" : "9384"
	},
	{
		"SuburbName" : "Mt Pisa",
		"Postcode" : "9384"
	},
	{
		"SuburbName" : "Nevis",
		"Postcode" : "9384"
	},
	{
		"SuburbName" : "Queenstown",
		"Postcode" : "9384"
	},
	{
		"SuburbName" : "Roaring Meg",
		"Postcode" : "9384"
	},
	{
		"SuburbName" : "Cambrians",
		"Postcode" : "9386"
	},
	{
		"SuburbName" : "Hills Creek",
		"Postcode" : "9386"
	},
	{
		"SuburbName" : "Idaburn",
		"Postcode" : "9386"
	},
	{
		"SuburbName" : "Omakau",
		"Postcode" : "9386"
	},
	{
		"SuburbName" : "Oturehua",
		"Postcode" : "9386"
	},
	{
		"SuburbName" : "Ranfurly",
		"Postcode" : "9386"
	},
	{
		"SuburbName" : "Saint Bathans",
		"Postcode" : "9386"
	},
	{
		"SuburbName" : "St Bathans",
		"Postcode" : "9386"
	},
	{
		"SuburbName" : "Wedderburn",
		"Postcode" : "9386"
	},
	{
		"SuburbName" : "Ida Valley",
		"Postcode" : "9387"
	},
	{
		"SuburbName" : "Manorburn",
		"Postcode" : "9387"
	},
	{
		"SuburbName" : "Moa Creek",
		"Postcode" : "9387"
	},
	{
		"SuburbName" : "Omakau",
		"Postcode" : "9387"
	},
	{
		"SuburbName" : "Ophir",
		"Postcode" : "9387"
	},
	{
		"SuburbName" : "Oturehua",
		"Postcode" : "9387"
	},
	{
		"SuburbName" : "Poolburn",
		"Postcode" : "9387"
	},
	{
		"SuburbName" : "Alexandra",
		"Postcode" : "9391"
	},
	{
		"SuburbName" : "Blackmans",
		"Postcode" : "9391"
	},
	{
		"SuburbName" : "Bridge Hill",
		"Postcode" : "9391"
	},
	{
		"SuburbName" : "Clyde",
		"Postcode" : "9391"
	},
	{
		"SuburbName" : "Conroys Gully",
		"Postcode" : "9391"
	},
	{
		"SuburbName" : "Earnscleugh",
		"Postcode" : "9391"
	},
	{
		"SuburbName" : "Fraser Dam",
		"Postcode" : "9391"
	},
	{
		"SuburbName" : "Springvale",
		"Postcode" : "9391"
	},
	{
		"SuburbName" : "Waikerikeri",
		"Postcode" : "9391"
	},
	{
		"SuburbName" : "Alexandra",
		"Postcode" : "9392"
	},
	{
		"SuburbName" : "Bridge Hill",
		"Postcode" : "9392"
	},
	{
		"SuburbName" : "Butchers Gully",
		"Postcode" : "9392"
	},
	{
		"SuburbName" : "Conroys Gully",
		"Postcode" : "9392"
	},
	{
		"SuburbName" : "Fruitlands",
		"Postcode" : "9392"
	},
	{
		"SuburbName" : "Little Valley",
		"Postcode" : "9392"
	},
	{
		"SuburbName" : "Roxburgh",
		"Postcode" : "9392"
	},
	{
		"SuburbName" : "Shingle Creek",
		"Postcode" : "9392"
	},
	{
		"SuburbName" : "Alexandra",
		"Postcode" : "9393"
	},
	{
		"SuburbName" : "Chatto Creek",
		"Postcode" : "9393"
	},
	{
		"SuburbName" : "Galloway",
		"Postcode" : "9393"
	},
	{
		"SuburbName" : "Letts Gully",
		"Postcode" : "9393"
	},
	{
		"SuburbName" : "Little Valley",
		"Postcode" : "9393"
	},
	{
		"SuburbName" : "Omakau",
		"Postcode" : "9393"
	},
	{
		"SuburbName" : "Ophir",
		"Postcode" : "9393"
	},
	{
		"SuburbName" : "Springvale",
		"Postcode" : "9393"
	},
	{
		"SuburbName" : "Waikerikeri",
		"Postcode" : "9393"
	},
	{
		"SuburbName" : "Gimmerburn",
		"Postcode" : "9395"
	},
	{
		"SuburbName" : "Patearoa",
		"Postcode" : "9395"
	},
	{
		"SuburbName" : "Puketoi",
		"Postcode" : "9395"
	},
	{
		"SuburbName" : "Ranfurly",
		"Postcode" : "9395"
	},
	{
		"SuburbName" : "Wedderburn",
		"Postcode" : "9395"
	},
	{
		"SuburbName" : "Kyeburn",
		"Postcode" : "9396"
	},
	{
		"SuburbName" : "Kyeburn Diggings",
		"Postcode" : "9396"
	},
	{
		"SuburbName" : "Naseby",
		"Postcode" : "9396"
	},
	{
		"SuburbName" : "Ranfurly",
		"Postcode" : "9396"
	},
	{
		"SuburbName" : "Hyde",
		"Postcode" : "9397"
	},
	{
		"SuburbName" : "Kokonga",
		"Postcode" : "9397"
	},
	{
		"SuburbName" : "Kyeburn",
		"Postcode" : "9397"
	},
	{
		"SuburbName" : "Middlemarch",
		"Postcode" : "9397"
	},
	{
		"SuburbName" : "Naseby",
		"Postcode" : "9397"
	},
	{
		"SuburbName" : "Ranfurly",
		"Postcode" : "9397"
	},
	{
		"SuburbName" : "Rock And Pillar",
		"Postcode" : "9397"
	},
	{
		"SuburbName" : "Tiroiti",
		"Postcode" : "9397"
	},
	{
		"SuburbName" : "Waipiata",
		"Postcode" : "9397"
	},
	{
		"SuburbName" : "Orangapai",
		"Postcode" : "9398"
	},
	{
		"SuburbName" : "Paerau",
		"Postcode" : "9398"
	},
	{
		"SuburbName" : "Patearoa",
		"Postcode" : "9398"
	},
	{
		"SuburbName" : "Puketoi",
		"Postcode" : "9398"
	},
	{
		"SuburbName" : "Ranfurly",
		"Postcode" : "9398"
	},
	{
		"SuburbName" : "Serpentine",
		"Postcode" : "9398"
	},
	{
		"SuburbName" : "Waipiata",
		"Postcode" : "9398"
	},
	{
		"SuburbName" : "Bucklands Crossing",
		"Postcode" : "9471"
	},
	{
		"SuburbName" : "Evansdale",
		"Postcode" : "9471"
	},
	{
		"SuburbName" : "Karitane",
		"Postcode" : "9471"
	},
	{
		"SuburbName" : "Merton",
		"Postcode" : "9471"
	},
	{
		"SuburbName" : "Mount Trotter",
		"Postcode" : "9471"
	},
	{
		"SuburbName" : "Mt Trotter",
		"Postcode" : "9471"
	},
	{
		"SuburbName" : "Omimi",
		"Postcode" : "9471"
	},
	{
		"SuburbName" : "SOUTH ISLAND",
		"Postcode" : "9471"
	},
	{
		"SuburbName" : "Seacliff",
		"Postcode" : "9471"
	},
	{
		"SuburbName" : "Waikouaiti",
		"Postcode" : "9471"
	},
	{
		"SuburbName" : "Warrington",
		"Postcode" : "9471"
	},
	{
		"SuburbName" : "Flag Swamp",
		"Postcode" : "9472"
	},
	{
		"SuburbName" : "Goodwood",
		"Postcode" : "9472"
	},
	{
		"SuburbName" : "SOUTH ISLAND",
		"Postcode" : "9472"
	},
	{
		"SuburbName" : "Waikouaiti",
		"Postcode" : "9472"
	},
	{
		"SuburbName" : "Bushey",
		"Postcode" : "9481"
	},
	{
		"SuburbName" : "Glenpark",
		"Postcode" : "9481"
	},
	{
		"SuburbName" : "Goodwood",
		"Postcode" : "9481"
	},
	{
		"SuburbName" : "Meadowbank",
		"Postcode" : "9481"
	},
	{
		"SuburbName" : "Mount Trotter",
		"Postcode" : "9481"
	},
	{
		"SuburbName" : "Mt Trotter",
		"Postcode" : "9481"
	},
	{
		"SuburbName" : "SOUTH ISLAND",
		"Postcode" : "9481"
	},
	{
		"SuburbName" : "Bushey",
		"Postcode" : "9482"
	},
	{
		"SuburbName" : "Hampden",
		"Postcode" : "9482"
	},
	{
		"SuburbName" : "Hillgrove",
		"Postcode" : "9482"
	},
	{
		"SuburbName" : "Moeraki",
		"Postcode" : "9482"
	},
	{
		"SuburbName" : "SOUTH ISLAND",
		"Postcode" : "9482"
	},
	{
		"SuburbName" : "Shag Point",
		"Postcode" : "9482"
	},
	{
		"SuburbName" : "Dunback",
		"Postcode" : "9483"
	},
	{
		"SuburbName" : "Glenpark",
		"Postcode" : "9483"
	},
	{
		"SuburbName" : "Green Valley",
		"Postcode" : "9483"
	},
	{
		"SuburbName" : "Hyde",
		"Postcode" : "9483"
	},
	{
		"SuburbName" : "Inch Valley",
		"Postcode" : "9483"
	},
	{
		"SuburbName" : "Kyeburn",
		"Postcode" : "9483"
	},
	{
		"SuburbName" : "Macraes",
		"Postcode" : "9483"
	},
	{
		"SuburbName" : "Macraes Flat",
		"Postcode" : "9483"
	},
	{
		"SuburbName" : "Makareao",
		"Postcode" : "9483"
	},
	{
		"SuburbName" : "Meadowbank",
		"Postcode" : "9483"
	},
	{
		"SuburbName" : "Moonlight",
		"Postcode" : "9483"
	},
	{
		"SuburbName" : "Morrisons",
		"Postcode" : "9483"
	},
	{
		"SuburbName" : "Mount Trotter",
		"Postcode" : "9483"
	},
	{
		"SuburbName" : "Nenthorn",
		"Postcode" : "9483"
	},
	{
		"SuburbName" : "SOUTH ISLAND",
		"Postcode" : "9483"
	},
	{
		"SuburbName" : "Round Hill",
		"Postcode" : "9483"
	},
	{
		"SuburbName" : "Stoneburn",
		"Postcode" : "9483"
	},
	{
		"SuburbName" : "Waihemo",
		"Postcode" : "9483"
	},
	{
		"SuburbName" : "Waynes",
		"Postcode" : "9483"
	},
	{
		"SuburbName" : "Alma",
		"Postcode" : "9491"
	},
	{
		"SuburbName" : "Cormacks",
		"Postcode" : "9491"
	},
	{
		"SuburbName" : "Corriedale",
		"Postcode" : "9491"
	},
	{
		"SuburbName" : "Danseys Pass",
		"Postcode" : "9491"
	},
	{
		"SuburbName" : "Deborah",
		"Postcode" : "9491"
	},
	{
		"SuburbName" : "Duntroon",
		"Postcode" : "9491"
	},
	{
		"SuburbName" : "Elderslie",
		"Postcode" : "9491"
	},
	{
		"SuburbName" : "Enfield",
		"Postcode" : "9491"
	},
	{
		"SuburbName" : "Five Forks",
		"Postcode" : "9491"
	},
	{
		"SuburbName" : "Fuchsia Creek",
		"Postcode" : "9491"
	},
	{
		"SuburbName" : "Georgetown",
		"Postcode" : "9491"
	},
	{
		"SuburbName" : "Island Cliff",
		"Postcode" : "9491"
	},
	{
		"SuburbName" : "Kia Ora",
		"Postcode" : "9491"
	},
	{
		"SuburbName" : "Kokoamo",
		"Postcode" : "9491"
	},
	{
		"SuburbName" : "Livingstone",
		"Postcode" : "9491"
	},
	{
		"SuburbName" : "Maerewhenua",
		"Postcode" : "9491"
	},
	{
		"SuburbName" : "Ngapara",
		"Postcode" : "9491"
	},
	{
		"SuburbName" : "Oamaru",
		"Postcode" : "9491"
	},
	{
		"SuburbName" : "Queens Flat",
		"Postcode" : "9491"
	},
	{
		"SuburbName" : "Tapui",
		"Postcode" : "9491"
	},
	{
		"SuburbName" : "Tokarahi",
		"Postcode" : "9491"
	},
	{
		"SuburbName" : "Waiareka",
		"Postcode" : "9491"
	},
	{
		"SuburbName" : "Weston",
		"Postcode" : "9491"
	},
	{
		"SuburbName" : "Whitstone",
		"Postcode" : "9491"
	},
	{
		"SuburbName" : "Windsor",
		"Postcode" : "9491"
	},
	{
		"SuburbName" : "Windsor Park",
		"Postcode" : "9491"
	},
	{
		"SuburbName" : "Airedale",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Alma",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Ardgowan",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Awamoa",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Deborah",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Elderslie",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Enfield",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Five Forks",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Fuchsia Creek",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Herbert",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Holmes Hill",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Incholme",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Island Stream",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Kakanui",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Kauru Hill",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Kia Ora",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Maheno",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Maraeweka",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Mount Stalker",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Mount Stalker",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Oamaru",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "OAMARU",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Papakaio",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Pukeuri",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Reidston",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Rosebery",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Teschemakers",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "The Dasher",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Totara",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Waitaki Bridge",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Weston",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Whitstone",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Windsor",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Windsor Park",
		"Postcode" : "9492"
	},
	{
		"SuburbName" : "Hilderthorpe",
		"Postcode" : "9493"
	},
	{
		"SuburbName" : "Oamaru",
		"Postcode" : "9493"
	},
	{
		"SuburbName" : "OAMARU",
		"Postcode" : "9493"
	},
	{
		"SuburbName" : "Papakaio",
		"Postcode" : "9493"
	},
	{
		"SuburbName" : "Pukeuri",
		"Postcode" : "9493"
	},
	{
		"SuburbName" : "Richmond",
		"Postcode" : "9493"
	},
	{
		"SuburbName" : "Rosebery",
		"Postcode" : "9493"
	},
	{
		"SuburbName" : "Waitaki Bridge",
		"Postcode" : "9493"
	},
	{
		"SuburbName" : "Airedale",
		"Postcode" : "9494"
	},
	{
		"SuburbName" : "Awamoko",
		"Postcode" : "9494"
	},
	{
		"SuburbName" : "Black Point",
		"Postcode" : "9494"
	},
	{
		"SuburbName" : "Duntroon",
		"Postcode" : "9494"
	},
	{
		"SuburbName" : "Earthquakes",
		"Postcode" : "9494"
	},
	{
		"SuburbName" : "Georgetown",
		"Postcode" : "9494"
	},
	{
		"SuburbName" : "Island Cliff",
		"Postcode" : "9494"
	},
	{
		"SuburbName" : "Kokoamo",
		"Postcode" : "9494"
	},
	{
		"SuburbName" : "Maerewhenua",
		"Postcode" : "9494"
	},
	{
		"SuburbName" : "Ngapara",
		"Postcode" : "9494"
	},
	{
		"SuburbName" : "Oamaru",
		"Postcode" : "9494"
	},
	{
		"SuburbName" : "OAMARU",
		"Postcode" : "9494"
	},
	{
		"SuburbName" : "Otekaieke",
		"Postcode" : "9494"
	},
	{
		"SuburbName" : "Otiake",
		"Postcode" : "9494"
	},
	{
		"SuburbName" : "Papakaio",
		"Postcode" : "9494"
	},
	{
		"SuburbName" : "Peebles",
		"Postcode" : "9494"
	},
	{
		"SuburbName" : "Pukeuri",
		"Postcode" : "9494"
	},
	{
		"SuburbName" : "Queens Flat",
		"Postcode" : "9494"
	},
	{
		"SuburbName" : "Richmond",
		"Postcode" : "9494"
	},
	{
		"SuburbName" : "Waikaura",
		"Postcode" : "9494"
	},
	{
		"SuburbName" : "Waitaki Bridge",
		"Postcode" : "9494"
	},
	{
		"SuburbName" : "Windsor Park",
		"Postcode" : "9494"
	},
	{
		"SuburbName" : "Alma",
		"Postcode" : "9495"
	},
	{
		"SuburbName" : "Awamoa",
		"Postcode" : "9495"
	},
	{
		"SuburbName" : "Cormacks",
		"Postcode" : "9495"
	},
	{
		"SuburbName" : "Hampden",
		"Postcode" : "9495"
	},
	{
		"SuburbName" : "Herbert",
		"Postcode" : "9495"
	},
	{
		"SuburbName" : "Kakanui",
		"Postcode" : "9495"
	},
	{
		"SuburbName" : "Maheno",
		"Postcode" : "9495"
	},
	{
		"SuburbName" : "Mount Stalker",
		"Postcode" : "9495"
	},
	{
		"SuburbName" : "Mount Stalker",
		"Postcode" : "9495"
	},
	{
		"SuburbName" : "Oamaru",
		"Postcode" : "9495"
	},
	{
		"SuburbName" : "Totara",
		"Postcode" : "9495"
	},
	{
		"SuburbName" : "Waianakarua",
		"Postcode" : "9495"
	},
	{
		"SuburbName" : "Whitstone",
		"Postcode" : "9495"
	},
	{
		"SuburbName" : "Cattle Creek",
		"Postcode" : "9498"
	},
	{
		"SuburbName" : "Elephant Hill",
		"Postcode" : "9498"
	},
	{
		"SuburbName" : "Hakataramea",
		"Postcode" : "9498"
	},
	{
		"SuburbName" : "Hakataramea Valley",
		"Postcode" : "9498"
	},
	{
		"SuburbName" : "Hunters Hills",
		"Postcode" : "9498"
	},
	{
		"SuburbName" : "Kirkliston",
		"Postcode" : "9498"
	},
	{
		"SuburbName" : "Kurow",
		"Postcode" : "9498"
	},
	{
		"SuburbName" : "Southburn",
		"Postcode" : "9498"
	},
	{
		"SuburbName" : "Station Peak",
		"Postcode" : "9498"
	},
	{
		"SuburbName" : "Waimate",
		"Postcode" : "9498"
	},
	{
		"SuburbName" : "Waitangi",
		"Postcode" : "9498"
	},
	{
		"SuburbName" : "Coal Creek Flat",
		"Postcode" : "9571"
	},
	{
		"SuburbName" : "Knobby Range",
		"Postcode" : "9571"
	},
	{
		"SuburbName" : "Lake Roxburgh Village",
		"Postcode" : "9571"
	},
	{
		"SuburbName" : "Roxburgh",
		"Postcode" : "9571"
	},
	{
		"SuburbName" : "Roxburgh East",
		"Postcode" : "9571"
	},
	{
		"SuburbName" : "Roxburgh",
		"Postcode" : "9571"
	},
	{
		"SuburbName" : "Shingle Creek",
		"Postcode" : "9571"
	},
	{
		"SuburbName" : "Beaumont",
		"Postcode" : "9572"
	},
	{
		"SuburbName" : "Dumbarton",
		"Postcode" : "9572"
	},
	{
		"SuburbName" : "Ettrick",
		"Postcode" : "9572"
	},
	{
		"SuburbName" : "Island Block",
		"Postcode" : "9572"
	},
	{
		"SuburbName" : "Lake Onslow",
		"Postcode" : "9572"
	},
	{
		"SuburbName" : "Lawrence",
		"Postcode" : "9572"
	},
	{
		"SuburbName" : "Millers Flat",
		"Postcode" : "9572"
	},
	{
		"SuburbName" : "Raes Junction",
		"Postcode" : "9572"
	},
	{
		"SuburbName" : "Roxburgh",
		"Postcode" : "9572"
	},
	{
		"SuburbName" : "Roxburgh East",
		"Postcode" : "9572"
	},
	{
		"SuburbName" : "Teviot",
		"Postcode" : "9572"
	},
	{
		"SuburbName" : "Balclutha",
		"Postcode" : "9583"
	},
	{
		"SuburbName" : "Catlins Forest Park",
		"Postcode" : "9583"
	},
	{
		"SuburbName" : "Clinton",
		"Postcode" : "9583"
	},
	{
		"SuburbName" : "Lochindorb",
		"Postcode" : "9583"
	},
	{
		"SuburbName" : "Slopedown",
		"Postcode" : "9583"
	},
	{
		"SuburbName" : "Ashley Downs",
		"Postcode" : "9584"
	},
	{
		"SuburbName" : "Balclutha",
		"Postcode" : "9584"
	},
	{
		"SuburbName" : "CLIFTON",
		"Postcode" : "9584"
	},
	{
		"SuburbName" : "Clinton",
		"Postcode" : "9584"
	},
	{
		"SuburbName" : "Kuriwao",
		"Postcode" : "9584"
	},
	{
		"SuburbName" : "Popotunoa",
		"Postcode" : "9584"
	},
	{
		"SuburbName" : "Wairuna",
		"Postcode" : "9584"
	},
	{
		"SuburbName" : "Waiwera South",
		"Postcode" : "9584"
	},
	{
		"SuburbName" : "New Haven",
		"Postcode" : "9585"
	},
	{
		"SuburbName" : "Owaka",
		"Postcode" : "9585"
	},
	{
		"SuburbName" : "Balclutha",
		"Postcode" : "9586"
	},
	{
		"SuburbName" : "Caberfeidh",
		"Postcode" : "9586"
	},
	{
		"SuburbName" : "Catlins Forest Park",
		"Postcode" : "9586"
	},
	{
		"SuburbName" : "Chaslands",
		"Postcode" : "9586"
	},
	{
		"SuburbName" : "Hinahina",
		"Postcode" : "9586"
	},
	{
		"SuburbName" : "Katea",
		"Postcode" : "9586"
	},
	{
		"SuburbName" : "Lochindorb",
		"Postcode" : "9586"
	},
	{
		"SuburbName" : "Maclennan",
		"Postcode" : "9586"
	},
	{
		"SuburbName" : "New Haven",
		"Postcode" : "9586"
	},
	{
		"SuburbName" : "Owaka",
		"Postcode" : "9586"
	},
	{
		"SuburbName" : "Owaka Valley",
		"Postcode" : "9586"
	},
	{
		"SuburbName" : "Papatowai",
		"Postcode" : "9586"
	},
	{
		"SuburbName" : "Puketiro",
		"Postcode" : "9586"
	},
	{
		"SuburbName" : "Purakauiti",
		"Postcode" : "9586"
	},
	{
		"SuburbName" : "Purakaunui",
		"Postcode" : "9586"
	},
	{
		"SuburbName" : "Purekireki",
		"Postcode" : "9586"
	},
	{
		"SuburbName" : "Tahakopa",
		"Postcode" : "9586"
	},
	{
		"SuburbName" : "Tahakopa Valley",
		"Postcode" : "9586"
	},
	{
		"SuburbName" : "Tarara",
		"Postcode" : "9586"
	},
	{
		"SuburbName" : "Tawanui",
		"Postcode" : "9586"
	},
	{
		"SuburbName" : "Blue Mountains",
		"Postcode" : "9587"
	},
	{
		"SuburbName" : "Crookston",
		"Postcode" : "9587"
	},
	{
		"SuburbName" : "Dunrobin",
		"Postcode" : "9587"
	},
	{
		"SuburbName" : "Edievale",
		"Postcode" : "9587"
	},
	{
		"SuburbName" : "Ettrick",
		"Postcode" : "9587"
	},
	{
		"SuburbName" : "Heriot",
		"Postcode" : "9587"
	},
	{
		"SuburbName" : "Kelso",
		"Postcode" : "9587"
	},
	{
		"SuburbName" : "Leithen Bush",
		"Postcode" : "9587"
	},
	{
		"SuburbName" : "McEwens Bush",
		"Postcode" : "9587"
	},
	{
		"SuburbName" : "Moa Flat",
		"Postcode" : "9587"
	},
	{
		"SuburbName" : "Park Hill",
		"Postcode" : "9587"
	},
	{
		"SuburbName" : "Roxburgh",
		"Postcode" : "9587"
	},
	{
		"SuburbName" : "Tapanui",
		"Postcode" : "9587"
	},
	{
		"SuburbName" : "Wilden",
		"Postcode" : "9587"
	},
	{
		"SuburbName" : "Beaumont",
		"Postcode" : "9591"
	},
	{
		"SuburbName" : "Craigellachie",
		"Postcode" : "9591"
	},
	{
		"SuburbName" : "Evans Flat",
		"Postcode" : "9591"
	},
	{
		"SuburbName" : "Gabriels Gully",
		"Postcode" : "9591"
	},
	{
		"SuburbName" : "Glendhu Forest",
		"Postcode" : "9591"
	},
	{
		"SuburbName" : "Kononi",
		"Postcode" : "9591"
	},
	{
		"SuburbName" : "Lawrence",
		"Postcode" : "9591"
	},
	{
		"SuburbName" : "Tuapeka Flat",
		"Postcode" : "9591"
	},
	{
		"SuburbName" : "Tuapeka West",
		"Postcode" : "9591"
	},
	{
		"SuburbName" : "Berwick Forest",
		"Postcode" : "9593"
	},
	{
		"SuburbName" : "Clydevale",
		"Postcode" : "9593"
	},
	{
		"SuburbName" : "Forsyth",
		"Postcode" : "9593"
	},
	{
		"SuburbName" : "Johnston",
		"Postcode" : "9593"
	},
	{
		"SuburbName" : "Lawrence",
		"Postcode" : "9593"
	},
	{
		"SuburbName" : "Manuka Creek",
		"Postcode" : "9593"
	},
	{
		"SuburbName" : "Outram",
		"Postcode" : "9593"
	},
	{
		"SuburbName" : "Puketi",
		"Postcode" : "9593"
	},
	{
		"SuburbName" : "Tuapeka Flat",
		"Postcode" : "9593"
	},
	{
		"SuburbName" : "Waitahuna",
		"Postcode" : "9593"
	},
	{
		"SuburbName" : "Waitahuna Gully",
		"Postcode" : "9593"
	},
	{
		"SuburbName" : "Waitahuna West",
		"Postcode" : "9593"
	},
	{
		"SuburbName" : "Middlemarch",
		"Postcode" : "9596"
	},
	{
		"SuburbName" : "Ngapuna",
		"Postcode" : "9596"
	},
	{
		"SuburbName" : "Rock And Pillar",
		"Postcode" : "9596"
	},
	{
		"SuburbName" : "Middlemarch",
		"Postcode" : "9597"
	},
	{
		"SuburbName" : "Mount Stoker",
		"Postcode" : "9597"
	},
	{
		"SuburbName" : "Mt Stoker",
		"Postcode" : "9597"
	},
	{
		"SuburbName" : "Pukerangi",
		"Postcode" : "9597"
	},
	{
		"SuburbName" : "Shannon",
		"Postcode" : "9597"
	},
	{
		"SuburbName" : "Sutton",
		"Postcode" : "9597"
	},
	{
		"SuburbName" : "Macraes Flat",
		"Postcode" : "9598"
	},
	{
		"SuburbName" : "Middlemarch",
		"Postcode" : "9598"
	},
	{
		"SuburbName" : "Mount Stoker",
		"Postcode" : "9598"
	},
	{
		"SuburbName" : "Mt Stoker",
		"Postcode" : "9598"
	},
	{
		"SuburbName" : "Nenthorn",
		"Postcode" : "9598"
	},
	{
		"SuburbName" : "Rock And Pillar",
		"Postcode" : "9598"
	},
	{
		"SuburbName" : "Sutton",
		"Postcode" : "9598"
	},
	{
		"SuburbName" : "Centre Hill",
		"Postcode" : "9672"
	},
	{
		"SuburbName" : "Hillside Southland",
		"Postcode" : "9672"
	},
	{
		"SuburbName" : "Manapouri",
		"Postcode" : "9672"
	},
	{
		"SuburbName" : "Mavora",
		"Postcode" : "9672"
	},
	{
		"SuburbName" : "Mossburn",
		"Postcode" : "9672"
	},
	{
		"SuburbName" : "Mount Hamilton",
		"Postcode" : "9672"
	},
	{
		"SuburbName" : "Mount Nicholas",
		"Postcode" : "9672"
	},
	{
		"SuburbName" : "Mount Prospect",
		"Postcode" : "9672"
	},
	{
		"SuburbName" : "MOUNT Hamilton",
		"Postcode" : "9672"
	},
	{
		"SuburbName" : "Mount Nicholas",
		"Postcode" : "9672"
	},
	{
		"SuburbName" : "MOUNT PROSPECT",
		"Postcode" : "9672"
	},
	{
		"SuburbName" : "Te Anau",
		"Postcode" : "9672"
	},
	{
		"SuburbName" : "The Key",
		"Postcode" : "9672"
	},
	{
		"SuburbName" : "Blackmount",
		"Postcode" : "9679"
	},
	{
		"SuburbName" : "Fiordland National Park",
		"Postcode" : "9679"
	},
	{
		"SuburbName" : "Hillside Southland",
		"Postcode" : "9679"
	},
	{
		"SuburbName" : "Manapouri",
		"Postcode" : "9679"
	},
	{
		"SuburbName" : "Milford Sound",
		"Postcode" : "9679"
	},
	{
		"SuburbName" : "Otautau",
		"Postcode" : "9679"
	},
	{
		"SuburbName" : "Te Anau",
		"Postcode" : "9679"
	},
	{
		"SuburbName" : "Te Anau Downs",
		"Postcode" : "9679"
	},
	{
		"SuburbName" : "The Key",
		"Postcode" : "9679"
	},
	{
		"SuburbName" : "Whare Creek",
		"Postcode" : "9679"
	},
	{
		"SuburbName" : "Bald Hill",
		"Postcode" : "9682"
	},
	{
		"SuburbName" : "Birchwood",
		"Postcode" : "9682"
	},
	{
		"SuburbName" : "Blackmount",
		"Postcode" : "9682"
	},
	{
		"SuburbName" : "Eastern Bush",
		"Postcode" : "9682"
	},
	{
		"SuburbName" : "Feldwick",
		"Postcode" : "9682"
	},
	{
		"SuburbName" : "Happy Valley",
		"Postcode" : "9682"
	},
	{
		"SuburbName" : "Merrivale",
		"Postcode" : "9682"
	},
	{
		"SuburbName" : "Monowai",
		"Postcode" : "9682"
	},
	{
		"SuburbName" : "Orawia",
		"Postcode" : "9682"
	},
	{
		"SuburbName" : "Otahu Flat",
		"Postcode" : "9682"
	},
	{
		"SuburbName" : "Otautau",
		"Postcode" : "9682"
	},
	{
		"SuburbName" : "Pukemaori",
		"Postcode" : "9682"
	},
	{
		"SuburbName" : "Raymonds Gap",
		"Postcode" : "9682"
	},
	{
		"SuburbName" : "Riverton",
		"Postcode" : "9682"
	},
	{
		"SuburbName" : "Scotts Gap",
		"Postcode" : "9682"
	},
	{
		"SuburbName" : "Tuatapere",
		"Postcode" : "9682"
	},
	{
		"SuburbName" : "Waikouro",
		"Postcode" : "9682"
	},
	{
		"SuburbName" : "Wairaki",
		"Postcode" : "9682"
	},
	{
		"SuburbName" : "Bayswater",
		"Postcode" : "9683"
	},
	{
		"SuburbName" : "Drummond",
		"Postcode" : "9683"
	},
	{
		"SuburbName" : "Fairfax",
		"Postcode" : "9683"
	},
	{
		"SuburbName" : "Flints Bush",
		"Postcode" : "9683"
	},
	{
		"SuburbName" : "Gladfield",
		"Postcode" : "9683"
	},
	{
		"SuburbName" : "Gropers Bush",
		"Postcode" : "9683"
	},
	{
		"SuburbName" : "Hazletts",
		"Postcode" : "9683"
	},
	{
		"SuburbName" : "Isla Bank",
		"Postcode" : "9683"
	},
	{
		"SuburbName" : "Otautau",
		"Postcode" : "9683"
	},
	{
		"SuburbName" : "Ringway",
		"Postcode" : "9683"
	},
	{
		"SuburbName" : "Riverton",
		"Postcode" : "9683"
	},
	{
		"SuburbName" : "Thornbury",
		"Postcode" : "9683"
	},
	{
		"SuburbName" : "Winton",
		"Postcode" : "9683"
	},
	{
		"SuburbName" : "Aparima",
		"Postcode" : "9689"
	},
	{
		"SuburbName" : "Bayswater",
		"Postcode" : "9689"
	},
	{
		"SuburbName" : "Beaumont",
		"Postcode" : "9689"
	},
	{
		"SuburbName" : "Birchwood",
		"Postcode" : "9689"
	},
	{
		"SuburbName" : "Dunrobin",
		"Postcode" : "9689"
	},
	{
		"SuburbName" : "Gowan Hill",
		"Postcode" : "9689"
	},
	{
		"SuburbName" : "Mount Linton",
		"Postcode" : "9689"
	},
	{
		"SuburbName" : "Mount Linton",
		"Postcode" : "9689"
	},
	{
		"SuburbName" : "Nightcaps",
		"Postcode" : "9689"
	},
	{
		"SuburbName" : "Ohai",
		"Postcode" : "9689"
	},
	{
		"SuburbName" : "Opio",
		"Postcode" : "9689"
	},
	{
		"SuburbName" : "Otautau",
		"Postcode" : "9689"
	},
	{
		"SuburbName" : "Scotts Gap",
		"Postcode" : "9689"
	},
	{
		"SuburbName" : "Tinkertown",
		"Postcode" : "9689"
	},
	{
		"SuburbName" : "Twinlaw",
		"Postcode" : "9689"
	},
	{
		"SuburbName" : "Waikouro",
		"Postcode" : "9689"
	},
	{
		"SuburbName" : "Wairio",
		"Postcode" : "9689"
	},
	{
		"SuburbName" : "Woodlaw",
		"Postcode" : "9689"
	},
	{
		"SuburbName" : "Wreys Bush",
		"Postcode" : "9689"
	},
	{
		"SuburbName" : "Bald Hill",
		"Postcode" : "9691"
	},
	{
		"SuburbName" : "Clifden",
		"Postcode" : "9691"
	},
	{
		"SuburbName" : "Dean",
		"Postcode" : "9691"
	},
	{
		"SuburbName" : "Eastern Bush",
		"Postcode" : "9691"
	},
	{
		"SuburbName" : "Fiordland National Park",
		"Postcode" : "9691"
	},
	{
		"SuburbName" : "Grove Burn",
		"Postcode" : "9691"
	},
	{
		"SuburbName" : "Happy Valley",
		"Postcode" : "9691"
	},
	{
		"SuburbName" : "Lillburn",
		"Postcode" : "9691"
	},
	{
		"SuburbName" : "Merrivale",
		"Postcode" : "9691"
	},
	{
		"SuburbName" : "Orawia",
		"Postcode" : "9691"
	},
	{
		"SuburbName" : "Otautau",
		"Postcode" : "9691"
	},
	{
		"SuburbName" : "Papatotara",
		"Postcode" : "9691"
	},
	{
		"SuburbName" : "Piko Piko",
		"Postcode" : "9691"
	},
	{
		"SuburbName" : "Pukemaori",
		"Postcode" : "9691"
	},
	{
		"SuburbName" : "Riverton",
		"Postcode" : "9691"
	},
	{
		"SuburbName" : "Rowallan",
		"Postcode" : "9691"
	},
	{
		"SuburbName" : "Te Waewae",
		"Postcode" : "9691"
	},
	{
		"SuburbName" : "Tuatapere",
		"Postcode" : "9691"
	},
	{
		"SuburbName" : "Blue Mountains",
		"Postcode" : "9771"
	},
	{
		"SuburbName" : "Clinton",
		"Postcode" : "9771"
	},
	{
		"SuburbName" : "Conical Hill",
		"Postcode" : "9771"
	},
	{
		"SuburbName" : "East Gore",
		"Postcode" : "9771"
	},
	{
		"SuburbName" : "Gore",
		"Postcode" : "9771"
	},
	{
		"SuburbName" : "McNab",
		"Postcode" : "9771"
	},
	{
		"SuburbName" : "Pomahaka",
		"Postcode" : "9771"
	},
	{
		"SuburbName" : "Popotunoa",
		"Postcode" : "9771"
	},
	{
		"SuburbName" : "Pukerau",
		"Postcode" : "9771"
	},
	{
		"SuburbName" : "Rankleburn",
		"Postcode" : "9771"
	},
	{
		"SuburbName" : "Tapanui",
		"Postcode" : "9771"
	},
	{
		"SuburbName" : "Waikaka Valley",
		"Postcode" : "9771"
	},
	{
		"SuburbName" : "Waikoikoi",
		"Postcode" : "9771"
	},
	{
		"SuburbName" : "Waipahi",
		"Postcode" : "9771"
	},
	{
		"SuburbName" : "Wairuna",
		"Postcode" : "9771"
	},
	{
		"SuburbName" : "Willowbank",
		"Postcode" : "9771"
	},
	{
		"SuburbName" : "Charlton",
		"Postcode" : "9772"
	},
	{
		"SuburbName" : "Clinton",
		"Postcode" : "9772"
	},
	{
		"SuburbName" : "Diamond Peak",
		"Postcode" : "9772"
	},
	{
		"SuburbName" : "East Gore",
		"Postcode" : "9772"
	},
	{
		"SuburbName" : "Ferndale",
		"Postcode" : "9772"
	},
	{
		"SuburbName" : "Gore",
		"Postcode" : "9772"
	},
	{
		"SuburbName" : "Kaiwera",
		"Postcode" : "9772"
	},
	{
		"SuburbName" : "Mataura",
		"Postcode" : "9772"
	},
	{
		"SuburbName" : "Otaraia",
		"Postcode" : "9772"
	},
	{
		"SuburbName" : "Pukerau",
		"Postcode" : "9772"
	},
	{
		"SuburbName" : "Slopedown",
		"Postcode" : "9772"
	},
	{
		"SuburbName" : "Tuturau",
		"Postcode" : "9772"
	},
	{
		"SuburbName" : "Upper Charlton",
		"Postcode" : "9772"
	},
	{
		"SuburbName" : "Waikana",
		"Postcode" : "9772"
	},
	{
		"SuburbName" : "Awatere",
		"Postcode" : "9773"
	},
	{
		"SuburbName" : "Chatton",
		"Postcode" : "9773"
	},
	{
		"SuburbName" : "Chatton North",
		"Postcode" : "9773"
	},
	{
		"SuburbName" : "East Chatton",
		"Postcode" : "9773"
	},
	{
		"SuburbName" : "East Gore",
		"Postcode" : "9773"
	},
	{
		"SuburbName" : "Gore",
		"Postcode" : "9773"
	},
	{
		"SuburbName" : "Knapdale",
		"Postcode" : "9773"
	},
	{
		"SuburbName" : "Maitland",
		"Postcode" : "9773"
	},
	{
		"SuburbName" : "Otama",
		"Postcode" : "9773"
	},
	{
		"SuburbName" : "Otamita",
		"Postcode" : "9773"
	},
	{
		"SuburbName" : "Riversdale",
		"Postcode" : "9773"
	},
	{
		"SuburbName" : "Waikaka",
		"Postcode" : "9773"
	},
	{
		"SuburbName" : "Wendon",
		"Postcode" : "9773"
	},
	{
		"SuburbName" : "Wendon Valley",
		"Postcode" : "9773"
	},
	{
		"SuburbName" : "Whiterigg",
		"Postcode" : "9773"
	},
	{
		"SuburbName" : "Brydone",
		"Postcode" : "9774"
	},
	{
		"SuburbName" : "Croydon",
		"Postcode" : "9774"
	},
	{
		"SuburbName" : "Croydon Bush",
		"Postcode" : "9774"
	},
	{
		"SuburbName" : "Gore",
		"Postcode" : "9774"
	},
	{
		"SuburbName" : "Invercargill",
		"Postcode" : "9774"
	},
	{
		"SuburbName" : "Mataura",
		"Postcode" : "9774"
	},
	{
		"SuburbName" : "Pebbly Hill",
		"Postcode" : "9774"
	},
	{
		"SuburbName" : "Te Tipua",
		"Postcode" : "9774"
	},
	{
		"SuburbName" : "Tuturau",
		"Postcode" : "9774"
	},
	{
		"SuburbName" : "Upper Charlton",
		"Postcode" : "9774"
	},
	{
		"SuburbName" : "Waimumu",
		"Postcode" : "9774"
	},
	{
		"SuburbName" : "Waitane",
		"Postcode" : "9774"
	},
	{
		"SuburbName" : "Waitane West",
		"Postcode" : "9774"
	},
	{
		"SuburbName" : "Blue Mountains",
		"Postcode" : "9775"
	},
	{
		"SuburbName" : "Crossans Corner",
		"Postcode" : "9775"
	},
	{
		"SuburbName" : "East Gore",
		"Postcode" : "9775"
	},
	{
		"SuburbName" : "Glenkenich",
		"Postcode" : "9775"
	},
	{
		"SuburbName" : "Gore",
		"Postcode" : "9775"
	},
	{
		"SuburbName" : "Greenvale",
		"Postcode" : "9775"
	},
	{
		"SuburbName" : "Kelso",
		"Postcode" : "9775"
	},
	{
		"SuburbName" : "Knapdale",
		"Postcode" : "9775"
	},
	{
		"SuburbName" : "Leithen Bush",
		"Postcode" : "9775"
	},
	{
		"SuburbName" : "Maitland",
		"Postcode" : "9775"
	},
	{
		"SuburbName" : "McNab",
		"Postcode" : "9775"
	},
	{
		"SuburbName" : "Merino Downs",
		"Postcode" : "9775"
	},
	{
		"SuburbName" : "Tapanui",
		"Postcode" : "9775"
	},
	{
		"SuburbName" : "Te Kiteroa",
		"Postcode" : "9775"
	},
	{
		"SuburbName" : "Waikaka",
		"Postcode" : "9775"
	},
	{
		"SuburbName" : "Waikaka Valley",
		"Postcode" : "9775"
	},
	{
		"SuburbName" : "Waikoikoi",
		"Postcode" : "9775"
	},
	{
		"SuburbName" : "Whiterigg",
		"Postcode" : "9775"
	},
	{
		"SuburbName" : "Willowbank",
		"Postcode" : "9775"
	},
	{
		"SuburbName" : "Ardlussa",
		"Postcode" : "9776"
	},
	{
		"SuburbName" : "Balfour",
		"Postcode" : "9776"
	},
	{
		"SuburbName" : "Croydon",
		"Postcode" : "9776"
	},
	{
		"SuburbName" : "Croydon Bush",
		"Postcode" : "9776"
	},
	{
		"SuburbName" : "Glenure",
		"Postcode" : "9776"
	},
	{
		"SuburbName" : "Gore",
		"Postcode" : "9776"
	},
	{
		"SuburbName" : "Kingston Crossing",
		"Postcode" : "9776"
	},
	{
		"SuburbName" : "Longridge",
		"Postcode" : "9776"
	},
	{
		"SuburbName" : "Longridge North",
		"Postcode" : "9776"
	},
	{
		"SuburbName" : "Lumsden",
		"Postcode" : "9776"
	},
	{
		"SuburbName" : "Mandeville",
		"Postcode" : "9776"
	},
	{
		"SuburbName" : "Nine Mile",
		"Postcode" : "9776"
	},
	{
		"SuburbName" : "Otamita",
		"Postcode" : "9776"
	},
	{
		"SuburbName" : "Riversdale",
		"Postcode" : "9776"
	},
	{
		"SuburbName" : "Sandstone",
		"Postcode" : "9776"
	},
	{
		"SuburbName" : "Waimea",
		"Postcode" : "9776"
	},
	{
		"SuburbName" : "Waiparu",
		"Postcode" : "9776"
	},
	{
		"SuburbName" : "Waipounamu",
		"Postcode" : "9776"
	},
	{
		"SuburbName" : "Ardlussa",
		"Postcode" : "9777"
	},
	{
		"SuburbName" : "Cattle Flat",
		"Postcode" : "9777"
	},
	{
		"SuburbName" : "Chatton North",
		"Postcode" : "9777"
	},
	{
		"SuburbName" : "Croydon Bush",
		"Postcode" : "9777"
	},
	{
		"SuburbName" : "Dipton",
		"Postcode" : "9777"
	},
	{
		"SuburbName" : "Freshford",
		"Postcode" : "9777"
	},
	{
		"SuburbName" : "Gore",
		"Postcode" : "9777"
	},
	{
		"SuburbName" : "Hokonui Hills",
		"Postcode" : "9777"
	},
	{
		"SuburbName" : "Mandeville",
		"Postcode" : "9777"
	},
	{
		"SuburbName" : "Nine Mile",
		"Postcode" : "9777"
	},
	{
		"SuburbName" : "Otama",
		"Postcode" : "9777"
	},
	{
		"SuburbName" : "Otamita",
		"Postcode" : "9777"
	},
	{
		"SuburbName" : "Riversdale",
		"Postcode" : "9777"
	},
	{
		"SuburbName" : "Upper Charlton",
		"Postcode" : "9777"
	},
	{
		"SuburbName" : "Waikaia",
		"Postcode" : "9777"
	},
	{
		"SuburbName" : "Waiparu",
		"Postcode" : "9777"
	},
	{
		"SuburbName" : "Waipounamu",
		"Postcode" : "9777"
	},
	{
		"SuburbName" : "Wendon",
		"Postcode" : "9777"
	},
	{
		"SuburbName" : "Wendonside",
		"Postcode" : "9777"
	},
	{
		"SuburbName" : "Argyle Hill",
		"Postcode" : "9778"
	},
	{
		"SuburbName" : "Cattle Flat",
		"Postcode" : "9778"
	},
	{
		"SuburbName" : "Piano Flat",
		"Postcode" : "9778"
	},
	{
		"SuburbName" : "Potters",
		"Postcode" : "9778"
	},
	{
		"SuburbName" : "Riversdale",
		"Postcode" : "9778"
	},
	{
		"SuburbName" : "Steeple Burn",
		"Postcode" : "9778"
	},
	{
		"SuburbName" : "Waikaia",
		"Postcode" : "9778"
	},
	{
		"SuburbName" : "Balfour",
		"Postcode" : "9779"
	},
	{
		"SuburbName" : "Glenure",
		"Postcode" : "9779"
	},
	{
		"SuburbName" : "Josephville",
		"Postcode" : "9779"
	},
	{
		"SuburbName" : "Kingston Crossing",
		"Postcode" : "9779"
	},
	{
		"SuburbName" : "Lintley",
		"Postcode" : "9779"
	},
	{
		"SuburbName" : "Longridge North",
		"Postcode" : "9779"
	},
	{
		"SuburbName" : "Lumsden",
		"Postcode" : "9779"
	},
	{
		"SuburbName" : "Riversdale",
		"Postcode" : "9779"
	},
	{
		"SuburbName" : "St Patricks",
		"Postcode" : "9779"
	},
	{
		"SuburbName" : "Browns",
		"Postcode" : "9781"
	},
	{
		"SuburbName" : "Invercargill",
		"Postcode" : "9781"
	},
	{
		"SuburbName" : "Lochiel",
		"Postcode" : "9781"
	},
	{
		"SuburbName" : "Mabel Bush",
		"Postcode" : "9781"
	},
	{
		"SuburbName" : "Ryal Bush",
		"Postcode" : "9781"
	},
	{
		"SuburbName" : "Springhills",
		"Postcode" : "9781"
	},
	{
		"SuburbName" : "Thomsons Crossing",
		"Postcode" : "9781"
	},
	{
		"SuburbName" : "Tussock Creek",
		"Postcode" : "9781"
	},
	{
		"SuburbName" : "Winton",
		"Postcode" : "9781"
	},
	{
		"SuburbName" : "Benmore",
		"Postcode" : "9782"
	},
	{
		"SuburbName" : "Browns",
		"Postcode" : "9782"
	},
	{
		"SuburbName" : "Centre Bush",
		"Postcode" : "9782"
	},
	{
		"SuburbName" : "Dipton",
		"Postcode" : "9782"
	},
	{
		"SuburbName" : "Hokonui",
		"Postcode" : "9782"
	},
	{
		"SuburbName" : "Hokonui Hills",
		"Postcode" : "9782"
	},
	{
		"SuburbName" : "Kauana",
		"Postcode" : "9782"
	},
	{
		"SuburbName" : "Limehills",
		"Postcode" : "9782"
	},
	{
		"SuburbName" : "Otapiri",
		"Postcode" : "9782"
	},
	{
		"SuburbName" : "Otapiri Gorge",
		"Postcode" : "9782"
	},
	{
		"SuburbName" : "Springhills",
		"Postcode" : "9782"
	},
	{
		"SuburbName" : "Thomsons Crossing",
		"Postcode" : "9782"
	},
	{
		"SuburbName" : "Winton",
		"Postcode" : "9782"
	},
	{
		"SuburbName" : "Bayswater",
		"Postcode" : "9783"
	},
	{
		"SuburbName" : "Castle Downs",
		"Postcode" : "9783"
	},
	{
		"SuburbName" : "Centre Bush",
		"Postcode" : "9783"
	},
	{
		"SuburbName" : "Dipton West",
		"Postcode" : "9783"
	},
	{
		"SuburbName" : "Drummond",
		"Postcode" : "9783"
	},
	{
		"SuburbName" : "Dunearn",
		"Postcode" : "9783"
	},
	{
		"SuburbName" : "Five Roads",
		"Postcode" : "9783"
	},
	{
		"SuburbName" : "Heddon Bush",
		"Postcode" : "9783"
	},
	{
		"SuburbName" : "Invercargill",
		"Postcode" : "9783"
	},
	{
		"SuburbName" : "Limehills",
		"Postcode" : "9783"
	},
	{
		"SuburbName" : "Oreti Plains",
		"Postcode" : "9783"
	},
	{
		"SuburbName" : "Otapiri",
		"Postcode" : "9783"
	},
	{
		"SuburbName" : "Otautau",
		"Postcode" : "9783"
	},
	{
		"SuburbName" : "South Hillend",
		"Postcode" : "9783"
	},
	{
		"SuburbName" : "Taringatura",
		"Postcode" : "9783"
	},
	{
		"SuburbName" : "Winton",
		"Postcode" : "9783"
	},
	{
		"SuburbName" : "Barnhill",
		"Postcode" : "9791"
	},
	{
		"SuburbName" : "Benmore",
		"Postcode" : "9791"
	},
	{
		"SuburbName" : "Caroline",
		"Postcode" : "9791"
	},
	{
		"SuburbName" : "Castle Downs",
		"Postcode" : "9791"
	},
	{
		"SuburbName" : "Dipton",
		"Postcode" : "9791"
	},
	{
		"SuburbName" : "Dipton West",
		"Postcode" : "9791"
	},
	{
		"SuburbName" : "Hokonui Hills",
		"Postcode" : "9791"
	},
	{
		"SuburbName" : "Lumsden",
		"Postcode" : "9791"
	},
	{
		"SuburbName" : "South Hillend",
		"Postcode" : "9791"
	},
	{
		"SuburbName" : "Wether Hill",
		"Postcode" : "9791"
	},
	{
		"SuburbName" : "Winton",
		"Postcode" : "9791"
	},
	{
		"SuburbName" : "Barnhill",
		"Postcode" : "9792"
	},
	{
		"SuburbName" : "Braxton",
		"Postcode" : "9792"
	},
	{
		"SuburbName" : "Castle Downs",
		"Postcode" : "9792"
	},
	{
		"SuburbName" : "Castlerock",
		"Postcode" : "9792"
	},
	{
		"SuburbName" : "Centre Hill",
		"Postcode" : "9792"
	},
	{
		"SuburbName" : "Dipton",
		"Postcode" : "9792"
	},
	{
		"SuburbName" : "Dipton West",
		"Postcode" : "9792"
	},
	{
		"SuburbName" : "Dunrobin",
		"Postcode" : "9792"
	},
	{
		"SuburbName" : "Hamilton Burn",
		"Postcode" : "9792"
	},
	{
		"SuburbName" : "Lumsden",
		"Postcode" : "9792"
	},
	{
		"SuburbName" : "Mossburn",
		"Postcode" : "9792"
	},
	{
		"SuburbName" : "Otautau",
		"Postcode" : "9792"
	},
	{
		"SuburbName" : "Wether Hill",
		"Postcode" : "9792"
	},
	{
		"SuburbName" : "Acton",
		"Postcode" : "9793"
	},
	{
		"SuburbName" : "Athol",
		"Postcode" : "9793"
	},
	{
		"SuburbName" : "Fairlight",
		"Postcode" : "9793"
	},
	{
		"SuburbName" : "Garston",
		"Postcode" : "9793"
	},
	{
		"SuburbName" : "Irthing",
		"Postcode" : "9793"
	},
	{
		"SuburbName" : "Kingston",
		"Postcode" : "9793"
	},
	{
		"SuburbName" : "Lower Nevis",
		"Postcode" : "9793"
	},
	{
		"SuburbName" : "Lowther",
		"Postcode" : "9793"
	},
	{
		"SuburbName" : "Lumsden",
		"Postcode" : "9793"
	},
	{
		"SuburbName" : "Mid Dome",
		"Postcode" : "9793"
	},
	{
		"SuburbName" : "Mossburn",
		"Postcode" : "9793"
	},
	{
		"SuburbName" : "Nokomai",
		"Postcode" : "9793"
	},
	{
		"SuburbName" : "Parawa",
		"Postcode" : "9793"
	},
	{
		"SuburbName" : "Te Anau",
		"Postcode" : "9793"
	},
	{
		"SuburbName" : "Walter Peak",
		"Postcode" : "9793"
	},
	{
		"SuburbName" : "West Dome",
		"Postcode" : "9793"
	},
	{
		"SuburbName" : "Balfour",
		"Postcode" : "9794"
	},
	{
		"SuburbName" : "Josephville",
		"Postcode" : "9794"
	},
	{
		"SuburbName" : "Lintley",
		"Postcode" : "9794"
	},
	{
		"SuburbName" : "Longridge North",
		"Postcode" : "9794"
	},
	{
		"SuburbName" : "Lumsden",
		"Postcode" : "9794"
	},
	{
		"SuburbName" : "Riversdale",
		"Postcode" : "9794"
	},
	{
		"SuburbName" : "Invercargill Airport",
		"Postcode" : "9810"
	},
	{
		"SuburbName" : "Ascot",
		"Postcode" : "9871"
	},
	{
		"SuburbName" : "Dacre",
		"Postcode" : "9871"
	},
	{
		"SuburbName" : "Hawthorndale",
		"Postcode" : "9871"
	},
	{
		"SuburbName" : "Invercargill",
		"Postcode" : "9871"
	},
	{
		"SuburbName" : "Kapuka",
		"Postcode" : "9871"
	},
	{
		"SuburbName" : "Kennington",
		"Postcode" : "9871"
	},
	{
		"SuburbName" : "Longbush",
		"Postcode" : "9871"
	},
	{
		"SuburbName" : "Mabel Bush",
		"Postcode" : "9871"
	},
	{
		"SuburbName" : "Mill Road",
		"Postcode" : "9871"
	},
	{
		"SuburbName" : "Mokotua",
		"Postcode" : "9871"
	},
	{
		"SuburbName" : "Morton Mains",
		"Postcode" : "9871"
	},
	{
		"SuburbName" : "Oteramika",
		"Postcode" : "9871"
	},
	{
		"SuburbName" : "Rimu",
		"Postcode" : "9871"
	},
	{
		"SuburbName" : "Rockdale",
		"Postcode" : "9871"
	},
	{
		"SuburbName" : "Roslyn Bush",
		"Postcode" : "9871"
	},
	{
		"SuburbName" : "Seaward Bush",
		"Postcode" : "9871"
	},
	{
		"SuburbName" : "Seaward Downs",
		"Postcode" : "9871"
	},
	{
		"SuburbName" : "Tisbury",
		"Postcode" : "9871"
	},
	{
		"SuburbName" : "Woodlands",
		"Postcode" : "9871"
	},
	{
		"SuburbName" : "Wyndham",
		"Postcode" : "9871"
	},
	{
		"SuburbName" : "Ascot",
		"Postcode" : "9872"
	},
	{
		"SuburbName" : "Dacre",
		"Postcode" : "9872"
	},
	{
		"SuburbName" : "Dunsdale",
		"Postcode" : "9872"
	},
	{
		"SuburbName" : "Edendale",
		"Postcode" : "9872"
	},
	{
		"SuburbName" : "Glencoe",
		"Postcode" : "9872"
	},
	{
		"SuburbName" : "Grove Bush",
		"Postcode" : "9872"
	},
	{
		"SuburbName" : "Hedgehope",
		"Postcode" : "9872"
	},
	{
		"SuburbName" : "Invercargill",
		"Postcode" : "9872"
	},
	{
		"SuburbName" : "Mabel Bush",
		"Postcode" : "9872"
	},
	{
		"SuburbName" : "Mataura",
		"Postcode" : "9872"
	},
	{
		"SuburbName" : "Mill Road",
		"Postcode" : "9872"
	},
	{
		"SuburbName" : "Myross Bush",
		"Postcode" : "9872"
	},
	{
		"SuburbName" : "Pebbly Hill",
		"Postcode" : "9872"
	},
	{
		"SuburbName" : "Rakahouka",
		"Postcode" : "9872"
	},
	{
		"SuburbName" : "Roslyn Bush",
		"Postcode" : "9872"
	},
	{
		"SuburbName" : "Te Tipua",
		"Postcode" : "9872"
	},
	{
		"SuburbName" : "Waihopai",
		"Postcode" : "9872"
	},
	{
		"SuburbName" : "Woodlands",
		"Postcode" : "9872"
	},
	{
		"SuburbName" : "Argyle Corner",
		"Postcode" : "9874"
	},
	{
		"SuburbName" : "Branxholme",
		"Postcode" : "9874"
	},
	{
		"SuburbName" : "Five Roads",
		"Postcode" : "9874"
	},
	{
		"SuburbName" : "Invercargill",
		"Postcode" : "9874"
	},
	{
		"SuburbName" : "Isla Bank",
		"Postcode" : "9874"
	},
	{
		"SuburbName" : "Lorneville",
		"Postcode" : "9874"
	},
	{
		"SuburbName" : "Makarewa",
		"Postcode" : "9874"
	},
	{
		"SuburbName" : "Northope",
		"Postcode" : "9874"
	},
	{
		"SuburbName" : "Oporo",
		"Postcode" : "9874"
	},
	{
		"SuburbName" : "Otahuti",
		"Postcode" : "9874"
	},
	{
		"SuburbName" : "Otautau",
		"Postcode" : "9874"
	},
	{
		"SuburbName" : "Ryal Bush",
		"Postcode" : "9874"
	},
	{
		"SuburbName" : "Spar Bush",
		"Postcode" : "9874"
	},
	{
		"SuburbName" : "Taramoa",
		"Postcode" : "9874"
	},
	{
		"SuburbName" : "Underwood",
		"Postcode" : "9874"
	},
	{
		"SuburbName" : "Waianiwa",
		"Postcode" : "9874"
	},
	{
		"SuburbName" : "Waikiwi",
		"Postcode" : "9874"
	},
	{
		"SuburbName" : "Wallacetown",
		"Postcode" : "9874"
	},
	{
		"SuburbName" : "West Plains",
		"Postcode" : "9874"
	},
	{
		"SuburbName" : "Wrights Bush",
		"Postcode" : "9874"
	},
	{
		"SuburbName" : "Ashers",
		"Postcode" : "9875"
	},
	{
		"SuburbName" : "Awarua Wetlands",
		"Postcode" : "9875"
	},
	{
		"SuburbName" : "Catlins Forest Park",
		"Postcode" : "9875"
	},
	{
		"SuburbName" : "Fortrose",
		"Postcode" : "9875"
	},
	{
		"SuburbName" : "Gorge Road",
		"Postcode" : "9875"
	},
	{
		"SuburbName" : "Haldane",
		"Postcode" : "9875"
	},
	{
		"SuburbName" : "Invercargill",
		"Postcode" : "9875"
	},
	{
		"SuburbName" : "Kapuka",
		"Postcode" : "9875"
	},
	{
		"SuburbName" : "Kapuka South",
		"Postcode" : "9875"
	},
	{
		"SuburbName" : "Otara",
		"Postcode" : "9875"
	},
	{
		"SuburbName" : "Tokanui",
		"Postcode" : "9875"
	},
	{
		"SuburbName" : "Waimahaka",
		"Postcode" : "9875"
	},
	{
		"SuburbName" : "Waimahaka",
		"Postcode" : "9875"
	},
	{
		"SuburbName" : "Wyndham",
		"Postcode" : "9875"
	},
	{
		"SuburbName" : "Anderson Park",
		"Postcode" : "9876"
	},
	{
		"SuburbName" : "Branxholme",
		"Postcode" : "9876"
	},
	{
		"SuburbName" : "Grove Bush",
		"Postcode" : "9876"
	},
	{
		"SuburbName" : "Invercargill",
		"Postcode" : "9876"
	},
	{
		"SuburbName" : "Lorneville",
		"Postcode" : "9876"
	},
	{
		"SuburbName" : "LORNEVILLE",
		"Postcode" : "9876"
	},
	{
		"SuburbName" : "Makarewa",
		"Postcode" : "9876"
	},
	{
		"SuburbName" : "Myross Bush",
		"Postcode" : "9876"
	},
	{
		"SuburbName" : "Rakahouka",
		"Postcode" : "9876"
	},
	{
		"SuburbName" : "Roslyn Bush",
		"Postcode" : "9876"
	},
	{
		"SuburbName" : "Ryal Bush",
		"Postcode" : "9876"
	},
	{
		"SuburbName" : "Tussock Creek",
		"Postcode" : "9876"
	},
	{
		"SuburbName" : "Waihopai",
		"Postcode" : "9876"
	},
	{
		"SuburbName" : "Waikiwi",
		"Postcode" : "9876"
	},
	{
		"SuburbName" : "Winton",
		"Postcode" : "9876"
	},
	{
		"SuburbName" : "Awarua",
		"Postcode" : "9877"
	},
	{
		"SuburbName" : "Awarua Plains",
		"Postcode" : "9877"
	},
	{
		"SuburbName" : "Awarua Wetlands",
		"Postcode" : "9877"
	},
	{
		"SuburbName" : "Bluff",
		"Postcode" : "9877"
	},
	{
		"SuburbName" : "CLIFTON",
		"Postcode" : "9877"
	},
	{
		"SuburbName" : "Colyers Island",
		"Postcode" : "9877"
	},
	{
		"SuburbName" : "Greenhills",
		"Postcode" : "9877"
	},
	{
		"SuburbName" : "Greenpoint",
		"Postcode" : "9877"
	},
	{
		"SuburbName" : "Invercargill",
		"Postcode" : "9877"
	},
	{
		"SuburbName" : "Kennington",
		"Postcode" : "9877"
	},
	{
		"SuburbName" : "Kingswell",
		"Postcode" : "9877"
	},
	{
		"SuburbName" : "Mokotua",
		"Postcode" : "9877"
	},
	{
		"SuburbName" : "Motu Rimu",
		"Postcode" : "9877"
	},
	{
		"SuburbName" : "Ocean Beach",
		"Postcode" : "9877"
	},
	{
		"SuburbName" : "Omaui",
		"Postcode" : "9877"
	},
	{
		"SuburbName" : "Rimu",
		"Postcode" : "9877"
	},
	{
		"SuburbName" : "Rockdale",
		"Postcode" : "9877"
	},
	{
		"SuburbName" : "Seaward Bush",
		"Postcode" : "9877"
	},
	{
		"SuburbName" : "Tisbury",
		"Postcode" : "9877"
	},
	{
		"SuburbName" : "Tiwai Point",
		"Postcode" : "9877"
	},
	{
		"SuburbName" : "Waimatua",
		"Postcode" : "9877"
	},
	{
		"SuburbName" : "Woodend",
		"Postcode" : "9877"
	},
	{
		"SuburbName" : "Invercargill",
		"Postcode" : "9879"
	},
	{
		"SuburbName" : "Invercargill Airport",
		"Postcode" : "9879"
	},
	{
		"SuburbName" : "New River Ferry",
		"Postcode" : "9879"
	},
	{
		"SuburbName" : "Oreti Beach",
		"Postcode" : "9879"
	},
	{
		"SuburbName" : "Otatara",
		"Postcode" : "9879"
	},
	{
		"SuburbName" : "Sandy Point",
		"Postcode" : "9879"
	},
	{
		"SuburbName" : "Taramoa",
		"Postcode" : "9879"
	},
	{
		"SuburbName" : "West Invercargill",
		"Postcode" : "9879"
	},
	{
		"SuburbName" : "West Plains",
		"Postcode" : "9879"
	},
	{
		"SuburbName" : "Bald Hill",
		"Postcode" : "9881"
	},
	{
		"SuburbName" : "Colac Bay",
		"Postcode" : "9881"
	},
	{
		"SuburbName" : "Happy Valley",
		"Postcode" : "9881"
	},
	{
		"SuburbName" : "Longwood",
		"Postcode" : "9881"
	},
	{
		"SuburbName" : "Orepuki",
		"Postcode" : "9881"
	},
	{
		"SuburbName" : "Pahia",
		"Postcode" : "9881"
	},
	{
		"SuburbName" : "Riverton",
		"Postcode" : "9881"
	},
	{
		"SuburbName" : "Round Hill",
		"Postcode" : "9881"
	},
	{
		"SuburbName" : "Ruahine",
		"Postcode" : "9881"
	},
	{
		"SuburbName" : "Te Waewae",
		"Postcode" : "9881"
	},
	{
		"SuburbName" : "Tihaka",
		"Postcode" : "9881"
	},
	{
		"SuburbName" : "Tuatapere",
		"Postcode" : "9881"
	},
	{
		"SuburbName" : "Waihoaka",
		"Postcode" : "9881"
	},
	{
		"SuburbName" : "Waipango",
		"Postcode" : "9881"
	},
	{
		"SuburbName" : "Wakapatu",
		"Postcode" : "9881"
	},
	{
		"SuburbName" : "Ermedale",
		"Postcode" : "9883"
	},
	{
		"SuburbName" : "Flints Bush",
		"Postcode" : "9883"
	},
	{
		"SuburbName" : "Gropers Bush",
		"Postcode" : "9883"
	},
	{
		"SuburbName" : "Gummies Bush",
		"Postcode" : "9883"
	},
	{
		"SuburbName" : "Invercargill",
		"Postcode" : "9883"
	},
	{
		"SuburbName" : "Otaitai Bush",
		"Postcode" : "9883"
	},
	{
		"SuburbName" : "Otautau",
		"Postcode" : "9883"
	},
	{
		"SuburbName" : "Pourakino Valley",
		"Postcode" : "9883"
	},
	{
		"SuburbName" : "Riverton",
		"Postcode" : "9883"
	},
	{
		"SuburbName" : "Riverton Racecourse",
		"Postcode" : "9883"
	},
	{
		"SuburbName" : "Taramoa",
		"Postcode" : "9883"
	},
	{
		"SuburbName" : "Thornbury",
		"Postcode" : "9883"
	},
	{
		"SuburbName" : "Waimatuku",
		"Postcode" : "9883"
	},
	{
		"SuburbName" : "Waipango",
		"Postcode" : "9883"
	},
	{
		"SuburbName" : "Wrights Bush",
		"Postcode" : "9883"
	},
	{
		"SuburbName" : "Catlins Forest Park",
		"Postcode" : "9884"
	},
	{
		"SuburbName" : "Curio Bay",
		"Postcode" : "9884"
	},
	{
		"SuburbName" : "Haldane",
		"Postcode" : "9884"
	},
	{
		"SuburbName" : "Niagara",
		"Postcode" : "9884"
	},
	{
		"SuburbName" : "Otara",
		"Postcode" : "9884"
	},
	{
		"SuburbName" : "Progress Valley",
		"Postcode" : "9884"
	},
	{
		"SuburbName" : "Quarry Hills",
		"Postcode" : "9884"
	},
	{
		"SuburbName" : "Slope Point",
		"Postcode" : "9884"
	},
	{
		"SuburbName" : "Tokanui",
		"Postcode" : "9884"
	},
	{
		"SuburbName" : "TOKANUI",
		"Postcode" : "9884"
	},
	{
		"SuburbName" : "Waikawa",
		"Postcode" : "9884"
	},
	{
		"SuburbName" : "Waikawa Valley",
		"Postcode" : "9884"
	},
	{
		"SuburbName" : "Edendale",
		"Postcode" : "9891"
	},
	{
		"SuburbName" : "Fortification",
		"Postcode" : "9891"
	},
	{
		"SuburbName" : "Glenham",
		"Postcode" : "9891"
	},
	{
		"SuburbName" : "Mataura Island",
		"Postcode" : "9891"
	},
	{
		"SuburbName" : "Menzies Ferry",
		"Postcode" : "9891"
	},
	{
		"SuburbName" : "Pine Bush",
		"Postcode" : "9891"
	},
	{
		"SuburbName" : "Quarry Hills",
		"Postcode" : "9891"
	},
	{
		"SuburbName" : "Redan",
		"Postcode" : "9891"
	},
	{
		"SuburbName" : "Tokanui",
		"Postcode" : "9891"
	},
	{
		"SuburbName" : "Waimahaka",
		"Postcode" : "9891"
	},
	{
		"SuburbName" : "Wyndham",
		"Postcode" : "9891"
	},
	{
		"SuburbName" : "Catlins Forest Park",
		"Postcode" : "9892"
	},
	{
		"SuburbName" : "Clinton",
		"Postcode" : "9892"
	},
	{
		"SuburbName" : "Ferndale",
		"Postcode" : "9892"
	},
	{
		"SuburbName" : "Glenham",
		"Postcode" : "9892"
	},
	{
		"SuburbName" : "Mataura",
		"Postcode" : "9892"
	},
	{
		"SuburbName" : "Mimihau",
		"Postcode" : "9892"
	},
	{
		"SuburbName" : "Mokoreta",
		"Postcode" : "9892"
	},
	{
		"SuburbName" : "Owaka",
		"Postcode" : "9892"
	},
	{
		"SuburbName" : "Oware",
		"Postcode" : "9892"
	},
	{
		"SuburbName" : "Redan",
		"Postcode" : "9892"
	},
	{
		"SuburbName" : "Slopedown",
		"Postcode" : "9892"
	},
	{
		"SuburbName" : "Tahakopa Valley",
		"Postcode" : "9892"
	},
	{
		"SuburbName" : "Tuturau",
		"Postcode" : "9892"
	},
	{
		"SuburbName" : "Wyndham",
		"Postcode" : "9892"
	},
	{
		"SuburbName" : "Ashers",
		"Postcode" : "9893"
	},
	{
		"SuburbName" : "Brydone",
		"Postcode" : "9893"
	},
	{
		"SuburbName" : "Dacre",
		"Postcode" : "9893"
	},
	{
		"SuburbName" : "Edendale",
		"Postcode" : "9893"
	},
	{
		"SuburbName" : "Gorge Road",
		"Postcode" : "9893"
	},
	{
		"SuburbName" : "Invercargill",
		"Postcode" : "9893"
	},
	{
		"SuburbName" : "Kamahi",
		"Postcode" : "9893"
	},
	{
		"SuburbName" : "Kapuka",
		"Postcode" : "9893"
	},
	{
		"SuburbName" : "Mataura",
		"Postcode" : "9893"
	},
	{
		"SuburbName" : "Menzies Ferry",
		"Postcode" : "9893"
	},
	{
		"SuburbName" : "Morton Mains",
		"Postcode" : "9893"
	},
	{
		"SuburbName" : "Oteramika",
		"Postcode" : "9893"
	},
	{
		"SuburbName" : "Seaward Downs",
		"Postcode" : "9893"
	},
	{
		"SuburbName" : "Wyndham",
		"Postcode" : "9893"
	}
]
