import { createReducer } from "@reduxjs/toolkit";
import { addDays } from "../components/Utils/dateTime";
import _ from "lodash";

const initialState = {
  messagesLoading: false,
};

const system = createReducer(initialState, {
  MESSAGES_LOADED: (state, action) => {
    return {
      ...state,
      messagesLoading: false,
    };
  },
  MESSAGES_LOADING: (state, action) => {
    return {
      ...state,
      messagesLoading: true,
    };
  },
  GET_MESSAGES: (state, action) => {
    return {
      ...state,
      messages: action.payload,
      messagesLoading: false,
    };
  },
  EDIT_FIELD: (state, action) => {
    const {
      payload: { index, value, field, width },
    } = action;

    let inches;
    let inchesArr = [];
    let linePosition = 1.75;
    let lines = [];

    if (field === "message1") {
      debugger;
      for (let i = 0; i < width.length; i++) {
        // inches = width.map((i) => i.getBoundingClientRect().width);
        inchesArr.push(
          (width[i].getBoundingClientRect().width / 96 / 2 + 2).toFixed(2) - 1
        );
      }
      lines = value.split("\n").map((m, index) => {
        if (index > 0) {
          linePosition += 0.25;
        }
        return {
          lx: inchesArr[index],
          ly: linePosition,
          text: m.replace("’", "'"),
        };
      });
    } else {
      inches = width / 96 / 2 + 2;
      lines = state.messages[index].message1.split("\n").map((m, index) => {
        if (index > 0) {
          linePosition += 0.25;
        }
        return { lx: inchesArr[index], ly: linePosition, text: m };
      });
    }
    if (field === "duration" || field === "start") {
      let messages = [...state.messages];
      if (field === "duration") {
        messages[index][field] = parseInt(value);
      } else {
        messages[index][field] = value;
      }
      messages.map((m, index) => {
        if (index === 1) {
          m.end = addDays(messages[index].start, messages[index].duration - 1);
        }
        if (index > 1) {
          m.start = addDays(
            messages[index - 1].start,
            messages[index - 1].duration
          );
          m.end = addDays(messages[index].start, messages[index].duration - 1);
        }
        return m;
      });
      state.messages = messages;
    } else {
      state.messages[index][field] = value;

      let messages = [...state.messages];
      // console.log(messages[index].messageObj);
      let messageObj = messages[index].messageObj;
      switch (field) {
        case "greeting":
          messageObj.greeting = {
            lx: 1.55,
            ly: 1.25,
            text: value + " {{name}}",
            fontSize: 30,
          };
          break;
        case "message1":
          messageObj.body = {
            lines: lines,
            fontSize: 30,
          };
          break;
        case "signedBy":
          messageObj.signature = {
            lx: 1.8,
            ly: lines[lines.length - 1].ly + 0.5,
            fontSize: 30,
            text: value,
          };
          break;
        default:
          break;
      }
      state.messages[index].messageObj = messageObj;
    }
  },
  REMOVE_MESSAGE_FROM_STORE: (state, action) => {
    const { payload } = action;
    let newArr = _.cloneDeep([...state.messages]);
    newArr.splice(payload, 1);

    newArr.map((m, index) => {
      if (index === 1) {
        m.end = addDays(newArr[index].start, newArr[index].duration - 1);
      }
      if (index > 1) {
        m.start = addDays(newArr[index - 1].start, newArr[index - 1].duration);
        m.end = addDays(newArr[index].start, newArr[index].duration - 1);
      }
      return m;
    });

    state.messages = newArr;
  },
  SWAP_AND_UPDATE: (state, action) => {
    return {
      ...state,
      messages: action.payload,
    };
  },
});

export default system;
