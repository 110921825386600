import React, { useState, useEffect } from "react";
import "./control.scss";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import PrintCourierCompost from "./PrintCourierCompost";
import UpdateTransitTimes from "./UpdateTransitTimes";
import UpdateNZPostClaimPrices from "./UpdateNZPostClaimPrices";
import UpdateDefaultCourier from "./UpdateDefaultCourier";
import AutoPilotPopup from "./AutoPilotFlavourPopup";
import GenerateReturnTicketPopUp from "./GenerateReturnTicketPopUp";
import GenerateManualTicketPopUp from "./GenerateManualTicketPopUp";
import { setSnackBarProps } from "../../reduxActions/snackbar";
import { connect } from "react-redux";
import Snacky from "../Shared/Snacky";

const Control = (props) => {
  let history = useHistory();

  const [courierCompostPopup, setCourierCompost] = useState(false);
  const [transitHoursPopup, setTransitHoursPopup] = useState(false);
  const [nzpostClaimsPopup, setNzpostClaimsPopup] = useState(false);
  const [defautlCourierPopup, setDefaultCourierPopup] = useState(false);
  const [autoPilotPopup, setAutoPilotPopup] = useState(false);
  const [returnLabelPopup, setreturnLabelPopup] = useState(false);
  const [manualLabelPopup, setManualLabelPopup] = useState(false);

  const goToPage = (page) => {
    history.push(`/${page}`);
  };

  return (
    <div className="control-container" style={{
      height: "95vH",
      overflow: "auto"
    }}>
      <Snacky snackprops={props.snackbar} setSnackBarProps={props.setSnackBarProps} />
      {returnLabelPopup && (
        <GenerateReturnTicketPopUp
          open={returnLabelPopup}
          handleClose={() => setreturnLabelPopup(false)}
          setSnackBarProps={props.setSnackBarProps}
        />
      )}
      {courierCompostPopup && (
        <PrintCourierCompost
          open={courierCompostPopup}
          handleClose={() => setCourierCompost(false)}
          setSnackBarProps={props.setSnackBarProps}
        />
      )}
      {manualLabelPopup && (
        <GenerateManualTicketPopUp
          open={manualLabelPopup}
          handleClose={() => setManualLabelPopup(false)}
          setSnackBarProps={props.setSnackBarProps}
        />
      )}
      {transitHoursPopup && (
        <UpdateTransitTimes
          open={transitHoursPopup}
          handleClose={() => setTransitHoursPopup(false)}
          setSnackBarProps={props.setSnackBarProps}
        />
      )}
      {nzpostClaimsPopup && (
        <UpdateNZPostClaimPrices
          open={nzpostClaimsPopup}
          handleClose={(showSuccess = false) => {
            if (showSuccess) {
              props.setSnackBarProps("success", "Saved successfully", true)
            }
            setNzpostClaimsPopup(false)
          }}
          setSnackBarProps={props.setSnackBarProps}
        />
      )}
      {defautlCourierPopup && (
        <UpdateDefaultCourier
          open={defautlCourierPopup}
          handleClose={(showSuccess = false) => {
            if (showSuccess) {
              props.setSnackBarProps("success", "Saved successfully", true)
            }
            setDefaultCourierPopup(false)
          }}
          setSnackBarProps={props.setSnackBarProps}
        />
      )}
      {autoPilotPopup && (
        <AutoPilotPopup
          open={autoPilotPopup}
          handleClose={(showSuccess = false) => {
            if (showSuccess) {
              props.setSnackBarProps("success", "Saved successfully", true)
            }
            setAutoPilotPopup(false)
          }}
          setSnackBarProps={props.setSnackBarProps}
        />
      )}
      <Button
        variant="contained"
        className="control-home-button-style"
        onClick={() => goToPage("Messaging")}
      >
        Handwritten Note
      </Button>
      <Button
        variant="contained"
        className="control-home-button-style"
        onClick={() => goToPage("InfinityNotes")}
      >
        Infinity Notes
      </Button>
      <Button
        variant="contained"
        className="control-home-button-style"
        onClick={() => setCourierCompost(true)}
      >
        Print Courier and Compost
      </Button>
      <Button
        variant="contained"
        className="control-home-button-style"
        onClick={() => goToPage("EmailTemplate")}
      >
        Set up Automatic Emails
      </Button>
      <Button
        variant="contained"
        className="control-home-button-style"
        onClick={() => setTransitHoursPopup(true)}
      >
        Set up overdue tranist times
      </Button>
      <Button
        variant="contained"
        className="control-home-button-style"
        onClick={() => setNzpostClaimsPopup(true)}
      >
        Set up NZPost claim prices
      </Button>
      <Button
        variant="contained"
        className="control-home-button-style"
        onClick={() => goToPage("ManageNotes")}
      >
        Manage Notes - Shipping Label
      </Button>
      <Button
        variant="contained"
        className="control-home-button-style"
        onClick={() => setDefaultCourierPopup(true)}
      >
        Label Settings
      </Button>
      <Button
        variant="contained"
        className="control-home-button-style"
        onClick={() => setAutoPilotPopup(true)}
      >
        Auto Pilot Settings
      </Button>
      <Button
        variant="contained"
        className="control-home-button-style"
        onClick={() => setreturnLabelPopup(true)}
      >
        Generate Return Ticket
      </Button>
      <Button
        variant="contained"
        className="control-home-button-style"
        onClick={() => setManualLabelPopup(true)}
      >
        Generate Manual Ticket
      </Button>
      <Button
        variant="contained"
        className="control-home-button-style"
        onClick={() => history.push("/PostalZones")}
      >
        Go to Postal Zones
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  snackbar: state.snackbar,
});

export default connect(mapStateToProps, {
  setSnackBarProps
})(Control);
