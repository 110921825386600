import React, { useEffect, useState } from "react";
import AdminLayout from "../AdminLayout/AdminLayout";
import {
  searchReferrals,
} from "../../../repository/referrals";
import { Typography } from "@material-ui/core";
import ReferralsFilter from "./ReferralsFilter/ReferralsFilter";
import ReferralsTable from "./ReferralsTable/ReferralsTable";
import "./referrals.scss";

const Referrals = () => {
  const [referrals, setReferrals] = useState([]);
  const [referralFilter, setReferralFilter] = useState({ label: "Pending", value: "PENDING" });

  useEffect(() => {
    getReferrals(referralFilter);
  }, [referralFilter]);

  const getReferrals = async (referralFilter) => {
    const referrals = await searchReferrals(referralFilter)
    setReferrals(referrals);
  };

  return (
    <AdminLayout>
      <div className="referral-root">
        <div className="referral-header-container">
          <div className="referral-name-container">
            <Typography variant="h4">Referrals</Typography>
          </div>
          <div>
            <ReferralsFilter filter={referralFilter} setFilter={setReferralFilter} />
          </div>
        </div>
        <div className="referral-counter-container">
          <Typography>{`${referrals?.length} records found`}</Typography>
        </div>
        <div className="referral-table-results-container">
          <ReferralsTable
            referrals={referrals}
            setReferrals={setReferrals}
          />
        </div>
      </div>
    </AdminLayout>
  );
};

export default Referrals;
