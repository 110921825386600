import {
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useRef, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import ConfirmationDialogActions from "../../Shared/ConfirmationDialogActions";
const ProductDetailsRow = ({
  product,
  handleUpdateLocalProduct,
  productSuppliers,
  index,
  handleChangeProductStatus,
}) => {
  const inputRef = useRef(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [openStausDialog, setOpenStausDialog] = useState(false);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : null
    );
  };

  const handleCloseContext = () => {
    setContextMenu(null);
  };

  const handleChangeStatusDialog = () => {
    setOpenStausDialog(true);
    handleCloseContext();
  };

  const handleCloseStatusDialog = () => {
    setOpenStausDialog(false);
  };

  const handleConfirmChangeStatus = () => {
    handleChangeProductStatus(product.id, product.isActive ? 0 : 1);
    handleCloseStatusDialog();
  };

  return (
    <>
      {openStausDialog && (
        <ConfirmationDialogActions
          title={"Are you sure?"}
          open={openStausDialog}
          contentText={`This action will make the product status ${
            product.isActive ? "INACTIVE" : "ACTIVE"
          }. Are you sure you want to proceed?`}
          button1Action={() => handleCloseStatusDialog()}
          button1Text={"Cancel"}
          button2Text={"Confirm"}
          button2Action={handleConfirmChangeStatus}
        />
      )}
      <Draggable key={index} draggableId={"" + index} index={index}>
        {(provided, snapshot) => (
          <TableRow
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={
             ` ${index % 2 === 0
                ? "products-table-even-row"
                : "products-table-odd-row"} 
                ${!product.isActive ? "products-table-inactive-row" : ""}`
            }
          >
            <TableCell
              className={`products-table-cell ${
                index % 2 === 0
                  ? "products-table-even-cell"
                  : "products-table-odd-cell"
              } ${!product.isActive ? "products-table-inactive-row" : ""}
            `}
              style={{ width: 30 }}
              ref={inputRef}
              onContextMenu={handleContextMenu}
            >
              <DragIndicatorIcon className="drag-drop-icon" />
              <Menu
                open={contextMenu !== null}
                onClose={handleCloseContext}
                anchorReference="anchorPosition"
                anchorPosition={
                  contextMenu !== null
                    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                    : undefined
                }
              >
                {product?.isActive ? (
                  <MenuItem onClick={handleChangeStatusDialog}>
                    Make it INACTIVE
                  </MenuItem>
                ) : (
                  <MenuItem onClick={handleChangeStatusDialog}>
                    Turn ACTIVE
                  </MenuItem>
                )}
              </Menu>
            </TableCell>
            <TableCell
              className={`products-table-cell ${
                index % 2 === 0
                  ? "products-table-even-cell"
                  : "products-table-odd-cell"
              }`}
            >
              <TextField
                size="small"
                value={product.name}
                fullWidth
                onChange={(e) => {
                  handleUpdateLocalProduct("name", e.target.value, product?.id);
                }}
                InputLabelProps={{ shrink: true }}
              />
            </TableCell>
            <TableCell className="products-table-cell">
              <Autocomplete
                options={productSuppliers}
                getOptionLabel={(option) => option.name}
                value={productSuppliers.find(
                  (p) => p.id === product.productSupplierId
                )}
                onChange={(e, newValue) => {
                  handleUpdateLocalProduct(
                    "productSupplierId",
                    newValue?.id,
                    product?.id
                  );
                }}
                disableClearable
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </TableCell>
            <TableCell className="products-table-cell">
              <Autocomplete
                options={["NZD", "AUD"]}
                getOptionLabel={(option) => option}
                value={product.currency}
                onChange={(e, newValue) => {
                  handleUpdateLocalProduct("currency", newValue, product?.id);
                }}
                disableClearable
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </TableCell>
            <TableCell className="products-table-cell">
              {" "}
              <TextField
                size="small"
                value={product.supplierCode}
                fullWidth
                onChange={(e) => {
                  handleUpdateLocalProduct(
                    "supplierCode",
                    e.target.value,
                    product?.id
                  );
                }}
                InputLabelProps={{ shrink: true }}
              />
            </TableCell>
            <TableCell className="products-table-cell">
              <TextField
                size="small"
                value={product.code}
                fullWidth
                onChange={(e) => {
                  handleUpdateLocalProduct("code", e.target.value, product?.id);
                }}
                InputLabelProps={{ shrink: true }}
              />
            </TableCell>
          </TableRow>
        )}
      </Draggable>
    </>
  );
};

export default ProductDetailsRow;
