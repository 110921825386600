import {
  Box,
  Collapse,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { convertStatusId } from "../../Utils/domainUtils";
import { convertMySQLToLocalTimeString } from "../../Utils/dateTime";
import CurrencyFormatter from "../../Shared/CurrencyFormatter";
import "./customerOrders.scss";
import { findOrderByShopifyId } from "../../../repository/orders";
import { convertIdToShopifyGraphql } from "../../Utils/shopifyConverter";

const Row = (props) => {
  const { order, handleCheckbox, selectedOrder } = props;
  const [open, setOpen] = useState(false);

  const toggleRow = () => {
    setOpen(!open);
    if (!open) {
      handleCheckbox(order.id)
    } else {
      handleCheckbox(null)
    }
  }

  return (
    <React.Fragment>
      <TableRow>
        <TableCell className="customer-order-outer-cell">
          <Checkbox
            onChange={(event) => {
              if (event.target.checked) {
                handleCheckbox(order.id)
              } else {
                handleCheckbox(null)
              }
            }}
            color="primary"
            checked={selectedOrder === order.id}
          />
        </TableCell>
        <TableCell component="th" scope="row" onClick={toggleRow} className="customer-order-outer-cell">
          <Typography
            className="hyperlink"
            variant="body1"
            component="div"
            onClick={() =>
              window.open(
                `https://simply-coffee-capsules.myshopify.com/admin/orders/${order.id}`

              )}
          >
            {`SC` + order.orderNumber}
          </Typography>
        </TableCell>
        <TableCell component="th" scope="row" onClick={toggleRow} className="customer-order-outer-cell">
          <Typography variant="body1">
            {order.paymentStatus}
          </Typography>
        </TableCell>
        <TableCell align="right" onClick={toggleRow} className="customer-order-outer-cell">
          <Typography variant="body1">
            {convertMySQLToLocalTimeString(order.timeReceived).toString()}
          </Typography>
        </TableCell>
        <TableCell align="right" onClick={toggleRow} className="customer-order-outer-cell">
          <Typography variant="body1">
            {convertStatusId(order.status)}
          </Typography>
        </TableCell>
        <TableCell align="right" onClick={toggleRow} className="customer-order-outer-cell">
          <Typography className="hyperlink"
            variant="body1"
            component="div"
            onClick={() => {
              order.labels.map((label, _) => {
                var url = order.courier.toLowerCase() === "nzpost" ?
                  `https://www.nzpost.co.nz/tools/tracking/item/${label.trackingNumber}`
                  : `https://www.aramex.co.nz/tools/track/?l=${label.trackingNumber}`;
                window.open(url)
              })
            }
            }>
            {order.courier}
          </Typography>
        </TableCell>
        <TableCell align="right" onClick={toggleRow} className="customer-order-outer-cell">
          <CurrencyFormatter useDollar amount={order.totalPrice} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases" className="customer-order-inner-table">
                <TableBody>
                  {order.items.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.quantity}x</TableCell>
                      <TableCell>{item.sku}</TableCell>
                      <TableCell>
                        <Typography variant="subtitle1">
                          <i>{item.name}</i>
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="subtitle1">
                          <CurrencyFormatter useDollar amount={item.price} />
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell />
      </TableRow>
    </React.Fragment >
  );
}

const CustomerOrders = ({ customer, setReorderId, setFirestoreOrder }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    if (customer) {
      handleFindOrders();
    }
  }, [customer]);

  const handleFindOrders = () => {
    if (customer) {
      axios
        .get(`AdminPanel/FindOrdersByCustomer/${customer.id}`)
        .then(({ data }) => {
          setOrders(data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  const handleCheckbox = async (id) => {
    setSelectedOrder(id)
    setReorderId(id)
    setFirestoreOrder(await findOrderByShopifyId(id))
  }

  return (
    <div>
      {isLoading ? (
        <Skeleton variant="rect" height={300} />
      ) : (
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => (
                <Row key={order.id} order={order} handleCheckbox={handleCheckbox} selectedOrder={selectedOrder} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default CustomerOrders;
