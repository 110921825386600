import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import WebsiteErrorsRow from "./WebsiteErrorsRow";

const WebsiteErrorsTable = ({ websiteErrors, removeWebsiteErrorFromList }) => {
  return (
    <div className="">
      <div className="default-admin-table-container-wrapper">
        <Typography className="default-admin-table-heading">
          Website Order Errors
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="caption" className="default-admin-table-header">
                  Date
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption" className="default-admin-table-header">
                  Customer Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption" className="default-admin-table-header">
                  Email
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption" className="default-admin-table-header">
                  Status
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption" className="default-admin-table-header">
                  Error Message
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption" className="default-admin-table-header">
                  Items
                </Typography>
              </TableCell>
              <TableCell style={{width: 250}}>
                <Typography variant="caption" className="default-admin-table-header">
                  Actions
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {websiteErrors.map((websiteError, index) => (
              <WebsiteErrorsRow
                key={index}
                index={index}
                websiteError={websiteError}
                removeWebsiteErrorFromList={removeWebsiteErrorFromList}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default WebsiteErrorsTable;
