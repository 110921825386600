import React, { forwardRef, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    FormControlLabel,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Typography,
    CircularProgress,
    Switch,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from "@material-ui/core";
import Axios from "axios"
import { makeStyles } from "@material-ui/core/styles";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} mountOnEnter unmountOnExit />;
});

const UpdateDefaultCourier = (props) => {
    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        }
    }));
    const classes = useStyles();

    const {
        open,
        handleClose,
        setSnackBarProps
    } = props;

    const [courier, setCourier] = useState(1);
    const [express, setExpress] = useState(0);
    const [expressZones, setExpressZones] = useState(null);
    const [prioritizeHyperPack, setPrioritizeHyperPack] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fetchedData, setFetchedData] = useState(false);

    const getExpressZoneTableRow = (expressZone) => {
        return (
            <TableRow key={`${expressZone.id}-express-zone-table-row`}>
                <TableCell style={{ color: "black !important" }}>
                    {`${expressZone.zoneName}`}
                </TableCell>
                <TableCell align="center">
                    {/* <Switch
                        disabled={loading}
                        checked={expressZone.lowerPriority === 1}
                        onChange={e => handleChangePriority(expressZone)}
                        color="primary"
                    /> */}
                    <Select
                        value={expressZone.priority}
                        onChange={(e) => handleChangePriority(expressZone, e.target.value)}
                        disabled={loading}
                    >
                        <MenuItem value={3}>High</MenuItem>
                        <MenuItem value={4}>Medium</MenuItem>
                        <MenuItem value={5}>Regular</MenuItem>
                        <MenuItem value={6}>Low</MenuItem>
                        <MenuItem value={7}>No Priority</MenuItem>
                    </Select>
                </TableCell>
            </TableRow>
        )
    }

    const handleChangePriority = (expressZone, value) => {
        var newExpressZones = [...expressZones];

        // for (var i = 0; i < newExpressZones.length; i++) {
        //     if (newExpressZones[i].id === expressZone.id) {
        //         if (newExpressZones[i].lowerPriority === 1) {
        //             newExpressZones[i].lowerPriority = 0;
        //         } else {
        //             newExpressZones[i].lowerPriority = 1;
        //         }
        //     }
        // }

        for (var i = 0; i < newExpressZones.length; i++) {
            if (newExpressZones[i].id === expressZone.id) {
                if (newExpressZones[i].priority !== value) {
                    newExpressZones[i].priority = value;
                }
            }
        }

        setExpressZones(newExpressZones);
    }

    const saveChanges = () => {
        setLoading(true)
        Axios.patch(`SystemActions/UpdateDefaultCourierSettings/qlbvgherlkjubvg897645HRSTHNXF454lkjhbv`, {
            Express: express,
            Courier: courier,
            expressZones: expressZones,
            PrioritizeHyperPack: prioritizeHyperPack
        }).then(res => {
            handleClose(true);
        })
    }

    useEffect(() => {
        Axios.get(`SystemActions/GetDefaultCourierSettings/qlbvgherlkjubvg897645HRSTHNXF454lkjhbv`)
            .then(res => {
                if (res != null) {
                    console.log("SystemDefault")
                    console.log(res)
                    setCourier(res.data.courier);
                    setExpress(res.data.express);
                    setExpressZones(res.data.expressZones);
                    setPrioritizeHyperPack(res.data.prioritizeHyperPack)
                    setFetchedData(true);
                }
            })
            .catch(e => {
                setSnackBarProps("warning", "Could not fetch data", true)
            })
    }, [])

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => handleClose()}
            >
                <DialogTitle>Update Default Courier</DialogTitle>
                {
                    fetchedData && (
                        <div>
                            <DialogContent>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column"
                                    }}
                                >
                                    <FormControl className={classes.formControl}>
                                        <InputLabel>Default Courier</InputLabel>
                                        <Select
                                            value={courier}
                                            onChange={(e) => setCourier(e.target.value)}
                                            disabled={loading}
                                        >
                                            <MenuItem value={1}>NZ Post</MenuItem>
                                            <MenuItem value={2}>Aramex</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                disabled={loading}
                                                checked={prioritizeHyperPack}
                                                onChange={e => {
                                                    if (e.target.checked) {
                                                        setPrioritizeHyperPack(true)
                                                    } else {
                                                        setPrioritizeHyperPack(false)
                                                    }
                                                }}
                                                color="primary"
                                            />
                                        }
                                        label="Enable Hyperpack Prioritisation"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                disabled={loading}
                                                checked={express === 1}
                                                onChange={e => {
                                                    if (e.target.checked) {
                                                        setExpress(1)
                                                    } else {
                                                        setExpress(0)
                                                    }
                                                }}
                                                color="primary"
                                            />
                                        }
                                        label="Enable Express Courier"
                                    />
                                    {express === 1 && (
                                        <Table id="express-zone-table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Zone</TableCell>
                                                    <TableCell>Packing Priority</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {expressZones.map(zone => {
                                                    return getExpressZoneTableRow(zone);
                                                })}
                                            </TableBody>
                                        </Table>
                                    )}
                                </div>
                            </DialogContent>
                            <DialogActions>
                                {loading ?
                                    <CircularProgress />
                                    : (
                                        <React.Fragment>
                                            <Button variant="contained" color="default" onClick={() => handleClose()}>
                                                Go Back
                                            </Button>
                                            <Button variant="contained" color="primary" onClick={() => saveChanges()}>
                                                Update
                                            </Button>
                                        </React.Fragment>
                                    )
                                }
                            </DialogActions>
                        </div>
                    )
                }
            </Dialog>
        </div>
    );
};

export default UpdateDefaultCourier;