import React, { useEffect, useState } from "react";
import { Grid, Card } from "@material-ui/core";
import Buttons from "./Buttons";
import "./home.scss";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { setSnackBarProps } from "../../reduxActions/snackbar";
import Snacky from "../Shared/Snacky";
import InvestigationDialog from "../Investigation/InvestigationDialog";
import {
  getNumberOfSubscriptionsWithError,
  searchSubscriptions,
} from "../../repository/subscriptions";
import DelayedOrdersButton from "./DelayedOrdersButton";
import { over } from "lodash";
const Home = (props) => {
  let history = useHistory();
  const {
    orders,
    hpMode,
    delayedOrders,
    snackbar,
    setSnackBarProps,
    ordersForInvestigation,
  } = props;

  const [showOrdersForInvestigation, setShowOrdersForInvestigation] =
    useState(false);

  const [loadingNumItems, setLoadingNumItems] = useState(true);
  const [loadingNumDelayedOrders, setLoadingNumDelayedOrders] = useState(true);
  const [loadingNumFailedAutoship, setLoadingNumFailedAutoship] =
    useState(true);
  const [storeOrderNumItems, setStoreOrdersNumItems] = useState(0);
  const [lineItemsNumItems, setLineItemsNumItems] = useState(0);
  const [handpackNumItems, setHandpackNumItems] = useState(0);
  const [ordersOnHoldNumItems, setOrdersOnHoldNumItems] = useState(0);
  const [failedOrdersNumItems, setFailedOrdersNumItems] = useState(0);

  // HP Num Items
  const [hpStoreOrdersNumItems, setHpStoreOrdersNumItems] = useState(0);
  const [hpBulkStoreOrdersNumItems, setHpBulkStoreOrdersNumItems] = useState(0);
  const [hpNoteQueueNumItems, setHpNoteQueueNumItems] = useState(0);
  // In HP mode Orders on hold takes in failed orders as well
  const [hpOrdersOnHoldNumItems, setHpOrdersOnHoldNumItems] = useState(0);
  const [hpPackingStationNumItems, setHpPackingStationNumItems] = useState(0);
  const [hpBulkPackingStationNumItems, setHpBulkPackingStationNumItems] =
    useState(0);

  const [delayedOrdersNumItems, setDelayedOrdersNumItems] = useState(0);
  const [delayedOrdersGreenItems, setDelayedOrdersGreenItems] = useState(0);
  const [delayedOrdersOrangeItems, setDelayedOrdersOrangeItems] = useState(0);
  const [delayedOrdersRedItems, setDelayedOrdersRedItems] = useState(0);
  const [failedAutoshipNumItems, setFailedAutoshipNumItems] = useState(0);

  useEffect(() => {
    if (ordersForInvestigation && ordersForInvestigation.length > 0) {
      setShowOrdersForInvestigation(true);
    }
  }, [ordersForInvestigation]);

  useEffect(() => {
    const getNumItemsOfType = (types, hpBulkPack) => {
      return orders.filter(
        (order) =>
          types.includes(order.status) &&
          (hpBulkPack === undefined || (order.hpBulkPack === 1) === hpBulkPack)
      ).length;
    };

    if (orders) {
      setStoreOrdersNumItems(getNumItemsOfType([0]));
      setLineItemsNumItems(getNumItemsOfType([1, 2, 3]));
      setHandpackNumItems(getNumItemsOfType([6]));
      setOrdersOnHoldNumItems(getNumItemsOfType([11]));
      setFailedOrdersNumItems(getNumItemsOfType([9]));

      // HP Num Items
      setHpStoreOrdersNumItems(getNumItemsOfType([0], false));
      setHpBulkStoreOrdersNumItems(getNumItemsOfType([0], true));
      setHpNoteQueueNumItems(getNumItemsOfType([1, 2]));
      setHpOrdersOnHoldNumItems(getNumItemsOfType([9, 6, 11]));
      setHpPackingStationNumItems(getNumItemsOfType([3], false));
      setHpBulkPackingStationNumItems(getNumItemsOfType([3], true));

      setLoadingNumItems(false);
    } else {
      setLoadingNumItems(true);
    }
  }, [orders]);

  useEffect(() => {
    const getDelayedOrdersNumItems = () =>
      delayedOrders.filter((delayedOrder) => delayedOrder.hoursDelayed >= 12)
        .length;
    if (delayedOrders) {
      setDelayedOrdersNumItems(getDelayedOrdersNumItems());
      setDelayedOrdersGreenItems(
        delayedOrders.filter(
          (delayedOrder) =>
            delayedOrder.status !== "Disputed" &&
            !delayedOrder.fromDisputed &&
            !delayedOrder.fromQuickFix &&
            delayedOrder.hoursDelayed <= 48
        ).length
      );
      setDelayedOrdersOrangeItems(
        delayedOrders.filter(
          (delayedOrder) =>
            delayedOrder.status !== "Disputed" &&
            !delayedOrder.fromDisputed &&
            !delayedOrder.fromQuickFix &&
            delayedOrder.hoursDelayed > 48 &&
            delayedOrder.hoursDelayed <= 90
        ).length
      );
      setDelayedOrdersRedItems(
        delayedOrders.filter(
          (delayedOrder) =>
            delayedOrder.status !== "Disputed" &&
            !delayedOrder.fromDisputed &&
            !delayedOrder.fromQuickFix &&
            delayedOrder.hoursDelayed > 90
        ).length
      );
      setLoadingNumDelayedOrders(false);
    } else {
      setLoadingNumDelayedOrders(true);
    }
  }, [delayedOrders]);

  useEffect(() => {
    setLoadingNumFailedAutoship(true);
    searchSubscriptions({ status: "ACTIVE" })
      .then((subscriptions) => getNumberOfSubscriptionsWithError(subscriptions))
      .then((numItems) => {
        setFailedAutoshipNumItems(numItems);
        setLoadingNumFailedAutoship(false);
      });
  }, []);

  const goToPage = (click) => {
    history.push(`/${click}`);
  };

  const cards = (click, text, args) => {
    var xs = 6,
      md = 6,
      lg = 6;
    var numItems = undefined;

    if (args !== undefined) {
      if (args.overideGridSize !== undefined) {
        xs = md = lg = args.overideGridSize;
      }
      if (args.numItems !== undefined) {
        numItems = args.numItems;
      }
    }

    return (
      <Grid item xs={xs} md={md} lg={lg} className="grid-items">
        <Card elevation={9} className="home-card-for-button">
          <Buttons
            handleClick={() => goToPage(click)}
            buttonText={text}
            numItems={numItems}
            loadingNumItems={args?.loading || loadingNumItems}
            // disabled={text === "Fuel Camistry Cars" && contractor.clicked}
          />
        </Card>
      </Grid>
    );
  };

  const renderDelayedOrdersCard = (click, text, args) => {
    var xs = 6,
      md = 6,
      lg = 6;
    let greenItems = 0;

    if (args !== undefined) {
      if (args.overideGridSize !== undefined) {
        xs = md = lg = args.overideGridSize;
      }
      if (args.greenItems !== undefined) {
        greenItems = args.greenItems;
      }
    }

    return (
      <Grid item xs={xs} md={md} lg={lg} className="grid-items">
        <Card elevation={9} className="home-card-for-button">
          <DelayedOrdersButton
            handleClick={() => goToPage(click)}
            buttonText={text}
            greenItems={delayedOrdersGreenItems}
            orangeItems={delayedOrdersOrangeItems}
            redItems={delayedOrdersRedItems}
            loadingNumItems={loadingNumDelayedOrders}
            // disabled={text === "Fuel Camistry Cars" && contractor.clicked}
          />
        </Card>
      </Grid>
    );
  };

  return (
    <div className="home-container">
      {showOrdersForInvestigation && (
        <InvestigationDialog
          open={showOrdersForInvestigation}
          handleClose={() => setShowOrdersForInvestigation(false)}
          ordersForInvestiation={ordersForInvestigation}
        />
      )}
      <Snacky snackprops={snackbar} setSnackBarProps={setSnackBarProps} />
      <Grid container className="home-button-grid-container">
        {/* Old Non-HP mode view. Can be removed if we delete the robot */}
        {hpMode === false && (
          <>
            {cards("AdminPanel", "Admin Panel", {
              overideGridSize: 3,
              numItems: failedAutoshipNumItems,
              loading: loadingNumFailedAutoship,
            })}
            {cards("StockLevels", "Stock Levels", { overideGridSize: 3 })}
            {cards("StoreOrders", "Store Orders", {
              numItems: storeOrderNumItems,
            })}
            {cards("HandpackOrders", "Handpack Orders", {
              numItems: handpackNumItems,
            })}
            {cards("OrdersOnHold", "Orders On Hold", {
              numItems: ordersOnHoldNumItems,
            })}
            {cards("LineInfo", "Line Information", {
              numItems: lineItemsNumItems,
            })}
            {cards("AllOrders", "All Orders")}
            {cards("FailedOrders", "Failed Orders", {
              numItems: failedOrdersNumItems,
            })}
            {cards("Control", "Control Panel")}
            {cards("BusinessDirect", "Business Direct")}
            {renderDelayedOrdersCard("DelayedOrders", "Delayed Orders", {
              numItems: delayedOrdersNumItems,
              loading: loadingNumDelayedOrders,
            })}
          </>
        )}
        {/* New HP-Mode view */}
        {hpMode && (
          <>
            {cards("StoreOrders", "Store Orders", {
              numItems: hpStoreOrdersNumItems,
            })}
            {cards("AdminPanel", "Admin Panel", {
              overideGridSize: 3,
              numItems: failedAutoshipNumItems,
              loading: loadingNumFailedAutoship,
            })}
            {cards("BusinessDirect", "Business Direct", { overideGridSize: 3 })}
            {cards("BulkStoreOrders", "Bulk Store Orders", {
              numItems: hpBulkStoreOrdersNumItems,
            })}
            {cards("OrdersOnHold", "Orders On Hold", {
              numItems: hpOrdersOnHoldNumItems,
            })}
            {cards("NoteQueue", "Note Queue", {
              numItems: hpNoteQueueNumItems,
            })}
            {cards("AllOrders", "All Orders")}
            {cards("PackingStation", "Packing Station", {
              numItems: hpPackingStationNumItems,
            })}
            {cards("Control", "Control Panel")}
            {cards("BulkPackingStation", "Bulk Packing Station", {
              numItems: hpBulkPackingStationNumItems,
            })}
            {renderDelayedOrdersCard("DelayedOrders", "Delayed Orders", {
              numItems: delayedOrdersNumItems,
              loading: loadingNumDelayedOrders,
            })}
          </>
        )}
      </Grid>
    </div>
  );
};

// export default Home;
const mapStateToProps = (state) => ({
  orders: state.order.orders,
  hpMode: state.system.hpMode,
  delayedOrders: state.order.delayedOrders,
  snackbar: state.snackbar,
  ordersForInvestigation: state.order.ordersForInvestigation,
});

export default connect(mapStateToProps, {
  setSnackBarProps,
})(Home);
