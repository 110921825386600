import {
    Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";

const ResultsTable = (props) => {
  
  return (
    <div className="messaging-div">
      <Paper className="message-table-paper">
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow style={{ height: "40px" }}>
              <TableCell align={"center"}>Note</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody >
            {props.notes.map((note) => {
              return (
                <TableRow key={note.id}>
                  <TableCell align="center" component="th" scope="row" className="messaging-body-cell" style={{ width: "70%" }}>
                    {note.note}
                  </TableCell>
                  <TableCell align="center" className="messaging-body-cell" style={{ width: "30%" }}>
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => props.handleStatusChange(note.id)}
                    >
                         {note.isActive === 1 ? "DISCARD FROM LABEL" : "ADD TO THE LABEL" }
                    </Button></TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default ResultsTable;
