import React, { useEffect, useState } from "react"
import { withStyles } from "@material-ui/core/styles";
import {
    Grid,
    TextField,
    Typography,
} from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import Axios from "axios"
import { ConvertNZPostAddress } from "../../../Utils/nzPostUtils";

const WhiteTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "white",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "white",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "white",
            },
            "&:hover fieldset": {
                borderColor: "white",
            },
            "&.Mui-focused fieldset": {
                borderColor: "white",
            },
        },
    },
})(TextField);

const EditShippingSection = (props) => {
    let {
        selectedCompany,
        updateCompanyAddressArr
    } = props;

    const filterAutoComplete = createFilterOptions();

    const [nzPostAddresses, setNzPostAddresses] = useState([]);
    const [companyAddresses, setCompanyAddresses] = useState([]);

    const getNZPostAddresses = (searchTerm) => {
        Axios.get(`Orders/GetNZPostAddresses/${encodeURIComponent(searchTerm)}`)
            .then((res) => {
                var addressList = [];
                for (var i = 0; i < res.data.length; i++) {
                    addressList.push(res.data[i].fullAddress)
                }
                setNzPostAddresses(addressList);
            })
            .catch((e) => {
                // console.log(e);
            });
    };

    const updateCompanyAddresses = (index, property, newValue) => {
        var newCompanyAddresses = [...companyAddresses];
        
        var newAddressObj = {
            ...newCompanyAddresses[index],
            [`${property}`]: newValue
        }
        newCompanyAddresses[index] = newAddressObj;
        updateCompanyAddressArr(newCompanyAddresses);
    }

    useEffect(() => {
        setCompanyAddresses(selectedCompany.companyAddress)
    }, [selectedCompany])

    return (
        <React.Fragment>
            <Grid item xs={12} md={12} lg={12}>
                <Typography variant={"h6"} align="center">
                    Shipping Address
                </Typography>
            </Grid>

            {companyAddresses.map((companyAddress, i) => {
                return (
                    <React.Fragment>
                        <Grid item xs={12} md={12} lg={12}>
                            <WhiteTextField
                                key={`name-for-shipping-textbox-${i}`}
                                label="Name for shipping"
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {
                                    updateCompanyAddresses(i, "name", e.target.value)
                                }}
                                value={companyAddress.name}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={4} md={4} lg={4}>
                            <WhiteTextField
                                key={`address1-textbox-${i}`}
                                label="Address 1"
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {
                                    updateCompanyAddresses(i, "address1", e.target.value)
                                }}
                                value={companyAddress.address1}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={4} md={4} lg={4}>
                            <WhiteTextField
                                key={`address2-textbox-${i}`}
                                label="Address 2"
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {
                                    updateCompanyAddresses(i, "address2", e.target.value)
                                }}
                                value={companyAddress.address2}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={4} md={4} lg={4}>
                            <WhiteTextField
                                key={`city-textbox-${i}`}
                                label="City"
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {
                                    updateCompanyAddresses(i, "city", e.target.value)
                                }}
                                value={companyAddress.city}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={4} md={4} lg={4}>
                            <WhiteTextField
                                key={`zip-textbox-${i}`}
                                label="Zip"
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {
                                    updateCompanyAddresses(i, "zip", e.target.value)
                                }}
                                value={companyAddress.zip}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={4} md={4} lg={4}>
                            <WhiteTextField
                                key={`country-textbox-${i}`}
                                label="Country"
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {
                                    updateCompanyAddresses(i, "country", e.target.value)
                                }}
                                value={companyAddress.country}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <hr/>
                        </Grid>
                    </React.Fragment>
                )
            })}
        </React.Fragment>
    )
}

export default EditShippingSection;