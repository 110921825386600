import React from "react";
import _ from "lodash";
import { Typography, Button } from "@material-ui/core";
import "./packingScreen.scss";
import PackingScreenModalStatusBadge from "./PackingScreenModalStatusBadge";
import { connect } from "react-redux";
import { bulkCompleteOrders, bulkLabels } from "../../reduxActions/order";

const PackingScreenModalCurrentOrder = (props) => {
  const { className, order, bulkCompleteOrders, onCompleteOrder, bulkLabels } =
    props;

  const addressString = `${
    order.shippingAddress.companyName
      ? `${order.shippingAddress.companyName} - `
      : ""
  }${order.shippingAddress.address1 ?? ""}, ${
    order.shippingAddress.address2 ? `${order.shippingAddress.address2},` : ""
  } ${order.shippingAddress.city} ${order.shippingAddress.zip}`;

  return (
    <div className={`packing-screen-section-container ${className ?? ""}`}>
      <div className="packing-screen-section-section">
        <div className="packing-screen-current-order-title">
          <Typography variant="h5">Currently Packing</Typography>
          <div className="packing-screen-current-order-title-buttons">
            <PackingScreenModalStatusBadge
              orderId={order.id}
              status={order.status}
            />
            <Button variant="contained" onClick={() => bulkLabels([order.id])}>
              Print Label
            </Button>
          </div>
        </div>
        <div style={{ marginTop: "20px" }}>{`SC${order.orderNumber}`}</div>
        <Typography variant="h6">{`${order.customerFirstName} ${order.customerLastName}`}</Typography>
        <Typography variant="span">{addressString}</Typography>
      </div>
      <div className="packing-screen-captioned-grid-big">
        {order.courierOrder.quantitiesDescription.split(" | ").map((item) => (
          <div>{item}</div>
        ))}
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          bulkCompleteOrders([order.id]);
          onCompleteOrder();
        }}
      >
        Mark Order As Complete
      </Button>
    </div>
  );
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { bulkCompleteOrders, bulkLabels })(
  PackingScreenModalCurrentOrder
);
