import React, { useEffect, useState } from "react"
import { withStyles } from "@material-ui/core/styles";
import {
    Grid,
    TextField,
    Typography,
} from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import Axios from "axios"
import { ConvertNZPostAddress } from "../../../Utils/nzPostUtils";

const WhiteTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "white",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "white",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "white",
            },
            "&:hover fieldset": {
                borderColor: "white",
            },
            "&.Mui-focused fieldset": {
                borderColor: "white",
            },
        },
    },
})(TextField);

const ShippingAddressSection = (props) => {
    let {
        updateBusinessDirectAddresses,
        shippingAddress
    } = props;

    const [shippingAddresses, setShippingAddresses] = useState([]);
    const [loadingShippingAddresses, setLoadingShippingAddresses] = useState(false);
    // const [renderCompanyShippingAddress, setRenderCompanyShippingAddress] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const getNZPostAddresses = (searchTerm) => {
        setLoadingShippingAddresses(true);
        Axios.get(`Orders/GetNZPostAddresses/${encodeURIComponent(searchTerm)}`)
            .then((res) => {
                var addressList = [];
                for (var i = 0; i < res.data.length; i++) {
                    addressList.push(res.data[i].fullAddress)
                }
                setShippingAddresses(addressList);
            })
            .catch((e) => { console.log(e); })
            .finally(() => { setLoadingShippingAddresses(false) });
    };

    const handleAddressSelection = (fullAddress) => {
        var addressObject = ConvertNZPostAddress(fullAddress);

        updateBusinessDirectAddresses("shippingAddress", {
            ...shippingAddress,
            ...addressObject
        })
    };

    // useEffect(() => {
    //     setRenderCompanyShippingAddress(!renderCompanyShippingAddress)
    // }, [shippingAddresses])

    return (
        <React.Fragment>
            <Grid item xs={12} md={12} lg={12}>
                <Typography variant={"h6"} align="center">
                    Shipping Address
                </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <Autocomplete
                    // key={renderCompanyShippingAddress}
                    // autoComplete={true}
                    fullWidth
                    id="nzpost-address-dropdown"
                    options={shippingAddresses}
                    loading={loadingShippingAddresses}
                    value={searchTerm}
                    renderInput={(params) => (
                        <WhiteTextField
                            {...params}
                            label={"Search for an address"}
                            variant="outlined"
                            fullWidth
                            onChange={(e) => {
                                if (e.target.value?.trim()) {
                                    getNZPostAddresses(e.target.value.trim())
                                    setSearchTerm(e.target.value.trim())
                                }
                            }}
                            // onBlur={(e) =>
                            // // e.target.value !== null &&
                            // //     e.target.value !== ""
                            // //     ? getNZPostAddresses(e.target.value)
                            // //     : ""
                            // {
                            //     if (e.target.value?.trim()) {
                            //         getNZPostAddresses(e.target.value.trim())
                            //         setSearchTerm(e.target.value.trim())
                            //     }
                            // }}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    getNZPostAddresses(e.target.value.trim())
                                    setSearchTerm(e.target.value.trim())
                                    e.preventDefault();
                                }
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    onChange={(event, value) => {
                        if (value !== null && value !== "") {
                            handleAddressSelection(value);
                        } else {
                            updateBusinessDirectAddresses("shippingAddress", {
                                name: "",
                                address1: "",
                                address2: "",
                                city: "",
                                zip: "",
                                country: "",
                            })
                        }
                        setSearchTerm("");
                    }}
                />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
                <WhiteTextField
                    label="Address 1"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => {
                        updateBusinessDirectAddresses("shippingAddress", {
                            ...shippingAddress,
                            address1: e.target.value
                        })
                    }}
                    value={shippingAddress.address1}
                    spellCheck
                />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
                <WhiteTextField
                    label="Address 2"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => {
                        updateBusinessDirectAddresses("shippingAddress", {
                            ...shippingAddress,
                            address2: e.target.value
                        })
                    }}
                    value={shippingAddress.address2}
                    spellCheck
                />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
                <WhiteTextField
                    label="City"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => {
                        updateBusinessDirectAddresses("shippingAddress", {
                            ...shippingAddress,
                            city: e.target.value
                        })
                    }}
                    value={shippingAddress.city}
                    spellCheck
                />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
                <WhiteTextField
                    label="Zip"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => {
                        updateBusinessDirectAddresses("shippingAddress", {
                            ...shippingAddress,
                            zip: e.target.value
                        })
                    }}
                    value={shippingAddress.zip}
                    spellCheck
                />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
                <WhiteTextField
                    label="Country"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => {
                        updateBusinessDirectAddresses("shippingAddress", {
                            ...shippingAddress,
                            country: e.target.value
                        })
                    }}
                    value={shippingAddress.country}
                    spellCheck
                />
            </Grid>
        </React.Fragment>
    )
}

export default ShippingAddressSection;