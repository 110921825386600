import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";

const LoadingBackdrop = ({loading}) => {
  const useStyles = makeStyles((theme) => ({
    paper: {
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 3),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));

  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={loading}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default LoadingBackdrop;
