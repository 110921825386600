import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { connect } from "react-redux";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  CircularProgress,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import "./StockInstructions.scss";
import { getShelfInfo } from "../../reduxActions/stock";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#27293d",
  },
}));

const StockInstructions = (props) => {
  const classes = useStyles();

  const {
    getShelfInfo,
    flavourRequiredRowCount,
    currentStock,
    minimumRowsPerFlavour,
    maxSingleFlavourRows,
    allProducts
  } = props;

  const [minimumRows, setMinimumRows] = useState(minimumRowsPerFlavour);
  const [noSingleFlavourRows, setNoSingleFlavourRows] = useState(maxSingleFlavourRows);

  const getTableBody = () => {
    const getRow = (flavour, count) => {
      // var overUnder = count - currentStock[flavour];
      return (
        <TableRow>
          <TableCell align="center">{flavour}</TableCell>
          <TableCell align="center">{count}</TableCell>
          {/* <TableCell align="center">{currentStock[flavour]}</TableCell>
          <TableCell align="center">
            {overUnder > 0 ? `+${overUnder}` : overUnder}
          </TableCell> */}
        </TableRow>
      );
    };

    return (
      <TableBody>
        {allProducts.map(p => {
          if(p.isMiscellaneous !== 1 && p.code !== "PHC"){
            return getRow(p.code, flavourRequiredRowCount[p.code])
          } else {
            return ""
          }
        })}
      </TableBody>
    );
  };

  const getMinRowOptions = () => {
    const menuItems = [];

    for (var i = 0; i <= 10; i++) {
      menuItems.push(<MenuItem value={i}>{i}</MenuItem>);
    }

    return menuItems;
  };

  const getMaxSingleFlavourRowOptions = () => {
    const menuItems = [];

    for (var i = 0; i <= 100; i++) {
      menuItems.push(<MenuItem value={i}>{i}</MenuItem>);
    }

    return menuItems;
  };

  return (
    <div className={classes.root}>
      <div id="minimum-row-container">
      <FormControl className={"minimum-row-form-control"}>
          <InputLabel>Minimum rows per flavour</InputLabel>
          <Select
            value={minimumRows}
            onChange={(e) => {
              setMinimumRows(e.target.value);
              axios
                .patch(`Stock/PatchMinimumRow/${e.target.value}`)
                .then((res) => {})
                .catch((e) => {
                  console.log(e);
                });
            }}
          >
            {getMinRowOptions().map((item) => {
              return item;
            })}
          </Select>
        </FormControl>
        <FormControl className={"single-flavour-row-form-control"}>
          <InputLabel>Total rows available</InputLabel>
          <Select
            value={noSingleFlavourRows}
            onChange={(e) => {
              setNoSingleFlavourRows(e.target.value);
              axios
                .patch(`Stock/PatchMaxSingleFlavourRow/${e.target.value}`)
                .then((res) => {})
                .catch((e) => {
                  console.log(e);
                });
            }}
          >
            {getMaxSingleFlavourRowOptions().map((item) => {
              return item;
            })}
          </Select>
        </FormControl>
      </div>
      <Table id="stock-instrucitons-table">
        <colgroup>
          <col style={{ width: "50%" }} />
          <col style={{ width: "50%" }} />
          {/* <col style={{ width: "25%" }} />
          <col style={{ width: "25%" }} /> */}
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell align="center">Flavour</TableCell>
            <TableCell align="center">Required</TableCell>
            {/* <TableCell align="center">{`Current`}</TableCell>
            <TableCell align="center">{`Changes needed`}</TableCell> */}
          </TableRow>
        </TableHead>
        {getTableBody()}
      </Table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  flavourRequiredRowCount: state.stock.flavourRequiredRowCount,
  currentStock: state.stock.currentStock,
  minimumRowsPerFlavour: state.stock.minimumRowsPerFlavour,
  maxSingleFlavourRows: state.stock.maxSingleFlavourRows,
  allProducts: state.products.allProducts
});

export default connect(mapStateToProps, {
  getShelfInfo,
})(StockInstructions);
