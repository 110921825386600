import { TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import "./subscriptionsFilter.scss";

const statusOptions = [
  { label: "All", value: null },
  { label: "Active", value: "ACTIVE" },
  { label: "Paused", value: "PAUSED" },
  { label: "Problem", value: "PROBLEM" }
];
const SubscriptionsFilter = ({ filter, setFilter }) => {
  const handleChangeStatus = (e, { value }) => {
    setFilter({ ...filter, status: value });
  };

  console.log(filter)

  return (
    <div>
      <Autocomplete
        id="combo-box-demo"
        options={[
          { label: "All", value: null },
          { label: "Active", value: "ACTIVE" },
          { label: "Paused", value: "PAUSED" },
          { label: "Problem", value: "PROBLEM" }
        ]}
        onChange={handleChangeStatus}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.value === value.value}
        value={statusOptions.find((option) => option.value === filter.status)}
        style={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Status"
            variant="outlined"
            size="small"
            wi
          />
        )}
      />
    </div>
  );
};

export default SubscriptionsFilter;
