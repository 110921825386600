import React, { forwardRef, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Typography,
    CircularProgress,
    TextField,
    Grid
} from "@material-ui/core";
import Axios from "axios"
import { makeStyles } from "@material-ui/core/styles";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} mountOnEnter unmountOnExit />;
});

const UpdateNZPostClaimPrices = (props) => {
    const {
        open,
        handleClose,
        setSnackBarProps
    } = props;

    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [shippingBox, setShippingBox] = useState(false);
    const [note, setNote] = useState(false);
    const [consumables, setConsumables] = useState(false);
    const [brandLicense, setBrandLicense] = useState(false);
    const [packing, setPacking] = useState(false);

    const [shippingBoxDescription, setShippingBoxDescription] = useState(false);
    const [noteDescription, setNoteDescription] = useState(false);
    const [consumablesDescription, setConsumablesDescription] = useState(false);
    const [brandLicenseDescription, setBrandLicenseDescription] = useState(false);
    const [packingDescription, setPackingDescription] = useState(false);

    useEffect(() => {
        Axios.get(`NzPostClaimPrices/GetNzPostClaimPrices`)
            .then(res => {
                // console.log(res)
                if (res != null) {
                    setShippingBox(res.data.shippingBox);
                    setNote(res.data.note);
                    setConsumables(res.data.consumables);
                    setBrandLicense(res.data.brandLicense);
                    setPacking(res.data.packing);
                    setShippingBoxDescription(res.data.shippingBoxDescription);
                    setNoteDescription(res.data.noteDescription);
                    setConsumablesDescription(res.data.consumablesDescription);
                    setBrandLicenseDescription(res.data.brandLicenseDescription);
                    setPackingDescription(res.data.packingDescription);
                    setLoaded(true);
                }
            })
            .catch(e => {

            })
    }, [])

    const saveChanges = () => {
        setLoading(true)
        Axios.put(`NzPostClaimPrices/PutNzPostClaimPrices/1`, {
            id: 1,
            shippingBox: shippingBox,
            note: note,
            consumables: consumables,
            brandLicense: brandLicense,
            packing: packing,
            shippingBoxDescription: shippingBoxDescription,
            noteDescription: noteDescription,
            consumablesDescription: consumablesDescription,
            brandLicenseDescription: brandLicenseDescription,
            packingDescription: packingDescription
        })
            .then(res => {
                handleClose(true);
            })
            .catch(e => setSnackBarProps("warning", "Failed. Please try again", true))
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => handleClose()}
                disableBackdropClick
            >
                <DialogTitle>Update NZPost claims prices</DialogTitle>
                {!loaded && (
                    <CircularProgress />
                )}
                {loaded && (
                    <React.Fragment>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={10} md={10} lg={10}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={shippingBoxDescription}
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={e => {
                                            setShippingBoxDescription(e.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2} md={2} lg={2}>
                                    <TextField
                                        variant="outlined"
                                        label="Value"
                                        fullWidth
                                        type="number"
                                        value={shippingBox}
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={e => {
                                            setShippingBox(e.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={10} md={10} lg={10}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={noteDescription}
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={e => {
                                            setNoteDescription(e.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2} md={2} lg={2}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        label="Value"
                                        type="number"
                                        value={note}
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={e => {
                                            setNote(e.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={10} md={10} lg={10}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={consumablesDescription}
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={e => {
                                            setConsumablesDescription(e.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2} md={2} lg={2}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        type="number"
                                        value={consumables}
                                        margin="normal"
                                        label="Value"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={e => {
                                            setConsumables(e.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={10} md={10} lg={10}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={brandLicenseDescription}
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={e => {
                                            setBrandLicenseDescription(e.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2} md={2} lg={2}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        label="Value"
                                        value={brandLicense}
                                        type="number"
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={e => {
                                            setBrandLicense(e.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={10} md={10} lg={10}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={packingDescription}
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={e => {
                                            setPackingDescription(e.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2} md={2} lg={2}>
                                    <TextField
                                        variant="outlined"
                                        label="Value"
                                        fullWidth
                                        value={packing}
                                        type="number"
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={e => {
                                            setPacking(e.target.value);
                                        }}
                                    />
                                </Grid>
                            </Grid>

                        </DialogContent>
                        <DialogActions>
                            {loading ?
                                <CircularProgress />
                                : (
                                    <React.Fragment>
                                        <Button variant="contained" color="default" onClick={() => handleClose()}>
                                            Go Back
                                        </Button>
                                        <Button variant="contained" color="primary" onClick={() => saveChanges()}>
                                            Update
                                        </Button>
                                    </React.Fragment>
                                )
                            }
                        </DialogActions>
                    </React.Fragment>
                )}
            </Dialog>
        </div>
    );
};

export default UpdateNZPostClaimPrices;