import React, { useEffect, useState } from "react"
import { withStyles } from "@material-ui/core/styles";
import {
    Grid,
    Button,
    TextField
} from "@material-ui/core";
import axios from "axios";
import { setSnackBarProps } from "../../../reduxActions/snackbar";
import { connect } from "react-redux";
import Dialogy from "../../Shared/Dialogy";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import EditCompanySection from "./EditComponents/EditCompanySection";
import EditBillingAddressSection from "./EditComponents/EditBillingSection";
import EditEmployeeeSection from "./EditComponents/EditEmployeeSection";
import EditShippingSection from "./EditComponents/EditShippingSection";

const WhiteTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "white",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "white",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "white",
            },
            "&:hover fieldset": {
                borderColor: "white",
            },
            "&.Mui-focused fieldset": {
                borderColor: "white",
            },
        },
    },
})(TextField);

const EditCompany = (props) => {
    const [successDialogyOpen, setSuccessDialogyOpen] = useState(false);
    const [failDialogyOpen, setFailDialogyOpen] = useState(false);

    const [companiesLoaded, setCompaniesLoaded] = useState(false);
    const [fetchCompanies, setFetchCompanies] = useState(true);
    const [companyList, setCompanyList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);

    const filterAutoComplete = createFilterOptions();

    const autoCompleteFilterOptions = (options, params) => {
        const filtered = filterAutoComplete(options, params);

        return filtered;
    };

    const updateSelectedCompany = (property, newValue) => {
        setSelectedCompany({
            ...selectedCompany,
            [`${property}`]: newValue
        })
    }

    const updateBillingAddress = (addressObject) => {
        setSelectedCompany({
            ...selectedCompany,
            ...addressObject
        })
    }

    const updateEmployeeArr = (employeeArr) => {
        setSelectedCompany({
            ...selectedCompany,
            employee: [...employeeArr]
        })
    }

    const updateCompanyAddressArr = (companyAddressArr) => {
        setSelectedCompany({
            ...selectedCompany,
            companyAddress: [...companyAddressArr]
        })
    }

    const saveChanges = () => {
        axios.post(`BusinessDirect/UpdateCompany`, selectedCompany)
            .then(res => {
                setSelectedCompany(null);
                setFetchCompanies(true);
                setSuccessDialogyOpen(true);
            }).catch(e => {
                setFailDialogyOpen(true);
            })
    }

    useEffect(() => {
        document.getElementsByTagName("body")[0].style.overflow = "unset";
        return () => {
            document.getElementsByTagName("body")[0].style.overflow = "hidden";
        };
    }, []);

    useEffect(() => {
        if (fetchCompanies) {
            setFetchCompanies(false);
            axios.get('BusinessDirect/GetCompanies')
                .then(res => {
                    setCompanyList(res.data)
                    setCompaniesLoaded(true);
                }).catch(e => {
                    debugger;
                })
        }
    }, [fetchCompanies])

    return (
        <div className="business-direct-form">
            <Dialogy
                open={successDialogyOpen}
                handleClose={() => {
                    setSuccessDialogyOpen(false);
                }}
                details={{
                    title: "Success",
                    text: "Information has been updated",
                    type: "",
                }}
            />
            <Dialogy
                open={failDialogyOpen}
                handleClose={() => {
                    setFailDialogyOpen(false);
                }}
                details={{
                    title: "Failed",
                    text: "Please try again",
                    type: "",
                }}
            />
            <Grid container spacing={2}>
                {/* Company search */}
                <Grid item xs={12} md={12} lg={12}>
                    <Autocomplete
                        id="company-search-bar"
                        freeSolo
                        selectOnFocus
                        disableClearable
                        handleHomeEndKeys
                        //value={selectedCompany.name}
                        onChange={(event, newValue) => {
                            if ((typeof newValue) !== "number" && (typeof newValue) !== "string") {
                                setSelectedCompany(newValue)
                            }
                        }}
                        fullWidth
                        autoHighlight
                        autoSelect
                        options={companyList}
                        filterOptions={(options, params) => autoCompleteFilterOptions(options, params)}
                        getOptionLabel={(option) => {
                            if (option.inputValue) {
                                return option.title;
                            }
                            return option.name
                        }}
                        renderInput={(params) => (
                            <WhiteTextField
                                {...params}
                                label="Company Search"
                                placeholder="Search here"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    />
                </Grid>
                {selectedCompany !== null && (
                    <React.Fragment>
                        <EditCompanySection
                            selectedCompany={selectedCompany}
                            updateSelectedCompany={updateSelectedCompany}
                        />
                        <EditEmployeeeSection
                            selectedCompany={selectedCompany}
                            updateSelectedCompany={updateSelectedCompany}
                            updateEmployeeArr={updateEmployeeArr}
                        />
                        <EditBillingAddressSection
                            selectedCompany={selectedCompany}
                            updateSelectedCompany={updateSelectedCompany}
                            updateBillingAddress={updateBillingAddress}
                        />
                        {selectedCompany.shippingSameAsBilling === 0 && (
                            <EditShippingSection
                                selectedCompany={selectedCompany}
                                updateSelectedCompany={updateSelectedCompany}
                                updateCompanyAddressArr={updateCompanyAddressArr}
                            />
                        )}
                        <Grid item xs={12} md={12} lg={12}>
                            <div
                                style={{ display: "flex", justifyContent: "center" }}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => saveChanges()}
                                    style={{ width: "300px" }}
                                >
                                    Save
                                </Button>
                            </div>
                        </Grid>
                    </React.Fragment>
                )}

            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    snackbar: state.snackbar,
});

export default connect(mapStateToProps, {
    setSnackBarProps,
})(EditCompany);