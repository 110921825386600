import React, { useEffect, useState } from "react"
import { withStyles } from "@material-ui/core/styles";
import {
    Grid,
    TextField,
    Tabs,
    Tab,
    Box,
    Typography,
    Checkbox,
    FormControlLabel
} from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import Axios from "axios"
import { ConvertNZPostAddress } from "../../../Utils/nzPostUtils";

const WhiteTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "white",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "white",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "white",
            },
            "&:hover fieldset": {
                borderColor: "white",
            },
            "&.Mui-focused fieldset": {
                borderColor: "white",
            },
        },
    },
})(TextField);

const CompanySection = (props) => {
    let {
        newCompany,
        updateNewCompany
    } = props;

    return (
        <React.Fragment>
            <Grid item xs={12} md={12} lg={12}>
                <WhiteTextField
                    key={"create-company-name-textbox"}
                    label="Name"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => {
                        updateNewCompany("name", e.target.value)
                    }}
                    value={newCompany.name}
                />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
                <WhiteTextField
                    label="Accounts Email"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => {
                        updateNewCompany("accountsEmail", e.target.value)
                    }}
                    value={newCompany.accountsEmail}
                />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
                <WhiteTextField
                    label="Default Purchase Order"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => {
                        updateNewCompany("purchaseOrder", e.target.value)
                    }}
                    value={newCompany.purchaseOrder}
                />
            </Grid>
        </React.Fragment>
    )
}

export default CompanySection;