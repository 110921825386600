import { TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { statusOptions } from "../../../../repository/referrals";
import React from "react";
import "./referralsFilter.scss";


const ReferralsFilter = ({ filter, setFilter }) => {
  const handleChangeStatus = (e, option) => {
    setFilter(option);
  };

  return (
    <div>
      <Autocomplete
        id="combo-box-demo"
        options={statusOptions}
        onChange={handleChangeStatus}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.label === value.label}
        value={statusOptions.find((option) => option.label === filter.label)}
        style={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Status"
            variant="outlined"
            size="small"
            wi
          />
        )}
      />
    </div>
  );
};

export default ReferralsFilter;
