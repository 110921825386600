import React from "react";
import "./orderLinkConfirmationModal.scss";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import OrderSummary from "../OrderSummary/OrderSummary";
import { StoreContext } from "../../../context/store/StoreContext";
import { useContext } from "react";
import { useEffect } from "react";
import { convertIdFromGraphql } from "../../Utils/shopifyConverter";
import axios from "axios";
import { calculateTotalCheckout } from '../../../repository/shopify';

function OrderLinkConfirmationModal({
  open,
  handleClose,
  firestoreCustomer,
  shippingAddress,
  confirmOrderLinkSent,
  discountCode,
  shippingCost,
}) {
  const { checkout, useCustomTotal, customTotal, updateDiscount, generatedDiscount } = useContext(StoreContext);
  const [link, setLink] = useState("");
  const [loadingDiscount, setLoadingDiscount] = useState(true);

  useEffect(() => {
    if (useCustomTotal) {
      setLoadingDiscount(true);
      updateDiscount(customTotal - calculateTotalCheckout(checkout, shippingCost), 
        calculateTotalCheckout(checkout, 0) - 0.5, 
        firestoreCustomer ? `${firestoreCustomer.firstName}${firestoreCustomer.lastName}` : 'Unkown Name').then(() => {setLoadingDiscount(false);})
    } else {
      setLoadingDiscount(false);
    }
  }, [useCustomTotal, customTotal]);

  useEffect(() => {
    setLink(renderLinkUrl());
  }, [useCustomTotal, generatedDiscount])

  const renderLinkUrl = () => {
    let shopQueryParam = "?vid=";

    var discountToUse;
    discountToUse = checkout.discountApplications.length > 0
        ? checkout.discountApplications[0].code
        : "";
    
    if(useCustomTotal) {
      discountToUse = generatedDiscount.code
    }
    
    const discountParam = discountToUse !== "" ? "&discount=" + discountToUse : ""

    if (checkout?.lineItems?.length > 0) {
      for (let i = 0; i < checkout?.lineItems?.length; i++) {
        console.log(checkout);
        const totalNumberCapsules = Number(
          checkout?.lineItems[i].customAttributes.find(
            (a) => a.key === "numberOfCapsules"
          )?.value || 0
        );
        shopQueryParam =
          shopQueryParam +
          `${convertIdFromGraphql(checkout?.lineItems[i].variant?.id)}:${
            checkout?.lineItems[i].quantity
          }:${totalNumberCapsules * checkout?.lineItems[i].quantity},`;
      }
    }
    return (
      "https://www.coffeecapsules2u.co.nz/checkout" +
      shopQueryParam +
      "&email=" +
      firestoreCustomer.email +
      discountParam
    );
  };
  const sendLinkToCustomer = () => {
    axios
      .post(`adminPanel/SendOrderLinkToCustomer`, {
        first_name: firestoreCustomer.firstName,
        email: firestoreCustomer.email,
        shop_link: link,
        line_items: checkout?.lineItems.map((item) => ({
          quantity: item.quantity,
          product_title: item?.title + " - " + item.variant.title,
        })),
      })
      .then(({ data }) => {
        confirmOrderLinkSent(true);
      })
      .catch((err) => {
        confirmOrderLinkSent(false);
      })
      .finally(() => handleClose());
  };

  return (
    <Dialog fullWidth maxWidth={"lg"} open={open} onClose={handleClose}>
      <DialogTitle>{`New Order for ${firestoreCustomer.firstName}`}</DialogTitle>
      <DialogContent>
        <div className="order-confirmation-modal-root">
          <div className="order-confirmation-modal-lineitems">
            <Typography variant="h6">
              <strong>{`Items`}</strong>
            </Typography>
            <div>
              {checkout?.lineItems.map((item, index) => (
                <div key={index} className="order-confirmation-modal-variants">
                  <Typography
                    variant="body2"
                    className={"order-confirmation-modal-name"}
                  >
                    <strong>{item.title}</strong>
                  </Typography>
                  <Typography
                    variant="body2"
                    className={"order-confirmation-modal-meta"}
                  >
                    {item.variant?.title === "Default Title"
                      ? ""
                      : item.variant?.title}
                    {" x " + item.quantity}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
          <div className="order-confirmation-modal-customerDetails">
            <div className="order-confirmation-modal-info">
              <Typography variant="h6">
                <strong>Shipping Address</strong>
              </Typography>
              <Typography variant="body2">{`${shippingAddress.firstName} ${shippingAddress.lastName}`}</Typography>
              {shippingAddress.companyName ? (
                <Typography variant="body2">
                  {shippingAddress.companyName}
                </Typography>
              ) : (
                ""
              )}
              <Typography variant="body2">
                {shippingAddress.addressLine1}
              </Typography>
              <Typography variant="body2">
                {shippingAddress.addressLine2}
              </Typography>
              <Typography variant="body2">{`${shippingAddress.city} ${shippingAddress.postCode}`}</Typography>
              <Typography variant="body2">
                {shippingAddress.phoneNumber}
              </Typography>
            </div>
            <div className="order-confirmation-modal-info">
                <Typography variant="h6">
                  <strong>Order Link</strong>
                </Typography>

                {!loadingDiscount && 
                  (<div className="selected-payment-container">
                      <Typography
                        variant="body2"
                        className={"order-confirmation-modal-meta"}
                      >
                        {link}
                      </Typography>
                  </div>)}
            </div>
          </div>

          <OrderSummary
            isReadOnly
            shippingCost={shippingCost}
            discountCode={discountCode}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={sendLinkToCustomer}
          color="primary"
          variant="contained"
          disabled = {loadingDiscount}
        >
          Send Link to Customer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OrderLinkConfirmationModal;
