import { TextField } from "@material-ui/core";
import React from "react";
import { CardElement } from '@stripe/react-stripe-js';
import './addNewPayment.scss'

const AddNewPayment = ({
  handleCardChange,
  setCardholderName,
  cardholderName,
  errorState,
}) => {
  return (
    <div className="add-new-card-container">
      <div className="payment-container-stripe">
        <CardElement
          options={{
            hidePostalCode: true,
            style: {
              base: {
                fontSize: "16px",
                color: "#000",
                fontFamily:
                  'Inter,Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
                iconColor: "#000",
                "::placeholder": {
                  color: "#716868",
                  fontSize: "14.5px",
                  fontFamily:
                    'Inter,Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
                },
              },
            },
          }}
          onChange={handleCardChange}
        />
      </div>
      <TextField
        id="cardholderName"
        label="Cardholder Name *"
        variant="outlined"
        size="small"
        value={cardholderName}
        error={errorState.cardholderName}
        placeholder={"Cardholder Name *"}
        onChange={(e) => setCardholderName(e.target.value)}
      />
    </div>
  );
};

export default AddNewPayment;
