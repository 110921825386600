import { Typography } from "@material-ui/core";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import AbandonedCheckoutsTable from "../AbandonedCheckoutsTable/AbandonedCheckoutsTable";
import AdminLayout from "../AdminLayout/AdminLayout";
import "./abandonedCheckout.scss";
const AbandonedCheckout = () => {
  const [checkouts, setCheckouts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    searchAbandonedCheckouts();
  }, []);

  const searchAbandonedCheckouts = () => {
    setIsLoading(true);
    axios
      .get(`/Stripe/SearchPaymentIntentsForAbandonedCart`)
      .then(({ data }) => {
        setIsLoading(false);
        console.log(data);
        setCheckouts(data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  };

  return (
    <AdminLayout>
      <div className="root">
        <div className="header-container">
          <div className="name-container">
            <Typography variant="h4">Abandoned Checkouts</Typography>
          </div>
        </div>
        <div className="table-results-cotnainer">
          <AbandonedCheckoutsTable
            isLoading={isLoading}
            checkouts={checkouts}
          />
        </div>
      </div>
    </AdminLayout>
  );
};

export default AbandonedCheckout;
