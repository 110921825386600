import React, { useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import ReplyIcon from "@material-ui/icons/Reply";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button, CardActions } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import MUIRichTextEditor from "mui-rte";
import { stateToHTML, draftToHtml } from "draft-js-export-html";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
    },
    subheader: {
      fontStyle: "italic",
    },
    cardheader: {
      border: "1px solid #f0f0f0",
      borderRadius: 10,
    },
  })
);

const ReplyEmailMessageCard = (props) => {
  const [emailBody, setEmailBody] = useState("");
  const classes = useStyles();

  const handleEmailBodyChange = (editorState) => {
    if (editorState.getCurrentContent().hasText()) {
      const html = stateToHTML(editorState.getCurrentContent());
      const divWrapper = document.createElement("div");
      divWrapper.innerHTML = html;
      setEmailBody(divWrapper.outerHTML);
    }
  };

  const handleSendButton = (e) => {
    let replyEmailMessage = {...props.emailMessage};
    replyEmailMessage.id = "";
    replyEmailMessage.to = props.emailMessage?.from;
    replyEmailMessage.body = emailBody;
    props.handleSendReplyMessage(replyEmailMessage);
  }

  const renderSubheader = () => {
    if (props.emailMessage?.cc && props.isReplyAll) {
      return (
        <div>
          <div>To: {props.emailMessage?.from}</div>
          <div>Cc: {props.emailMessage?.cc}</div>
        </div>
      );
    }
    return <div>To: {props.emailMessage?.from}</div>;
  };

  return (
    <div>
      {props.open ? (
        <Card className={classes.root} elevation={1}>
          <CardHeader
            className={classes.cardheader}
            action={
              <IconButton aria-label="settings">
                <DeleteIcon onClick={(e) => props.handleDiscardReply()} />
              </IconButton>
            }
            classes={{ subheader: classes.subheader }}
            component={Box}
            subheader={renderSubheader()}
            subheaderTypographyProps={{
              variant: "caption",
              align: "left",
              gutterBottom: true,
            }}
            title="Patrick Cole <pcole@coffeecapsules2u.co.nz>"
            titleTypographyProps={{ variant: "subtitle2", align: "left" }}
          />
          <CardContent>
            <MUIRichTextEditor
              onChange={handleEmailBodyChange}
              inlineToolbar={true}
              label="Type here..."
            />
          </CardContent>
          <CardActions style={{marginTop: 12}}>
            <Button
              autoFocus
              onClick={(e) => handleSendButton()}
              color="primary"
              endIcon={<SendIcon />}
            >
              Send
            </Button>
          </CardActions>
        </Card>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default ReplyEmailMessageCard;
