import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import React from "react";
import SubscriptionsRow from "../SubscriptionsRow/SubscriptionsRow";
import "./subscriptionsTable.scss";

const headCells = [
  { id: "name", numeric: false, disablePadding: false, label: "Name" },
  {
    id: "nextShipment",
    numeric: false,
    disablePadding: false,
    label: "Next Shipment",
  },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
];

function descendingComparator(a, b, orderBy) {
  if (orderBy === "name") {
    if (
      `${b["shippingAddress"]["firstName"]} ${b["shippingAddress"]["lastName"]}` <
      `${a["shippingAddress"]["firstName"]} ${a["shippingAddress"]["lastName"]}`
    ) {
      return -1;
    }
    if (
      `${b["shippingAddress"]["firstName"]} ${b["shippingAddress"]["lastName"]}` >
      `${a["shippingAddress"]["firstName"]} ${a["shippingAddress"]["lastName"]}`
    ) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }

  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const SubscriptionsTable = ({
  subscriptions,
  handleUpdateStatus,
  handleUpdateAddress,
  handleSetIsRural,
  paymentMethods,
  handleUpdatePayment,
  handleQuantityChange,
  isUpdating,
  handleNewProduct,
  handleRemoveItem,
  handleEditFrequency,
  isGlobalPage,
  handleCreateNewSubscription,
  handleProcessSubscriptionOrder,
  deleteSubscriptionOrder,
  isProcessing,
  justProccessedSubscriptionId,
}) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("nextShipment");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div>
      {!isGlobalPage && (
        <div className="subscriptions-table-new-autoship">
          <Button variant="outlined" onClick={handleCreateNewSubscription}>
            New Autoship
          </Button>
        </div>
      )}
      {!isGlobalPage && subscriptions.length === 0 ? (
        <div>
          <Typography variant="body2">
            <strong>Autoship has not been set up.</strong>
          </Typography>
        </div>
      ) : (
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={(e) => handleRequestSort(e, headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(subscriptions, getComparator(order, orderBy)).map(
                (subscription) => (
                  <SubscriptionsRow
                    key={subscription.id}
                    subscription={subscription}
                    paymentMethods={paymentMethods}
                    handleUpdateStatus={handleUpdateStatus}
                    handleUpdateAddress={handleUpdateAddress}
                    handleUpdatePayment={handleUpdatePayment}
                    handleQuantityChange={handleQuantityChange}
                    handleSetIsRural={handleSetIsRural}
                    isUpdating={isUpdating}
                    handleNewProduct={handleNewProduct}
                    handleRemoveItem={handleRemoveItem}
                    handleEditFrequency={handleEditFrequency}
                    handleProcessSubscriptionOrder={
                      handleProcessSubscriptionOrder
                    }
                    deleteSubscriptionOrder={deleteSubscriptionOrder}
                    isProcessing={isProcessing}
                    justProcessed={
                      justProccessedSubscriptionId === subscription.id
                    }
                  />
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default SubscriptionsTable;
