import React, { useState, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { isValidToken } from "./authHelpers";
import Loader from "../Shared/Loader";

const AuthenticatedRoute = (props) => {
  const { component: Component, token, path } = props;
  const [state, setState] = useState('loading');

  useEffect(() => {
    try {
      const token = isValidToken();
      setState(token === "" ? 'redirect' : 'loggedin');
    }
    catch {
      setState('redirect');
    }
  }, []);

  if (state === 'loading') {
    return <div>Loading..</div>
  }

  return (
    <Route
      exact
      path={path}
      render={props => ((state === 'loggedin') ? <Component {...props} /> : <Redirect to="/login" />)}
    />
  );
};
const mapStateToProps = (state) => ({
  token: state.authentication.token,
});
export default connect(mapStateToProps, {})(AuthenticatedRoute);
