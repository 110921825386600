import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";


const PdfViewer = ({ file, scale = 1 }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} scale={scale} />
      </Document>
    </div>
  );
};

export default PdfViewer;
