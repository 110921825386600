import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";

const AddressHistoryTable = (props) => {
  return (
    <div>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow style={{ height: "40px" }}>
            <TableCell align={"center"}>Type</TableCell>
            <TableCell align={"center"}>Updated At</TableCell>
            <TableCell align={"center"}>New Address</TableCell>
            <TableCell align={"center"}>Previous Address</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.historyList.map((history) => {
            return (
              <TableRow key={history.id}>
                  <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  className="messaging-body-cell" 
                >
                  {history.type}
                </TableCell>
                  <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  className="messaging-body-cell" 
                >
                  {new Date(history.updatedAt).toLocaleString('en-nz')}
                </TableCell>
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  className="messaging-body-cell" 
                >
                  {history.newAddress1}  {history.newAddress2} {history.newCity} {history.newPostcode}
                </TableCell>
                <TableCell
                  align="center"
                  className="messaging-body-cell" 
                >
                  {history.oldAddress1} {history.newAddress2} {history.oldCity} {history.oldPostcode}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default AddressHistoryTable;
