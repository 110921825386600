import React from 'react';
import {
    Grid,
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    MenuItem,
    MenuList,
} from "@material-ui/core";
import {
    ArrowDropDown as ArrowDropDownIcon
} from "@material-ui/icons"

//match enums serverside
const splitButtonOptions = [
    'One or more packages missing',//0
    'Watching Package',//1
    'Contacting NZPost',//2
    'Check Delivery',//3
    'Successfully Delivered',//4
    'Test Email'//5
];

const SplitButton = (props) => {
    const {
        selectedIndex,
        setSelectedIndex,
        reloadTempates
    } = props;

    const anchorRef = React.useRef(null);

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        console.info(`You clicked ${splitButtonOptions[selectedIndex]}`);
    };

    const handleMenuItemClick = (event, index) => {
        reloadTempates();
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <Grid container direction="column" alignItems="center">
            <Grid item xs={12}>
                <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
                    <Button onClick={handleClick}>{splitButtonOptions[selectedIndex]}</Button>
                    <Button
                        color="primary"
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: "9" }}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu-email-template">
                                        {splitButtonOptions.map((option, index) => (
                                            <MenuItem
                                                key={option}
                                                selected={index === selectedIndex}
                                                onClick={(event) => handleMenuItemClick(event, index)}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    );
}

export default SplitButton;