import { TableCell, TableRow, TextField } from "@material-ui/core";
import React from "react";

const ProductDetailsBusinessDirectRow = ({
  product,
  handleUpdateLocalProduct,
  index,
}) => {
  return (
    <TableRow
      className={` ${
        index % 2 === 0 ? "products-table-even-row" : "products-table-odd-row"
      } 
         ${!product.isActive ? "products-table-inactive-row" : ""}`}
    >
      <TableCell className="products-table-cell">
        <TextField
          size="small"
          value={product?.productBusinessDirect?.wholesale || ""}
          fullWidth
          onChange={(e) => {
            handleUpdateLocalProduct(
              "productBusinessDirect",
              { ...product?.productBusinessDirect, wholesale: e.target.value },
              product?.id
            );
          }}
          InputLabelProps={{ shrink: true }}
        />
      </TableCell>
      <TableCell className="products-table-cell">
        {" "}
        <TextField
          size="small"
          value={product?.productBusinessDirect?.retail || ""}
          fullWidth
          onChange={(e) => {
            handleUpdateLocalProduct(
              "productBusinessDirect",
              { ...product?.productBusinessDirect, retail: e.target.value },
              product?.id
            );
          }}
          InputLabelProps={{ shrink: true }}
        />
      </TableCell>
    </TableRow>
  );
};

export default ProductDetailsBusinessDirectRow;
