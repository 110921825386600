import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import CurrencyFormatter from "../../Shared/CurrencyFormatter";
const WebsiteItemsDialog = ({ open, handleClose, items }) => {
  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogContent>
        <div className="default-admin-table-container-wrapper">
          <Typography className="default-admin-table-heading">
            Line Items
          </Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    variant="caption"
                    className="default-admin-table-header"
                  >
                    Item
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="caption"
                    className="default-admin-table-header"
                  >
                    Quantity
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="caption"
                    className="default-admin-table-header"
                  >
                    Price
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="caption"
                    className="default-admin-table-header"
                  >
                    SKU
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => (
                <TableRow
                  key={index}
                  className={
                    index % 2 === 0
                      ? "default-admin-table-even-row"
                      : "default-admin-table-odd-row"
                  }
                >
                  <TableCell className="default-admin-table-cell">
                    <Typography variant="caption">
                      {item.productTitle} {item.variantTitle}
                    </Typography>
                  </TableCell>
                  <TableCell className="default-admin-table-cell">
                    <Typography variant="caption">{item.quantity}</Typography>
                  </TableCell>
                  <TableCell className="default-admin-table-cell">
                    <Typography variant="caption">
                      <CurrencyFormatter useDollar amount={item.price} />
                    </Typography>
                  </TableCell>
                  <TableCell className="default-admin-table-cell">
                    <Typography variant="caption">{item.sku}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => handleClose()}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WebsiteItemsDialog;
