import React, { useState, useEffect } from "react";
import { EditorState, Modifier } from "draft-js";
import {
  Select,
  MenuItem,
} from "@material-ui/core";

const mergeTags = [
  { name: "First Name", value: "{firstName}" },
  { name: "Last Name", value: "{lastName}" },
  { name: "Order No.", value: "{orderNumber}" },
  { name: "Day Sent", value: "{daySent}" },
  { name: "No Boxes Sent", value: "{numberBoxesSent}" },
  { name: "No Boxes Delivered", value: "{numberBoxesDeivered}" },
  { name: "Received Package Button", value: "{receivedPackageButton}" },
  { name: "Did Not Recieve Package Button", value: "{didNotReceivePackageButton}" },
  { name: "Tracking Link 1", value: "{trackinglink1}" },
  { name: "Tracking Link 2", value: "{trackinglink2}" },
  { name: "Tracking Link 3", value: "{trackinglink3}" },
  { name: "Tracking Link 4", value: "{trackinglink4}" },
  { name: "Tracking Link 5", value: "{trackinglink5}" },
  { name: "Courier Name", value: "{courierName}" },
  { name: "Missing Tracking Links", value: "{missingTrackingLinks}" },
  { name: "Mark as delivered link", value: "{markAsDeliveredLink}" },
];

export const MergeTagOptions = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const addTag = () => {
    const { editorState, onChange } = props;

    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      selectedOption,
      editorState.getCurrentInlineStyle()
    );

    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  useEffect(() => {
    if (selectedOption !== null) {
      addTag();
      setSelectedOption(null);
    }
  }, [selectedOption]);

  return (
    <Select
      value={"MergeTags"}
      onChange={(event) => {
        setSelectedOption(event.target.value);
      }}
    >
      <MenuItem key={1} value={"MergeTags"} disabled>
        Merge Tags
      </MenuItem>
      {mergeTags.map((tag, index) => {
        return (
          <MenuItem key={index + 1} value={tag.value}>
            {tag.name}
          </MenuItem>
        );
      })}
    </Select>
  );
};