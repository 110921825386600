import React, { useRef } from "react";
import {
    GoogleMap,
    Polygon,
    withScriptjs,
    withGoogleMap,
    TrafficLayer,
} from "react-google-maps";
import _ from "lodash";

function MemoizedPolygonPropsAreEqual(prevState, nextState) {
    return (
        _.isEqual(prevState.poly.metaData, nextState.poly.metaData)
    );
}

const MemoizedPolygon = React.memo(function MemoizedPolygon(props) {
    debugger
    const {
        boundaries,
        poly,
        onClick
    } = props;

    const polygonRef = useRef(this);

    return (
        <Polygon
            ref={polygonRef}
            path={boundaries[poly.metaData.id]}
            key={poly.metaData.id}
            options={{
                fillColor: poly.metaData.deliveryZone.hex,
                fillOpacity: 0.4,
                strokeColor: "black",
                strokeOpacity: 0.8,
                strokeWeight: 3
            }}
            onClick={() => { onClick(poly, polygonRef); }}
        />
    );
}, MemoizedPolygonPropsAreEqual);

export default MemoizedPolygon;

