import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { useHistory } from "react-router-dom";
import jwtDecode from "jwt-decode";
import axios from "axios";

const Login = ({ location }) => {
  const history = useHistory();

  
  const navigateTarget = location.state?.navigateTarget;
  return (
    <div className="login-style">
      <GoogleLogin
        onSuccess={({ credential }) => {
          const userInfo = jwtDecode(credential);
          if(userInfo && userInfo.email && userInfo.email_verified){
            axios
            .get(`Users/GetUserByEmail/${userInfo.email}`)
            .then(({ data }) => {
              window.localStorage.setItem("access_token", credential);
              if (navigateTarget) {
                history.push(navigateTarget)
              } else {
                history.push("/Home")
              }
            })
            .catch((err) => alert("user is not allowed"));
          }else{
            window.localStorage.removeItem("access_token");
          }
        }}
        onError={() => {
          console.log("Login Failed");
        }}
      />
    </div>
  );
};

export default Login;
