import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Button,
  Toolbar,
} from "@material-ui/core";
import { updateOutOfStock } from "../../reduxActions/system";
import { getStock } from "../../reduxActions/stock";
import StockTable from "./StockTable";
import StockRows from "./StockRows";
import StockInstructions from "./StockInstructions";
import "./stockLevels.scss";
import Footer from "../Footer/Footer";
import axios from "axios";
import { setSnackBarProps } from "../../reduxActions/snackbar";
import Snacky from "../Shared/Snacky";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} id={`stock-tabpanel-${index}`} {...other}>
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `stock-tab-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#27293d",
  },
}));

const SimpleTabs = (props) => {
  const { getStock, goToStockTab, snackbar, setSnackBarProps } = props;

  const classes = useStyles();
  const [value, setValue] = useState(0);

  useEffect(() => {
    console.log(goToStockTab);
    if (goToStockTab) {
      setValue(0);
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      getStock();
    }
  };

  //row.LastOpen = DateTime.UtcNow;
  const handleOpenAllRows = () => {
    axios.patch(`Stock/OpenAllRows`)
    .then(res => {
      setSnackBarProps("success", "Rows opened", true);
    })
    .catch(e => setSnackBarProps("warning", "Failed to open rows", true))
  };

  return (
    <div className={classes.root}>
      <Snacky snackprops={snackbar} setSnackBarProps={setSnackBarProps} />
      <AppBar position="static" id="stock-tab-app-bar">
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Update Stock" {...a11yProps(0)} />
          <Tab label="Dashboard" {...a11yProps(1)} />
          <Tab label="Row Flavours" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      {value === 0 && (
        <Button variant="contained" size="small" color="primary"
          onClick={() => handleOpenAllRows()}
          id="open-all-rows-button"
        >
          Open All Rows
        </Button>)}
      <TabPanel value={value} index={0}>
        <StockRows />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <StockTable />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <StockInstructions />
      </TabPanel>
      {value === 0 && <Footer />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  stock: state.stock.stock,
  snackbar: state.snackbar,
});

export default connect(mapStateToProps, {
  getStock,
  setSnackBarProps,
})(SimpleTabs);
