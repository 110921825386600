import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "axios";
import ManageAddressDialog from "./ManageAddressDialog";
import AddUpdateAddressDialog from "./AddUpdateAddressDialog";
import { useCustomer } from "../../../context/customer/CustomerContext";

const CustomerAddressCard = ({
  customerId,
  isCheckout,
  setShippingAddress,
}) => {
  const { firestoreCustomer } = useCustomer();
  const [loadingAddresses, setLoadingAddresses] = useState(true);

  const [addresses, setAddresses] = useState([]);
  const [defaultAddress, setDefaultAddress] = useState(null);
  const [showManageAddressCard, setShowManageAddressCard] = useState(false);
  const [showAddUpdateAddressDialog, setShowAddUpdateAddressDialog] =
    useState(false);

  useEffect(() => {
    if (isCheckout) {
      setShippingAddress(defaultAddress);
    }
  }, [defaultAddress]);

  useEffect(() => {
    if (customerId || firestoreCustomer) {
      makeFetchAddressRequest();
    }
  }, [customerId, firestoreCustomer]);

  const makeFetchAddressRequest = () => {
    setLoadingAddresses(true);
    axios
      .get(`AdminPanel/GetAddresses/${customerId}`)
      .then((res) => {
        var defaultAddress = res.data.addresses.find((a) => a.isDefaultAddress);
        if (defaultAddress) {
          setDefaultAddress(defaultAddress);
        } else if (!defaultAddress && res.data.addresses.length > 0) {
          setDefaultAddress(res.data.addresses[0]);
        } else {
          setDefaultAddress(null);
        }
        setAddresses(res.data.addresses);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoadingAddresses(false);
      });
  };

  return (
    <Card>
      <ManageAddressDialog
        addresses={addresses}
        open={showManageAddressCard}
        handleClose={() => setShowManageAddressCard(false)}
        reloadAddresses={() => makeFetchAddressRequest()}
        customerId={customerId}
      />
      <AddUpdateAddressDialog
        open={showAddUpdateAddressDialog}
        handleClose={() => setShowAddUpdateAddressDialog(false)}
        reloadAddresses={() => makeFetchAddressRequest()}
        type={"add"}
        customerId={customerId}
        addressToEdit={{}}
      />
      {loadingAddresses ? (
        <CircularProgress />
      ) : (
        <React.Fragment>
          {defaultAddress ? (
            <div>
              <CardContent>
                <Typography variant="h6">Default address</Typography>
                <Typography variant="body2">{`${defaultAddress.firstName} ${defaultAddress.lastName}`}</Typography>
                {defaultAddress.companyName ? (
                  <Typography variant="body2">
                    {defaultAddress.companyName}
                  </Typography>
                ) : (
                  ""
                )}
                <Typography variant="body2">
                  {defaultAddress.addressLine1}
                </Typography>
                <Typography variant="body2">
                  {defaultAddress.addressLine2}
                </Typography>
                <Typography variant="body2">{`${defaultAddress.city} ${defaultAddress.postCode}`}</Typography>
                <Typography variant="body2">
                  {defaultAddress.phoneNumber}
                </Typography>
                <Typography variant="body2">
                  <i>{defaultAddress.note}</i>
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  color="primary"
                  size="small"
                  onClick={() => setShowAddUpdateAddressDialog(true)}
                >
                  Add new address
                </Button>
                <Button
                  color="primary"
                  size="small"
                  onClick={() => setShowManageAddressCard(true)}
                >
                  Manage
                </Button>
              </CardActions>
            </div>
          ) : (
            <div>
              <CardContent>
                <Typography variant="h6">Default address</Typography>
                <Typography variant="body2">No Address found...</Typography>
              </CardContent>
              <CardActions>
                <Button
                  color="primary"
                  size="small"
                  onClick={() => setShowAddUpdateAddressDialog(true)}
                >
                  Add new address
                </Button>
              </CardActions>
            </div>
          )}
        </React.Fragment>
      )}
    </Card>
  );
};

export default CustomerAddressCard;
