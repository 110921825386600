import React from "react";
import { connect } from "react-redux";
import OrdersTable from "../Shared/OrdersTable";

const StoreOrders = (props) => {
  const { orders, hpMode } = props;

  if (orders) {
    return (
      <OrdersTable
        parent="Store Orders"
        orders={orders.filter(
          (o) => o.status === 0 && (hpMode === false || o.hpBulkPack === 0)
        )}
      />
    );
  } else {
    return <div>Loading...</div>;
  }
};

const mapStateToProps = (state) => ({
  orders: state.order.orders,
  hpMode: state.system.hpMode,
});

export default connect(mapStateToProps, {})(StoreOrders);
