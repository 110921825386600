import React from 'react'
import AddressHistoryTable from './AddressHistoryTable'

const AddressHistoryTabPanel = (props) => {
    return (
        <div>
            <AddressHistoryTable historyList={props.shippingAddressHistory}/>
        </div>
    )
}

export default AddressHistoryTabPanel
