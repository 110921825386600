import { Container, Dialog, IconButton, Slide, Typography } from "@material-ui/core";
import React from "react";
import "./subscriptionProductsModal.scss";
import CloseIcon from '@material-ui/icons/Close';
import ProductsTabs from "../CreateOrder/ProductTabs";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const SubscriptionProductsModal = ({
  open,
  handleClose,
  handleSelectNewProduct,
  isAddingNewProduct,
}) => {
  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "#FAF8F3",
            padding: 15,
          },
        }}
      >
        <div className={"subscription-products-root"}>
            <Container>
            <div className={"subscription-products-closeContainer"}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon  />
              </IconButton>
            </div>
            {isAddingNewProduct ? (
              <>
                <Typography variant="h2">Add a new Autoship product</Typography>
              </>
            ) : (
              <>
                <Typography variant="h2">Update Autoship Product</Typography>
              </>
            )}
            </Container>
            <ProductsTabs isSubscription={true} handleSelectNewProduct={handleSelectNewProduct}/>
        </div>
      </Dialog>
    </div>
  );
};

export default SubscriptionProductsModal;
