import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import {
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Checkbox,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import {
  Flag as FlagIcon,
  ConfirmationNumber as ConfirmationNumberIcon,
  NotificationsActive as NotificationsActiveIcon,
  PanTool as PanToolIcon,
  CheckCircleOutline,
  NotInterested,
} from "@material-ui/icons";
import "../Shared/storeOrders.scss";
import { convertTime, formatTime } from "../Utils/dateTime";
import "material-icons";
import Snacky from "../Shared/Snacky";
import { setSnackBarProps } from "../../reduxActions/snackbar";
import { getComparator, stableSort } from "../Shared/Table";
import axios from "axios";

function MemoizedTableBodyPropsAreEqual(prevState, nextState) {
  return (
    prevState.order === nextState.order &&
    prevState.orderBy === nextState.orderBy &&
    _.isEqual(prevState.selected, nextState.selected) &&
    _.isEqual(prevState.filteredOrders, nextState.filteredOrders)
  );
}

const MemoizedDelayedTableBody = React.memo(function MemoizedDelayedTableBody(
  props
) {
  const {
    filteredOrders,
    order,
    orderBy,
    parent,
    isSelected,
    selected,
    handleClick,
    setOpenDelayedOrder,
    setSelectedOrder,
  } = props;

  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [showTicketConfirmation, setShowTicketConfirmation] = useState(false);

  const sendTicket = () => {
    setLoading(true);
  };

  if (loading) {
    return <CircularProgress />;
  } else {
    return (
      <React.Fragment>
        {showTicketConfirmation && (
          <Dialog
            open={showTicketConfirmation}
            keepMounted
            onClose={() => {
              setSelectedOrder(null);
              setShowTicketConfirmation(false);
            }}
          >
            <DialogTitle>{`Send Ticket`}</DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to send a ticket to NZ Post about this
                order?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="default"
                onClick={() => {
                  setSelectedOrder(null);
                  setShowTicketConfirmation(false);
                }}
              >
                Go Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => sendTicket()}
              >
                Print
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <TableBody className="table-body">
          {stableSort(filteredOrders, getComparator(order, orderBy)).map(
            (o, index) => {
              const isItemSelected = isSelected(o.order.id);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  tabIndex={-1}
                  key={`${o.order.id}-${o.trackingNumber}-${o.fromDisputed}-${o.status}`}
                  selected={isItemSelected}
                  hover
                  role="checkbox"
                  onClick={() => {
                    let orderClone = { ...o };
                    orderClone.hasUpdates = 0;
                    setOpenDelayedOrder(true);
                    setSelectedOrder(orderClone);
                  }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      style={{ color: "white" }}
                      onClick={(event) => handleClick(event, o.order.id)}
                      checked={isItemSelected}
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </TableCell>
                  <TableCell padding="checkbox">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      {o.ticketSent && <ConfirmationNumberIcon />}
                      {o.hasUpdates && (
                        <NotificationsActiveIcon style={{ color: "#4a94a8" }} />
                      )}
                    </div>
                  </TableCell>
                  <TableCell>{`SC${o.order.orderNumber}`}</TableCell>
                  <TableCell align="center">{o.packageNo}</TableCell>
                  <TableCell>{o.order.customerFirstName}</TableCell>
                  <TableCell>{o.order.customerLastName}</TableCell>
                  <TableCell>{`${o.order.shippingAddress.address2} - ${o.order.shippingAddress.city}`}</TableCell>
                  <TableCell>{o.boxSize}</TableCell>
                  <TableCell>
                    {convertTime(o.order.orderStatusTime.shipped)}
                  </TableCell>
                  <TableCell>{o.hoursDelayed}</TableCell>
                  <TableCell>{o.isNorth ? "North" : "South"}</TableCell>
                  <TableCell>
                    {o.status}
                    {o.handPacked === 1 ? <PanToolIcon fontSize="small" /> : ""}
                  </TableCell>
                  <TableCell>{o.courier}</TableCell>
                  <TableCell>
                    {o.scanOut ? (
                      <CheckCircleOutline size="small" style={{color: "green"}} />
                    ) : (
                      <NotInterested size="small" style={{color: "red"}} />
                    )}
                  </TableCell>
                </TableRow>
              );
            }
          )}
        </TableBody>
      </React.Fragment>
    );
  }
},
MemoizedTableBodyPropsAreEqual);

export default MemoizedDelayedTableBody;
