import moment from "moment/moment";
import { ruralPostcodes } from "./postcodeUtils";

export const checkSkuForCapsulesTotal = (sku, quantity) => {
  let totalNumberOfCapsules = 0;

  if (sku) {
    if (sku === "NZM_Sampler_Mixed" || sku === "COMP_Trial_Decaf") {
      totalNumberOfCapsules = 60 * quantity;
    } else if (sku === "COMP_Sampler_Mixed") {
      totalNumberOfCapsules = 50 * quantity;
    } else if (sku.toLowerCase().includes("sampler_mixed_")) {
      totalNumberOfCapsules = 70 * quantity;
    } else if (sku === "NZM_Trial_PHC") {
      totalNumberOfCapsules = 80 * quantity;
    } else if (sku.includes("180")) {
      totalNumberOfCapsules = 180 * quantity;
    } else if (sku.includes("120")) {
      totalNumberOfCapsules = 120 * quantity;
    } else if (sku.includes("140")) {
      totalNumberOfCapsules = 140 * quantity;
    } else if (sku.includes("240")) {
      totalNumberOfCapsules = 240 * quantity;
    } else if (sku.includes("360")) {
      totalNumberOfCapsules = 360 * quantity;
    } else if (sku.includes("20")) {
      totalNumberOfCapsules = 20 * quantity;
    } else if (sku.includes("40")) {
      totalNumberOfCapsules = 40 * quantity;
    } else if (sku.includes("60")) {
      totalNumberOfCapsules = 60 * quantity;
    } else if (sku.includes("80")) {
      totalNumberOfCapsules = 80 * quantity;
    } else {
      const split = sku?.split("_");
      if (split && split.length > 2) {
        const potentialTotal = split[1];
        if (potentialTotal && !isNaN(potentialTotal)) {
          totalNumberOfCapsules = Number(potentialTotal) * quantity;
        } else if (potentialTotal && !isNaN(split[2])) {
          totalNumberOfCapsules = Number(split[2]) * quantity;
        }
      }
    }
  }

  return totalNumberOfCapsules;
};

export const getShippingCost = (
  shippingRates,
  numberOfCapsules = 0,
  postcode = null
) => {
  var isRural = false;
  if (postcode !== null && postcode !== "") {
    var foundRural = ruralPostcodes.find((r) => r.Postcode === postcode);
    if (foundRural === undefined) {
      isRural = false;
    } else {
      isRural = true;
    }
  }

  if (numberOfCapsules >= 120) {
    if (isRural) {
      var ruralPromoShipping = shippingRates.find((s) => s.title === "Rural");
      return ruralPromoShipping.promoRate;
    } else {
      var metroPromoShipping = shippingRates.find((s) => s.title === "Metro");
      return metroPromoShipping.promoRate;
    }
  } else {
    if (isRural) {
      var ruralShippping = shippingRates.find((s) => s.title === "Rural");
      return ruralShippping.rate;
    } else {
      var metroShipping = shippingRates.find((s) => s.title === "Metro");
      return metroShipping.rate;
    }
  }
};

export const getShippingCostForFirestore = (
  shippingRates,
  numberOfCapsules = 0,
  postcode = null
) => {
  try {
    var isRural = false;
    if (postcode) {
      var foundRural = ruralPostcodes.find((r) => r.Postcode === postcode);
      if (!foundRural) {
        isRural = false;
      } else {
        isRural = true;
      }
    }

    if (numberOfCapsules >= 120) {
      if (isRural) {
        var ruralPromoShipping = shippingRates.find((s) => s.title === "Rural");
        return {
          eta: null,
          rate: ruralPromoShipping.promoRate,
          title: ruralPromoShipping.title,
        };
      } else {
        var metroPromoShipping = shippingRates.find((s) => s.title === "Metro");
        return {
          eta: null,
          rate: metroPromoShipping.promoRate,
          title: metroPromoShipping.title,
        };
      }
    } else {
      if (isRural) {
        var ruralShippping = shippingRates.find((s) => s.title === "Rural");
        return {
          eta: null,
          rate: ruralShippping.rate,
          title: ruralShippping.title,
        };
      } else {
        var metroShipping = shippingRates.find((s) => s.title === "Metro");
        return {
          eta: null,
          rate: metroShipping.rate,
          title: metroShipping.title,
        };
      }
    }
  } catch {
    return {
      rate: null,
      title: null,
    };
  }
};

export const checkIfPostcodeIsRural = (postcode) => {
  var isRural = false;
  if (postcode !== null && postcode !== "") {
    var foundRural = ruralPostcodes.find((r) => r.Postcode === postcode);
    if (foundRural === undefined) {
      isRural = false;
    } else {
      isRural = true;
    }
  }
};

export const convertCheckoutLineItemsForStripe = (checkout) => {
  let lineItems = "";
  if (checkout && checkout.lineItems?.length > 0) {
    checkout.lineItems.forEach((item) => {
      lineItems = lineItems+`${item.variant?.sku}x${item.quantity} `
    });
  }
  return lineItems;
};

export const calculateShipping = (shippingAddress, totalNumberOfCapsules, shippingRates) => {
  let shippingRate = {
    title: "Shipping",
    rate: 5,
  };

  if(shippingRates?.length){
    const ruralShippingRate = shippingRates.find(
      (rate) => rate.isRural
    );
    const metroShippingRate = shippingRates.find(
      (rate) => !rate.isRural
    );

    if (shippingAddress.ruralDelivery) {
      shippingRate.eta = calculateEta(true);
      shippingRate.title = ruralShippingRate.title;
      if (totalNumberOfCapsules < 120) {
        shippingRate.rate = ruralShippingRate.rate;
      } else {
        shippingRate.rate = ruralShippingRate.promoRate;
      }
    } else {
      shippingRate.eta = calculateEta(false);
      shippingRate.title = metroShippingRate.title;
      if (totalNumberOfCapsules < 120) {
        shippingRate.rate = metroShippingRate.rate;
      } else {
        shippingRate.rate = metroShippingRate.promoRate;
      }
    }
  }

  return shippingRate;
};

const calculateEta = (isRural) => {
  let eta = {
    deliveryEta: addWorkingDay(moment(), isRural ? 3 : 2),
    dispatchEta: addWorkingDay(moment(), 1),
  };
  return eta;
};

const addWorkingDay = (date, days) => {
  let daysToAdd = days;
  const today = moment(date);
  const nextWeekStart = today.clone().add(1, "week").weekday(1);
  const weekEnd = today.clone().weekday(5);

  const daysTillWeekEnd = Math.max(0, weekEnd.diff(today, "days"));
  if (daysTillWeekEnd >= daysToAdd) return today.clone().add(daysToAdd, "days");

  daysToAdd = daysToAdd - daysTillWeekEnd - 1;

  return nextWeekStart
    .add(Math.floor(daysToAdd / 5), "week")
    .add(daysToAdd % 5, "days");
}
