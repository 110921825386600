import React, { useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { connect } from "react-redux";
import {
  bulkRelease,
  bulkLabelsAndComplete,
  bulkNotes,
  bulkError,
  bulkSendHandpack,
  bulkSendStore,
  bulkSendPackingStation,
  bulkSendToHoldOrder,
  bulkToggleBulkPack,
} from "../../reduxActions/order";
import Dialogy from "./Dialogy";
import { Divider } from "@material-ui/core";

const getOptions = (hpMode) => {
  if (!hpMode)
    return [
      [
        "Send To Store",
        "Send To Line",
        "Send To Failed",
        "Send To Handpack",
        "Send To Order Hold",
        "Export Selected",
      ],
      ["Write Notes"],
      ["Complete + Evo Print", "Complete + HP Print"],
    ];
  else
    return [
      [
        "Send To Store",
        "Send To Line",
        "Send to Packing Station",
        "Send To Order Hold",
        "Export Selected",
        "Toggle Bulk Pack",
      ],
      ["Complete", "HP Print"],
      ["Complete + HP Print"],
    ];
};

const GroupedButtonActions = (props) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [buttonDisabled, setbuttonDisabled] = useState(false);
  const [evoPrintDialogy, setEvoPrintDialogyOpen] = useState(false);
  const {
    bulkRelease,
    bulkNotes,
    bulkLabelsAndComplete,
    selected,
    clearSelected,
    bulkError,
    bulkSendHandpack,
    bulkSendStore,
    bulkSendPackingStation,
    handleExportColumns,
    bulkSendToHoldOrder,
    bulkToggleBulkPack,
    hpMode,
  } = props;

  const handleMenuItemClick = (event, option) => {
    setbuttonDisabled(true);
    const timeoutId = setTimeout(() => {
      setbuttonDisabled(false);
    }, 5000);
    if (option === "Send To Line") {
      bulkRelease(selected);
    } else if (option === "Write Notes") {
      bulkNotes(selected);
    } else if (option === "Complete + Evo Print") {
      setEvoPrintDialogyOpen(true);
    } else if (option === "Send To Failed") {
      bulkError(selected);
    } else if (option === "Send To Handpack") {
      bulkSendHandpack(selected);
    } else if (option === "Send To Store") {
      bulkSendStore(selected);
    } else if (option === "Send to Packing Station") {
      bulkSendPackingStation(selected);
    } else if (option === "Complete + HP Print") {
      bulkLabelsAndComplete(selected, 2);
    } else if (option === "Send To Order Hold") {
      bulkSendToHoldOrder(selected);
    } else if (option === "Toggle Bulk Pack") {
      bulkToggleBulkPack(selected);
    } else if (option === "Export Selected") {
      console.log(selected);
      handleExportColumns(selected);
    } else {
    }
    // } else if (index === 6) {
    //   handleExportColumns(selected)
    // }else if (index === 7) {
    //   bulkLabelsAndComplete(selected, 2);
    // }else if (index === 8) {
    //   bulkSendToHoldOrder(selected);
    // }else {
    // }
    option !== "Complete + HP Print" &&
      option !== "Complete + Evo Print" &&
      option !== "Toggle Bulk Pack" &&
      clearSelected(); // clears if not export option

    setOpen(false);
    setbuttonDisabled(false);
    clearTimeout(timeoutId);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Dialogy
        open={evoPrintDialogy}
        handleClose={() => setEvoPrintDialogyOpen(false)}
        details={{
          title: "Robot Printer",
          text: "Are you sure you want to send the evo label",
          type: "checks",
        }}
        proceed={() => {
          bulkLabelsAndComplete(selected, 1);
          clearSelected();
        }}
      />
      <Grid container direction="column" alignItems="flex-end">
        <Grid item xs={12}>
          <ButtonGroup
            variant="contained"
            color="primary"
            ref={anchorRef}
            aria-label="split button"
            disabled={buttonDisabled}
          >
            {/* <Button onClick={handleClick}>{options[selectedIndex]}</Button> */}
            <Button>Actions</Button>
            <Button
              color="primary"
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: 9999 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {getOptions(hpMode).map((optionsInSection, index) => (
                        <>
                          {index ? <Divider /> : <></>}
                          {optionsInSection.map((option, index) => (
                            <MenuItem
                              key={option}
                              onClick={(event) =>
                                handleMenuItemClick(event, option)
                              }
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  hpMode: state.system.hpMode,
});

export default connect(mapStateToProps, {
  bulkRelease,
  bulkSendHandpack,
  bulkSendStore,
  bulkSendPackingStation,
  bulkNotes,
  bulkLabelsAndComplete,
  bulkError,
  bulkSendToHoldOrder,
  bulkToggleBulkPack,
})(GroupedButtonActions);
