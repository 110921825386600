export const convertTime = (value, convertToNZ = false) => {
  var date = new Date(value);
  const day = date.getDate().toString().padStart(2, "0");
  const hour = date.getHours();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().substr(-2);
  let hour12 = (hour % 12).toString().padStart(2, "0");
  const minute = date.getMinutes().toString().padStart(2, "0");
  // const ampm = hour >= 12 ? "PM" : "AM";
  const ampm = hour >= 12 ? "PM" : "AM";
  hour12 = hour12 === "00" && ampm === "PM" ? "12" : hour12;

  return `${day}/${month}/${year} ${hour12}:${minute}${ampm}`;
};

export const convertTimeLocal = (value) => {
  var date = new Date(value+"Z");
  const day = date.getDate().toString().padStart(2, "0");
  const hour = date.getHours();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().substr(-2);
  let hour12 = (hour % 12).toString().padStart(2, "0");
  const minute = date.getMinutes().toString().padStart(2, "0");
  // const ampm = hour >= 12 ? "PM" : "AM";
  const ampm = hour >= 12 ? "PM" : "AM";
  hour12 = hour12 === "00" && ampm === "PM" ? "12" : hour12;

  return `${day}/${month}/${year} ${hour12}:${minute}${ampm}`;
};

export const getDayFromDateTimeString = (dateString) => {
  const date = new Date(dateString);
  switch (date.getDay()) {
    case 0:
      return "Sunday";
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    default:
      return "Sunday";
  }
};

export const getNowDateString = () => {
  const date = new Date();
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

export const addDays = (d, days) => {
  if (days == null || days === "") {
    days = 1;
  }
  if (d != null && d !== "") {
    // var dateSplit = d.substring(0, 10).split("-");
    var date = new Date(d);
    date.setDate(date.getDate() + parseInt(days));
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  } else {
    return getNowDateString();
  }
};

export const formatTime = (timer) => {
  const getSeconds = `0${timer % 60}`.slice(-2);
  const minutes = `${Math.floor(timer / 60)}`;
  const getMinutes = `0${minutes % 60}`.slice(-2);

  return `${getMinutes}:${getSeconds}`;
};

export const formatTimeDelay = (timer) => {
  const getSeconds = `0${timer % 60}`.slice(-2);
  const minutes = `${Math.floor(timer / 60)}`;
  const getMinutes = `0${minutes % 60}`.slice(-2);
  const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

  return `${getHours}:${getMinutes}:${getSeconds}`;
};

export const formatLineDelay = (timer) => {
  const minutes = `${Math.floor(timer / 60)}`;
  const getMinutes = `0${minutes % 60}`.slice(-2);
  const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

  return `${getHours}:${getMinutes}`;
};

export const convertMySQLToLocalTime = (value) => {
  var t = value.split(/[-T:]/);

  // Apply each element to the Date function
  var d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));

  return d;
};

export const convertMySQLToLocalTimeString = (value) => {
  var t = value.split(/[-T:]/);

  // Apply each element to the Date function
  var date = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));

  const day = date.getDate().toString().padStart(2, "0");
  const hour = date.getHours();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().substr(-2);
  let hour12 = (hour % 12).toString().padStart(2, "0");
  const minute = date.getMinutes().toString().padStart(2, "0");
  // const ampm = hour >= 12 ? "PM" : "AM";
  const ampm = hour >= 12 ? "PM" : "AM";
  hour12 = hour12 === "00" && ampm === "PM" ? "12" : hour12;

  return `${day}/${month}/${year} ${hour12}:${minute}${ampm}`;
};

export const getDateAsString = (value) => {
  var date = new Date(value);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();

  if(isNaN(day) || isNaN(month) || isNaN(year) || year === "1970"){
    return ""
  } else {
    return `${day}/${month}/${year}`;
  }
};

export const getBusinessDatesCount = (startDate, endDate) => {
    let count = -1;
    const curDate = new Date(startDate.getTime());
    while (curDate <= endDate) {
        const dayOfWeek = curDate.getDay();
        if(dayOfWeek !== 0 && dayOfWeek !== 6) count++;
        curDate.setDate(curDate.getDate() + 1);
    }
    return count;
};

