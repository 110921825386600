import React, { forwardRef, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Dialogy = (props) => {
  const {
    details: { title, text, type },
    open,
    handleClose,
    proceed,
  } = props;

  // console.log(props);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        disableBackdropClick
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {type === "checks" ? (
            <>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleClose()}
              >
                Go Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => proceed()}
              >
                Proceed
              </Button>
            </>
          ) : type === "outOfStockPopup" ? (
            <>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleClose()}
              >
                Notify me later
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => proceed()}
              >
                Understood
              </Button>
            </>
          ) : (
            <Button variant="contained" color="primary" onClick={handleClose}>
              Ok
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Dialogy;
