import React, { useState, useEffect } from "react";
import "../Delays.scss";
import {
  Typography,
  Grid,
  Button,
  CircularProgress,
  Card,
  DialogTitle,
  DialogContent,
  DialogActions,
  ButtonGroup,
  Popper,
  Grow,
  MenuItem,
  ClickAwayListener,
  Paper,
  MenuList,
  Dialog,
  FormControlLabel,
  Checkbox,
  Menu,
  TextField,

} from "@material-ui/core";
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import axios from "axios";

const ActionButtons = (props) => {
  const {
    storeOrder,
    trackingNumber,
    setOrderEvents,
    getDelayedOrders,
    closePopup,
    fromDisputed,
    fromQuickFix
  } = props;

  const [openRaiseTicket, setOpenRaiseTicket] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(null);
  const [disputeAll, setDisputeAll] = useState(false);

  const [checkDeliveryButtonLoading, setCheckDeliveryButtonLoading] = useState(false);

  const [openClaimConfirmation, setOpenClaimConfirmation] = useState(false);
  const [openMuteConfirmation, setOpenMuteConfirmation] = useState(false);
  const [claimNotes, setClaimNotes] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openCompressedActions, setOpenCompressedActions] = useState(false);

  const [compressActionButtons, setCompressActionButtons] = useState(false);
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const handleResize = () => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth
    })
  }
  window.addEventListener('resize', handleResize)

  const raiseTicket = () => {
    setLoading(true);
    axios
      .get(`ControlCenter/SendTicketRequest/${storeOrder.id}/${trackingNumber}`)
      .then((res) => {
        setOrderEvents(res.data);
      })
      .catch((e) => {
        // console.log(e)
      })
      .finally((e) => {
        setOpenRaiseTicket(false);
        setLoading(false);
      });
    axios
      .get(
        `ControlCenter/RaiseTicketManually/${storeOrder.id}/${trackingNumber}/${type}`
      )
      .then((res) => {
        // console.log(res);
        setOrderEvents(res.data);
      })
      .catch((e) => {
        // console.log(e)
      });
  };

  const disputeWholeOrder = () => {
    setLoading(true);
    axios
      .get(`ControlCenter/SendTicketRequest/${storeOrder.id}/${trackingNumber}`)
      .then((res) => {
        setOrderEvents(res.data);
      })
      .catch((e) => {
        // console.log(e)
      })
      .finally((e) => {
        setOpenRaiseTicket(false);
        setLoading(false);
      });
    axios
      .post(`ControlCenter/DisputeWholeOrderSelenium/${storeOrder.id}/${trackingNumber}`)
      .then((res) => {
        // console.log(res)
      })
      .catch((e) => {
        // console.log(e)
      });
  };

  //----------Components-----------//
  const raiseTicketConfirmation = () => {
    return (
      <Dialog
        open={openRaiseTicket}
        keepMounted
        onClose={() => {
          setOpenRaiseTicket(false);
          setDisputeAll(false);
          setType(null);
        }}
      >
        <DialogTitle>
          {type === "update"
            ? "Raise Ticket"
            : type === "disputed"
              ? "Dispute Deilvery"
              : disputeAll
                ? "Dispute whole order"
                : ""}
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to raise a ticket?
          </Typography>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <CircularProgress />
          ) : (
            <React.Fragment>
              <Button
                variant="contained"
                color="default"
                onClick={() => {
                  setOpenRaiseTicket(false);
                  setDisputeAll(false);
                  setType(null);
                }}
              >
                Go Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (disputeAll) {
                    disputeWholeOrder();
                  } else {
                    raiseTicket(type);
                  }
                }}
              >
                Raise Ticket
              </Button>
            </React.Fragment>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  const createClaimDocumentsConfirmation = () => {
    return (
      <Dialog
        open={openClaimConfirmation}
        keepMounted
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            setOpenClaimConfirmation(false);
          }
        }}
      >
        <DialogTitle>Create Claim Documents?</DialogTitle>
        <DialogContent>
          <Typography>
            This will create the necessary documents for a claims invoice
          </Typography>
          <TextField
            style={{ marginTop: "10px" }}
            label="Notes"
            placeholder="Place any notes for the statement of record in here"
            InputLabelProps={{ shrink: true }}
            multiline={true}
            rowsMax={7}
            rows={7}
            fullWidth
            variant="outlined"
            value={claimNotes}
            onChange={e => setClaimNotes(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          {loading ? (
            <CircularProgress style={{ margin: 5 }} />
          ) : (
            <React.Fragment>
              <Button
                variant="contained"
                color="default"
                onClick={() => {
                  setOpenClaimConfirmation(false);
                }}
              >
                Go Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setLoading(true);
                  //`ControlCenter/QueueClaimForm/${storeOrder.id}/${trackingNumber}`
                  axios
                    .post(`ControlCenter/QueueClaimForm`, {
                      orderId: storeOrder.id,
                      trackingNumber: trackingNumber,
                      notes: claimNotes
                    })
                    .then((res) => {
                      debugger;
                      if (res.data.orderEvents.length > 0 && res.data.files.length === 0) {
                        setOrderEvents(res.data.orderEvents);
                        setLoading(false);
                        setOpenClaimConfirmation(false);
                      } else if (res.data.files.length <= 5) {
                        var count = 0;
                        for (var i = 0; i < res.data.files.length; i++) {
                          axios.get(`ControlCenter/DownloadDriveFile/${res.data.files[i].value}/${res.data.files[i].key}`, {
                            params: {
                              cacheBustTimestamp: Date.now(), // prevents IE cache problems on re-download
                            },
                            responseType: "blob",
                            headers: {
                              Accept: "application/octet-stream",
                            },
                          })
                            .then((r) => {
                              var fileName = r.headers["content-disposition"];
                              if (fileName.includes("attachment; filename=\"")) {
                                fileName = fileName.replace("attachment; filename=\"", "");
                                fileName = fileName.split("\";")[0];
                              } else {
                                fileName = fileName.replace("attachment; filename=", "");
                                fileName = fileName.split(";")[0];
                              }

                              const blob = new Blob([r.data], { type: "application/pdf" });

                              const url = window.URL.createObjectURL(blob);
                              const a = document.createElement("a");
                              a.style.display = "none";
                              a.href = url;
                              a.setAttribute("download", `${fileName}.pdf`);
                              document.body.appendChild(a);
                              a.click();
                              window.URL.revokeObjectURL(url);
                            }).finally(() => {
                              count++;
                              if (res.data.files.length === count) {
                                getDelayedOrders();
                                setLoading(false);
                                setOpenClaimConfirmation(false);
                                setOrderEvents(res.data.orderEvents);
                              }
                            })
                        }
                      }

                    })
                }}
              >
                Create documents
              </Button>
            </React.Fragment>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  const MuteCheckbox = () => {
    const [muted, setMuted] = useState(false);

    useEffect(() => {
      axios
        .get(`ControlCenter/GetMuteStatus/${storeOrder.orderNumber}`)
        .then((res) => {
          setMuted(res.data);
        })
        .catch((e) => {
          //   console.log(e);
        });
    }, []);

    return (
      <FormControlLabel
        className={"mute-form-control"}
        control={
          <Checkbox
            style={{
              color: "white",
            }}
            checked={muted}
            onChange={(e) => {
              setMuted(e.target.checked);
              axios
                .patch(
                  `ControlCenter/MuteEmailsForOrder?orderNumber=${storeOrder.orderNumber}&muted=${e.target.checked}`
                )
                .then((e) => {
                  //   console.log(e);
                })
                .catch((e) => {
                  //   console.log(e);
                });
            }}
            name="muted-checkbox"
            color="primary"
          />
        }
        label="Mute Email Notifications"
      />
    );
  };

  const ManualPackageStatusButton = () => {
    const splitButtonOptions = [
      "Set Status",
      "Set as Delivered",
      "Set as Failed",
      "Set as Not Sent",
      "Set Claim Raised",
      "Set Claim Sent",
      "Set Claim Paid",
      "Set Claim Dismissed"
    ];
    const anchorRef = React.useRef(null);

    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleMenuItemClick = (event, index) => {
      if (index !== 0) {
        axios
          .patch(`ControlCenter/ManuallyUpdateTrackingStatus`, [{
            orderId: storeOrder.id,
            trackingNumber: trackingNumber,
            status:
                index === 1
                    ? "Delivered"
                    : index === 2
                        ? "Failed"
                        : index === 3
                            ? "NotSent"
                            : index === 4
                                ? "ClaimCreated"
                                : index === 5
                                    ? "ClaimSent"
                                    : index === 6
                                        ? "ClaimPaid"
                                        : index === 7
                                            ? "ClaimDismissed"
                                            : null,
            isDisputed: fromDisputed,
            isQuickFix: fromQuickFix
          }])
          .then((res) => {
            getDelayedOrders();
            setOrderEvents(res.data);
            closePopup();
          })
          .catch((e) => {
            // console.log(e);
          });
      }
      setOpen(false);
    };

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    return (
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <ButtonGroup
            variant="contained"
            color="primary"
            ref={anchorRef}
            aria-label="split button"
          >
            <Button size="small">{splitButtonOptions[selectedIndex]}</Button>
            <Button
              color="primary"
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: "99999999999999" }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-delayed-order">
                      {splitButtonOptions.map((option, index) => (
                        <MenuItem
                          key={option}
                          disabled={index === 0}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    );
  };

  const LinksButton = (props) => {
    const splitButtonOptions = ["Links", "Shopify", storeOrder.courierOrder[0].courier === 2 ? "Aramex" : "NZPost", "RobotUI"];
    const anchorRef = React.useRef(null);

    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleMenuItemClick = (event, index) => {
      if (index === 1) {
        window.open(
          `https://simply-coffee-capsules.myshopify.com/admin/orders/${storeOrder.id}`
        );
      } else if (index === 2) {
        window.open(
          storeOrder.courierOrder[0].courier === 2 ? `https://www.aramex.co.nz/tools/track/?l=${trackingNumber}` : `https://www.nzpost.co.nz/tools/tracking/item/${trackingNumber}`
        );
      } else if (index === 3) {
        window.open(
          `https://cc2u-robot-ui.ts.r.appspot.com/AllOrders/${storeOrder.orderNumber}`
        );
      }

      setOpen(false);
    };

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    return (
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <ButtonGroup
            variant="contained"
            color="primary"
            ref={anchorRef}
            aria-label="split button"
          >
            <Button size="small">{splitButtonOptions[selectedIndex]}</Button>
            <Button
              color="primary"
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: "99999999999999" }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-delayed-order">
                      {splitButtonOptions.map((option, index) => (
                        <MenuItem
                          key={option}
                          disabled={index === 0}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    );
  };

  const DisputeDeliveryButton = (props) => {
    const disputeOptions = ["Dispute Delivery", "This Package", "Whole Order"];
    const anchorRef = React.useRef(null);

    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleMenuItemClick = (event, index) => {
      if (index === 1) {
        setType("disputed");
        setOpenRaiseTicket(true);
      } else if (index === 2) {
        // disputeWholeOrder();
        setDisputeAll(true);
        setOpenRaiseTicket(true);
      }

      setOpen(false);
    };

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    return (
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <ButtonGroup
            variant="contained"
            color="primary"
            ref={anchorRef}
            aria-label="split button"
          >
            <Button size="small">{disputeOptions[selectedIndex]}</Button>
            <Button
              color="primary"
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: "99999999999999" }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-delayed-order">
                      {disputeOptions.map((option, index) => (
                        <MenuItem
                          key={option}
                          disabled={index === 0}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    );
  };

  const CheckDeliveryButton = (props) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        disabled={checkDeliveryButtonLoading}
        onClick={() => {
          setCheckDeliveryButtonLoading(true);
          axios
            .post(
              `ControlCenter/SendCheckDeilvery/${storeOrder.id}/${trackingNumber}`
            )
            .then((res) => {
              //   console.log(res);
              setOrderEvents(res.data);
              setCheckDeliveryButtonLoading(false);
            })
            .catch((e) => {
              //   console.log(e);
              setCheckDeliveryButtonLoading(false);
            });
        }}
      >
        Check Delivery
        {checkDeliveryButtonLoading && <CircularProgress />}
      </Button>
    );
  };

  //---------End of Components---------//

  useEffect(() => {
    if (compressActionButtons && dimensions.width > 896) {
      setCompressActionButtons(false)
      console.log()

    } else if (!compressActionButtons && dimensions.width <= 896) {
      setCompressActionButtons(true)
      console.log(document.getElementsByTagName('canvas'))
    }
  }, [dimensions])

  const downloadClaimFiles = () => {
    var forms = storeOrder.aramexClaimForm.length > 0 ? (storeOrder.aramexClaimForm) : (storeOrder.nzPostClaimForm.length > 0 ? storeOrder.nzPostClaimForm : null)
    var ids = [];
    var fileNames = [];

    if (forms !== null) {
      if (forms[0].driveId) {
        ids.push(forms[0].driveId);
        fileNames.push(`${storeOrder?.orderNumber} Compensation Form`);
      }
      if (forms[0].statementOfRecordDriveId) {
        ids.push(forms[0].statementOfRecordDriveId);
        fileNames.push(`${storeOrder?.orderNumber} Statement Of Record`);
      }
      if (forms[0].invoicePdfDriveId) {
        ids.push(forms[0].invoicePdfDriveId);
        fileNames.push(`${storeOrder?.orderNumber} Invoice`);
      }
      if (forms[0].proofOfPurchaseDriveId) {
        fileNames.push(`${storeOrder?.orderNumber} Proof of Purchase`);
        ids.push(forms[0].proofOfPurchaseDriveId);
      }
      ids.push("12Mw-u52KyYDwDXGluZwvKK3C7BR8pnCo");
      fileNames.push(`Proof of Stock Purchase`);
    }

    for (var i = 0; i < ids.length; i++) {
      axios.get(`ControlCenter/DownloadDriveFile/${ids[i]}/${fileNames[i]}`, {
        params: {
          cacheBustTimestamp: Date.now(), // prevents IE cache problems on re-download
        },
        responseType: "blob",
        headers: {
          Accept: "application/octet-stream",
        },
      })
        .then((r) => {
          debugger;
          var fileName = r.headers["content-disposition"];
          if (fileName.includes("attachment; filename=\"")) {
            fileName = fileName.replace("attachment; filename=\"", "");
            fileName = fileName.split("\";")[0];
          } else {
            fileName = fileName.replace("attachment; filename=", "");
            fileName = fileName.split(";")[0];
          }
          const blob = new Blob([r.data], { type: "application/pdf" });

          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.setAttribute("download", `${fileName}.pdf`);
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }).catch(e => {
          console.log(e);
        })
    }
  }

  return (
    <Card className="action-button-container" elevation={5}>
      {openRaiseTicket && raiseTicketConfirmation()}
      {openClaimConfirmation && createClaimDocumentsConfirmation()}
      {compressActionButtons ? (
        <div>
          <Button
            id="openCompressedActions-button"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            color="primary"
            variant="contained"
          >
            Actions
          </Button>
          <Menu
            id="action-button-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem>
              <div style={{
                display: "flex",
                flexDirection: "column",
              }}>
                <div style={{ padding: 5 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setType("update");
                      setOpenRaiseTicket(true);
                    }}
                  >
                    Raise Ticket
                  </Button>
                </div>
                <div style={{ padding: 5 }}>
                  <CheckDeliveryButton />
                </div>
                <div style={{ padding: 5 }}>
                  {storeOrder.aramexClaimForm.length === 0 && storeOrder.nzPostClaimForm.length === 0 ? (
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setOpenClaimConfirmation(true);
                      }}
                    >
                      Create claim documents
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      style={{
                        marginLeft: "5px",
                      }}
                      onClick={() => downloadClaimFiles()}
                    >
                      Download claim documents
                    </Button>
                  )}
                </div>
                <div style={{ padding: 5 }}><DisputeDeliveryButton /></div>
                <div style={{ padding: 5 }}><LinksButton /></div>
                <div style={{ padding: 5 }}><ManualPackageStatusButton /></div>
                <div style={{ padding: 5 }}><MuteCheckbox /></div>
              </div>
            </MenuItem>
          </Menu>
        </div>
      ) : (
        <Grid container>
          <Grid item xs={1} md={1} lg={1}>
            <div className="center">
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={() => {
                  setType("update");
                  setOpenRaiseTicket(true);
                }}
                style={{
                  marginLeft: "10px",
                }}
              >
                Raise Ticket
              </Button>
            </div>
          </Grid>
          <Grid item xs={2} md={2} lg={2}>
            <div className="center">
              <CheckDeliveryButton />
            </div>
          </Grid>
          <Grid item xs={2} md={2} lg={2}>
            <div className="center">
              {storeOrder.aramexClaimForm.length === 0 && storeOrder.nzPostClaimForm.length === 0 ? (
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setOpenClaimConfirmation(true);
                  }}
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  Create claim documents
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  style={{
                    marginLeft: "5px",
                  }}
                  onClick={() => downloadClaimFiles()}
                >
                  Download claim documents
                </Button>
              )}
            </div>
          </Grid>
          <Grid item xs={2} md={2} lg={2}>
            <div className="center">
              <DisputeDeliveryButton />
            </div>
          </Grid>
          <Grid item xs={1} md={1} lg={1}>
            <div className="center">
              <LinksButton />
            </div>
          </Grid>
          <Grid item xs={2} md={2} lg={2}>
            <div className="center">
              <ManualPackageStatusButton />
            </div>
          </Grid>
          <Grid item xs={2} md={2} lg={2}>
            <div className="center">
              <MuteCheckbox />
            </div>
          </Grid>
        </Grid>
      )
      }
    </Card>
  );
};

export default ActionButtons;
