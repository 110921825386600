import React, { useEffect, useState } from "react"
import { withStyles } from "@material-ui/core/styles";
import {
    Grid,
    TextField,
    Tabs,
    Tab,
    Box,
    Typography,
    Checkbox,
    FormControlLabel
} from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import Axios from "axios"
import { ConvertNZPostAddress } from "../../../Utils/nzPostUtils";

const WhiteTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "white",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "white",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "white",
            },
            "&:hover fieldset": {
                borderColor: "white",
            },
            "&.Mui-focused fieldset": {
                borderColor: "white",
            },
        },
    },
})(TextField);

const EditCompanySection = (props) => {
    let {
        selectedCompany,
        updateSelectedCompany
    } = props;

    useEffect(() => {
        console.log("selectedCompany")
        console.log(selectedCompany)
    }, [selectedCompany])

    return (
        <React.Fragment>
            <Grid item xs={12} md={12} lg={12}>
                <WhiteTextField
                    key={"edit-company-name-textbox"}
                    label="Name"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => {
                        updateSelectedCompany("name", e.target.value)
                    }}
                    value={selectedCompany.name}
                    size="small"
                />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
                <WhiteTextField
                    key={"edit-account-email-textbox"}
                    label="Accounts Email"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => {
                        updateSelectedCompany("accountsEmail", e.target.value)
                    }}
                    value={selectedCompany.accountsEmail}
                    size="small"
                />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
                <WhiteTextField
                    key={"edit-purchase-order-textbox"}
                    label="Default Purchase Order"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => {
                        updateSelectedCompany("purchaseOrder", e.target.value)
                    }}
                    value={selectedCompany.purchaseOrder === null ? "" : selectedCompany.purchaseOrder}
                    size="small"
                />
            </Grid>
        </React.Fragment>
    )
}

export default EditCompanySection;