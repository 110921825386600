import React from "react";
import { connect } from "react-redux";
import OrdersTable from "../Shared/OrdersTable";

const HandpackOrders = (props) => {
  const { orders } = props;

  if (orders) {
    return <OrdersTable parent="Handpack Orders" orders={orders.filter((o) => o.status === 6)} />;
  } else {
    return <div>Loading...</div>;
  }
};

const mapStateToProps = (state) => ({
  orders: state.order.orders,
});

export default connect(mapStateToProps, {})(HandpackOrders);