import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import EditStoreOrderPopup from "../StoreOrders/EditStoreOrderPopup";
import {
  convertMySQLToLocalTimeString,
  getBusinessDatesCount,
} from "../Utils/dateTime";
import {
  convertStatusId,
  formatColumnValueForExport,
} from "../Utils/domainUtils";
import ActionButton from "./ActionButton";
import {
  bulkRelease,
  bulkSendStore,
  fulfillOrderInShopfy,
  getOrdersForInvestigation,
  removeInvestigationIssueFromOrder,
} from "../../reduxActions/order";

const InvestigationTable = ({
  orders,
  bulkRelease,
  bulkSendStore,
  getOrdersForInvestigation,
  removeInvestigationIssueFromOrder,
  fulfillOrderInShopfy,
}) => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showEditOrderPopup, setShowEditOrderPopup] = useState(false);

  const displayInvestigationStatus = (status) => {
    if (status === "UNFULFILLED_IN_SHOPIFY") {
      return "Order not fulfilled";
    }

    return "Unknown";
  };

  const displayPackageStatus = (order) => {
    const courierOrder = order.courierOrder.find((o) => o.current === 1);
    if (courierOrder) {
      return courierOrder.packageStatus;
    }

    return "";
  };

  const viewOrder = (order) => {
    setSelectedOrder(order);
    setShowEditOrderPopup(true);
  };

  const handleSendToStore = async (order) => {
    await bulkSendStore([order.id]);
    getOrdersForInvestigation();
  };

  const handleRelease = async (order) => {
    await bulkRelease([order.id]);
    getOrdersForInvestigation();
  };

  const markAsResolved = async (order) => {
    await removeInvestigationIssueFromOrder(order.id)
    getOrdersForInvestigation();
  };

  const fulfillOrder = async (order, notifyCustomer) => {
    await fulfillOrderInShopfy(order.id, notifyCustomer)
    getOrdersForInvestigation();
  };

  const goToShopify = (order) => {
    window.open(
      "https://simply-coffee-capsules.myshopify.com/admin/orders/" + order.id
    );
  };

  const viewCourierTracking = async (order) => {
    const courierOrder = order.courierOrder.find((o) => o.current === 1);
    if (courierOrder) {
      const tracking = formatColumnValueForExport(
        "trackingNumbers",
        courierOrder.trackingNumbers
      );
      var url =
        courierOrder.courier === 1
          ? "https://www.nzpost.co.nz/tools/tracking/item/" + tracking
          : "https://www.aramex.co.nz/tools/track/?l=" + tracking;
      window.open(url);
    }
  };

  const displayBusinessDays = (date) => {
    if (date) {
      const count = getBusinessDatesCount(new Date(date), new Date());
      return count > 1 ? "("+count+" days)" : "("+count+" day)";
    }

    return "";
  };
  return (
    <TableContainer style={{padding: "55px 0"}}>
      {showEditOrderPopup && (
        <EditStoreOrderPopup
          modalState={showEditOrderPopup}
          togglePopUp={(value) => setShowEditOrderPopup(value)}
          activeTab={0}
          handleCloseModal={() => {
            setSelectedOrder(null);
            setShowEditOrderPopup(false);
          }}
          order={selectedOrder}
        />
      )}
      <Table aria-labelledby="tableTitle" aria-label="sticky table">
        <TableHead className="color-black">
          <TableRow>
            <TableCell className="text-center">Order Number</TableCell>
            <TableCell className="text-center">Time Received</TableCell>
            <TableCell className="text-center">Customer</TableCell>
            <TableCell className="text-center">Status</TableCell>
            <TableCell className="text-center">Completed At</TableCell>
            <TableCell className="text-center">Package Status</TableCell>
            <TableCell className="text-center">Issue</TableCell>
            <TableCell className="text-center"></TableCell>
          </TableRow>
        </TableHead>

        <TableBody className="color-black">
          {orders?.map((o) => (
            <TableRow key={o.id}>
              <TableCell className="text-center">{`SC${o.orderNumber}`}</TableCell>
              <TableCell className="text-center">
                {convertMySQLToLocalTimeString(o.timeReceived).toString()}{" "}
                {displayBusinessDays(o.timeReceived)}
              </TableCell>
              <TableCell className="text-center">
                {o.customerFullName}
              </TableCell>
              <TableCell className="text-center">
                {convertStatusId(o.status)}
              </TableCell>
              <TableCell className="text-center">
                {convertMySQLToLocalTimeString(o.packingEnd).toString()}{" "}
                {displayBusinessDays(o.packingEnd)}
              </TableCell>
              <TableCell className="text-center">
                {displayPackageStatus(o)}
              </TableCell>
              <TableCell className="text-center">
                {displayInvestigationStatus(o.investigationStatus)}
              </TableCell>
              <TableCell className="text-center">
                <ActionButton
                  order={o}
                  viewOrder={viewOrder}
                  handleSendToStore={handleSendToStore}
                  handleRelease={handleRelease}
                  viewCourierTracking={viewCourierTracking}
                  goToShopify={goToShopify}
                  markAsResolved={markAsResolved}
                  fulfillOrder={fulfillOrder}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  bulkRelease,
  bulkSendStore,
  getOrdersForInvestigation,
  removeInvestigationIssueFromOrder,
  fulfillOrderInShopfy,
})(InvestigationTable);
