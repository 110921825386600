import React, { forwardRef, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Checkbox,
    FormControl,
    FormControlLabel
} from "@material-ui/core";
import Axios from "axios"

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} mountOnEnter unmountOnExit />;
});

const PrintLabelPopup = (props) => {
    const {
        open,
        handleClose,
        order,
        setSnackBarProps
    } = props;

    const [printNote, setPrintNote] = useState(false);
    const [printAllLabels, setPrintAllLabels] = useState(true);
    const [completeStatus, setCompleteStatus] = useState(true);
    const [ids, setIds] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);

    const print = () => {
        var printObj = {
            printObjects: [],
            completeStatus: completeStatus,
            handWrittenNoteFk: order.handwrittenNoteFk,
            printNote: printNote,
            orderId: order.id
        }

        if (printAllLabels) {
            for (var i = 0; i < ids.length; i++) {
                printObj.printObjects.push({
                    type: "Label",
                    driveId: ids[i],
                    orderId: order.id
                })
            }
        }

        Axios.post(`PrintQueues/Print`, printObj)
            .then(res => {
                setSnackBarProps("success", "Sent to the printer", true);
            })
            .catch(e => {
                props.setSnackBarProps("warning", "Failed to print", true);
            })
            .finally(() => {
                handleClose()
            })
    }

    useEffect(() => {
        setIds(order.courierOrder.driveId.split(","));
    }, [])

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => handleClose()}
            >
                <DialogTitle>{`Print Options for SC${order.orderNumber}`}</DialogTitle>
                <DialogContent>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column"
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={printAllLabels}
                                    name="all-labels-checkbox"
                                    color="primary"
                                    onChange={e => setPrintAllLabels(e.target.checked)}
                                />
                            }
                            label="Label"
                        />
                        {/* <FormControlLabel
                            control={
                                <Checkbox
                                    checked={printNote}
                                    name="note-checkbox"
                                    color="primary"
                                    onChange={e => setPrintNote(e.target.checked)}
                                />
                            }
                            label="Note"
                        /> */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={completeStatus}
                                    name="change-status"
                                    color="primary"
                                    onChange={e => setCompleteStatus(e.target.checked)}
                                />
                            }
                            label="Mark status as complete"
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="default" onClick={() => handleClose()}>
                        Go Back
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => print()}>
                        Print
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default PrintLabelPopup;