import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  Modal,
  AppBar,
  Toolbar,
  Tab,
  Tabs,
  makeStyles,
} from "@material-ui/core";
import TabPanel from "../Shared/TabPanel";
import "./packingScreen.scss";
import { sortLineInformationInHpMode } from "../Shared/OrdersTable";
import PackingScreenModalChunkInfo from "./PackingScreenModalChunkInfo";
import PackingScreenModalChunkUpcomingOrders from "./PackingScreenModalUpcomingOrders";
import PackingScreenModalCurrentOrder from "./PackingScreenModalCurrentOrder";
import { bulkCompleteOrders } from "../../reduxActions/order";

const getOrdersInChunk = (orders, chunkNumber) => {
  return orders
    .filter((order) => order.hpChunkNumber === chunkNumber)
    .sort(sortLineInformationInHpMode);
};

/**
 * Renders a modal component for the packing screen.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Determines if the modal is open.
 * @param {function} props.setOpen - Callback function to set the open state of the modal.
 * @param {Array} props.orders - The list of orders.
 * @param {number} props.chunkNumber - The chunk number.
 * @returns {JSX.Element} The packing screen modal component.
 */
const PackingScreenModal = (props) => {
  const { open, setOpen, orders, chunkNumber, bulkCompleteOrders } = props;
  const [orderIndex, setOrderIndex] = React.useState(0);
  const [ordersInChunk, setOrdersInChunk] = React.useState(
    getOrdersInChunk(orders, chunkNumber)
  );

  useEffect(() => {
    setOrdersInChunk(getOrdersInChunk(orders, chunkNumber));
  }, [orders, chunkNumber]);

  const useStyles = makeStyles((theme) => ({
    paper: {
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 3),
    },
  }));
  const classes = useStyles();

  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 38) {
        // Up arrow key
        if (orderIndex > 0) {
          setOrderIndex(orderIndex - 1);
        }
      } else if (event.keyCode === 40) {
        // Down arrow key
        if (orderIndex < ordersInChunk.length - 1) {
          setOrderIndex(orderIndex + 1);
        }
      } else if (event.keyCode === 13) {
        // Enter key
        // Mark order as complete
        bulkCompleteOrders([ordersInChunk[orderIndex].id]);
        onCompleteOrder();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [orderIndex, ordersInChunk]);

  if (ordersInChunk.length === 0) {
    setOpen(false);
    return null;
  }

  const chunkInfo = ordersInChunk[0].hpChunkNumberObject;

  const onCompleteOrder = () => {
    if (orderIndex < ordersInChunk.length - 1) {
      setOrderIndex(orderIndex + 1);
    } else {
      setOpen(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      id="packing-screen-modal"
    >
      <div>
        <AppBar position="static" style={{ background: "#27293d" }}>
          <Toolbar variant="dense" style={{ paddingRight: 0 }}>
            <span style={{ display: "flex" }}>
              <Tabs value={0}>
                <Tab wrapped label="Packing Station" />
              </Tabs>
            </span>
          </Toolbar>
        </AppBar>
        <div className={`${classes.paper} edit-order-paper`}>
          <TabPanel value={0} index={0}>
            <div className="packing-screen-container">
              <PackingScreenModalChunkInfo
                className={classes.paper}
                chunkInfo={chunkInfo}
                chunkNumber={chunkNumber}
                ordersInChunk={ordersInChunk}
              />
              <PackingScreenModalCurrentOrder
                className={classes.paper}
                order={ordersInChunk[orderIndex]}
                onCompleteOrder={onCompleteOrder}
              />
              <PackingScreenModalChunkUpcomingOrders
                className={classes.paper}
                ordersInChunk={ordersInChunk}
                orderIndex={orderIndex}
                setOrderIndex={setOrderIndex}
              />
            </div>
          </TabPanel>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  orders: state.order.orders,
});

export default connect(mapStateToProps, { bulkCompleteOrders })(
  PackingScreenModal
);
