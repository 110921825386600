import { createAction } from "@reduxjs/toolkit";
import axios from "axios";
import { getNowDateString } from "../components/Utils/dateTime";
import { addDays } from "../components/Utils/dateTime";
import _ from "lodash";

export const setError = createAction("SET_ERROR");

export const sendMessagesToStore = createAction("GET_MESSAGES");
export const setMessagesLoading = createAction("MESSAGES_LOADING");
export const setMessagesLoaded = createAction("MESSAGES_LOADED");
export const getMessages = () => async (dispatch) => {
  try {
    const values = await axios.get("Messages/GetMessage");
    let messages = values.data.map((m) => {
      m.messageObj = JSON.parse(m.messageObj);
      return m;
    });
    dispatch(sendMessagesToStore(messages));
  } catch (error) {
    dispatch(setError(error));
    dispatch(setMessagesLoaded());
  }
};

export const onEditField = createAction("EDIT_FIELD");

export const onUpdateField = (message, field) => async (dispatch) => {
  try {
    await axios.patch(`Messages/PatchMessage/${field}`, message);
  } catch (error) {
    dispatch(setError(error));
  }
};

export const createBlankMessage = (m, messagesLength, id) => async (dispatch) => {
  try {
    await axios.post(`Messages/PostMessage`, {
      id: id,
      greeting: "",
      message1: "", 
      signedBy: "",
      messageObj: JSON.stringify({
        body: {
          lines: [],
          fontSize: 28,
        },
        message: { rx: 7.1, ry: 2.95 },
        greeting: { lx: 2, ly: 1, text: "", fontSize: 30 },
        signature: { lx: 2.5, ly: 2.25, text: "", fontSize: 30 },
      }),
      start: addDays(m.start, m.duration),
      end: getNowDateString(),
      orderCount: messagesLength,
      isApologyNote: 0
    });
  } catch (error) {
    dispatch(setError(error));
  }
};

export const removeMessage = (id, index) => async (dispatch) => {
  dispatch(removeMessageFromStore(index));
  try {
    await axios.delete(`Messages/DeleteMessage/${id}`);
  } catch (error) {
    dispatch(setError(error));
  }
};

export const removeMessageFromStore = createAction("REMOVE_MESSAGE_FROM_STORE");

export const swapAndUpdate = (d, s, messages) => async (dispatch) => {
  let originalArr = [...messages];
  const start = originalArr[d].start;
  let newArr = _.cloneDeep(messages);

  var z = newArr[d];
  newArr[d] = newArr[s];
  newArr[s] = z;
  newArr[d].start = start;

  var arrPositiveCount = 1;
  newArr.map((m, index) => {
    if (index === 1) {
      m.end = addDays(newArr[index].start, newArr[index].duration - 1);
    }
    if (index > 1) {
      m.start = addDays(newArr[index - 1].start, newArr[index - 1].duration);
      m.end = addDays(newArr[index].start, newArr[index].duration - 1);
    }
    if(m.orderCount > 0) {
      m.orderCount = arrPositiveCount;
      arrPositiveCount++;
    }
    return m;
  });
  dispatch(swapAndUpdateStore(newArr));
  try{
    var objToSendToDB = _.cloneDeep(newArr);
    objToSendToDB.map((m, index) => {
      m.messageObj = JSON.stringify(m.messageObj)
      return m;
    });
    await axios.patch("Messages/UpdateDbDateDuration", objToSendToDB);
  }
  catch(e) {
  }
};

export const swapAndUpdateStore = createAction("SWAP_AND_UPDATE");

export const updateDbDateDuration = (arr) => async (dispatch) => {
  try {
    let messages = arr.map((m) => {
      return { ...m, messageObj: JSON.stringify(m.messageObj) };
    });

    await axios.patch("Messages/UpdateDbDateDuration", messages);
  } catch (error) {
    dispatch(setError(error));
  }
};
