import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React from "react";
import PaymentIcon from "@material-ui/icons/Payment";
import "./managePaymentMethodModal.scss";
const ManagePaymentMethodModal = ({
  open,
  handleClose,
  paymentMethods,
  selectedPayment,
  setSelectedPayment,
}) => {
  return (
    <Card>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>
          <Typography variant="h6">Manage Payment Methods</Typography>
        </DialogTitle>
        {paymentMethods.map((pm, index) => {
          return (
            <DialogContent dividers key={pm.id}>
              <div className="manage-payment-container">
                <div>
                  <PaymentIcon />
                </div>
                <div className="manage-payment-details">
                  <Typography variant="body2">{`**** ${pm?.card?.last4}`}</Typography>
                  <Typography variant="body2">{`EXP ${
                    pm?.card?.exp_month < 10
                      ? "0" + pm?.card?.exp_month
                      : pm?.card?.exp_month
                  }/${pm?.card?.exp_year}`}</Typography>
                  <Typography variant="body2">
                    {pm?.billing_details?.name}
                  </Typography>
                </div>
              </div>

              {pm.id !== selectedPayment?.id && (
                <Button
                  color="primary"
                  variant="outlined"
                  style={{ float: "right" }}
                  onClick={(e) => {
                    setSelectedPayment(pm);
                    handleClose();
                  }}
                >
                  Select Payment
                </Button>
              )}
            </DialogContent>
          );
        })}
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default ManagePaymentMethodModal;
