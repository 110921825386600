import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  stock: [],
  stockRows: [],
  singleFlavourRow: [],
  flavourRequiredRowCount: {
    P5: 0,
    P7: 0,
    P9: 0,
    P10: 0,
    P12: 0,
    P14: 0,
    PDC: 0,
    PCA: 0,
    C5: 0,
    C7: 0,
    C9: 0,
    C10: 0,
    C12: 0,
    C14: 0,
    CDC: 0,
    ICE: 0,
    COC: 0,
    PHC: 0,
    COL: 0,
    Sleeve: 0,
    MOCHA: 0,
    TASTER_1: 0,
  },
  currentStock: {
    P5: 0,
    P7: 0,
    P9: 0,
    P10: 0,
    P12: 0,
    P14: 0,
    PDC: 0,
    PCA: 0,
    C5: 0,
    C7: 0,
    C9: 0,
    C10: 0,
    C12: 0,
    C14: 0,
    CDC: 0,
    ICE: 0,
    COC: 0,
    PHC: 0,
    COL: 0,
    Sleeve: 0,
    MOCHA: 0,
    TASTER_1: 0,
  },
  minimumRowsPerFlavour: 0,
  maxSingleFlavourRows: 0
};

const system = createReducer(initialState, {
  GET_STOCK: (state, action) => {
    return {
      ...state,
      stock: action.payload.items,
    };
  },
  GET_STOCK_ROWS: (state, action) => {
    return {
      ...state,
      stockRows: action.payload.stockRows,
      singleFlavourRow: action.payload.singleFlavourRow,
    };
  },
  GET_SHELF_INFO: (state, action) => {
    return {
      ...state,
      flavourRequiredRowCount: action.payload.rowsNeeded,
      currentStock: action.payload.currentStock,
      minimumRowsPerFlavour: action.payload.minimumRows,
      maxSingleFlavourRows: action.payload.maxSingleFlavourRows

    };
  },
});

export default system;
