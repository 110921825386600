import { createReducer } from "@reduxjs/toolkit";

const initialState = {
    allProducts: [],
    products: [],
};

const products = createReducer(initialState, {
  GET_ALL_PRODUCTS: (state, action) => {
    return {
      ...state,
      allProducts: action.payload,
    };
  },
  GET_PRODUCTS: (state, action) => {
    return {
      ...state,
      products: action.payload,
    };
  }
});

export default products;
