import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import NoteTabs from "./ManageNotesComponents/NoteTabs";
import ResultsTable from "./ManageNotesComponents/ResultsTable";
import { getAllNotes, updateNote } from "../../reduxActions/notes";

const ManageNotes = (props) => {
  const { notes, getAllNotes, updateNote } = props;
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    getAllNotes();
    document.getElementsByTagName("body")[0].style.overflow = "unset";
    return () => {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    };
  }, []);

  useEffect(() => {
    if (notes) {
      let status = activeTab === 0 ? 1 : 0;
      setFilteredNotes(notes.filter((n) => n.isActive === status));
    }
  }, [notes]);

  const handleTabChange = (tabValue) => {
    setActiveTab(tabValue);
    setFilteredNotes(notes.filter((n) => n.isActive !== tabValue));
  };

  const handleStatusChange = async (noteId) => {
    let note = notes.find((n) => n.id === noteId);
    if (note) {
      let noteClone = { ...note };
      noteClone.isActive = note.isActive === 0 ? 1 : 0;
      await updateNote(noteClone);
    }
  };

  return (
    <div>
      <NoteTabs handleTabChange={handleTabChange} activeTab={activeTab} />
      <ResultsTable
        notes={filteredNotes}
        handleStatusChange={handleStatusChange}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  notes: state.note.notes,
});
export default connect(mapStateToProps, { getAllNotes, updateNote })(
  ManageNotes
);
