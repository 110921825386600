import { Button, TableCell, TableRow, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import WebsiteItemsDialog from "./WebsiteItemsDialog";
import WebsiteActionButtons from "./WebsiteActionButtons";

const WebsiteErrorsRow = ({
  websiteError,
  index,
  removeWebsiteErrorFromList,
}) => {
  const [openItemsDialog, setOpenItemsDialog] = useState(false);

  const handleOpenOrderItemsDialog = (e) => {
    e.preventDefault();
    debugger;
    if (websiteError?.firebaseOrder?.lineItems?.length > 0) {
      setOpenItemsDialog(true);
    }
  };

  const handleCloseOrderItemsDialog = () => {
    setOpenItemsDialog(false);
  };
  return (
    <>
      {openItemsDialog && (
        <WebsiteItemsDialog
          open={openItemsDialog}
          handleClose={handleCloseOrderItemsDialog}
          items={websiteError?.firebaseOrder?.lineItems}
        />
      )}
      <TableRow
        className={
          index % 2 === 0
            ? "default-admin-table-even-row"
            : "default-admin-table-odd-row"
        }
      >
        <TableCell className="default-admin-table-cell">
          <Typography variant="caption">
            {moment(websiteError.createdAt).format("DD/MM/YYYY HH:mm")}
          </Typography>
        </TableCell>
        <TableCell className="default-admin-table-cell">
          <Typography variant="caption">
            {websiteError.firebaseOrder?.firstName +
              " " +
              websiteError.firebaseOrder?.lastName}
          </Typography>
        </TableCell>
        <TableCell className="default-admin-table-cell">
          <Typography variant="caption">
            {websiteError.firebaseOrder?.email}
          </Typography>
        </TableCell>
        <TableCell className="default-admin-table-cell">
          <Typography variant="caption">{websiteError.status}</Typography>
        </TableCell>
        <TableCell className="default-admin-table-cell">
          <Typography variant="caption">{websiteError.errorMessage}</Typography>
        </TableCell>
        <TableCell className="default-admin-table-cell">
          <div onClick={handleOpenOrderItemsDialog}>
            <Typography
              variant="caption"
              style={{
                color: "black",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              see items
            </Typography>
          </div>
        </TableCell>
        <TableCell className="default-admin-table-cell">
          <WebsiteActionButtons
            websiteError={websiteError}
            removeWebsiteErrorFromList={removeWebsiteErrorFromList}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default WebsiteErrorsRow;
