import { createAction } from "@reduxjs/toolkit";
import axios from "axios";

export const setError = createAction("SET_ERROR");

export const sendRobotPositionsToStore = createAction("UPDATE_ROBOT_POSITIONS");
export const getRobotPositions = () => async (dispatch) => {
  try {
    const values = await axios.get("RobotPositions/GetRobotPositions");
    dispatch(sendRobotPositionsToStore(values.data));
  } catch (error) {
    dispatch(setError(error));
  }
};
