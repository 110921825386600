import React, { useEffect, useMemo, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import { Button, InputAdornment, Typography } from "@material-ui/core";
import axios from "axios";
import { Skeleton } from "@material-ui/lab";
import _ from "lodash";
import AddIcon from "@material-ui/icons/Add";
import Snacky from "../../Shared/Snacky";
import { setSnackBarProps } from "../../../reduxActions/snackbar";
import { connect } from "react-redux";
import SkuManagerFilter from "./SkuManagerFilter";


const SkuManager = ({ products, snackbar, setSnackBarProps }) => {
  const bottomElementRef = useRef(null);
  const [skus, setSkus] = useState([]);
  const [updatedRecords, setUpdatedRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [keywordlookUp, setKeywordlookUp] = useState("");
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  //Infinite Scroll
  useEffect(() => {
    if (!isLoading && hasMoreData && !isLoadingMore) {
      const observer = new IntersectionObserver(
        async (entries) => {
          if (entries[0].isIntersecting) {
            setIsLoadingMore(true); // prevent making multiple calls
            await getAllSkus();
            setIsLoadingMore(false);
          }
        },
        { threshold: [1] }
      ); // By specifying [1] as the threshold value, you're instructing the Intersection Observer to trigger when the target element becomes fully visible.

      if (bottomElementRef.current) {
        observer.observe(bottomElementRef.current);
      }

      return () => {
        if (bottomElementRef.current) {
          observer.unobserve(bottomElementRef.current);
        }
      };
    }
  }, [isLoading, hasMoreData, isLoadingMore]);

  useEffect(() => {
    getAllSkus();
  }, [keywordlookUp]);


  const handleFilterChange = (keyword) => {
    setKeywordlookUp(keyword)
    setCurrentPage(0);
    setHasMoreData(true);
  }

  const getAllSkus = () => {
    if (!hasMoreData || isLoading || isLoadingMore) {
      return; // No more data to fetch
    }
    currentPage === 0 ? setIsLoading(true) : setIsLoadingMore(true);

    axios
      .get("products/GetAllSkus", {
        params: {
          keyword: keywordlookUp,
          page: currentPage,
        },
      })
      .then(({ data }) => {
        if (data.length === 0) {
          setHasMoreData(false);
        } else {
          if (currentPage === 0) {
            setSkus(data);
          } else {
            setSkus((prevSkus) => [...prevSkus, ...data]);
          }
          setCurrentPage(currentPage + 1);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
        setIsLoadingMore(false);
      });
  };

  const handleSkuChange = (productId, skuId, newValue) => {
    const updatedSku = { skuId, productId, quantity: newValue };
    const existingRecordIndex = updatedRecords.findIndex(
      (record) => record.productId === productId && record.skuId === skuId
    );

    if (existingRecordIndex !== -1) {
      const updatedRecordsCopy = [...updatedRecords];
      updatedRecordsCopy[existingRecordIndex] = updatedSku;
      setUpdatedRecords(updatedRecordsCopy);
    } else {
      setUpdatedRecords([...updatedRecords, updatedSku]);
    }

    setSkus((prevSkus) =>
      prevSkus.map((sku) =>
        sku.id === skuId
          ? {
              ...sku,
              skuProducts: sku.skuProducts.some(
                (s) => s.productId === productId
              )
                ? sku.skuProducts.map((product) =>
                    product.productId === productId
                      ? { ...product, quantity: newValue }
                      : product
                  )
                : [...sku.skuProducts, { productId, quantity: newValue }],
            }
          : sku
      )
    );
    setHasChanges(true);
  };

  const handleSaveProducts = () => {
    setIsSaving(true);
    axios
      .post(`Products/BatchSaveSkuProducts`, updatedRecords)
      .then(({ data }) => {
        setIsAddingNew(false);
        setHasChanges(false);
        setUpdatedRecords([]);
        setSnackBarProps(
          "success",
          `Fantastic! The skus have been saved.`,
          true
        );
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps("warning", `Oops! Unable to save skus.`, true);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  console.log(updatedRecords);
  return isLoading ? (
    <div className="products-loading-skeleton-container">
      <Skeleton height={50} />
      <Skeleton height={50} />
      <Skeleton height={50} />
    </div>
  ) : (
    <div style={{ width: "100%" }} className="product-results-container">
      <Snacky snackprops={snackbar} setSnackBarProps={setSnackBarProps} />

      <div className="products-actions-container">
        <SkuManagerFilter keyword={keywordlookUp} handleFilterChange={handleFilterChange}/>
        {!isAddingNew && !isSaving && (
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={(e) => {
              alert("under development");
              // handleCreateBlankSku();
            }}
          >
            Add new SKU
          </Button>
        )}
        {!isSaving && hasChanges && (
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={(e) => {
              handleSaveProducts();
            }}
          >
            Save
          </Button>
        )}
      </div>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className="products-table-header-cell products-table-sku-cell">
                <Typography variant="caption" className="products-table-header">
                  Variant SKU
                </Typography>
              </TableCell>
              {products.map((product) => (
                <TableCell
                  className="products-table-header-cell products-table-other-cell"
                  key={product.code}
                >
                  <Typography
                    variant="caption"
                    className="products-table-header"
                  >
                    {product.code}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {skus.map((sku, index) => (
              <TableRow
                key={index}
                className={
                  index % 2 === 0
                    ? "products-table-even-row"
                    : "products-table-odd-row"
                }
              >
                <TableCell
                  className={`products-table-cell ${
                    index % 2 === 0
                      ? "products-table-even-cell"
                      : "products-table-odd-cell"
                  } products-table-sticky-cell products-table-sku-cell`}
                >
                  <Typography variant={"body2"}>
                    {sku?.skuNumber?.toUpperCase()}
                  </Typography>
                </TableCell>
                {products.map((product, productIndex) => {
                  const productSku = sku.skuProducts.find(
                    (sp) => sp.productId === product.id
                  );
                  const quantity = productSku ? productSku.quantity : "";

                  return (
                    <TableCell
                      className={`products-table-cell products-table-other-cell`}
                      key={productIndex}
                    >
                      <TextField
                        value={quantity}
                        onChange={(e) =>
                          handleSkuChange(product.id, sku.id, e.target.value)
                        }
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}

            <div ref={bottomElementRef}></div>
          </TableBody>
        </Table>

        {hasMoreData && (
          <div className="products-loading-skeleton-container">
            <Skeleton height={20} />
            <Skeleton height={20} />
            <Skeleton height={20} />
          </div>
        )}
      </TableContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  snackbar: state.snackbar,
});

export default connect(mapStateToProps, {
  setSnackBarProps,
})(SkuManager);
