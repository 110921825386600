import React, { useState, useEffect } from "react";
import {
    GoogleMap,
    withScriptjs,
    withGoogleMap,
    TrafficLayer,
    GoogleMapProps
} from "react-google-maps";
import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel';
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import MemoizedPolygon from "./MemoizedPolygon";

const GoogleMapComponent = function Map() {
    // debugger;
    //const boundaries = require('./Boundaries.json');

    return (compose(
        withProps((props) => ({
            googleMapURL:
                "https://maps.googleapis.com/maps/api/js?key=" +
                "AIzaSyCYYP7HO1FKheu289_7tcnVoVYKIuf_fQk" +
                "&v=3.exp&libraries=geometry,drawing,places",
            loadingElement: props.loadingElement,
            containerElement: props.containerElement,
            mapElement: props.mapElement
        })),
        withScriptjs,
        withGoogleMap
    )((props) => (
        <GoogleMap
            mapTypeId={props.mapType}
            defaultCenter={{
                lat: props.defaultCentre ? Number(props.defaultCentre.lat) : -33.83001256324069,
                lng: props.defaultCentre ? Number(props.defaultCentre.lng) : 151.182493988605,
            }}
            //note different spellings of centre and center.
            {...props.centre && { center: props.centre }}
            defaultZoom={props.defaultZoom}
            zoomControl={false}
            tilt={props.tilt}
        >
            {props.isMarkerShown && (
                props.delayedOrders &&
                props.delayedOrders.map((delayedOrder, i) => {
                    const onClick = props.handleMarkerClick.bind(this, delayedOrder);
                    var lat = delayedOrder.order.shippingAddress.latitude ? delayedOrder.order.shippingAddress.latitude : 0;
                    var lng = delayedOrder.order.shippingAddress.longitude ? delayedOrder.order.shippingAddress.longitude : 0
                    if (lat !== 0 && lng !== 0 && !delayedOrder.status.includes("claim") && !delayedOrder.fromDisputed) {
                        return (
                            <MarkerWithLabel
                                key={`${delayedOrder.order.orderNumber}-marker`}
                                position={{
                                    lat: lat,
                                    lng: lng,
                                }}
                                //onClick={onClick}
                                labelAnchor={new window.google.maps.Point(0, 0)}
                                labelStyle={{
                                    backgroundColor: "white",
                                    fontSize: "10px",
                                    padding: "2px",
                                    borderRadius: "5px"
                                }}

                            >
                                <div>
                                    {/* <p>SC{delayedOrder.order.orderNumber}<br/>Hours: {delayedOrder.hoursDelayed}</p> */}
                                    {/* <p>Hours: {delayedOrder.hoursDelayed}</p> */}
                                </div>
                            </MarkerWithLabel>

                        );
                    }
                    return null;
                }))
            }

            {props.showTrafficLayer && <TrafficLayer autoUpdate />}

            {(props.isSuburbHighlighted && props.polygons) && props.mapUpdated && props.boundaries && (
                props.polygons.map((poly, i) => {
                    const onClick = props.onClick.bind(this, poly)
                    return (
                        <MemoizedPolygon
                            boundaries={props.boundaries}
                            //boundaries={boundaries}
                            poly={poly}
                            onClick={onClick}
                        />
                    );
                })
            )}

        </GoogleMap>

    )));
}()

export default GoogleMapComponent;