import React, { useEffect, useState } from "react"
import { withStyles } from "@material-ui/core/styles";
import {
    Grid,
    TextField,
    Typography,
} from "@material-ui/core";

const WhiteTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "white",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "white",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "white",
            },
            "&:hover fieldset": {
                borderColor: "white",
            },
            "&.Mui-focused fieldset": {
                borderColor: "white",
            },
        },
    },
})(TextField);

const EmployeeeSection = (props) => {
    let {
        newEmployee,
        updateNewEmployee
    } = props;

    return (
        <React.Fragment>
            <Grid item xs={12} md={12} lg={12}>
                <Typography variant={"h6"} align="center">
                    Employee Info
                </Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
                <WhiteTextField
                    label="First Name"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => {
                        updateNewEmployee("firstName", e.target.value)
                    }}
                    value={newEmployee.firstName}
                    spellCheck
                />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
                <WhiteTextField
                    label="Last Name"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => {
                        updateNewEmployee("lastName", e.target.value)
                    }}
                    value={newEmployee.lastName}
                    spellCheck
                />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
                <WhiteTextField
                    label="Email"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => {
                        updateNewEmployee("email", e.target.value)
                    }}
                    value={newEmployee.email}
                    spellCheck
                />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
                <WhiteTextField
                    label="Phone"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => {
                        updateNewEmployee("phone", e.target.value)
                    }}
                    value={newEmployee.phone}
                    spellCheck
                />
            </Grid>
        </React.Fragment>
    )
}

export default EmployeeeSection;