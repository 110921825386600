import {
    Button,
    Card,
    CardActions,
    CardContent,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./customerAddress.scss";
import AddUpdateAddressDialog from "./AddUpdateAddressDialog";

const ManageAddressDialog = (props) => {
    const {
        addresses,
        open,
        handleClose,
        reloadAddresses,
        customerId,
    } = props;

    const [showAddUpdateAddressDialog, setShowAddUpdateAddressDialog] = useState(false);
    const [addressToEdit, setAddressToEdit] = useState({});

    const makeAddressDefault = (address) => {
        axios.post(`AdminPanel/SetAddressAsDefault/${customerId}`, address)
        .then(res => {
            reloadAddresses();
        }).catch(e => {

        })
    }

    return (
        <Card>
            {addressToEdit !== null && addressToEdit !== undefined ? (
                <AddUpdateAddressDialog
                    open={showAddUpdateAddressDialog}
                    handleClose={() => {
                        setAddressToEdit({})
                        setShowAddUpdateAddressDialog(false)
                    }}
                    type="update"
                    customerId={customerId}
                    addressToEdit={addressToEdit}
                    reloadAddresses={() => reloadAddresses()}
                />
            ) : ""}
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>
                    <Typography variant="h6">Manage addresses</Typography>
                </DialogTitle>
                {addresses.map((address, index) => {
                    return (
                        <DialogContent dividers key={address.id}>
                            <div>
                                <Typography variant="body2">{`${address.firstName} ${address.lastName}`}</Typography>
                                {address.companyName !== "" && address.companyName !== null && address.companyName !== undefined ?
                                    <Typography variant="body2">{address.companyName}</Typography> : ""}
                                <Typography variant="body2">{address.addressLine1}</Typography>
                                <Typography variant="body2">{address.addressLine2}</Typography>
                                <Typography variant="body2">{`${address.city} ${address.postCode}`}</Typography>
                                <Typography variant="body2">{address.phoneNumber}</Typography>
                            </div>
                            <Button color="primary" size="small"
                                className="manage-address-edit-address-button"
                                onClick={(e) => {
                                    setAddressToEdit(address)
                                    setShowAddUpdateAddressDialog(true);
                                }}
                            >
                                Edit Address
                            </Button>
                            {address.isDefaultAddress ? "" :
                                <Button color="primary" variant="outlined" style={{ float: "right" }}
                                    onClick={(e) => {
                                        makeAddressDefault(address);
                                    }}
                                >
                                    Make Default
                                </Button>
                            }
                        </DialogContent>
                    )
                })}
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>Ok</Button>
                </DialogActions>
            </Dialog>
            {/* <CardContent>
                <Typography variant="h6">Manage addresses</Typography>
                {addresses.map((address, index) => {
                    return (
                        <div>
                            <Typography variant="body2">{address.street}</Typography>
                            <Typography variant="body2">{address.suburb}</Typography>
                            <Typography variant="body2">{address.city}</Typography>
                            <Typography variant="body2">{address.country}</Typography>
                            <Button color="primary" size="small" style={{ float: "left" }}>
                                Edit Address
                            </Button>
                            <Button color="primary" variant="contained" style={{ float: "right" }}>
                                Make Default
                            </Button>
                        </div>
                    )
                })}
            </CardContent>
            <CardActions>
                <Button color="primary" size="small">
                    Manage
                </Button>
            </CardActions> */}
        </Card>
    );
};

export default ManageAddressDialog;