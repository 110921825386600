import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    CircularProgress
} from "@material-ui/core";
import { isEmpty } from "../Utils/helperFunctions";

const ConfirmationDialogWithInput = ({
    loading = false,
    handleClose,
    open,
    title,
    textFieldLabel,
    submitLabel,
    handleSubmit,
    description
}) => {
    const [inputValue, setInputValue] = useState("");

    return (
        <div>
            <Dialog
                open={open}
                onClose={(e, reason) => {
                    if (reason !== "backdropClick") {
                        handleClose()
                    }
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ paper: "confirmation-with-input-dialog" }}
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {description}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label={textFieldLabel}
                        type="text"
                        fullWidth
                        variant="standard"
                        multiline
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                </DialogContent>
                {loading ? (
                    <DialogActions style={{ padding: 20 }}>
                        <CircularProgress />
                    </DialogActions>
                ) : (
                    <DialogActions>
                        <Button disabled={loading} className="default-button" variant="contained" onClick={handleClose}>Cancel</Button>
                        <Button
                            color="primary"
                            disabled={isEmpty(inputValue) || loading}
                            className={isEmpty(inputValue) ? "" : "default-button"}
                            variant="contained"
                            onClick={() => handleSubmit(inputValue)}
                            autoFocus>
                            {submitLabel}
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        </div>
    );
};

export default ConfirmationDialogWithInput;
