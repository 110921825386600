import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
    Modal,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Grid,
    CircularProgress,
} from "@material-ui/core";
import {
    Close
} from "@material-ui/icons";
import {
    ShopifyDetails,
    RobotUIDetails,
} from "./DeayedOrderComponents/DelayedOrderComponents";
import ActionButtons from "./DeayedOrderComponents/ActionButtons";
import ImagePanel from "./DeayedOrderComponents/ImagePanel";
import LogsPanel from "./DeayedOrderComponents/LogsPanel";
import { makeStyles } from "@material-ui/core/styles";
import "./Delays.scss";
import axios from "axios";
import {
    getDelayedOrders
} from "../../reduxActions/order"

const useStyles = makeStyles((theme) => ({
    paper: {
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

const DelayedOrderPopUp = (props) => {
    const {
        modalState,
        handleClose,
        orderId,
        trackingNumber,
        boxSize,
        hoursInTransit,
        getDelayedOrders,
        user,
        fromDisputed,
        fromQuickFix
    } = props;

    const classes = useStyles();

    const [shopifyOrder, setShopifyOrder] = useState(null)
    const [storeOrder, setStoreOrder] = useState(null)
    const [orderEvents, setOrderEvents] = useState(null)

    const clearCanvasForRerender = () => {
        var canvases = document.getElementsByTagName('canvas');
        for (var i = 0; i < canvases.length; i++) {
            var context = canvases[i].getContext('2d')
            context.clearRect(0, 0, canvases[i].width, canvases[i].height);
        }
    }

    useEffect(() => {
        axios.get(`ControlCenter/GetControlCenterOrder/${orderId}/${trackingNumber}`)
            .then(res => {
                setShopifyOrder(res.data.shopifyOrder);
                setStoreOrder(res.data.order);
                setOrderEvents(res.data.orderEvents)
            })
            .catch(e => {
                // console.log(e)
            })
    }, [])

    return (
        <Modal
            open={modalState}
            disableBackdropClick
            onClose={() => {
                handleClose();
            }}
            className={"delayed-order-modal"}
        >
            <div>
                <AppBar position="static" style={{ background: "#27293d" }}>
                    <Toolbar variant="dense" style={{ paddingRight: 0 }}>
                        <Typography
                            style={{
                                fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                                width: "100%",
                                textAlign: "center",
                            }}
                            variant={"subtitle1"}
                            component={"span"}
                        >
                            {storeOrder && (
                                <span>{`SC${storeOrder.orderNumber} - ${boxSize}0 Capsules (${hoursInTransit} hours in transit)`}</span>
                            )}
                        </Typography>
                        <IconButton
                            onClick={() => {
                                handleClose();
                            }}
                            style={{
                                color: "white",
                                display: "block",
                                margin: "auto 0px auto auto",
                            }}
                        >
                            <Close />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className={`${classes.paper} edit-delayed-order-paper`}>
                    {storeOrder && shopifyOrder ? (
                        <Grid container style={{ height: "100%", margin: "0px", padding: "0px" }}>
                            <Grid item xs={6} md={6} lg={6} className="full-grid-length">
                                <LogsPanel
                                    shopifyOrder={shopifyOrder}
                                    storeOrder={storeOrder}
                                    orderEvents={orderEvents}
                                    setOrderEvents={setOrderEvents}
                                    trackingNumber={trackingNumber}
                                    user={user}
                                />
                            </Grid>
                            <Grid item xs={6} md={6} lg={6} className="full-grid-length">
                                <Grid container style={{ height: "100%" }}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Grid container className="image-panel-grid" spacing={1}>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <ImagePanel
                                                    orderId={orderId}
                                                    trackingNumber={trackingNumber}
                                                    type={"image"}
                                                    imageType={"note"}
                                                    fromDisputed={fromDisputed}
                                                    fromClaims={false}
                                                />
                                            </Grid><Grid item xs={12} md={6} lg={6}>
                                                <ImagePanel
                                                    orderId={orderId}
                                                    trackingNumber={trackingNumber}
                                                    type={"image"}
                                                    imageType={"barcode"}
                                                    fromDisputed={fromDisputed}
                                                    fromClaims={false}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <ImagePanel
                                                    orderId={orderId}
                                                    trackingNumber={trackingNumber}
                                                    type={"image"}
                                                    imageType={"label"}
                                                    fromDisputed={fromDisputed}
                                                    fromClaims={false}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <ImagePanel
                                                    orderId={orderId}
                                                    trackingNumber={trackingNumber}
                                                    type={"pdf"}
                                                    fromDisputed={fromDisputed}
                                                    fromClaims={false}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Grid container style={{ height: "100%" }} spacing={1}>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <RobotUIDetails storeOrder={storeOrder} />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <ShopifyDetails shopifyOrder={shopifyOrder} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} className="sticky">
                                <ActionButtons
                                    shopifyOrder={shopifyOrder}
                                    storeOrder={storeOrder}
                                    trackingNumber={trackingNumber}
                                    setOrderEvents={setOrderEvents}
                                    getDelayedOrders={getDelayedOrders}
                                    closePopup={() => handleClose()}
                                    fromDisputed={fromDisputed}
                                    fromQuickFix={fromQuickFix}
                                />
                            </Grid>
                        </Grid>
                    ) : <CircularProgress />}
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    user: state.authentication.activeUser.firstName
});

export default connect(mapStateToProps, {
    getDelayedOrders
})(DelayedOrderPopUp);