import React from "react";
import { connect } from "react-redux";
import OrdersTable from "../Shared/OrdersTable";

const AllOrders = (props) => {
  const { orders, hpMode } = props;

  if (orders) {
    return (
      <OrdersTable parent="All Orders" isChunkSorted={hpMode} orders={orders} />
    );
  } else {
    return <div>Loading...</div>;
  }
};

const mapStateToProps = (state) => ({
  hpMode: state.system.hpMode,
  orders: state.order.orders,
});

export default connect(mapStateToProps, {})(AllOrders);
