import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  stopwatch: false,
  time: 0,
  handleInterval: 0,
};
const timer = createReducer(initialState, {
  START_STOP_TIMER_STORE: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      stopwatch: payload,
    };
  },
  START_TIME_STORE: (state, action) => {
    if (state.stopwatch) {
      state.time = state.time + 1;
      state.handleInterval = action.payload;
    }
  },
  STOP_TIME_STORE: (state) => {
    clearInterval(state.handleInterval);
    state.handleInterval = 0;
    state.time = 0;
  },
});

export default timer;
