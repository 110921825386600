import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";

const Buttons = (props) => {
  Buttons.propTypes = {
    handleClick: PropTypes.func.isRequired,
    buttonText: PropTypes.string.isRequired,
    numItems: PropTypes.number,
    className: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    loadingNumItems: PropTypes.bool,
  };

  const [numItemsColor, setNumItemsColor] = useState("");
  useEffect(() => {
    if (props.numItems !== undefined) {
      if (props.numItems < 5) {
        setNumItemsColor("green");
      } else if (props.numItems < 20) {
        setNumItemsColor("yellow");
        console.log("yellow");
      } else {
        setNumItemsColor("red");
      }
    }
  }, [props.numItems]);

  return (
    <Button
      className="home-button"
      onClick={props.handleClick}
      variant="contained"
      color="primary"
    // disabled={props.disabled}
    >
      <div>{props.buttonText}</div>
      {props.numItems !== undefined &&
        <div
          className="home-num-items-container"
          style={{ color: `${numItemsColor}` }}
        >
          {
            props.loadingNumItems ? <CircularProgress/> : props.numItems
          }
        </div>
      }
    </Button>
  );
};

export default Buttons;
