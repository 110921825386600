import React, { useState, useEffect } from "react";
import {
    Grid,
    TextField,
    Tabs,
    Tab,
    Box,
    Typography,
    Button
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Claims from "../Claims/Claims";
import Delays from "./Delays";
import "./Delays.scss";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                // <Box p={1}>
                //     <Typography>{children}</Typography>
                // </Box>
                <p>
                    {children}
                </p>
            )}
        </div>
    );
}

const DelayedOrdersHome = () => {
    let history = useHistory();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="delayed-orders-home-container">
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                style={{
                    color: "white !important"
                }}
                centered
            >
                <Tab label="Delayed Orders" />
                <Tab label="Disputed" />
                <Tab label="Quickfixed" />
                <Tab label="Created Claims" />
            </Tabs>
            <TabPanel value={value} index={0}>
                <Delays type="delays"/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Delays type="disputed"/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Delays type="quickFix"/>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Claims />
            </TabPanel>
        </div>
    )
}

export default DelayedOrdersHome;