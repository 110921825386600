import React, { useState, useEffect } from "react"
import {
    Button,
    ButtonGroup,
    Popper,
    Grid,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem
} from "@material-ui/core";
import {
    ArrowDropDown as ArrowDropDownIcon
} from "@material-ui/icons";
import axios from "axios";

const SetStatusButton = (props) => {
    const {
        storeOrder,
        trackingNumber,
        claimId,
        currentStatus,
        refreshClaims,
        setSnackBarProps
    } = props;

    const splitButtonOptions = ["ClaimCreated", "ClaimSent", "ClaimPaid", "ClaimDismissed"];
    const anchorRef = React.useRef(null);

    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleMenuItemClick = (event, index) => {
        //send to backend
        axios.patch(`ControlCenter/UpdateClaimStatus/${claimId}`, {
            id: claimId,
            status: splitButtonOptions[index]
        })
            .then((res) => {
                refreshClaims();
                setSnackBarProps("success", "Status updated", true);
            })
            .catch((e) => {
                setSnackBarProps("error", "Status failed to update", true);

            });
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    useEffect(() => {
        var currentIndex = splitButtonOptions.findIndex(o => o === currentStatus);
        setSelectedIndex(currentIndex);
    }, [])

    return (
        <Grid container direction="column" alignItems="center">
            <Grid item xs={12}>
                <ButtonGroup
                    variant="contained"
                    color="primary"
                    ref={anchorRef}
                    aria-label="split button"
                >
                    <Button size="small">{splitButtonOptions[selectedIndex].replace("Claim", "Claim ")}</Button>
                    <Button
                        color="primary"
                        size="small"
                        aria-controls={open ? "split-button-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="menu"
                        onClick={handleToggle}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    style={{ zIndex: "99999999999999" }}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "center top" : "center bottom",
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-delayed-order">
                                        {splitButtonOptions.map((option, index) => (
                                            <MenuItem
                                                key={option}
                                                selected={index === selectedIndex}
                                                onClick={(event) => handleMenuItemClick(event, index)}
                                            >
                                                {option === "ClaimSent" ? "Claim Sent" :
                                                    option === "ClaimDismissed" ? "Claim Dismissed" :
                                                        option === "ClaimPaid" ? "Claim Paid" :
                                                            option === "ClaimCreated" ? "Claim Created" : ""
                                                }
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    )
}

export default SetStatusButton;