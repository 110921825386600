import React, { useContext } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { CardActions, Chip, Typography } from "@material-ui/core";
import "./customerDetailsCard.scss";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import { useHistory } from "react-router-dom";
import { StoreContext } from "../../../context/store/StoreContext";

const CustomerDetailsCard = ({ customer, discountCode }) => {
  const history = useHistory();
  const { checkout, addDiscountToCart } = useContext(StoreContext);

  const handleClickName = (e) => {
    e.preventDefault()
    history.push("/AdminPanel/Customers")
  }

  const fetchDiscountCode = async () => {
    if (discountCode?.code) {
      let code = discountCode.code
      if (String(code).toLowerCase() === "bronze") {code = "CCR100"}
      const invalidDiscountMessage = await addDiscountToCart(code);
      if (invalidDiscountMessage) {
        alert(invalidDiscountMessage.replace("Discount code ", ""));
      }
    }
  };

  return (
   
    <Card className="customer-details-card-root">
      <CardContent>
        {customer ? (
          <div className="customer-details-card-content">
            <div>
              <Typography className="customer-details-card-name" variant="h5" component="h2" onClick={handleClickName}>
                {customer?.firstName} {customer?.lastName}
              </Typography>
              <Typography
                color="textSecondary"
                className="customer-details-card-meta"
              >
                {customer?.email}
              </Typography>
              <Typography
                color="textSecondary"
                className="customer-details-card-meta"
              >
                {customer?.phoneNumber}
              </Typography>
            </div>
          </div>
        ) : (
          <div className="no-customer-msg">No Customer details found</div>
        )}
      </CardContent>
      <CardActions>
        {discountCode && (
          <div className="customer-details-card-loyalty">
            <Chip
              onClick={fetchDiscountCode}
              icon={<LoyaltyIcon />}
              label={discountCode.code}
              variant="outlined"
            />
          </div>
        )}
      </CardActions>
    </Card>
  );
};

export default CustomerDetailsCard;
