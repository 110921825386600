import {
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  IconButton,
  Snackbar,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { Loyalty as LoyaltyIcon } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useCustomer } from "../../../context/customer/CustomerContext";
import AdminLayout from "../AdminLayout/AdminLayout";
import "./customerDetails.scss";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import StorefrontIcon from "@material-ui/icons/Storefront";
import PaymentIcon from "@material-ui/icons/Payment";
import CustomerOrders from "../CustomerOrders/CustomerOrders";
import CustomerPaymentCard from "../CustomerPaymentCard/CustomerPaymentCard";
import CustomerAddressCard from "../CustomerAddress/CustomerAddressCard";
import { useHistory } from "react-router-dom";
import RefundModal from "../RefundModal/RefundModal";
import AddNewCardModal from "../AddNewCardModal/AddNewCardModal";
import AutoshipDeleteModal from "../AutoshipDeleteModal/AutoshipDeleteModal";
import axios from "axios";
import CustomerNotes from "../CustomerNotes/CustomerNotes";
import {
  createNewSubscription,
  findSubscriptionsByShopifyCustomerId,
  updateSubscriptionAddress,
  updateSubscriptionFrequency,
  updateSubscriptionLineItems,
  updateSubscriptionPayment,
  updateSubscriptionStatus,
  updateSubscriptionIsRural,
  processSubscriptionOrder,
} from "../../../repository/subscriptions";
import SubscriptionsTable from "../SubscriptionsTable/SubscriptionsTable";
import { Alert } from "@material-ui/lab";
import moment from "moment/moment";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../../config/firebase";
import EditCustomerModal from "../EditCustomerModal/EditCustomerModal";
import {
  findCustomerById,
  findFirestoreCustomerByFirestoreId,
} from "../../../repository/customers";
import { findReferralsByIds } from "../../../repository/referrals";
import { convertIdFromGraphql } from "../../Utils/shopifyConverter";
import ReferralsTable from "../Referrals/ReferralsTable/ReferralsTable";

const actionCodeSettings = {
  url: "https://www.coffeecapsules2u.co.nz/login",
};

const CustomerDetails = () => {
  const history = useHistory();
  const { customer, firestoreCustomer, paymentMethods, findCustomerDetails } =
    useCustomer();

  const [reorderId, setReorderId] = useState(null);
  const [customerTags, setCustomerTags] = useState([]);
  const [firestoreOrder, setFirestoreOrder] = useState(null);
  const [openRefundModal, setOpenRefundModal] = useState(false);
  const [forcedRefundAmmount, setForcedRefundAmmount] = useState(null);
  const [refundTitle, setRefundTitle] = useState("");
  const [openNewCardModal, setOpenNewCardModal] = useState(false);
  const [currenltyMigrating, setCurrentlyMigrating] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const [messageDisplay, setMessageDisplay] = useState(null);
  const [shippingAddress, setShippingAddress] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [openEditCustomerModal, setOpenEditCustomerModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [justProccessedSubscriptionId, setJustProccessedSubscriptionId] =
    useState(null);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [generatingCustomerPasswordLink, setGeneratingCustomerPasswordLink] =
    useState(false);
  const [customerPasswordResetLink, setCustomerPasswordResetLink] =
    useState(null);

  useEffect(() => {
    if (customer !== null && customer?.tags !== "") {
      setCustomerTags(customer?.tags?.split(","));
    }
    if (customer?.id) {
      fetchCustomerSubscription(customer?.id);
    }

    setFirestoreOrder(null);
    setReorderId(null);
  }, [customer]);

  useEffect(() => {
    console.log(firestoreCustomer);
    if (firestoreCustomer?.referralCodeIds?.length > 0) {
      fetchCustomerReferrals(firestoreCustomer?.referralCodeIds);
    }
  }, [firestoreCustomer]);

  useEffect(() => {
    const urlParams = new URLSearchParams(history.location.search);
    if (urlParams.has("id")) {
      setLoadingCustomer(true);
      console.log(urlParams.get("id"));
      findCustomerById(urlParams.get("id"))
        .then((customer) => findCustomerDetails(customer))
        .finally(() => setLoadingCustomer(false));
    } else if (urlParams.has("firestoreId")) {
      setLoadingCustomer(true);
      findFirestoreCustomerByFirestoreId(urlParams.get("firestoreId"))
        .then((customer) =>
          findCustomerById(convertIdFromGraphql(customer.shopifyId))
        )
        .then((customer) => findCustomerDetails(customer))
        .finally(() => setLoadingCustomer(false));
    }
  }, [history.location.search]);

  // useEffect(() => {
  //   if (firestoreCustomer === null && customer !== null && customer !== undefined) {
  //     setCurrentlyMigrating(true);

  //   }
  // }, [firestoreCustomer, customer])
  const handleRefundCallback = () => {
    setFirestoreOrder(null);
    setReorderId(null);
  };

  const handleProcessSubscriptionOrder = async (subscription) => {
    setIsProcessing(true);
    const _subscription = await processSubscriptionOrder(subscription);
    setJustProccessedSubscriptionId(_subscription.id);
    handleUpdateSubscriptionList(_subscription);
    setIsProcessing(false);
  };

  const handleDeleteSubscriptionOrder = (subscription) => {
    setSelectedSubscription(subscription);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setSelectedSubscription(null);
    setOpenDeleteModal(false);
  };

  const handleSubmitDeleteModal = async () => {
    setIsProcessing(true);
    const _subscription = await updateSubscriptionStatus(
      selectedSubscription,
      "DELETED"
    );
    handleUpdateSubscriptionList(_subscription);
    handleCloseDeleteModal();
    setIsProcessing(false);
  };

  const fetchCustomerSubscription = async (customerId) => {
    setSubscriptions(await findSubscriptionsByShopifyCustomerId(customerId));
  };

  const fetchCustomerReferrals = async (referralIds) => {
    const _referrals = await findReferralsByIds(referralIds);
    console.log(_referrals);
    setReferrals(_referrals);
  };

  const handleUpdateStatus = async (subscription, status) => {
    const _subscription = await updateSubscriptionStatus(subscription, status);
    handleUpdateSubscriptionList(_subscription);
  };

  const handleUpdateSubscriptionList = (_subscription) => {
    if (_subscription) {
      setSubscriptions(
        subscriptions.map((s) =>
          s.id === _subscription.id ? _subscription : s
        )
      );
      setMessageDisplay({
        message: "Autoship has been updated.",
        hasError: false,
      });
    } else {
      setMessageDisplay({
        message: "Unable to update your Autoship.",
        hasError: true,
      });
    }
  };

  const handleUpdateAddress = async (subscription, address) => {
    const _subscription = await updateSubscriptionAddress(
      subscription,
      address
    );
    handleUpdateSubscriptionList(_subscription);
  };

  const handleUpdatePayment = async (subscription, paymentId) => {
    const _subscription = await updateSubscriptionPayment(
      subscription,
      paymentId
    );
    handleUpdateSubscriptionList(_subscription);
  };

  const handleQuantityChange = async (subscription, lineItem, newQuantity) => {
    if (lineItem) {
      setIsUpdating(true);
      if (newQuantity === 0) {
        const _subscription = await updateSubscriptionLineItems(
          subscription,
          subscription.lineItems.filter((item) => item.sku !== lineItem.sku)
        );
        handleUpdateSubscriptionList(_subscription);
        setIsUpdating(false);
      } else {
        lineItem.quantity = newQuantity;
        const _subscription = await updateSubscriptionLineItems(
          subscription,
          subscription.lineItems.map((item) =>
            item.sky === lineItem.sku ? lineItem : item
          )
        );
        handleUpdateSubscriptionList(_subscription);
        setIsUpdating(false);
      }
    }
  };

  const handleNewProduct = async (
    subscription,
    selectedProduct,
    newVariant,
    isAddingNewProduct
  ) => {
    let lineItems = [];

    if (isAddingNewProduct) {
      let _lineItems = [...subscription.lineItems];
      lineItems = [
        {
          sku: newVariant.sku,
          productTitle: newVariant.productTitle,
          quantity: 1,
          price: newVariant.price.toString() || "0",
          productId: newVariant.productId,
          variantId: newVariant.variantId,
          variantTitle: newVariant.variantTitle,
          imageSrc: newVariant.productImage,
        },
        ..._lineItems,
      ];
    } else {
      lineItems = subscription.lineItems.map((item) =>
        item.sku === selectedProduct.sku
          ? {
              sku: newVariant.sku,
              productTitle: newVariant.productTitle,
              quantity: selectedProduct.quantity,
              price: newVariant.price.toString() || "0",
              productId: newVariant.productId,
              variantId: newVariant.variantId,
              variantTitle: newVariant.variantTitle,
              imageSrc: newVariant.productImage,
            }
          : item
      );
    }
    if (lineItems.length) {
      const _subscription = await updateSubscriptionLineItems(
        subscription,
        lineItems
      );
      handleUpdateSubscriptionList(_subscription);
    }
  };

  const handleRemoveItem = async (subscription, lineItem) => {
    if (lineItem) {
      const _subscription = await updateSubscriptionLineItems(
        subscription,
        subscription.lineItems.filter((item) => item.sku !== lineItem.sku)
      );
      handleUpdateSubscriptionList(_subscription);
    }
  };
  const handleEditFrequency = async (
    subscription,
    nextShipment,
    subscriptionFrequency
  ) => {
    if (subscription) {
      const _subscription = await updateSubscriptionFrequency(
        subscription,
        nextShipment,
        subscriptionFrequency
      );
      handleUpdateSubscriptionList(_subscription);
    }
  };

  const handleCreateNewSubscription = async () => {
    let subscription = {
      email: customer.email,
      customerId: firestoreCustomer.id,
      status: "ACTIVE",
      lineItems: [],
      nextShipment: moment().add(4, "weeks"),
      subscriptionFrequency: 4,
      shopifyCustomerId: customer.id,
      paymentMethodId: paymentMethods?.length > 0 ? paymentMethods[0].id : null,
      shippingAddress: shippingAddress,
      stripeCustomerId: firestoreCustomer.stripeId,
      eligibleLoyaltyPercentage: customer.loyaltyTier.discountPercent,
    };
    const _subscription = await createNewSubscription(subscription);
    if (_subscription) {
      setSubscriptions([_subscription, ...subscriptions]);
      setMessageDisplay({
        message: "Autoship has been added.",
        hasError: false,
      });
    } else {
      setMessageDisplay({ message: "Unable to add Autoship.", hasError: true });
    }
  };

  const handleSendResetPassword = () => {
    sendPasswordResetEmail(auth, customer.email, actionCodeSettings)
      .then(() => {
        setMessageDisplay({
          message: "Sweet! We have sent a link to reset the password.",
          hasError: false,
        });
      })
      .catch((error) => {
        setMessageDisplay({
          message: "We are unable to reset the password at the moment.",
          hasError: true,
        });
      });
  };

  const copyToClipboard = (text) => {
    try {
      navigator.clipboard.writeText(text);
      setMessageDisplay({
        message: "Copied to clipboard!",
        hasError: false,
      });
    } catch (err) {
      console.error("Failed to copy: ", err);
      setMessageDisplay({
        message: "Could not copy to clipboard.",
        hasError: true,
      });
    }
  };

  const handleGenerateResetPasswordLink = () => {
    setGeneratingCustomerPasswordLink(true);
    axios
      .get(`AdminPanel/GetCustomerPasswordResetLink/${customer.email}`)
      .then((response) => {
        setCustomerPasswordResetLink(response.data);
        copyToClipboard(response.data);
      })
      .catch((error) => {
        console.error(error);
        setMessageDisplay({
          message: "Something went wrong",
          hasError: true,
        });
      })
      .finally(() => setGeneratingCustomerPasswordLink(false));
  };

  const handleCloseCustomerDetailsModal = () => {
    setOpenEditCustomerModal(false);
  };

  const openNormalRefundModal = () => {
    setForcedRefundAmmount(null);
    setRefundTitle(`Refund ${firestoreOrder.orderNumber}`);
    setOpenRefundModal(true);
  };

  const openLoyaltyRefundModal = () => {
    console.log(firestoreOrder);
    if (firestoreOrder) {
      let discountPercentage;
      switch (customer?.loyaltyTier?.tier?.toLowerCase()) {
        case "bronze":
          discountPercentage = 0.05;
          break;
        case "silver":
          discountPercentage = 0.07;
          break;
        case "gold":
          discountPercentage = 0.1;
          break;
        case "platinum":
          discountPercentage = 0.125;
          break;
      }
      setRefundTitle(
        `Refund ${
          firestoreOrder.orderNumber
        } - ${customer?.loyaltyTier?.tier?.toUpperCase()} - ${
          Math.floor(discountPercentage * 10000) / 100
        }%`
      );
      setForcedRefundAmmount(
        (firestoreOrder.total - firestoreOrder.shippingCost.rate) *
          discountPercentage
      );
    }
    setOpenRefundModal(true);
  };

  const handleSetIsRural = async (subscription, isRural) => {
    if (subscription) {
      const _subscription = await updateSubscriptionIsRural(
        subscription.id,
        isRural
      );
      handleUpdateSubscriptionList(_subscription);
    }
  };

  return (
    <AdminLayout>
      {loadingCustomer ? (
        <Typography variant="h4">Loading customer...</Typography>
      ) : customer ? (
        <div className="customer-details-root">
          <div className="customer-container">
            <div className="customer-personal-details">
              <div className="customer-details-header-container">
                <div className="customer-details-name-container">
                  <Typography variant="h4">
                    {customer.firstName + " " + customer.lastName}
                  </Typography>
                </div>
                <IconButton onClick={() => setOpenEditCustomerModal(true)}>
                  <EditIcon />
                </IconButton>
              </div>
              <div className="email-container">
                {customer.email && (
                  <div className="icon-container">
                    <MailOutlineIcon className="meta" />
                    <Typography variant="body2" className="meta">
                      {customer.email}
                    </Typography>
                  </div>
                )}
                {customer.phone && (
                  <div className="icon-container">
                    <PhoneAndroidIcon className="meta" />
                    <Typography variant="body2" className="meta">
                      {customer.phone}
                    </Typography>
                  </div>
                )}
                {firestoreCustomer && firestoreCustomer.authId && (
                  <Chip
                    label="Has Website Login"
                    size="small"
                    color="primary"
                  />
                )}

                {firestoreCustomer && (
                  <Chip
                    label="Has been migrated from shopify"
                    size="small"
                    variant="outlined"
                  />
                )}
                {currenltyMigrating ? (
                  <Chip
                    label="Currenlty Migrating"
                    size="small"
                    color="primary"
                  >
                    <CircularProgress />
                  </Chip>
                ) : (
                  ""
                )}
              </div>

              <div className="loyalty-tier-container">
                {customerTags?.length > 0 ? (
                  <div className="icon-container">
                    <LoyaltyIcon className="meta" />
                    <Typography variant="body2" className="meta">
                      {customer?.loyaltyTier?.tier?.toUpperCase() ||
                        "No Loyalty Tier"}
                    </Typography>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div>
              <Typography variant="h5" onClick>
                Quick Links
              </Typography>
              <div className="quick-links-container">
                <span className="icon-span">
                  <StorefrontIcon className="meta" />
                  <Typography
                    className="hyperlink"
                    variant="body2"
                    onClick={() =>
                      window.open(
                        `https://simply-coffee-capsules.myshopify.com/admin/customers/${customer.id}`
                      )
                    }
                  >
                    Shopify
                  </Typography>
                </span>
                <span className="icon-span">
                  <PaymentIcon className="meta" />
                  <Typography
                    className="hyperlink"
                    variant="body2"
                    onClick={() =>
                      window.open(
                        `https://dashboard.stripe.com/customers/${customer.stripeId}`
                      )
                    }
                  >
                    Stripe
                  </Typography>
                </span>
                <span className="icon-span">
                  {firestoreCustomer && firestoreCustomer.authId && (
                    <>
                      <Button
                        variant="outlined"
                        onClick={handleSendResetPassword}
                      >
                        Send Reset Password Email
                      </Button>
                      {!customerPasswordResetLink ? (
                        <Button
                          variant="outlined"
                          onClick={handleGenerateResetPasswordLink}
                          disabled={generatingCustomerPasswordLink}
                        >
                          {generatingCustomerPasswordLink && (
                            <CircularProgress />
                          )}
                          Generate Reset Password Link
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          style={{ textTransform: "none" }}
                          onClick={() =>
                            copyToClipboard(customerPasswordResetLink)
                          }
                        >
                          <div
                            style={{
                              maxWidth: "400px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {customerPasswordResetLink}
                          </div>
                        </Button>
                      )}
                    </>
                  )}
                </span>
              </div>
            </div>

            <div className="customer-orders">
              <div className="customer-orders-header">
                <Typography variant="h5" onClick>
                  Orders
                </Typography>
                <div className="action-container">
                  {firestoreOrder && firestoreOrder.paymentIntentId && (
                    <>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => openLoyaltyRefundModal()}
                      >
                        Refund Loyalty
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => openNormalRefundModal()}
                      >
                        Refund
                      </Button>
                    </>
                  )}
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      history.push({
                        pathname: `/AdminPanel/CreateOrder/${customer.id}`,
                      });
                    }}
                  >
                    Create Order
                  </Button>
                  {/* <Button
                    variant="contained"
                    color="primary"
                    disabled={reorderId === null}
                    onClick={() => {
                      history.push({
                        pathname: `/AdminPanel/CreateOrder/${customer.id}/${reorderId}`,
                      });
                    }}
                  >
                    Reorder
                  </Button> */}
                </div>
              </div>

              <CustomerOrders
                customer={customer}
                setReorderId={setReorderId}
                setFirestoreOrder={setFirestoreOrder}
              />
            </div>
            <div className="autoship-orders">
              <Typography variant="h5">Autoship</Typography>
              {openDeleteModal && selectedSubscription && (
                <AutoshipDeleteModal
                  open={openDeleteModal}
                  handleClose={handleCloseDeleteModal}
                  handleSubmit={handleSubmitDeleteModal}
                  subscription={selectedSubscription}
                />
              )}
              <SubscriptionsTable
                subscriptions={subscriptions}
                paymentMethods={paymentMethods}
                handleUpdateStatus={handleUpdateStatus}
                handleUpdateAddress={handleUpdateAddress}
                handleSetIsRural={handleSetIsRural}
                handleUpdatePayment={handleUpdatePayment}
                handleQuantityChange={handleQuantityChange}
                isUpdating={isUpdating}
                handleNewProduct={handleNewProduct}
                handleRemoveItem={handleRemoveItem}
                handleEditFrequency={handleEditFrequency}
                handleCreateNewSubscription={handleCreateNewSubscription}
                handleProcessSubscriptionOrder={handleProcessSubscriptionOrder}
                deleteSubscriptionOrder={handleDeleteSubscriptionOrder}
                isProcessing={isProcessing}
                justProccessedSubscriptionId={justProccessedSubscriptionId}
              />
            </div>
            <div className="autoship-orders">
              <Typography variant="h5">Referral Code</Typography>
              <ReferralsTable
                referrals={referrals}
                setReferrals={referrals}
                isGlobalPage={false}
              />
            </div>
          </div>
          <div className="side-cards-container">
            {customer && (
              <React.Fragment>
                <CustomerAddressCard
                  customerId={customer.id}
                  setShippingAddress={setShippingAddress}
                  isCheckout={true}
                />
                <CustomerPaymentCard
                  setOpenNewCardModal={setOpenNewCardModal}
                />
                {customer?.id && (
                  <Card>
                    <CardContent>
                      <Typography variant="h6">Notes</Typography>
                      <CustomerNotes customerId={customer.id} />
                    </CardContent>
                  </Card>
                )}
              </React.Fragment>
            )}
          </div>
          {openEditCustomerModal && (
            <EditCustomerModal
              open={openEditCustomerModal}
              handleClose={handleCloseCustomerDetailsModal}
              setMessageDisplay={setMessageDisplay}
            />
          )}
          {openRefundModal && (
            <RefundModal
              open={openRefundModal}
              handleClose={(e) => setOpenRefundModal(false)}
              order={firestoreOrder}
              handleCallback={handleRefundCallback}
              forceValue={forcedRefundAmmount}
              title={refundTitle}
            />
          )}
          {openNewCardModal && (
            <AddNewCardModal
              open={openNewCardModal}
              handleClose={(e) => setOpenNewCardModal(false)}
            />
          )}
          {messageDisplay && (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={messageDisplay}
              autoHideDuration={5000}
              onClose={() => setMessageDisplay(null)}
            >
              {messageDisplay.hasError ? (
                <Alert
                  onClose={() => setMessageDisplay(null)}
                  variant="filled"
                  severity="error"
                >
                  {messageDisplay.message}
                </Alert>
              ) : (
                <Alert
                  onClose={() => setMessageDisplay(null)}
                  variant="filled"
                  severity="success"
                >
                  {messageDisplay.message}
                </Alert>
              )}
            </Snackbar>
          )}
        </div>
      ) : (
        <Typography variant="h4">
          Please search a customer using the search bar
        </Typography>
      )}
    </AdminLayout>
  );
};

export default CustomerDetails;
