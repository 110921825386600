import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  Select,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import axios from "axios";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: 15,
    minWidth: 180,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ShippingDetails = ({
  order,
  setOrderToBeUpdated,
  returnLabel,
  setReturnLabel,
}) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const [addresses, setAddressses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  useEffect(() => {
    if ({ searchTerm }) {
      const delayDebounceFn = setTimeout(() => {
        getNZPostAddresses(searchTerm);
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchTerm]);

  const getNZPostAddresses = (searchTerm) => {
    setAutocompleteLoading(true);
    if (searchTerm && searchTerm !== "") {
      axios
        .get(`Orders/GetNZPostAddresses/${encodeURIComponent(searchTerm)}`)
        .then((res) => {
          setAddressses(res.data);
        })
        .catch((e) => {
          // console.log(e);
          setAddressses([]);
        })
        .finally(() => setAutocompleteLoading(false));
    } else {
      setAutocompleteLoading(false);
    }
  };

  const getSelectedAddress = () => {
    if (selectedAddress) {
      return selectedAddress;
    }
    const defaultAddress = {
      fullAddress: `${
        order.shippingAddress.address1 ? order.shippingAddress.address1 : ""
      } ${
        order.shippingAddress.address2 ? order.shippingAddress.address2 : ""
      } ${order.shippingAddress.city ? order.shippingAddress.city : ""} ${
        order.shippingAddress.zip ? order.shippingAddress.zip : ""
      }`,
      isPostal: order.shippingAddress.isPostal,
    };

    setAddressses([defaultAddress]);
    setSelectedAddress(defaultAddress);
  };

  const selectNewShippingAddressFromDpid = (dpid, isPostal) => {
    axios
      .put(
        `Orders/SelectNewShippingAddressFromDpid/${dpid}/${isPostal}`,
        order.shippingAddress
      )
      .then(({ data }) => {
        setOrderToBeUpdated({ ...order, shippingAddress: data });
      })
      .catch((e) => {
        console.log(e);
        alert("error assigning new address");
      });
  };
  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            label="First Name"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={order.shippingAddress.firstName}
            onChange={(e) => {
              let shippingAddress = { ...order.shippingAddress };
              shippingAddress.firstName = e.target.value;
              setOrderToBeUpdated({
                ...order,
                shippingAddress: shippingAddress,
              });
            }}
            spellCheck
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            label="Last Name"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={order.shippingAddress.lastName}
            onChange={(e) => {
              let shippingAddress = { ...order.shippingAddress };
              shippingAddress.lastName = e.target.value;
              setOrderToBeUpdated({
                ...order,
                shippingAddress: shippingAddress,
              });
            }}
            spellCheck
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Autocomplete
            disableClearable
            loading={autocompleteLoading}
            fullWidth
            id="nzpost-address-dropdown"
            options={addresses}
            getOptionLabel={(option) =>
              `${option.fullAddress} (${
                option.isPostal ? "Postal" : "Not Postal"
              })`
            }
            value={getSelectedAddress()}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Search for an address"}
                variant="outlined"
                fullWidth
                size="small"
                onChange={(e) => {
                  if (e.target.value?.trim()) {
                    setSearchTerm(e.target.value.trim());
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            onChange={(event, value) => {
              debugger;
              setSelectedAddress(value);
              selectNewShippingAddressFromDpid(value.dpid, value.isPostal);
            }}
          />
        </Grid>
      </Grid>
      <Grid container item xs={4}>
        <FormControl
          variant="outlined"
          size="small"
          className={classes.formControl}
        >
          <InputLabel htmlFor="outlined-age-native-simple">
            Generate Return Label
          </InputLabel>
          <Select
            displayEmpty
            native
            label="Generate Return Label"
            value={returnLabel}
            onChange={(e) => setReturnLabel(e.target.value)}
            inputProps={{
              name: "GenerateReturnLabel",
              id: "outlined-age-native-simple",
            }}
          >
            <option value={0}>No return label</option>
            <option value={6}>6</option>
            <option value={12}>12</option>
            <option value={18}>18</option>
            <option value={24}>24</option>
            <option value={36}>36</option>
          </Select>
        </FormControl>
      </Grid>
    </div>
  );
};

export default ShippingDetails;
