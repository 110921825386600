import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useCustomer } from "../../../context/customer/CustomerContext";
import "./customerPaymentCard.scss";
import PaymentIcon from "@material-ui/icons/Payment";
import ManagePaymentMethodModal from "../ManagePaymentMethodModal/ManagePaymentMethodModal";

const CustomerPaymentCard = ({
  setOpenNewCardModal,
  isCheckout,
  setCheckoutPayment,
}) => {
  const { customer, firestoreCustomer, paymentMethods } = useCustomer();
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [showManagePaymentMethods, setShowManagePaymentMethods] = useState(
    false
  );
  useEffect(() => {
    selectDefaultPaymentMethod();
  }, [paymentMethods]);

  useEffect(() => {
    if (isCheckout) {
      setCheckoutPayment(selectedPayment);
    }
  }, [selectedPayment]);

  const selectDefaultPaymentMethod = () => {
    if (paymentMethods && paymentMethods.length > 0) {
      if (customer?.defaultPaymentMethod) {
        const defaultPaymentMethod = paymentMethods.find(
          (p) => p.id === customer.defaultPaymentMethod
        );
        if (defaultPaymentMethod) {
          setSelectedPayment(defaultPaymentMethod);
        } else {
          setSelectedPayment(paymentMethods[0]);
        }
      } else {
        setSelectedPayment(paymentMethods[0]);
      }
    } else {
      setSelectedPayment(null);
    }
  };

  return (
    <Card>
      {showManagePaymentMethods && (
        <ManagePaymentMethodModal
          paymentMethods={paymentMethods}
          open={showManagePaymentMethods}
          handleClose={() => setShowManagePaymentMethods(false)}
          selectedPayment={selectedPayment}
          setSelectedPayment={setSelectedPayment}
        />
      )}
      <CardContent>
        <Typography variant="h6">Payment Method</Typography>

        {selectedPayment ? (
          <div className="selected-payment-container">
            <div>
              <PaymentIcon />
            </div>
            <div className="selected-payment-details">
              <Typography variant="body2">{`**** ${selectedPayment?.card?.last4}`}</Typography>
              <Typography variant="body2">{`EXP ${
                selectedPayment?.card?.exp_month < 10
                  ? "0" + selectedPayment?.card?.exp_month
                  : selectedPayment?.card?.exp_month
              }/${selectedPayment?.card?.exp_year}`}</Typography>
              <Typography variant="body2">
                {selectedPayment?.billing_details?.name}
              </Typography>
            </div>
          </div>
        ) : (
          <div className="no-selected-payment-container">
            <Typography variant="body2">{`No Payment Method Selected`}</Typography>
          </div>
        )}
      </CardContent>
      <CardActions>
        {firestoreCustomer ? (
          <>
            <Button
              color="primary"
              size="small"
              onClick={() => setOpenNewCardModal(true)}
            >
              Add new card
            </Button>
            {paymentMethods.length > 0 && (
              <Button
                color="primary"
                size="small"
                onClick={() => setShowManagePaymentMethods(true)}
              >
                Manage
              </Button>
            )}
          </>
        ) : (
          <Typography variant="body2">
            WIP: Customer hasn't been migrated yet...
          </Typography>
        )}
      </CardActions>
    </Card>
  );
};

export default CustomerPaymentCard;
