import { TableCell, TableRow, TextField } from "@material-ui/core";
import React from "react";

const ProductDetailsStockRow = ({
  product,
  handleUpdateLocalProduct,
  index,
}) => {
  return (
    <TableRow
      className={` ${
        index % 2 === 0 ? "products-table-even-row" : "products-table-odd-row"
      } 
         ${!product.isActive ? "products-table-inactive-row" : ""}`}
    >
      <TableCell className={`products-table-cell`}>
        <TextField
          size="small"
          value={product?.productStock?.packQuantity || ""}
          fullWidth
          onChange={(e) => {
            handleUpdateLocalProduct(
              "productStock",
              { ...product?.productStock, packQuantity: e.target.value },
              product?.id
            );
          }}
          InputLabelProps={{ shrink: true }}
        />
      </TableCell>
    </TableRow>
  );
};

export default ProductDetailsStockRow;
