import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import "../Shared/storeOrders.scss";
import "material-icons";
import { setSnackBarProps } from "../../reduxActions/snackbar";
import MemoizedDelayedTableBody from "./MemoizedDelayedTableBody";
import { handleCheckboxClick, EnhancedTableToolbar } from "../Shared/Table";
import DelayedOrderPopUp from "./DelayedOrderPopUp";
import Footer from "../Footer/Footer";
import { PacmanLoader } from "react-spinners";

//----------------------------------------

const headCells = [
  { id: "ticket", disablePadding: true, label: "Ticket" },
  { id: "orderNumber", disablePadding: true, label: "Order#" },
  { id: "packageNo", disablePadding: true, label: "Package#" },
  { id: "customerFirstName", disablePadding: false, label: "First Name" },
  { id: "customerLastName", disablePadding: false, label: "Last Name" },
  { id: "shippingAddress", disablePadding: false, label: "Destination" },
  { id: "boxSize", disablePadding: false, label: "Box Size" },
  { id: "timeShipped", disablePadding: false, label: "Time Shipped" },
  { id: "hoursDelayed", disablePadding: false, label: "Hours Delayed" },
  { id: "isNorth", disablePadding: false, label: "Island" },
  { id: "status", disablePadding: false, label: "Package Status" },
  { id: "courier", disablePadding: false, label: "Courier" },
  { id: "scanOut", disablePadding: false, label: "Scan" },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const getCell = (headCell) => {
    return (
      <TableCell
        key={headCell.id}
        align={"left"}
        sortDirection={orderBy === headCell.id ? order : false}
      >
        {headCell.id !== "orderItem" &&
        headCell.id !== "shippingAddress" &&
        headCell.id !== "buttons" ? (
          <TableSortLabel
            active={orderBy === headCell.id}
            direction={orderBy === headCell.id ? order : "asc"}
            onClick={createSortHandler(headCell.id)}
          >
            {headCell.label}
            {orderBy === headCell.id ? (
              <span className="visuallyHidden">
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </span>
            ) : null}
          </TableSortLabel>
        ) : (
          headCell.label
        )}
      </TableCell>
    );
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            style={{ color: "white" }}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => getCell(headCell))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const SearchTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
})(TextField);

//----------------------------------------

const DelayedOrdersTable = (props) => {
  const { snackbar, setSnackBarProps, orders, type } = props;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);

  const [openDelayedOrder, setOpenDelayedOrder] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setloading] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredOrders.map((o) => o.order.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    setSelected(handleCheckboxClick(event, name, selected));
  };

  const filterStatus = ["NotSent", "Delivered", "Failed"];
  //Keep search state when order changes
  useEffect(() => {
    if (searchValue === null || searchValue === "") {
      setFilteredOrders(
        orders.filter(
          (delayedOrder) =>
            (delayedOrder.hoursDelayed > 0 &&
              !filterStatus.includes(delayedOrder.status)) ||
            delayedOrder.status === "Disputed" ||
            delayedOrder.fromQuickFix === true
        )
      );
    } else {
      const searchValueLower = searchValue.toLowerCase();
      setFilteredOrders(
        orders.filter(
          (delayedOrder) =>
            delayedOrder.status.toLowerCase() === searchValueLower ||
            delayedOrder.trackingNumber
              .toLowerCase()
              .includes(searchValueLower) ||
            `sc${delayedOrder.order.orderNumber}`.includes(searchValueLower) ||
            `${delayedOrder.order.customerFirstName} ${delayedOrder.order.customerLastName}`
              .toLowerCase()
              .includes(searchValueLower) ||
            `${delayedOrder.order.emailAddress}`.includes(searchValueLower) ||
            `${delayedOrder.order.shippingAddress.phone}`.includes(
              searchValueLower
            ) ||
            (delayedOrder.order.note != null &&
              delayedOrder.order.note.toLowerCase().includes(searchValueLower))
        )
      );
    }
  }, [orders]);

  const handleSearch = async (e) => {
    if (e.key === "Enter") {
      setloading(true);

      //Search filter
      console.time("search");
      if (searchValue !== null && searchValue !== "") {
        try {
          const searchValueLower = searchValue.toLowerCase();
          const { data } = await axios.get(
            `ControlCenter/GetDelayedOrderBySearch/${searchValueLower}/${type}`
          );
          setFilteredOrders(data);
          setloading(false);
        } catch (err) {
          console.log(err);
        }
        console.timeEnd("search");
      } else {
        setFilteredOrders(orders);
      }
    }
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      setFilteredOrders(orders);
    } else {
      const searchValueLower = e.target.value.toLowerCase();
      setFilteredOrders(
        orders.filter(
          (delayedOrder) =>
            delayedOrder.status.toLowerCase() === searchValueLower ||
            delayedOrder.trackingNumber
              .toLowerCase()
              .includes(searchValueLower) ||
            `sc${delayedOrder.order.orderNumber}`.includes(searchValueLower) ||
            `${delayedOrder.order.customerFirstName} ${delayedOrder.order.customerLastName}`
              .toLowerCase()
              .includes(searchValueLower) ||
            `${delayedOrder.order.emailAddress}`.includes(searchValueLower) ||
            `${delayedOrder.order.shippingAddress.phone}`.includes(
              searchValueLower
            ) ||
            (delayedOrder.order.note != null &&
              delayedOrder.order.note.toLowerCase().includes(searchValueLower))
        )
      );
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const exportSelectedOrders = async () => {
    var selectedOrders = [];
    selected.forEach((selectedOrderId) => {
      selectedOrders.push(
        filteredOrders.find((o) => o.order.id === selectedOrderId)
      );
    });
    var exportObjList = await selectedOrders.map((o) => {
      var exportObj = {
        orderNumber: "SC" + o.order.orderNumber,
        Package: o.packageNo,
        courier: o.courier,
        status: o.status,
        tracking: o.trackingNumber,
        boxSize: o.boxSize,
        timeShipped: o.order.orderStatusTime.shipped,
        timeReceived: o.order.timeReceived,
        hoursDelayed: o.hoursDelayed,
        ticketSent: o.ticketSent,
        customer: o.order.customerFullName,
        email: o.order.emailAddress,
        totalPrice: o.order.totalPrice,
        address1: o.order.shippingAddress.address1,
        address2: o.order.shippingAddress.address2,
        city: o.order.shippingAddress.city,
        postcode: o.order.shippingAddress.zip,
      };
      return exportObj;
    });

    axios
      .post("Export/ExportDelayedOrdersToExcel", exportObjList, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Delayed Orders - " + Date.now() + ".xlsx"
        );
        document.body.appendChild(link);
        link.click();
      });
  };

  const getParentTitle = () => {
    if (type === "delays") {
      return "Delayed Orders";
    } else if (type === "disputed") {
      return "Disputed Orders";
    } else if (type === "quickFix") {
      return "QuickFix Orders";
    }
    return "Delayed Orders";
  };

  return (
    <div>
      {openDelayedOrder && (
        <DelayedOrderPopUp
          modalState={openDelayedOrder}
          handleClose={() => {
            setOpenDelayedOrder(false);
            setSelectedOrder(null);
          }}
          orderId={selectedOrder.order.id}
          trackingNumber={selectedOrder.trackingNumber}
          boxSize={selectedOrder.boxSize}
          hoursInTransit={selectedOrder.hoursInTransit}
          fromDisputed={selectedOrder.fromDisputed}
          fromQuickFix={selectedOrder.fromQuickFix}
          setSnackBarProps={setSnackBarProps}
        />
      )}
      <Paper className="paper-style">
        <EnhancedTableToolbar
          numSelected={selected.length}
          setSelected={setSelected}
          selected={selected}
          parent={getParentTitle()}
          exportSelectedOrders={exportSelectedOrders}
          totalRows={filteredOrders.length}
        />
        <hr className="hr-table-style" />
        <SearchTextField
          label="Search"
          variant="outlined"
          className="order-table-search-bar"
          value={searchValue}
          onChange={handleChange}
          onKeyDown={handleSearch}
        />
        <hr className="hr-table-style" />
        <TableContainer className="delays-table-container">
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={filteredOrders.length}
              parent={"DelayedOrders"}
            />
            {!loading && (
              <MemoizedDelayedTableBody
                filteredOrders={filteredOrders}
                order={order}
                orderBy={orderBy}
                parent={"DelayedOrders"}
                selected={selected}
                isSelected={isSelected}
                handleClick={handleClick}
                setOpenDelayedOrder={setOpenDelayedOrder}
                setSelectedOrder={setSelectedOrder}
              />
            )}
          </Table>
        </TableContainer>
      </Paper>
      {loading && (
        <div className="packman-loader">
          <PacmanLoader size={25} color="#4d4d00" loading={true} />
        </div>
      )}
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  snackbar: state.snackbar,
});

export default connect(mapStateToProps, {
  setSnackBarProps,
})(DelayedOrdersTable);
