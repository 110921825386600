import React, { forwardRef, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Checkbox,
    FormControl,
    FormControlLabel
} from "@material-ui/core";
import Axios from "axios"

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} mountOnEnter unmountOnExit />;
});

const SaveToShopifyDialog = (props) => {
    const {
        open,
        handleClose,
        handleContinue,
        makeDefaultShopify,
        setMakeDefaultShopify,
        changeShippingShopify,
        setChangeShippingShopify,
        updatePreferredCourierShopify,
        setUpdatePreferredCourierShopify
    } = props;

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => handleClose()}
            >
                <DialogTitle>{`Update Shopify?`}</DialogTitle>
                <DialogContent>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column"
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={makeDefaultShopify}
                                    name="default-address-shopify-checkbox"
                                    color="primary"
                                    onChange={e => setMakeDefaultShopify(e.target.checked)}
                                />
                            }
                            label="Set as default address for customer"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={changeShippingShopify}
                                    name="shipping-address-shopify-checkbox"
                                    color="primary"
                                    onChange={e => setChangeShippingShopify(e.target.checked)}
                                />
                            }
                            label="Set shipping address for order"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={updatePreferredCourierShopify}
                                    name="preferred-courier-shopify-checkbox"
                                    color="primary"
                                    onChange={e => setUpdatePreferredCourierShopify(e.target.checked)}
                                />
                            }
                            label="Set preferred courier against customer in Shopify"
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="default" onClick={() => handleClose()}>
                        Go Back
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => handleContinue()}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default SaveToShopifyDialog;