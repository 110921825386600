import React, { useEffect, useState } from "react";
import Snacky from "../../Shared/Snacky";
import { Skeleton } from "@material-ui/lab";
import { Button, TextField, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ProductsTable from "./ProductsTable";
import axios from "axios";
import { connect } from "react-redux";
import { setSnackBarProps } from "../../../reduxActions/snackbar";

const initialProduct = {
  name: "",
  productSupplierId: "",
  currency: "NZD",
  supplierCode: "",
  code: "",
  productStock: {
    weight: 0.1,
  },
};

const ProductsDetail = ({
  snackbar,
  setSnackBarProps,
  products,
  setProducts,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [productSuppliers, setProductSuppliers] = useState([]);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [updatedProducts, setUpdatedProducts] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [originalStockAccountCode, setOriginalStockAccountCode] = useState("");
  const [stockAccountCode, setStockAccountCode] = useState("");

  useEffect(() => {
    getAllProductSuppliers();
    getStockAccountCode();
  }, []);

  const getAllProductSuppliers = () => {
    setIsLoading(true);
    axios
      .get(`products/GetProductSuppliers`)
      .then(({ data }) => {
        setProductSuppliers(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  };

  const getStockAccountCode = () => {
    axios
      .get(`systemSettings/GetStockAccountCode`)
      .then(({ data }) => {
        setOriginalStockAccountCode(data.stockAccountCode);
        setStockAccountCode(data.stockAccountCode);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getNextDisplayOrder = () => {
    // If products list is empty, return null or a default value
    if (!products || products.length === 0) {
      return 1;
    }

    // Use reduce to find the highest displayOrder
    return (
      products.reduce((maxOrder, product) => {
        return product.displayOrder > maxOrder
          ? product.displayOrder
          : maxOrder;
      }, products[0].displayOrder) + 1
    );
  };

  const handleCreateBlankProduct = () => {
    const _newProduct = {
      ...initialProduct,
      displayOrder: getNextDisplayOrder(),
    };
    setProducts([_newProduct, ...products]);
    setIsAddingNew(true);
    setHasChanges(true);
  };

  const batchUpdateProductState = (savedProducts) => {
    let productsClone = [...products];
    savedProducts.forEach((product) => {
      productsClone = productsClone.map((p) =>
        p.id === product.id || !p.id ? product : p
      ); // in case p.id is undefined it replaces with the first product from savedProducts list. Api returns the new product first.
    });
    setProducts(productsClone.sort((a, b) => a.displayOrder - b.displayOrder));
  };

  const handleChangeProductStatus = (productId, newStatus) => {
    setIsSaving(true);
    const updatedProduct = products.find((p) => p.id === productId);
    axios
      .post(`Products/BatchSaveProducts`, [ { ...updatedProduct, isActive: newStatus }])
      .then(({ data }) => {
        batchUpdateProductState(data);
        setSnackBarProps(
          "success",
          `Fantastic! The product have been saved.`,
          true
        );
      })
      .catch((err) => {
        console.log(err);
        setSnackBarProps("warning", `Oops! Unable to save product.`, true);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleSaveProducts = () => {
    if (updatedProducts.length > 0) {
      setIsSaving(true);
      axios
        .post(`Products/BatchSaveProducts`, updatedProducts)
        .then(({ data }) => {
          setIsAddingNew(false);
          setHasChanges(false);
          batchUpdateProductState(data);
          setUpdatedProducts([]);
          setSnackBarProps(
            "success",
            `Fantastic! The products have been saved.`,
            true
          );
        })
        .catch((err) => {
          console.log(err);
          setSnackBarProps("warning", `Oops! Unable to save products.`, true);
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };
  const handleUpdateStockAccountCode = () => {
    if (stockAccountCode !== originalStockAccountCode) {
      setIsSaving(true);
      axios
        .post(`SystemSettings/UpdateStockAccountCode`, { stockAccountCode })
        .then(({ data }) => {
          setHasChanges(false);
        })
        .catch((err) => {
          console.log(err);
          setSnackBarProps(
            "warning",
            `Oops! Unable to save account code.`,
            true
          );
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  const handleUpdateProductChangeList = (product) => {
    let existingProduct = updatedProducts.find((p) => p.id === product.id);
    if (existingProduct) {
      setUpdatedProducts(
        updatedProducts.map((p) => (p.id === product.id ? product : p))
      );
    } else {
      setUpdatedProducts([...updatedProducts, product]);
    }
    setHasChanges(true);
  };

  const handleUpdateAllProductsChangeList = (productList) => {
    setUpdatedProducts(productList);
    setHasChanges(true);
  };
  return (
    <>
      <Snacky snackprops={snackbar} setSnackBarProps={setSnackBarProps} />
      <div className="products-root">
        <div>
          {isLoading ? (
            <div className="products-loading-skeleton-container">
              <Skeleton height={50} />
              <Skeleton height={50} />
              <Skeleton height={50} />
            </div>
          ) : (
            <div className="product-results-container">
              <div className="products-actions-container">
                <div>
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Stock Account"
                    onChange={(e) => {
                      setStockAccountCode(e.target.value);
                      setHasChanges(true);
                    }}
                    value={stockAccountCode}
                  />
                </div>
                {!isAddingNew && !isSaving && (
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    onClick={(e) => {
                      handleCreateBlankProduct();
                    }}
                  >
                    Add new product
                  </Button>
                )}
                {!isSaving && hasChanges && (
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={(e) => {
                      handleSaveProducts();
                      handleUpdateStockAccountCode();
                    }}
                  >
                    Save
                  </Button>
                )}
              </div>
              {products.length > 0 && productSuppliers.length > 0 ? (
                <ProductsTable
                  products={products}
                  productSuppliers={productSuppliers}
                  handleUpdateAllProductsChangeList={
                    handleUpdateAllProductsChangeList
                  }
                  handleChangeProductStatus={handleChangeProductStatus}
                  handleUpdateProductChangeList={handleUpdateProductChangeList}
                />
              ) : (
                <div
                  className="producs-paper-container"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 30,
                  }}
                >
                  <Typography>
                    <strong>No Products Found</strong>
                  </Typography>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  snackbar: state.snackbar,
});

export default connect(mapStateToProps, {
  setSnackBarProps,
})(ProductsDetail);
