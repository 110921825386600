import React, { forwardRef } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControlLabel,
    Button,
    Slide,
    Typography
} from "@material-ui/core";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} mountOnEnter unmountOnExit />;
});

const ConfirmDialog = (props) => {
    const {
        open,
        handleClose,
        handleConfirm,
        title,
        contentText
    } = props;

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => handleClose()}
        >
            <DialogTitle>
                <Typography variant="h6">
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography>
                    {contentText}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="default" onClick={() => handleClose()}>
                    Go Back
                </Button>
                <Button variant="contained" color="primary" onClick={() => handleConfirm()}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDialog;