export const convertIdFromGraphql = (graphql_id) => {
  if (!graphql_id) {
    return;
  }
  return graphql_id.substring(graphql_id.lastIndexOf("/") + 1);
};
export const convertIdToShopifyGraphql = (id, type) => {
  if (id && type) {
    return `gid://shopify/${type}/${id}`;
  }
  return;
};
