import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import BusinessDirect from "./BusinessDirect";
import { setSnackBarProps } from "../../reduxActions/snackbar";
import {
    Accordion as MuiAccordion,
    AccordionSummary as MuiAccordionSummary,
    AccordionDetails as MuiAccordionDetails,
    Grid,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    Card,
    CardContent,
    CardActions,
    CircularProgress
} from "@material-ui/core";
import {
    ExpandMore as ExpandMoreIcon
} from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import Axios from "axios";
import "./BusinessDirect.scss";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { convertTime, convertTimeLocal } from "../Utils/dateTime";

const Accordion = withStyles({
    root: {
        border: "1px solid #dcdef1",
        boxShadow: "none",
        "&:before": {
            display: "none",
        },
        // "&$expanded": {
        //     margin: "auto",
        // },
        color: "white"
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: "#27293d",
        borderBottom: "1px solid #dcdef1",
        marginBottom: -1,
        minHeight: 45,
        "&$expanded": {
            minHeight: 45,
        },
    },
    content: {
        "&$expanded": {
            margin: "12px 0",
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        backgroundColor: "#27293d",
    },
}))(MuiAccordionDetails);

const BusinessDirectHistory = (props) => {
    const {
        companies,
        setOrderToReorder,
        setLineItemsReorder,
        companyName
    } = props;

    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const [loading, setLoading] = useState(false);

    const getRow = (bd) => {
        var lineItems = JSON.parse(bd.jsonLineItem)
        var price = 0;
        var totalCapsules = 0;
        if (lineItems !== undefined || lineItems !== null) {
            lineItems.forEach(item => {
                price += (item.Price * item.Quantity)
                if (item.Sku !== "COMP_Compost_Special" && item.Sku !== "NZM_0_SHIPPING") {
                    totalCapsules += (10 * item.Quantity)
                }
            });
        }
        var dateStringPlaced = bd.datePlaced !== null ? convertTimeLocal(bd.datePlaced) : "";
        
        
        return (
            <TableRow key={`${bd.id}-row`}>
                <TableCell>
                    <ul>
                        {lineItems.map(item => {
                            return (
                                <li key={`${bd.id}-${item.Title}-li`}>{`${item.Title} (x${item.Quantity})`}</li>
                            )
                        })}
                    </ul>
                    {`(${totalCapsules} capsules in total) ${dateStringPlaced}`}
                </TableCell>
                <TableCell>
                    {`$${price.toFixed(2)}`}
                </TableCell>
                <TableCell>
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            setOrderToReorder(bd)
                            setLineItemsReorder(lineItems)
                        }}
                    >
                        Reorder
                    </Button>
                </TableCell>
            </TableRow>
        )
    }

    useEffect(() => {
        if (companyName === null || companyName === "") {
            setLoading(true)
            setFilteredCompanies(companies)
        }
        else if (companies !== null) {
            setLoading(true)
            setFilteredCompanies(companies.filter(c => c.name === companyName))
        }
    }, [companyName])

    useEffect(() => {
        setLoading(false)
    }, [loading])

    if (loading) {
        return <CircularProgress />
    }
    else {
        return (
            <div>
                <Accordion className="business-direct-acordian" expanded={true}>
                    <AccordionSummary id="business-direct-accordian">
                        <Grid container item justify="center" alignItems="center">
                            <Typography variant="h5">ORDER HISTORY</Typography>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails >
                        <Table>
                            <TableBody>
                                {filteredCompanies != null && (
                                    filteredCompanies.map(c => {
                                        var bdOrders = c.businessDirectOrder;
                                        return (
                                            <TableRow>
                                                {bdOrders.length > 0 && (
                                                    <Accordion expanded={true}>
                                                        <AccordionSummary>
                                                            <Typography variant="h6">{c.name}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{ backgroundColor: "#3d3e50" }}>
                                                            <Table className="bd-table">
                                                                <TableHead>
                                                                    <TableCell>Items</TableCell>
                                                                    <TableCell>Price</TableCell>
                                                                    <TableCell>Reorder</TableCell>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {bdOrders.map(bd => {
                                                                        return (
                                                                            getRow(bd)
                                                                        )
                                                                    })}
                                                                </TableBody>
                                                            </Table>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                )}
                                            </TableRow>
                                        )
                                    })
                                )}
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    snackbar: state.snackbar,
});

export default connect(mapStateToProps, {
    setSnackBarProps
})(BusinessDirectHistory);