import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React, { useState, useEffect } from "react";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { getSubscriptionFrequencyList } from "../../../repository/subscriptions";
import "./subscriptionFrequencyModal.scss";
const SubscriptionFrequencyModal = ({
  open,
  handleClose,
  handleEditFrequency,
  subscription,
}) => {
  const [subscriptionFrequency, setSubscriptionFrequency] = useState(
    subscription.subscriptionFrequency || 4
  );
  const [nextShipment, setNextShipment] = useState(
    subscription.nextShipment?.seconds
      ? moment.unix(subscription.nextShipment.seconds).toDate()
      : subscription.nextShipment
  );
  const [nextShipmentError, setNextShipmentError] = useState(null);
  const handleSubmit = async () => {
    if (nextShipment && subscriptionFrequency) {
      await handleEditFrequency(
        subscription,
        nextShipment,
        subscriptionFrequency
      );
      handleClose();
    } else {
      setNextShipmentError("Please enter a valid date.");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"xs"}>
      <DialogTitle id="form-dialog-title">{`Update Frequency and Next Shipment Date`}</DialogTitle>
      <DialogContent>
        <div className={"subscription-frequency-confirmContainer"}>
          <div className={"subscription-frequency-subscriptionOptions"}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="DD/MM/YYYY"
                error={nextShipmentError ? true : false}
                helperText={nextShipmentError ? nextShipmentError : ""}
                id={"nextShipment"}
                label={"Next Shipment *"}
                disableFuture={false}
                disablePast={true}
                minDate={moment().add(1, "days")}
                value={nextShipment ? nextShipment : null}
                size="small"
                onChange={(value) => 
                  setNextShipment(
                    value && value.isValid()
                      ? new Date(value)
                      : null
                  )
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
            <FormControl variant="filled" size="small">
              <Select
                variant="outlined"
                //value={subscription.subscriptionFrequency || 4}
                value={subscriptionFrequency || 4}
                onChange={(e) => {
                  console.log(e.target.value);
                  console.log(e.target);
                  debugger;
                  setSubscriptionFrequency(e.target.value);
                }}
                label="Frequency *"
              >
                {getSubscriptionFrequencyList().map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className={"subscription-frequency-actionContainer"}>
            <Button onClick={handleClose} color={"secondary"} size="small">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              color={"primary"}
              size="small"
              variant="contained"
            >
              Save
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SubscriptionFrequencyModal;
