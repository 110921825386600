import React, { useState, useRef, Fragment } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { useHistory } from "react-router-dom";

const options = ["Go to Stock Page", "Reattempt", "Send to failed"];

const GroupedButtonOutOfStock = (props) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [buttonDisabled, setbuttonDisabled] = useState(false);
  const { updateOutOfStock, outOfStock, missingStock } = props;

  const handleMenuItemClick = (event, index) => {
    setbuttonDisabled(true);
    setTimeout(() => {
      setbuttonDisabled(false);
    }, 5000);
    if (options[index] === "Go to Stock Page") {
      GoToStockTab();
    } else {
      updateOutOfStock(options[index]);
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const GoToStockTab = () => {
    history.push({
      pathname: "/StockLevels",
      state: true,
    });
  };

  return (
    <Fragment>
      <ButtonGroup
        variant="contained"
        color={outOfStock === 1 ? "secondary" : "primary"}
        ref={anchorRef}
        aria-label="split button"
        disabled={buttonDisabled}
      >
        <Button>{missingStock}</Button>
        <Button
          color={outOfStock === 1 ? "secondary" : "primary"}
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default GroupedButtonOutOfStock;
