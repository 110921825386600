import {
  CircularProgress,
  InputAdornment,
  InputBase,
  makeStyles,
  TextField,
} from "@material-ui/core";
import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import "./customerFinder.scss";
import { Autocomplete } from "@material-ui/lab";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useCustomer } from "../../../context/customer/CustomerContext";
import { useHistory } from "react-router-dom";
function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const CustomerFinder = () => {
  const { findCustomerDetails } = useCustomer();
  const history = useHistory()
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const loading = open && options.length === 0;
  const [loadingOptions, setLoadingOptions] = useState(false);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleSearchTerm = () => {
    if (searchTerm && searchTerm.length >= 3) {
      setLoadingOptions(true);
      axios.get(`AdminPanel/FindCustomerByKeyword?keyword=${searchTerm.toLowerCase()}`)
        .then(({ data }) => {
          // var options = [...data.customers, ...data.orders]
          var options = [...data.customers]
          setOptions(options)
        }).finally(() => {
          setLoadingOptions(false);
        })
    }
  }
  return (
    <Autocomplete
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={async (event, newValue) => {
        await findCustomerDetails(newValue);
        history.push("/AdminPanel/Customers");
      }}
      fullWidth
      getOptionSelected={(option, value) => {
        if (option.firstName) {
          return option.firstName === value.firstName;
        }
        else {
          return option.orderNumber === value.orderNumber;
        }
      }}
      getOptionLabel={(option) => {
        if (option.firstName) {
          return option.firstName + " " + option.lastName + " - " + option.email;
        } else {
          return "SC" + option.orderNumber;
        }

      }}
      options={options}
      loading={loading && loadingOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          className="searchInput"
          size="small"
          onChange={(e) => setSearchTerm(e.target.value?.trim())}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSearchTerm()
            }
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default CustomerFinder;
