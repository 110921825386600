import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { useState } from "react";
import CurrencyFormatter from "../../Shared/CurrencyFormatter";
import "./abandonedCheckoutsTable.scss";
import moment from "moment";
const Row = ({ checkout, handleCheckbox, selectedCheckout }) => {
  const [open, setOpen] = useState(false);

  const toggleRow = () => {
    setOpen(!open);
    if (!open) {
      handleCheckbox(checkout.id);
    } else {
      handleCheckbox(null);
    }
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell className="checkout-outer-cell ">
          <Checkbox
            onChange={(event) => {
              if (event.target.checked) {
                handleCheckbox(checkout.id);
              } else {
                handleCheckbox(null);
              }
            }}
            color="primary"
            checked={selectedCheckout === checkout.id}
          />
        </TableCell>

        <TableCell
          component="th"
          scope="row"
          onClick={toggleRow}
          className="checkout-outer-cell "
        >
          <Typography variant="body1">
            <CurrencyFormatter useDollar amount={checkout.amount / 100} />
          </Typography>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          onClick={toggleRow}
          className="checkout-outer-cell "
        >
          <Typography variant="body1">
            {moment.unix(checkout.created).format("DD/MM/YYYY HH:mm")}
          </Typography>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          onClick={toggleRow}
          className="checkout-outer-cell "
        >
          <Typography variant="body1">{checkout.metadata["email"]}</Typography>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          onClick={toggleRow}
          className="checkout-outer-cell "
        >
          <Typography variant="body1">
            {checkout.metadata["first_name"] +
              " " +
              checkout.metadata["last_name"]}
          </Typography>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          onClick={toggleRow}
          className="checkout-outer-cell "
        >
          <Typography variant="body1">{checkout.metadata["items"]}</Typography>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const AbandonedCheckoutsTable = ({ checkouts, isLoading }) => {
  const [selectedCheckout, setSelectedCheckout] = useState(null);
  const handleCheckbox = (id) => {
    setSelectedCheckout(id);
  };

  return (
    <div>
      {isLoading ? (
        <Skeleton variant="rect" height={300} />
      ) : (
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Date Created</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Items</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {checkouts?.length &&
                checkouts.map((checkout) => (
                  <Row
                    key={checkout.id}
                    checkout={checkout}
                    handleCheckbox={handleCheckbox}
                    selectedCheckout={selectedCheckout}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default AbandonedCheckoutsTable;
