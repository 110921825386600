import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
  TextField,
  Grid,
  Button
} from "@material-ui/core"
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon
} from "@material-ui/icons";
import "../Control/control.scss";
import "../Shared/storeOrders.scss";
import MemoizedClaimsTableBody from './MemoizedClaimsTableBody\'';
import ClaimsPopup from './ClaimsComponents/ClaimsPopup';
import axios from "axios";
import Snacky from '../Shared/Snacky';
import { connect } from "react-redux";
import { setSnackBarProps } from "../../reduxActions/snackbar";
import Footer from '../Footer/Footer';

const headCells = [
  { id: "orderNumber", disablePadding: true, label: "Order#" },
  { id: "customerFirstName", disablePadding: false, label: "First Name" },
  { id: "customerLastName", disablePadding: false, label: "Last Name" },
  { id: "shippingAddress", disablePadding: false, label: "Destination" },
  { id: "documentsCreated", disablePadding: false, label: "Document Created" },
  { id: "status", disablePadding: false, label: "Claim Status" },
  { id: "courier", disablePadding: false, label: "Courier" }
];

const ClaimsTableHead = (props) => {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

ClaimsTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          fontSize: "1.5em",
          color: "white",
          backgroundColor: "#206020",
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
    textAlign: "center",
  },
}));

const ClaimsTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, totalRows, exportSelectedOrders } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Grid container>
          <Grid item xs={12} md={11} lg={11}>
            <Typography
              className={classes.title}
              color="inherit"
              variant="subtitle1"
              component="div"
              align='center'
            >
              {numSelected} selected
            </Typography>
          </Grid>
          <Grid item xs={12} md={1} lg={1}>
            <Button color="primary" variant="contained" size="small" onClick={exportSelectedOrders} >
              Export Selected
            </Button>
          </Grid>
        </Grid>
      ) : numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Grid container>
          <Grid item xs={4} md={4} lg={4} alignItems="center">
            <Typography
              className={classes.title}
              color="inherit"
              variant="h6"
              component="div"
              align='center'
            >
              {totalRows} in total
            </Typography>
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <Typography
              className={classes.title}
              variant="h5"
              id="tableTitle"
              component="div"
            >
              Claims
            </Typography>
          </Grid>
          <Grid item xs={4} md={4} lg={4}></Grid>
        </Grid>
      )}
    </Toolbar>
  );
};

ClaimsTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const SearchTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
})(TextField);

//----------------------------

const ClaimsTable = (props) => {
  const {
    claims,
    refreshClaims,
    snackbar,
    setSnackBarProps
  } = props

  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [claimsPopup, setClaimsPopup] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState(null);

  const [searchValue, setSearchValue] = useState("");
  const [filteredClaims, setFilteredClaims] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredClaims.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const exportSelectedOrders = async () => {
    var claimsToExport = [];
    selected.forEach(selectedClaimId => {
      claimsToExport.push(filteredClaims.find(c => c.id === selectedClaimId));
    });
    var exportObjList = await claimsToExport.map(c => {
      var exportObj = {
        "Order Number": "SC"+c.orderNumber,
        "Customer": `${c.customerFirstName} ${c.customerLastName}`,
        "Email": c.email,
        "Total Price": c.totalPrice,
        "Tracking Number": c.trackingNumber,
        "Address 1": c.shippingAddress.address1,
        "Address 2": c.shippingAddress.address2,
        "City": c.shippingAddress.city,
        "Postcode": c.shippingAddress.zip,
        "Time Shipped": c.timeShipped,
        "Courier": c.courier
      }
      return exportObj;
    })

    axios.post("Export/ExportDynamicObjectsToExcel", exportObjList, { responseType: "blob" } )
      .then((response) => {
       const url = window.URL.createObjectURL(new Blob([response.data]));
       const link = document.createElement("a");
       link.href = url;
       link.setAttribute("download", "Claims - "+Date.now()+".xlsx"); 
       document.body.appendChild(link);
       link.click();
    });
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleSearch = async (e) => {
    if (e.key === "Enter") {
      setLoading(true);

      //Search filter
      console.time("search");
      if (searchValue !== null && searchValue !== "") {
        try {
          const searchValueLower = searchValue.toLowerCase();
          const { data } = await axios.get(`ControlCenter/GetClaimBySearch/${searchValueLower}`);
          setFilteredClaims(data);
          setLoading(false);
        } catch (err) {
          console.log(err);
        }
        console.timeEnd("search");
      } else {
        setFilteredClaims(claims);
      }
    }
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      setFilteredClaims(claims.filter((claim) => !filterStatus.includes(claim.status)))
    } else {
      const searchValueLower = e.target.value.toLowerCase();
      setFilteredClaims(
        claims.filter(
          (claim) => {
            return (
              claim.status.toLowerCase() === searchValueLower ||
              claim.trackingNumber.toLowerCase().includes(searchValueLower) ||
              `sc${claim.orderNumber}`.includes(searchValueLower) ||
              `${claim.customerFirstName} ${claim.customerLastName}`.toLowerCase().includes(searchValueLower)
            )
          }
        )
      );
    }
  };

  const filterStatus = ["DISMISSED", "PAID"];
  //Keep search state when order changes
  useEffect(() => {
    if (searchValue === null || searchValue === "") {
      setFilteredClaims(claims.filter((claim) => !filterStatus.includes(claim.status)))
    } else {
      const searchValueLower = searchValue.toLowerCase();
      setFilteredClaims(
        claims.filter(
          (claim) =>
            claim.status.toLowerCase() === searchValueLower ||
            claim.trackingNumber.toLowerCase().includes(searchValueLower) ||
            `sc${claim.orderNumber}`.includes(searchValueLower) ||
            `${claim.customerFirstName} ${claim.customerLastName}`.toLowerCase().includes(searchValueLower)
        )
      )
    }
  }, [claims]);

  return (
    <div className={classes.root}>
      <Snacky snackprops={snackbar} setSnackBarProps={setSnackBarProps} />
      {claimsPopup && (
        <ClaimsPopup
          modalState={claimsPopup}
          handleClose={() => {
            setClaimsPopup(false)
            setSelectedClaim(null)
          }}
          selectedClaim={selectedClaim}
          refreshClaims={refreshClaims}
          setSnackBarProps={setSnackBarProps}
        />
      )}
      <Paper className={"paper-style"}>
        <ClaimsTableToolbar numSelected={selected.length} totalRows={filteredClaims.length} exportSelectedOrders={exportSelectedOrders} />
        <hr className="hr-table-style" />
        <SearchTextField
          label="Search"
          variant="outlined"
          className="order-table-search-bar"
          value={searchValue}
          onChange={handleChange}
          onKeyDown={handleSearch}
        />
        <hr className="hr-table-style" />
        <TableContainer className="delays-table-container">
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <ClaimsTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={filteredClaims.length}
            />
            <MemoizedClaimsTableBody
              order={order}
              orderBy={orderBy}
              isSelected={isSelected}
              handleClick={handleClick}
              claims={filteredClaims}
              setClaimsPopup={setClaimsPopup}
              setSelectedClaim={setSelectedClaim}
              selected={selected}
            />
          </Table>
        </TableContainer>
      </Paper>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => ({
  snackbar: state.snackbar
});

export default connect(mapStateToProps, {
  setSnackBarProps
})(ClaimsTable);