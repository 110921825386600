import React, { forwardRef, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { connect } from "react-redux";
import { setAssigneeForChunk } from "../../reduxActions/order";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide direction="up" ref={ref} {...props} mountOnEnter unmountOnExit />
  );
});

const AssigneeDialog = (props) => {
  const { open, handleClose, chunkNumber, setAssigneeForChunk } = props;

  const [assignee, setAssignee] = useState(
    localStorage.getItem("assignee") || ""
  );

  useEffect(() => {
    localStorage.setItem("assignee", assignee);
  }, [assignee]);

  const handleContinue = () => {
    setAssigneeForChunk(chunkNumber, assignee);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose()}
      >
        <DialogTitle>{`Enter Assignee Name`}</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Autocomplete
              value={assignee}
              freeSolo
              autoSelect
              onChange={(event, newValue, reason) => {
                console.log(reason, newValue);
                setAssignee(newValue);
              }}
              options={[
                "Nate Palmer",
                "Kirk Powys",
                "Joshua Browne",
                "Cameron Houston",
              ]}
              renderInput={(params) => (
                <TextField {...params} color="primary" label="Assignee Name" />
              )}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="default"
            onClick={() => handleClose()}
          >
            Go Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleContinue()}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { setAssigneeForChunk })(
  AssigneeDialog
);
