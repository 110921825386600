import React, { useEffect, useState } from "react";
import AdminLayout from "../AdminLayout/AdminLayout";
import SubscriptionsTable from "../SubscriptionsTable/SubscriptionsTable";
import AutoshipDeleteModal from "../AutoshipDeleteModal/AutoshipDeleteModal";
import "./autoship.scss";
import {
  findSubscriptionsByShopifyCustomerId,
  updateSubscriptionAddress,
  updateSubscriptionFrequency,
  updateSubscriptionLineItems,
  updateSubscriptionPayment,
  updateSubscriptionStatus,
  searchSubscriptions,
  processSubscriptionOrder,
  getNumberOfSubscriptionsWithError,
  updateSubscriptionIsRural,
} from "../../../repository/subscriptions";
import { Snackbar, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import SubscriptionsFilter from "../SubscriptionsFilter/SubscriptionsFilter";

const Autoship = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [messageDisplay, setMessageDisplay] = useState(null);
  const [subscriptionFilter, setSubscriptionFilter] = useState({
    status: "ACTIVE",
  });
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [numOfSubscriptionsWithError, setNumOfSubscriptionsWithError] =
    useState(0);
  const [justProccessedSubscriptionId, setJustProccessedSubscriptionId] =
    useState(null);

  useEffect(() => {
    searchAllSubscriptions(subscriptionFilter);
  }, [subscriptionFilter]);

  const searchAllSubscriptions = async (subscriptionFilter) => {
    const subscriptions = await searchSubscriptions(subscriptionFilter);
    setSubscriptions(subscriptions);
    setNumOfSubscriptionsWithError(
      getNumberOfSubscriptionsWithError(subscriptions)
    );
  };

  const handleProcessSubscriptionOrder = async (subscription) => {
    setIsProcessing(true);
    const _subscription = await processSubscriptionOrder(subscription);
    setJustProccessedSubscriptionId(_subscription.id);
    handleUpdateSubscriptionList(_subscription, true, "PROCESS_ORDER");
    setIsProcessing(false);
  };

  const handleDeleteSubscriptionOrder = (subscription) => {
    setSelectedSubscription(subscription);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setSelectedSubscription(null);
    setOpenDeleteModal(false);
  };

  const handleSubmitDeleteModal = async () => {
    setIsProcessing(true);
    const _subscription = await updateSubscriptionStatus(
      selectedSubscription,
      "DELETED"
    );
    handleUpdateSubscriptionList(_subscription, true);
    handleCloseDeleteModal();
    setIsProcessing(false);
  };

  const handleUpdateStatus = async (subscription, status) => {
    const _subscription = await updateSubscriptionStatus(subscription, status);
    handleUpdateSubscriptionList(_subscription);
  };

  const handleUpdateSubscriptionList = (
    _subscription,
    forceRefreshAll,
    action
  ) => {
    if (_subscription) {
      if (forceRefreshAll) {
        searchAllSubscriptions(subscriptionFilter);
      } else {
        setSubscriptions((subscriptions) =>
          subscriptions.map((s) =>
            s.id === _subscription.id ? _subscription : s
          )
        );
      }

      setMessageDisplay({
        message: "Autoship has been updated.",
        hasError: false,
      });
    } else {
      setMessageDisplay({
        message: "Unable to update your Autoship.",
        hasError: true,
      });
    }
  };

  const handleUpdateAddress = async (subscription, address) => {
    const _subscription = await updateSubscriptionAddress(
      subscription,
      address
    );
    handleUpdateSubscriptionList(_subscription);
  };

  const handleUpdatePayment = async (subscription, paymentId) => {
    const _subscription = await updateSubscriptionPayment(
      subscription,
      paymentId
    );
    handleUpdateSubscriptionList(_subscription);
  };

  const handleQuantityChange = async (subscription, lineItem, newQuantity) => {
    if (lineItem) {
      setIsUpdating(true);
      if (newQuantity === 0) {
        const _subscription = await updateSubscriptionLineItems(
          subscription,
          subscription.lineItems.filter((item) => item.sku !== lineItem.sku)
        );
        handleUpdateSubscriptionList(_subscription);
        setIsUpdating(false);
      } else {
        lineItem.quantity = newQuantity;
        const _subscription = await updateSubscriptionLineItems(
          subscription,
          subscription.lineItems.map((item) =>
            item.sky === lineItem.sku ? lineItem : item
          )
        );
        handleUpdateSubscriptionList(_subscription);
        setIsUpdating(false);
      }
    }
  };

  const handleNewProduct = async (
    subscription,
    selectedProduct,
    newVariant,
    isAddingNewProduct
  ) => {
    let lineItems = [];

    if (isAddingNewProduct) {
      let _lineItems = [...subscription.lineItems];
      lineItems = [
        {
          sku: newVariant.sku,
          productTitle: newVariant.productTitle,
          quantity: 1,
          price: newVariant.price.toString() || "0",
          productId: newVariant.productId,
          variantId: newVariant.variantId,
          variantTitle: newVariant.variantTitle,
          imageSrc: newVariant.productImage,
        },
        ..._lineItems,
      ];
    } else {
      lineItems = subscription.lineItems.map((item) =>
        item.sku === selectedProduct.sku
          ? {
              sku: newVariant.sku,
              productTitle: newVariant.productTitle,
              quantity: selectedProduct.quantity,
              price: newVariant.price.toString() || "0",
              productId: newVariant.productId,
              variantId: newVariant.variantId,
              variantTitle: newVariant.variantTitle,
              imageSrc: newVariant.productImage,
            }
          : item
      );
    }
    if (lineItems.length) {
      const _subscription = await updateSubscriptionLineItems(
        subscription,
        lineItems
      );
      handleUpdateSubscriptionList(_subscription);
    }
  };

  const handleRemoveItem = async (subscription, lineItem) => {
    if (lineItem) {
      const _subscription = await updateSubscriptionLineItems(
        subscription,
        subscription.lineItems.filter((item) => item.sku !== lineItem.sku)
      );
      handleUpdateSubscriptionList(_subscription);
    }
  };
  const handleEditFrequency = async (
    subscription,
    nextShipment,
    subscriptionFrequency
  ) => {
    if (subscription) {
      const _subscription = await updateSubscriptionFrequency(
        subscription,
        nextShipment,
        subscriptionFrequency
      );
      handleUpdateSubscriptionList(_subscription);
    }
  };

  const handleSetIsRural = async (subscription, isRural) => {
    if (subscription) {
      const _subscription = await updateSubscriptionIsRural(
        subscription.id,
        isRural
      );
      handleUpdateSubscriptionList(_subscription);
    }
  };

  return (
    <AdminLayout>
      <div className="autoship-root">
        <div className="autoship-header-container">
          <div className="autoship-name-container">
            <Typography variant="h4">Autoship</Typography>
            <Typography variant="body2">{`${numOfSubscriptionsWithError} of the below autoships have a problem`}</Typography>
          </div>
          <div>
            <SubscriptionsFilter
              filter={subscriptionFilter}
              setFilter={setSubscriptionFilter}
            />
          </div>
        </div>
        <div className="autoship-counter-container">
          <Typography>{`${subscriptions?.length} records found`}</Typography>
        </div>
        <div className="autoship-table-results-container">
          {openDeleteModal && selectedSubscription && (
            <AutoshipDeleteModal
              open={openDeleteModal}
              handleClose={handleCloseDeleteModal}
              handleSubmit={handleSubmitDeleteModal}
              subscription={selectedSubscription}
            />
          )}
          <SubscriptionsTable
            subscriptions={subscriptions}
            paymentMethods={[]}
            handleUpdateStatus={handleUpdateStatus}
            handleUpdateAddress={handleUpdateAddress}
            handleUpdatePayment={handleUpdatePayment}
            handleQuantityChange={handleQuantityChange}
            handleSetIsRural={handleSetIsRural}
            isUpdating={isUpdating}
            handleNewProduct={handleNewProduct}
            handleRemoveItem={handleRemoveItem}
            handleEditFrequency={handleEditFrequency}
            handleProcessSubscriptionOrder={handleProcessSubscriptionOrder}
            deleteSubscriptionOrder={handleDeleteSubscriptionOrder}
            isGlobalPage={true}
            isProcessing={isProcessing}
            justProccessedSubscriptionId={justProccessedSubscriptionId}
          />
        </div>
      </div>
      {messageDisplay && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={messageDisplay}
          autoHideDuration={5000}
          onClose={() => setMessageDisplay(null)}
        >
          {messageDisplay.hasError ? (
            <Alert
              onClose={() => setMessageDisplay(null)}
              variant="filled"
              severity="error"
            >
              {messageDisplay.message}
            </Alert>
          ) : (
            <Alert
              onClose={() => setMessageDisplay(null)}
              variant="filled"
              severity="success"
            >
              {messageDisplay.message}
            </Alert>
          )}
        </Snackbar>
      )}
    </AdminLayout>
  );
};

export default Autoship;
