import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { connect } from "react-redux";
import {
  bulkRelease,
  bulkLabelsAndComplete,
  printChunk,
  bulkNotes,
  bulkSendHandpack,
  bulkSendStore,
  completeChunk,
  removeUpdatingOrder,
  bulkSendToHoldOrder,
} from "../../reduxActions/order";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Button,
  TextField,
  Grid,
  FormControlLabel,
  CircularProgress,
} from "@material-ui/core";
import IOSSwitch from "../Footer/IOSSwitch";
import {
  Description as DescriptionIcon,
  ErrorOutline as ErrorOutlineIcon,
  Print as PrintIcon,
  PanTool as PanToolIcon,
  CheckCircleOutline,
  PinDrop as PinDropIcon,
  Place as PlaceIcon,
  Person as PersonIcon,
  Check as CheckIcon,
} from "@material-ui/icons";
import BuildIcon from "@material-ui/icons/Build";
import axios from "axios";
import "../Shared/storeOrders.scss";
import Footer from "../Footer/Footer";
import DocumentViewer from "./DocumentViewer";
import EditStoreOrderPopup from "../StoreOrders/EditStoreOrderPopup";
import {
  convertTime,
  formatTime,
  formatTimeDelay,
  convertMySQLToLocalTimeString,
} from "../Utils/dateTime";
import "material-icons";
import PrintLabelPopup from "./PrintLabelPopup";
import Snacky from "../Shared/Snacky";
import { setSnackBarProps } from "../../reduxActions/snackbar";
import { handleCheckboxClick, getComparator, stableSort } from "./Table";
import GroupedButtonActions from "./GroupedButtonActions";
import { PacmanLoader } from "react-spinners";
import ExportColumnsModal from "./ExportColumnsModal";
import {
  formatArrayValueForExport,
  formatColumnValueForExport,
} from "../Utils/domainUtils";
import QuickFixPopup from "../QuickOrderFix/QuickFixPopup";
import AssigneeDialog from "../PackingStation/AssigneeDialog";
import PackingScreenModal from "../PackingStation/PackingScreenModal";

const headCells = [
  { id: "orderNumber", disablePadding: true, label: "Order#" },
  { id: "customerFirstName", disablePadding: false, label: "First Name" },
  { id: "customerLastName", disablePadding: false, label: "Last Name" },
  { id: "shippingAddress", disablePadding: false, label: "Destination" },
  { id: "note", disablePadding: false, label: "Note" },
  { id: "numberOfBoxes", disablePadding: false, label: "Boxes" },
  { id: "orderItem", disablePadding: false, label: "Order Items" },
  { id: "timeReceived", disablePadding: false, label: "Time Received" },
  {
    id: "packingEnd",
    disablePadding: false,
    label: "Time Completed",
  },
  {
    id: "error",
    disablePadding: false,
    label: "Time of Error",
  },
  { id: "label", disablePadding: false, label: "Label" },
  { id: "status", disablePadding: false, label: "Status" },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    parent,
    isChunkSorted,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const getCell = (headCell) => {
    return (
      <TableCell
        key={headCell.id}
        align={"left"}
        sortDirection={orderBy === headCell.id ? order : false}
      >
        {headCell.id !== "buttons" ? (
          <TableSortLabel
            disabled={isChunkSorted && parent !== "All Orders"}
            active={orderBy === headCell.id}
            direction={orderBy === headCell.id ? order : "asc"}
            onClick={createSortHandler(headCell.id)}
          >
            {headCell.label}
            {orderBy === headCell.id ? (
              <span className="visuallyHidden">
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </span>
            ) : null}
          </TableSortLabel>
        ) : (
          headCell.label
        )}
      </TableCell>
    );
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            style={{ color: "white" }}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => {
          if (headCell.id === "packingEnd") {
            if (parent === "All Orders") {
              return getCell(headCell);
            }
          } else if (headCell.id === "error") {
            if (parent === "Failed Orders") {
              return getCell(headCell);
            }
          } else {
            return getCell(headCell);
          }
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  isChunkSorted: PropTypes.bool,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          fontSize: "1.5em",
          color: "white",
          backgroundColor: "#206020",
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
    textAlign: "center",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    selected,
    bulkRelease,
    setSelected,
    parent,
    totalRows,
    bulkNotes,
    bulkLabelsAndComplete,
    handleExportColumns,
    bulkSendToHoldOrder,
    overrideTitle,
  } = props;

  const clearSelected = () => {
    setSelected([]);
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={`${classes.title} mobile-table-header`}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Grid container>
          <Grid item xs={4} className="table-header-grid-items">
            <Typography
              className={`${classes.title} mobile-table-header`}
              color="inherit"
              variant="h6"
              component="div"
            >
              {totalRows} in total
            </Typography>
          </Grid>
          <Grid item xs={4} className="table-header-grid-items">
            <Typography
              className={`${classes.title} mobile-table-header`}
              variant="h5"
              id="tableTitle"
              component="div"
            >
              {overrideTitle ? overrideTitle : parent}
            </Typography>
          </Grid>
        </Grid>
      )}

      {numSelected > 0 ? (
        <GroupedButtonActions
          selected={selected}
          clearSelected={clearSelected}
          handleExportColumns={handleExportColumns}
        />
      ) : null}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  overrideTitle: PropTypes.string.isRequired,
};

//---------------------------
const SearchTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
})(TextField);

export const OrderStatusCell = (props) => {
  const { order, hpMode, orderUpdating, releaseOrder, setActiveTab } = props;

  if (orderUpdating) {
    return (
      <Button variant="contained" className="button-processing">
        Loading
      </Button>
    );
  }

  if (
    order.status === 0 &&
    (order.courierOrder !== null || order.courierOrder !== undefined) &&
    order.courierOrder.driveId != null
  ) {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={(e) => {
          e.stopPropagation();
          releaseOrder(order.id);
        }}
      >
        Release
      </Button>
    );
  } else if (order.status === 0) {
    return <React.Fragment></React.Fragment>;
  } else if (order.status === 1) {
    return (
      <Button variant="contained" className="button-processing">
        Queued
      </Button>
    );
  } else if (order.status === 2) {
    return (
      <Button variant="contained" className="button-processing">
        {hpMode ? "Note Written" : "Queued"}
      </Button>
    );
  } else if (order.status === 3) {
    return (
      <Button variant="contained" className="button-packing">
        {hpMode ? "Ready for Packing" : "Packing"}
      </Button>
    );
  } else if (order.status === 4) {
    return (
      <div style={{ display: "flex" }}>
        <Button variant="contained" className="button-completed">
          Completed
        </Button>
        {order.handPacked === 1 ? <PanToolIcon fontSize="small" /> : ""}
      </div>
    );
  } else if (order.status === 5) {
    return (
      <div style={{ display: "flex" }}>
        <Button variant="contained" className="button-error">
          No Label
        </Button>
        {order.handPacked === 1 ? <PanToolIcon fontSize="small" /> : ""}
      </div>
    );
  } else if (order.status === 6) {
    return (
      <div style={{ display: "flex" }}>
        <Button variant="contained" className="button-error">
          Handpack
        </Button>
      </div>
    );
  } else if (order.status === 7) {
    return (
      <div style={{ display: "flex" }}>
        <Button variant="contained" className="button-fulfilled">
          Fulfilled
        </Button>
        {order.handPacked === 1 ? <PanToolIcon fontSize="small" /> : ""}
      </div>
    );
  } else if (order.status === 8) {
    return (
      <Button variant="contained" className="button-patrick">
        {`P & L`}
      </Button>
    );
  } else if (order.status === 11) {
    return (
      <Button variant="contained" className="button-error">
        {`On Hold`}
      </Button>
    );
  } else {
    return (
      <Button
        variant="contained"
        className="button-error"
        onClick={() => {
          setActiveTab(5);
        }}
      >
        Error
      </Button>
    );
  }
};

/**
 * Sorts line information in HP mode.
 *
 * @param {Object} a - The first object to compare.
 * @param {Object} b - The second object to compare.
 * @returns {number} - The comparison result.
 */
export function sortLineInformationInHpMode(a, b) {
  function compareNullableValues(a, b) {
    if (a === null && b === null) return 0;
    if (a === null) return -1; // Treat null as smaller
    if (b === null) return 1;
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  }
  // Order by chunk numbers
  const chunkComparison = compareNullableValues(
    a.hpChunkNumber,
    b.hpChunkNumber
  );
  if (chunkComparison !== 0) return chunkComparison;

  // Compare by Courier
  const courierComparison = compareNullableValues(
    a.courierOrder.courier,
    b.courierOrder.courier
  );
  if (courierComparison !== 0) return courierComparison;

  // Compare by QuantitiesDescription
  const quantitiesComparison = compareNullableValues(
    a.courierOrder.quantitiesDescription,
    b.courierOrder.quantitiesDescription
  );
  if (quantitiesComparison !== 0) return quantitiesComparison;

  // Compare by Id in descending order

  return compareNullableValues(b.id, a.id);
}

function MemoizedTableBodyPropsAreEqual(prevState, nextState) {
  return (
    prevState.order === nextState.order &&
    prevState.orderBy === nextState.orderBy &&
    _.isEqual(prevState.selected, nextState.selected) &&
    _.isEqual(prevState.filteredOrders, nextState.filteredOrders) &&
    _.isEqual(prevState.hpMode, nextState.hpMode) &&
    _.isEqual(prevState.ordersUpdating, nextState.ordersUpdating) &&
    _.isEqual(prevState.allProducts, nextState.allProducts)
  );
}

const MemoizedTableBody = React.memo(function MemoizedTableBody(props) {
  const {
    filteredOrders,
    order,
    orderBy,
    selected,
    isSelected,
    setSelected,
    selectAllInChunk,
    setShowOrderPopup,
    setOrderForPopup,
    setShowDocumentViewer,
    releaseOrder,
    handleClick,
    parent,
    setShowPrintLabelPopup,
    setActiveTab,
    ordersUpdating,
    allProducts,
    setShowQuickFixPopup,
    removeUpdatingOrder,
    hpMode,
    printChunk,
    completeChunk,
    handleOpenPackingScreen,
  } = props;

  let { isChunkSorted } = props;

  const [assigneeDialogOpen, setAssigneeDialogOpen] = useState(false);
  const [assigneeDialogChunkNumber, setAssigneeDialogChunkNumber] = useState(0);

  var flavourOrder = {
    P5: 1,
    P7: 2,
    P9: 3,
    P10: 4,
    P12: 5,
    P14: 6,
    PCA: 7,
    PDC: 8,
    COL: 9,
    C5: 10,
    C7: 11,
    C9: 12,
    C10: 13,
    C12: 14,
    C14: 15,
    CDC: 16,
    RT: 17,
    GLM: 18,
    CT: 19,
    Sleeve: 20,
    MOCHA: 21,
    TASTER_1: 22,
  };

  const sortFlavours = (a, b) => {
    if (flavourOrder[a] < flavourOrder[b]) {
      return -1;
    } else if (flavourOrder[a] > flavourOrder[b]) {
      return 1;
    } else {
      return 0;
    }
  };

  const IsHomeCompostableTrialPack = (coffeeTypesQuantityString) => {
    const trialPackString = "C5,C5,C9,C9,C10,C10,C12,C12,C14,C14";
    return coffeeTypesQuantityString === trialPackString;
  };

  const IsHomeCompostableLuckyTrialPack = (coffeeTypesQuantityString) => {
    const trialPackString = "C5,C5,C9,C9,C10,C10,C12,C12,C12,C12";
    return coffeeTypesQuantityString === trialPackString;
  };

  const GetFlavours = (o) => {
    if (o.courierOrder == null) {
      debugger;
    }
    var flavourArr = o.courierOrder.coffeeTypesQuantity.split(",");
    var miscArr = [];

    if (IsHomeCompostableTrialPack(o.courierOrder.coffeeTypesQuantity)) {
      flavourArr = ["HCTrialPack"];
    }

    if (IsHomeCompostableLuckyTrialPack(o.courierOrder.coffeeTypesQuantity)) {
      flavourArr = ["HCLuckyTrialPack"];
    }

    try {
      miscArr = o.courierOrder.miscQuantity.split(",");
    } catch (e) {
      console.log(e);
    }

    flavourArr.sort((a, b) => sortFlavours(a, b));
    miscArr.sort();

    var flavours = {};
    flavourArr.forEach((flavour) => {
      if (flavours.hasOwnProperty(flavour)) {
        flavours[flavour] += 1;
      } else {
        flavours[flavour] = 1;
      }
    });
    miscArr.forEach((miscName) => {
      if (flavours.hasOwnProperty(miscName)) {
        flavours[miscName] += 1;
      } else {
        flavours[miscName] = 1;
      }
    });
    var itemsInDiv = [];

    for (const [key, value] of Object.entries(flavours)) {
      var flavour = key;
      var cssClassName = "";

      var p = allProducts.find((ap) => ap.code === flavour);

      if (!(p == undefined || p == null)) {
        if (p.isCompostable === 1) {
          cssClassName = "item-compost-container";
        } else if (
          flavour === "RT" ||
          flavour === "GLM" ||
          flavour === "HCTrialPack" ||
          flavour === "HCLuckyTrialPack"
        ) {
          cssClassName = "item-return-ticket-container";
        } else if (p.isMiscellaneous) {
          cssClassName = "item-misc-container";
        } else {
          cssClassName = "item-plastic-container";
        }

        itemsInDiv.push(
          <div key={`${o.id}-${key}`} className={`${cssClassName}`}>
            {`${value} x ${flavour} `}
          </div>
        );
      }
    }

    return <React.Fragment>{itemsInDiv.map((item) => item)}</React.Fragment>;
  };

  if (isChunkSorted && parent === "All Orders") {
    if (orderBy === "status") {
      isChunkSorted = true;
    } else {
      isChunkSorted = false;
    }
  }

  const sortedOrders = stableSort(
    filteredOrders,
    isChunkSorted ? sortLineInformationInHpMode : getComparator(order, orderBy)
  );

  let prevRowColor = undefined;
  let prevChunkNumber = undefined;
  return (
    <>
      <AssigneeDialog
        open={assigneeDialogOpen}
        handleClose={() => setAssigneeDialogOpen(false)}
        chunkNumber={assigneeDialogChunkNumber}
      />
      <TableBody className="table-body">
        {sortedOrders.map((o, index) => {
          const isItemSelected = isSelected(o.id);
          const labelId = `enhanced-table-checkbox-${index}`;
          var boxSizeString = "";
          var boxSizeArr = [];
          const insertChunkSeparator =
            isChunkSorted && prevChunkNumber !== o.hpChunkNumber;
          let isChunkSelected = false;
          if (insertChunkSeparator) {
            // Check if all orders in the chunk are selected
            const chunkNumber = o.hpChunkNumber;
            const ordersInChunk = filteredOrders.filter(
              (o) => o.hpChunkNumber === chunkNumber
            );
            isChunkSelected = ordersInChunk.every((o) => isSelected(o.id));
          }

          prevChunkNumber = o.hpChunkNumber;
          const chunkUpdating = ordersUpdating.includes(
            `chunk_${o.hpChunkNumber}`
          );
          const orderUpdating = ordersUpdating.includes(o.id);
          if (o.boxSize !== null) {
            boxSizeArr = o.boxSize.split(",");

            for (var i = 0; i < boxSizeArr.length; i++) {
              if (parseInt(boxSizeArr[i]) <= 6) {
                boxSizeString += "6";
              } else if (parseInt(boxSizeArr[i]) <= 12) {
                boxSizeString += "12";
              } else if (parseInt(boxSizeArr[i]) <= 18) {
                boxSizeString += "18";
              } else if (parseInt(boxSizeArr[i]) <= 24) {
                boxSizeString += "24";
              } else if (parseInt(boxSizeArr[i]) <= 36) {
                boxSizeString += "36";
              }
              if (i < boxSizeArr.length - 1) {
                boxSizeString += ",";
              }
            }
          }

          let rowColor = undefined;
          if (isChunkSorted) {
            if (o.hpChunkNumber === null) {
              rowColor = "rgba(255, 0, 0, 0.1)";
            } else if (
              index > 0 &&
              o.hpChunkNumber === sortedOrders[index - 1].hpChunkNumber
            ) {
              rowColor = prevRowColor;
            } else if (prevRowColor === undefined) {
              rowColor = "rgba(72, 61, 139, 0.3)";
            }

            prevRowColor = rowColor;
          }

          return (
            <>
              {insertChunkSeparator && (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={`chunk-separator-${o.id}`}
                  onClick={() => {
                    if (!chunkUpdating) {
                      handleOpenPackingScreen(o.hpChunkNumber);
                    }
                  }}
                  style={{ background: rowColor }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      style={{ color: "white" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelected(
                          selectAllInChunk(
                            o.hpChunkNumber,
                            !isChunkSelected,
                            selected
                          )
                        );
                      }}
                      checked={isChunkSelected}
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </TableCell>
                  <TableCell colSpan={2} />
                  <TableCell colSpan={parent === "All Orders" ? 9 : 8}>
                    <div className="chunk-separator-container">
                      <div class="chunk-separator-title">
                        <Typography variant="h6" component="div">
                          {o.hpChunkNumber === null
                            ? "Not assigned to a chunk"
                            : `
                          ${
                            o.hpChunkNumberObject?.isBulkPack === 1
                              ? "Bulk "
                              : ""
                          }
                          Chunk ${o.hpChunkNumber}`}
                        </Typography>
                        {o.hpChunkNumberObject &&
                          o.hpChunkNumberObject.noteWriter !== null && (
                            <div>
                              {`Note Writer: ${o.hpChunkNumberObject.noteWriter}`}
                            </div>
                          )}
                      </div>
                      <div class="chunk-separator-body">
                        {o.hpChunkNumberObject && parent === "All Orders" && (
                          <Button variant="contained">
                            <PersonIcon size="small" />
                            {o.hpChunkNumberObject?.assignedTo
                              ? `Packed by: ${o.hpChunkNumberObject.assignedTo}`
                              : "No Packer was Assigned"}
                          </Button>
                        )}
                        {parent !== "Note Queue" && parent !== "All Orders" && (
                          <>
                            <Button
                              variant="contained"
                              disabled={chunkUpdating}
                              onClick={(e) => {
                                e.stopPropagation();
                                setAssigneeDialogChunkNumber(o.hpChunkNumber);
                                setAssigneeDialogOpen(true);
                              }}
                            >
                              <PersonIcon size="small" />
                              {o.hpChunkNumberObject?.assignedTo
                                ? `Packer: ${o.hpChunkNumberObject.assignedTo}`
                                : "Assign Packer"}
                            </Button>
                            <Button
                              variant="contained"
                              disabled={chunkUpdating}
                              onClick={(e) => {
                                e.stopPropagation();
                                printChunk(o.hpChunkNumber);
                              }}
                            >
                              <PrintIcon size="small" />
                              {o.hpChunkNumberObject?.labelsPrinted
                                ? "Reprint"
                                : "Print"}
                            </Button>
                            {o.hpChunkNumberObject?.labelsPrinted && (
                              <Button
                                variant="contained"
                                disabled={chunkUpdating}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  completeChunk(o.hpChunkNumber);
                                }}
                              >
                                <CheckIcon size="small" />
                                Complete
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              )}
              <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={o.id}
                selected={isItemSelected}
                onClick={() => {
                  if (!ordersUpdating.includes(o.id)) {
                    setShowOrderPopup(true);
                    setOrderForPopup(o);
                  }
                }}
                style={{ background: rowColor }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    style={{ color: "white" }}
                    onClick={(event) => {
                      if (!ordersUpdating.includes(o.id)) {
                        handleClick(event, o.id);
                      }
                    }}
                    checked={isItemSelected}
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </TableCell>
                <TableCell>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {`SC${o.orderNumber}`}
                  </div>
                </TableCell>
                <TableCell>{o.customerFirstName}</TableCell>
                <TableCell>{o.customerLastName}</TableCell>
                <TableCell>{`${o.shippingAddress.address2} - ${o.shippingAddress.city}`}</TableCell>
                <TableCell className="memoized-table-cell-note">
                  {o.note}
                </TableCell>
                <TableCell>{`${o.numberOfBoxes} (${boxSizeString})`}</TableCell>
                <TableCell className="memoized-table-cell-order-item">
                  {GetFlavours(o)}
                </TableCell>
                <TableCell>
                  {convertMySQLToLocalTimeString(o.timeReceived).toString()}
                </TableCell>
                {parent === "All Orders" && (
                  <TableCell>
                    {o.packingEnd !== null
                      ? convertMySQLToLocalTimeString(o.packingEnd).toString()
                      : ""}
                  </TableCell>
                )}
                {parent === "Failed Orders" && (
                  <TableCell>
                    {o.error.length > 0
                      ? convertTime(o.error[o.error.length - 1].timestamp)
                      : ""}
                  </TableCell>
                )}
                <TableCell>
                  {o.courierOrder !== null && o.courierOrder.driveId ? (
                    <div style={{ display: "flex" }}>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          if (!ordersUpdating.includes(o.id)) {
                            e.stopPropagation();
                            setShowPrintLabelPopup(true);
                            setOrderForPopup(o);
                          }
                        }}
                        style={{ color: "white" }}
                      >
                        <PrintIcon size="small" />
                      </IconButton>
                      <div
                        className="courier-button-div"
                        onClick={(e) => {
                          if (!ordersUpdating.includes(o.id)) {
                            e.stopPropagation();
                            setOrderForPopup(o);
                            setActiveTab(3);
                            setShowOrderPopup(true);
                          }
                        }}
                      >
                        <div className="courier-provider">
                          {o.courierOrder.courier === 1
                            ? "NZPost"
                            : o.courierOrder.courier === 2
                            ? "Aramex"
                            : "Express"}
                        </div>
                        <div>
                          <DescriptionIcon className="label-button" />
                        </div>
                      </div>
                      {o.checkAddress === 1 && (
                        <IconButton
                          size="small"
                          style={{ color: "#FF5F1F" }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <PlaceIcon size="small" />
                        </IconButton>
                      )}
                      {o.scanOut === 1 && (
                        <div className="scan-out">
                          <CheckCircleOutline />
                        </div>
                      )}
                      {o.status == 7 && (
                        <div
                          className="courier-button-div"
                          onClick={(e) => {
                            e.stopPropagation();
                            setOrderForPopup(o);
                            setShowQuickFixPopup(true);
                          }}
                          style={{ color: "white" }}
                        >
                          <div className="courier-provider">{`Quick Fix`}</div>
                          <div>
                            <BuildIcon className="label-button" />
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <IconButton style={{ color: "red" }}>
                      <ErrorOutlineIcon />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell>
                  <OrderStatusCell
                    order={o}
                    hpMode={hpMode}
                    orderUpdating={orderUpdating || chunkUpdating}
                    releaseOrder={releaseOrder}
                    setActiveTab={setActiveTab}
                  />
                </TableCell>
              </TableRow>
            </>
          );
        })}
      </TableBody>
    </>
  );
}, MemoizedTableBodyPropsAreEqual);

MemoizedTableBody.propTypes = {
  filteredOrders: PropTypes.array.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  selected: PropTypes.array.isRequired,
  isSelected: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  selectAllInChunk: PropTypes.func.isRequired,
  setShowOrderPopup: PropTypes.func.isRequired,
  setOrderForPopup: PropTypes.func.isRequired,
  setShowDocumentViewer: PropTypes.func.isRequired,
  releaseOrder: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  hpMode: PropTypes.bool.isRequired,
  isChunkSorted: PropTypes.bool,
};
//--------------------------

const OrdersTable = (props) => {
  const { snackbar, setSnackBarProps, ordersUpdating, allProducts } = props;
  const location = useLocation();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [selectedIdExport, setSelectedIdExport] = useState([]);
  const [showDocumentViewer, setShowDocumentViewer] = useState(false);
  const [orderForPopup, setOrderForPopup] = useState(null);
  const [showEditOrderPopup, setShowOrderPopup] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [showPackingScreenModal, setShowPackingScreenModal] = useState(false);
  const [activeChunk, setActiveChunk] = useState(-1);

  const [searchValue, setSearchValue] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [directToPopup, setDirectToPopup] = useState(false);
  const [orderNumberFromUrl, setOrderNumberFromUrl] = useState(null);
  const [showPrintLabelPopup, setShowPrintLabelPopup] = useState(false);

  const [loading, setloading] = useState(false);

  const [showExportColumnsModal, setShowExportColumnsModal] = useState(false);
  const [showQuickFixPopup, setShowQuickFixPopup] = useState(false);

  const {
    orders,
    bulkRelease,
    bulkNotes,
    bulkLabelsAndComplete,
    printChunk,
    completeChunk,
    parent,
    overrideTitle,
    bulkSendToHoldOrder,
    isChunkSorted,
    system: { hpMode },
  } = props;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredOrders.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const selectAllInChunk = (chunkNumber, shouldAdd, selected) => {
    let newSelected = Array.from(selected);
    const lineItemsInTheSameChunk = filteredOrders.filter(
      (o) => o.hpChunkNumber === chunkNumber
    );

    if (!shouldAdd) {
      // Deselect all orders with the same hpChunkNumber
      const removeFromSelected = (nameToRemove) => {
        const selectedIndex = newSelected.indexOf(nameToRemove);
        if (selectedIndex === 0) {
          newSelected = newSelected.slice(1);
        } else if (selectedIndex === newSelected.length - 1) {
          newSelected = newSelected.slice(0, -1);
        } else if (selectedIndex > 0) {
          newSelected = newSelected
            .slice(0, selectedIndex)
            .concat(newSelected.slice(selectedIndex + 1));
        }
      };

      for (let i = 0; i < lineItemsInTheSameChunk.length; i++) {
        removeFromSelected(lineItemsInTheSameChunk[i].id);
      }
    } else {
      // Select all the orders with the same hpChunkNumber
      const addToSelected = (nameToAdd) => {
        const selectedIndex = newSelected.indexOf(nameToAdd);
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(nameToAdd);
        }
      };

      for (let i = 0; i < lineItemsInTheSameChunk.length; i++) {
        addToSelected(lineItemsInTheSameChunk[i].id);
      }
    }

    return newSelected;
  };

  const handleSelectDoubleClick = (event, name, selected) => {
    event.stopPropagation();
    const shouldAdd = selected.indexOf(name) >= 0;
    const ordersHpChunkNumber = filteredOrders.find(
      (o) => o.id === name
    ).hpChunkNumber;

    return selectAllInChunk(ordersHpChunkNumber, shouldAdd, selected);
  };

  const handleClick = (event, name) => {
    if (isChunkSorted && event.detail === 2) {
      // event.detail become 2 when a double click is detected
      setSelected(handleSelectDoubleClick(event, name, selected));
    } else {
      setSelected(handleCheckboxClick(event, name, selected));
    }
  };

  const handleOpenPackingScreen = (chunkNumber) => {
    setActiveChunk(chunkNumber);
    setShowPackingScreenModal(true);
  };

  //Keep search state when order changes 12 x P12
  useEffect(() => {
    if (searchValue === null || searchValue === "") {
      setFilteredOrders(orders);
    } else {
      const searchValueLower = searchValue.toLowerCase();
      setFilteredOrders(
        orders.filter(
          (order) =>
            `sc${order.orderNumber}`.includes(searchValueLower) ||
            `${order.customerFirstName} ${order.customerLastName}`
              .toLowerCase()
              .includes(searchValueLower) ||
            `sc${order.emailAddress}`.includes(searchValueLower) ||
            `sc${order.shippingAddress.phone}`.includes(searchValueLower) ||
            `${order.courierOrder.quantitiesDescription}` === searchValue ||
            (order.note != null &&
              order.note.toLowerCase().includes(searchValueLower))
        )
      );
    }
  }, [orders]);

  //Specific Order
  useEffect(() => {
    var arr = location.pathname.split("/");
    if (
      arr[arr.length - 1].length > 0 &&
      arr[arr.length - 2].toLowerCase() === "allorders"
    ) {
      setDirectToPopup(true);
      setOrderNumberFromUrl(arr[arr.length - 1]);
    }
  }, []);

  //default filter for all orders
  useEffect(() => {
    if (parent === "All Orders") {
      setOrder("desc");
      setOrderBy("status");
    }
  }, []);

  useEffect(() => {
    if (directToPopup) {
      try {
        setloading(true);
        axios
          .get(`Orders/GetOrderBySearch/${orderNumberFromUrl}`)
          .then((res) => {
            setFilteredOrders(res.data);
            setOrderForPopup(
              res.data.find(
                (o) => o.orderNumber.toString() === orderNumberFromUrl
              )
            );
            setShowOrderPopup(true);
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            setloading(false);
          });
      } catch (err) {
        console.log(err);
      }
    }
  }, [directToPopup]);

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const releaseOrder = (id) => {
    axios
      .patch(`/Orders/PatchOrderRelease/${id}`, {
        id: id,
        status: 1,
      })
      .then((res) => {
        // getOrders();
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const handleSearch = async (e) => {
    if (e.key === "Enter") {
      setloading(true);

      //Search filter
      console.time("search");
      if (searchValue !== null && searchValue !== "") {
        try {
          const searchValueLower = searchValue.toLowerCase();
          const { data } = await axios.get(
            `Orders/GetOrderBySearch/${searchValueLower}`
          );
          setFilteredOrders(data);
          setloading(false);
        } catch (err) {
          console.log(err);
        }
        console.timeEnd("search");
      } else {
        setFilteredOrders(orders);
      }
    }
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      setFilteredOrders(orders);
    } else {
      const searchValueLower = e.target.value.toLowerCase();
      setFilteredOrders(
        orders.filter(
          (order) =>
            `sc${order.orderNumber}`.includes(searchValueLower) ||
            `${order.customerFirstName} ${order.customerLastName}`
              .toLowerCase()
              .includes(searchValueLower) ||
            `sc${order.emailAddress}`.includes(searchValueLower) ||
            `sc${order.shippingAddress.phone}`.includes(searchValueLower) ||
            `${order.courierOrder.quantitiesDescription}` === e.target.value ||
            (order.note != null &&
              order.note.toLowerCase().includes(searchValueLower))
        )
      );
    }
  };

  const handleExportColumns = (s) => {
    setSelectedIdExport(s);
    setShowExportColumnsModal(true);
  };

  const handleCloseExportColumnsModal = () => {
    setShowExportColumnsModal(false);
    setSelectedIdExport([]);
  };

  const bulkExport = async (selectedColumns) => {
    var selectedOrders = [];
    selectedIdExport.forEach((selectedOrderId) => {
      selectedOrders.push(filteredOrders.find((o) => o.id === selectedOrderId));
    });
    var exportObjList = await selectedOrders.map((o) => {
      var exportObj = {};
      selectedColumns.forEach((element) => {
        var value = "";
        if (element.parent && element.isArray) {
          value = formatArrayValueForExport(
            element.field,
            element.parent,
            o[element.parent]
          );
        } else if (element.parent && element.isArray === false) {
          value = o[element.parent][element.field];
        } else {
          value = formatColumnValueForExport(element.field, o[element.field]);
        }
        exportObj[element.name.replace(/\s/g, "")] = value;
      });
      return exportObj;
    });

    axios
      .post("Export/ExportDynamicObjectsToExcel", exportObjList, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${parent} - ${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      });

    handleCloseExportColumnsModal();
  };

  const handleCloseQuickFixPopup = () => {
    removeUpdatingOrder(orderForPopup.id);
    setShowQuickFixPopup(false);
    setOrderForPopup(null);
  };

  if (orders) {
    return (
      <div className="container-div">
        {showDocumentViewer && (
          <DocumentViewer
            modalState={showDocumentViewer}
            handleCloseModal={() => {
              setShowDocumentViewer(false);
              setOrderForPopup(null);
            }}
            driveId={orderForPopup.courierOrder.driveId}
            docType={"PDF"}
            title={"Label"}
            headerColor={"#27293d"}
            height={"auto"}
            width={"80vW"}
          />
        )}
        {showEditOrderPopup && (
          <EditStoreOrderPopup
            modalState={showEditOrderPopup}
            togglePopUp={(value) => setShowOrderPopup(value)}
            activeTab={activeTab}
            handleCloseModal={() => {
              setShowOrderPopup(false);
              setOrderForPopup(null);
              setDirectToPopup(false);
              setActiveTab(0);
            }}
            order={orderForPopup}
          />
        )}
        {showPackingScreenModal && (
          <PackingScreenModal
            open={showPackingScreenModal}
            setOpen={setShowPackingScreenModal}
            chunkNumber={activeChunk}
            orders={orders}
          />
        )}
        {showExportColumnsModal && (
          <ExportColumnsModal
            open={showExportColumnsModal}
            handleClose={handleCloseExportColumnsModal}
            bulkExport={bulkExport}
          />
        )}
        {showPrintLabelPopup && (
          <PrintLabelPopup
            open={showPrintLabelPopup}
            handleClose={() => {
              setShowPrintLabelPopup(false);
              setOrderForPopup(null);
            }}
            order={orderForPopup}
            setSnackBarProps={setSnackBarProps}
          />
        )}
        {showQuickFixPopup && (
          <QuickFixPopup
            open={showQuickFixPopup}
            handleClose={handleCloseQuickFixPopup}
            order={orderForPopup}
            setSnackBarProps={setSnackBarProps}
          />
        )}
        <Snacky snackprops={snackbar} setSnackBarProps={setSnackBarProps} />
        <Paper className="paper-style">
          <EnhancedTableToolbar
            numSelected={selected.length}
            setSelected={setSelected}
            selected={selected}
            bulkRelease={bulkRelease}
            bulkNotes={bulkNotes}
            bulkLabelsAndComplete={bulkLabelsAndComplete}
            handleExportColumns={handleExportColumns}
            parent={parent}
            totalRows={filteredOrders.length}
            bulkSendToHoldOrder={bulkSendToHoldOrder}
            overrideTitle={overrideTitle}
          />
          <hr className="hr-table-style" />
          <SearchTextField
            label="Search"
            variant="outlined"
            className="order-table-search-bar"
            value={searchValue}
            onChange={handleChange}
            onKeyDown={handleSearch}
          />
          <hr className="hr-table-style" />
          <TableContainer className="table-container">
            <Table
              aria-labelledby="tableTitle"
              stickyHeader
              aria-label="sticky table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={filteredOrders.length}
                parent={props.parent}
                hpMode={hpMode}
                isChunkSorted={isChunkSorted}
              />
              {!loading && (
                <MemoizedTableBody
                  filteredOrders={filteredOrders}
                  order={order}
                  orderBy={orderBy}
                  selected={selected}
                  isSelected={isSelected}
                  setSelected={setSelected}
                  selectAllInChunk={selectAllInChunk}
                  setShowOrderPopup={setShowOrderPopup}
                  setOrderForPopup={setOrderForPopup}
                  setShowDocumentViewer={setShowDocumentViewer}
                  setActiveTab={setActiveTab}
                  releaseOrder={releaseOrder}
                  handleClick={handleClick}
                  parent={props.parent}
                  setShowPrintLabelPopup={setShowPrintLabelPopup}
                  ordersUpdating={ordersUpdating}
                  allProducts={allProducts}
                  setShowQuickFixPopup={setShowQuickFixPopup}
                  hpMode={hpMode}
                  printChunk={printChunk}
                  completeChunk={completeChunk}
                  isChunkSorted={isChunkSorted}
                  handleOpenPackingScreen={handleOpenPackingScreen}
                />
              )}
            </Table>
          </TableContainer>
        </Paper>
        {loading && (
          <div className="packman-loader">
            <PacmanLoader size={25} color="#4d4d00" loading={true} />
          </div>
        )}
        <Footer />
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};

const mapStateToProps = (state) => ({
  system: state.system,
  snackbar: state.snackbar,
  ordersUpdating: state.order.ordersUpdating,
  allProducts: state.products.allProducts,
});

export default connect(mapStateToProps, {
  bulkRelease,
  bulkSendHandpack,
  bulkSendStore,
  setSnackBarProps,
  bulkNotes,
  bulkLabelsAndComplete,
  printChunk,
  completeChunk,
  removeUpdatingOrder,
  bulkSendToHoldOrder,
})(OrdersTable);
