import { createAction } from "@reduxjs/toolkit";
import axios from "axios";

export const setError = createAction("SET_ERROR");

export const getAllNotes = () => async (dispatch) => {
  try {
    var res = await axios.get("ManagedNote/GetAllNotes");
    dispatch(getAllManagedNotes(res.data));
  } catch (error) {
    dispatch(setError(error));
  }
};

export const getAllManagedNotes = createAction("GET_ALL_MANAGED_NOTES");

export const updateNote = (note) => async (dispatch) => {
  try {
    var res = await axios.put("ManagedNote/UpdateManagedNote", note);
    dispatch(updateNoteSuccess(res.data));
  } catch (error) {
    dispatch(setError(error));
  }
};

export const updateNoteSuccess = createAction("UPDATE_NOTE");

export const addManagedNote = (note, isActive) => async (dispatch) => {
  try {
    var res = await axios.post("ManagedNote/CreateManagedNote", {note, isActive});
    dispatch(addManagedNoteSuccess(res.data));
  } catch (error) {
    dispatch(setError(error));
  }
};

export const addManagedNoteSuccess = createAction("ADD_MANAGED_NOTE");
