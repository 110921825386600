import React, { useEffect, useRef, useState } from "react";
import _, { over } from "lodash";
import { connect } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormGroup,
  InputLabel,
  DialogActions,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Input,
  Fab,
  CircularProgress,
  Typography,
  Checkbox,
  Button,
} from "@material-ui/core";
import { Delete, Add, DragIndicator } from "@material-ui/icons";
import {
  getMessages,
  onEditField,
  onUpdateField,
  createBlankMessage,
  removeMessage,
  swapAndUpdate,
  updateDbDateDuration,
} from "../../reduxActions/message";
import "./control.scss";
import card from "../../img/note.PNG";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import { scrollToBottom } from "../Utils/helperFunctions";
import axios from "axios";
import { getNowDateString } from "../../components/Utils/dateTime";
import { addDays } from "../../components/Utils/dateTime";
import Dialogy from "../Shared/Dialogy";

const Messaging = (props) => {
  const {
    messages,
    getMessages,
    onEditField,
    onUpdateField,
    createBlankMessage,
    removeMessage,
    swapAndUpdate,
    updateDbDateDuration,
    messagesLoading
  } = props;

  const [override, setOverride] = useState(false);
  const [loading, setLoading] = useState(false);

  const [displayCard, setdisplayCard] = useState(false);
  const [messageField, setMessageField] = useState({
    message: "",
    index: 0,
  });
  const [creatingNewMessage, setCreatingNewMessage] = useState(false)

  const [infinityNotesDialogyOpen, setInfinityNotesDialogyOpen] = useState(false)
  const [infinityNotesAmmount, setInfinityNotesAmmount] = useState(0);
  const [loadingSendInfinityNotes, setLoadingSendInfinityNotes] = useState(false)

  const sendInfinityNotes = async () => {
    if (infinityNotesAmmount > 0) {
      setLoadingSendInfinityNotes(true)
      await axios.post(`Orders/InfinityNotes/${infinityNotesAmmount}`, { messageObj : JSON.stringify(messages[messageIndex].messageObj)})
      setLoadingSendInfinityNotes(false)
    }
    setInfinityNotesDialogyOpen(false)
  };

  const prevMessages = usePrevious(messages);

  // Hook
  function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();

    // Store current value in ref
    useEffect(() => {
      ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
  }

  useEffect(() => {
    axios
      .get("Messages/GetOverrideMessageStatus")
      .then((res) => {
        setOverride(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (!messages) {
      getMessages(prevMessages);
    }
  }, []);

  useEffect(() => {
    if (messages) {
      setMessageField({
        index: 0,
        message: messages[0].message1,
      });
    }
  }, []);

  const createNewMessage = (m, messagesLength, id) => {
    setCreatingNewMessage(true);
    axios.post(`Messages/PostMessage`, {
      id: id,
      greeting: "Hi",
      message1: "Enjoy your coffee!",
      signedBy: "Patrick",
      messageObj: JSON.stringify({
        body: {
          "lines": [
            {
              "lx": 2.05,
              "ly": 1.75,
              "text": "Enjoy your coffee!"
            }
          ],
          "fontSize": 30
        },
        message: {
          "rx": 7.1,
          "ry": 2.95
        },
        greeting: {
          "lx": 1.55,
          "ly": 1.25,
          "text": "Hi {{name}}",
          "fontSize": 30
        },
        signature: {
          "lx": 1.8,
          "ly": 2.25,
          "text": "Patrick",
          "fontSize": 30
        },
      }),
      start: addDays(m.start, m.duration),
      end: getNowDateString(),
      orderCount: messagesLength,
      isApologyNote: 0
    }).then(() => {
      getMessages();
      setMessageField({
        index: messagesLength,
        message: messages[messagesLength].message1,
      });
    }).catch(e => {

    }).finally(() => {
      setCreatingNewMessage(false)
    })
  }

  const handleDragStart = () => {
    let rowSize = 0;
    let rows = document.getElementsByClassName("table-rows-els");

    if (rows.length > 0) {
      for (let i = 0; i < rows.length; i++) {
        rowSize += rows[i].offsetHeight;
      }
    }
    let table = document.getElementsByClassName("messaging-table-container")[0];
    if (table) {
      table.style.height = `${rowSize + 40}px`;
    }
  };

  const handleDragEnd = (m) => {
    if (m.destination.index !== 0) {
      swapAndUpdate(m.destination.index, m.source.index, messages);
      debugger;
      let table = document.getElementsByClassName(
        "messaging-table-container"
      )[0];
      if (table) {
        table.style.height = "auto";
      }
    }
  };

  const handleUpdateField = (m, field) => {
    updateDbDateDuration(messages);
  };

  useEffect(() => {
    //scrollToBottom("message-table-body");
    setCreatingNewMessage(false);
  }, [messages]);

  const [messageIndex, setmessageIndex] = useState();

  const handleUpdate = (message, field) => {
    let m = { ...message };
    m.messageObj = JSON.stringify(m.messageObj);
    onUpdateField(m, field);
    setMessageFieldUpdated(true);
  };

  const [updateMessageStore, setUpdateMessageStore] = useState();

  const handleEditMessage = (value, index) => {
    setMessageField({ message: value, index: index });
  };



  useEffect(() => {
    const timer = setTimeout(() => {
      setUpdateMessageStore(true);
      setMessageFieldUpdated(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [messageField]);

  useEffect(() => {
    if (updateMessageStore) {
      onEditField({
        index: messageField.index,
        value: messageField.message,
        field: "message1",
        width: document.getElementsByClassName("message-body-id"),
      });
      setUpdateMessageStore(false);
    }
  }, [updateMessageStore]);

  const [messageFieldUpdated, setMessageFieldUpdated] = useState(false);

  useEffect(() => {
    if (
      messageField.message &&
      document.getElementsByClassName("message-body-id").length &&
      !messageFieldUpdated
    ) {
      onEditField({
        index: messageField.index,
        value: messageField.message,
        field: "message1",
        width: document.getElementsByClassName("message-body-id"),
      });
      setMessageFieldUpdated(true);
    }
  });

  if (messages) {
    return (
      <div>
        <div className="messaging-div">
          <Paper className="message-table-paper">
            <TableContainer
              className="message-table-container"
              id="message-table-body"
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow style={{ height: "40px" }}>
                    <TableCell
                      align={"center"}
                      style={{ width: "3%" }}
                    ></TableCell>
                    <TableCell align={"center"} style={{ width: "5%" }}>
                      Order
                    </TableCell>
                    <TableCell align={"center"} style={{ width: "7%" }}>
                      Greeting
                    </TableCell>
                    <TableCell align={"center"} style={{ width: "62%" }}>
                      Message
                    </TableCell>
                    <TableCell align={"center"} style={{ width: "10%" }}>
                      Signed By
                    </TableCell>
                    <TableCell align={"center"} style={{ width: "3%" }}>
                      Apology
                    </TableCell>
                    {/* <TableCell align={"center"} style={{ width: "10%" }}>
                      Start
                    </TableCell>
                    <TableCell align={"center"} style={{ width: "10%" }}>
                      Duration (Days)
                    </TableCell> */}
                    <TableCell style={{ width: "3%" }}></TableCell>
                  </TableRow>
                </TableHead>
                <DragDropContext
                  onDragEnd={handleDragEnd}
                  onDragStart={handleDragStart}
                >
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <TableBody ref={provided.innerRef}>
                        {messages.map((m, index) => {
                          if (m.orderCount === 0) {
                            return (
                              <TableRow
                                className="table-rows-els"
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={`${m.id}-table-row`}
                              >
                                <TableCell
                                  className="messaging-body-cell"
                                  style={{ width: "3%" }}
                                  onClick={() => setmessageIndex(index)}
                                ></TableCell>
                                <TableCell
                                  className="messaging-body-cell"
                                  align="center"
                                  style={{ width: "5%" }}
                                >
                                  {m.orderCount}
                                </TableCell>
                                <TableCell
                                  className="messaging-body-cell"
                                  style={{ width: "7%" }}
                                  onClick={() => setmessageIndex(index)}
                                >
                                  <Input
                                    value={m.greeting}
                                    onBlur={(e) => handleUpdate(m, "greeting")}
                                    onChange={(e) => {
                                      onEditField({
                                        index: index,
                                        value: e.target.value,
                                        field: "greeting",
                                        width: document
                                          .getElementById("message-greeting-id")
                                          .getBoundingClientRect().width,
                                      });
                                    }}
                                    fullWidth={true}
                                    className="messaging-input"
                                  />
                                </TableCell>
                                <TableCell
                                  className="messaging-body-cell"
                                  style={{ width: "62%" }}
                                  onClick={() => setmessageIndex(index)}
                                >
                                  <Input
                                    value={
                                      m.message1 && messageField.index !== index
                                        ? m.message1
                                        : messageField.message
                                    }
                                    onBlur={(e) => handleUpdate(m, "message1")}
                                    onChange={(e) =>
                                      handleEditMessage(e.target.value, index)
                                    }
                                    fullWidth={true}
                                    className="messaging-input"
                                    multiline={true}
                                  />
                                </TableCell>
                                <TableCell
                                  className="messaging-body-cell"
                                  style={{ width: "10%" }}
                                  onClick={() => setmessageIndex(index)}
                                >
                                  <Input
                                    value={m.signedBy}
                                    onBlur={(e) => handleUpdate(m, "signedBy")}
                                    onChange={(e) =>
                                      onEditField({
                                        index: index,
                                        value: e.target.value,
                                        field: "signedBy",
                                        width: document
                                          .getElementById(
                                            "message-signature-id"
                                          )
                                          .getBoundingClientRect().width,
                                      })
                                    }
                                    fullWidth={true}
                                    className="messaging-input"
                                  />
                                </TableCell>
                                <TableCell
                                  className="messaging-body-cell"
                                  style={{ width: "3%", textAlign: "center" }}
                                >

                                </TableCell>
                                <TableCell
                                  className="messaging-body-cell-delete"
                                  style={{ width: "3%" }}
                                >
                                  {!loading && (
                                    <Checkbox
                                      checked={override === 1}
                                      onClick={(e) => {
                                        setLoading(true);
                                        if (e.target.checked) {
                                          axios
                                            .patch(
                                              "Messages/UpdateOverrideStatus/qlbvgherlkjubvg897645HRSTHNXF454lkjhbv/true"
                                            )
                                            .then((res) => {
                                              console.log(res);
                                              setOverride(1);
                                            })
                                            .catch((e) => {
                                              console.log(e);
                                            })
                                            .finally(() => {
                                              setLoading(false);
                                            });
                                        } else {
                                          axios
                                            .patch(
                                              "Messages/UpdateOverrideStatus/qlbvgherlkjubvg897645HRSTHNXF454lkjhbv/false"
                                            )
                                            .then((res) => {
                                              console.log(res);
                                              setOverride(0);
                                            })
                                            .catch((e) => {
                                              console.log(e);
                                            })
                                            .finally(() => {
                                              setLoading(false);
                                            });
                                        }
                                      }}
                                    />
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          } else if (m.orderCount === 1) {
                            return (
                              <TableRow
                                className="table-rows-els"
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={`${m.id}-table-row`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <TableCell
                                  className="messaging-body-cell"
                                  style={{ width: "3%" }}
                                  onClick={() => setmessageIndex(index)}
                                ></TableCell>
                                <TableCell
                                  className="messaging-body-cell"
                                  align="center"
                                  style={{ width: "5%" }}
                                >
                                  {m.orderCount}
                                </TableCell>
                                <TableCell
                                  className="messaging-body-cell"
                                  style={{ width: "7%" }}
                                  onClick={() => setmessageIndex(index)}
                                >
                                  <Input
                                    value={m.greeting}
                                    onBlur={(e) => handleUpdate(m, "greeting")}
                                    onChange={(e) => {
                                      onEditField({
                                        index: index,
                                        value: e.target.value,
                                        field: "greeting",
                                        width: document
                                          .getElementById("message-greeting-id")
                                          .getBoundingClientRect().width,
                                      });
                                    }}
                                    fullWidth={true}
                                    className="messaging-input"
                                  />
                                </TableCell>
                                <TableCell
                                  className="messaging-body-cell"
                                  style={{ width: "62%" }}
                                  onClick={() => setmessageIndex(index)}
                                >
                                  <Input
                                    value={
                                      m.message1 && messageField.index !== index
                                        ? m.message1
                                        : messageField.message
                                    }
                                    onBlur={(e) => handleUpdate(m, "message1")}
                                    onChange={(e) =>
                                      handleEditMessage(e.target.value, index)
                                    }
                                    fullWidth={true}
                                    className="messaging-input"
                                    multiline={true}
                                  />
                                </TableCell>
                                <TableCell
                                  className="messaging-body-cell"
                                  style={{ width: "10%" }}
                                  onClick={() => setmessageIndex(index)}
                                >
                                  <Input
                                    value={m.signedBy}
                                    onBlur={(e) => handleUpdate(m, "signedBy")}
                                    onChange={(e) =>
                                      onEditField({
                                        index: index,
                                        value: e.target.value,
                                        field: "signedBy",
                                        width: document
                                          .getElementById(
                                            "message-signature-id"
                                          )
                                          .getBoundingClientRect().width,
                                      })
                                    }
                                    fullWidth={true}
                                    className="messaging-input"
                                  />
                                </TableCell>
                                <TableCell
                                  className="messaging-body-cell"
                                  style={{ width: "3%", textAlign: "center" }}
                                >

                                </TableCell>
                                <TableCell
                                  className="messaging-body-cell-delete"
                                  style={{ width: "3%" }}
                                ></TableCell>
                              </TableRow>
                            );
                          } else {
                            return (
                              <Draggable
                                key={`${m.id}-draggable`}
                                draggableId={`${m.id}-draggable-id`}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <TableRow
                                    className="table-rows-els"
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={`${m.id}-table-row`}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <TableCell
                                      className="messaging-body-cell"
                                      style={{ width: "3%" }}
                                    >
                                      <DragIndicator
                                        fontSize="large"
                                        style={{
                                          padding: 0,
                                          color: "#b3b3b3",
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      className="messaging-body-cell"
                                      align="center"
                                      style={{ width: "5%" }}
                                    >
                                      {m.orderCount}
                                    </TableCell>
                                    <TableCell
                                      className="messaging-body-cell"
                                      style={{ width: "7%" }}
                                      onClick={() => setmessageIndex(index)}
                                    >
                                      <Input
                                        value={m.greeting}
                                        onBlur={(e) =>
                                          handleUpdate(m, "greeting")
                                        }
                                        onChange={(e) => {
                                          onEditField({
                                            index: index,
                                            value: e.target.value,
                                            field: "greeting",
                                            width: document
                                              .getElementById(
                                                "message-greeting-id"
                                              )
                                              .getBoundingClientRect().width,
                                          });
                                        }}
                                        fullWidth={true}
                                        className="messaging-input"
                                      />
                                    </TableCell>
                                    <TableCell
                                      className="messaging-body-cell"
                                      style={{ width: "57%" }}
                                      onClick={() => {
                                        setmessageIndex(index)
                                      }}
                                    >
                                      <Input
                                        value={
                                          m.message1 &&
                                            messageField.index !== index
                                            ? m.message1
                                            : messageField.message
                                        }
                                        onBlur={(e) =>
                                          handleUpdate(m, "message1")
                                        }
                                        onChange={(e) => {
                                          handleEditMessage(
                                            e.target.value,
                                            index
                                          )
                                        }
                                        }
                                        fullWidth={true}
                                        className="messaging-input"
                                        multiline={true}
                                      />
                                    </TableCell>
                                    <TableCell
                                      className="messaging-body-cell"
                                      style={{ width: "10%" }}
                                      onClick={() => setmessageIndex(index)}
                                    >
                                      <Input
                                        value={m.signedBy}
                                        onBlur={(e) =>
                                          handleUpdate(m, "signedBy")
                                        }
                                        onChange={(e) => {
                                          onEditField({
                                            index: index,
                                            value: e.target.value,
                                            field: "signedBy",
                                            width: document
                                              .getElementById(
                                                "message-signature-id"
                                              )
                                              .getBoundingClientRect().width,
                                          });
                                        }}
                                        fullWidth={true}
                                        className="messaging-input"
                                      />
                                    </TableCell>
                                    {/* <TableCell
                                      className="messaging-body-cell"
                                      style={{ width: "10%" }}
                                      onClick={() => setmessageIndex(index)}
                                    >
                                      <Input
                                        value={m.start
                                          .toString()
                                          .substring(0, 10)}
                                        onBlur={(e) =>
                                          handleUpdateField(m, "start")
                                        }
                                        onChange={(e) =>
                                          onEditField({
                                            index: index,
                                            value: e.target.value,
                                            field: "start",
                                          })
                                        }
                                        fullWidth={true}
                                        className="messaging-input"
                                        type="date"
                                        disabled={index !== 1}
                                      />
                                    </TableCell>
                                    <TableCell
                                      className="messaging-body-cell"
                                      style={{ width: "10%" }}
                                      onClick={() => setmessageIndex(index)}
                                    >
                                      <Input
                                        value={m.duration}
                                        onBlur={(e) =>
                                          handleUpdateField(m, "duration")
                                        }
                                        onChange={(e) =>
                                          onEditField({
                                            index: index,
                                            value: e.target.value,
                                            field: "duration",
                                          })
                                        }
                                        fullWidth={true}
                                        className="messaging-input"
                                        type="number"
                                      />
                                    </TableCell> */}
                                    <TableCell
                                      className="messaging-body-cell"
                                      style={{
                                        width: "3%",
                                        textAlign: "center",
                                      }}
                                    >
                                      {!loading && (
                                        <Checkbox
                                          checked={m.isApologyNote === 1}
                                          onClick={(e) => {
                                            setLoading(true);
                                            let message = { ...m };
                                            message.messageObj = JSON.stringify(message.messageObj)
                                            message.isApologyNote =
                                              e.target.checked ? 1 : 0;
                                            axios
                                              .patch(
                                                "Messages/PatchMessage/isApologyNote",
                                                message
                                              )
                                              .then((res) => {
                                                onEditField({
                                                  index: index,
                                                  value: e.target.value ? 1 : 0,
                                                  field: "isApologyNote",
                                                });
                                              })
                                              .catch((e) => {
                                                console.log(e);
                                              })
                                              .finally(() => {
                                                setLoading(false);
                                              });
                                          }}
                                        />
                                      )}
                                    </TableCell>
                                    <TableCell
                                      className="messaging-body-cell-delete"
                                      style={{ width: "3%" }}
                                    >
                                      {m.start && (
                                        <Delete
                                          className="messaging-delete-icon"
                                          onClick={() => {
                                            setmessageIndex();
                                            removeMessage(m.id, index);
                                          }}
                                        />
                                      )}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Draggable>
                            );
                          }
                        })}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableContainer>
          </Paper>
          <Fab
            disabled={creatingNewMessage && !messagesLoading}
            size="small"
            color="primary"
            aria-label="add"
            className="messaging-fab"
            onClick={() => {
              if (!creatingNewMessage && !messagesLoading) {
                setCreatingNewMessage(true);
                var highestId = messages.length - 1;
                for (var i = 0; i < messages.length; i++) {
                  highestId = messages[i].id > highestId ? messages[i].id : highestId;
                }
                highestId++;//new Id will be 1 higher;
                setmessageIndex(messages.length - 1);
                createNewMessage(//createBlankMessage(
                  messages[messages.length - 1],
                  messages.length - 2, //for each negative order,
                  highestId
                );
                scrollToBottom("message-table-body");
              }
            }}
          >
            <Add />
          </Fab>
        </div>
        <div className="handwritten-card-container message-table-car-container">
          <img
            src={card}
            alt="Snow"
            width="auto"
            height="auto"
            onLoad={() => setdisplayCard(true)}
          />
          {!displayCard && <CircularProgress />}
          {displayCard && messageIndex != null && (
            <div className="message-table-card-greeting">
              <Typography id="message-greeting-id" className="robot-font">
                {messages[messageIndex].messageObj.greeting.text.slice(0, -9)}
              </Typography>
              <Typography className="message-table-card-message robot-font">
                {messages[messageIndex].messageObj.body.lines.map((m) => (
                  <div className="message-body-id">{m.text}</div>
                ))}
              </Typography>
              <Typography
                id="message-signature-id"
                className="message-table-card-signed robot-font"
              >
                {messages[messageIndex].messageObj.signature.text}
              </Typography>
            </div>

          )}
          {messageIndex != null && <Button className="infinity-note-button" color="primary" variant="contained" onClick={() => setInfinityNotesDialogyOpen(true)}
          >Use As Infinity Notes</Button>}
          <Dialog
            open={infinityNotesDialogyOpen}
            onClose={(e, reason) => {
              setInfinityNotesDialogyOpen(false)
            }}
          >
            <DialogTitle>Infinity Notes</DialogTitle>
            <DialogContent>
              <FormGroup>
                <InputLabel>How many infinity notes?</InputLabel>
                <Input
                  type="number"
                  value={infinityNotesAmmount}
                  onChange={(e) => {
                    let value = e.target.value
                    if (value !== "" && Number.parseInt(value) < 0) { value = 0 }
                    setInfinityNotesAmmount(value)
                  }}
                  disabled={loadingSendInfinityNotes}
                />
              </FormGroup>
            </DialogContent>
            <DialogActions>
              {loadingSendInfinityNotes ?
                <CircularProgress />
                : (
                  <React.Fragment>
                    <Button variant="contained" color="default" onClick={() => setInfinityNotesDialogyOpen(false)}>
                      Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => sendInfinityNotes()}>
                      Write
                    </Button>
                  </React.Fragment>
                )
              }
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};

const mapStateToProps = (state) => ({
  messages: state.message.messages,
  messagesLoading: state.message.messagesLoading
});

export default connect(mapStateToProps, {
  getMessages,
  onEditField,
  onUpdateField,
  createBlankMessage,
  removeMessage,
  swapAndUpdate,
  updateDbDateDuration,
})(Messaging);
