import React, { useEffect } from "react";
import "./adminLayout.scss";
import { makeStyles, createTheme } from "@material-ui/core/styles";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import StorefrontIcon from "@material-ui/icons/Storefront";
import FaceIcon from "@material-ui/icons/Face";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PeopleIcon from "@material-ui/icons/People";
import TableChartIcon from "@material-ui/icons/TableChart";
import WarningIcon from '@material-ui/icons/Warning';
import {
  AppBar,
  createMuiTheme,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import CustomerFinder from "../CustomerFinder/CustomerFinder";
import { Link } from "react-router-dom";
import {
  getNumberOfSubscriptionsWithError,
  searchSubscriptions,
} from "../../../repository/subscriptions";
import { getReferralsWithCreditStatus } from "../../../repository/referrals";

const websiteTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#862121",
    },
    secondary: {
      main: "#C1AF94",
    },
  },
});
const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "100vh",
    minHeight: "100vh",
    overflow: "auto",
    backgroundColor: "#FAF8F3",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#F4F0E9",
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const AdminLayout = ({ children }) => {
  const [loadingNumErroredAutoship, setLoadingNumErroredAutoship] =
    React.useState(true);
  const [erroredAutoshipNumItems, setErroredAutoshipNumItems] =
    React.useState(0);
  const [loadingNumReferrals, setLoadingNumReferrals] = React.useState(true);
  const [numPendingReferrals, setNumPendingReferrals] = React.useState(0);

  useEffect(() => {
    setLoadingNumErroredAutoship(true);
    searchSubscriptions({ status: "ACTIVE" })
      .then((subscriptions) => getNumberOfSubscriptionsWithError(subscriptions))
      .then((numSubscriptionsWithError) => {
        setLoadingNumErroredAutoship(false);
        setErroredAutoshipNumItems(numSubscriptionsWithError);
      });
  }, []);

  useEffect(() => {
    setLoadingNumReferrals(true);
    getReferralsWithCreditStatus("PENDING").then((referrals) => {
      setLoadingNumReferrals(false);
      console.log(referrals);
      setNumPendingReferrals(referrals.length);
    });
  }, []);

  const classes = useStyles();
  return (
    <ThemeProvider theme={websiteTheme}>
      <div className={classes.root}>
        <AppBar position="fixed" color={"secondary"} className={classes.appBar}>
          <Toolbar className="appbar-container">
            <div style={{ width: drawerWidth }}>
              <img
                src="https://storage.googleapis.com/cc2u/logo/cc2u-logo.svg"
                alt="cc2u-logo"
              />
            </div>
            <CustomerFinder />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open
          className={"admin-layout-menu-nav-menu"}
          classes={{
            paper: "admin-layout-menu-nav-menu-paper",
          }}
        >
          <Toolbar />
          <List>
            {[
              { text: "Orders", icon: <StorefrontIcon />, to: "#" },
              {
                text: "Customers",
                icon: <FaceIcon />,
                to: "/AdminPanel/Customers",
              },
              {
                text:
                  loadingNumErroredAutoship || erroredAutoshipNumItems == 0
                    ? "Autoship"
                    : `Autoship (Problems: ${erroredAutoshipNumItems})`,
                icon: <LocalShippingIcon />,
                to: "/AdminPanel/Autoship",
              },
              {
                text: "Abandoned Checkout",
                icon: <ShoppingCartIcon />,
                to: "/AdminPanel/AbandonedCheckout",
              },
              {
                text:
                  loadingNumReferrals || numPendingReferrals == 0
                    ? "Referrals"
                    : `Referrals (Pending: ${numPendingReferrals})`,
                icon: <PeopleIcon />,
                to: "/AdminPanel/Referrals",
              },
              {
                text: "Products",
                icon: <TableChartIcon />,
                to: "/AdminPanel/Products",
              },
              {
                text: "Errors",
                icon: <WarningIcon />,
                to: "/AdminPanel/Errors",
              },
            ].map((navItem, index) => (
              <Tooltip title={navItem.text}>
                <ListItem button key={index} to={navItem.to} component={Link}>
                  <ListItemIcon>{navItem.icon}</ListItemIcon>
                  <ListItemText
                    primary={navItem.text}
                    className="admin-layout-menu-nav-item-text"
                  />
                </ListItem>
              </Tooltip>
            ))}
          </List>
        </Drawer>
        <main className="main-container" id="admin-panel">
          <Toolbar />
          {children}
        </main>
      </div>
    </ThemeProvider>
  );
};

export default AdminLayout;
