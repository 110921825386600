import React, { forwardRef, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    FormControlLabel,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Typography,
    CircularProgress,
    Input,
    Chip,
    Checkbox,
    ListItemText
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import Axios from "axios"

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
        width: "100%"
    },
    plasticChip: {
        backgroundColor: "black",
        color: "white"
    },
    compostChip: {
        backgroundColor: "#704214",
        color: "white"
    },
    chip: {
        margin: 2
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const MenuProps = {
    getContentAnchorEl: null
};

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} mountOnEnter unmountOnExit />;
});

const AutoPilotFlavourPopup = (props) => {
    const classes = useStyles();

    const {
        open,
        handleClose,
        setSnackBarProps,
        allProducts
    } = props;

    const [flavoursSelected, setFlavoursSelected] = useState([]);
    const [minimum6BoxCount, setMinimum6BoxCount] = useState(4);
    const [loading, setLoading] = useState(false);
    const [coffeeSelection, setCoffeeSelection] = useState([]);

    const handleChange = (event) => {
        var newFlavoursSelected = [...event.target.value];
        newFlavoursSelected.sort();
        setFlavoursSelected(newFlavoursSelected);
    };

    const saveAutoPilotSettings = () => {
        setLoading(true);
        Axios.patch(`SystemActions/PatchAutoPilotSettings`, {
            NewFlavours: flavoursSelected,
            AutoPilot06MinimumCount: minimum6BoxCount
        })
            .then(res => {
                setSnackBarProps("success", "Saved", true);
                handleClose();
            }).catch((e) => {
                setSnackBarProps("warning", "Failed to save", true);
            }).finally(() => {
                setLoading(false);
            })
    }

    useEffect(() => {
        Axios.get(`SystemActions/GetAutopilotExcludedFlavours`)
            .then(res => {
                if (res.data !== null && res.data.trim() !== "") {
                    var flavourArr = res.data.split(",");
                    setFlavoursSelected(flavourArr);
                }
            }).catch((e) => {
                console.log(e)
            })
    }, [])

    useEffect(() => {
        Axios.get(`SystemActions/GetMinimum6BoxCount`)
            .then(res => {
                setMinimum6BoxCount(res.data)
            }).catch((e) => {
                console.log(e)
            })
    }, [])

    useEffect(() => {
        var newCoffeeSelection = [];
        allProducts.forEach(p => {
            if (p.isMiscellaneous === 0) {
                newCoffeeSelection.push(p.code);
            }
        });
        setCoffeeSelection(newCoffeeSelection);
    }, [allProducts])

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => handleClose()}
                disableBackdropClick
            >
                <DialogTitle>AutoPilot Settings</DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1">Minimum box count for 6 Box AutoPilot</Typography>
                    <FormControl className={classes.formControl}>
                        <InputLabel>Minimum box count</InputLabel>
                        <Select
                            id="minimum-for-6-box"
                            value={minimum6BoxCount}
                            onChange={e => setMinimum6BoxCount(e.target.value)}
                            input={<Input id="select-minimum-for-6-box" />}
                        >
                            <MenuItem key={1} value={1}>1</MenuItem>
                            <MenuItem key={2} value={2}>2</MenuItem>
                            <MenuItem key={3} value={3}>3</MenuItem>
                            <MenuItem key={4} value={4}>4</MenuItem>
                            <MenuItem key={5} value={5}>5</MenuItem>
                            <MenuItem key={6} value={6}>6</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <hr />
                <DialogContent>
                    <Typography variant="subtitle1">Select the flavours that will <b>not</b> be sent to the line</Typography>
                    <FormControl className={classes.formControl}>
                        <InputLabel>Select Flavours</InputLabel>
                        <Select
                            id="mutiple-flavours-chip"
                            multiple
                            value={flavoursSelected}
                            onChange={e => handleChange(e)}
                            input={<Input id="select-multiple-flavours-chip" />}
                            renderValue={(selected) => "Selected:"}
                            MenuProps={MenuProps}
                        >
                            {coffeeSelection.map((flavour) => (
                                <MenuItem key={flavour} value={flavour}>
                                    {flavour}
                                </MenuItem>
                            ))}
                        </Select>
                        <div className={classes.chips}>
                            {flavoursSelected.map((value) => (
                                <Chip
                                    variant="outlined"
                                    size="small"
                                    key={value}
                                    label={value}
                                    className={`${classes.chip}`}
                                    onDelete={() => {
                                        var newFlavoursSelected = [...flavoursSelected];
                                        setFlavoursSelected(newFlavoursSelected.filter((e) => e !== value))
                                    }}
                                    style={{ margin: 5 }}
                                />
                            ))}
                        </div>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    {loading ?
                        <CircularProgress style={{margin: 5}}/>
                        : (
                            <React.Fragment>
                                <Button variant="contained" color="primary" onClick={() => setFlavoursSelected([])}>
                                    Clear Flavours
                                </Button>
                                <Button variant="contained" color="default" onClick={() => handleClose()}>
                                    Go Back
                                </Button>
                                <Button variant="contained" color="primary" onClick={() => saveAutoPilotSettings()}>
                                    Save
                                </Button>
                            </React.Fragment>
                        )
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state) => ({
    allProducts: state.products.allProducts,
});

export default connect(mapStateToProps, {

})(AutoPilotFlavourPopup);