import { createAction } from "@reduxjs/toolkit";
import axios from "axios";

export const getAllProducts = createAction("GET_ALL_PRODUCTS");
export const getProductSuccess = createAction("GET_PRODUCTS");
export const setError = createAction("SET_ERROR");

export const getProducts = () => async (dispatch) => {
  try {
    var res = await axios.get("Products/GetProducts?productType=all");
    dispatch(getAllProducts(res.data));
    const responseProducts = await axios.get("Products/GetProductDetails");
    dispatch(getProductSuccess(responseProducts.data));
  } catch (error) {
    dispatch(setError(error));
  }
};
