import {
  Button,
  ButtonGroup,
  CircularProgress,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import { connect } from "react-redux";
import { getDelayedOrders } from "../../reduxActions/order";
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import LoadingBackdrop from "../Shared/LoadingBackdrop";

const BulkUpdateDelayedOrdersButton = ({
  selectedOrders,
  getDelayedOrders,
  user,
  delayedOrders,
}) => {
  const splitButtonOptions = [
    "Set Status",
    "Set as Delivered",
    "Set as Failed",
    "Set as Not Sent",
    "Set Claim Raised",
    "Set Claim Sent",
    "Set Claim Paid",
    "Set Claim Dismissed",
  ];
  const anchorRef = React.useRef(null);

  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  console.log("selectedOrders", selectedOrders);
  console.log("delayedOrders", delayedOrders);

  const filterOrdersByIDs = (status) => {
    return delayedOrders
    .filter((delayedOrder) => selectedOrders.includes(delayedOrder.order.id))
    .map(({ order, trackingNumber, fromDisputed, fromQuickFix }) => ({
      orderId: order.id,
      status,
      trackingNumber,
      fromDisputed,
      fromQuickFix,
    }));
  };

  console.log(filterOrdersByIDs(delayedOrders, selectedOrders));

  const handleMenuItemClick = (event, index) => {
    if (index !== 0) {
      const status =
        index === 1
          ? "Delivered"
          : index === 2
          ? "Failed"
          : index === 3
          ? "NotSent"
          : index === 4
          ? "ClaimCreated"
          : index === 5
          ? "ClaimSent"
          : index === 6
          ? "ClaimPaid"
          : index === 7
          ? "ClaimDismissed"
          : null;
      setLoading(true);
      axios
        .patch(
          `ControlCenter/ManuallyUpdateTrackingStatus`,
          filterOrdersByIDs(status)
        )
        .then((res) => {
          getDelayedOrders();
        })
        .catch((e) => {
          console.log(e);
          alert("Unable to update status");
        })
        .finally(() => setLoading(false));
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid container direction="column" alignItems="center">
      {loading && <LoadingBackdrop loading={loading} />}
      <Grid item xs={12}>
        <ButtonGroup
          variant="contained"
          color="primary"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button size="small">{splitButtonOptions[selectedIndex]}</Button>

          <Button
            color="primary"
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{ zIndex: "99999999999999" }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-delayed-order">
                    {splitButtonOptions.map((option, index) => (
                      <MenuItem
                        key={option}
                        disabled={index === 0}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (state) => ({
  user: state.authentication.activeUser.firstName,
  delayedOrders: state.order.delayedOrders,
});
export default connect(mapStateToProps, {
  getDelayedOrders,
})(BulkUpdateDelayedOrdersButton);
