import React, { useEffect, Fragment } from "react";
import { connect } from "react-redux";
import {
  updateOAuthStatus,
  updateOAuthEmail,
  idActiveUser,
  getAllUsers,
} from "../../reduxActions/authentication";
import { useHistory } from "react-router-dom";
import jwtDecode from "jwt-decode";

const GAuth = (props) => {
  const {
    updateOAuthStatus,
    updateOAuthEmail,
    allUsers,
    getAllUsers,
    idActiveUser,
    authorized,
    oAuthEmail,
    activeUser,
  } = props;

  const handleSignIn = () => {
    var gEm = GoogleAuth.currentUser.get().getBasicProfile().getEmail();
    updateOAuthStatus(GoogleAuth.isSignedIn.get());
    updateOAuthEmail(gEm);
    history.push("/Home");
  };
  let GoogleAuth;
  let profile;
  let accessToken;
  let history = useHistory();

  useEffect(() => {
    if (allUsers.length == 0) {
      getAllUsers();
    }
  }, [allUsers]);

  useEffect(() => {
    if (oAuthEmail != "") {
      idActiveUser(oAuthEmail, allUsers);
    }
  }, [allUsers, oAuthEmail, authorized]);

  useEffect(() => {
    if (Object.keys(activeUser).length > 2 && authorized == true ) {
      history.push(props.endpoint ? props.endpoint : "/Home");
    }
  }, [activeUser]);

  useEffect(() => {
    const tokenResponse = window.localStorage.getItem("access_token")
    if(!tokenResponse){
      const location = {
        pathname: '/login',
        state: { navigateTarget: props.endpoint ? props.endpoint : "/Home" }
      }
      history.push(location)
    }else{
      try{
        const userInfo = jwtDecode(tokenResponse);
        if(userInfo && userInfo.email && userInfo.email_verified){
          const authenticatedLocation = {
            pathname: props.endpoint ? props.endpoint : "/Home",
            state: { navigateTarget: props.endpoint ? props.endpoint : "/Home" }
          }
          history.push(authenticatedLocation);
        }else{
          const location = {
            pathname: '/login',
            state: { navigateTarget: props.endpoint ? props.endpoint : "/Home" }
          }
          history.push(location)
        }
      }catch(err){
        const location = {
          pathname: '/login',
          state: { navigateTarget: props.endpoint ? props.endpoint : "/Home" }
        }
        history.push(location)
      }
    }
    //gapiInit();
    //initClient
  }, []);
  

  const gapiInit = () => {
    if (window.gapi.auth2 == undefined) {
      // console.log("gapi Init");
      window.gapi.load("client:auth2", () => {
        window.gapi.client
          .init({
            clientId:
              "179069991436-jvv7cail7193b23a2llburkihsgj74de.apps.googleusercontent.com",
            scope: "email",
            plugin_name:'Robot UI'
          })
          .then(() => {
            gapiConfig();
          });
      });
    }
  };

  const gapiConfig = () => {
    GoogleAuth = window.gapi.auth2.getAuthInstance();
    if (GoogleAuth == undefined) {
      gapiInit();
    } else {
      if (GoogleAuth != undefined) {
        if (GoogleAuth.isSignedIn.get() === true) {
          GoogleAuth.isSignedIn.listen(handleSignIn);
          profile = GoogleAuth.currentUser.get().getBasicProfile();
          updateOAuthStatus(GoogleAuth.isSignedIn.get());
          updateOAuthEmail(profile.getEmail());
        } else {
          GoogleAuth.isSignedIn.listen(handleSignIn);
          history.push("/login")
        }
      }
    }
  };

  return (
<Fragment />
  );
};
const mapStateToProps = (state, ownProps) => ({
  token: state.authentication.token,
  loginFailure: state.authentication.loginErrorMessage,
  activeUser: state.authentication.activeUser,
  allUsers: state.authentication.allUsers,
  oAuthEmail: state.authentication.email,
  authorized: state.authentication.oAuthSignedIn,
});
export default connect(mapStateToProps, {
  updateOAuthStatus,
  updateOAuthEmail,
  idActiveUser,
  getAllUsers,
})(GAuth);
