import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  user: "",
  email: "",
  password: "",
  token: "",
  errorMessage: "",
  oAuthSignedIn: false,
  userList: [],
  allUsers: [],
  activeUser: { id: 0, firstName: "" },
};
const authentication = createReducer(initialState, {
  LOGIN_USER_SUCCESS: (state, action) => {
    return {
      ...state,
      activeUser: action.payload,
      email: action.payload.email,
      token: action.payload.token,
      password: action.payload.password,
    };
  },
  LOGIN_USER_FAILURE: (state, action) => {
    return {
      ...state,
      errorMessage: action.payload,
    };
  },
  UPDATE_OAUTH_STATUS: (state, action) => {
    return {
      ...state,
      oAuthSignedIn: action.payload,
    };
  },
  UPDATE_ACTIVE_USER: (state, action) => {
    return {
      ...state,
      activeUser: action.payload,
    };
  },
  UPDATE_OAUTH_EMAIL: (state, action) => {
    return {
      ...state,
      email: action.payload,
    };
  },
  GET_USERS_SUCCESS: (state, action) => {
    return {
      ...state,
      userList: action.payload.userList,
      allUsers: action.payload.allUsers,
    };
  },
  GET_USERS_FAILURE: (state, action) => {
    return {
      ...state,
      errorMessage: action.payload,
    };
  },
});

export default authentication;
