import React, { useState, useEffect } from "react";
import "../Delays.scss";
import { CircularProgress, Card } from "@material-ui/core";
import axios from "axios";
import PdfViewer from "../../Shared/PdfViewer";
// import PDFViewer from "pdf-viewer-reactjs";

const ImagePanel = (props) => {
  const { orderId, trackingNumber, type, imageType, fromDisputed, fromClaims } = props;

  const [imageLoaded, setImageLoaded] = useState(false);
  const [base64, setBase64] = useState(false);
  const [faiedToLoad, setFailedToLoad] = useState(false);

  useEffect(() => {
    if (!imageLoaded) {
      if (type === "image") {
        axios
          .get(`ControlCenter/GetImage/${orderId}/${imageType}/${fromDisputed}/${fromClaims}`)
          .then((res) => {
            setBase64(res.data);
            setImageLoaded(true);
          })
          .catch((e) => {
            // console.log(e)
            setFailedToLoad(true);
          });
      } else {
        axios
          .get(
            `ControlCenter/GetLabel/${orderId}/${trackingNumber}/${fromDisputed}`
          )
          .then((res) => {
            setBase64(res.data);
            setImageLoaded(true);
          })
          .catch((e) => {
            // console.log(e);
          });
      }
    }
  }, [imageLoaded]);

  if (faiedToLoad) {
    return <React.Fragment></React.Fragment>;
  } else if (!imageLoaded) {
    return <CircularProgress />;
  } else {
    return (
      <Card className="image-panel">
        {type === "image" && (
          <img
            src={`data:image/jpeg;base64,${base64}`}
            alt="note"
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
            }}
          />
        )}
        {type === "pdf" && base64 && (
          <PdfViewer file={`data:application/pdf;base64,${base64}`} scale={0.8}/>
          // <PDFViewer
          //   scale={0.8}
          //   hideNavbar
          //   hideZoom
          //   hideRotation
          //   canvasCss={"canvasCss"}
          //   document={{
          //     url: `data:application/pdf;base64,${base64}`,
          //   }}
          //   navigation={{
          //     css: {
          //       navbarWrapper: "navbarWrapper", // CSS Class for the Navbar Wrapper
          //       zoomOutBtn: "navButton", // CSS Class for the ZoomOut Button
          //       resetZoomBtn: "navButton", // CSS Class for the Reset Zoom Button
          //       zoomInBtn: "navButton", // CSS Class for the ZoomIn Button
          //       previousPageBtn: "navButton", // CSS Class for the PreviousPage button
          //       pageIndicator: "navButton navPageIndicator", // CSS Class for the Page Indicator
          //       nextPageBtn: "navButton", // CSS Class for the NextPage button
          //       // rotateLeftBtn: "",  // CSS Class for the RotateLeft button
          //       // resetRotationBtn: "",  // CSS Class for the Reset Rotation button
          //       // rotateRightBtn: ""  // CSS Class for the RotateRight button
          //     },
          //   }}
          // />
        )}
      </Card>
    );
  }
};

export default ImagePanel;
