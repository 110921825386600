import axios from "axios";

const retrievePaymentMethodById = async (paymentMethodId) => {
  try {
    const { data } = await axios.get(
      `Stripe/RetrievePaymentMethodById/${paymentMethodId}`
    );
    return data;
  } catch (err) {
    console.error(err);
  }
  return null;
};

export { retrievePaymentMethodById };
