import React from 'react';
import { isNumeric } from '../Utils/helperFunctions';

const CurrencyFormatter = ({
  amount,
  currency = 'NZD',
  appendZero = true,
  useDollar = false,
  isDiscountCode = false,
}) => {
  if (isDiscountCode) {
    if (amount?.amount) {
      amount = amount?.amount;
    }
  }
  let displayAmount =
    (typeof amount !== 'number' && parseFloat(amount?.replace('$', ''))) ||
    amount;
  /* Set language display */
  const languageCode =
    typeof window !== 'undefined'
      ? window.navigator.language || 'en-NZ'
      : 'en-NZ';

  /* Format and return */
  // isolate currency
  const formatObject = new Intl.NumberFormat(languageCode, {
    style: 'currency',
    currency,
  });
  let symbol = '$';
  let formattedPrice = formatObject.format(displayAmount);
  if ('formatToParts' in formatObject) {
    const formattedPriceParts = formatObject.formatToParts(displayAmount);
    if (useDollar === false) symbol = formattedPriceParts[0].value;
    const currencyValue = formattedPriceParts.find(
      (obj) => obj.type === 'currency'
    );
    const decimalValue = formattedPriceParts.find(
      (obj) => obj.type === 'fraction'
    );
    formattedPrice = formattedPrice.replace(currencyValue.value, '');
    if (decimalValue && decimalValue.value === '00' && !appendZero) {
      formattedPrice = formattedPrice.replace(`.${decimalValue.value}`, '');
    }
  } else {
    // new Intl.NumberFormat is not supported; return amount with dollar sign
    formattedPrice = amount;
  }

  const priceComponent = (
    <>
      {isDiscountCode && <span>{`- `}</span>}
      <span>{symbol}</span>
      <span>{formattedPrice}</span>
    </>
  );

  return isNumeric(amount) ? priceComponent : 'No price available';
};

export default CurrencyFormatter;
