import {
    Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from "@material-ui/core";
import React, { forwardRef, useEffect } from "react";
import OrdersTable from "../Shared/OrdersTable";
import InvestigationTable from "./InvestigationTable";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide direction="up" ref={ref} {...props} mountOnEnter unmountOnExit />
  );
});

const InvestigationDialog = ({ open, handleClose, ordersForInvestiation }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="xl"
    >
      <DialogTitle id="form-dialog-title" onClose={handleClose}>
        These orders require attention
      </DialogTitle>
      <DialogContent dividers>
        {ordersForInvestiation && ordersForInvestiation.length > 0 ? (
          <InvestigationTable orders={ordersForInvestiation} />
        ) : (
          <Typography>{`No other orders requiring attention`}</Typography>
        )}
      </DialogContent>
      <DialogActions>
          <Button variant="outlined" onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvestigationDialog;
