import React, { useState, useEffect } from "react";
import {
    Typography,
    Grid,
    Button,
    CircularProgress,
    Card,
    DialogTitle,
    DialogContent,
    DialogActions,
    ButtonGroup,
    Popper,
    Grow,
    MenuItem,
    ClickAwayListener,
    Paper,
    MenuList,
    Dialog,
    FormControlLabel,
    Checkbox,
    Menu,
    TextField,

} from "@material-ui/core";
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import axios from "axios";

const RaiseTicketButton = (props) => {
    const {
        storeOrderId,
        trackingNumber,
        setOrderEvents
    } = props;

    const [openRaiseTicket, setOpenRaiseTicket] = useState(false);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState(null);
    const [disputeAll, setDisputeAll] = useState(false);

    const raiseTicket = () => {
        setLoading(true);
        axios
            .get(`ControlCenter/SendTicketRequest/${storeOrderId}/${trackingNumber}`)
            .then((res) => {
                setOrderEvents(res.data);
            })
            .catch((e) => {
                // console.log(e)
            })
            .finally((e) => {
                setOpenRaiseTicket(false);
                setLoading(false);
            });
        axios
            .get(
                `ControlCenter/RaiseTicketManually/${storeOrderId}/${trackingNumber}/${type}`
            )
            .then((res) => {
                // console.log(res);
                setOrderEvents(res.data);
            })
            .catch((e) => {
                // console.log(e)
            });
    };

    const disputeWholeOrder = () => {
        setLoading(true);
        axios
            .get(`ControlCenter/SendTicketRequest/${storeOrderId}/${trackingNumber}`)
            .then((res) => {
                setOrderEvents(res.data);
            })
            .catch((e) => {
                // console.log(e)
            })
            .finally((e) => {
                setOpenRaiseTicket(false);
                setLoading(false);
            });
        axios
            .post(`ControlCenter/DisputeWholeOrderSelenium/${storeOrderId}/${trackingNumber}`)
            .then((res) => {
                // console.log(res)
            })
            .catch((e) => {
                // console.log(e)
            });
    };

    const RaiseTicketConfirmation = () => {
        return (
            <Dialog
                open={openRaiseTicket}
                keepMounted
                onClose={() => {
                    setOpenRaiseTicket(false);
                    setDisputeAll(false);
                    setType(null);
                }}
            >
                <DialogTitle>
                    {type === "update"
                        ? "Raise Ticket"
                        : type === "disputed"
                            ? "Dispute Deilvery"
                            : disputeAll
                                ? "Dispute whole order"
                                : ""}
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to raise a ticket?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <React.Fragment>
                            <Button
                                variant="contained"
                                color="default"
                                onClick={() => {
                                    setOpenRaiseTicket(false);
                                    setDisputeAll(false);
                                    setType(null);
                                }}
                            >
                                Go Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    if (disputeAll) {
                                        disputeWholeOrder();
                                    } else {
                                        raiseTicket(type);
                                    }
                                }}
                            >
                                Raise Ticket
                            </Button>
                        </React.Fragment>
                    )}
                </DialogActions>
            </Dialog>
        );
    };

    const DisputeDeliveryButton = (props) => {
        const disputeOptions = ["Dispute Delivery", "This Package", "Whole Order"];
        const anchorRef = React.useRef(null);

        const [open, setOpen] = useState(false);
        const [selectedIndex, setSelectedIndex] = useState(0);

        const handleMenuItemClick = (event, index) => {
            if (index === 1) {
                setType("disputed");
                setOpenRaiseTicket(true);
            } else if (index === 2) {
                // disputeWholeOrder();
                setDisputeAll(true);
                setOpenRaiseTicket(true);
            }

            setOpen(false);
        };

        const handleToggle = () => {
            setOpen((prevOpen) => !prevOpen);
        };

        const handleClose = (event) => {
            if (anchorRef.current && anchorRef.current.contains(event.target)) {
                return;
            }

            setOpen(false);
        };

        return (
            <Grid container direction="column" alignItems="center">
                <Grid item xs={12}>
                    <ButtonGroup
                        variant="contained"
                        color="primary"
                        ref={anchorRef}
                        aria-label="split button"
                    >
                        <Button size="small">{disputeOptions[selectedIndex]}</Button>
                        <Button
                            color="primary"
                            size="small"
                            aria-controls={open ? "split-button-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="menu"
                            onClick={handleToggle}
                        >
                            <ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        style={{ zIndex: "99999999999999" }}
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === "bottom" ? "center top" : "center bottom",
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-delayed-order">
                                            {disputeOptions.map((option, index) => (
                                                <MenuItem
                                                    key={option}
                                                    disabled={index === 0}
                                                    selected={index === selectedIndex}
                                                    onClick={(event) => handleMenuItemClick(event, index)}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Grid>
            </Grid>
        );
    };

    return (
        <React.Fragment>
            {openRaiseTicket && RaiseTicketConfirmation()}
            <div style={{display: "flex"}}>
                <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={() => {
                        setType("update");
                        setOpenRaiseTicket(true);
                    }}
                    style={{
                        marginRight: "10px",
                        width: "200px"
                    }}
                >
                    Raise Ticket
                </Button>
                <DisputeDeliveryButton />
            </div>
        </React.Fragment>
    );
}

export default RaiseTicketButton;

