import React, { useState, useEffect } from "react";
import {
    Typography,
    Button,
    CircularProgress,
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    TextField,

} from "@material-ui/core";
import axios from "axios";

const CreateClaimDocumentsConfirmation = (props) => {
    const {
        orderId,
        trackingNumber,
        setOrderEvents,
        openClaimConfirmation,
        setOpenClaimConfirmation,
        getDelayedOrders
    } = props;

    const [claimNotes, setClaimNotes] = useState("");
    const [loading, setLoading] = useState(false);

    return (
        <Dialog
            open={openClaimConfirmation}
            keepMounted
            onClose={(_, reason) => {
                if (reason !== "backdropClick") {
                    setOpenClaimConfirmation(false);
                }
            }}
        >
            <DialogTitle>Create Claim Documents?</DialogTitle>
            <DialogContent>
                <Typography>
                    This will create the necessary documents for a claims invoice
                </Typography>
                <TextField
                    style={{ marginTop: "10px" }}
                    label="Notes"
                    placeholder="Place any notes for the statement of record in here"
                    InputLabelProps={{ shrink: true }}
                    multiline={true}
                    rowsMax={7}
                    rows={7}
                    fullWidth
                    variant="outlined"
                    value={claimNotes}
                    onChange={e => setClaimNotes(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                {loading ? (
                    <CircularProgress style={{ margin: 5 }} />
                ) : (
                    <React.Fragment>
                        <Button
                            variant="contained"
                            color="default"
                            onClick={() => {
                                setOpenClaimConfirmation(false);
                            }}
                        >
                            Go Back
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setLoading(true);
                                axios.post(`ControlCenter/QueueClaimForm`, {
                                    orderId: orderId,
                                    trackingNumber: trackingNumber,
                                    notes: claimNotes
                                }).then((res) => {
                                    debugger;
                                    if (res.data.orderEvents.length > 0 && res.data.files.length === 0) {
                                        setOrderEvents(res.data.orderEvents);
                                        setLoading(false);
                                        setOpenClaimConfirmation(false);
                                    } else if (res.data.files.length <= 5) {
                                        var count = 0;

                                        for (var i = 0; i < res.data.files.length; i++) {
                                            axios.get(`ControlCenter/DownloadDriveFile/${res.data.files[i].value}/${res.data.files[i].key}`, {
                                                params: {
                                                    cacheBustTimestamp: Date.now(), // prevents IE cache problems on re-download
                                                },
                                                responseType: "blob",
                                                headers: {
                                                    Accept: "application/octet-stream",
                                                },
                                            })
                                                .then((r) => {
                                                    var fileName = r.headers["content-disposition"];
                                                    if(fileName.includes("attachment; filename=\"")){
                                                        fileName = fileName.replace("attachment; filename=\"", "");
                                                        fileName = fileName.split("\";")[0];
                                                    } else {
                                                        fileName = fileName.replace("attachment; filename=", "");
                                                        fileName = fileName.split(";")[0];
                                                    }

                                                    const blob = new Blob([r.data], { type: "application/pdf" });

                                                    const url = window.URL.createObjectURL(blob);
                                                    const a = document.createElement("a");
                                                    a.style.display = "none";
                                                    a.href = url;
                                                    a.setAttribute("download", `${fileName}.pdf`);

                                                    document.body.appendChild(a);
                                                    a.click();
                                                    window.URL.revokeObjectURL(url);
                                                }).finally(() => {
                                                    count++;
                                                    if (res.data.files.length === count) {
                                                        getDelayedOrders();
                                                        setLoading(false);
                                                        setOpenClaimConfirmation(false);
                                                        setOrderEvents(res.data.orderEvents);
                                                    }
                                                })
                                        }
                                    }
                                })
                            }}
                        >
                            Create documents
                        </Button>
                    </React.Fragment>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default CreateClaimDocumentsConfirmation;