import React, { useState } from "react";
import { Search as SearchIcon } from "@material-ui/icons";
import { InputAdornment, TextField } from "@material-ui/core";
const SkuManagerFilter = ({ handleFilterChange, keyword }) => {
  const [keywordlookUp, setKeywordlookUp] = useState(keyword);
  return (
    <div style={{ flex: "1" }}>
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        label="Search Sku (Press Enter to Search)"
        onChange={(e) => setKeywordlookUp(e.target.value)}
        value={keywordlookUp}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleFilterChange(keywordlookUp);
          }
        }}
      />
    </div>
  );
};

export default SkuManagerFilter;
