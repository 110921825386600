
import { Typography } from "@material-ui/core";
import React from "react";
import AdminLayout from "./AdminLayout/AdminLayout";

const AdminPanel = () => {
  return (
    <AdminLayout>
      <Typography variant="h3">Welcome to the Admin Panel aka Nate's Ninja Hub!</Typography> 
      <Typography>Search for customers using the search bar above</Typography>
      <Typography>Running env variables in:</Typography>
      <Typography>{process.env.REACT_APP_ADMIN_PANEL}</Typography>
    </AdminLayout>
  );
};

export default AdminPanel;
