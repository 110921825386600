import {
  Box,
  Button,
  Collapse,
  IconButton,
  Input,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import NatureIcon from "@material-ui/icons/Nature";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import WarningIcon from "@material-ui/icons/Warning";
import moment from "moment/moment";
import React from "react";
import { useState } from "react";
import CurrencyFormatter from "../../Shared/CurrencyFormatter";
import "./subscriptionsRow.scss";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import { isEmpty } from "../../Utils/helperFunctions";
import { useEffect } from "react";
import { retrievePaymentMethodById } from "../../../repository/stripe";
import PaymentIcon from "@material-ui/icons/Payment";
import AddUpdateAddressDialog from "../CustomerAddress/AddUpdateAddressDialog";
import ManagePaymentMethodModal from "../ManagePaymentMethodModal/ManagePaymentMethodModal";
import QuantityInput from "../QuantityInput/QuantityInput";
import SubscriptionProductsModal from "../SubscriptionProductsModal/SubscriptionProductsModal";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import { useCustomer } from "../../../context/customer/CustomerContext";
import { calculateOrderSummary } from "../../../repository/orders";
import SubscriptionFrequencyModal from "../SubscriptionFrequencyModal/SubscriptionFrequencyModal";
import { useHistory, Link } from "react-router-dom/cjs/react-router-dom";
import { updateSubscriptionOrderTotal } from "../../../repository/subscriptions";
const SubscriptionsRow = ({
  subscription,
  handleUpdateStatus,
  handleUpdateAddress,
  handleSetIsRural,
  paymentMethods,
  handleUpdatePayment,
  handleQuantityChange,
  isUpdating,
  handleNewProduct,
  handleRemoveItem,
  handleEditFrequency,
  handleProcessSubscriptionOrder,
  deleteSubscriptionOrder,
  isProcessing,
  justProcessed,
}) => {
  const history = useHistory();
  const { customer } = useCustomer();
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [open, setOpen] = useState(false);
  const [openFrequencyModal, setOpenFrequencyModal] = useState(false);
  const [openAddressChangeModal, setOpenAddressChangeModal] = useState(false);
  const [showManagePaymentMethods, setShowManagePaymentMethods] =
    useState(false);
  const [openProductsModal, setOpenProductsModal] = useState(false);
  const [isAddingNewProduct, setIsAddingNewProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [orderSummary, setOrderSummary] = useState(null);
  const [editingOrderTotal, setEditingOrderTotal] = useState(false);
  const [newOrderTotal, setNewOrderTotal] = useState(null);

  useEffect(() => {
    if (subscription && open && !selectedPayment) {
      fetchPaymentMethod();
    }
  }, [subscription, open]);

  useEffect(() => {
    if (
      selectedPayment &&
      subscription.paymentMethodId !== selectedPayment.id
    ) {
      handleUpdatePayment(subscription, selectedPayment.id);
    }
  }, [selectedPayment]);

  useEffect(() => {
    if (subscription && open) {
      handleCalculateOrderSummary();
    }
  }, [subscription?.lineItems, open]);

  const handleCalculateOrderSummary = async () => {
    setOrderSummary(
      await calculateOrderSummary(
        subscription?.lineItems,
        subscription?.shippingAddress,
        subscription?.eligibleLoyaltyPercentage || 5,
        subscription?.discountForSubscription || 0
      )
    );
  };

  const handleUpdateOrderTotal = async () => {
    if (newOrderTotal !== orderSummary?.total) {
      setOrderSummary(
        await updateSubscriptionOrderTotal(
          subscription?.id,
          subscription?.lineItems,
          subscription?.shippingAddress,
          subscription?.eligibleLoyaltyPercentage || 5,
          subscription?.discountForSubscription || 0,
          newOrderTotal
        )
      );
    }
  };

  const fetchPaymentMethod = async () => {
    if (subscription?.paymentMethodId) {
      const paymentMethod = await retrievePaymentMethodById(
        subscription?.paymentMethodId
      );

      if (paymentMethod) {
        setSelectedPayment(paymentMethod);
      }
    }
  };

  const toggleRow = () => {
    setOpen(!open);
  };

  const handleClickStatusButton = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (subscription?.status === "ACTIVE") {
      handleUpdateStatus(subscription, "PAUSED");
    } else {
      handleUpdateStatus(subscription, "ACTIVE");
    }
  };

  const reloadAddresses = async (address) => {
    await handleUpdateAddress(subscription, address);
    setOpenAddressChangeModal(false);
  };

  const handleOpenPaymentMethods = () => {
    if (paymentMethods?.length) {
      setShowManagePaymentMethods(true);
    }
  };

  const doDecrement = (item) => {
    handleQuantityChange(subscription, item, Number(item.quantity || 0) - 1);
  };
  const doIncrement = (item) => {
    handleQuantityChange(subscription, item, Number(item.quantity || 0) + 1);
  };

  const handleOpenSwapProductModal = (selectedLine) => {
    setSelectedProduct(selectedLine);
    setIsAddingNewProduct(false);
    setOpenProductsModal(true);
  };

  const handleOpenNewProductModal = () => {
    setSelectedProduct(null);
    setIsAddingNewProduct(true);
    setOpenProductsModal(true);
  };

  const handleSelectNewProduct = async (variant) => {
    await handleNewProduct(
      subscription,
      selectedProduct,
      variant,
      isAddingNewProduct
    );
    handleCloseProductsModal();
  };

  const handleCloseProductsModal = () => {
    setSelectedProduct(null);
    setIsAddingNewProduct(false);
    setOpenProductsModal(false);
  };

  const handleStartEditOrderTotal = () => {
    setEditingOrderTotal(true);
    setNewOrderTotal(orderSummary?.total);
  };

  const handleCancelEditOrderTotal = () => {
    setEditingOrderTotal(false);
  };

  const handleSaveOrderTotal = async () => {
    await handleUpdateOrderTotal();
    setEditingOrderTotal(false);
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell
          component="th"
          scope="row"
          onClick={toggleRow}
          className="subscriptions-row-outer-cell"
        >
          <Link
            to={`/AdminPanel/Customers/?id=${subscription.shopifyCustomerId}`}
          >
            {`${subscription.shippingAddress?.firstName} ${subscription.shippingAddress?.lastName}`}
          </Link>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          onClick={toggleRow}
          className="subscriptions-row-outer-cell"
        >
          <div className="subscriptions-row-date-cell">
            <Typography variant="body1">
              {moment(subscription.nextShipment).format("MMMM Do Y")}
            </Typography>
            {subscription.errorMessage && (
              <Tooltip title={`${subscription.errorMessage}`}>
                <WarningIcon />
              </Tooltip>
            )}
            {!subscription.paymentMethodId && (
              <Tooltip title={`Autoship is missing payment method`}>
                <MoneyOffIcon />
              </Tooltip>
            )}
          </div>

          <div className={"subscriptions-row-editLinkContainer"}>
            <span
              role={"presentation"}
              onClick={(e) => {
                e.stopPropagation();
                setOpenFrequencyModal(true);
              }}
            >
              Edit
            </span>
          </div>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          onClick={toggleRow}
          className="subscriptions-row-outer-cell"
        >
          <Typography variant="body1">{subscription.status}</Typography>
        </TableCell>
        <TableCell className="subscriptions-row-outer-cell subscriptions-row-statusBtn-container">
          <Button
            size={"small"}
            variant="outlined"
            color="primary"
            onClick={handleClickStatusButton}
          >
            <div className={"subscriptions-row-statusBtn"}>
              {subscription?.status === "ACTIVE" ? (
                <>
                  <PauseCircleOutlineIcon />
                  <span>PAUSE AUTOSHIP</span>
                </>
              ) : (
                <>
                  <PlayCircleOutlineIcon />
                  <span>RESUME AUTOSHIP</span>
                </>
              )}
            </div>
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className={"subscriptions-row-addressDetailContainer"}>
                <div className={"subscriptions-row-addressContainer"}>
                  <span>
                    {`Deliver to 
                ${
                  subscription?.shippingAddress?.firstName
                    ? subscription?.shippingAddress?.firstName + ", "
                    : ""
                }
                ${
                  subscription?.shippingAddress?.companyName !== null &&
                  !isEmpty(subscription?.shippingAddress?.companyName)
                    ? subscription?.shippingAddress?.companyName + ", "
                    : ""
                }
                ${
                  subscription?.shippingAddress?.addressLine1
                    ? subscription?.shippingAddress?.addressLine1 + ", "
                    : ""
                }
                ${
                  subscription?.shippingAddress?.addressLine2
                    ? subscription?.shippingAddress?.addressLine2 + ", "
                    : ""
                }
                ${
                  subscription?.shippingAddress?.city
                    ? subscription?.shippingAddress?.city + ", "
                    : ""
                }
                ${
                  subscription?.shippingAddress?.postCode
                    ? subscription?.shippingAddress?.postCode + "."
                    : ""
                }
              `}
                  </span>
                  <div className={"subscriptions-row-editLinkContainer"}>
                    <span
                      role={"presentation"}
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenAddressChangeModal(true);
                      }}
                    >
                      Edit
                    </span>
                  </div>
                </div>
              </div>

              <div className={"subscriptions-row-paymentDetailContainer"}>
                <div className={"subscriptions-row-ccBrand"}>
                  <PaymentIcon />
                </div>
                {selectedPayment && (
                  <div className="subscriptions-row-ccDetails">
                    <div>{`**** ${selectedPayment?.card?.last4}`}</div>
                    <div>{`EXP ${
                      selectedPayment?.card?.exp_month < 10
                        ? "0" + selectedPayment?.card?.exp_month
                        : selectedPayment?.card?.exp_month
                    }/${selectedPayment?.card?.exp_year}`}</div>
                  </div>
                )}
                <div className={"subscriptions-row-editLinkContainer"}>
                  <span
                    role={"presentation"}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenPaymentMethods();
                    }}
                  >
                    Edit
                  </span>
                </div>
              </div>
              {subscription?.hasPaymentFailed && (
                <>
                  <div className={"subscriptions-row-paymentDetailContainer"}>
                    <div className={"subscriptions-row-ccBrand"}>
                      <WarningIcon />
                    </div>

                    <div className="subscriptions-row-ccDetails">
                      {`The Payment has failed.`}
                    </div>
                  </div>
                  <div className={"subscriptions-row-paymentDetailContainer"}>
                    <div className="subscriptions-row-ccDetails">
                      {`Reason: ${subscription.errorMessage}`}
                    </div>
                  </div>
                  <div className={"subscriptions-row-paymentDetailContainer"}>
                    <div className="subscriptions-row-ccDetails">
                      {`Number of retries: ${subscription.paymentRetries}`}
                    </div>
                  </div>
                  <div className={"subscriptions-row-paymentDetailContainer"}>
                    <div className="subscriptions-row-ccDetails">
                      {`Has Customer been notified? ${
                        subscription.errorNotificationSent ? "Yes" : "No"
                      }`}
                    </div>
                  </div>
                </>
              )}

              <div className={"subscriptions-row-newProductContainer"}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => handleOpenNewProductModal()}
                  startIcon={<AddIcon />}
                >
                  Add new product
                </Button>
              </div>

              <div className={"subscriptions-row-itemList"}>
                {subscription?.lineItems?.map((item, index) => {
                  return (
                    <div
                      className={"subscriptions-row-itemWrapper"}
                      key={index}
                    >
                      <div className={"subscriptions-row-closeContainer"}>
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={(e) => handleRemoveItem(subscription, item)}
                          size="small"
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                      <div
                        className={"subscriptions-row-itemContainer"}
                        key={index}
                      >
                        <div
                          role={"presentation"}
                          className={"subscriptions-row-imageContainer"}
                        >
                          <img
                            alt={item.productTitle}
                            src={item.imageSrc}
                          ></img>
                        </div>
                        <div>
                          <span className={"subscriptions-row-itemName"}>
                            {item.productTitle}
                          </span>
                          <div className={"subscriptions-row-orderItemMeta"}>
                            <span className={"subscriptions-row-meta"}>
                              {item.variantTitle} {" x "} {item.quantity}{" "}
                              <CurrencyFormatter
                                useDollar
                                amount={item.price}
                              />{" "}
                              {" each"}
                            </span>
                            <QuantityInput
                              value={item.quantity}
                              onIncrement={() => doIncrement(item)}
                              onDecrement={() => doDecrement(item)}
                              onChange={(e) =>
                                handleQuantityChange(
                                  subscription,
                                  item,
                                  e.currentTarget.value
                                )
                              }
                              disabled={isUpdating}
                            />
                            <div
                              className={
                                "subscriptions-row-mobileActionContainer"
                              }
                            >
                              <div
                                className={"subscriptions-row-itemTotalMobile"}
                              >
                                <CurrencyFormatter
                                  useDollar
                                  amount={item.quantity * item.price}
                                />
                              </div>
                              <div
                                className={
                                  "subscriptions-row-actionContainerMobile"
                                }
                              >
                                <Button
                                  variant="outlined"
                                  onClick={() =>
                                    handleOpenSwapProductModal(item)
                                  }
                                  fullWidth
                                  className={"subscriptions-row-addItemToCart"}
                                  size={"small"}
                                  level={"secondary"}
                                >
                                  Swap Product
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={"subscriptions-row-actionContainer"}>
                          <Button
                            variant="outlined"
                            onClick={() => handleOpenSwapProductModal(item)}
                            fullWidth
                            className={"subscriptions-row-addItemToCart"}
                            size={"small"}
                            level={"secondary"}
                          >
                            Swap Product
                          </Button>
                        </div>
                        <div className={"subscriptions-row-itemTotal"}>
                          <CurrencyFormatter
                            useDollar
                            amount={item.quantity * item.price}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div className={"subscriptions-row-transactionDetails"}>
                  <div className={"subscriptions-row-transactionalGrid"}>
                    <>
                      <span
                        className={"subscriptions-row-meta"}
                      >{`Subtotal`}</span>
                      <span className={"subscriptions-row-meta"}>
                        <CurrencyFormatter
                          useDollar
                          amount={orderSummary?.subtotal}
                        />
                      </span>
                    </>
                    {orderSummary?.loyaltyDiscount !== 0 && (
                      <>
                        <span
                          className={"subscriptions-row-meta"}
                        >{`Loyalty Discount `}</span>
                        <span className={"subscriptions-row-meta"}>
                          <CurrencyFormatter
                            appendZero
                            useDollar
                            isDiscountCode
                            amount={orderSummary?.loyaltyDiscount}
                          />
                        </span>
                      </>
                    )}
                    {orderSummary?.autoshipDiscount !== 0 && (
                      <>
                        <span
                          className={"subscriptions-row-meta"}
                        >{`Autoship every ${subscription.subscriptionFrequency} weeks`}</span>
                        <span className={"subscriptions-row-meta"}>
                          <CurrencyFormatter
                            useDollar
                            amount={orderSummary?.autoshipDiscount}
                            isDiscountCode
                          />
                        </span>
                      </>
                    )}
                    <>
                      <span className={"subscriptions-row-meta"}>
                        {subscription.shippingAddress.ruralDelivery ? (
                          <IconButton
                            onClick={() =>
                              handleSetIsRural(subscription, false)
                            }
                            title="To Urban Shipping"
                          >
                            <NatureIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => handleSetIsRural(subscription, true)}
                            title="To Rural Shipping"
                          >
                            <LocationCityIcon />
                          </IconButton>
                        )}
                        {`Shipping and Delivery`}
                      </span>
                      <span className={"subscriptions-row-meta"}>
                        <CurrencyFormatter
                          useDollar
                          amount={orderSummary?.shippingCost}
                        />
                      </span>
                    </>
                    <span className={"subscriptions-row-grandTotal"}>
                      {editingOrderTotal && (
                        <>
                          <IconButton onClick={handleSaveOrderTotal}>
                            <CheckIcon />
                          </IconButton>
                          <IconButton onClick={handleCancelEditOrderTotal}>
                            <ClearIcon />
                          </IconButton>
                        </>
                      )}
                      Order Total{" "}
                    </span>
                    <span
                      className={`subscriptions-row-grandTotal subscriptions-row-bold`}
                    >
                      {!editingOrderTotal ? (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={handleStartEditOrderTotal}
                        >
                          <CurrencyFormatter
                            useDollar
                            amount={orderSummary?.total}
                          />
                        </span>
                      ) : (
                        <>
                          <Input
                            type="number"
                            value={newOrderTotal}
                            onChange={(e) => setNewOrderTotal(e.target.value)}
                          />
                        </>
                      )}
                    </span>
                  </div>
                </div>
                <div className={"subscriptions-row-actions"}>
                  <div className={"subscriptions-row-deletenow"}>
                    {isProcessing ? (
                      <Typography>Processing ... </Typography>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => deleteSubscriptionOrder(subscription)}
                        startIcon={<ClearIcon />}
                      >
                        Delete Autoship For Good
                      </Button>
                    )}
                  </div>
                  {subscription.status === "ACTIVE" && (
                    <div className={"subscriptions-row-processnow"}>
                      {isProcessing ? (
                        <Typography>Processing ... </Typography>
                      ) : justProcessed ? (
                        <Typography>Just Processed</Typography>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() =>
                            handleProcessSubscriptionOrder(subscription)
                          }
                          startIcon={<CheckIcon />}
                        >
                          Process Autoship Now
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell />
      </TableRow>
      {openAddressChangeModal && (
        <AddUpdateAddressDialog
          open={openAddressChangeModal}
          handleClose={() => {
            setOpenAddressChangeModal(false);
          }}
          type="subscription"
          customerId={subscription.customerId}
          addressToEdit={subscription?.shippingAddress}
          reloadAddresses={reloadAddresses}
        />
      )}

      {showManagePaymentMethods && (
        <ManagePaymentMethodModal
          paymentMethods={paymentMethods}
          open={showManagePaymentMethods}
          handleClose={() => setShowManagePaymentMethods(false)}
          selectedPayment={selectedPayment}
          setSelectedPayment={setSelectedPayment}
        />
      )}
      {openProductsModal &&
        subscription &&
        (selectedProduct || isAddingNewProduct) && (
          <SubscriptionProductsModal
            open={openProductsModal}
            isAddingNewProduct={isAddingNewProduct}
            handleClose={handleCloseProductsModal}
            handleSelectNewProduct={handleSelectNewProduct}
          />
        )}

      {openFrequencyModal && subscription && (
        <SubscriptionFrequencyModal
          open={openFrequencyModal}
          handleClose={() => setOpenFrequencyModal(false)}
          subscription={subscription}
          handleEditFrequency={handleEditFrequency}
        />
      )}
    </React.Fragment>
  );
};

export default SubscriptionsRow;
