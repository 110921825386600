import React from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

const Snacky = (props) => {
  const { open, severity, text } = props.snackprops;
  const { setSnackBarProps } = props;
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={open}
      autoHideDuration={5000}
      onClose={() => setSnackBarProps(severity, text, false)}
    >
      <MuiAlert severity={severity}>{text}</MuiAlert>
    </Snackbar>
  );
};

export default Snacky;