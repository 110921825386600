import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import { convertIdToShopifyGraphql } from "../../components/Utils/shopifyConverter";
import {
  findCustomerById,
  findFirestoreCustomerByShopifyId,
} from "../../repository/customers";
const defaultState = {
  customer: null,
  firestoreCustomer: null,
  paymentMethods: [],
};

export const CustomerContext = createContext(defaultState);

export const CustomerContextProvider = ({ children }) => {
  const [state, setState] = useState(defaultState);

  const findCustomerDetails = async (customer) => {
    try {
      const { data } = await axios.get(
        `Customer/GetCustomerLoyaltyTier/${customer?.id}`
      );
      customer.loyaltyTier = data;
      const firestoreDetails = await findFirestoreCustomerAndPayemntMethodsByShopifyId(
        customer?.id
      );

      if (!firestoreDetails.firestoreCustomer) {
        await axios
          .post(`FirestoreCustomer/MigrateCustomer/${customer?.id}`)
          .then((res) => {
            firestoreDetails.firestoreCustomer = res.data;
          })
          .catch(() => {})
          .finally(() => {});
      }
      if (
        !customer?.stripeId &&
        firestoreDetails?.firestoreCustomer?.stripeId
      ) {
        customer.stripeId = firestoreDetails.firestoreCustomer.stripeId;
        await axios.post(
          `Customer/SaveStripeId/${customer?.id}/${firestoreDetails.firestoreCustomer.stripeId}`
        );
      }
      setState({
        ...state,
        customer: customer,
        firestoreCustomer: firestoreDetails.firestoreCustomer,
        paymentMethods: firestoreDetails.paymentMethods,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const findFirestoreCustomerAndPayemntMethodsByShopifyId = async (shopifyId) => {
    let firestoreDetails = {
      firestoreCustomer: null,
      paymentMethods: [],
    };
    if (shopifyId) {
      const _firestoreCustomer = await findFirestoreCustomerByShopifyId(shopifyId);
      firestoreDetails.firestoreCustomer = _firestoreCustomer;
      if (_firestoreCustomer && _firestoreCustomer.stripeId) {
        const _paymentMethods = await getSavedPaymentMethodForCustomer(
          _firestoreCustomer.stripeId
        );
        firestoreDetails.paymentMethods = _paymentMethods;
      }
    }
    return firestoreDetails;
  };

  const getSavedPaymentMethodForCustomer = async (stripeId) => {
    let _paymentMethods = [];
    try {
      const { data } = await axios.get(
        `Stripe/GetSavedPaymentMethodForCustomer/${stripeId}`
      );
      if (data) {
        _paymentMethods = data;
      }
      return _paymentMethods;
    } catch (err) {
      console.error(err);
      return _paymentMethods;
    }
  };

  const refreshPaymentMethods = async (stripeId) => {
    try {
      const paymentMethods = await getSavedPaymentMethodForCustomer(stripeId);
      setState({
        ...state,
        paymentMethods: paymentMethods,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const fetchCustomerDetailsById = async (customerId) => {
    try {
      const _customer = await findCustomerById(customerId);
      if (_customer) {
        findCustomerDetails(_customer);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const updateCustomerDetails = async (customerDetails) => {
    let _customer = { ...state.customer };
    _customer.firstName = customerDetails.firstName;
    _customer.lastName = customerDetails.lastName;
    _customer.email = customerDetails.email;
    _customer.phone = customerDetails.phoneNumber;

    let _firestoreCustomer = { ...state.firestoreCustomer };
    _firestoreCustomer.firstName = customerDetails.firstName;
    _firestoreCustomer.lastName = customerDetails.lastName;
    _firestoreCustomer.email = customerDetails.email;
    _firestoreCustomer.phoneNumber = customerDetails.phoneNumber;

    setState({
      ...state,
      customer: _customer,
      firestoreCustomer: _firestoreCustomer,
    });
  };

  return (
    <CustomerContext.Provider
      value={{
        customer: state.customer,
        firestoreCustomer: state.firestoreCustomer,
        paymentMethods: state.paymentMethods,
        findCustomerDetails,
        fetchCustomerDetailsById,
        refreshPaymentMethods,
        updateCustomerDetails,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export function useCustomer() {
  return useContext(CustomerContext);
}
