import axios from "axios";
import { createAction } from "@reduxjs/toolkit";

const getUsersFailure = createAction("GET_USERS_FAILURE");
const getUsersSuccess = createAction("GET_USERS_SUCCESS");

export const updateOAuthStatus = createAction("UPDATE_OAUTH_STATUS");
export const updateOAuthEmail = createAction("UPDATE_OAUTH_EMAIL");
export const updateActiveUser = createAction("UPDATE_ACTIVE_USER");

const reduceUsers = (usersList) => {
  var userArr = [];
  usersList.forEach((element) => {
    userArr.push(element.email);
  });
  return userArr;
};

export const idActiveUser = (email, allUsers) => (dispatch) => {
  for (let i = 0; i < allUsers.length; i++) {
    if (email === "") {
      dispatch(updateOAuthEmail(email));
      dispatch(updateActiveUser({ id: 0, firstName: "" }));
    } else {
      if (allUsers[i].email === email) {
        dispatch(updateOAuthEmail(email));
        dispatch(updateActiveUser(allUsers[i]));
      }
    }
  }
};

export const getAllUsers = () => async (dispatch) => {
  try {
    const res = await axios.get("Users/GetUser");
    if (res.status !== "500") {
      dispatch(
        getUsersSuccess({ userList: reduceUsers(res.data), allUsers: res.data })
      );
    } else {
      dispatch(getUsersFailure(res.data));
    }
  } catch (err) {
    dispatch(getUsersFailure(err));
  }
};
