import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
    Modal,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
    Grid,
    FormControl,
    InputLabel,
    Select,
    Input,
    MenuItem,
    CircularProgress,
    TextField
} from "@material-ui/core";
import {
    Delete as DeleteIcon,
    Close as CloseIcon
} from "@material-ui/icons";
import "./PostalZone.scss";
import axios from "axios";
import ConfirmDialog from "./ConfirmDialog";
import { SketchPicker } from 'react-color';

const useStyles = makeStyles(theme => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        minHeight: "80vH",//390,
        minWidth: "50vW",
        maxHeight: "80vH",
        maxWidth: "70vW",
        overflow: "auto"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
        maxWidth: 300,
    },
}));

const courierList = [
    "Aramex",
    "NZPost",
    "Express North",
    "Express South",
    "Express East",
    "Express West",
];

const ZoneMenu = (props) => {
    const classes = useStyles();
    const {
        modalState,
        handleModal,
        deliveryZones,
        refreshDeliveryZones
    } = props;

    const [copyOfDeliveryZones, setCopyOfDeliveryZones] = useState([]);
    const [zonesToDelete, setZonesToDelete] = useState([]);
    const [selectedZone, setSelectedZone] = useState(null);

    const [saving, setSaving] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    const sortZones = (a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        return 0;
    }

    const getNewId = () => {
        var highestExistingId = 0;
        for (var i = 0; i < copyOfDeliveryZones.length; i++) {
            if (highestExistingId < copyOfDeliveryZones[i].id) {
                highestExistingId = copyOfDeliveryZones[i].id;
            }
        }
        return highestExistingId + 1;
    }

    const handleDeleteZone = (id) => {
        var newZonesToDelete = [...zonesToDelete];
        newZonesToDelete.push(id);
        var newCopyOfDeliveryZones = [...copyOfDeliveryZones];
        newCopyOfDeliveryZones = newCopyOfDeliveryZones.filter(zone => zone.id !== id);

        setCopyOfDeliveryZones(newCopyOfDeliveryZones);
        setZonesToDelete(newZonesToDelete);
    }

    const handleSave = () => {
        setSaving(true);
        axios.patch(`DeliveryZones/SaveDeliveryZones`, {
            deliveryZones: copyOfDeliveryZones,
            deliveryZonesToDelete: zonesToDelete
        })
            .then((res) => {
                refreshDeliveryZones();
                handleModal();
            }).catch((e) => {
                console.log(e)
            }).finally(() => {
                setSaving(false);
            })
    }

    const editTextField = (newZone, propertyToEdit, newValue) => {
        var newCopyOfDeliveryZones = [...copyOfDeliveryZones];
        var index = newCopyOfDeliveryZones.findIndex(oldZone => oldZone.id === newZone.id);

        if (index !== -1) {
            var zoneToUpdate = newCopyOfDeliveryZones[index];
            zoneToUpdate[propertyToEdit] = newValue;

            setCopyOfDeliveryZones(newCopyOfDeliveryZones);
        }

    }

    const addZone = () => {
        var newCopyOfDeliveryZones = [...copyOfDeliveryZones];
        newCopyOfDeliveryZones.push({
            id: getNewId(),
            name: "New Zone",
            courier: "NZPost",
            hex: "yellow"
        });
        setCopyOfDeliveryZones(newCopyOfDeliveryZones)
    }

    useEffect(() => {
        setCopyOfDeliveryZones([...deliveryZones].sort(sortZones))
    }, [deliveryZones]);

    return (
        <Modal
            open={modalState}
            onClose={(_, reason) => {
                if (reason !== "backdropClick") {
                    handleModal();
                }
            }}
            className={classes.modal}
        >
            <div>
                <AppBar position="static" style={{ background: "#3d4977" }}>
                    <Toolbar variant="dense" style={{ paddingRight: 0 }}>
                        <Typography
                            variant="h6"
                            align="center"
                            style={{ flexGrow: 1 }}
                        >
                            {`Zone Menu`}
                        </Typography>
                        <span style={{ display: "flex" }}>
                            <IconButton
                                onClick={handleModal}
                                disabled={saving}
                            >
                                <CloseIcon />
                            </IconButton>
                        </span>
                    </Toolbar>
                </AppBar>
                <div
                    className={classes.paper}
                >
                    {showConfirmDelete && (
                        <ConfirmDialog
                            open={showConfirmDelete}
                            title="Delete Zone?"
                            contentText={`Are you sure you want to delete ${selectedZone.name} zone?`}
                            handleClose={() => {
                                setShowConfirmDelete(false)
                                setSelectedZone(null);
                            }}
                            handleConfirm={() => {
                                handleDeleteZone(selectedZone.id);
                                setShowConfirmDelete(false)
                                setSelectedZone(null);
                            }}
                        />
                    )}
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => addZone()}
                        className="button-float-right"
                    >
                        Add Zone
                    </Button>
                    <Table id="zone-table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Zone Name</TableCell>
                                <TableCell>Courier</TableCell>
                                <TableCell>Color</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {copyOfDeliveryZones.map(zone => {
                                return (
                                    <TableRow key={`table-row-${zone.id}`}>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                value={zone.name}
                                                margin="normal"
                                                InputLabelProps={{ shrink: true }}
                                                onChange={e => {
                                                    editTextField(zone, "name", e.target.value)
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <FormControl className={classes.formControl}>
                                                <InputLabel>Zone</InputLabel>
                                                <Select
                                                    value={zone.courier}
                                                    onChange={e => editTextField(zone, "courier", e.target.value)}
                                                >
                                                    {courierList.map((courier) => (
                                                        <MenuItem key={`courier-zone-option-${courier}`} value={courier}>
                                                            {courier}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell>
                                            {/* <TextField
                                                fullWidth
                                                value={zone.hex}
                                                margin="normal"
                                                InputLabelProps={{ shrink: true }}
                                                onChange={e => {
                                                    editTextField(zone, "hex", e.target.value)
                                                }}
                                            /> */}
                                            <SketchPicker
                                                color={zone.hex}
                                                onChangeComplete={(color, event) => {
                                                    editTextField(zone, "hex", color.hex)
                                                }}
                                                disableAlpha={true}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={() => {
                                                    setShowConfirmDelete(true);
                                                    setSelectedZone(zone)
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                    {saving ? <CircularProgress />
                        : <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={() => handleSave()}
                            className="button-float-right"
                            disabled={saving}
                        >
                            {`Save & exit`}
                        </Button>
                    }

                </div>
            </div>
        </Modal>
    )
}

export default ZoneMenu;