import axios from "axios";

const findSubscriptionsByShopifyCustomerId = async (shopifyId) => {
  let subscriptions = [];
  const { data } = await axios.get(
    `Subscription/FindSubscriptionsByShopifyCustomerId/${shopifyId}`
  );
  if (data && data.length > 0) {
    data.forEach(async (sub) => {
      if (sub.status !== "DELETED") {
        if (sub.lineItems && sub?.lineItems?.length > 0) {
          subscriptions.push(sub);
        } else {
          await updateSubscriptionStatus(sub, "DELETED");
        }
      }
    });
  }

  return subscriptions;
};

const updateSubscriptionStatus = async (subscription, status) => {
  try {
    const { data } = await axios.post(
      `Subscription/UpdateSubscriptionStatus/${status}`,
      subscription
    );
    return data;
  } catch (err) {
    console.error(err);
  }
  return null;
};

const updateSubscriptionAddress = async (subscription, address) => {
  try {
    const { data } = await axios.post(
      `Subscription/UpdateSubscriptionAddress/${subscription.id}`,
      address
    );
    return data;
  } catch (err) {
    console.error(err);
  }
  return null;
};

const updateSubscriptionPayment = async (subscription, paymentId) => {
  try {
    const { data } = await axios.post(
      `Subscription/UpdateSubscriptionPayment/${paymentId}`,
      subscription
    );
    return data;
  } catch (err) {
    console.error(err);
  }
  return null;
};

const updateSubscriptionLineItems = async (subscription, lineItems) => {
  try {
    const { data } = await axios.post(
      `Subscription/UpdateSubscriptionLineItems/${subscription.id}`,
      lineItems
    );
    return data;
  } catch (err) {
    console.error(err);
  }
  return null;
};

const updateSubscriptionFrequency = async (
  subscription,
  nextShipment,
  subscriptionFrequency
) => {
  try {
    subscription.nextShipment = nextShipment;
    subscription.subscriptionFrequency = subscriptionFrequency;
    const { data } = await axios.post(
      `Subscription/UpdateSubscriptionFrequency/${subscription.id}`,
      subscription
    );
    return data;
  } catch (err) {
    console.error(err);
  }
  return null;
};

const updateSubscriptionIsRural = async (subscriptionId, isRural) => {
  try {
    const { data } = await axios.post(
      `Subscription/UpdateSubscriptionIsRural/${subscriptionId}`,
      { isRural }
    );
    return data;
  } catch (err) {
    console.error(err);
  }
  return null;
};

const getSubscriptionFrequencyList = () => {
  return [
    { label: "Deliver every 2 weeks", value: 2 },
    { label: "Deliver every 3 weeks", value: 3 },
    { label: "Deliver every 4 weeks (most common)", value: 4 },
    { label: "Deliver every 5 weeks", value: 5 },
    { label: "Deliver every 6 weeks", value: 6 },
    { label: "Deliver every 7 weeks", value: 7 },
    { label: "Deliver every 8 weeks", value: 8 },
  ];
};

const searchSubscriptions = async (filter) => {
  try {
    let filter_to_use = { ...filter };
    if (filter.status === "PROBLEM") {
      filter_to_use.status = null;
    }
    const { data } = await axios.post(
      `Subscription/searchSubscriptions`,
      filter_to_use
    );

    if (filter.status === "PROBLEM") {
      return data.filter((subscription) => isProblemSubscription(subscription));
    }
    return data;
  } catch (err) {
    console.error(err);
  }
  return null;
};
const createNewSubscription = async (subscription) => {
  try {
    const { data } = await axios.post(
      `Subscription/createNewSubscription`,
      subscription
    );
    return data;
  } catch (err) {
    console.error(err);
  }
  return null;
};

const processSubscriptionOrder = async (subscription) => {
  try {
    const { data } = await axios.post(
      `Subscription/ProcessSubscriptionOrder`,
      subscription
    );
    return data;
  } catch (err) {
    console.error(err);
  }
  return null;
};

const isProblemSubscription = (subscription) => {
  return subscription.errorMessage || !subscription.paymentMethodId;
};

const getNumberOfSubscriptionsWithError = (subscriptions) => {
  return subscriptions.filter((subscription) =>
    isProblemSubscription(subscription)
  ).length;
};

const updateSubscriptionOrderTotal = async (
  id,
  lineItems,
  shippingAddress,
  eligibleLoyaltyPercentage,
  autoshipDiscountAmount,
  newOrderTotal
) => {
  let orderSummary = null;

  const { data } = await axios.post(`AdminPanel/UpdateSubscriptionOrderTotal`, {
    id,
    lineItems,
    shippingAddress,
    eligibleLoyaltyPercentage,
    autoshipDiscountAmount,
    newOrderTotal,
  });
  if (data) {
    orderSummary = data;
  }

  return orderSummary;
};

export {
  findSubscriptionsByShopifyCustomerId,
  updateSubscriptionStatus,
  updateSubscriptionAddress,
  updateSubscriptionIsRural,
  updateSubscriptionPayment,
  updateSubscriptionLineItems,
  getSubscriptionFrequencyList,
  updateSubscriptionFrequency,
  searchSubscriptions,
  createNewSubscription,
  processSubscriptionOrder,
  getNumberOfSubscriptionsWithError,
  updateSubscriptionOrderTotal,
};
