import { createReducer } from "@reduxjs/toolkit";
import { addDays } from "../components/Utils/dateTime";
import _ from "lodash";

const initialState = {
  infinityNotesLoading: false,
};

const system = createReducer(initialState, {
  INFINITY_NOTES_LOADED: (state, action) => {
    return {
      ...state,
      infinityNotesLoading: false,
    };
  },
  INFINITY_NOTES_LOADING: (state, action) => {
    return {
      ...state,
      infinityNotesLoading: true,
    };
  },
  GET_INFINITY_NOTES: (state, action) => {
    return {
      ...state,
      infinityNotes: action.payload,
      infinityNotesLoading: false,
    };
  },
  EDIT_INFINITY_NOTE_FIELD: (state, action) => {
    const {
      payload: { index, value, field, width },
    } = action;

    if (index < 0) {
      return;
    }

    let inches;
    let inchesArr = [];
    let linePosition = 1.75;
    let lines = [];

    if (field === "message") {
      debugger;
      for (let i = 0; i < width.length; i++) {
        // inches = width.map((i) => i.getBoundingClientRect().width);
        inchesArr.push(
          (width[i].getBoundingClientRect().width / 96 / 2 + 2).toFixed(2) - 1
        );
      }
      lines = value.split("\n").map((m, index) => {
        if (index > 0) {
          linePosition += 0.25;
        }
        return {
          lx: inchesArr[index],
          ly: linePosition,
          text: m.replace("’", "'"),
        };
      });
    } else {
      inches = width / 96 / 2 + 2;
      lines = state.infinityNotes[index].message.split("\n").map((m, index) => {
        if (index > 0) {
          linePosition += 0.25;
        }
        return { lx: inchesArr[index], ly: linePosition, text: m };
      });
    }

    state.infinityNotes[index][field] = value;

    let infinityNotes = [...state.infinityNotes];
    
    let messageObj = infinityNotes[index].messageObj;
    switch (field) {
      case "greeting":
        messageObj.greeting = {
          lx: 1.55,
          ly: 1.25,
          text: value + " {{name}}",
          fontSize: 30,
        };
        break;
      case "message":
        messageObj.body = {
          lines: lines,
          fontSize: 30,
        };
        break;
      case "signedBy":
        messageObj.signature = {
          lx: 1.8,
          ly: lines[lines.length - 1].ly + 0.5,
          fontSize: 30,
          text: value,
        };
        break;
      default:
        break;
    }
    state.infinityNotes[index].messageObj = messageObj;
  },
  REMOVE_INFINITY_NOTE_FROM_STORE: (state, action) => {
    const { payload } = action;
    let newArr = _.cloneDeep([...state.infinityNotes]);
    newArr.splice(payload, 1);

    newArr.map((m, index) => {
      if (index === 1) {
        m.end = addDays(newArr[index].start, newArr[index].duration - 1);
      }
      if (index > 1) {
        m.start = addDays(newArr[index - 1].start, newArr[index - 1].duration);
        m.end = addDays(newArr[index].start, newArr[index].duration - 1);
      }
      return m;
    });

    state.infinityNotes = newArr;
  },
  SWAP_AND_UPDATE_INFINITY_NOTES: (state, action) => {
    return {
      ...state,
      infinityNotes: action.payload,
    };
  },
});

export default system;
