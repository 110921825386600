import React from "react";
import PropTypes from "prop-types";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Button,
  Grid,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import BulkUpdateDelayedOrdersButton from "../Delays/BulkUpdateDelayedOrdersButton";

export const descendingComparator = (a, b, orderBy) => {
  if (orderBy === "shippingAddress") {
    if (b[orderBy].city.toLowerCase() < a[orderBy].city.toLowerCase()) {
      return -1;
    }
    if (b[orderBy].city.toLowerCase() > a[orderBy].city.toLowerCase()) {
      return 1;
    }
  } else if (orderBy === "timeReceived" || orderBy === "packingEnd") {
    if (b[orderBy] == null || b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (a[orderBy] == null || b[orderBy] > a[orderBy]) {
      return 1;
    }
  } else if (orderBy === "orderItem") {
    if (
      b["courierOrder"].quantitiesDescription.toLowerCase() == null ||
      b["courierOrder"].quantitiesDescription.toLowerCase() <  a["courierOrder"].quantitiesDescription.toLowerCase()
    ) {
      return -1;
    }
    if (
      a["courierOrder"].quantitiesDescription.toLowerCase() == null ||
      b["courierOrder"].quantitiesDescription.toLowerCase() > a["courierOrder"].quantitiesDescription.toLowerCase()
    ) {
      return 1;
    }
  } else if (orderBy === "label") {
    if (
      b["courierOrder"].driveId == null ||
      b["courierOrder"].driveId < a["courierOrder"].driveId
    ) {
      return -1;
    }
    if (
      a["courierOrder"].driveId == null ||
      b["courierOrder"].driveId > a["courierOrder"].driveId
    ) {
      return 1;
    }
  } else if (orderBy === "ticket") {
    if ((b.hasUpdates === false && b.hasUpdates < a.hasUpdates) || b.ticketSent === false || b.ticketSent < a.ticketSent) {
      return -1;
    }
    if ((b.hasUpdates === false && b.hasUpdates > a.hasUpdates) || a.ticketSent === false || b.ticketSent > a.ticketSent) {
      return 1;
    }
  } else {
    if (b[orderBy] == null || b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (a[orderBy] == null || b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ backgroundColor: "#27293d" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            align={headCell.align}
            className={headCell.cssClass}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export const handleCheckboxClick = (event, name, selected) => {
  event.stopPropagation();
  const selectedIndex = selected.indexOf(name);
  let newSelected = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, name);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1)
    );
  }

  return newSelected;
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          fontSize: "1.5em",
          color: "white",
          backgroundColor: "#206020",
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
    textAlign: "center",
  },
}));

export const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, selected, BulkRelease, setSelected, parent, totalRows, exportSelectedOrders } =
    props;

  const BulkReleaseOrder = () => {
    BulkRelease(selected);
    setSelected([]);
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 && parent === "Delayed Orders" ? (
        <Grid container>
          <Grid item xs={12} md={10} lg={10}>
              <Typography
              className={classes.title}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {numSelected} selected
            </Typography>
          </Grid>
          <Grid item xs={12} md={1} lg={1}>
            <Button color="primary" variant="contained" size="small" onClick={exportSelectedOrders}>
                Export Selected
              </Button>
          </Grid>
          <Grid item xs={12} md={1} lg={1}>
            <BulkUpdateDelayedOrdersButton selectedOrders={selected} />
          </Grid>
        </Grid>
        
      ) : numSelected > 0 ? (
        <Typography
              className={classes.title}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {numSelected} selected
            </Typography>
      ) : (
        <Grid container>
          <Grid item xs={4} md={4} lg={4}>
            <Typography
              className={classes.title}
              color="inherit"
              variant="h6"
              component="div"
            >
              {totalRows} in total
            </Typography>
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <Typography
              className={classes.title}
              variant="h5"
              id="tableTitle"
              component="div"
            >
              {parent}
            </Typography>
          </Grid>
          <Grid item xs={4} md={4} lg={4}></Grid>
        </Grid>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
