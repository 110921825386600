import React from "react";
import "./orderConfirmationModal.scss";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import CustomerDetailsCard from "../CustomerDetailsCard/CustomerDetailsCard";
import AddNewPayment from "../AddNewPayment/AddNewPayment";
import PaymentIcon from "@material-ui/icons/Payment";
import { isEmpty } from "../../Utils/helperFunctions";
import { useState } from "react";
import OrderSummary from "../OrderSummary/OrderSummary";
import { StoreContext } from "../../../context/store/StoreContext";
import { useContext } from "react";

const errorState = {
  cardholderName: "",
};

function OrderConfirmationModal({
  open,
  handleClose,
  firestoreCustomer,
  selectedPayment,
  shippingAddress,
  discountCodeObj,
  handleMakePayment,
  cardholderName,
  setCardholderName,
  discountCode,
  shippingCost,
}) {
  const { checkout } = useContext(StoreContext);
  const [errorForm, setErrorForm] = useState(errorState);
  const [isValidPaymentMethod, setIsValidPaymentMethod] = useState(false);

  function isValid() {
    let validForm = true;
    const tempError = { ...errorState };

    if (isEmpty(cardholderName)) {
      tempError.cardholderName = "Required field";
      alert("enter Cardholder Name");
      validForm = false;
    }

    if (!validForm) {
      setErrorForm(tempError);
    }

    return validForm;
  }

  const handleCardChange = async (event) => {
    if (event.complete) {
      setIsValidPaymentMethod(true);
    } else {
      setIsValidPaymentMethod(false);
    }
  };

  const completePayment = () => {
    if (selectedPayment) {
      handleMakePayment();
    } else {
      if (isValid()) {
        handleMakePayment();
      }
    }
  };

  return (
    <Dialog fullWidth maxWidth={"lg"} open={open} onClose={handleClose}>
      <DialogTitle>{`New Order for ${firestoreCustomer.firstName}`}</DialogTitle>
      <DialogContent>
        <div className="order-confirmation-modal-root">
          <div className="order-confirmation-modal-lineitems">
            <Typography variant="h6">
              <strong>{`Items`}</strong>
            </Typography>
            <div>
              {checkout?.lineItems.map((item, index) => (
                <div key={index} className="order-confirmation-modal-variants">
                  <Typography
                    variant="body2"
                    className={"order-confirmation-modal-name"}
                  >
                    <strong>{item.title}</strong>
                  </Typography>
                  <Typography
                    variant="body2"
                    className={"order-confirmation-modal-meta"}
                  >
                    {item.variant?.title === "Default Title"
                      ? ""
                      : item.variant?.title}
                    {" x " + item.quantity}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
          <div className="order-confirmation-modal-customerDetails">
            <div className="order-confirmation-modal-info">
              <Typography variant="h6">
                <strong>Shipping Address</strong>
              </Typography>
              <Typography variant="body2">{`${shippingAddress.firstName} ${shippingAddress.lastName}`}</Typography>
              {shippingAddress.companyName ? (
                <Typography variant="body2">
                  {shippingAddress.companyName}
                </Typography>
              ) : (
                ""
              )}
              <Typography variant="body2">
                {shippingAddress.addressLine1}
              </Typography>
              <Typography variant="body2">
                {shippingAddress.addressLine2}
              </Typography>
              <Typography variant="body2">{`${shippingAddress.city} ${shippingAddress.postCode}`}</Typography>
              <Typography variant="body2">
                {shippingAddress.phoneNumber}
              </Typography>
            </div>
            <div className="order-confirmation-modal-info">
              <Typography variant="h6">
                <strong>Payment</strong>
              </Typography>
              {selectedPayment ? (
                <div className="selected-payment-container">
                  <div>
                    <PaymentIcon />
                  </div>
                  <div className="selected-payment-details">
                    <Typography variant="body2">{`**** ${selectedPayment?.card?.last4}`}</Typography>
                    <Typography variant="body2">{`EXP ${
                      selectedPayment?.card?.exp_month < 10
                        ? "0" + selectedPayment?.card?.exp_month
                        : selectedPayment?.card?.exp_month
                    }/${selectedPayment?.card?.exp_year}`}</Typography>
                    <Typography variant="body2">
                      {selectedPayment?.billing_details?.name}
                    </Typography>
                  </div>
                </div>
              ) : (
                <AddNewPayment
                  handleCardChange={handleCardChange}
                  setCardholderName={setCardholderName}
                  cardholderName={cardholderName}
                  errorState={errorForm}
                />
              )}
            </div>
          </div>

          <OrderSummary
            isReadOnly
            shippingCost={shippingCost}
            discountCode={discountCode}
            canEditCustomTotal
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={completePayment} color="primary" variant="contained">
          Complete Payment and Order
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OrderConfirmationModal;
