import axios from "axios";

const findFirestoreCustomerByShopifyId = async (shopifyId) => {
  let customer = null;
  const { data } = await axios.get(
    `FirestoreCustomer/FindCustomerByShopifyId/${shopifyId}`
  );
  if (data) {
    customer = data;
  }

  return customer;
};

const findFirestoreCustomerByFirestoreId = async (firestoreId) => {
  let customer = null;
  const { data } = await axios.get(
    `FirestoreCustomer/FindCustomerByFirestoreId/${firestoreId}`
  );
  if (data) {
    customer = data;
  }

  return customer;
}


const findCustomerById = async (customerId) => {
  let customer = null;
  const response = await axios.get(`Customer/FindCustomerById/${customerId}`);
  console.log(response)
  let data = response.data;
  if (data) {
    customer = data;
  }

  return customer;
};

export { findFirestoreCustomerByShopifyId, findFirestoreCustomerByFirestoreId, findCustomerById };
