import React, { useEffect, useState } from "react"
import { withStyles } from "@material-ui/core/styles";
import {
    Grid,
    TextField,
    Typography,
    Checkbox,
    FormControlLabel
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Axios from "axios"
import { ConvertNZPostAddress } from "../../../Utils/nzPostUtils";

const WhiteTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "white",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "white",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "white",
            },
            "&:hover fieldset": {
                borderColor: "white",
            },
            "&.Mui-focused fieldset": {
                borderColor: "white",
            },
        },
    },
})(TextField);

const EditBillingAddressSection = (props) => {
    let {
        selectedCompany,
        updateSelectedCompany,
        updateBillingAddress
    } = props;

    const [billingAddresses, setBillingAddresses] = useState([]);
    const [loadingBillingAddresses, setLoadingBillingAddresses] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const getNZPostAddresses = (searchTerm) => {
        setLoadingBillingAddresses(true);
        Axios.get(`Orders/GetNZPostAddresses/${encodeURIComponent(searchTerm)}`)
            .then((res) => {
                var addressList = [];
                for (var i = 0; i < res.data.length; i++) {
                    addressList.push(res.data[i].fullAddress)
                }
                setBillingAddresses(addressList);
            })
            .catch((e) => { console.log(e);})
            .finally(() => { setLoadingBillingAddresses(false) });
    };

    const handleAddressSelection = (fullAddress) => {
        var addressObject = ConvertNZPostAddress(fullAddress);

        updateBillingAddress({
            ...addressObject
        });
    };

    return (
        <React.Fragment>
            <Grid item xs={12} md={12} lg={12}>
                <Typography variant={"h6"} align="center">
                    Billing Address
                </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <Autocomplete
                    fullWidth
                    id="nzpost-address-dropdown"
                    options={billingAddresses}
                    loading={loadingBillingAddresses}
                    value={searchTerm}
                    renderInput={(params) => (
                        <WhiteTextField
                            {...params}
                            label={"Search for an address"}
                            variant="outlined"
                            fullWidth
                            onChange={(e) => {
                                if (e.target.value?.trim()) {
                                    getNZPostAddresses(e.target.value.trim())
                                    setSearchTerm(e.target.value.trim())
                                }
                            }}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    getNZPostAddresses(e.target.value.trim())
                                    setSearchTerm(e.target.value.trim())
                                    e.preventDefault();
                                }
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                    onChange={(event, value) => {
                        if (value !== null && value !== "") {
                            handleAddressSelection(value);
                        }
                        setSearchTerm("")
                    }}
                />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
                <WhiteTextField
                    label="Address 1"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => {
                        updateSelectedCompany("address1", e.target.value)
                    }}
                    value={selectedCompany.address1}
                    spellCheck
                    size="small"
                />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
                <WhiteTextField
                    label="Address 2"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => {
                        updateSelectedCompany("address2", e.target.value)
                    }}
                    value={selectedCompany.address2}
                    spellCheck
                    size="small"
                />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
                <WhiteTextField
                    label="City"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => {
                        updateSelectedCompany("city", e.target.value)
                    }}
                    value={selectedCompany.city}
                    spellCheck
                    size="small"
                />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
                <WhiteTextField
                    label="Zip"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => {
                        updateSelectedCompany("zip", e.target.value)
                    }}
                    value={selectedCompany.zip}
                    spellCheck
                    size="small"
                />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
                <WhiteTextField
                    label="Country"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => {
                        updateSelectedCompany("country", e.target.value)
                    }}
                    value={selectedCompany.country}
                    spellCheck
                    size="small"
                />
            </Grid>
            <Grid item xs={6} md={6} lg={6} alignItems="center">
                <FormControlLabel
                    control={
                        <Checkbox
                            style={{
                                color: "white",
                            }}
                            checked={selectedCompany.shippingSameAsBilling === 1}
                            onClick={(e) => {
                                updateSelectedCompany("shippingSameAsBilling", e.target.checked ? 1 : 0)
                            }}
                        />
                    }
                    label="Shipping same as billing address"
                />
            </Grid>
        </React.Fragment>
    )
}

export default EditBillingAddressSection;