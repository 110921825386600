import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    TextField,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const WhiteTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
            },
        },
    },
})(TextField);

const SkuItemTable = (props) => {
    const {
        skuPriceItems,
        itemQuantity,
        setItemQuantity
    } = props;

    if (itemQuantity === null) {
        return <React.Fragment></React.Fragment>
    }
    else {
        return (
            <Table className="business-direct-sku-table">
                <TableHead>
                    <TableRow>
                        <TableCell>Code</TableCell>
                        <TableCell>Flavour</TableCell>
                        <TableCell>Quantity</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {skuPriceItems.map(item => {
                        return (
                            <TableRow id={`${item.code}-row`}>
                                <TableCell>{item.code}</TableCell>
                                <TableCell>{item.flavour}</TableCell>
                                <TableCell>
                                    <WhiteTextField
                                        className="business-direct-textfield"
                                        fullWidth
                                        variant="standard"
                                        onChange={(e) => {
                                            setItemQuantity({
                                                ...itemQuantity,
                                                [item.flavour]: e.target.value
                                            })
                                        }}
                                        type="number"
                                        value={itemQuantity[item.flavour]}
                                    />
                                </TableCell>
                            </TableRow>
                        )
                    })}
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell><Typography>Total Boxes:</Typography></TableCell>
                        <TableCell><Typography>{`${props.totalItems}`}</Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell><Typography>Total Capsules:</Typography></TableCell>
                        <TableCell><Typography>{`${props.totalCapsules}`}</Typography></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }

}

export default SkuItemTable;