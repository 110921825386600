import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import {
    TableBody,
    TableCell,
    TableRow,
    Typography,
    Checkbox,
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@material-ui/core";
import {
    Flag as FlagIcon,
    ConfirmationNumber as ConfirmationNumberIcon,
    NotificationsActive as NotificationsActiveIcon,
    PanTool as PanToolIcon,
    CheckCircleOutline,
    NotInterested,
} from "@material-ui/icons";
import "../Shared/storeOrders.scss";
import { convertTime, formatTime, getDateAsString } from "../Utils/dateTime";
import "material-icons";
import Snacky from "../Shared/Snacky";
import { setSnackBarProps } from "../../reduxActions/snackbar";
import { getComparator, stableSort } from "../Shared/Table";
import axios from "axios";

function MemoizedClaimsTableBodyPropsAreEqual(prevState, nextState) {
    return (
        prevState.order === nextState.order &&
        prevState.orderBy === nextState.orderBy &&
        prevState.isSelected === nextState.isSelected &&
        _.isEqual(prevState.selected, nextState.selected) &&
        _.isEqual(prevState.claims, nextState.claims)
    );
}

const MemoizedClaimsTableBody = React.memo(function MemoizedClaimsTableBody(props) {
    const {
        order,
        orderBy,
        isSelected,
        handleClick,
        claims,
        setClaimsPopup,
        setSelectedClaim,
    } = props;

    const [loading, setLoading] = useState(false);

    if (loading) {
        return <CircularProgress />;
    } else {
        return (
            <React.Fragment>
                <TableBody className="table-body">
                    {stableSort(claims, getComparator(order, orderBy))
                        .map((claim, index) => {
                            const isItemSelected = isSelected(claim.id);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            let date = getDateAsString(claim.documentsCreated);
                            return (
                                <TableRow
                                    hover
                                    onClick={(event) => {
                                        // handleClick(event, claim.id)
                                        setClaimsPopup(true)
                                        setSelectedClaim(claim)
                                    }}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={claim.id}
                                    selected={isItemSelected}
                                >
                                    <TableCell padding="checkbox" onClick={(e) => e.stopPropagation()}>
                                        <Checkbox
                                            checked={isItemSelected}
                                            inputProps={{ 'aria-labelledby': labelId }}
                                            onClick={(event) => handleClick(event, claim.id)}
                                            style={{ color: "white" }}
                                        />
                                    </TableCell>
                                    <TableCell component="th" id={labelId} scope="row" padding="none" onClick={(e) => e.stopPropagation()}>
                                        {`SC${claim.orderNumber}`}
                                    </TableCell>
                                    <TableCell>{claim.customerFirstName}</TableCell>
                                    <TableCell>{claim.customerLastName}</TableCell>
                                    <TableCell>{`${claim.shippingAddress.address2} - ${claim.shippingAddress.city}`}</TableCell>
                                    <TableCell>{date === "" ? "Not Created" : date}</TableCell>
                                    <TableCell>{claim.status}</TableCell>
                                    <TableCell>{claim.courier}</TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </React.Fragment>
        );
    }
}, MemoizedClaimsTableBodyPropsAreEqual);

export default MemoizedClaimsTableBody;
