import React, { useEffect } from "react";
import * as styles from "./Modal.module.css";

const Modal = ({ children, visible, close, escapeToClose = true }) => {
  useEffect(() => {
    if (typeof window === "undefined") return;
    window.addEventListener("keydown", function (event) {
      const key = event.key;
      if (key === "Escape" && escapeToClose) {
        close();
      }
    });
    return () =>
      window.removeEventListener("keydown", function (event) {
        const key = event.key;
        if (key === "Escape" && escapeToClose) {
          close();
        }
      });
  }, [close]);

  return (
    <div
      className={`${styles.root} ${
        visible === true ? styles.show : styles.hide
      }`}
    >
      <div className={styles.contentContainer}>{children}</div>
      <div
        role={"presentation"}
        onClick={() => {
          if (escapeToClose) {
            close();
          }
        }}
        className={styles.backdrop}
      ></div>
    </div>
  );
};

export default Modal;
