import React, { useState, useEffect } from "react";
import {
    Grid,
    TextField,
    Tabs,
    Tab,
    Box,
    Typography,
    Button
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CreateCompany from "./CreateCompany"
import EditCompany from "./EditCompany"
import BusinessDirect from "../BusinessDirect";
import EditCustomerRatesTable from "./EditComponents/EditCustomerRatesTable";
import CreateCustomerRatesTable from "./CreateComponents/CreateCustomerRatesTable";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const BusinessDirectHome = () => {
    let history = useHistory();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="create-edit-company-details-container">
            <div className="business-direct-form">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    style={{
                        color: "white !important"
                    }}
                    centered
                >
                    <Tab label="Create Order" />
                    <Tab label="Create Company" />
                    <Tab label="Edit Company" />
                    <Tab label="Create Customer Rates" />
                    <Tab label="Edit Customer Rates" />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <BusinessDirect />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <CreateCompany />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <EditCompany />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <CreateCustomerRatesTable />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <EditCustomerRatesTable />
                </TabPanel>
            </div>
        </div>
    )
}

export default BusinessDirectHome;