import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ReplyIcon from "@material-ui/icons/Reply";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import { Divider, Tooltip } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  subheader: {
    fontStyle: "italic",
  },
  cardheader: {
    border: "1px solid #f0f0f0",
    borderRadius: 10,
  },
  cardContentForReply: {
    overflowY: "scroll",
    height: 300,
  },
});

const EmailMessageCard = (props) => {
  const renderSubheader = () => {
    if (props.emailMessage?.cc) {
      return (
        <div>
          <div>To: {props.emailMessage?.to}</div>
          <div>Cc: {props.emailMessage?.cc}</div>
        </div>
      );
    }
    return <div>To: {props.emailMessage?.to}</div>;
  };

  const classes = useStyles();
  return (
    <div>
      <Card className={classes.root} elevation={0}>
        <CardHeader
          className={classes.cardheader}
          action={
            !props.isReplyCardOpen && (
              <Tooltip title="Reply">
                <IconButton aria-label="settings">
                  <ReplyIcon onClick={(e) => props.handleReplyButton(false)} />
                </IconButton>
              </Tooltip>
            )
          }
          avatar={<Avatar></Avatar>}
          classes={{ subheader: classes.subheader }}
          component={Box}
          subheader={renderSubheader()}
          subheaderTypographyProps={{
            variant: "caption",
            align: "left",
            gutterBottom: true,
          }}
          title={props.emailMessage?.from}
          titleTypographyProps={{ variant: "subtitle2", align: "left" }}
        />
        <CardContent>
          <div
            className={props.isReplyCardOpen && classes.cardContentForReply}
            dangerouslySetInnerHTML={{ __html: props.emailMessage?.body }}
          ></div>
        </CardContent>
      </Card>
    </div>
  );
};

export default EmailMessageCard;
