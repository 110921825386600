import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Snackbar
} from "@material-ui/core";
import React, { useState } from "react";
import {
  denyReferral,
  approveReferral,
} from "../../../../repository/referrals";
import ReferralRow from "../ReferralRow/ReferralRow";
import { Alert } from "@material-ui/lab";
import ReferralDenyModal from "../ReferralDenyModal/ReferralDenyModal";
import "./referralsTable.scss";

const headCells = [
  { id: "code", numeric: false, disablePadding: false, label: "Code" },
  { id: "name", numeric: false, disablePadding: false, label: "Name" },
  {
    id: "codeUsed",
    numeric: false,
    disablePadding: false,
    label: "Code Used",
  },
  { id: "usedByName", numeric: false, disablePadding: false, label: "Used By" },
  { id: "status", numeric: false, disablePadding: false, label: "Credit Status" },
];

function descendingComparator(a, b, orderBy) {
  if (orderBy === "name" || orderBy === "usedByName") {
    if (`${b["referrerCustomer"]["firstName"]} ${b["referrerCustomer"]["lastName"]}` < `${a["referrerCustomer"]["firstName"]} ${a["referrerCustomer"]["lastName"]}`) {
      return -1;
    }
    if (`${b["referrerCustomer"]["firstName"]} ${b["referrerCustomer"]["lastName"]}` > `${a["referrerCustomer"]["firstName"]} ${a["referrerCustomer"]["lastName"]}`) {
      return 1;
    }
  }
  else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }

  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const ReferralsTable = ({
  referrals,
  setReferrals,
  isGlobalPage=true,
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("usedAt");
  const [messageDisplay, setMessageDisplay] = useState(null);
  const [selectedReferral, setSelectedReferral] = useState(null);
  const [openDenyModal, setOpenDenyModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);


  const handleDenyReferralUse = (referral) => {
    setSelectedReferral(referral);
    setOpenDenyModal(true);
  };

  const handleCloseDenyModal = () => {
    setSelectedReferral(null);
    setOpenDenyModal(false);
  };

  const handleAcceptDenyModal = async () => {
    setIsProcessing(true);
    const _referral = await denyReferral(selectedReferral.id);
    handleUpdateReferralList(_referral);
    handleCloseDenyModal();
    setIsProcessing(false);
  };

  const handleApproveReferralUse = async (referral) => {
    setIsProcessing(true);
    const _referral = await approveReferral(referral.id);
    handleUpdateReferralList(_referral);
    setIsProcessing(false);
  };

  const handleUpdateReferralList = (_referral) => {
    if (_referral) {
      setReferrals(
        (referrals) => referrals.map((r) =>
          r.id === _referral.id ? {...r, creditStatus: _referral.creditStatus} : r
        )
      );

      setMessageDisplay({
        message: "Referral has been updated.",
        hasError: false,
      });
    } else {
      setMessageDisplay({
        message: "Unable to update your Referral.",
        hasError: true,
      });
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div>
      {!isGlobalPage && referrals.length === 0 ? (
        <div>
          <Typography variant="body2">
            <strong>Referral has not been set up.</strong>
          </Typography>
        </div>
      ) : (
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={(e) => handleRequestSort(e, headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(referrals, getComparator(order, orderBy)).map(
              (referral) => (
                <ReferralRow
                  key={referral.id}
                  referral={referral}
                  handleDenyReferralUse={handleDenyReferralUse}
                  handleApproveReferralUse={handleApproveReferralUse}
                  isProcessing={isProcessing}
                />
              )
            )}
          </TableBody>
        </Table>
      </TableContainer> )}
      {openDenyModal && selectedReferral && (
        <ReferralDenyModal
          open={openDenyModal}
          handleClose={handleCloseDenyModal}
          handleSubmit={handleAcceptDenyModal}
        />
      )}
      {messageDisplay && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={messageDisplay}
          autoHideDuration={5000}
          onClose={() => setMessageDisplay(null)}
        >
          {messageDisplay.hasError ? (
            <Alert
              onClose={() => setMessageDisplay(null)}
              variant="filled"
              severity="error"
            >
              {messageDisplay.message}
            </Alert>
          ) : (
            <Alert
              onClose={() => setMessageDisplay(null)}
              variant="filled"
              severity="success"
            >
              {messageDisplay.message}
            </Alert>
          )}
        </Snackbar>
      )}
    </div>
  );
};

export default ReferralsTable;
