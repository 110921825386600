import {
  Button,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./customerAddress.scss";
import { ConvertNZPostAddress } from "../../Utils/nzPostUtils";

const AddUpdateAddressDialog = (props) => {
  const {
    open,
    handleClose,
    reloadAddresses,
    type,
    customerId,
    addressToEdit,
  } = props;

  const [shippingAddressOptionsLoading, setShippingAddressOptionsLoading] =
    useState(false);
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [currentAddress, setCurrentAddress] = useState({
    id: null,
    firstName: "",
    lastName: "",
    companyName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    postCode: "",
    phoneNumber: "",
  });
  const [saving, setSaving] = useState(false);

  const updateCurrentAddress = (property, newValue) => {
    var newAdddress = { ...currentAddress };
    switch (property) {
      case "firstName":
      case "lastName":
      case "companyName":
      case "addressLine1":
      case "addressLine2":
      case "city":
      case "postCode":
      case "phoneNumber":
      case "ruralDelivery":
      case "note":
        newAdddress[property] = newValue;
        setCurrentAddress(newAdddress);
        break;
      default:
        break;
    }
  };

  const getTextField = (label, type, value, props) => {
    return (
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        label={label}
        value={value}
        onChange={(e) => {
          updateCurrentAddress(type, e.target.value);
        }}
        {...props}
      />
    );
  };

  const getNZPostAddresses = (searchTerm) => {
    setShippingAddressOptionsLoading(true);
    axios
      .get(`Orders/GetNZPostAddresses/${encodeURIComponent(searchTerm)}`)
      .then((res) => {
        var addressList = [];
        for (var i = 0; i < res.data.length; i++) {
          debugger;
          addressList.push(res.data[i].fullAddress);
        }
        setShippingAddresses(addressList);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setShippingAddressOptionsLoading(false);
      });
  };

  const onAddressSelect = (fullAddress) => {
    var addressObject = ConvertNZPostAddress(fullAddress);
    var newAddress = {
      ...currentAddress,
      addressLine1: addressObject.address1,
      addressLine2: addressObject.address2,
      city: addressObject.city,
      postCode: addressObject.zip,
    };

    setCurrentAddress(newAddress);
  };

  const checkIfAllFieldsCompleted = () => {
    for (const property in currentAddress) {
      switch (property) {
        case "addressLine1":
        case "addressLine2":
        case "city":
        case "firstName":
        case "lastName":
        case "postCode":
        case "phoneNumber":
          if (
            (currentAddress[property] === null ||
              currentAddress[property] === undefined ||
              currentAddress[property].trim() === "") &&
            property !== "companyName"
          ) {
            return false;
          }
          break;
        case "ruralDelivery":
        case "note":
        default:
          // Do nothing, these are optional fields
          break;
      }
    }
    return true;
  };

  const addAddress = () => {
    if (!saving) {
      setSaving(true);
      const sampleAddress = {
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        postCode: "",
        companyName: "",
        note: "",
        label: "Home",
        phoneNumber: "",
        isVerified: false,
        ruralDelivery: "",
        dpid: null,
        isDefaultAddress: false,
      };

      axios
        .post(`AdminPanel/AddAddress/${customerId}`, {
          ...sampleAddress,
          ...currentAddress,
        })
        .then((res) => {
          reloadAddresses();
          handleClose();
        })
        .catch((e) => {})
        .finally(() => {
          setSaving(false);
        });
    }
  };

  const updateAddress = () => {
    if (!saving) {
      setSaving(true);
      axios
        .post(`AdminPanel/UpdateAddress/${customerId}`, {
          ...currentAddress,
        })
        .then((res) => {
          reloadAddresses();
          handleClose();
        })
        .catch((e) => {})
        .finally(() => {
          setSaving(false);
        });
    }
  };

  useEffect(() => {
    if (type === "update") {
      setCurrentAddress({
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        postCode: "",
        companyName: "",
        note: "",
        label: "Home",
        phoneNumber: "",
        isVerified: false,
        ruralDelivery: "",
        dpid: null,
        isDefaultAddress: false,
        ...addressToEdit,
      });
    } else if (type === "subscription") {
      setCurrentAddress({
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        postCode: "",
        companyName: "",
        note: "",
        label: "Home",
        phoneNumber: "",
        isVerified: false,
        ruralDelivery: "",
        dpid: null,
        ...addressToEdit,
      });
    }
  }, [type, addressToEdit]);

  const subscriptionUpdateAddress = () => {
    reloadAddresses(currentAddress);
  };

  return (
    <Card>
      <Dialog
        open={open}
        onClose={(e, reason) => {
          if (
            reason !== "backdropClick" &&
            reason !== "escapeKeyDown" &&
            !saving
          ) {
            handleClose();
          }
        }}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          {type === "add"
            ? "Add new address"
            : type === "update"
            ? "Update address"
            : ""}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} lg={6}>
              {getTextField(
                "First Name*",
                "firstName",
                currentAddress.firstName
              )}
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              {getTextField("Last Name*", "lastName", currentAddress.lastName)}
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              {getTextField(
                "Company",
                "companyName",
                currentAddress.companyName
              )}
            </Grid>
            <Grid item xs={6} md={6} lg={6}></Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Autocomplete
                fullWidth
                size="small"
                id="nzpost-address-dropdown"
                options={shippingAddresses}
                loading={shippingAddressOptionsLoading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Search for an address"}
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      e.target.value !== null && e.target.value !== ""
                        ? getNZPostAddresses(e.target.value)
                        : ""
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                onChange={(event, value) => {
                  if (value !== null && value !== "") {
                    onAddressSelect(value);
                  }
                }}
              />
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              {getTextField(
                "Street*",
                "addressLine1",
                currentAddress.addressLine1
              )}
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              {getTextField(
                "Suburb*",
                "addressLine2",
                currentAddress.addressLine2
              )}
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              {getTextField("City*", "city", currentAddress.city)}
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              {getTextField(
                "Postal Code*",
                "postCode",
                currentAddress.postCode
              )}
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              {getTextField(
                "Phone*",
                "phoneNumber",
                currentAddress.phoneNumber
              )}
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              {getTextField(
                "Delivery Instructions",
                "note",
                currentAddress.note,
                {
                  multiline: true,
                  minRows: 3,
                }
              )}
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(currentAddress.ruralDelivery)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        updateCurrentAddress("ruralDelivery", "true");
                      } else {
                        updateCurrentAddress("ruralDelivery", "");
                      }
                    }}
                  />
                }
                label="Rural Delivery"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {saving ? <CircularProgress /> : ""}
          {!saving ? (
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          ) : (
            ""
          )}
          {checkIfAllFieldsCompleted() && !saving ? (
            <Button
              variant="outlined"
              onClick={() => {
                if (type === "add") {
                  addAddress();
                } else if (type === "update") {
                  updateAddress();
                } else if (type === "subscription") {
                  subscriptionUpdateAddress();
                }
              }}
            >
              Save
            </Button>
          ) : (
            ""
          )}
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default AddUpdateAddressDialog;
