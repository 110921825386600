import axios from "axios";

const findOrderByShopifyId = async (shopifyId) => {
  let order = null;

  const { data } = await axios.get(
    `FirestoreOrder/FindOrderByShopifyId/${shopifyId}`
  );
  if (data) {
    order = data;
  }

  return order;
};

const calculateOrderSummary = async (
  lineItems,
  shippingAddress,
  eligibleLoyaltyPercentage,
  autoshipDiscountAmount
) => {
  let orderSummary = null;

  const { data } = await axios.post(`AdminPanel/CalculateOrderSummary`, {
    lineItems,
    shippingAddress,
    eligibleLoyaltyPercentage,
    autoshipDiscountAmount,
  });
  if (data) {
    orderSummary = data;
  }

  return orderSummary;
};

export { findOrderByShopifyId, calculateOrderSummary };
