import React from "react";
import StockTabs from "./StockTabs";
import { useHistory, useLocation } from "react-router-dom";

const StockLevels = (props) => {
    const location = useLocation();
    console.log("Stock Levels Comp")
    console.log(location.state)
    return <StockTabs goToStockTab={location.state}/>;
};

export default StockLevels;