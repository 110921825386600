import React, { Fragment } from "react";
import { Button } from "@material-ui/core";
import "./packingScreen.scss";
import { connect } from "react-redux";

const PackingScreenModalStatusBadge = (props) => {
  const { orderId, ordersUpdating, status, size } = props;

  if (ordersUpdating.includes(orderId)) {
    return (
      <Button
        variant="contained"
        className={`button-processing ${
          size === "small" ? "packing-screen-modal-status-badge-small" : ""
        }`}
      >
        Processing
      </Button>
    );
  } else if (status === 1) {
    return (
      <Button
        variant="contained"
        className={`button-processing ${
          size === "small" ? "packing-screen-modal-status-badge-small" : ""
        }`}
      >
        Queued
      </Button>
    );
  } else if (status === 2) {
    return (
      <Button
        variant="contained"
        className={`button-processing ${
          size === "small" ? "packing-screen-modal-status-badge-small" : ""
        }`}
      >
        Note Written
      </Button>
    );
  } else if (status === 3) {
    return (
      <Button
        variant="contained"
        className={`button-packing ${
          size === "small" ? "packing-screen-modal-status-badge-small" : ""
        }`}
      >
        Ready For Packing
      </Button>
    );
  } else if (status === 4) {
    return (
      <div style={{ display: "flex" }}>
        <Button
          variant="contained"
          className={`button-completed ${
            size === "small" ? "packing-screen-modal-status-badge-small" : ""
          }`}
        >
          Completed
        </Button>
      </div>
    );
  }

  return <Fragment />;
};

const mapStateToProps = (state) => ({
  ordersUpdating: state.order.ordersUpdating,
});

export default connect(mapStateToProps, {})(PackingScreenModalStatusBadge);
