import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  useCustomer,
} from "../../../context/customer/CustomerContext";
import { isEmpty } from "../../Utils/helperFunctions";
import "./editCustomerModal.scss";

const initialState = {
  email: "",
  phoneNumber: "",
  firstName: "",
  lastName: "",
};
const errorState = {
  email: "",
  phoneNumber: "",
  firstName: "",
  lastName: "",
};
const EditCustomerModal = ({ open, handleClose, setMessageDisplay }) => {
  const { customer, firestoreCustomer, updateCustomerDetails } = useCustomer();
  const [updateForm, setUpdateForm] = useState(initialState);
  const [errorForm, setErrorForm] = useState(errorState);

  useEffect(() => {
    setUpdateForm({
      ...updateForm,
      email: customer.email,
      phoneNumber: firestoreCustomer.phoneNumber,
      firstName: customer.firstName,
      lastName: customer.lastName,
      shopifyId: customer.id,
      authId: firestoreCustomer.authId,
      firestoreId: firestoreCustomer.id,
    });
  }, [customer, firestoreCustomer]);

  const handleSave = () => {
    if (isValid()) {
      axios
        .post(`AdminPanel/UpdateCustomerDetails/`, updateForm)
        .then(async ({ data }) => {
          await updateCustomerDetails(updateForm);
          setMessageDisplay({
            message: "Sweet! Customer has been updated.",
            hasError: false,
          });
          handleClose()
        })
        .catch((error) => {
          setMessageDisplay({
            message: `Oh Snap! ${
              error?.response?.data || "Something went wrong"
            }`,
            hasError: true,
          });
          console.log(error);
        });
      console.log(updateForm);
    }
  };

  const isValid = () => {
    let validForm = true;
    const tempError = { ...errorState };

    if (isEmpty(updateForm.firstName)) {
      tempError.firstName = "Required field";
      validForm = false;
    }

    if (isEmpty(updateForm.lastName) === true) {
      tempError.lastName = "Required field";
      validForm = false;
    }

    if (isEmpty(updateForm.email) === true) {
      tempError.email = "Required field";
      validForm = false;
    }

    if (!validForm) {
      setErrorForm(tempError);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }

    return validForm;
  };

  const handleChange = (id, value) => {
    if (id === "phoneNumber" && value && value.length > 4) {
      if (!value.startsWith("+64")) {
        if (value.startsWith("0")) {
          value = "+64" + value.substring(1);
        } else {
          value = "+64" + value;
        }
      }
    }

    const tempForm = { ...updateForm, [id]: value };
    setUpdateForm(tempForm);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"xs"}>
      <DialogTitle id="form-dialog-title">{`Edit ${customer.firstName} ${customer.lastName}`}</DialogTitle>
      <DialogContent>
        <div className="edit-customer-form-container">
          <TextField
            id="firstName"
            label="First Name *"
            variant="outlined"
            size="small"
            value={updateForm.firstName}
            error={errorForm.firstName}
            helperText={errorForm.firstName ? errorForm.firstName : ""}
            placeholder={"First Name *"}
            onChange={(e) => handleChange("firstName", e.target.value)}
          />
          <TextField
            id="lastName"
            label="Last Name *"
            variant="outlined"
            size="small"
            value={updateForm.lastName}
            error={errorForm.lastName}
            placeholder={"Last Name *"}
            helperText={errorForm.lastName ? errorForm.lastName : ""}
            onChange={(e) => handleChange("lastName", e.target.value)}
          />
          <TextField
            id="email"
            label="Email *"
            variant="outlined"
            size="small"
            value={updateForm.email}
            error={errorForm.email}
            placeholder={"Email *"}
            helperText={errorForm.email ? errorForm.email : ""}
            onChange={(e) => handleChange("email", e.target.value)}
          />
          <TextField
            id="phoneNumber"
            label="Phone Number"
            variant="outlined"
            size="small"
            value={updateForm.phoneNumber}
            error={errorForm.phoneNumber}
            helperText={errorForm.phoneNumber ? errorForm.phoneNumber : ""}
            placeholder={"Phone Number *"}
            onChange={(e) =>
              handleChange("phoneNumber", e.target.value?.replace(/\s/g, ""))
            }
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCustomerModal;
