import { Button, CircularProgress } from "@material-ui/core";
import React from "react";

const DelayedOrdersButton = (props) => {
  return (
    <Button
      className="home-button"
      onClick={props.handleClick}
      variant="contained"
      color="primary"
      // disabled={props.disabled}
    >
      <div>{props.buttonText}</div>
      <div style={{ marginLeft: 25 }}>
        {props.loadingNumItems ? (
          <CircularProgress />
        ) : (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: 15,
            }}
          >
            {props.redItems && (
              <div style={{ color: `red` }}>{props.redItems}</div>
            )}
            {props.orangeItems && (
              <div style={{ color: `orange` }}>{props.orangeItems}</div>
            )}
            {props.greenItems && (
              <div style={{ color: `green` }}>{props.greenItems}</div>
            )}
          </div>
        )}
      </div>
    </Button>
  );
};

export default DelayedOrdersButton;
