import React, { useEffect, useState } from "react";
import AdminLayout from "../AdminLayout/AdminLayout";
import "./products.scss";
import ProductsDetail from "./ProductsDetail";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SkuManager from "../SkuManager/SkuManager";
import { Box, Typography } from "@material-ui/core";
import axios from "axios";
import { Skeleton } from "@material-ui/lab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box style={{marginTop: 10}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Products = () => {
  const [value, setValue] = useState(0);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getAllProducts();
  }, []);

  const getAllProducts = () => {
    setIsLoading(true);
    axios
      .get(`products/GetProductDetails`)
      .then(({ data }) => {
        setProducts(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <AdminLayout>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Product Details" />
        <Tab label="Sku Manager" />
      </Tabs>
      {isLoading ? (
        <div className="products-loading-skeleton-container">
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </div>
      ) : (
        <>
          <TabPanel value={value} index={0}>
            <ProductsDetail
              products={products}
              setProducts={setProducts}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <SkuManager products={products} />
          </TabPanel>
        </>
      )}
    </AdminLayout>
  );
};

export default Products;
