export const convertStatusId = (id) => {
  switch (id) {
    case 0:
      return "In Store";
    case 1:
      return "Queued";
    case 2:
      return "Queued";
    case 3:
      return "Packing";
    case 4:
      return "Completed";
    case 6:
      return "Handpack";
    case 7:
      return "Fulfilled";
    case 8:
      return "P & L";
    case 11:
        return "On Hold";
    default:
      return "Error";
  }
};

export const convertNumericalBooleanToYesOrNo = (id) => {
  switch (id) {
    case 0:
      return "No";
    case 1:
      return "Yes";
    default:
      break;
  }
};

export const convertCourierId = (id) => {
  switch (id) {
    case 1:
      return "NZ Post";
    case 2:
      return "Aramex";
    default:
      return "Express";
  }
};

export const formatColumnValueForExport = (field, value) => {
  if (field === "orderNumber") {
    value = "SC" + value;
  } else if (field === "status") {
    value = convertStatusId(value);
  }else if (field === "courier") {
    value = convertCourierId(value);
  }else if (field === "trackingNumbers") {
    value = value && value.replace(/[^a-zA-Z0-9,]/g, '');
  }

  return value;
};

export const formatArrayValueForExport = (field, parent, array) => {
  var value = "";
  if (field === "trackingNumbers" && parent === "courierOrder") {
    value = formatColumnValueForExport(field, array.find(item => item.current === 1)[field]);
  } else if (field === "courier" && parent === "courierOrder") {
    value = formatColumnValueForExport(field, array.find(item => item.current === 1)[field]);
  } else if (field === "quantitiesDescription" && parent === "courierOrder") {
    value = array.find(item => item.current === 1)[field];
  }

  return value;
};
