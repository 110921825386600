import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import "./stockLevels.scss";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper
} from '@material-ui/core';
import {
    EnhancedTableHead,
    stableSort,
    getComparator
} from "../Shared/Table";
import ProgressBar from "../Shared/ProgressBar";

const headCells = [
    { id: "sku", disablePadding: false, align: "center", label: "Sku" },
    { id: "currentQuantity", disablePadding: false, align: "center", label: "Current Quantity" },
    { id: "totalCapacity", disablePadding: false, align: "center", label: "Total Capacity" },
    { id: "percentageRemaining", disablePadding: false, align: "center", label: "Percentage Remaining" }
];

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
        color: "black"
    },
}));

const StockTable = (props) => {
    const { stock } = props;

    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('percentageRemaining');
    const [selected, setSelected] = React.useState([]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        // <div className="stock-table-container-div">
            <Paper className="paper-stock-table">
                <TableContainer>
                    <Table className="stock-table" >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={stock.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {stableSort(stock, getComparator(order, orderBy))
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            tabIndex={-1}
                                            key={row.sku}
                                        >
                                            <TableCell align="center">{row.sku}</TableCell>
                                            <TableCell align="center">{row.currentQuantity}</TableCell>
                                            <TableCell align="center">{row.totalCapacity}</TableCell>
                                            <TableCell align="center">
                                                <ProgressBar
                                                    bgcolor={"#6a1b9a"}
                                                    completed={Math.round(row.percentageRemaining)}
                                                    height={20}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        // </div>
    )
}

const mapStateToProps = (state) => ({
    stock: state.stock.stock
});

export default connect(mapStateToProps, {

})(StockTable);