import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
  } from "@material-ui/core";
  import React from "react";
  
  const ConfirmationDialogActions = ({
    open,
    handleClose,
    title,
    contentText,
    button1Class = "",
    button1Action,
    button1Text,
    button2Class = "",
    button2Action,
    button2Text,
    isLoading = false
  }) => {
    return (
      <Dialog
        open={open}
        onClose={(e, reason) => {
          handleClose();
        }}
        onClick={(e) => {
          //Prevents triggering accordian expand
          e.preventDefault();
          e.stopPropagation();
        }}>
        <DialogTitle style={{ textAlign: "center" }}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: "center" }}>
            {contentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ textAlign: "center", padding: 20 }}>
          {isLoading ? <CircularProgress /> :
            <React.Fragment>
              <Button className={button1Class} onClick={button1Action} variant="contained">
                {button1Text}
              </Button>
              <Button className={button2Class} onClick={button2Action} variant="contained" autoFocus>
                {button2Text}
              </Button>
            </React.Fragment>}
        </DialogActions>
      </Dialog>
    );
  };
  
  export default ConfirmationDialogActions;
  