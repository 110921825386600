import React, { useState, useEffect } from "react";
import "./emailTemplate.scss";
import {
  TextField,
  Typography,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Fab,
  Button,
  CircularProgress,
} from "@material-ui/core";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
} from "@material-ui/icons";
import axios from "axios";
import SplitButton from "./EmailTemplateComponents/SplitButton";
import NewEmailTemplatePopUp from "./EmailTemplateComponents/NewEmailTemplatePopUp";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
  compositeDecorator,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { MergeTagOptions, SaveButton } from "../Utils/ToolbarCustomOptions";
import Dialogy from "../Shared/Dialogy";

const EmailTemplate = (props) => {
  const [templates, setTemplates] = useState([]);
  const [getTemplates, setGetTemplates] = useState(true);
  const [templatesLoaded, setTemplatesLoaded] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showNewEmailPopUp, setShowNewEmailPopUp] = useState(false);
  const [dialogyOpen, setDialogyOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);

  useEffect(() => {
    document.getElementsByTagName("body")[0].style.overflow = "unset";
    return () => {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    };
  }, []);

  useEffect(() => {
    if (getTemplates) {
      setGetTemplates(false);
      axios
        .get(`EmailTemplates/GetEmailTemplates`)
        .then((res) => {
          // console.log(res)
          setTemplates(res.data);
          setTemplatesLoaded(true);
        })
        .catch((e) => {
        //   console.log(e);
        });
    }
  }, [getTemplates]);

  const UpdateTemplateUI = (id, col, newValue) => {
    let newTemplates = [...templates];
    var index = newTemplates.findIndex((t) => t.id === id);
    newTemplates[index] = {
      ...newTemplates[index],
      [col]: newValue,
    };
    setTemplates(newTemplates);
  };

  const BodyEditor = (props) => {
    const { id, bodyHtml } = props;

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const onEditorStateChange = (editorState) => {
      setEditorState(editorState);
    };

    useEffect(() => {
      if (bodyHtml !== null) {
        const blocksFromHTML = convertFromHTML(bodyHtml);
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        setEditorState(
          EditorState.createWithContent(state, compositeDecorator)
        );
      }
    }, [bodyHtml]);

    return (
      <div className="editor-container-row-cell">
        <Editor
          editorState={editorState}
          editorClassName={"editor-style-row-cell"}
          onEditorStateChange={onEditorStateChange}
          handlePastedText={() => false}
          toolbar={{
            options: [],
          }}
          toolbarCustomButtons={[
            <MergeTagOptions
              onChange={onEditorStateChange}
              editorState={editorState}
            />,
          ]}
          onBlur={(event, editorState) => {
            var newHtml = draftToHtml(
              convertToRaw(editorState.getCurrentContent())
            );
            if (bodyHtml !== newHtml) {
              axios
                .patch(`EmailTemplates/UpdateEmailTemplate`, {
                  type: "body",
                  bodyHtml: newHtml,
                  id: id,
                })
                .then((res) => {
                //   console.log(res);
                })
                .catch((e) => {
                //   console.log(e);
                });
            }
          }}
        />
      </div>
    );
  };

  return (
    <div className="email-template-div">
      {showNewEmailPopUp && (
        <NewEmailTemplatePopUp
          modalState={showNewEmailPopUp}
          handleClose={() => setShowNewEmailPopUp(false)}
          setTemplates={setTemplates}
          setGetTemplates={setGetTemplates}
          type={selectedIndex}
        />
      )}
      {
        <Dialogy
          open={dialogyOpen}
          handleClose={() => {
            setIdToDelete(null);
            setDialogyOpen(false);
          }}
          details={{
            title: "Delete Confirmation",
            text: "Are you sure you want to delete this template?",
            type: "checks",
          }}
          proceed={() => {
            axios
              .delete(`EmailTemplates/DeleteEmailTemplate/${idToDelete}`)
              .then((res) => {
                // console.log(res);
                setGetTemplates(true);
                setIdToDelete(null);
                setDialogyOpen(false);
              })
              .catch((e) => {
                // console.log(e);
              });
          }}
        />
      }
      <SplitButton
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        reloadTempates={() => setGetTemplates(true)}
      />
      <Table
        stickyHeader
        style={{ maxHeight: "80vh", overflow: "auto" }}
        className="email-template-table"
      >
        <colgroup>
          <col width="30%" />
          <col width="65%" />
          <col width="5%" />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="h6">Subject</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6">Body</Typography>
            </TableCell>
            <TableCell>
              <Fab
                size="small"
                color="primary"
                onClick={() => setShowNewEmailPopUp(true)}
              >
                <AddIcon />
              </Fab>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getTemplates && <CircularProgress />}
          {templates
            .filter((t) => t.type === selectedIndex)
            .map((template) => (
              <TableRow key={`tempate-${template.id}`}>
                <TableCell>
                  <TextField
                    value={template.subject}
                    variant="standard"
                    size="medium"
                    fullWidth
                    onChange={(e) => {
                      UpdateTemplateUI(template.id, "subject", e.target.value);
                    }}
                    onBlur={() => {
                      axios
                        .patch(`EmailTemplates/UpdateEmailTemplate`, {
                          type: "subject",
                          subject: template.subject,
                          id: template.id,
                        })
                        .then((res) => {
                        //   console.log(res);
                        })
                        .catch((e) => {
                        //   console.log(e);
                        });
                    }}
                  />
                </TableCell>
                <TableCell>
                  <BodyEditor
                    subject={template.subject}
                    bodyHtml={template.body}
                    id={template.id}
                  />
                </TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => {
                      setIdToDelete(template.id);
                      setDialogyOpen(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default EmailTemplate;
