import React from "react";
import { connect } from "react-redux";
import DelayedOrdersTable from "./DelayedOrdersTable";

const DelayedOrders = (props) => {
  const { 
    delayedOrders,
    type
   } = props;

  if (delayedOrders) {
    if(type === "delays"){
      return <DelayedOrdersTable orders={delayedOrders.filter(d => d.status !== "Disputed" && !d.fromDisputed && !d.fromQuickFix)} type={type}/>;
    } else if(type === "disputed"){
      return <DelayedOrdersTable orders={delayedOrders.filter(d => d.status === "Disputed")} type={type}/>
    } else if(type === "quickFix"){
      return <DelayedOrdersTable orders={delayedOrders.filter(d => d.fromQuickFix)} type={type}/>
    }
  } else {
    return <div>Loading...</div>;
  }
};

const mapStateToProps = (state) => ({
  delayedOrders: state.order.delayedOrders,
});

export default connect(mapStateToProps, {})(DelayedOrders);
