import React from "react";
import { connect } from "react-redux";
import OrdersTable from "../Shared/OrdersTable";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const BulkStoreOrders = (props) => {
  const { orders, hpMode } = props;
  const history = useHistory();

  if (!hpMode) {
    history.push("/");
  }

  if (orders) {
    return (
      <OrdersTable
        parent="Store Orders"
        overrideTitle="Bulk Store Orders"
        orders={orders.filter((o) => o.status === 0 && o.hpBulkPack === 1)}
      />
    );
  } else {
    return <div>Loading...</div>;
  }
};

const mapStateToProps = (state) => ({
  orders: state.order.orders,
  hpMode: state.system.hpMode,
});

export default connect(mapStateToProps, {})(BulkStoreOrders);
