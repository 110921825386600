import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  text: "",
  severity: "info",
  open: false,
};
const snackbar = createReducer(initialState, {
  SET_SNACKBAR_PROPS: (state, action) => {
    const {payload : {
      text, severity, open
    }} = action;
    return {
      ...state,
      text,
      severity,
      open,
    };
  },
});

export default snackbar;
