export const getValueOrNull = (value) => {
  if ((value !== null) & (value !== undefined)) {
    return value;
  }

  return null;
};

export const getValueOrEmptyString = (value) => {
  if ((value !== null) & (value !== undefined)) {
    return value;
  }

  return "";
};

export const hasValue = (item) => {
  if (item !== null && item !== undefined && item !== "") {
    return true;
  }
  return false;
};

export const scrollToBottom = (id) => {
  var div = document.getElementById(id);
  if (div != null) {
    div.scrollTop = div.scrollHeight;
  }
};

export const isNumeric = (str) => {
  if (['string', 'number'].indexOf(typeof str) === -1) return false; // we only process strings and numbers!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export const isEmpty = (input) => {
  if (input === '' || input === null || input === undefined || input === " ") return true;
}