import React from "react";
import _ from "lodash";
import { Typography, Button } from "@material-ui/core";
import "./packingScreen.scss";
import PackingScreenModalStatusBadge from "./PackingScreenModalStatusBadge";
import { completeChunk, printChunk } from "../../reduxActions/order";
import { connect } from "react-redux";

const PackingScreenModalChunkInfo = (props) => {
  const {
    className,
    chunkInfo,
    chunkNumber,
    ordersInChunk,
    printChunk,
    completeChunk,
  } = props;

  const smallestStatus = _.minBy(ordersInChunk, (order) => order.status).status;

  const boxSizes = ordersInChunk.reduce(
    (acc, order) => {
      order.boxSize.split(",").forEach((boxSize) => {
        if (parseInt(boxSize) <= 6) {
          acc[6]++;
        } else if (parseInt(boxSize) <= 12) {
          acc[12]++;
        } else if (parseInt(boxSize) <= 18) {
          acc[18]++;
        } else if (parseInt(boxSize) <= 24) {
          acc[24]++;
        } else if (parseInt(boxSize) <= 36) {
          acc[36]++;
        }
      });
      return acc;
    },
    { 6: 0, 12: 0, 18: 0, 24: 0, 36: 0 }
  );

  const totalHyperPacks = ordersInChunk.reduce((acc, order) => {
    order.courierOrder.quantitiesDescription.split(" | ").forEach((item) => {
      const [quantity, value] = item.split(" x ");
      const quantityInt = parseInt(quantity);
      if (!isNaN(quantityInt)) {
        const numHyperPacks = Math.floor(quantityInt / 6);
        if (numHyperPacks) {
          if (!(value in acc)) {
            acc[value] = 0;
          }
          acc[value] = acc[value] + numHyperPacks;
        }
      }
    });
    return acc;
  }, {});

  return (
    <div className={`packing-screen-section-container ${className ?? ""}`}>
      <div className="packing-screen-section-section">
        <Typography variant="h4">{`Chunk #${chunkNumber}`}</Typography>
        {chunkInfo?.noteWriter !== undefined &&
          chunkInfo.noteWriter !== null && (
            <Typography variant="h6">{`Note Writer #${chunkInfo.noteWriter}`}</Typography>
          )}
        <Button variant="contained" onClick={() => printChunk(chunkNumber)}>
          Print All Labels
        </Button>
        <PackingScreenModalStatusBadge status={smallestStatus} />
      </div>
      <div className="packing-screen-section-section">
        <div className="packing-screen-captioned">
          <Typography variant="caption">Assigned To</Typography>
          <Typography variant="span" className="packing-screen-captioned-item">
            {chunkInfo?.assignedTo}
          </Typography>
        </div>
        <div className="packing-screen-captioned">
          <Typography variant="caption">Box Sizes</Typography>
          <div className="packing-screen-captioned-grid">
            {Object.entries(boxSizes)
              .filter(([_, count]) => count > 0)
              .map(([size, count]) => (
                <div key={size}>{`${count} x ${size}0`}</div>
              ))}
          </div>
        </div>
        <div className="packing-screen-captioned">
          <Typography variant="caption">Total Hyper Packs Required</Typography>
          <div className="packing-screen-captioned-grid">
            {Object.entries(totalHyperPacks)
              .filter(([_, count]) => count > 0)
              .map(([size, count]) => (
                <div key={size}>{`${count} x 6${size}`}</div>
              ))}
          </div>
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => completeChunk(chunkNumber)}
        >
          Complete Chunk
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { printChunk, completeChunk })(
  PackingScreenModalChunkInfo
);
