import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useState, useContext } from "react";
import { StoreContext } from "../../../context/store/StoreContext";
import {
  calculateDiscountTotal,
  calculateTotalCheckout,
} from "../../../repository/shopify";
import CurrencyFormatter from "../../Shared/CurrencyFormatter";

import "./orderSummary.scss";
import { Skeleton } from "@material-ui/lab";
const OrderSummary = ({
  setDiscountCode,
  discountCode,
  shippingCost,
  isReadOnly,
  canEditCustomTotal,
}) => {
  const {
    checkout,
    addDiscountToCart,
    useCustomTotal,
    customTotal,
    setUseCustomTotal,
    setCustomTotal,
    calculatingPrices,
  } = useContext(StoreContext);

  const fetchDiscountCode = async () => {
    if (discountCode) {
      const invalidDiscountMessage = await addDiscountToCart(discountCode);
      if (invalidDiscountMessage) {
        alert(invalidDiscountMessage.replace("Discount code ", ""));
      }
    }
  };

  return (
    <div>
      {!isReadOnly && !useCustomTotal && (
        <div className="create-order-discount-code-container">
          <TextField
            size="small"
            fullWidth
            label="Discount code"
            value={discountCode}
            onChange={(e) => {
              setDiscountCode(e.target.value);
            }}
            variant="outlined"
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={fetchDiscountCode}
          >
            Apply Code
          </Button>
        </div>
      )}
      <div className="order-summary-wrapper">
        <div className="order-summary-item">
          <Typography variant="body2">Subtotal:</Typography>
          <Typography variant="body2">
            {!calculatingPrices ? (
              <CurrencyFormatter
                useDollar
                amount={checkout?.lineItemsSubtotalPrice?.amount}
              />
            ) : (
              <Skeleton width={80} />
            )}
          </Typography>
        </div>
        <div className="order-summary-item">
          <Typography variant="body2">
            Discount:{" "}
            {!useCustomTotal &&
              `${
                checkout?.discountApplications?.length > 0
                  ? `${checkout?.discountApplications[0].code}`
                  : ""
              }`}
          </Typography>
          <Typography variant="body2">
            {!calculatingPrices ? (
              <CurrencyFormatter
                amount={
                  useCustomTotal
                    ? calculateTotalCheckout(checkout, shippingCost) -
                      customTotal
                    : calculateDiscountTotal(
                        checkout?.discountApplications,
                        checkout?.lineItemsSubtotalPrice?.amount
                      ) || 0
                }
                appendZero
                useDollar
                isDiscountCode
              />
            ) : (
              <Skeleton width={80} />
            )}
          </Typography>
        </div>
        <div className="order-summary-item">
          <Typography variant="body2"> Shipping & Delivery:</Typography>
          <Typography variant="body2">
            {!calculatingPrices ? (
              <CurrencyFormatter useDollar amount={shippingCost?.rate || 0} />
            ) : (
              <Skeleton width={80} />
            )}
          </Typography>
        </div>
        <div className="order-summary-item">
          <Typography variant="body2">
            {" "}
            <b>Total:</b>
          </Typography>
          <Typography variant="body2">
            {!calculatingPrices ? (
              <CurrencyFormatter
                amount={
                  useCustomTotal
                    ? customTotal
                    : calculateTotalCheckout(checkout, shippingCost)
                }
                useDollar
              />
            ) : (
              <Skeleton width={80} />
            )}
          </Typography>
        </div>
        <div className="order-summary-item">
          <Typography variant="body2">Use Custom Total:</Typography>
          <Checkbox
            checked={useCustomTotal}
            disabled={!canEditCustomTotal}
            onChange={() => {
              if (!useCustomTotal) {
                setCustomTotal(
                  `${calculateTotalCheckout(checkout, shippingCost)}`
                );
              }
              setUseCustomTotal(!useCustomTotal);
            }}
          />
        </div>
        {useCustomTotal && (
          <div className="order-summary-item order-summary-custom-total">
            <Typography variant="body2">
              <b>Custom Total:</b>
            </Typography>
            <TextField
              variant="outlined"
              pattern="[0-9]*"
              fullWidth
              size="small"
              value={`${customTotal}`}
              disabled={!canEditCustomTotal}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                if (e.target.value === "") {
                  setCustomTotal(0);
                } else {
                  const num = parseFloat(e.target.value);
                  if (
                    num > 0 &&
                    num <= calculateTotalCheckout(checkout, shippingCost)
                  ) {
                    setCustomTotal(num);
                  }
                }
              }}
              onBlur={(e) => {
                const num = parseFloat(e.target.value);
                if (num < 0.5) {
                  setCustomTotal(0.5);
                }
                if (num > calculateTotalCheckout(checkout, shippingCost)) {
                  setCustomTotal(
                    calculateTotalCheckout(checkout, shippingCost)
                  );
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderSummary;
