import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Button,
    Snackbar,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import Axios from "axios";

const WhiteTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
            },
        },
    },
})(TextField);

const EditCustomerRatesTable = (props) => {

    const [selectedRate, setSelectedRate] = useState(null);
    const [skuPriceItems, setSkuPriceItems] = useState([]);
    const [customerRates, setCustomerRates] = useState([]);

    const [isUpdating, setIsUpdating] = useState(false);
    const [messageDisplay, setMessageDisplay] = useState(null);
    const [fetchRates, setFetchRates] = useState(true);

    const UpdateCustomerRatesJson = () => {
        setIsUpdating(true);
        Axios.post("/BusinessDirect/UpdateCustomerRatesJson", {
            ...selectedRate,
            ratesJson: JSON.stringify(selectedRate["ratesJson"])
        })
            .then(res => {
                setMessageDisplay({
                    message: "Success. The rate has been updated",
                    hasError: false,
                });
                setFetchRates(true);
            }).catch(e => {
                setMessageDisplay({
                    message: "There was an error updating the rates. Please try again",
                    hasError: true,
                });
            }).finally(() => {
                setIsUpdating(false);
            })
    }

    useEffect(() => {
        Axios.get(`BusinessDirect/GetSkuPrice`)
            .then((res) => {
                setSkuPriceItems(res.data);
            })
            .catch((e) => { });
    }, []);

    useEffect(() => {
        if(fetchRates){
            setFetchRates(false);
            Axios.get(`BusinessDirect/GetCustomerRates`)
            .then((res) => {
                debugger;
                for (var i = 0; i < res.data.length; i++) {
                    res.data[i]["ratesJson"] = JSON.parse(res.data[i]["ratesJson"]);
                }
                setCustomerRates(res.data);
            })
            .catch((e) => { });
        }
    }, [fetchRates]);

    return (
        <div className="business-direct-form">
            {messageDisplay && (
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={messageDisplay}
                    autoHideDuration={5000}
                    onClose={() => setMessageDisplay(null)}
                >
                    {messageDisplay.hasError ? (
                        <Alert
                            onClose={() => setMessageDisplay(null)}
                            variant="filled"
                            severity="error"
                        >
                            {messageDisplay.message}
                        </Alert>
                    ) : (
                        <Alert
                            onClose={() => setMessageDisplay(null)}
                            variant="filled"
                            severity="success"
                        >
                            {messageDisplay.message}
                        </Alert>
                    )}
                </Snackbar>
            )}
            <Grid container>
                <Grid item xs={6} md={6} lg={6}>
                    <FormControl style={{ display: "flex" }}>
                        <InputLabel>Rate</InputLabel>
                        <Select
                            value={selectedRate}
                            onChange={(e) => setSelectedRate(e.target.value)}
                            fullWidth
                            variant="standard"
                            disabled={isUpdating}
                        >
                            <MenuItem disabled value={null}>{`Choose Rate`}</MenuItem>
                            {customerRates.map(cr => {
                                return (
                                    <MenuItem value={cr}>{cr.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button size="small" variant="contained" color="primary" onClick={() => UpdateCustomerRatesJson()} disabled={isUpdating || selectedRate === null}>
                            {`Save "${selectedRate?.name}"`}
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <div style={{ overflowY: "auto", height: "80vh" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Code</TableCell>
                            <TableCell>Flavour</TableCell>
                            <TableCell>Price</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedRate === null ? <React.Fragment></React.Fragment> :
                            skuPriceItems.map(item => {
                                return (
                                    <TableRow id={`${selectedRate.name}-${item.code}-row`}>
                                        <TableCell>{item.code}</TableCell>
                                        <TableCell>{item.flavour}</TableCell>
                                        <TableCell>
                                            <WhiteTextField
                                                disabled={isUpdating}
                                                fullWidth
                                                variant="standard"
                                                onChange={(e) => {
                                                    var copyOfRatesJson = { ...selectedRate["ratesJson"] }
                                                    copyOfRatesJson[item.flavour] = parseFloat(e.target.value);
                                                    setSelectedRate({
                                                        ...selectedRate,
                                                        "ratesJson": copyOfRatesJson
                                                    })
                                                }}
                                                type="number"
                                                value={selectedRate["ratesJson"][item.flavour] ? selectedRate["ratesJson"][item.flavour] : null}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}

export default EditCustomerRatesTable;