import { Button, Divider, TextField, Typography } from "@material-ui/core";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { convertTime, getDateAsString } from "../../Utils/dateTime";
import "./customerNotes.scss";
const CustomerNotes = ({ customerId }) => {
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    pullCustomerNotes();
  }, [customerId]);

  const pullCustomerNotes = () => {
    axios.get(`Customer/FetchCustomerNotes/${customerId}`).then(({ data }) => {
      setNotes(data);
    });
  };

  const saveNote = () => {
    axios
      .post(`Customer/SaveCustomerNote`, {
        customerId: customerId,
        note: note,
        date: new Date(),
      })
      .then(({ data }) => {
        setNotes([...notes, data]);
        setNote("");
      })
      .catch((err) => alert("something went wrong"));
  };
  return (
    <div className="customer-notes-root">
      <div className="customer-notes-input-wrapper">
        <TextField
          size="small"
          fullWidth
          label="Note"
          value={note}
          onChange={(e) => {
            setNote(e.target.value);
          }}
          variant="outlined"
        />
        <Button fullWidth variant="outlined" color="primary" onClick={saveNote}>
          Save
        </Button>
      </div>
      <div>
        {notes.length > 0 ? (
          notes.map((noteObj, index) => (
            <div className="customer-notes-line" key={index}>
              <Typography variant="body2">{noteObj.note}</Typography>
              <Typography variant="body2">
                {convertTime(noteObj.date)} {}
              </Typography>
            </div>
          ))
        ) : (
          <>
            <Divider />
            <Typography variant="h6">No notes here</Typography>
          </>
        )}
      </div>
    </div>
  );
};

export default CustomerNotes;
