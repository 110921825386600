import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Switch } from "@material-ui/core";

const IOSSwitch = (props) => {
  return (
    <Switch
      disableRipple
      classes={{
        root: "switch-root",
        switchBase: "switch-switchbase",
        thumb: "switch-thumb",
        track: "switch-track",
        checked: "switch-checked",
      }}
      {...props}
    />
  );
};

export default IOSSwitch;
