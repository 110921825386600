import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
    Modal,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
    Grid,
    FormControl,
    InputLabel,
    Select,
    Input,
    MenuItem,
    CircularProgress
} from "@material-ui/core";
import {
    Close as CloseIcon
} from "@material-ui/icons";
import "./PostalZone.scss";
import axios from "axios";


const useStyles = makeStyles(theme => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        minHeight: 390,
        minWidth: "40vW",
        maxHeight: "80vH",
        maxWidth: "70vW",
        overflow: "auto"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
        maxWidth: 300,
    },
}));

const PostcodeMenu = (props) => {
    const classes = useStyles();
    const {
        modalState,
        handleModal,
        delayedOrdersForArea,
        selectedPoly,
        deliveryZones,
        updatePostcodeAreaDeliveryZone
    } = props;

    const [currentDeliveryZone, setCurrentDeliveryZone] = useState("");
    const [saving, setSaving] = useState(false);

    const handleSave = () => {
        setSaving(true);
        axios.patch(`PostcodeAreas/UpdatePostcodeAreaZone/${selectedPoly?.metaData.id}`, currentDeliveryZone)
            .then((res) => {
                updatePostcodeAreaDeliveryZone(selectedPoly, currentDeliveryZone)
                handleModal();
            }).catch(e => console.log(e))
            .finally(() => setSaving(false))
    }

    useEffect(() => {
        var index = deliveryZones?.findIndex(zone => zone.id === selectedPoly?.metaData.deliveryZone.id);
        if (index >= 0) {
            setCurrentDeliveryZone(deliveryZones[index]);
        }
    }, [deliveryZones, selectedPoly]);


    return (
        <Modal
            open={modalState}
            onClose={(_, reason) => {
                if (reason !== "backdropClick") {
                    handleModal();
                }
            }}
            className={classes.modal}
        >
            <div>
                <AppBar position="static" style={{ background: "#3d4977" }}>
                    <Toolbar variant="dense" style={{ paddingRight: 0 }}>
                        <Typography
                            variant="h6"
                            align="center"
                            style={{ flexGrow: 1 }}
                        >
                            {`Postcode: ${selectedPoly?.metaData.postcode}`}
                        </Typography>
                        <span style={{ display: "flex" }}>
                            <IconButton
                                onClick={handleModal}
                                disabled={saving}
                            >
                                <CloseIcon />
                            </IconButton>
                        </span>
                    </Toolbar>
                </AppBar>
                <div
                    className={classes.paper}
                >
                    {currentDeliveryZone !== null && (
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <FormControl className={classes.formControl}>
                                <InputLabel>Zone</InputLabel>
                                <Select
                                    value={currentDeliveryZone}
                                    onChange={e => setCurrentDeliveryZone(e.target.value)}
                                >
                                    {deliveryZones?.map((zone) => (
                                        <MenuItem key={zone.id} value={zone}>
                                            {`${zone.name} - ${zone.courier}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {saving ? <CircularProgress /> :
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    onClick={() => handleSave()}
                                    className="zone-edit-save-button"
                                    disabled={saving}
                                >
                                    {!saving ? `Save & Exit` : <CircularProgress />}
                                </Button>
                            }
                        </div>
                    )}
                    {delayedOrdersForArea?.length > 0 ? (
                        <Table id="postal-map-delayed-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Order</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Courier</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Hours</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {delayedOrdersForArea.map(delayedOrder => {
                                    return (
                                        <TableRow>
                                            <TableCell>SC{delayedOrder.order.orderNumber}</TableCell>
                                            <TableCell>{delayedOrder.order.customerFirstName} {delayedOrder.order.customerLastName}</TableCell>
                                            <TableCell>{delayedOrder.courier}</TableCell>
                                            <TableCell>{delayedOrder.status}</TableCell>
                                            <TableCell>{delayedOrder.hoursDelayed}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    ) : <Typography align="center">No orders delayed in this postcode</Typography>}
                </div>
            </div>
        </Modal>
    )
}

export default PostcodeMenu;