import React from "react";
import { Backdrop } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GridLoader } from "react-spinners";
import { Typography } from "@material-ui/core";
import "./loader.scss";
import GAuth from "../OAuth/GAuth";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: "99999",
    color: "white",
    background: "black !important",
  },
}));

const Loader = (props) => {
  const classes = useStyles();
    const { loaderText, location } = props;

  return (
    <div>
      <Backdrop className={classes.backdrop} open={true}>
              <GAuth endpoint={location.state !== undefined ? location.state.endpoint === "/index.html" ? "/Home" : location.state.endpoint : "/Home"}/>
        <div
          className="align-items-center justify-content-center"
          style={{ width: "102px", height: "auto" }}
        >
          {loaderText != null ? (
            <Typography component={"h2"} className="loader-text">
              {loaderText}
            </Typography>
          ) : (
            <Typography component={"h2"} className="loader-text">
            Signing In
          </Typography>
          )}
          <GridLoader
            size={30}
            className="center-loader"
            color="white"
            loading={true}
          />
        </div>
        {/* <div
            className="d-flex align-items-center justify-content-center"
            style={{ width: '90px', height: '80px' }}>
            <GridLoader color={"white"} loading={true} />
          </div> */}
      </Backdrop>
    </div>
  );
};

export default Loader;
