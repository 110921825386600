import { createAction } from "@reduxjs/toolkit";
import axios from "axios";
import { getNowDateString } from "../components/Utils/dateTime";
import { addDays } from "../components/Utils/dateTime";
import _ from "lodash";

export const setError = createAction("SET_ERROR");

export const sendInfinityNotesToStore = createAction("GET_INFINITY_NOTES");
export const setInfinityNotesLoading = createAction("INFINITY_NOTES_LOADING");
export const setInfinityNotesLoaded = createAction("INFINITY_NOTES_LOADED");
export const getInfinityNotes = () => async (dispatch) => {
  try {
    const values = await axios.get("InfinityNotes/GetInfinityNote");
    let infinityNotes = values.data.map((m) => {
      m.messageObj = JSON.parse(m.messageObj);
      return m;
    });
    dispatch(sendInfinityNotesToStore(infinityNotes));
  } catch (error) {
    dispatch(setError(error));
    dispatch(setInfinityNotesLoaded());
  }
};

export const onEditField = createAction("EDIT_INFINITY_NOTE_FIELD");

export const onUpdateField = (infinityNote, field) => async (dispatch) => {
  try {
    await axios.patch(`InfinityNotes/PatchInfinityNote/${field}`, infinityNote);
  } catch (error) {
    dispatch(setError(error));
  }
};

export const removeInfinityNote = (id, index) => async (dispatch) => {
  dispatch(removeInfinityNoteFromStore(index));
  try {
    await axios.delete(`InfinityNotes/DeleteInfinityNote/${id}`);
  } catch (error) {
    dispatch(setError(error));
  }
};

export const removeInfinityNoteFromStore = createAction("REMOVE_INFINITY_NOTE_FROM_STORE");

export const swapAndUpdate = (d, s, infinityNotes) => async (dispatch) => {
  let originalArr = [...infinityNotes];
  const start = originalArr[d].start;
  let newArr = _.cloneDeep(infinityNotes);
  debugger;

  var z = newArr[d];
  newArr[d] = newArr[s];
  newArr[s] = z;

  for (let i = 0; i < newArr.length; i++) {
    newArr[i].orderOnScreen = i;
  };

  dispatch(swapAndUpdateStore(newArr));
  try{
    var objToSendToDB = _.cloneDeep(newArr);
    objToSendToDB.map((m, index) => {
      m.messageObj = JSON.stringify(m.messageObj)
      return m;
    });
    await axios.patch("InfinityNode/UpdateDb", objToSendToDB);
  }
  catch(e) {
  }
};

export const swapAndUpdateStore = createAction("SWAP_AND_UPDATE_INFINITY_NOTES");
