import React from "react";
import { connect } from "react-redux";
import OrdersTable from "../Shared/OrdersTable";
import { useHistory } from "react-router-dom";
import { packingStationFilter } from "./PackingStation";

const BulkPackingStation = (props) => {
  const { orders, hpMode } = props;
  const history = useHistory();

  if (!hpMode) {
    history.push("/LineInfo");
  }

  if (orders) {
    return (
      <OrdersTable
        parent="Bulk Packing Station"
        isChunkSorted={true}
        orders={packingStationFilter(orders, true)}
      />
    );
  } else {
    return <div>Loading...</div>;
  }
};

const mapStateToProps = (state) => ({
  orders: state.order.orders,
  hpMode: state.system.hpMode,
});

export default connect(mapStateToProps, {})(BulkPackingStation);
