import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Button,
    Snackbar
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import Axios from "axios";

const WhiteTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
            },
        },
    },
})(TextField);

const CreateCustomerRatesTable = (props) => {

    const [newRate, setNewRate] = useState(null);
    const [skuPriceItems, setSkuPriceItems] = useState([]);
    const [defaultRate, setDefaultRate] = useState(null);

    const [isUpdating, setIsUpdating] = useState(false);
    const [messageDisplay, setMessageDisplay] = useState(null);


    const UpdateCustomerRatesJson = () => {
        Axios.post("/BusinessDirect/CreateCustomerRatesJson", {
            ...newRate,
            ratesJson: JSON.stringify(newRate["ratesJson"])
        })
            .then(res => {
                setMessageDisplay({
                    message: `Success. ${newRate?.name} has been created`,
                    hasError: false,
                });
                setNewRate({ ...defaultRate })
            }).catch(e => {
                var message = `There was an error creating ${newRate?.name}. Please try again.`;
                if (typeof e.response.data === "string") {
                    message = `There was an error creating ${newRate?.name}. ${e.response.data}.`;
                }
                setMessageDisplay({
                    message: message,
                    hasError: true,
                });

            }).finally(() => {
                setIsUpdating(false);
            })
    }

    useEffect(() => {
        Axios.get(`BusinessDirect/GetSkuPrice`)
            .then((res) => {
                setSkuPriceItems(res.data);
            })
            .catch((e) => { });
    }, []);

    useEffect(() => {
        Axios.get(`BusinessDirect/GetCustomerRates`)
            .then((res) => {
                for (var i = 0; i < res.data.length; i++) {
                    if (res.data[i]["name"] === "Wholesale") {
                        res.data[i]["ratesJson"] = JSON.parse(res.data[i]["ratesJson"]);
                        setDefaultRate({
                            id: 0,
                            name: "New Rate",
                            ratesJson: res.data[i]["ratesJson"]
                        });
                        setNewRate({
                            id: 0,
                            name: "New Rate",
                            ratesJson: res.data[i]["ratesJson"]
                        })
                        break;
                    }
                }

            })
            .catch((e) => { });
    }, []);

    return (
        <div className="business-direct-form">
            {messageDisplay && (
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={messageDisplay}
                    autoHideDuration={5000}
                    onClose={() => setMessageDisplay(null)}
                >
                    {messageDisplay.hasError ? (
                        <Alert
                            onClose={() => setMessageDisplay(null)}
                            variant="filled"
                            severity="error"
                        >
                            {messageDisplay.message}
                        </Alert>
                    ) : (
                        <Alert
                            onClose={() => setMessageDisplay(null)}
                            variant="filled"
                            severity="success"
                        >
                            {messageDisplay.message}
                        </Alert>
                    )}
                </Snackbar>
            )}
            <Grid container spacing={2}>
                <Grid item xs={6} md={6} lg={6}>
                    <WhiteTextField
                        label="Rate Name"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => {
                            setNewRate({
                                ...newRate,
                                name: e.target.value
                            })
                        }}
                        value={newRate?.name}
                        spellCheck
                        size="small"
                    />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button size="small" variant="contained" color="primary" onClick={() => UpdateCustomerRatesJson()} disabled={isUpdating}>
                            {`Create "${newRate?.name}"`}
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <div style={{ overflowY: "auto", height: "80vh" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Code</TableCell>
                            <TableCell>Flavour</TableCell>
                            <TableCell>Price</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {newRate === null ? <React.Fragment></React.Fragment> :
                            skuPriceItems.map(item => {
                                return (
                                    <TableRow id={`${newRate.name}-${item.code}-row`}>
                                        <TableCell>{item.code}</TableCell>
                                        <TableCell>{item.flavour}</TableCell>
                                        <TableCell>
                                            <WhiteTextField
                                                fullWidth
                                                variant="standard"
                                                onChange={(e) => {
                                                    var copyOfRatesJson = { ...newRate["ratesJson"] }
                                                    copyOfRatesJson[item.flavour] = parseFloat(e.target.value);
                                                    setNewRate({
                                                        ...newRate,
                                                        "ratesJson": copyOfRatesJson
                                                    })
                                                }}
                                                type="number"
                                                value={newRate["ratesJson"][item.flavour] ? newRate["ratesJson"][item.flavour] : null}
                                                disabled={isUpdating}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}

export default CreateCustomerRatesTable;