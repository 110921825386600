import { createAction } from "@reduxjs/toolkit";

export const setError = createAction("SET_ERROR");

export const handleStartStop = createAction("START_STOP_TIMER_STORE");
export const handleStartTimeStore = createAction("START_TIME_STORE");
export const handleStopTimeStore = createAction("STOP_TIME_STORE");

export const handleStartStopTime = (value) => async (dispatch) => {
  try {
    dispatch(handleStartStop(value));
    if (value) {
      dispatch(handleStopTimeStore());
      const handle = setInterval(() => {
        dispatch(handleStartTimeStore(handle));
      }, 1000);
    } else {
      dispatch(handleStopTimeStore());
    }
  } catch (error) {
    dispatch(setError(error));
  }
};
