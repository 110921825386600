import { createAction } from "@reduxjs/toolkit";
import axios from "axios";

export const sendStockToStore = createAction("GET_STOCK");
export const sendStockRowsToStore = createAction("GET_STOCK_ROWS");
export const sendShelfInfoToStore = createAction("GET_SHELF_INFO");

export const getStock = () => async (dispatch) => {
  try {
    var values = await axios.get("Stock/GetCurrentStock");
    dispatch(sendStockToStore(values.data));
  } catch (error) {}
};

export const getStockRows = () => async (dispatch) => {
  try {
    var values = await axios.get("Stock/GetStockRowsFromDb");
    // console.log("values.data")
    // console.log(values.data)
    dispatch(sendStockRowsToStore(values.data));
  } catch (error) {}
};

export const getShelfInfo = () => async (dispatch) => {
  try {
    var values = await axios.get("Stock/getShelfInfo/30");
    dispatch(sendShelfInfoToStore(values.data));
  } catch (error) {}
};
