import React, { forwardRef, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    FormControlLabel,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Typography,
    CircularProgress,
    FormGroup,
    Checkbox,
} from "@material-ui/core";
import Axios from "axios"

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} mountOnEnter unmountOnExit />;
});

const GenerateReturnTicketPopUp = (props) => {
    const {
        open,
        handleClose,
        setSnackBarProps
    } = props;

    const [loading, setLoading] = useState(false);
    const [boxSize, setBoxSize] = useState("6");
    const [print, setPrint] = useState(true);
    const [download, setDownload] = useState(false);

    const generateLabel = () => {
        setLoading(true)
        Axios.post(`SystemActions/GenerateReturnLabel/${boxSize}/${print}/${download}`,
            {},
            {
                params: {
                    cacheBustTimestamp: Date.now(), // prevents IE cache problems on re-download
                },
                responseType: "blob",
                headers: {
                    Accept: "application/octet-stream",
                },
            })
            .then(res => {
                setSnackBarProps("success", "Success", true);
                // var downloadStream = res.data.downloadStream;
                if (res.data !== "" && download) {
                    const blob = new Blob([res.data], { type: "application/pdf" });

                    //Used to open pdf in a another tab
                    // const fileUrl = URL.createObjectURL(blob);
                    // const w = window.open(fileUrl, '_blank');
                    // w && w.focus();

                    // Code Below to force download
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `Return-Label-${+new Date()}.pdf`;
                    link.click();
                }
            })
            .catch(e => {
                props.setSnackBarProps("warning", "Failed", true);
            })
            .finally(() => {
                handleClose()
                setLoading(false)
            })
    }

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={(e, reason) => {
                    if(reason !== 'backdropClick'){
                        handleClose()
                    }
                }}
            >
                <DialogTitle>Generate Return Label</DialogTitle>
                <DialogContent>
                    <FormGroup>
                        <InputLabel id={"box-size-select-label"}>Box Size</InputLabel>
                        <Select
                            id="box-size-select"
                            value={boxSize}
                            onChange={(e) => setBoxSize(e.target.value)}
                            disabled={loading}
                        >
                            <MenuItem value={"6"}>6</MenuItem>
                            <MenuItem value={"12"}>12</MenuItem>
                            <MenuItem value={"18"}>18</MenuItem>
                            <MenuItem value={"24"}>24</MenuItem>
                            <MenuItem value={"36"}>36</MenuItem>
                        </Select>
                        <FormControlLabel
                            control={<Checkbox color="primary" disabled={loading} checked={print} onChange={e => setPrint(e.target.checked)} name="print-return-label" />}
                            label="Print"
                        />
                        <FormControlLabel
                            control={<Checkbox color="primary" disabled={loading} checked={download} onChange={e => setDownload(e.target.checked)} name="download-return-label" />}
                            label="Download"
                        />
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    {loading ?
                        <CircularProgress style={{ margin: 10 }} />
                        : (
                            <React.Fragment>
                                <Button variant="contained" color="default" onClick={() => handleClose()}>
                                    Go Back
                                </Button>
                                <Button variant="contained" color="primary" onClick={() => generateLabel()}>
                                    Continue
                                </Button>
                            </React.Fragment>
                        )
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default GenerateReturnTicketPopUp;