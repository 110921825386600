import React, { useEffect, useState } from "react";
import HomeIcon from "@material-ui/icons/Home";
import { useHistory, useLocation } from "react-router-dom";
import { SettingsInputHdmiOutlined } from "@material-ui/icons";

const Layout = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [home, sethome] = useState(false);

  const goHome = () => {
    history.push("/");
  };

  useEffect(() => {
    if (location.pathname === "/Home" || location.pathname === "/login") {
      sethome(false);
    } else {
      sethome(true);
    }
  });

  const childrenWithProps = React.Children.map(props.children, child => {
    // checking isValidElement is the safe way and avoids a typescript error too
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { test: true });
    }
    return child;
  });

  return (
    <div>
      {home && <HomeIcon className="home-icon" onClick={goHome} />}
      {props.children}
    </div>
  );
};

export default Layout;
