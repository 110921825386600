import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  positions: [],
};
const robotPosition = createReducer(initialState, {
  UPDATE_ROBOT_POSITIONS: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      positions: payload,
    };
  },
});

export default robotPosition;
