import * as React from "react";
import fetch from "isomorphic-fetch";
import Client from "shopify-buy";
import axios from "axios"

const client = Client.buildClient(
  {
    domain: process.env.REACT_APP_SHOPIFY_STORE_URL,
    storefrontAccessToken:
      process.env.REACT_APP_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
);

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  client,
  checkout: {
    lineItems: [],
  },
  calculatingPrices : false,
  registerCalulatingPrices : () => {},
  useCustomTotal: false,
  setUseCustomTotal: () => {},
  customTotal: 0,
  setCustomTotal: () => {},
  generatedDiscount : {
    code : "",
    currentState : {
      amount : 0,
      minimumSpend : 0,
    },
  },
  updateDiscount : () => {},
};

export const StoreContext = React.createContext(defaultValues);

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `shopify_checkout_id`;

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = React.useState(defaultValues.checkout);
  const [loading, setLoading] = React.useState(false);
  const [shouldAddStoreParams, setShouldAddStoreParams] = React.useState(true);
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false);
  const [totalNumberOfCapsules, setTotalNumberOfCapsules] = React.useState(0);
  const [useCustomTotal, setUseCustomTotal] = React.useState(
    defaultValues.useCustomTotal
  );
  const [customTotal, setCustomTotal] = React.useState(
    defaultValues.customTotal
  );
  const [generatedDiscount, setGeneratedDiscount] = React.useState(defaultValues.generatedDiscount);
  const [calculatingPrices, setCalulatingPrices] = React.useState(defaultValues.calculatingPrices)

  const setCheckoutItem = (checkout) => {
    if (checkout) {
      console.log("updating existing checkout");
      const items =
        checkout.lineItems
          ?.filter((item) => item.variant == null)
          .map((item) => item.id) || [];
      client.checkout.removeLineItems(checkout.id, items).then((res) => {
        setCheckout(res);
      });
    } else {
      console.log("setting checkout");
      setCheckout(checkout);
    }
  };

  React.useEffect(() => {
    console.log("initialising StoreContext Checkout");
    const initializeCheckout = async () => {
      const newCheckout = await client.checkout.create();
      setCheckoutItem(newCheckout);
    };

    initializeCheckout();
  }, []);

  React.useEffect(() => {
    if (checkout?.lineItems) {
      console.log("checkout?.lineItems", checkout?.lineItems);
      const numberOfCapsules = checkout.lineItems.reduce((total, item) => {
        const totalNumberCapsules = Number(
          item.customAttributes.find((a) => a.key === "numberOfCapsules")
            ?.value || 0
        );
        return total + totalNumberCapsules * item.quantity;
      }, 0);

      setTotalNumberOfCapsules(numberOfCapsules);
    }
  }, [checkout?.lineItems]);

  const createNewCheckout = async () => {
    const newCheckout = await client.checkout.create();
    setCheckoutItem(newCheckout);
  };

  const addVariantToCart = (variantId, quantity, variant) => {
    setLoading(true);
    const checkoutID = checkout.id;
    const totalNumberOfCapsules = variant?.numberOfCapsules || 0;
    const customAttributes = [
      { key: "numberOfCapsules", value: totalNumberOfCapsules.toString() },
    ];
    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
        customAttributes,
      },
    ];

    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      });
  };

  const fetchProductRecommedations = (productId) => {
    return client.product
      .fetchRecommendations(productId)
      .then((products) => {})
      .catch((err) => console.log(err));
  };

  const addMultipleVariantsToCart = (lineItemsToUpdate) => {
    setLoading(true);
    console.log(checkout.id);
    console.log(lineItemsToUpdate);
    const checkoutID = checkout.id;
    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        console.log("res", res);
        setCheckout(res);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const addDiscountToCart = async (discountCode) => {
    setLoading(true);
    const checkoutID = checkout.id;

    const res = await client.checkout.addDiscount(checkoutID, discountCode);
    if (res.discountApplications?.length > 0) {
      const foundDiscountCode = res.discountApplications.find(
        (d) => d.code.toLowerCase() === discountCode.toLowerCase().trim()
      );
      if (foundDiscountCode && foundDiscountCode.applicable) {
        setCheckout(res);
        setLoading(false);
        return "";
      } else {
        await removeDiscountFromCart();
        return "This discount code is not applicable";
      }
    } else {
      if (res.userErrors?.length) {
        return res.userErrors[0]?.message;
      }
      return "Discount Code is not applicable for some products in your cart";
    }
  };

  const updateDiscount = async (amount, minimumSpend, customerName) => {
    console.log(generatedDiscount)
    if (amount === generatedDiscount.currentState.amount && minimumSpend === generatedDiscount.currentState.minimumSpend){
      console.log("Don't need to generateDiscount")
      return 
    }
      const uniqueCode = `${customerName.substring(0, 4)}${Math.floor(Math.random() * 100000)}`;
      await axios.post("AdminPanel/CreateDiscountCode/", {
          Title: `Custom code for ${customerName}: ${uniqueCode}`,
          Amount: amount,
          Type: "fixed_amount",
          MinimumSpend : minimumSpend,
          UniqueCode: uniqueCode,
          ExpiresInDays: 14,
    }).then((res) => {
      console.log("Generated discount")
      setGeneratedDiscount({
        code : res.data,
        currentState : {
          amount : amount,
          minimumSpend : minimumSpend
        }
      })
    }).catch((err) => {
      console.error(err)
    })
  }

  var busyLineItems = []
  const registerCalculatingPrices = (lineItemId, finished) => {
    if (finished) {
      busyLineItems = busyLineItems.filter((id) => id !== lineItemId)
      if (busyLineItems.length === 0) {
        setCalulatingPrices(false)
      }
    } else {
      setCalulatingPrices(true)
      if (!busyLineItems.includes(lineItemId)) {
        busyLineItems.push(lineItemId)
      } 
    }
  }

  const removeDiscountFromCart = () => {
    setLoading(true);
    const checkoutID = checkout.id;
    const lineItemsId = checkout.lineItems.filter((item) =>
      item.customAttributes?.find(
        (c) => c.key === "readOnly" && c.value === "true"
      )
    );
    // if(lineItemsId && lineItemsId.length){
    //   console.log("id", lineItemsId.map(item => item.id))
    //   client.checkout
    //   .removeLineItems(checkoutID, lineItemsId.map(item => item.id))
    // }
    return client.checkout.removeDiscount(checkoutID).then((res) => {
      if (lineItemsId && lineItemsId.length) {
        return client.checkout
          .removeLineItems(
            checkoutID,
            lineItemsId.map((item) => item.id)
          )
          .then((resDelete) => {
            setCheckout(resDelete);
            setLoading(false);
          });
      }
      setCheckout(res);
      setLoading(false);
      setDidJustAddToCart(true);
      setTimeout(() => setDidJustAddToCart(false), 3000);
    });
  };

  const removeLineItem = async (checkoutID2, lineItemID) => {
    setLoading(true);
    const checkoutID = checkout?.id;
    return client.checkout
      .removeLineItems(checkoutID, [lineItemID])
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      })
      .catch(async (err) => {
        setLoading(false);
        if (checkoutID) {
          if (checkout?.lineItems.length === 1) {
            const newCheckout = await client.checkout.create();
            setCheckoutItem(newCheckout);
          }
        }
      });
  };

  const updateLineItem = (checkoutID, lineItemID, quantity, item) => {
    setLoading(true);
    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ];

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res);
        if (quantity === 0) {
          removeLineItem(checkoutID, lineItemID);
        }
        console.log(`Set ${lineItemID} to ${quantity}`)
        setLoading(false);
      })
  };

  const clearCheckout = async () => {
    const checkoutID = checkout?.id;
    return client.checkout
      .removeLineItems(
        checkoutID,
        checkout.lineItems.map((item) => item.id)
      )
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      })
      .catch(async (err) => {
        setLoading(false);
        const newCheckout = await client.checkout.create();
        setCheckoutItem(newCheckout);
      });
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        addMultipleVariantsToCart,
        removeLineItem,
        updateLineItem,
        addDiscountToCart,
        removeDiscountFromCart,
        createNewCheckout,
        clearCheckout,
        fetchProductRecommedations,
        checkout,
        loading,
        didJustAddToCart,
        totalNumberOfCapsules,
        setShouldAddStoreParams,
        shouldAddStoreParams,
        calculatingPrices,
        registerCalculatingPrices,
        useCustomTotal,
        setUseCustomTotal,
        customTotal,
        setCustomTotal,
        updateDiscount,
        generatedDiscount,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
