import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  ordersUpdating: [],
  updateComplete: [],
  ordersForInvestigation: [],
};

const system = createReducer(initialState, {
  GET_ORDERS: (state, action) => {
    var currentOrdersUpdating = [...state.ordersUpdating];
    var currentUpdateComplete = [...state.updateComplete];
    var updateCompleteToRemove = [];

    var newOrdersUpdating = currentOrdersUpdating.filter((id) => {
      console.log(id);
      if (currentUpdateComplete.includes(id)) {
        updateCompleteToRemove.push(id);
        return false;
      }
      return true;
    });

    return {
      ...state,
      orders: action.payload,
      ordersUpdating: newOrdersUpdating,
      updateComplete: [...state.updateComplete].filter(
        (o) => !updateCompleteToRemove.includes(o.id)
      ),
    };
  },
  GET_DELAYED_ORDERS: (state, action) => {
    return {
      ...state,
      delayedOrders: action.payload,
    };
  },
  UPDATE_ORDERS_UPDATING: (state, action) => {
    var newArr = [...state.ordersUpdating];
    newArr.push(action.payload);
    return {
      ...state,
      ordersUpdating: newArr,
    };
  },
  REMOVE_UPDATING_ORDER: (state, action) => {
    var newUpdateComplete = [...state.updateComplete];
    newUpdateComplete.push(action.payload);
    var currentOrdersUpdating = [...state.ordersUpdating];
    currentOrdersUpdating = currentOrdersUpdating.filter(
      (id) => !newUpdateComplete.includes(id)
    );

    return {
      ...state,
      // updateComplete: newUpdateComplete,
      ordersUpdating: currentOrdersUpdating,
    };
  },
  GET_ORDERS_FOR_INVESTIGATION: (state, action) => {
    return {
      ...state,
      ordersForInvestigation: action.payload,
    };
  },
  SET_ASSIGNEE: (state, action) => {
    return {
      ...state,
      assignee: action.payload,
    };
  },
});

export default system;
