import React, { useEffect, useState } from "react";
import AdminLayout from "../AdminLayout/AdminLayout";
import Snacky from "../../Shared/Snacky";
import "./errors.scss";
import { Skeleton } from "@material-ui/lab";
import axios from "axios";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";
import { setSnackBarProps } from "../../../reduxActions/snackbar";
import WebsiteErrorsTable from "./WebsiteErrorsTable";
const Errors = ({ snackbar, setSnackBarProps }) => {
  const [isLoadingWebsiteErrors, setIsLoadingWebsiteErrors] = useState(false);
  const [websiteErrors, setWebsiteErrors] = useState([]);

  useEffect(() => {
    getWebsiteErrors();
  }, []);

  const getWebsiteErrors = () => {
    setIsLoadingWebsiteErrors(true);
    axios
      .get(`Errors/GetWebsiteErrors`)
      .then(({ data }) => {
        setWebsiteErrors(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoadingWebsiteErrors(false));
  };

  const removeWebsiteErrorFromList = (websiteErrorId) => {
    setWebsiteErrors(websiteErrors.filter((w) => w.id !== websiteErrorId));
  };

  return (
    <AdminLayout>
      <Snacky snackprops={snackbar} setSnackBarProps={setSnackBarProps} />
      <div className="errors-container-root">
        {isLoadingWebsiteErrors ? (
          <div className="errors-loading-skeleton-container">
            <Skeleton height={50} />
            <Skeleton height={50} />
            <Skeleton height={50} />
          </div>
        ) : (
          <div>
            {websiteErrors.length > 0 ? (
              <WebsiteErrorsTable
                websiteErrors={websiteErrors}
                removeWebsiteErrorFromList={removeWebsiteErrorFromList}
              />
            ) : (
              <div
                className="producs-paper-container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: 30,
                }}
              >
                <Typography>
                  <strong>Yay! No website errors found.</strong>
                </Typography>
              </div>
            )}
          </div>
        )}
      </div>
    </AdminLayout>
  );
};
const mapStateToProps = (state) => ({
  snackbar: state.snackbar,
});

export default connect(mapStateToProps, {
  setSnackBarProps,
})(Errors);
