import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  withStyles,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import ReplyIcon from "@material-ui/icons/Reply";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState } from "react";
import EmailMessageCard from "./EmailMessageCard";
import ReplyEmailMessageCard from "./ReplyEmailMessageCard";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const EmailMessageDialog = (props) => {
  return (
    <div>
      <Dialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth="md"
        PaperProps={{ backgroundColor: "#f0f0f0" }}
      >
        <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
          {props.emailMessage?.subject}
        </DialogTitle>
        <DialogContent dividers>
          {props.isSendingEmail ? (
            <CircularProgress />
          ) : (
            <React.Fragment>
              <EmailMessageCard
                emailMessage={props.emailMessage}
                handleReplyButton={props.handleReplyButton}
                isReplyCardOpen={props.openReplyEmailCard}
              />
              <ReplyEmailMessageCard
                emailMessage={props.emailMessage}
                open={props.openReplyEmailCard}
                handleDiscardReply={props.handleDiscardReply}
                handleSendReplyMessage={props.handleSendReplyMessage}
                isReplyAll={props.isReplyAll}
              />
            </React.Fragment>
          )}
        </DialogContent>
        <DialogActions>
          {!props.openReplyEmailCard && (
            <React.Fragment>
              <Button
                autoFocus
                onClick={(e) => props.handleReplyButton(false)}
                color="primary"
                startIcon={<ReplyIcon />}
              >
                Reply
              </Button>
              <Button
                autoFocus
                onClick={(e) => props.handleReplyButton(true)}
                color="primary"
                startIcon={<ReplyAllIcon />}
              >
                Reply All
              </Button>
            </React.Fragment>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EmailMessageDialog;
