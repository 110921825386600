import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
    Modal,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Grid,
    CircularProgress,
    Button,
} from "@material-ui/core";
import {
    Close
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import LogsPanel from "../../Delays/DeayedOrderComponents/LogsPanel";
import ImagePanel from "../../Delays/DeayedOrderComponents/ImagePanel";
import { RobotUIDetails, ShopifyDetails } from "../../Delays/DeayedOrderComponents/DelayedOrderComponents";
import SetStatusButton from "./SetStatusButton";
import LinksButton from "./LinksButton";
import CreateClaimDocumentsConfirmation from "./CreateClaimsDocumentDialog";
import RaiseTicketButton from "./RaiseTicketComponents";
import {
    getDelayedOrders
} from "../../../reduxActions/order";

const useStyles = makeStyles((theme) => ({
    paper: {
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 3),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

const ClaimsPopup = (props) => {
    const {
        user,
        modalState,
        handleClose,
        selectedClaim,
        refreshClaims,
        setSnackBarProps,
        getDelayedOrders
    } = props;

    const classes = useStyles();

    const [shopifyOrder, setShopifyOrder] = useState(null)
    const [storeOrder, setStoreOrder] = useState(null)
    const [orderEvents, setOrderEvents] = useState(null)
    const [openClaimConfirmation, setOpenClaimConfirmation] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        axios.get(`ControlCenter/GetControlCenterOrder/${selectedClaim.orderId}/${selectedClaim.trackingNumber}`)
            .then(res => {
                setShopifyOrder(res.data.shopifyOrder);
                setStoreOrder(res.data.order);
                setOrderEvents(res.data.orderEvents)
            })
            .catch(e => {
                // console.log(e)
            })
    }, [])

    return (
        <Modal
            open={modalState}
            disableBackdropClick
            onClose={() => {
                handleClose();
            }}
            className={"claims-order-modal"}
        >
            <div>
                {openClaimConfirmation && (
                    <CreateClaimDocumentsConfirmation
                        orderId={selectedClaim.orderId}
                        trackingNumber={selectedClaim.trackingNumber}
                        setOrderEvents={setOrderEvents}
                        openClaimConfirmation={openClaimConfirmation}
                        setOpenClaimConfirmation={setOpenClaimConfirmation}
                        getDelayedOrders={getDelayedOrders}
                    />
                )}
                <AppBar position="static" style={{ background: "#27293d" }}>
                    <Toolbar variant="dense" style={{ paddingRight: 0 }}>
                        <Typography
                            style={{
                                fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                                width: "100%",
                                textAlign: "center",
                            }}
                            variant={"subtitle1"}
                            component={"span"}
                        >
                            {storeOrder && (
                                <span>{`SC${storeOrder.orderNumber}`}</span>
                            )}
                        </Typography>
                        <IconButton
                            onClick={() => {
                                handleClose();
                            }}
                            style={{
                                color: "white",
                                display: "block",
                                margin: "auto 0px auto auto",
                            }}
                        >
                            <Close />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className={`${classes.paper} edit-claims-order-paper`}>
                    {storeOrder && shopifyOrder ? (
                        <Grid container style={{ height: "100%", margin: "0px", padding: "0px" }}>
                            <Grid item xs={6} md={6} lg={6} className="full-grid-length">
                                <LogsPanel
                                    shopifyOrder={shopifyOrder}
                                    storeOrder={storeOrder}
                                    orderEvents={orderEvents}
                                    setOrderEvents={setOrderEvents}
                                    trackingNumber={selectedClaim.trackingNumber}
                                    user={user}
                                />
                            </Grid>
                            <Grid item xs={6} md={6} lg={6} className="full-grid-length">
                                <Grid container style={{ height: "100%" }}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Grid container className="image-panel-grid" spacing={1}>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <ImagePanel
                                                    orderId={selectedClaim.orderId}
                                                    trackingNumber={selectedClaim.trackingNumber}
                                                    type={"image"}
                                                    imageType={"note"}
                                                    fromDisputed={false}
                                                    fromClaims={true}
                                                />
                                            </Grid><Grid item xs={12} md={6} lg={6}>
                                                <ImagePanel
                                                    orderId={selectedClaim.orderId}
                                                    trackingNumber={selectedClaim.trackingNumber}
                                                    type={"image"}
                                                    imageType={"barcode"}
                                                    fromDisputed={false}
                                                    fromClaims={true}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <ImagePanel
                                                    orderId={selectedClaim.orderId}
                                                    trackingNumber={selectedClaim.trackingNumber}
                                                    type={"image"}
                                                    imageType={"label"}
                                                    fromDisputed={false}
                                                    fromClaims={true}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <ImagePanel
                                                    orderId={selectedClaim.orderId}
                                                    trackingNumber={selectedClaim.trackingNumber}
                                                    type={"image"}
                                                    imageType={"pdf"}
                                                    fromDisputed={false}
                                                    fromClaims={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Grid container style={{ height: "100%" }} spacing={1}>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <RobotUIDetails storeOrder={storeOrder} />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                <ShopifyDetails shopifyOrder={shopifyOrder} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} className="sticky">
                                <Grid container>
                                    <Grid item xs={5} md={5} lg={5}>
                                        <RaiseTicketButton />
                                    </Grid>
                                    <Grid item xs={4} md={4} lg={4}>
                                        <div className="center">
                                            {storeOrder.aramexClaimForm.length === 0 && storeOrder.nzPostClaimForm.length === 0 ? (
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    size="small"
                                                    onClick={() => {
                                                        setOpenClaimConfirmation(true);
                                                    }}
                                                    style={{ width: "500px" }}
                                                >
                                                    Create claim documents
                                                </Button>
                                            ) : (
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    size="small"
                                                    style={{
                                                        marginLeft: "5px",
                                                    }}
                                                    disabled={loading}
                                                    onClick={() => {
                                                        setLoading(true);
                                                        var forms = storeOrder.aramexClaimForm.length > 0 ? (storeOrder.aramexClaimForm) : (storeOrder.nzPostClaimForm.length > 0 ? storeOrder.nzPostClaimForm : null)
                                                        var ids = [];
                                                        var fileNames = [];

                                                        if (forms !== null) {
                                                            if (forms[0].driveId) {
                                                                ids.push(forms[0].driveId);
                                                                fileNames.push(`${storeOrder?.orderNumber} Compensation Form`);
                                                            }
                                                            if (forms[0].statementOfRecordDriveId) {
                                                                ids.push(forms[0].statementOfRecordDriveId);
                                                                fileNames.push(`${storeOrder?.orderNumber} Statement Of Record`);
                                                            }
                                                            if (forms[0].invoicePdfDriveId) {
                                                                ids.push(forms[0].invoicePdfDriveId);
                                                                fileNames.push(`${storeOrder?.orderNumber} Invoice`);

                                                            }
                                                            if (forms[0].proofOfPurchaseDriveId) {
                                                                fileNames.push(`${storeOrder?.orderNumber} Proof of Purchase`);
                                                                ids.push(forms[0].proofOfPurchaseDriveId);
                                                            }
                                                            ids.push("12Mw-u52KyYDwDXGluZwvKK3C7BR8pnCo");
                                                            fileNames.push(`Proof of Stock Purchase`);
                                                        }
                                                        var count = 0;
                                                        for (var i = 0; i < ids.length; i++) {
                                                            axios.get(`ControlCenter/DownloadDriveFile/${ids[i]}/${fileNames[i]}`, {
                                                                params: {
                                                                    cacheBustTimestamp: Date.now(), // prevents IE cache problems on re-download
                                                                },
                                                                responseType: "blob",
                                                                headers: {
                                                                    Accept: "application/octet-stream"
                                                                },
                                                            }).then((r) => {
                                                                var fileName = r.headers["content-disposition"];
                                                                if(fileName.includes("attachment; filename=\"")){
                                                                    fileName = fileName.replace("attachment; filename=\"", "");
                                                                    fileName = fileName.split("\";")[0];
                                                                } else {
                                                                    fileName = fileName.replace("attachment; filename=", "");
                                                                    fileName = fileName.split(";")[0];
                                                                }

                                                                const blob = new Blob([r.data], { type: "application/pdf" });

                                                                const url = window.URL.createObjectURL(blob);
                                                                const a = document.createElement("a");
                                                                a.style.display = "none";
                                                                a.href = url;
                                                                //
                                                                a.setAttribute("download", `${fileName}.pdf`);
                                                                document.body.appendChild(a);
                                                                a.click();
                                                                window.URL.revokeObjectURL(url);
                                                            }).catch(e => {
                                                                console.log(e);
                                                            }).finally(() => {
                                                                count++;
                                                                if (i === count) {
                                                                    setLoading(false);
                                                                }
                                                            })
                                                        }
                                                    }}
                                                >
                                                    {loading ? <CircularProgress style={{ margin: 5 }} /> : `Download claim documents`}
                                                </Button>
                                            )}

                                        </div>
                                    </Grid>
                                    <Grid item xs={1} md={1} lg={1}>
                                        <div className="center">
                                            <LinksButton
                                                courier={selectedClaim.courier}
                                                orderId={selectedClaim.orderId}
                                                trackingNumber={selectedClaim.trackingNumber}
                                                orderNumber={selectedClaim.orderNumber}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={2} md={2} lg={2}>
                                        <div className="center">
                                            <SetStatusButton
                                                claimId={selectedClaim.id}
                                                currentStatus={selectedClaim.status}
                                                refreshClaims={refreshClaims}
                                                setSnackBarProps={setSnackBarProps}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : <CircularProgress />}
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    snackbar: state.snackbar,
});

export default connect(mapStateToProps, {
    getDelayedOrders
})(ClaimsPopup);