export const ConvertNZPostAddress = (fullAddress) => {
  let addressArr = fullAddress.split(",");
  let address1,
    address2,
    city,
    zip,
    country = null;
  address1 = addressArr[0].trim();
  if (addressArr.length === 3) {
    address2 = addressArr[1].trim();
    let postcodeCountryArr = addressArr[2].split(" ");
    zip = postcodeCountryArr[postcodeCountryArr.length - 1].trim();

    for (let i = 0; i < postcodeCountryArr.length - 1; i++) {
      if (postcodeCountryArr[i] !== "") {
        if (city == null) {
          city = postcodeCountryArr[i].trim();
        } else {
          city += ` ${postcodeCountryArr[i].trim()}`;
        }
      }
    }
  } else if (addressArr.length === 2) {
    let postcodeCountryArr = addressArr[1].split(" ");
    zip = postcodeCountryArr[postcodeCountryArr.length - 1].trim();

    for (let i = 0; i < postcodeCountryArr.length - 1; i++) {
      if (city == null) {
        address2 = postcodeCountryArr[i].trim();
      } else {
        address2 += ` ${postcodeCountryArr[i].trim()}`;
      }
      if (city == null) {
        city = postcodeCountryArr[i].trim();
      } else {
        city += ` ${postcodeCountryArr[i].trim()}`;
      }
    }
  } else if (addressArr.length === 4 && addressArr[0].includes("Level")) {
    let postcodeCityArr = addressArr[3].split(" ");
    address1 = `${addressArr[0]}, ${addressArr[1].trim()}`;
    address2 = addressArr[2].trim();
    zip = postcodeCityArr[postcodeCityArr.length - 1].trim();

    //starts at 1 so it does not include "" and ends at -1 so postcode is not included
    for (let i = 1; i < postcodeCityArr.length - 1; i++) {
      if (city == null) {
        if (city === undefined || city === null) {
          city = postcodeCityArr[i].trim();
        } else {
          city += ` ${postcodeCityArr[i].trim()}`;
        }
      } else {
        city += ` ${postcodeCityArr[i].trim()}`;
      }
    }
  }
  country = "New Zealand";
  return {
    address1: address1,
    address2: address2,
    city: city,
    zip: zip,
    country: country,
  };
};
