import {
  Backdrop,
  Button,
  CircularProgress,
  Snackbar,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useCustomer } from "../../../context/customer/CustomerContext";
import AdminLayout from "../AdminLayout/AdminLayout";
import "./createOrder.scss";
import Axios from "axios";
import ProductTabs from "./ProductTabs";
import { useHistory } from "react-router-dom";
import {
  calculateShipping,
  checkSkuForCapsulesTotal,
  convertCheckoutLineItemsForStripe,
  getShippingCost,
} from "../../Utils/adminPanelUtils";
import axios from "axios";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StoreContext } from "../../../context/store/StoreContext";
import { useContext } from "react";
import {
  calculateDiscountTotal,
  calculateTotalCheckout,
  convertCheckoutLineItems,
} from "../../../repository/shopify";
import CustomerDetailsCard from "../CustomerDetailsCard/CustomerDetailsCard";
import CustomerPaymentCard from "../CustomerPaymentCard/CustomerPaymentCard";
import CustomerAddressCard from "../CustomerAddress/CustomerAddressCard";
import OrderSummary from "../OrderSummary/OrderSummary";
import AddNewCardModal from "../AddNewCardModal/AddNewCardModal";
import OrderConfirmationModal from "../OrderConfirmationModal/OrderConfirmationModal";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import OrderLinkConfirmationModal from "../OrderLinkConfirmationModal/OrderLinkConfirmationModal";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const CreateOrder = () => {
  const classes = useStyles();
  const history = useHistory();
  const elements = useElements();
  const stripe = useStripe();
  const {
    checkout,
    addMultipleVariantsToCart,
    totalNumberOfCapsules,
    useCustomTotal,
    customTotal,
  } = useContext(StoreContext);
  const {
    customer,
    paymentMethods,
    fetchCustomerDetailsById,
    firestoreCustomer,
  } = useCustomer();
  const [customerId, setCustomerId] = useState(null);
  const [reorderId, setReorderId] = useState(null);
  const [manualDiscountCode, setManualDiscountCode] = useState("");
  const [shippingAddress, setShippingAddress] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    postCode: "",
    country: "",
  });
  const [cart, setCart] = useState({
    subtotal: 0,
  });

  const [customerTags, setCustomerTags] = useState([]);
  const [discountCode, setDiscountCode] = useState(null);
  const [shippingCost, setShippingCost] = useState(null);

  const [shippingRates, setShippingRates] = useState([]);
  const [loading, setLoading] = useState(false);

  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [stripeCustomerId, setStripeCustomerId] = useState(null);
  const [paymentIntentSecret, setPaymentIntentSecret] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [cardholderName, setCardholderName] = useState("");
  const [openNewCardModal, setOpenNewCardModal] = useState(false);
  const [showOrderConfirmation, setShowOrderConfirmation] = useState(false);
  const [showOrderLinkConfirmation, setShowOrderLinkConfirmation] =
    useState(false);
  const [messageDisplay, setMessageDisplay] = useState(null);

  useEffect(() => {
    if (shippingRates.length === 0) {
      axios
        .get(`AdminPanel/GetShippingRates`)
        .then((res) => {
          setShippingRates(res.data);
        })
        .catch(() => {});
    }
  }, []);

  useEffect(() => {
    if (stripe && checkout?.lineItems?.length > 0) {
      createNewPaymentIntent();
    }
  }, [stripe, checkout?.lineItems, shippingCost, customTotal]);

  useEffect(() => {
    if (checkout?.totalPriceV2?.amount && paymentIntentSecret) {
      createNewPaymentIntent();
    }
  }, [checkout?.totalPriceV2?.amount, shippingCost, customTotal]);

  useEffect(() => {
    if (paymentMethods?.length) {
      setSelectedPaymentMethod(paymentMethods[0]);
    }
  }, [paymentMethods]);

  useEffect(() => {
    var path = history.location.pathname;
    var pathArr = path.split("/");
    setCustomerId(pathArr[3]);
    if (pathArr.length === 5) {
      setReorderId(pathArr[4]);
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (customerId && !customer) {
      fetchCustomerDetailsById(customerId);
    }
  }, [customer, customerId]);

  useEffect(() => {
    if (customerId !== null) {
      var url = `AdminPanel/GetCustomerForCreateOrder/${customerId}`;
      Axios.get(url)
        .then((res) => {
          var tags = res.data.tags.split(",");
          setCustomerTags(tags);
          var newDiscountCode = null;
          for (var i = 0; i < tags.length; i++) {
            switch (tags[i]) {
              case "Platinum":
                newDiscountCode = {
                  amount: 12.5,
                  code: "Platinum",
                  type: "percentage",
                };
                break;
              case "Gold":
                newDiscountCode = {
                  amount: 10,
                  code: "Gold",
                  type: "percentage",
                };
                break;

              case "Silver":
                newDiscountCode = {
                  amount: 7,
                  code: "Silver",
                  type: "percentage",
                };
                break;
              default:
              case "Bronze":
                newDiscountCode = {
                  amount: 5,
                  code: "Bronze",
                  type: "percentage",
                };
                break;
            }
            if (newDiscountCode !== null) {
              setDiscountCode(newDiscountCode);

              break;
            }
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {});
    }
  }, [customerId]);

  useEffect(() => {
    if (shippingAddress?.postCode?.length === 4 && shippingRates) {
      calculateShippingCost();
    }
  }, [shippingAddress?.ruralDelivery, totalNumberOfCapsules, shippingRates]);

  useEffect(() => {
    if (reorderId) {
      var url = `AdminPanel/GetOrderItems/${reorderId}`;
      Axios.get(url)
        .then((res) => {
          var newCart = {
            ...cart,
          };

          var items = res.data;

          loadCartFromPreviousOrder(items);
          for (var i = 0; i < items.length; i++) {
            newCart[`${items[i].product.sku}`] = items[i];
          }
          var totalNumberOfCapsules = 0;
          var newSubtotal = 0;
          for (const property in newCart) {
            if (property !== "subtotal") {
              newSubtotal += newCart[property].totalPrice;
              totalNumberOfCapsules += checkSkuForCapsulesTotal(
                property,
                cart[property].quantity
              );
            }
          }
          var newShippingCost = getShippingCost(
            shippingRates,
            totalNumberOfCapsules,
            shippingAddress.postCode
          );
          setShippingCost(newShippingCost);
          newCart["subtotal"] = newSubtotal;
          setCart(newCart);
        })
        .catch((e) => {});
    }
  }, [reorderId]);

  const calculateShippingCost = async () => {
    console.log("totalNumberOfCapsules", totalNumberOfCapsules);
    console.log("shippingRates", shippingRates);
    const shippingRate = await calculateShipping(
      shippingAddress,
      totalNumberOfCapsules,
      shippingRates
    );
    setShippingCost(shippingRate);
  };

  const createNewPaymentIntent = async () => {
    const payload = {
      id: paymentIntentId,
      amount: useCustomTotal
        ? Math.floor(customTotal * 100)
        : Math.floor(calculateTotalCheckout(checkout, shippingCost) * 100),
      currency: "nzd",
      payment_method_types: ["card"],
    };

    if (payload.amount < 50) {
      payload.amount = 51;
    }

    if (customer && customer.email) {
      payload.metadata = {
        email: customer.email,
        first_name: customer.firstName,
        last_name: customer.lastName,
        address_line1: shippingAddress?.address1 || "",
        address_line2: shippingAddress?.address2 || "",
        city: shippingAddress?.city || "",
        post_code: shippingAddress?.zip || "",
        note: shippingAddress?.note || "",
        phone_number: shippingAddress?.phoneNumber || "",
        company_name: shippingAddress?.companyName || "",
        items: await convertCheckoutLineItemsForStripe(checkout),
      };
    } else {
      alert("Please select a customer");
    }

    console.log("customer", customer);
    if (customer && customer.stripeId) {
      payload.customer = customer.stripeId;
      if (stripeCustomerId) {
        setStripeCustomerId(null);
        payload.id = null; // unsetting as PI doesn't allow customer update
      }
    } else if (!stripeCustomerId && customer.email) {
      const newStripeCustomer = null; //await createCustomerStripe({firstName: "", lastName: "", email: email})
      if (newStripeCustomer) {
        setStripeCustomerId(newStripeCustomer.id);
        payload.customer = newStripeCustomer.id;
      }
    }

    axios
      .post("Stripe/CreateUpdatePaymentIntent", payload)
      .then(async ({ data }) => {
        const { client_secret, id } = data;
        await setPaymentIntentId(id);
        await setPaymentIntentSecret(client_secret);
      })
      .catch((err) => {
        console.error("error to initiate payment ", err);
      });
  };

  const createOrder = async () => {
    setLoading(true);
    const lineItems = await convertCheckoutLineItems(checkout);
    const amount =
      Math.floor(
        (calculateTotalCheckout(checkout, shippingCost) - customTotal) * 100
      ) / 100;
    const appliedDiscount = useCustomTotal
      ? {
          title: "Custom Discount",
          value: Number(amount),
          valueType: "fixed_amount",
          amount: Number(amount),
        }
      : calculateDiscountTotal(
          checkout.discountApplications,
          checkout?.lineItemsSubtotalPrice?.amount
        );
    const total = useCustomTotal
      ? customTotal
      : calculateTotalCheckout(checkout, shippingCost);

    Axios.post(`AdminPanel/ProcessAdminOrder/${customerId}`, {
      firstName: customer.firstName,
      lastName: customer.lastName,
      lineItems: lineItems,
      shippingAddress: {
        addressLine1: shippingAddress.addressLine1,
        addressLine2: shippingAddress.addressLine2,
        city: shippingAddress.city,
        postCode: shippingAddress.postCode,
        firstName: customer.firstName,
        lastName: customer.lastName,
        ruralDelivery: shippingAddress.ruralDelivery || "",
      },
      paymentMethod: "card",
      paymentIntentId: paymentIntentId,
      paymentMethodId: selectedPaymentMethod ? selectedPaymentMethod.id : "",
      total: total,
      appliedDiscount: appliedDiscount,
      shippingCost: shippingCost,
    })
      .then((res) => {
        if (res.data) {
          setMessageDisplay({
            hasError: false,
            message: `Order has been created and will display in Shopify soon.`,
          });
          setLoading(false);
          setTimeout(() => {
            history.push("/AdminPanel/Customers");
          }, 2000);
        }
      })
      .catch((e) => {
        setMessageDisplay({
          hasError: true,
          message:
            "Error while creating order, CC has been charged. Please manually create order in Shopify",
        });
        console.log(e);
        setLoading(false);
      });
  };

  const loadCartFromPreviousOrder = async (items) => {
    if (checkout?.id && items && items.length) {
      const lineItemsToBeAdded = await items.map((item) => {
        let variantId = item.product?.variantId || "";
        const totalNumberOfCapsules = checkSkuForCapsulesTotal(
          item?.product.sku,
          parseInt(1, 10)
        );
        const customAttributes = [
          { key: "numberOfCapsules", value: totalNumberOfCapsules.toString() },
        ];
        return {
          variantId: variantId,
          quantity: parseInt(item.quantity, 10),
          customAttributes,
        };
      });
      addMultipleVariantsToCart(
        lineItemsToBeAdded.filter((v) => v.variantId !== undefined)
      );
    }
  };

  const confirmCreditCardPayment = async () => {
    setLoading(true);
    let stripePayload = {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: cardholderName,
          address: {
            city: shippingAddress?.city,
            country: "NZ",
            line1: shippingAddress?.addressLine1,
            line2: shippingAddress?.addressLine2,
            postal_code: shippingAddress?.postCode,
          },
        },
      },
    };

    if (selectedPaymentMethod) {
      stripePayload.payment_method = selectedPaymentMethod.id;
    } else {
      stripePayload.setup_future_usage = "off_session";
    }

    const paymentResult = await stripe.confirmCardPayment(
      paymentIntentSecret,
      stripePayload
    );

    if (paymentResult.error) {
      setMessageDisplay({
        hasError: true,
        message: `${paymentResult.error.message}`,
      });
      setLoading(false);
    } else {
      if (paymentResult.paymentIntent.status === "succeeded") {
        setOpenPaymentModal(false);
        createOrder();
      } else {
        setMessageDisplay({
          hasError: true,
          message: "Unable to process payment",
        });
        alert();
        setLoading(false);
      }
    }
  };

  const confirmOrderLinkSent = (hasSent) => {
    if (hasSent) {
      setMessageDisplay({ hasError: false, message: `Email has been sent` });
    } else {
      setMessageDisplay({ hasError: true, message: `Something went wrong` });
    }
  };

  return (
    <AdminLayout>
      {showOrderConfirmation && (
        <OrderConfirmationModal
          open={showOrderConfirmation}
          handleClose={() => setShowOrderConfirmation(false)}
          firestoreCustomer={firestoreCustomer}
          selectedPayment={selectedPaymentMethod}
          shippingAddress={shippingAddress}
          handleMakePayment={confirmCreditCardPayment}
          cardholderName={cardholderName}
          setCardholderName={setCardholderName}
          shippingCost={shippingCost}
          discountCodeObj={discountCode}
          discountCode={manualDiscountCode}
        />
      )}

      {showOrderLinkConfirmation && (
        <OrderLinkConfirmationModal
          open={showOrderLinkConfirmation}
          handleClose={() => setShowOrderLinkConfirmation(false)}
          firestoreCustomer={firestoreCustomer}
          shippingAddress={shippingAddress}
          confirmOrderLinkSent={confirmOrderLinkSent}
          shippingCost={shippingCost}
          discountCodeObj={discountCode}
          discountCode={manualDiscountCode}
        />
      )}

      <div className="create-order-layout">
        <div>
          <Typography variant="h5">
            Create new order for {firestoreCustomer?.firstName}
          </Typography>
          <ProductTabs />
        </div>
        <div className="customer-details-cards">
          <CustomerDetailsCard
            customer={firestoreCustomer}
            discountCode={discountCode}
          />
          <CustomerAddressCard
            customerId={customer?.id}
            setShippingAddress={setShippingAddress}
            isCheckout
          />
          <CustomerPaymentCard
            setOpenNewCardModal={setOpenNewCardModal}
            setCheckoutPayment={setSelectedPaymentMethod}
            isCheckout
          />
          <OrderSummary
            shippingCost={shippingCost}
            setDiscountCode={setManualDiscountCode}
            discountCodeObj={discountCode}
            discountCode={manualDiscountCode}
            canEditCustomTotal
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => setShowOrderConfirmation(true)}
          >
            Complete Order
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() => setShowOrderLinkConfirmation(true)}
          >
            Send Order Link To Customer
          </Button>
        </div>
      </div>

      {openNewCardModal && (
        <AddNewCardModal
          open={openNewCardModal}
          handleClose={(e) => setOpenNewCardModal(false)}
        />
      )}

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {messageDisplay && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={messageDisplay}
          autoHideDuration={5000}
          onClose={() => setMessageDisplay(null)}
        >
          {messageDisplay.hasError ? (
            <Alert
              onClose={() => setMessageDisplay(null)}
              variant="filled"
              severity="error"
            >
              {messageDisplay.message}
            </Alert>
          ) : (
            <Alert
              onClose={() => setMessageDisplay(null)}
              variant="filled"
              severity="success"
            >
              {messageDisplay.message}
            </Alert>
          )}
        </Snackbar>
      )}
    </AdminLayout>
  );
};

export default CreateOrder;
