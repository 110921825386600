import React, { useState, useEffect } from "react";
import GoogleMapComponent from "./GoogleMapComponent";
import axios from "axios";
import { connect } from "react-redux";
import PostcodeMenu from "./PostcodeMenu";
import ZoneMenu from "./ZoneMenu";
import { Button } from "@material-ui/core";
import "./PostalZone.scss";
import { ContactSupportOutlined } from "@material-ui/icons";

const PostalZones = (props) => {
    const {
        delayedOrders
    } = props;

    const filterStatus = ["NotSent", "Delivered", "Failed"];

    const [postcodeList, setPostcodeList] = useState([]);
    const [getPostCodeList, setGetPostcodeList] = useState(true)

    const [deliveryZones, setDeliveryZones] = useState([]);
    const [getDeliveryZones, setGetDeliveryZones] = useState(true)

    const [showPostcodeMenu, setShowPostcodeMenu] = useState(false);
    const [selectedPoly, setSelectedPoly] = useState(null);

    const [showZoneMenu, setShowZoneMenu] = useState(false);
    const [mapUpdated, setMapUpdated] = useState(true);

    const [boundaries, setBoundaries] = useState(null);

    const [selectedPolygons, setSelectedPolygons] = useState(null);

    const handlePolygonClick = (poly) => {
        setSelectedPoly(poly);
        setShowPostcodeMenu(true);
    }

    const handleMarkerClick = (delayedOrder) => {
        alert(`SC${delayedOrder.order.orderNumber} clicked!`);
    }

    const updatePostcodeAreaDeliveryZone = (poly, newDeliveryZone) => {
        var newPostcodeList = [...postcodeList];
        //using loop because of multi polygons with the same postcode
        for (var i = 0; i < newPostcodeList.length; i++) {
            if (newPostcodeList[i].metaData.postcode === poly.metaData.postcode) {
                newPostcodeList[i].metaData.deliveryZone = newDeliveryZone;
            }
        }
        setPostcodeList(newPostcodeList);
        setMapUpdated(false);
    }

    useEffect(() => {
        if (getPostCodeList) {
            setGetPostcodeList(false)
            axios.get(`PostcodeAreas/GetPostcodeAreas`)
                .then((res) => {
                    setPostcodeList(res.data)
                }).catch(e => console.log(e))
        }
    }, [getPostCodeList])

    useEffect(() => {
        setGetDeliveryZones(false)
        axios.get(`DeliveryZones/GetDeliveryZones`)
            .then((res) => {
                setDeliveryZones(res.data)
            }).catch(e => console.log(e))
    }, [getDeliveryZones])

    useEffect(() => {
        if (!mapUpdated) {
            setMapUpdated(true);
        }
    }, [mapUpdated]);


    async function pullJson() {
        const response = await fetch('https://storage.googleapis.com/storage/v1/b/robotui/o/Boundaries.json?alt=media');
        const responseJson = await response.json();
        setBoundaries(responseJson);
        setMapUpdated(false);
    }

    useEffect(() => {
        pullJson();
    }, []);

    return (
        <React.Fragment>
            <PostcodeMenu
                modalState={showPostcodeMenu}
                handleModal={() => {
                    setShowPostcodeMenu(false);
                    setSelectedPoly(null);
                }}
                delayedOrdersForArea={delayedOrders?.filter(d =>
                    d.order.shippingAddress.zip === selectedPoly?.metaData?.postcode && d.hoursDelayed > 0)}
                selectedPoly={selectedPoly}
                deliveryZones={deliveryZones}
                updatePostcodeAreaDeliveryZone={updatePostcodeAreaDeliveryZone}
            />
            <ZoneMenu
                modalState={showZoneMenu}
                handleModal={() => {
                    setShowZoneMenu(false);
                }}
                deliveryZones={deliveryZones}
                refreshDeliveryZones={() => {
                    setGetDeliveryZones(true);
                    setGetPostcodeList(true);
                }}
            />
            <div className="zone-button-container">
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    className="zone-button"
                    onClick={() => setShowZoneMenu(true)}
                >
                    Zone Menu
                </Button>
            </div>
            <GoogleMapComponent
                isMarkerShown={true}
                isPostcodeHighlighted={true}
                isSuburbHighlighted={true}
                showTrafficLayer={false}
                defaultZoom={6}
                loadingElement={<div style={{ height: "100vH" }} />}
                containerElement={<div style={{ height: "100vH", width: "100%" }} />}
                mapElement={<div style={{ height: "100vH", width: "100%" }} />}
                mapType={"hybrid"}
                tilt={45}
                defaultCentre={{//Wellington
                    lat: -41.24964877357683,
                    lng: 174.7429197579141
                }}
                polygons={postcodeList}
                delayedOrders={delayedOrders ?? delayedOrders?.filter(d => !filterStatus.includes(d.status) && d.hoursDelayed > 0)}
                onClick={(poly) => handlePolygonClick(poly)}
                handleMarkerClick={(delayedOrder) => handleMarkerClick(delayedOrder)}
                deliveryZones={deliveryZones}
                mapUpdated={mapUpdated}
                boundaries={boundaries}
            ></GoogleMapComponent>
        </React.Fragment>
    )
}

//export default PostalZones;

const mapStateToProps = (state) => ({
    delayedOrders: state.order.delayedOrders,
});

export default connect(mapStateToProps, {})(PostalZones);