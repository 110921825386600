import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import "./stockLevels.scss";
import {
  Table,
  TableContainer,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import UpdateStockPopup from "./UpdateStockPopup";
import Snacky from "../Shared/Snacky";
import { setSnackBarProps } from "../../reduxActions/snackbar";
import { getStockRows } from "../../reduxActions/stock";

const StockRows = (props) => {
  const {
    snackbar,
    setSnackBarProps,
    getStockRows,
    stockRows,
    singleFlavourRow,
  } = props;

  const [showUpdateStockData, setShowUpdateStockData] = useState(false);
  const [column, setColumn] = useState(null);

  useEffect(() => {
    getStockRows();
  }, []);

  return (
    <div>
      {/* className="stock-table-container-div" */}
      <Snacky snackprops={snackbar} setSnackBarProps={setSnackBarProps} />
      {showUpdateStockData && (
        <UpdateStockPopup
          modalState={showUpdateStockData}
          handleCloseModal={() => {
            setShowUpdateStockData(false);
            setColumn(null);
          }}
          height={"auto"}
          width={"80vW"}
          headerColor={"#27293d"}
          column={column}
          setSnackBarProps={setSnackBarProps}
        />
      )}
      <Paper className="paper-stock-row-table">
        <Table className="stock-row-table">
          <TableBody>
            {stockRows.length > 0 &&
              stockRows.map((r, index) => (
                <TableRow key={index}>
                  {r.map((row) => (
                    <TableCell
                      key={row.label}
                      className={`stock-row-cell ${
                        row.rowDisabled
                          ? "disabled-background"
                          : row.closed
                          ? "red-background"
                          : singleFlavourRow.includes(row.stock)
                          ? "orange-background"
                          : "green-background"
                      }`}
                      onClick={row.rowDisabled == 0 ? () => {
                        setShowUpdateStockData(true);
                        setColumn(row);
                      } : ""}
                      // onClick={() => {
                      //   setShowUpdateStockData(true);
                      //   setColumn(row);
                      // }}
                    >
                      <Typography>
                        {row.rowDisabled ? "N/A" : row.stock}
                      </Typography>
                      {row.rowDisabled == 0 && (
                        <Typography>{row.current}</Typography>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  snackbar: state.snackbar,
  stockRows: state.stock.stockRows,
  singleFlavourRow: state.stock.singleFlavourRow,
});

export default connect(mapStateToProps, {
  setSnackBarProps,
  getStockRows,
})(StockRows);
