import React from "react";
import { connect } from "react-redux";
import OrdersTable from "../Shared/OrdersTable";
import {
    Paper
} from "@material-ui/core"

const Dashboard = (props) => {

    return (
        <Paper style={{
            height: "100vH",
            width: "100vW"
        }}>
            <iframe
                title="package-status-datastudio"
                width="99%"
                height="99%"
                src="https://datastudio.google.com/embed/reporting/d9ef32df-c167-4090-9367-48c53f22a4a3/page/XrtEC"
                allowfullscreen
            ></iframe>
        </Paper>
    )
};

const mapStateToProps = (state) => ({
    orders: state.order.orders,
});

export default connect(mapStateToProps, {})(Dashboard);
