import { createAction } from "@reduxjs/toolkit";
import axios from "axios";
import { setSnackBarProps } from "./snackbar";

export const setError = createAction("SET_ERROR");

export const sendOrdersToStore = createAction("GET_ORDERS");
export const sendDelayedOrdersToStore = createAction("GET_DELAYED_ORDERS");
export const addUpdatingOrders = createAction("UPDATE_ORDERS_UPDATING");
export const removeUpdatingOrder = createAction("REMOVE_UPDATING_ORDER");
export const ordersForInvestigation = createAction(
  "GET_ORDERS_FOR_INVESTIGATION"
);
export const setAssignee = createAction("SET_ASSIGNEE");

export const getOrders = () => async (dispatch) => {
  try {
    var values = await axios.get("Orders/GetOrder");
    dispatch(sendOrdersToStore(values.data));
  } catch (error) {
    dispatch(setError(error));
  }
};

export const bulkRelease = (orderIds) => async (dispatch) => {
  try {
    await axios.patch(`Orders/BulkRelease/${JSON.stringify(orderIds)}`);
  } catch (error) {
    dispatch(setError(error));
  }
};

export const bulkSendToHoldOrder = (orderIds) => async (dispatch) => {
  try {
    await axios.patch(`Orders/BulkSendToHoldOrder/${JSON.stringify(orderIds)}`);
  } catch (error) {
    dispatch(setError(error));
  }
};

export const bulkError = (orderIds) => async (dispatch) => {
  try {
    await axios.patch(`Orders/BulkError/${JSON.stringify(orderIds)}`);
  } catch (error) {
    dispatch(setError(error));
  }
};

export const bulkSendStore = (orderIds) => async (dispatch) => {
  try {
    await axios.patch(`Orders/BulkSendStore/${JSON.stringify(orderIds)}`);
  } catch (error) {
    dispatch(setError(error));
  }
};

export const bulkSendPackingStation = (orderIds) => async (dispatch) => {
  try {
    await axios.patch(`Orders/BulkSendPacking/${JSON.stringify(orderIds)}`);
  } catch (error) {
    dispatch(setError(error));
  }
};

export const bulkSendHandpack = (orderIds) => async (dispatch) => {
  try {
    await axios.patch(`Orders/BulkSendHandpack/${JSON.stringify(orderIds)}`);
  } catch (error) {
    dispatch(setError(error));
  }
};

export const bulkToggleBulkPack = (orderIds) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `Orders/BulkToggleBulkPack/${JSON.stringify(orderIds)}`,
      {},
      { validateStatus: (status) => status === 400 }
    );

    if (response.status === 400) {
      dispatch(
        setSnackBarProps(
          "error",
          "Orders need to be in the same state to be toggled",
          true
        )
      );
    }
  } catch (error) {
    dispatch(setError(error));
  }
};

export const bulkCompleteOrders = (orderIds) => async (dispatch) => {
  try {
    for (let i = 0; i < orderIds.length; i++) {
      dispatch(addUpdatingOrder(orderIds[i]));
    }
    await axios
      .patch(`Orders/BulkComplete/${JSON.stringify(orderIds)}`)
      .finally(() => {
        for (let i = 0; i < orderIds.length; i++) {
          dispatch(removeUpdatingOrder(orderIds[i]));
        }
      });
  } catch (error) {
    dispatch(setError(error));
  }
};

export const getDelayedOrders = () => async (dispatch) => {
  try {
    var values = await axios.get("ControlCenter/GetDelayedOrders");
    dispatch(sendDelayedOrdersToStore(values.data));
  } catch (error) {
    dispatch(setError(error));
  }
};

export const bulkNotes = (orderIds) => async (dispatch) => {
  try {
    await axios.patch(`Orders/BulkNotes/${JSON.stringify(orderIds)}`);
  } catch (error) {
    dispatch(setError(error));
  }
};

export const bulkLabels = (orderIds) => async (dispatch) => {
  try {
    await axios.patch(`Orders/BulkLabels/${JSON.stringify(orderIds)}`);
  } catch (error) {
    dispatch(setError(error));
  }
};

export const bulkLabelsAndComplete =
  (orderIds, printerId) => async (dispatch) => {
    try {
      await axios.patch(
        `Orders/BulkLabelsAndComplete/${JSON.stringify(orderIds)}/${printerId}`
      );
    } catch (error) {
      dispatch(setError(error));
    }
  };

export const setAssigneeForChunk =
  (chunkNumber, assignee) => async (dispatch) => {
    try {
      await axios.patch(
        `Orders/SetAssigneeForChunk/${chunkNumber}/${assignee}`
      );
    } catch (error) {
      dispatch(setError(error));
    }
  };

export const printChunk = (chunkNumber) => async (dispatch) => {
  try {
    dispatch(addUpdatingOrder(`chunk_${chunkNumber}`));
    await axios
      .patch(`Orders/PrintChunkInPackingStation/${chunkNumber}`)
      .finally(() => dispatch(removeUpdatingOrder(`chunk_${chunkNumber}`)));
  } catch (error) {
    dispatch(setError(error));
  }
};

export const completeChunk = (chunkNumber) => async (dispatch) => {
  try {
    dispatch(addUpdatingOrder(`chunk_${chunkNumber}`));
    await axios
      .patch(`Orders/CompleteChunk/${chunkNumber}`)
      .finally(() => dispatch(removeUpdatingOrder(`chunk_${chunkNumber}`)));
  } catch (error) {
    dispatch(setError(error));
  }
};

export const addUpdatingOrder = (orderId) => async (dispatch) => {
  try {
    dispatch(addUpdatingOrders(orderId));
  } catch (error) {
    dispatch(setError(error));
  }
};

export const saveEditedOrder = (editedOrder) => async (dispatch) => {
  try {
    axios
      .patch(
        `Orders/PatchEditedOrder/${editedOrder.id}/${editedOrder.makeDefault}/${editedOrder.changeShipping}/false`,
        {
          firstName: editedOrder.firstName,
          lastName: editedOrder.lastName,
          street: editedOrder.street,
          suburb: editedOrder.suburb,
          city: editedOrder.city,
          postCode: editedOrder.postCode,
          isPostal: editedOrder.isPostal,
          country: editedOrder.country,
          notes: editedOrder.note,
          deliveryInstructions: editedOrder.deliveryInstructions,
          //coffeeTypesQuantity: editedOrder.coffeeTypesQuantity,
          coffeeTypesQuantityArr: editedOrder.coffeeTypesQuantityArr,
          miscQuantityArr: editedOrder.miscQuantityArr,
          validAddress: editedOrder.validAddress,
          handwrittenNote: editedOrder.handwrittenNote,
          company: editedOrder.company,
          priority: editedOrder.priority,
          checkAddress: editedOrder.checkAddress,
          firstNameForbiddenProfaneWords:
            editedOrder.firstNameForbiddenProfaneWords,
          lastNameForbiddenProfaneWords:
            editedOrder.lastNameForbiddenProfaneWords,
          preferredCourier: editedOrder.preferredCourier,
          updatePreferredCourierShopify:
            editedOrder.updatePreferredCourierShopify,
        }
      )
      .then((res) => {
        // console.log(res.data);
      })
      .catch((e) => {
        // console.log(e);
      })
      .finally(() => {
        dispatch(removeUpdatingOrder(editedOrder.id));
      });
  } catch (error) {
    dispatch(setError(error));
  }
};

export const saveAndRelease = (editedOrder) => async (dispatch) => {
  try {
    axios
      .post(
        `Orders/SaveAndRelease/${editedOrder.id}/${editedOrder.makeDefault}/${editedOrder.changeShipping}/false`,
        {
          firstName: editedOrder.firstName,
          lastName: editedOrder.lastName,
          street: editedOrder.street,
          suburb: editedOrder.suburb,
          city: editedOrder.city,
          postCode: editedOrder.postCode,
          isPostal: editedOrder.isPostal,
          country: editedOrder.country,
          notes: editedOrder.note,
          deliveryInstructions: editedOrder.deliveryInstructions,
          coffeeTypesQuantityArr: editedOrder.coffeeTypesQuantityArr,
          miscQuantityArr: editedOrder.miscQuantityArr,
          validAddress: editedOrder.validAddress,
          handwrittenNote: editedOrder.handwrittenNote,
          company: editedOrder.company,
          priority: editedOrder.priority,
          checkAddress: editedOrder.checkAddress,
          firstNameForbiddenProfaneWords:
            editedOrder.firstNameForbiddenProfaneWords,
          lastNameForbiddenProfaneWords:
            editedOrder.lastNameForbiddenProfaneWords,
          preferredCourier: editedOrder.preferredCourier,
          updatePreferredCourierShopify:
            editedOrder.updatePreferredCourierShopify,
        }
      )
      .then((res) => {
        // console.log(res.data);
      })
      .catch((e) => {
        // console.log(e);
      })
      .finally(() => {
        dispatch(removeUpdatingOrder(editedOrder.id));
      });
  } catch (error) {
    dispatch(setError(error));
  }
};

export const getOrdersForInvestigation = () => async (dispatch) => {
  try {
    var values = await axios.get("OrderIssue/FindOrdersForInvestigation");
    dispatch(ordersForInvestigation(values.data));
  } catch (error) {
    dispatch(setError(error));
  }
};

export const removeInvestigationIssueFromOrder =
  (orderId) => async (dispatch) => {
    try {
      await axios.put(`OrderIssue/RemoveOrderFromInvestigation/` + orderId);
    } catch (error) {
      dispatch(setError(error));
    }
  };

export const fulfillOrderInShopfy =
  (orderId, notifyCustomer = false) =>
  async (dispatch) => {
    try {
      await axios.put(
        `Orders/FulfillOrderInShopfy/` + orderId + "/" + notifyCustomer
      );
    } catch (error) {
      dispatch(setError(error));
    }
  };
