import React, { useState, useEffect } from "react";
import "../Delays.scss";
import {
  Typography,
  Grid,
  TextField,
  Button,
  CircularProgress,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Fab,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import MailIcon from "@material-ui/icons/Mail";
import axios from "axios";
import { convertTime, getDayFromDateTimeString } from "../../Utils/dateTime";
import EmailMessageDialog from "./EmailMessageDialog";

const LogsPanel = (props) => {
  const { storeOrder, orderEvents, setOrderEvents, trackingNumber, user } = props;

  const [showWriteComment, setShowWriteComment] = useState(false);
  const [openEmailMessage, setOpenEmailMessage] = useState(false);
  const [emailMessage, setEmailMessage] = useState(null);
  const [openReplyEmailCard, setOpenReplyEmailCard] = useState(false);
  const [isReplyAll, setIsReplyAll] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const WriteCommentPopUp = (props) => {
    const { open, handleClose, setOrderEvents, storeOrderId, trackingNumber } =
      props;

    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(false);

    const postComment = () => {
      setLoading(true);
      axios
        .post(`ControlCenter/PostInternalComment`, {
          comment: comment,
          user: user,
          orderFk: storeOrderId,
          trackingId: trackingNumber,
        })
        .then((res) => {
          setOrderEvents(res.data);
        })
        .catch((e) => {
          //   console.log(e);
        })
        .finally(() => {
          setLoading(false);
          handleClose();
        });
    };

    return (
      <Dialog open={open} keepMounted onClose={() => handleClose()}>
        <DialogTitle>Internal comment</DialogTitle>
        <DialogContent
          style={{
            width: "500px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                label="Comment"
                multiline
                rowsMax={5}
                rows={5}
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
        </DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => handleClose()}
            >
              Go Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => postComment()}
            >
              Add comment
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  };

  useEffect(() => {
    if (orderEvents != null) {
      var objDiv = document.getElementById("log-panel-card");
      //   console.log(objDiv);
      objDiv.scrollTop = objDiv.scrollHeight - objDiv.clientHeight;
    }
  }, [orderEvents]);

  const handleOpenEmailMessage = (emailMessage) => {
    setOpenEmailMessage(true);
    setEmailMessage(emailMessage);
  };

  const handleCloseEmailMessageDialog = () => {
    setOpenEmailMessage(false);
    setEmailMessage(null);
    setOpenReplyEmailCard(false);
  };

  const handleReplyButton = (isReplyAllOption) => {
    setOpenReplyEmailCard(true);
    setIsReplyAll(isReplyAllOption);
  };

  const handleDiscardReply = () => {
    setOpenReplyEmailCard(false);
  };

  const handleSendReplyMessage = (replyMessage) => {
    setIsSendingEmail(true);
    axios
      .post(`ControlCenter/SendEmail/${isReplyAll}`, replyMessage)
      .then((res) => {
        var newEvent = {
          user: "Email",
          title: res.data.subject,
          content: res.data.snippet.replaceAll("&#39;", "'"),
          isEmailMessage: true,
          emailMessage: res.data,
          eventTime: "2021-10-04T14:42:47",
        };
        let orderEventsClone = [...orderEvents];
        orderEventsClone.push(newEvent);
        setOrderEvents(orderEventsClone);
        handleCloseEmailMessageDialog();
      })
      .catch((e) => {
        console.log(e);
        alert(
          "It looks like something went wrong. Please check the sent folder."
        );
      })
      .finally(() => {
        setIsSendingEmail(false);
      });
  };

  return (
    <React.Fragment>
      {showWriteComment && (
        <WriteCommentPopUp
          open={showWriteComment}
          handleClose={() => {
            setShowWriteComment(false);
          }}
          setOrderEvents={setOrderEvents}
          storeOrderId={storeOrder.id}
          trackingNumber={trackingNumber}
        />
      )}

      <EmailMessageDialog
        open={openEmailMessage}
        handleClose={handleCloseEmailMessageDialog}
        emailMessage={emailMessage}
        handleReplyButton={handleReplyButton}
        isReplyAll={isReplyAll}
        openReplyEmailCard={openReplyEmailCard}
        handleDiscardReply={handleDiscardReply}
        handleSendReplyMessage={handleSendReplyMessage}
        isSendingEmail={isSendingEmail}
      />
      <Card
        style={{ height: "100%", overflowY: "auto" }}
        className="no-margin-padding"
        elevation={9}
        id="log-panel-card"
      >
        <CardContent id="log-panel-card-content">
          <Typography variant="h6" align="center">
            {`Event Log - ${trackingNumber}`}
          </Typography>
          {orderEvents != null ? (
            orderEvents.map((event) => {
              return (
                <div
                  key={`${event.user}-${event.eventTime}-${event.title}-${event.content[0]}`}
                  className={`${
                    event.user.includes("RobotUI")
                      ? "robotui-speech-bubble"
                      : event.isEmailMessage
                      ? "email-speech-bubble"
                      : event.title.includes("Internal") ||
                        event.title.includes("Customer")
                      ? "comment-speech-bubble"
                      : event.user.includes("NZPost") ||
                      event.user.includes("Aramex")
                      ? "nzpost-speech-bubble"
                      : ""
                  }`}
                >
                  <Typography
                    variant="body1"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <b>
                      {`${event.user}`} - <i>{event.title}</i>
                    </b>
                    {event.isEmailMessage && (
                      <Button
                        variant="outlined"
                        style={{ color: "white" }}
                        startIcon={<MailIcon />}
                        onClick={(e) =>
                          handleOpenEmailMessage(event.emailMessage)
                        }
                      >
                        Open
                      </Button>
                    )}
                  </Typography>
                  <Typography variant="body1">{event.content}</Typography>
                  <Typography variant="body1" align="right">
                    <i>{`${getDayFromDateTimeString(
                      event.eventTime
                    )} ${convertTime(event.eventTime)}`}</i>
                  </Typography>
                </div>
              );
            })
          ) : (
            <CircularProgress />
          )}
          <div
            style={{
              position: "-webkit-sticky" /* Safari */,
              position: "sticky",
              bottom: 5,
              zIndex: 9999,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Fab
              size="small"
              color="primary"
              onClick={() => setShowWriteComment(true)}
            >
              <AddIcon />
            </Fab>
          </div>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default LogsPanel;
