import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Box,
    Tab,
    Tabs,
    AppBar
} from '@material-ui/core';
import ProductTable from './ProductTable';
import Axios from "axios";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`products-tabpanel-${index}`}
            aria-labelledby={`products-tab-${index}`}
            className="product-tab-panel"
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `products-tab-${index}`,
        'aria-controls': `products-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const ProductsTabs = ({isSubscription = false, handleSelectNewProduct}) => {

    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [getProducts, setGetProducts] = useState(true);
    const [regularProducts, setRegularProducts] = useState([]);
    const [compostableProducts, setCompostableProducts] = useState([]);
    const [limitedProducts, setLimitedProducts] = useState([]);
    const [samplerProducts, setSamplerProducts] = useState([]);
    const [mixedProducts, setMixedProducts] = useState([]);
    const [variantProducts, setVariantProducts] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (getProducts) {
            setGetProducts(false);
            Axios.get("Shopify/GetAllProductsForAdminPage")
                .then((res) => {
                    setRegularProducts(res.data.regular)
                    setCompostableProducts(res.data.compostable)
                    setMixedProducts(res.data.mixed)
                    setSamplerProducts(res.data.sampler)
                    setLimitedProducts(res.data.limited)
                    setVariantProducts(res.data.variant)
                }).catch(e => {
                    console.log(e);
                }).finally(() => {
                })
        }
    }, [getProducts])

    return (
        <div className={classes.root}>
            <AppBar position="static" className='product-tab-appbar'>
                <Tabs value={value} onChange={handleChange} aria-label="products tabs example" className={"product-tab-header"} component="span">
                    <Tab label="Regular + Compostable" {...a11yProps(0)} />
                    <Tab label="Sampler + Mixed" {...a11yProps(1)} />
                    <Tab label="Limited Edition + Variant" {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Grid container spacing={2} component="span">
                    <Grid item xs={6} md={6} lg={6} component="span">
                        <ProductTable capsuleType="Regular" products={regularProducts} isSubscription={isSubscription} handleSelectNewProduct={handleSelectNewProduct}/>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} component="span">
                        <ProductTable capsuleType="Compostable" products={compostableProducts} isSubscription={isSubscription} handleSelectNewProduct={handleSelectNewProduct}/>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Grid container spacing={2} component="span">
                    <Grid item xs={6} md={6} lg={6} component="span">
                            <ProductTable capsuleType="Sampler" products={samplerProducts} isSubscription={isSubscription} handleSelectNewProduct={handleSelectNewProduct}/>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} component="span">
                        <ProductTable capsuleType="Mixed" products={mixedProducts} isSubscription={isSubscription} handleSelectNewProduct={handleSelectNewProduct}/>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Grid container spacing={2} component="span">
                    <Grid item xs={6} md={6} lg={6} component="span">
                        <ProductTable capsuleType="Limited" products={limitedProducts} isSubscription={isSubscription} handleSelectNewProduct={handleSelectNewProduct}/>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} component="span">
                        <ProductTable capsuleType="Variant" products={variantProducts} isSubscription={isSubscription} handleSelectNewProduct={handleSelectNewProduct}/>
                    </Grid>
                </Grid>
            </TabPanel>
        </div>
    );
}

export default ProductsTabs;
