import React, { useState, useEffect } from "react";
import "../Delays.scss";
import {
    Typography,
    Card,
    CardContent,
} from "@material-ui/core";
//-------------------------------------------------//
const content = (label, text) => {
    return (
        <Typography align="left" variant="body1" noWrap={false}>
            <b>{`${label}: `}</b>{`${text !== null && text !== "" ? text : "N/A"}`}
        </Typography>
    )
}

export const ShopifyDetails = (props) => {
    const {
        shopifyOrder
    } = props;

    return (
        <Card style={{ height: "100%" }} className="no-margin-padding" elevation={9}>
            <CardContent>
                <Typography align="center" variant="h6">{`Shopify`}</Typography>
                {content("Address 1", shopifyOrder.shippingAddress.address1)}
                {content("Address 2", shopifyOrder.shippingAddress.address2)}
                {content("City", shopifyOrder.shippingAddress.city)}
                {content("Country", shopifyOrder.shippingAddress.country)}
                {content("Zip", shopifyOrder.shippingAddress.zip)}
                {content("Company", shopifyOrder.shippingAddress.company)}
                {content("Name", `${shopifyOrder.customer.firstName} ${shopifyOrder.customer.lastName}`)}
                {content("Email", shopifyOrder.customer.email)}
                {content("Phone", shopifyOrder.customer.phone)}
            </CardContent>
        </Card>
    )
}
//-------------------------------------------------//
export const RobotUIDetails = (props) => {
    const {
        storeOrder
    } = props;

    return (
        <Card style={{ height: "100%" }} className="no-margin-padding" elevation={9}>
            <CardContent>
                <Typography align="center" variant="h6">{`RobotUI`}</Typography>
                {content("Address 1", storeOrder.shippingAddress.address1)}
                {content("Address 2", storeOrder.shippingAddress.address2)}
                {content("City", storeOrder.shippingAddress.city)}
                {content("Country", storeOrder.shippingAddress.country)}
                {content("Zip", storeOrder.shippingAddress.zip)}
                {content("Company", storeOrder.shippingAddress.company)}
                {content("Name", `${storeOrder.shippingAddress.firstName} ${storeOrder.shippingAddress.lastName}`)}
                {content("Email", storeOrder.emailAddress)}
                {content("Phone", storeOrder.shippingAddress.phone)}
            </CardContent>
        </Card>
    )
}