import React from "react";
import "./quantityInput.scss";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import "./quantityInput.scss";

const QuantityInput = ({
  value,
  onDecrement,
  onIncrement,
  disabled,
  isTransparent,
  ...props
}) => {
  return (
    <div
      className={`quantity-input-root ${
        isTransparent === true ? "quantity-input-transparent" : ""
      }`}
    >
      <div
        className={"quantity-input-iconContainer"}
        role={"presentation"}
        onClick={onDecrement}
      >
        <RemoveIcon />
      </div>
      <div className="quantity-input-inputContainer">
        <input
          className={`${
            isTransparent === true ? "quantity-input-transparentInput" : ""
          }`}
          type={"number"}
          value={value}
          disabled={disabled}
          {...props}
        ></input>
      </div>
      <div
        role={"presentation"}
        onClick={onIncrement}
        className={"quantity-input-iconContainer"}
      >
        <AddIcon />
      </div>
    </div>
  );
};

export default QuantityInput;
