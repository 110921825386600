import React, { useEffect, useState } from "react"
import { withStyles } from "@material-ui/core/styles";
import {
    Grid,
    Button
} from "@material-ui/core";
import ShippingAddressSection from "./CreateComponents/ShippingSection";
import CompanySection from "./CreateComponents/CompanySection";
import BillingAddressSection from "./CreateComponents/BillingSection";
import EmployeeeSection from "./CreateComponents/EmployeeSection";
import axios from "axios";
import { setSnackBarProps } from "../../../reduxActions/snackbar";
import { connect } from "react-redux";
import Snacky from "../../Shared/Snacky";
import Dialogy from "../../Shared/Dialogy";
import { useHistory } from "react-router-dom";

const CreateCompany = (props) => {
    let history = useHistory();

    const [newCompany, setNewCompany] = useState({
        name: "",
        accountsEmail: "",
        address1: "",
        address2: "",
        city: "",
        zip: "",
        province: "",
        country: "",
        purchaseOrder: "",
        savePurchaseOrder: "",
        shippingSameAsBilling: 1
    })

    const [businessDirectAddresses, setBusinessDirectAddresses] = useState({
        billingAddress: {
            name: "",
            accountsEmail: "",
            address1: "",
            address2: "",
            city: "",
            zip: "",
            province: "",
            country: "",
            addressType: "billing"
        },
        shippingAddress: {
            name: "",
            accountsEmail: "",
            address1: "",
            address2: "",
            city: "",
            zip: "",
            province: "",
            country: "",
            addressType: "shipping"
        }
    })

    const [newEmployee, setNewEmployee] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: ""
    })

    const [successDialogyOpen, setSuccessDialogyOpen] = useState(false);
    const [failDialogyOpen, setFailDialogyOpen] = useState(false);

    const updateNewCompany = (property, newValue) => {
        var c = {
            ...newCompany,
            [property]: newValue
        };

        setNewCompany(c);
    }

    const updateNewEmployee = (property, newValue) => {
        var e = {
            ...newEmployee,
            [property]: newValue
        };

        setNewEmployee(e);
    }

    const updateBusinessDirectAddresses = (property, newValue) => {
        var newBusinessDirectAddresses = {
            ...businessDirectAddresses,
            [`${property}`]: {
                ...newValue
            }
        };

        setBusinessDirectAddresses(newBusinessDirectAddresses)
    }

    const handleSave = () => {
        axios.post(`BusinessDirect/CreateNewCompany`, {
            newCompany: newCompany,
            businessDirectAddresses: businessDirectAddresses,
            newEmployee: newEmployee
        }).then(res => {
            setSuccessDialogyOpen(true);
            setSnackBarProps("success", "Saved", true);
            setNewCompany({
                name: "",
                accountsEmail: "",
                address1: "",
                address2: "",
                city: "",
                zip: "",
                province: "",
                country: "",
                purchaseOrder: "",
                savePurchaseOrder: "",
                shippingSameAsBilling: 1
            });
            setBusinessDirectAddresses({
                billingAddress: {
                    name: "",
                    accountsEmail: "",
                    address1: "",
                    address2: "",
                    city: "",
                    zip: "",
                    province: "",
                    country: "",
                    addressType: "billing"
                },
                shippingAddress: {
                    name: "",
                    accountsEmail: "",
                    address1: "",
                    address2: "",
                    city: "",
                    zip: "",
                    province: "",
                    country: "",
                    addressType: "shipping"
                }
            });
            setNewEmployee({
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: ""
            });
        }).catch(e => {
            setFailDialogyOpen(true);
            setSnackBarProps("warning", "Failed. Please try again", true);
        })
    }

    const allFieldsFilledIn = () => {
        if (newCompany.shippingSameAsBilling) {
            if (newCompany.name !== "" &&
                newCompany.accountsEmail !== "" &&
                businessDirectAddresses.billingAddress.address1 !== "" &&
                businessDirectAddresses.billingAddress.address2 !== "" &&
                businessDirectAddresses.billingAddress.city !== "" &&
                businessDirectAddresses.billingAddress.zip !== "" &&
                businessDirectAddresses.billingAddress.country !== "") {
                return true;
            } else {
                return false;
            }
        } else {
            if (newCompany.name !== "" &&
                newCompany.accountsEmail !== "" &&
                businessDirectAddresses.billingAddress.address1 !== "" &&
                businessDirectAddresses.billingAddress.address2 !== "" &&
                businessDirectAddresses.billingAddress.city !== "" &&
                businessDirectAddresses.billingAddress.zip !== "" &&
                businessDirectAddresses.billingAddress.country !== "" &&
                businessDirectAddresses.shippingAddress.address1 !== "" &&
                businessDirectAddresses.shippingAddress.address2 !== "" &&
                businessDirectAddresses.shippingAddress.city !== "" &&
                businessDirectAddresses.shippingAddress.zip !== "" &&
                businessDirectAddresses.shippingAddress.country !== "") {
                return true;
            } else {
                return false;
            }
        }

    }

    useEffect(() => {
        document.getElementsByTagName("body")[0].style.overflow = "unset";
        return () => {
            document.getElementsByTagName("body")[0].style.overflow = "hidden";
        };
    }, []);

    return (
        <div className="business-direct-form">
            <Dialogy
                open={successDialogyOpen}
                handleClose={() => {
                    setSuccessDialogyOpen(false);
                }}
                details={{
                    title: "Success",
                    text: "Company created",
                    type: "",
                }}
            />
            <Dialogy
                open={failDialogyOpen}
                handleClose={() => {
                    setFailDialogyOpen(false);
                }}
                details={{
                    title: "Failed to create",
                    text: "Please try again",
                    type: "",
                }}
            />
            <Grid container spacing={2}>
                <CompanySection
                    newCompany={newCompany}
                    updateNewCompany={updateNewCompany}
                />
                <EmployeeeSection
                    newEmployee={newEmployee}
                    updateNewEmployee={updateNewEmployee}
                />
                <BillingAddressSection
                    newCompany={newCompany}
                    updateNewCompany={updateNewCompany}
                    updateBusinessDirectAddresses={updateBusinessDirectAddresses}
                    billingAddress={businessDirectAddresses.billingAddress}
                />
                {newCompany.shippingSameAsBilling === 0 && (
                    <ShippingAddressSection
                        updateBusinessDirectAddresses={updateBusinessDirectAddresses}
                        shippingAddress={businessDirectAddresses.shippingAddress}
                    />
                )}
                {allFieldsFilledIn() && (
                    <Grid item xs={12} md={12} lg={12}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={e => handleSave()}
                        >
                            Save
                        </Button>
                    </Grid>
                )}
            </Grid>
        </div>
    )
}

//export default CreateCompany;

const mapStateToProps = (state) => ({
    snackbar: state.snackbar,
});

export default connect(mapStateToProps, {
    setSnackBarProps,
})(CreateCompany);